import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import React from 'react';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import exportIcon from '@iconify/icons-ant-design/export-outline';
import { MdOutlineSystemUpdateAlt } from 'react-icons/md';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { navigate } from '@storybook/addon-links';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 45,
  display: 'flex',
  justifyContent: 'space-between'
  // padding: '0 !important'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  paddingTop: 2,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

export default function PartnersListToolbar({
  numSelected,
  filterName,
  onFilterName,
  parent,
  pageMenu
}) {
  const navigate = useNavigate();
  return (
    <RootStyle
      sx={{
        minHeight: 45,
        borderBottom: '1px solid #eaedf1'
      }}
    >
      <Box>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => {
            console.log('pageElement', pageElement);
            return (
              <React.Fragment key={pageElement.subPageId + index}>
                {pageElement.subPageCode === 'ADD_NEW' && pageElement.isEnable === true ? (
                  <>
                    <Tooltip title="Add New" placement="top" arrow>
                      <IconButton
                        className="squareIconButton"
                        onClick={(e) => navigate(`/${parent}/add`)}
                      >
                        <Icon icon={add} width={18} />
                      </IconButton>
                    </Tooltip>
                    &nbsp;
                  </>
                ) : pageElement.subPageCode === 'EXPORT_DATA' && pageElement.isEnable === true ? (
                  <>
                    <Tooltip title="Export data" placement="top" arrow>
                      <IconButton className="squareIconButton">
                        <MdOutlineSystemUpdateAlt size={18} />
                      </IconButton>
                    </Tooltip>
                    &nbsp;
                  </>
                ) : (
                  ''
                )}
              </React.Fragment>
            );
          })}
        {/* <Tooltip title="Add New" placement="top" arrow>
          <IconButton className="squareIconButton" onClick={(e) => navigate(`/${parent}/add`)}>
            <Icon icon={add} width={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Export data" placement="top" arrow>
          <IconButton className="squareIconButton">
            <MdOutlineSystemUpdateAlt size={18} />
          </IconButton>
        </Tooltip> */}
      </Box>
      <Box>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'SEARCH' && pageElement.isEnable === true ? (
                <>
                  <SearchStyle
                    value={filterName}
                    onChange={onFilterName}
                    placeholder="Search Prospect"
                    classes={{
                      root: 'searchHolder',
                      input: 'searchInput'
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    }
                  />
                  &nbsp;&nbsp;&nbsp;
                </>
              ) : pageElement.subPageCode === 'FILTER' && pageElement.isEnable === true ? (
                <Tooltip title="Filter list">
                  <IconButton
                  // onClick={(e) => {
                  //   if (isOpenFilter) {
                  //     openFilter(false);
                  //   } else {
                  //     openFilter(true);
                  //   }
                  // }}
                  >
                    <Icon icon={roundFilterList} />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
              &nbsp;
            </>
          ))}
      </Box>
    </RootStyle>
  );
}
