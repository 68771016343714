import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  Divider,
  TextareaAutosize
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function PaymentInvoice() {
  const [loanInterested, setLoanInterested] = useState(' ');
  const [selectPaymentMode, setSelectPaymentMode] = useState(' ');
  const [selectDate, setSelectDate] = useState(' ');
  return (
    <Page title="Partner Management System || Invoice">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Payment Details" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Payment Details
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Received On
                    </Typography>
                    <TextField
                      fullWidth
                      type="date"
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="27 June, 2022"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Payment Mode
                    </Typography>
                    <TextField
                      fullWidth
                      type="date"
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Account Pay"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Net Amount Received
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      GST Charged
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      TDS Deduction
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Net Amount for Commision
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="15,860"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="" textAlign="right">
              <Button variant="contained" className="mainBtn">
                Submit
              </Button>
            </Box>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
export default PaymentInvoice;
