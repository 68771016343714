import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import faker from 'faker';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';
// styling
import { StyledTableRow, StyledTableCell } from '../Global/Styling';
import CustomerToolbar from './CustomerToolbar';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'id', label: 'Customer ID', alignRight: false },
  { id: 'name', label: 'Customer NAME', alignRight: false },
  { id: 'city', label: 'Location', alignRight: false },
  { id: 'noofcalls', label: 'Age', alignRight: false },
  { id: 'created', label: 'Relationship Since', alignRight: false },
  { id: 'randomPhoneNumber', label: 'Mobile', alignRight: false },
  { id: 'status', label: 'Loan status', alignRight: false },
  { id: 'loanType', label: 'Loan Type', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function CustomerList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [pageMenu, setPageMenu] = useState([]); // State for store localstorage data so that we can provide data to others components

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  useEffect(() => {
    // get meneu access for user from localstorage which store in <NavSection /> component
    const sidebarMenu = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d74');
    if (sidebarMenu !== null) {
      const bytes = CryptoJS.AES.decrypt(sidebarMenu, 'sidebarMenu');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      console.log('ciphertext', JSON.parse(originalText));

      const pageCode = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d75');
      const pageCodeBytes = CryptoJS.AES.decrypt(pageCode, 'pageCode');
      const pageCodeText = pageCodeBytes.toString(CryptoJS.enc.Utf8);
      console.log('ciphertext', pageCodeText);
      JSON.parse(originalText).accessPagesMapping.forEach((accessPageMap) => {
        if (accessPageMap.pageCode === pageCodeText) {
          setPageMenu(accessPageMap.accessSubPagesMappings);
        }
      });
    }
  }, []);
  return (
    <Page title="PULSE by Real Value Finloans || Customer">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="List of Customer" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <CustomerToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="Customer"
              pageMenu={pageMenu}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          name,
                          amount,
                          amount2,
                          amount3,
                          name1,
                          name2,
                          role,
                          transactionType,
                          randomPhoneNumber,
                          status,
                          segment,
                          active,
                          created,
                          city,
                          noofcalls,
                          partnerType,
                          loanType,
                          users
                        } = row;
                        console.log('users', users);
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{id}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{noofcalls}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{randomPhoneNumber}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (active === 'No' && 'error') ||
                                  (active === 'pending' && 'warning') ||
                                  'success'
                                }
                              >
                                {sentenceCase(active === 'No' ? 'Inactive' : 'Active')}
                              </Label>
                            </StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="" width={80}>
                              <UserMoreMenu rowId={id} parent="customer" pageMenu={pageMenu} />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
