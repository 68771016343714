import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import Header from '../../pages/Global/Header';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Footer from '../../pages/Global/Footer';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 5;
const APP_BAR_DESKTOP = 16;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  background: '#eff1f5',
  paddingTop: '49px'
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(true);
  const toggle = Boolean(!open);

  return (
    <RootStyle>
      <DashboardSidebar isopensidebar={open.toString()} onCloseSidebar={() => setOpen(toggle)} />
      <MainStyle>
        <Header isopensidebar={open.toString()} setOpen={setOpen} />
        <Outlet />
        <Footer />
      </MainStyle>
    </RootStyle>
  );
}
