import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';
import { Box } from '@mui/system';
// other icons
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// react icons
import { BsWindowDock, BsCardHeading, BsTelephone } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineIdcard, AiFillEdit } from 'react-icons/ai';
import { RiBankCardLine, RiUser2Fill } from 'react-icons/ri';
import { FiEye } from 'react-icons/fi';
import { FaRegAddressCard, FaRegUser } from 'react-icons/fa';
import { CgCalendarDates } from 'react-icons/cg';
import { HiOutlineMail } from 'react-icons/hi';
import { SiOpensourceinitiative } from 'react-icons/si';
import { GiSwapBag, GiModernCity } from 'react-icons/gi';
import { MdIncompleteCircle, MdOutlineDateRange, MdOutlinePreview } from 'react-icons/md';
import { BiCloudUpload, BiCloudDownload, BiBoltCircle } from 'react-icons/bi';
// material icons
import CloseIcon from '@mui/icons-material/Close';
import HubIcon from '@mui/icons-material/Hub';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BadgeIcon from '@mui/icons-material/Badge';
import HomeIcon from '@mui/icons-material/Home';
import ContactsIcon from '@mui/icons-material/Contacts';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WcIcon from '@mui/icons-material/Wc';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import BusinessIcon from '@mui/icons-material/Business';
import FactoryIcon from '@mui/icons-material/Factory';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WorkIcon from '@mui/icons-material/Work';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MoneyIcon from '@mui/icons-material/Money';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import TopicIcon from '@mui/icons-material/Topic';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import PublicIcon from '@mui/icons-material/Public';
import VillaIcon from '@mui/icons-material/Villa';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FileViewer from 'react-file-viewer';
// components
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import aadharFrontDummy from './aadharFrontDummy.jpg';
import partnerService from '../services/partnerService';
import leadsService from '../services/leadsService';

const { ApplicationSummary } = leadsService;

function ViewApplicationSummary() {
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };
  const navigate = useNavigate();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');
  const [showProspectDetail, setShowProspectDetail] = useState(false);
  const [showCallCenterDetail, setShowCallCenterDetail] = useState(false);
  const [showLeadsDetail, setShowLeadsDetail] = useState(false);
  const [documentPreview, setDocumentPreview] = useState(false);
  const [applicationSummaryData, setApplicationSummaryData] = useState({});
  const [imageState, setImageState] = useState(); // uploaded image preview from api
  const [imageDocType, setImageDocType] = useState('jpg'); // uploaded image preview from api
  console.log('applicationSummaryData', applicationSummaryData);
  console.log('imageState', imageState);

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }

    console.log('localJWTtoken', localJWTtoken);
    const authToken = localJWTtoken.token.jwt;
    const authCompanyId = localJWTtoken.token.companyId;
    const authUserId = localJWTtoken.token.userId;
    const leadId = JSON.parse(localStorage.getItem('leadId'));

    const credentials = {
      companyId: authCompanyId,
      userId: authUserId,
      leadId: leadId
    };

    (async () => {
      const ApplicationSummaryApiResponse = await ApplicationSummary(credentials, authToken);
      if (ApplicationSummaryApiResponse.msg) {
        if (ApplicationSummaryApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ApplicationSummaryApiResponse.status === 'success') {
        setApplicationSummaryData(ApplicationSummaryApiResponse.data);
      } else {
        setApplicationSummaryData({});
      }
    })();
  }, []);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Page title="PULSE by Real Value Finloans || Leads">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Application Summary" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Loan Requirement
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <HubIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Loan Type
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {applicationSummaryData.loanRequirement &&
                        applicationSummaryData.loanRequirement.loanType
                          ? applicationSummaryData.loanRequirement.loanType
                          : 'N/A'}
                        &nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <EmojiObjectsIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Objective
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {applicationSummaryData.loanRequirement &&
                        applicationSummaryData.loanRequirement.objective
                          ? applicationSummaryData.loanRequirement.objective
                          : 'N/A'}
                        &nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <BadgeIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Employment Type
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {applicationSummaryData.loanRequirement &&
                        applicationSummaryData.loanRequirement.employmentType
                          ? applicationSummaryData.loanRequirement.employmentType
                          : 'N/A'}
                        &nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <GiSwapBag className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Loan Amount
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {applicationSummaryData.loanRequirement &&
                        applicationSummaryData.loanRequirement.loanAmount
                          ? applicationSummaryData.loanRequirement.loanAmount
                          : 'N/A'}
                        &nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <CalendarMonthIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Tenure
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {applicationSummaryData.loanRequirement &&
                        applicationSummaryData.loanRequirement.loanTenure
                          ? applicationSummaryData.loanRequirement.loanTenure
                          : 'N/A'}
                        &nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <HomeIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Property Identified
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {applicationSummaryData.loanRequirement &&
                        applicationSummaryData.loanRequirement.propertyIdentified
                          ? applicationSummaryData.loanRequirement.propertyIdentified
                          : 'N/A'}
                        &nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={3} mb={3}>
              {/* Applicant start here */}
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle2" className="mb-2 subHeading">
                  Applicant Details
                </Typography>
                {/* Personal Details */}
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Personal Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <ContactsIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                First Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.applicantDetails &&
                                applicationSummaryData.applicantDetails.personalDetails.firstName
                                  ? applicationSummaryData.applicantDetails.personalDetails
                                      .firstName
                                  : 'N/A'}
                                &nbsp;
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <ContactsIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Middle Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.applicantDetails &&
                                applicationSummaryData.applicantDetails.personalDetails.middleName
                                  ? applicationSummaryData.applicantDetails.personalDetails
                                      .middleName
                                  : 'N/A'}
                                &nbsp;
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <ContactsIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Last Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.applicantDetails &&
                                applicationSummaryData.applicantDetails.personalDetails.lastName
                                  ? applicationSummaryData.applicantDetails.personalDetails.lastName
                                  : 'N/A'}
                                &nbsp;
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <CalendarMonthIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                DOB
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.applicantDetails &&
                                applicationSummaryData.applicantDetails.personalDetails.dob
                                  ? applicationSummaryData.applicantDetails.personalDetails.dob
                                  : 'N/A'}
                                &nbsp;
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <FaRegUser className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Father Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.applicantDetails &&
                                applicationSummaryData.applicantDetails.personalDetails.fatherName
                                  ? applicationSummaryData.applicantDetails.personalDetails
                                      .fatherName
                                  : 'N/A'}
                                &nbsp;
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <WcIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Gender
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.applicantDetails &&
                                applicationSummaryData.applicantDetails.personalDetails.gender
                                  ? applicationSummaryData.applicantDetails.personalDetails.gender
                                  : 'N/A'}
                                &nbsp;
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex">
                            <WysiwygIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Aadhar Number
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.applicantDetails &&
                                applicationSummaryData.applicantDetails.personalDetails.aadharNumber
                                  ? applicationSummaryData.applicantDetails.personalDetails
                                      .aadharNumber
                                  : 'N/A'}
                                &nbsp;
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex">
                            <WysiwygIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                PAN Number
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.applicantDetails &&
                                applicationSummaryData.applicantDetails.personalDetails.panNumber
                                  ? applicationSummaryData.applicantDetails.personalDetails
                                      .panNumber
                                  : 'N/A'}
                                &nbsp;
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <img
                        src={
                          applicationSummaryData.applicantDetails &&
                          applicationSummaryData.applicantDetails.documentDetails.filter(
                            (doc) =>
                              doc.documentTypeName.toLowerCase() === 'USER IMAGE'.toLowerCase()
                          ).length
                            ? applicationSummaryData.applicantDetails.documentDetails.filter(
                                (doc) =>
                                  doc.documentTypeName.toLowerCase() === 'USER IMAGE'.toLowerCase()
                              )[0].documentUrl
                            : '../static/dummy/photoDummy.jpg'
                        }
                        alt="Applicant Pic"
                        className="applicantImg"
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* Income Details */}
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Income Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Box className="reviewEditIcon">
                    {/* <Tooltip title="Edit Income Details">
                      <IconButton
                        color="primary"
                        className="squareIconButton"
                        onClick={handleClickOpen}
                      >
                        <AiFillEdit size={18} />
                      </IconButton>
                    </Tooltip> */}
                  </Box>
                  <Dialog
                    className="PopupBox"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                  >
                    <DialogTitle id="alert-dialog-title" className="popupTitle">
                      <Typography variant="h6">Edit Income Details</Typography>
                      <IconButton onClick={handleClose} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers className="">
                      <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} mb={3}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl required fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Company Name
                              </Typography>
                              <TextField
                                id="Company Name"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="Kalolytic Solutions"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl required fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Designation
                              </Typography>
                              <TextField
                                id="Designation"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="React Developer"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl required fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Total Years in Present Company
                              </Typography>
                              <TextField
                                id="Total Years in Present Company"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="5"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl required fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Total Work Experience
                              </Typography>
                              <TextField
                                id="Total Work Experience"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="10"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Gross Salary
                              </Typography>
                              <TextField
                                id="Gross Salary"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="1,00,000"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Net Salary
                              </Typography>
                              <TextField
                                id="Net Salary"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="1,00,000"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Other Annual Income
                              </Typography>
                              <TextField
                                id="Other Annual Income"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="0"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                No. of Loan
                              </Typography>
                              <TextField
                                id="No. of Loan"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="1"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Monthly EMI
                              </Typography>
                              <TextField
                                id="Monthly EMI"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="15,000"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Principal o/s
                              </Typography>
                              <TextField
                                id="Principal o/s"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="20,00,000"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions textAlign="center">
                      <Button onClick={handleClose} className="mainBtn">
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <BusinessIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Company Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.employmentDetails.companyName
                              ? applicationSummaryData.applicantDetails.employmentDetails
                                  .companyName
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <RiUser2Fill className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Designation
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.employmentDetails.designation
                              ? applicationSummaryData.applicantDetails.employmentDetails
                                  .designation
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <WorkHistoryIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Total Years in Present Company
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.employmentDetails
                              .totalYearsInPresentCompany
                              ? applicationSummaryData.applicantDetails.employmentDetails
                                  .totalYearsInPresentCompany
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <WorkIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Total Work Experience
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.employmentDetails
                              .totalWorkExperience
                              ? applicationSummaryData.applicantDetails.employmentDetails
                                  .totalWorkExperience
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <CurrencyRupeeIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Gross Salary
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.incomeDetails.grossSalary
                              ? applicationSummaryData.applicantDetails.incomeDetails.grossSalary
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <CurrencyRupeeIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Net Salary
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.incomeDetails.netSalary
                              ? applicationSummaryData.applicantDetails.incomeDetails.netSalary
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <AccountBalanceWalletIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Other Annual Income
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                              applicationSummaryData.applicantDetails.incomeDetails
                                .otherAnnualIncome}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <CreditScoreIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            No of Loan
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.incomeDetails.numberOfLoan
                              ? applicationSummaryData.applicantDetails.incomeDetails.numberOfLoan
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <InsertInvitationIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Monthly EMI
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                              applicationSummaryData.applicantDetails.incomeDetails.monthlyEmi}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <MoneyIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Principal o/s
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                              applicationSummaryData.applicantDetails.incomeDetails
                                .principalOutstanding}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* Address Details */}
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Address Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <TopicIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Address Type
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.addressDetails.addressType
                              ? applicationSummaryData.applicantDetails.addressDetails.addressType
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <ApartmentIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            House/Building No
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.addressDetails
                              .houseOrBuildingNumber
                              ? applicationSummaryData.applicantDetails.addressDetails
                                  .houseOrBuildingNumber
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <AddRoadIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Street
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.addressDetails.street
                              ? applicationSummaryData.applicantDetails.addressDetails.street
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <PersonPinCircleIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Landmark
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.addressDetails.landmark
                              ? applicationSummaryData.applicantDetails.addressDetails.landmark
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <PinDropIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Pin Code
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.addressDetails.pinCode
                              ? applicationSummaryData.applicantDetails.addressDetails.pinCode
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiModernCity className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            City
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.addressDetails.cityName
                              ? applicationSummaryData.applicantDetails.addressDetails.cityName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <VillaIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            State
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.addressDetails.stateName
                              ? applicationSummaryData.applicantDetails.addressDetails.stateName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <PublicIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Country
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.applicantDetails &&
                            applicationSummaryData.applicantDetails.addressDetails.countryName
                              ? applicationSummaryData.applicantDetails.addressDetails.countryName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* Document Details */}
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Applicant Document Checklist
                  {/* isUploaded */}
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin">
                  <Box className="displayFlex flexWrap">
                    {applicationSummaryData.applicantDetails &&
                    applicationSummaryData.applicantDetails.documentDetails &&
                    applicationSummaryData.applicantDetails.documentDetails.length > 0
                      ? applicationSummaryData.applicantDetails.documentDetails.map(
                          (item, index) => {
                            console.log(item);
                            return (
                              <Box
                                // className="uploadFilePreview uploadFilePreviewGreen"
                                className={
                                  item.isUploaded === true
                                    ? 'uploadFilePreview uploadFilePreviewGreen'
                                    : 'uploadFilePreview uploadFilePreviewRed'
                                }
                                mb={2}
                                onClick={(e) => {
                                  if (item.isUploaded === true) {
                                    setImageState(item.documentUrl);
                                    setImageDocType(
                                      item.contentType ? item.contentType.split('/')[1] : 'jpg'
                                    );
                                    setDocumentPreview(true);
                                  }
                                }}
                              >
                                <RemoveRedEyeIcon className="ImageViewIcon" />
                                <Typography variant="body2" className="docName">
                                  {item.documentTypeName}
                                </Typography>
                              </Box>
                            );
                          }
                        )
                      : 'No Documents Uploaded'}
                    {/* <Box
                      className="uploadFilePreview uploadFilePreviewGreen"
                      mb={2}
                      onClick={(e) => {
                        setDocumentPreview(true);
                        // setImageState(url);
                      }}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Aadhar Card
                      </Typography>
                    </Box> */}

                    {/* <Box
                      className="uploadFilePreview uploadFilePreviewGreen"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Pan Card
                      </Typography>
                    </Box> */}

                    {/* <Box
                      className="uploadFilePreview uploadFilePreviewRed"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Address Proof
                      </Typography>
                    </Box> */}

                    {/* <Box
                      className="uploadFilePreview uploadFilePreviewGreen"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        ITR 2019
                      </Typography>
                    </Box> */}
                    {/* <Box
                      className="uploadFilePreview uploadFilePreviewRed"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        ITR 2020
                      </Typography>
                    </Box> */}
                    {/* <Box
                      className="uploadFilePreview uploadFilePreviewGreen"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Salary Slip March
                      </Typography>
                    </Box> */}
                    {/* <Box
                      className="uploadFilePreview uploadFilePreviewRed"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Photo
                      </Typography>
                    </Box> */}
                  </Box>
                  {/* <Dialog
                    open={documentPreview}
                    onClose={(e) => setDocumentPreview(false)}
                    fullWidth
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6">Document Preview</Typography>
                      <IconButton onClick={(e) => setDocumentPreview(false)} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <img src={imageState} alt="" className="" />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={(e) => setDocumentPreview(false)}
                        variant="contained"
                        className="mainBtn"
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog> */}
                </Box>
              </Grid>
              {/* Co Applicant start here */}
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle2" className="mb-2 subHeading">
                  Co Applicant Details
                </Typography>
                {/* Personal Details */}
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Personal Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <ContactsIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                First Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.coApplicantDetails &&
                                applicationSummaryData.coApplicantDetails.personalDetails.firstName
                                  ? applicationSummaryData.coApplicantDetails.personalDetails
                                      .firstName
                                  : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <ContactsIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Middle Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.coApplicantDetails &&
                                applicationSummaryData.coApplicantDetails.personalDetails.middleName
                                  ? applicationSummaryData.coApplicantDetails.personalDetails
                                      .middleName
                                  : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <ContactsIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Last Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.coApplicantDetails &&
                                applicationSummaryData.coApplicantDetails.personalDetails.lastName
                                  ? applicationSummaryData.coApplicantDetails.personalDetails
                                      .lastName
                                  : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <CalendarMonthIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                DOB
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.coApplicantDetails &&
                                applicationSummaryData.coApplicantDetails.personalDetails.dob
                                  ? applicationSummaryData.coApplicantDetails.personalDetails.dob
                                  : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <FaRegUser className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Father Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.coApplicantDetails &&
                                applicationSummaryData.coApplicantDetails.personalDetails.fatherName
                                  ? applicationSummaryData.coApplicantDetails.personalDetails
                                      .fatherName
                                  : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex mb-3">
                            <WcIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Gender
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.coApplicantDetails &&
                                applicationSummaryData.coApplicantDetails.personalDetails.gender
                                  ? applicationSummaryData.coApplicantDetails.personalDetails.gender
                                  : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex">
                            <WysiwygIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                Aadhar Number
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.coApplicantDetails &&
                                applicationSummaryData.coApplicantDetails.personalDetails
                                  .aadharNumber
                                  ? applicationSummaryData.coApplicantDetails.personalDetails
                                      .aadharNumber
                                  : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="displayFlex">
                            <WysiwygIcon className="viewLabelIcon" size={24} />
                            <Box>
                              <Typography variant="body2" className="labelText">
                                PAN Number
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {applicationSummaryData.coApplicantDetails &&
                                applicationSummaryData.coApplicantDetails.personalDetails.panNumber
                                  ? applicationSummaryData.coApplicantDetails.personalDetails
                                      .panNumber
                                  : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <img
                        src={
                          applicationSummaryData.coApplicantDetails &&
                          applicationSummaryData.coApplicantDetails.documentDetails.filter(
                            (doc) =>
                              doc.documentTypeName.toLowerCase() === 'USER IMAGE'.toLowerCase()
                          ).length
                            ? applicationSummaryData.coApplicantDetails.documentDetails.filter(
                                (doc) =>
                                  doc.documentTypeName.toLowerCase() === 'USER IMAGE'.toLowerCase()
                              )[0].documentUrl
                            : '../static/dummy/photoDummy.jpg'
                        }
                        alt="Applicant Pic"
                        className="applicantImg"
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* Income Details */}
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Income Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Box className="reviewEditIcon">
                    {/* <Tooltip title="Edit Income Details">
                      <IconButton
                        color="primary"
                        className="squareIconButton"
                        onClick={handleClickOpen}
                      >
                        <AiFillEdit size={18} />
                      </IconButton>
                    </Tooltip> */}
                  </Box>
                  <Dialog
                    className="PopupBox"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                  >
                    <DialogTitle id="alert-dialog-title" className="popupTitle">
                      <Typography variant="h6">Edit Income Details</Typography>
                      <IconButton onClick={handleClose} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers className="">
                      <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} mb={3}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl required fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Company Name
                              </Typography>
                              <TextField
                                id="Company Name"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="Kalolytic Solutions"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl required fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Designation
                              </Typography>
                              <TextField
                                id="Designation"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="React Developer"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl required fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Total Years in Present Company
                              </Typography>
                              <TextField
                                id="Total Years in Present Company"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="5"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl required fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Total Work Experience
                              </Typography>
                              <TextField
                                id="Total Work Experience"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="10"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Gross Salary
                              </Typography>
                              <TextField
                                id="Gross Salary"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="1,00,000"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Net Salary
                              </Typography>
                              <TextField
                                id="Net Salary"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="1,00,000"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Other Annual Income
                              </Typography>
                              <TextField
                                id="Other Annual Income"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="0"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                No. of Loan
                              </Typography>
                              <TextField
                                id="No. of Loan"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="1"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Monthly EMI
                              </Typography>
                              <TextField
                                id="Monthly EMI"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="15,000"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="formGroup">
                              <Typography variant="body1" className="formLabel">
                                Principal o/s
                              </Typography>
                              <TextField
                                id="Principal o/s"
                                inputProps={{ className: 'textInput' }}
                                fullWidth
                                defaultValue="20,00,000"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions textAlign="center">
                      <Button onClick={handleClose} className="mainBtn">
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <BusinessIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Company Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.employmentDetails.companyName
                              ? applicationSummaryData.coApplicantDetails.employmentDetails
                                  .companyName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <RiUser2Fill className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Designation
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.employmentDetails.designation
                              ? applicationSummaryData.coApplicantDetails.employmentDetails
                                  .designation
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <WorkHistoryIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Total Years in Present Company
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.employmentDetails
                              .totalYearsInPresentCompany
                              ? applicationSummaryData.coApplicantDetails.employmentDetails
                                  .totalYearsInPresentCompany
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <WorkIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Total Work Experience
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.employmentDetails
                              .totalWorkExperience
                              ? applicationSummaryData.coApplicantDetails.employmentDetails
                                  .totalWorkExperience
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <CurrencyRupeeIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Gross Salary
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.incomeDetails.grossSalary
                              ? applicationSummaryData.coApplicantDetails.incomeDetails.grossSalary
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <CurrencyRupeeIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Net Salary
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.incomeDetails.netSalary
                              ? applicationSummaryData.coApplicantDetails.incomeDetails.netSalary
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <AccountBalanceWalletIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Other Annual Income
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.incomeDetails
                              .otherAnnualIncome
                              ? applicationSummaryData.coApplicantDetails.incomeDetails
                                  .otherAnnualIncome
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <CreditScoreIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            No of Loan
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.incomeDetails.numberOfLoan
                              ? applicationSummaryData.coApplicantDetails.incomeDetails.numberOfLoan
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <InsertInvitationIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Monthly EMI
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.incomeDetails.monthlyEmi
                              ? applicationSummaryData.coApplicantDetails.incomeDetails.monthlyEmi
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <MoneyIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Principal o/s
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.incomeDetails
                              .principalOutstanding
                              ? applicationSummaryData.coApplicantDetails.incomeDetails
                                  .principalOutstanding
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* Address Details */}
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Address Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <TopicIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Address Type
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.addressDetails.addressType
                              ? applicationSummaryData.coApplicantDetails.addressDetails.addressType
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <ApartmentIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            House/Building No
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.addressDetails
                              .houseOrBuildingNumber
                              ? applicationSummaryData.coApplicantDetails.addressDetails
                                  .houseOrBuildingNumber
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <AddRoadIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Street
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.addressDetails.street
                              ? applicationSummaryData.coApplicantDetails.addressDetails.street
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <PersonPinCircleIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Landmark
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.addressDetails.landmark
                              ? applicationSummaryData.coApplicantDetails.addressDetails.landmark
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <PinDropIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Pin Code
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.addressDetails.pinCode
                              ? applicationSummaryData.coApplicantDetails.addressDetails.pinCode
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiModernCity className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            City
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.addressDetails.cityName
                              ? applicationSummaryData.coApplicantDetails.addressDetails.cityName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <VillaIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            State
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.addressDetails.stateName
                              ? applicationSummaryData.coApplicantDetails.addressDetails.stateName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <PublicIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Country
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {applicationSummaryData.coApplicantDetails &&
                            applicationSummaryData.coApplicantDetails.addressDetails.countryName
                              ? applicationSummaryData.coApplicantDetails.addressDetails.countryName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* Document Details */}
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Co Applicant Document Checklist
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin">
                  <Box className="displayFlex flexWrap">
                    {applicationSummaryData.coApplicantDetails &&
                    applicationSummaryData.coApplicantDetails.documentDetails &&
                    applicationSummaryData.coApplicantDetails.documentDetails.length > 0
                      ? applicationSummaryData.coApplicantDetails.documentDetails.map(
                          (item, index) => {
                            console.log(item);
                            return (
                              // <Box
                              //   className="uploadFilePreview uploadFilePreviewGreen"
                              //   mb={2}
                              //   onClick={(e) => setDocumentPreview(true)}
                              // >
                              //   <MdOutlinePreview className="ImageViewIcon" />
                              //   <Typography variant="body2" className="docName">
                              //     Aadhar Card
                              //   </Typography>
                              // </Box>
                              <Box
                                className={
                                  item.isUploaded === true
                                    ? 'uploadFilePreview uploadFilePreviewGreen'
                                    : 'uploadFilePreview uploadFilePreviewRed'
                                }
                                mb={2}
                                onClick={(e) => {
                                  if (item.isUploaded === true) {
                                    setImageState(item.documentUrl);
                                    setImageDocType(
                                      item.contentType ? item.contentType.split('/')[1] : 'jpg'
                                    );
                                    setDocumentPreview(true);
                                  }
                                }}
                              >
                                <RemoveRedEyeIcon className="ImageViewIcon" />
                                <Typography variant="body2" className="docName">
                                  {item.documentTypeName}
                                </Typography>
                              </Box>
                            );
                          }
                        )
                      : 'No Documents Uploaded'}
                    {/* <Box
                      className="uploadFilePreview uploadFilePreviewGreen"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Aadhar Card
                      </Typography>
                    </Box>
                    <Box
                      className="uploadFilePreview uploadFilePreviewGreen"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Pan Card
                      </Typography>
                    </Box>
                    <Box
                      className="uploadFilePreview uploadFilePreviewRed"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Address Proof
                      </Typography>
                    </Box>
                    <Box
                      className="uploadFilePreview uploadFilePreviewGreen"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        ITR 2019
                      </Typography>
                    </Box>
                    <Box
                      className="uploadFilePreview uploadFilePreviewRed"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        ITR 2020
                      </Typography>
                    </Box>
                    <Box
                      className="uploadFilePreview uploadFilePreviewGreen"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Salary Slip March
                      </Typography>
                    </Box>
                    <Box
                      className="uploadFilePreview uploadFilePreviewRed"
                      mb={2}
                      onClick={(e) => setDocumentPreview(true)}
                    >
                      <MdOutlinePreview className="ImageViewIcon" />
                      <Typography variant="body2" className="docName">
                        Photo
                      </Typography>
                    </Box> */}
                  </Box>
                  {/* <Dialog
                    open={documentPreview}
                    onClose={(e) => setDocumentPreview(false)}
                    fullWidth
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6">Document Preview</Typography>
                      <IconButton onClick={(e) => setDocumentPreview(false)} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <img src={imageState} alt="Aadhar Front" className="" />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={(e) => setDocumentPreview(false)}
                        variant="contained"
                        className="mainBtn"
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog> */}
                </Box>
              </Grid>
            </Grid>
            {/* <Box className="formRowHolder">
              <Button variant="contained" className="mainBtn">
                Submit
              </Button>
            </Box> */}
          </Scrollbar>
          <Dialog open={documentPreview} onClose={(e) => setDocumentPreview(false)} fullWidth>
            <DialogTitle className="popupTitle">
              <Typography variant="h6">Document Preview</Typography>
              <IconButton onClick={(e) => setDocumentPreview(false)} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <FileViewer
                className="uploadeImgViewver"
                fileType={imageDocType}
                filePath={imageState}
              />
              {/* <img src={imageState} alt="" className="" /> */}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => setDocumentPreview(false)}
                variant="contained"
                className="mainBtn"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}
export default ViewApplicationSummary;
