import { Box, Stack, Container } from '@mui/material';
import React from 'react';
import Page from '../components/Page';
import PageTitle from '../components/PageHeading';

function PrivacyPolicyText() {
  return (
    <Page title="PULSE by Real Value Finloans || Privacy Policy">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Privacy Policy" />
          </Stack>
          <Box>
            <h4 className="mb-1 mt-2">
              DISCLAIMER: The use of this website or Application is subject to the terms and
              conditions stated below. By accessing this website, you agree to abide by these terms
              and conditions.
            </h4>
            <p>
              This Portal: https://www.pulse.realvalue.loans (PULSE) (includes its pages, extensions
              etc., but not limited to) and other as may be launched from time to time (hereinafter
              referred to as "Website”, “Platform" and/or “Application”, which shall mean and be
              used interchangeably having the same meaning) is developed, operated and maintained by
              Real Value Finloan Services Pvt. Ltd. ("The Company"), a firm/ LLP/ Partnership firm /
              company, having office at 401/4th Foor, Shivalaya Building, Above AU Small Finance
              Bank, Chandavarkar Road, Near.Ghanta Panwala, Borivali (W), Mumbai - 400092,
              Maharashtra. In the Privacy Policy, "we", "our" and "us" means "the company"; "you"
              and "your" means any person who accesses and uses this Platform; This Privacy Policy
              covers this Platform’s treatment of personally identifiable information that the
              company collects when you are on this platform and afterwards, and when you use this
              platform’s services. This policy also covers the company's treatment of any personally
              identifiable information (“PII”) that users share and/or the PII which the company may
              fetch/collect from various service providers with whom the company has integrated its
              systems to provide the necessary services to you. This policy does not apply to the
              practices of companies that the company does not own or control or to people that the
              company does not employ or manage.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>A. </strong>
              <strong>IN BRIEF</strong>
            </h4>
            <p>
              Protecting your privacy is at the highest importance for the Company, and we are
              committed to safeguarding it. This policy outlines how personal data we collect from
              you, or that you provide to us or that we may fetch/collect from various service
              providers with whom the company has integrated its systems, will be processed. Please
              read the following policy to understand the information we collect, how we will use
              that information and the circumstances where we will share it with third parties. This
              policy should be read together with our terms &amp; condition. If you use any of our
              Services, you will be regarded as having read and accepted this Policy. You must not
              use this platform or our Services if you do not accept this Policy. By mere use of
              this platform, you expressly consent to our use and disclosure of your personal
              information in accordance with this Policy. Our privacy policy is subject to change at
              any time without notice. To make sure you are aware of any changes, please review this
              policy periodically. Your consent to use the cookies in accordance with the terms of
              this policy when you first visit our website permits us to use cookies every time you
              visit our website. Further, your consent shall enable our browser extension feature to
              enable us to store your last visits, conduct data analytics etc.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>B. </strong>
              <strong>COLLECTION OF AND PROCESSING OF YOUR PERSONAL DATA</strong>
            </h4>
            <p>
              The use of this platform may result in the collection and processing of your personal
              data. The term "personal data" under data protection law refers to all information
              relating to a specific or personally identifiable person (PII). An IP address can also
              be considered personal data. An IP address is assigned to each device connected to the
              internet by the internet service provider so that it can send and receive data. When
              you use the platform, we collect data that you provide yourself. In addition, when you
              use the platform, we also automatically collect certain information about your use of
              it.
            </p>
            <p>
              We process personal data in compliance with the applicable data protection
              regulations. We will only process data where we are legally permitted to do so. When
              you use this platform, we will process personal data only with your consent, for the
              performance of a contract to which you are a party, or in order to take steps at your
              request prior to entering into a contract, for compliance with a legal obligation, or
              if the processing is necessary for the purposes of our legitimate interests or the
              legitimate interests of a third party, except where such interests are overridden by
              your interests or fundamental rights and freedom which require the protection of
              personal data.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>C. </strong>
              <strong>COLLECTING PERSONAL INFORMATION</strong>
            </h4>
            <p>
              We may collect the following personal information, including but not limited to the
              following:
            </p>
            <p>
              1. information about your computer including your IP address, geographical location,
              browser type and version, and operating system;
            </p>
            <p>
              2. information about your visits to and use of this website including the referral
              source, length of visit, page views, and website navigation paths;
            </p>
            <p>
              3. information, such as your email address, that you enter when you register with our
              website, to set up subscriptions to our emails and/or newsletters, etc.
            </p>
            <p>
              4. information such as your identity proofs, if required and submitted to site for
              legal or website purpose from time to time.
            </p>
            <p>
              5. information that you enter when you create a profile on our website for example,
              your name, profile pictures, gender, birthday, relationship status, interests and
              hobbies, educational details, institute details, employment details etc., which may be
              requested on portal from time to time;
            </p>
            <p>6. information that you enter while using the services on our website;</p>
            <p>
              7. information that is generated while using our website, including when, how often,
              and under what circumstances you use it;
            </p>
            <p>
              8. information relating to anything you purchase, services you use, or transactions
              you make through our website, which includes your name, address, telephone number,
              email address, and credit card details;
            </p>
            <p>
              9. information contained in any communications that you send to us by email or through
              our website, including its communication content, metadata and hashed data;
            </p>
            <p>
              10. Our application also provides option of referral and hence shall enable collection
              of referral email id, phone number etc. However, user entering such PII on behalf of
              the referral shall completely hold consent of the referral for such act and any
              liability that may arise on us for using such referral’s PII for marketing or
              otherwise, shall have to be completely indemnify us by you.
            </p>
            <p>
              Certain areas and features of the website are available to you without the need to
              provide us with any information. However, other features of the Site or the Service
              will require providing the website with details such as your name, email address,
              address, mobile number etc., personally identifying information, a username and a
              password (collectively the "Registration Information"). There are no user IDs &amp;
              passwords of any government or other institution asked on the platform. Any of your
              information that has been availed from any third party, basis on this consent, shall
              be used only in accordance with specific internal procedures as well as the statutory
              stipulations and safeguards governing access, in order to operate, develop or improve
              the service.
            </p>
            <p>
              Before you disclose to us the personal information of another person, you must obtain
              that person's consent to both the disclosure and the processing of that personal
              information in accordance with this policy. In case of any discrepancies and/or issues
              arising due to such “on behalf” submission, the submitter shall be liable and
              obligated under the then existing PII and other applicable rules and regulations.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>D. </strong>
              <strong>PROCESSING PERSONAL INFORMATION</strong>
            </h4>
            <p>
              The company and its partners collect and use your personal information and other
              information only as follows ("The purpose"):
            </p>
            <p>
              1. To send you any administrative notices, offer alerts and other Transactional
              Communications relevant to your use of the Service;
            </p>
            <p>
              2. To enable you to apply for certain products and services for which you have chosen
              to apply;
            </p>
            <p>
              3. For market research, project planning, troubleshooting issues, detecting and
              protecting against error, fraud or other criminal activity;
            </p>
            <p>
              4. To third-party contractors that provide services to the company and are bound by
              these same privacy restrictions &amp; confidentiality obligations;
            </p>
            <p>5. To enforce Company’s terms &amp; conditions;</p>
            <p>
              6. To use data in connection with legal claims, compliance, regulatory and
              investigative purposes as necessary (including disclosure of such information in
              connection with legal process or litigation);
            </p>
            <p>7. To analyze usage of the site and to improve the Service/s and security;</p>
            <p>8. To otherwise as set forth in this Privacy Policy.</p>
            <p>
              9. To perform various analytics for the betterment of the user experience/products
            </p>
            <p>
              10. To carry out obligations arising from any contracts entered into between the User
              and company and to provide User facilities and services as per Users interest.
            </p>
            <p>
              11. To provide User with information about other services, events and services that
              company offer that are similar to this User have already purchased or inquired about
              or other communications containing information about new services or upcoming events
              of the company, company’s affiliates and non-affiliated third parties such as
              societies and sponsors, if User has consented to receive this information.
            </p>
            <p>12. To notify the User about changes or updates to application’s User account.</p>
            <p>13. To respond to the Users to requests, inquiries, comments or concerns.</p>
            <p>
              14. To allow the User to participate in interactive features of our service when User
              chooses to do so
            </p>
            <h4 className="mb-1 mt-2">
              <strong>E. </strong>
              <strong>CONSENT TO OVERRIDE DND</strong>
            </h4>
            <p>
              By using the platform and its services, you specifically provide consent to the
              company to send you transaction, service explicit &amp; service implicit
              communications which are essential to continue the services like sending OTP for
              log-in, Status of Application etc., irrespective of your Do Not Disturb Registration
              with TRAI, DoT and/or any other Appropriate Authority. Similarly, you provide your
              explicit consent for sending across various communications on your registered email as
              well as phone number through various communication applications like WhatsApp as may
              be integrated with portal in future.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>F. </strong>
              <strong>SHARING OF PERSONAL DATA</strong>
            </h4>
            <p>
              As a policy we do not disclose any personal information to anyone other than those to
              our employees, officers, professional advisors', third party contractors, third party
              service providers as reasonably necessary for the purposes set out in this policy.
            </p>
            <p>
              We may disclose your personal information to any member of our group of companies
              (this means our subsidiaries, our ultimate holding company /ies and all its
              subsidiaries) as reasonably necessary for the purposes set out in this policy.
            </p>
            <p>
              There are several products and services that may be offered by third parties on our
              Site, (collectively, "Offers"). If you choose to apply for these separate products or
              services, disclose information to the providers, or grant them permission to collect
              information about you, then their use of your information is governed by their privacy
              policies and they will be acting as data controllers of your information. You should
              evaluate the practices of these external services providers and should view their
              privacy policies or contact them directly for further information before deciding to
              use their services. The company is not responsible for their privacy practices.
            </p>
            <p>
              Personal data may also be shared with third party service providers, who will process
              it on behalf of the Company for the purposes identified above subject to
              confidentially arrangements and strictly on need-to-know basis, these include:
            </p>
            <p>
              1. Payment collection services providers (Payment gateways and otherwise required for
              digital payment collection)
            </p>
            <p>2. Identity verification agencies</p>
            <p>3. Fraud prevention agencies</p>
            <p>4. Digital marketing and content delivery agencies</p>
            <p>5. Application service provider</p>
            <p>
              6. Any other service provider strictly required on need basis to fulfill the purpose
              of service delivery as envisaged for this portal (“Purpose”).
            </p>
            <p>
              For the purposes of Data Protection Law, the Company is a data controller of your
              personal data. You can get in touch with the Company by using the contact details
              mentioned in the contact us page.
            </p>
            <p>
              Notwithstanding the foregoing, the company reserves the right (and you authorize the
              company) to share or disclose your personal information when the company determines,
              in its sole discretion, that the disclosure of such information is necessary or
              appropriate:
            </p>
            <p>1. As required by law</p>
            <p>
              2. When we believe in good faith that disclosure is necessary to protect our rights,
              protect your safety or the safety of others, investigate fraud, or respond to a
              government request,
            </p>
            <p>3. in connection with any ongoing or prospective legal proceedings;</p>
            <p>
              4. If the company is involved in a merger, acquisition, or sale of all or a portion of
              its assets, you will be notified via email and/or a prominent notice on our Web site
              of any change in ownership or uses of your personal information; optionally, company
              may provide choices you may have regarding your personal information then.
            </p>
            <p>
              5. In the event that the business is sold or merged or integrated with another
              business/investor, your details will be disclosed to our advisers and any prospective
              purchaser's adviser and will be passed to the new owners/investors of and/or in the
              business.
            </p>
            <p>
              6. We may sell, license or distribute information in anonymized or aggregated form so
              that the information does not identify a specific user, without restriction,
              including, but not limited to, for producing data analytics and reports for business
              partners or other third parties.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>G. </strong>
              <strong>SECURITY OF YOUR PERSONAL INFORMATION</strong>
            </h4>
            <p>
              1. We will take reasonable technical and organizational precautions to prevent the
              loss, misuse, or alteration of your personal information. We follow generally accepted
              standards to protect the personal information submitted to us, both during
              transmission and once we receive it.
            </p>
            <p>
              2. We use a combination of firewalls, encryption techniques and authentication
              procedures, among others, to maintain the security of your online session and to
              protect the accounts and systems from unauthorized access.
            </p>
            <p>
              3. All electronic financial transactions entered into through our website will be
              protected by encryption technology.
            </p>
            <p>
              4. When you register for the Service, this platform requires a password from you for
              your privacy and security. The platform transmits information such as your
              Registration Information for the company/ this platform or Account Credentials
              securely.
            </p>
            <p>
              5. Our databases are protected from general employee access both physically and
              logically. We encrypt your Service password so that your password cannot be recovered,
              even by us.
            </p>
            <p>
              6. You acknowledge that no method of transmission over the Internet, or method of
              electronic storage, is 100% secure, however, therefore, we cannot guarantee its
              absolute security. If you have any questions about security on our Web site, you can
              contact us on the email provided in contact us segment on our website
            </p>
            <h4 className="mb-1 mt-2">
              <strong>H. </strong>
              <strong>DATA TRANSFERS</strong>
            </h4>
            <p>
              Presently the platform is operational across various countries and territories across
              globe. Information that we collect may be stored, processed in, and transferred
              between any of the countries in which we may operate in the future in order to enable
              us to use the information in accordance with this policy. You expressly agree to the
              transfers of personal information described in this Section. We hereby declare that
              the data localization is not practiced as the platform is independent of territories.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>I. </strong>
              <strong>USING YOUR INFORMATION FOR MARKETING PURPOSES</strong>
            </h4>
            <p>
              From time to time we may request more personal information in order to provide you
              with other benefits of the Service. The company may aggregate personal information and
              disclose such data in a manner to:
            </p>
            <p>1. Third parties for their marketing and promotional purposes;</p>
            <p>
              2. Users of the Service for the purpose of comparing with relative to the broader
              community.
            </p>
            <p>
              3. Such information may not identify you individually. We may also use third party
              service providers to help us provide the Service to you, such as sending e-mail
              messages on our behalf or hosting and operating a particular feature or functionality
              of the Service. Our contracts with these third parties outline the appropriate use and
              handling of your information and prohibit them from using any of your personal
              information for purposes unrelated to the product or service they're providing.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>J. </strong>
              <strong>RETENTION OF PERSONAL INFORMATION</strong>
            </h4>
            <p>We need your personal data in order to:</p>
            <p>- Provide our products and services to you.</p>
            <p>- Manage our business for our legitimate interest</p>
            <p>- Comply with legal obligations, if any</p>
            <p>
              You may choose not to share personal data or withdraw consent but doing so may limit
              the services we are able to provide to you. However, once you choose to withdraw the
              consent the personal data which has been shared, shall continue to remain with us for
              as long as the applicable law provides.
            </p>
            <p>
              We may retain your Personal Data for a period of time consistent with the purpose of
              collection. We determine the appropriate retention period for Personal Data on the
              basis of the amount, nature, and sensitivity of your Personal Data, the potential risk
              of harm from unauthorized use or disclosure, and whether we can achieve the purposes
              of the processing through other means, as well as the applicable legal requirements
              (such as applicable statutes of limitation). If there is any information that we are
              unable, for technical reasons, to delete entirely from our systems post appropriate
              retention period, we will put in place appropriate measures to prevent any further use
              of the data.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>K. </strong>
              <strong>CHANGES TO YOUR PERSONAL INFORMATION</strong>
            </h4>
            <p>
              If your personal Information provided when you applied for a product on platform,
              changes, you may update it whenever you apply for a new product. To review and update
              your personal information to ensure it is accurate while your application is in
              process
            </p>
            <p>
              We will retain your information for as long as your account is active or as needed to
              provide you services or as may be required for internal analysis purpose or as may be
              required by law, whichever is later. If you wish to cancel your account or request
              that we no longer use your information to provide you services, contact us at email
              provided in contact us section of website. We will retain and use your information as
              necessary to comply with our legal obligations, resolve disputes, and enforce our
              agreements.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>L. </strong>
              <strong>LOG FILES</strong>
            </h4>
            <p>
              As is true of most web sites, we gather certain information automatically and store it
              in log files. This information may include internet protocol (IP) addresses, browser
              type, internet service provider (ISP), referring/exit pages, operating system,
              date/time stamp, and/or clickstream data. We may combine this automatically collected
              log information with other information we collect about you. We do this to improve
              services we offer you, to improve marketing, analytics, or site functionality
            </p>
            <h4 className="mb-1 mt-2">
              <strong>M. </strong>
              <strong>TRACKING TECHNOLOGIES / COOKIES</strong>
            </h4>
            <p>
              If enabled, we may place cookies on your machine that store small amounts of data on
              your computer about your visit to any of the pages of this Website. Cookies are small
              electronic files that collect information when someone visits a website. They can
              identify the pages that are being viewed and this can assist us in tracking which of
              our features appeal the most to you and what content you may have viewed on past
              visits. Some cookies only exist whilst viewers are online, but 'persistent' cookies
              are not session-based and remain on the viewer's computer. When you visit this site
              again, 'persistent' cookies can enable us to customize our content according to your
              preferences.
            </p>
            <p>
              Technologies such as: cookies, beacons, tags and scripts are used by Company and our
              partner entities, affiliates, or analytics or service providers such as google Ad
              sense, google analytics etc. These technologies are used in analyzing trends,
              administering the site, tracking users' movements around the site and to gather
              demographic information about our user base as a whole.
            </p>
            <p>
              We may receive reports based on the use of these technologies by these companies on an
              individual as well as aggregated basis.
            </p>
            <p>
              We use cookies for and on our platform in order to personalize our service for you.
              Users can control the use of cookies at the individual browser level. If you reject
              cookies, you may still use our site, but your ability to use some features or areas of
              our site may be limited. You may refuse to accept cookies by activating the setting on
              your browser which allows you to refuse the setting of cookies. Unless you have
              adjusted your browser setting so that it will refuse cookies, our system will issue
              cookies when you log on to the Website.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>N. </strong>
              <strong>TESTIMONIALS, BLOGS, AND OTHER FORUMS ON THIS WEBSITE</strong>
            </h4>
            <p>
              With this consent of yours, we may post your testimonial along with your name. If you
              want your testimonial removed, please contact us at email mentioned in contact us on
              website.
            </p>
            <p>
              If you use a blog or other public forum on our Site, any information you submit there
              can be read, collected, or used by other users and could be used to send you
              unsolicited messages. We are not responsible for the personal information you choose
              to submit in these forums. These forums may be hosted by the Company by one of our
              third-party service providers on the Company's behalf. To request removal of your
              personal information from our blog or community forum, contact us on the email
              mentioned in contact us segment of website. In some cases, we may not be able to
              remove your personal information, in which case we will let you know if we are unable
              to do so and why.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>O. </strong>
              <strong>BEHAVIORAL TARGETING / RE-TARGETING</strong>
            </h4>
            <p>
              We may partner with a third party to either display advertising on our platform or to
              manage our advertising on other sites. Our third-party partner may use technologies
              such as cookies to gather information about your activities on this site and other
              sites in order to provide you advertising based upon your browsing activities and
              interests. There are a number of products and services that may be offered by third
              parties on our Site, (collectively, "Offers"). If you choose to apply for these
              separate products or services, disclose information to the providers, or grant them
              permission to collect information about you, then their use of your information is
              governed by their privacy policies and they will be acting as data controllers of your
              information. You should evaluate the practices of these external services providers
              and should view their privacy policies or contact them directly for further
              information before deciding to use their services. The Company is not responsible for
              their privacy practices.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>P. </strong>
              <strong>SINGLE SIGN-ON</strong>
            </h4>
            <p>
              In future, there may be options wherein you can log in to our site using sign-in
              services such as Facebook, Google or an Open ID provider. These services will
              authenticate your identity and provide you the option to share certain personal
              information with us such as your sign-in information, name and email address to link
              between the sites.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>Q. </strong>
              <strong>LIKE / SHARE BUTTON</strong>
            </h4>
            <p>
              If you use the "Like" / “Share” button to share something, that item will appear on
              your Social Media profile page and also on your friends' newsfeed, depending on your
              social media’s privacy settings. You may also receive updates in your social media
              account’s newsfeed from this site or item in the future. Such social media companies
              also collect information such as which pages you have visited on this and other sites
              that have implemented the "Like" button.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>R. </strong>
              <strong>LINKS TO THIRD PARTY SITES</strong>
            </h4>
            <p>
              Our Site includes links to other Web sites whose privacy practices may differ from
              those of the company. If you submit personal information to any of those sites, your
              information is governed by their privacy policies and they will be acting as data
              controllers of your information. We encourage you to carefully read the privacy policy
              of any Web site you visit.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>S. </strong>
              <strong>OPTING OUT</strong>
            </h4>
            <p>
              We provide our registered customers with periodic emailers and email/SMS alerts. We
              also allow users to unsubscribe to email newsletters and from time to time may
              transmit emails promoting the company or third-party goods or services. The company's
              subscribers may opt-out of receiving our promotional emails and terminate their
              newsletter subscriptions by following the instructions in the emails. Opting out in
              this manner will not end transmission of service-related emails/SMS, such as email/SMS
              alerts
            </p>
            <h4 className="mb-1 mt-2">
              <strong>T. </strong>
              <strong>YOUR RESPONSIBILITY</strong>
            </h4>
            <p>
              You shall not disclose to any other person, in any manner whatsoever, any information
              relating to the Company of a confidential nature obtained in the course of availing
              the services through the website of the Company. Failure to comply with this
              obligation shall be deemed a serious breach of the terms herein and shall entitle the
              Company to any damages, to which you may be entitled otherwise.
            </p>
            <p>
              If you have any complaints, security related concern, please contact nodal officer at
              the email mentioned on contact us segment on our website. We will work closely with
              you to ensure a rapid and personal response to your concerns.
            </p>
            <p>
              You are responsible for maintaining the confidentiality of your login id and password.
              You are responsible for maintaining the security of your Login ID and Password and may
              not provide these credentials to any third party. If you believe that they have been
              stolen or been made known to others, you must contact us immediately at
              <br />
              details mentioned in contact us segment of website. We are not responsible if someone
              else accesses your account through Registration Information they have obtained from
              you or through a violation by you of this Privacy and Security Policy or the Company’s
              Terms &amp; Conditions
            </p>
            <h4 className="mb-1 mt-2">
              <strong>U. </strong>
              <strong>CHANGES TO THE POLICY</strong>
            </h4>
            <p>
              We may update this Privacy Policy to reflect changes to our information practices. We
              encourage you to periodically review this page for the latest information on our
              privacy practices.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>V. </strong>
              <strong>CONTACT US WITH QUESTIONS OR CONCERNS</strong>
            </h4>
            <p>
              If you have questions, comments, concerns or feedback regarding this Privacy and
              Security Policy or any other privacy or security concern, send an e-mail to Nodal
              Officer, as per details mentioned in contact us segment of our website.
            </p>
            <p>
              This Policy shall be governed by and construed in accordance with the laws of the
              Republic of India and the courts at Mumbai, Maharashtra, India shall have sole and
              exclusive jurisdiction in relation to any disputes arising out of or in connection
              with this Policy.
            </p>
            <h4 className="mt-2 mb-1">
              <strong>X. IPR OWNERSHIP</strong>
            </h4>
            <p>
              You shall not copy, modify, transmit, distribute / re-distribute, reverse engineer
              etc., but not limited to, or in any way exploit the Products or any other copyrighted
              materials and content provided other than for your individual training. Any other
              purpose is expressly prohibited under these terms. You shall also not permit anyone
              else to copy, use, modify, transmit, distribute or in any way exploit the Products or
              any other copyrighted materials, trademark/ registered mark, the designs, innovations,
              business ideas etc., which together shall be known as IPR and the ownership of the
              same belongs to the company. Any infringement, theft etc., of the IPR of the company
              shall result in criminal proceedings against you at your risk, cost and consequences.
            </p>
            <p>
              You will not (and will not allow any third party to) attempt to copy, record or
              reverse engineer any Videos or Documentation supplied by Company or stored on the
              website (or related) websites, outside the application in any manner.
            </p>
            <h4 className="mt-2 mb-1">
              <strong>Y. </strong>
              <strong>Children’s Privacy</strong>
            </h4>
            <p>
              Our Services do not address anyone under the age of 18. We do not knowingly collect
              personal identifiable information from children under 18. In the case we discover that
              a child under 18 has provided us with personal information, we immediately delete this
              from our servers. If you are a parent or guardian and you are aware that your child
              has provided us with personal information, please contact us so that we will be able
              to do necessary actions. Users below 18 years of age are advised to use this platform/
              Services under guardian ship of their parent/s and/or legal guardian.
            </p>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default PrivacyPolicyText;
