import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
import partnerService from '../services/partnerService';
import leadsService from '../services/leadsService';
import Loader from '../Global/Loader';
//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';
import LeadsToolbar from './LeadsToolbar';
// import ProspectToolbar from './ProspectToolbar';

// ----------------------------------------------------------------------

const { LeadsList, ListStageStatus, AssignLeads, InternalSalesManager } = leadsService;
const { LoanTypes, CityList, SourceList } = partnerService;

const TABLE_HEAD = [
  { id: 'leadId', label: 'Lead ID', alignRight: false },
  { id: 'created', label: 'Lead Date', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'loanType', label: 'Loan Type', alignRight: false },
  { id: 'users', label: 'City', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'leadSource', label: 'Lead Source', alignRight: false },
  { id: 'statusLeads', label: 'status', alignRight: false },
  { id: 'applicantType', label: 'Applicant Type', alignRight: false },
  { id: 'created', label: 'Last update', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (user) => user.customerName.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
// }
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function Leads() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('created');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [token, setToken] = useState('');
  const [companyId, setCompanyId] = useState();
  const [userId, setUserId] = useState('');
  const [refresh, setRefresh] = useState('');
  const [leadsListResponse, setLeadsListResponse] = useState([]);
  const [searchTerms, setSearchTerms] = useState(' ');
  const [selectProduct, setSelectProduct] = useState(' '); // select product filter
  const [loanTypesList, setLoanTypesList] = useState([]); // select product data from api
  const [typeId, setTypeId] = useState(); // id from select product data api
  const [selectStatus, setSelectStatus] = useState(' '); // select status
  const [statusList, setStatusList] = useState([]); // select status data from api
  const [selectSource, setSelectSource] = useState(' '); // select source
  const [sourceListData, setSourceListData] = useState([]); // source list data from api
  const [salesPersonList, setSalesPersonList] = useState([]); // sales person list
  const [pageMenu, setPageMenu] = useState([]); // State for store localstorage data so that we can provide data to others components
  const [stateListMenu, setStateListMenu] = useState([]);
  const [cityListMenu, setCityListMenu] = useState([]);
  const [cityListData, setCityListData] = useState(null);
  const [refreshComp, setRefreshComp] = useState('');
  const [openLoader, setOpenLoader] = useState(false); // loader
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  // lead assign
  const [openAssign, setOpenAssign] = useState(false);
  const [assignValue, setAssignValue] = useState(' ');
  const [validationMsg, setValidationMsg] = useState('');
  const [alertMsg, setAlertMsg] = useState(false);

  const handleRequestSort = (event, property) => {
    if (property === 'created') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = leadsListResponse.map((n) => {
        console.log('newname', n);
        return n;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // source leads field
  const handleFilterByName = (event) => {
    // setSearchTerms(event.target.value);
    setFilterName(event.target.value);
  };

  // select product field
  const handleSelectProduct = (e) => {
    setSelectProduct(e.target.value);
  };

  // select status
  const handleSelectStatus = (e) => {
    setSelectStatus(e.target.value);
  };

  // select source
  const handleSelectSource = (e) => {
    setSelectSource(e.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leadsListResponse.length) : 0;

  // const filteredUsers = applySortFilter(
  //   leadsListResponse,
  //   getComparator(order, orderBy),
  //   filterName
  // );

  // const isUserNotFound = filteredUsers.length === 0;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'normal',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: 4
    }
  }));
  const handleClick = (event, item) => {
    const selectedIndex = selected.indexOf(item);
    // console.log('selectedIndex', selectedIndex);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // console.log('newSelected', newSelected);
    setSelected(newSelected);
  };

  const handleAssignSubmit = async (e) => {
    if (assignValue === ' ') {
      setAlertMsg(true);
      return true;
    }
    const newArray = [];
    selected.forEach((item) => {
      const obj = {
        leadId: item.leadId,
        stageId: 56,
        leadStageStatusId: item.leadStageStatusId,
        assignedTo: assignValue
      };
      newArray.push(obj);
    });
    const credential = {
      assignableLeads: newArray
    };

    const AssignLeadsApiResponse = await AssignLeads(credential, token);
    if (AssignLeadsApiResponse.msg) {
      if (AssignLeadsApiResponse.msg === 'Forbidden') {
        navigate('/');
      }
    }
    if (AssignLeadsApiResponse.status === 'success') {
      console.log('AssignLeadsApiResponse', AssignLeadsApiResponse);
      navigate('/leads');
      setAssignValue(' ');
      setOpenAssign(false);
      setRefreshComp(new Date());
      setSelected([]);
    }
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }
    console.log('localJWTtoken', localJWTtoken);
    const authToken1 = localJWTtoken.token.jwt;
    const authCompanyId = localJWTtoken.token.companyId;
    const authUserId = localJWTtoken.token.userId;
    (async () => {
      // select product api
      const LoanTypesApiResponse = await LoanTypes(authToken1);
      if (LoanTypesApiResponse.msg) {
        if (LoanTypesApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (LoanTypesApiResponse.status === 'success') {
        setLoanTypesList(LoanTypesApiResponse.data.loanTypes);
        setTypeId(LoanTypesApiResponse.data.loanTypes.typeId);
      }

      // select status api
      const credentials1 = {
        stageId: 56
      };
      const ListStageStatusApiResponse = await ListStageStatus(credentials1, authToken1);
      if (ListStageStatusApiResponse.msg) {
        if (ListStageStatusApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ListStageStatusApiResponse.status === 'success') {
        setStatusList(ListStageStatusApiResponse.data.leadsStageStatuses);
      }

      // select source
      const SourceListApiResponse = await SourceList(authToken1);
      if (SourceListApiResponse.msg) {
        if (SourceListApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (SourceListApiResponse.status === 'success') {
        setSourceListData(SourceListApiResponse.data.prospectSourceTypes);
      }

      // select internal sales manager
      const credentials2 = {
        companyId: authCompanyId
      };
      const InternalSalesManagerApiResponse = await InternalSalesManager(credentials2, authToken1);
      if (InternalSalesManagerApiResponse.msg) {
        if (InternalSalesManagerApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (InternalSalesManagerApiResponse.status === 'success') {
        const finalData = InternalSalesManagerApiResponse.data.salesPersons.filter(
          (item, index) => authUserId !== item.userId
        );
        setSalesPersonList(finalData);
        // setSalesPersonList(InternalSalesManagerApiResponse.data.salesPersons);
      }

      // select city  api for filter
      const CityListApiResponse = await CityList(
        {
          searchLiteral: '',
          stateId: null
        },
        authToken1
      );
      console.log('CityListApiResponse', CityListApiResponse);
      if (CityListApiResponse.action_status === 'success') {
        setCityListMenu(CityListApiResponse.data);
      } else {
        setCityListMenu([]);
      }
    })();
  }, []);

  useEffect(() => {
    // get meneu access for user from localstorage which store in <NavSection /> component
    const sidebarMenu = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d74');
    if (sidebarMenu !== null) {
      const bytes = CryptoJS.AES.decrypt(sidebarMenu, 'sidebarMenu');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      const pageCode = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d75');
      const pageCodeBytes = CryptoJS.AES.decrypt(pageCode, 'pageCode');
      const pageCodeText = pageCodeBytes.toString(CryptoJS.enc.Utf8);
      console.log('ciphertext', pageCodeText);
      JSON.parse(originalText).accessPagesMapping.forEach((accessPageMap) => {
        if (accessPageMap.pageCode === pageCodeText) {
          setPageMenu(accessPageMap.accessSubPagesMappings);
        }
      });
    }
    setOpenLoader(true);
    // get meneu access for user from localstorage which store in <NavSection /> component which is added by prashant
    console.log('pageMenu', pageMenu);
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }
    console.log('localJWTtoken', localJWTtoken);
    const authToken = localJWTtoken.token.jwt;
    setToken(authToken);
    const authCompanyId = localJWTtoken.token.companyId;
    setCompanyId(authCompanyId);
    const authUserId = localJWTtoken.token.userId;
    setUserId(authUserId);

    const credentials = {
      companyId: authCompanyId,
      userId: authUserId,
      stageId: 56,

      leadsFilterModel: {
        fromLeadDate: filterDatesRange[0].startDate,
        toLeadDate: filterDatesRange[0].endDate,
        leadStageStatusId: selectStatus !== ' ' ? selectStatus : null,
        sourceTypeId: selectSource !== ' ' ? selectSource : null,
        // cityId: stateListData.length < 0 ? stateListData : null,
        cityId: cityListData === null ? null : cityListData.cityId,
        loanId: selectProduct !== ' ' ? selectProduct : null
      },

      entityListRequestModel: {
        searchLiteral: filterName !== '' ? filterName : null,
        pageSize: 500,
        pageNumber: 1,
        sortOrder: order
      }
    };

    setTimeout(async () => {
      // leader list api
      const LeadsListApiResponse = await LeadsList(credentials, authToken);
      if (LeadsListApiResponse.msg) {
        if (LeadsListApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (LeadsListApiResponse.status === 'success') {
        setOpenLoader(false);
        setLeadsListResponse(LeadsListApiResponse.data.leads);
      }

      // // select product api
      // const LoanTypesApiResponse = await LoanTypes(authToken);
      // if (LoanTypesApiResponse.msg) {
      //   if (LoanTypesApiResponse.msg === 'Forbidden') {
      //     navigate('/');
      //   }
      // }
      // if (LoanTypesApiResponse.status === 'success') {
      //   setLoanTypesList(LoanTypesApiResponse.data.loanTypes);
      //   setTypeId(LoanTypesApiResponse.data.loanTypes.typeId);
      // }

      // // select status api
      // const credentials1 = {
      //   stageId: 56
      // };
      // const ListStageStatusApiResponse = await ListStageStatus(credentials1, authToken);
      // if (ListStageStatusApiResponse.msg) {
      //   if (ListStageStatusApiResponse.msg === 'Forbidden') {
      //     navigate('/');
      //   }
      // }
      // if (ListStageStatusApiResponse.status === 'success') {
      //   setStatusList(ListStageStatusApiResponse.data.leadsStageStatuses);
      // }

      // // select source
      // const SourceListApiResponse = await SourceList(authToken);
      // if (SourceListApiResponse.msg) {
      //   if (SourceListApiResponse.msg === 'Forbidden') {
      //     navigate('/');
      //   }
      // }
      // if (SourceListApiResponse.status === 'success') {
      //   setSourceListData(SourceListApiResponse.data.prospectSourceTypes);
      // }

      // // select internal sales manager
      // const credentials2 = {
      //   companyId: authCompanyId
      // };
      // const InternalSalesManagerApiResponse = await InternalSalesManager(credentials2, authToken);
      // if (InternalSalesManagerApiResponse.msg) {
      //   if (InternalSalesManagerApiResponse.msg === 'Forbidden') {
      //     navigate('/');
      //   }
      // }
      // if (InternalSalesManagerApiResponse.status === 'success') {
      //   setSalesPersonList(InternalSalesManagerApiResponse.data.salesPersons);
      // }

      // // select city  api for filter
      // const CityListApiResponse = await CityList(
      //   {
      //     searchLiteral: '',
      //     stateId: null
      //   },
      //   authToken
      // );
      // console.log('CityListApiResponse', CityListApiResponse);
      // if (CityListApiResponse.action_status === 'success') {
      //   setCityListMenu(CityListApiResponse.data);
      // } else {
      //   setCityListMenu([]);
      // }
    }, 1000);
  }, [
    refresh,
    filterName,
    selectProduct,
    selectStatus,
    selectSource,
    cityListData,
    filterDatesRange,
    refreshComp,
    order
  ]);
  return (
    <Page title="PULSE by Real Value Finloans || Leads">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Leads" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <LeadsToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              selectProduct={selectProduct}
              setSelectProduct={setSelectProduct}
              onFilterProduct={handleSelectProduct}
              loanTypesList={loanTypesList}
              selectStatus={selectStatus}
              onFilterStatus={handleSelectStatus}
              statusList={statusList}
              selectSource={selectSource}
              onFilterSource={handleSelectSource}
              sourceListData={sourceListData}
              parent="leads"
              selected={selected}
              salesPersonList={salesPersonList}
              token={token}
              pageMenu={pageMenu}
              stateListMenu={stateListMenu}
              filterDatesRange={filterDatesRange}
              setFilterDatesRange={setFilterDatesRange}
              cityListMenu={cityListMenu}
              cityListData={cityListData}
              setCityListData={setCityListData}
              setRefresh={setRefresh}
              onAssignSubmit={handleAssignSubmit}
              openAssign={openAssign}
              setOpenAssign={setOpenAssign}
              assignValue={assignValue}
              setAssignValue={setAssignValue}
              validationMsg={validationMsg}
              setValidationMsg={setValidationMsg}
              alertMsg={alertMsg}
              setAlertMsg={setAlertMsg}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={leadsListResponse.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {leadsListResponse !== undefined
                      ? leadsListResponse.map((item, index) => {
                          console.log(item);
                          const isItemSelected = selected.indexOf(item) !== -1;
                          return (
                            <StyledTableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, item)}
                                />
                              </TableCell>

                              <StyledTableCell align="left">
                                {item.leadId ? item.leadId : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {fDate(item.createdOn)}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.customerName ? item.customerName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.loanName ? item.loanName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.cityName ? item.cityName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.loanAmountRequested ? item.loanAmountRequested : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.sourceTypeName ? item.sourceTypeName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Label
                                  variant="ghost"
                                  // variant="filled"
                                  color={
                                    // (item.stageStatusName === 'New' && 'leadsnew') ||
                                    (item.stageStatusName === 'New' && 'greymsg') ||
                                    (item.stageStatusName === 'Contacted' && 'greymsg') ||
                                    (item.stageStatusName === null && 'error') ||
                                    (item.stageStatusName === 'Not Interested' && 'error') ||
                                    (item.stageStatusName === 'Not Contactable' && 'greymsg') ||
                                    (item.stageStatusName === 'Partial Complete' && 'greymsg') ||
                                    (item.stageStatusName === 'Completed' && 'success') ||
                                    (item.stageStatusName === 'Submitted' && 'success') ||
                                    'warning'
                                  }
                                >
                                  {sentenceCase(
                                    item.stageStatusName ? item.stageStatusName : 'null'
                                  )}
                                </Label>
                              </StyledTableCell>
                              <StyledTableCell align="left">Salaried</StyledTableCell>
                              <StyledTableCell align="left">
                                {fDate(item.leadDate) ? fDate(item.leadDate) : 'N/A'}
                              </StyledTableCell>

                              <StyledTableCell align="" width={115}>
                                <UserMoreMenu
                                  rowId={item.leadId}
                                  assignedTo={item.assignedTo}
                                  parent="leads"
                                  companyId={item.companyId}
                                  applicationId={item.applicationId}
                                  pageMenu={pageMenu}
                                  itemObj={item}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      : ''}
                    {/* {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const {
                          id,
                          amount,
                          name,
                          loanType,
                          statusLeads,
                          city,
                          leadSource,
                          leadId,
                          source,
                          created,
                          transactionType,
                          applicationType,
                          applicantType,
                          isVerified
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{leadId}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{leadSource}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (statusLeads === 'U-Doc' && 'error') ||
                                  (statusLeads === 'U-Log in' && 'error') ||
                                  (statusLeads === 'Contacted' && 'success') ||
                                  (statusLeads === 'Completed' && 'success') ||
                                  (statusLeads === 'Submitted' && 'success') ||
                                  'warning'
                                }
                              >
                                {sentenceCase(statusLeads)}
                              </Label>
                            </StyledTableCell>
                            <StyledTableCell align="left">{applicantType}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>

                            <StyledTableCell align="" width={115}>
                              <UserMoreMenu rowId={id} parent="leads" />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })} */}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {leadsListResponse.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          align="center"
                          colSpan={TABLE_HEAD.length + 1}
                          sx={{ py: 3 }}
                        >
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Card>
          <Loader openLoader={openLoader} />
        </Box>
      </Container>
    </Page>
  );
}
