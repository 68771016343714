import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  Divider,
  TextareaAutosize,
  Styled
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { BiCloudDownload } from 'react-icons/bi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
// import { StyledTableCell } from '../Global/Styling';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '4px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '3px 8px'
  }
}));

function InvoiceGeneratePayable() {
  const [loanInterested, setLoanInterested] = useState(' ');
  const [selectLeadSourceType, setSelectLeadSourceType] = useState(' ');
  const [selectProcessingFeeStatus, setSelectProcessingFeeStatus] = useState(' ');
  return (
    <Page title="PULSE by Real Value Finloans || Invoice">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Invoice Details" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Box className="topToolbar mb-2">
              <Tooltip title="Download" placement="top" arrow>
                <IconButton className="squareIconButton">
                  <BiCloudDownload size={18} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="viewLeadCard" mb={3}>
              <Box>
                <img src="../../static/logos/realValue-logo.png" alt="logo" height={40} />
              </Box>
              <Typography variant="h6" className="textCenter mb-2">
                Tax Invoice
              </Typography>
              <Box>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={6}>
                    <Box className="greenBorder tableBox" p={1} sx={{ height: '100%' }}>
                      <Typography variant="body2" className="mb-2">
                        REAL VALUE FINLOAN SERVICES PVT LTD
                      </Typography>
                      <Typography variant="body2" className="mb-1">
                        A-605, Lancelot Bldg, 6TH Floor, Opp Kalyan Jwellers, S V Road, Borivali
                        West- 400 092
                      </Typography>
                      <Typography variant="body2" className="mb-1">
                        Contact No: 9820143480
                      </Typography>
                      <Typography variant="body2" className="">
                        Email Add: prasad_fortune@yahoo.co.in
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="greenBorder tableBox" sx={{ height: '100%' }}>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>Date</StyledTableCell>
                              <StyledTableCell>9th Nov. 2021</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>Invoice No</StyledTableCell>
                              <StyledTableCell>KSPL/2122/009</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>DSA Code</StyledTableCell>
                              <StyledTableCell>DAA42100134</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>PAN No</StyledTableCell>
                              <StyledTableCell>AAFCR7597F</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>GST No</StyledTableCell>
                              <StyledTableCell>27AAFCR7597F1ZU</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>HSN Code</StyledTableCell>
                              <StyledTableCell />
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="greenBorder tableBox" p={1} sx={{ height: '100%' }}>
                      <Typography variant="subtitle2" className="mb-3">
                        Bill To
                      </Typography>
                      <Typography variant="body2">Kotak Mahindra Bank Limited,</Typography>
                      <Typography variant="body2">
                        Address: Address
                        <br />
                        Rajkot
                      </Typography>
                      <Typography variant="body2">GST No: 24AAACK4409J1ZQ</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="greenBorder tableBox" sx={{ height: '100%' }}>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>GST Number</StyledTableCell>
                              <StyledTableCell>AHRGF12346569799CS</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>Place of Supply</StyledTableCell>
                              <StyledTableCell>Delhi</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>Branch Code</StyledTableCell>
                              <StyledTableCell>RVL54233</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>State Code</StyledTableCell>
                              <StyledTableCell>011</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>Disbursal Month</StyledTableCell>
                              <StyledTableCell>June</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>Reverse Charges</StyledTableCell>
                              <StyledTableCell>5,899</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
                <Box className="greenBorder tableBox" mb={2}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Name of Borrower</StyledTableCell>
                          <StyledTableCell>Product</StyledTableCell>
                          <StyledTableCell>Reference Number</StyledTableCell>
                          <StyledTableCell>Disbursal Amt</StyledTableCell>
                          <StyledTableCell>Payout %</StyledTableCell>
                          <StyledTableCell>Dsa Payout Amt</StyledTableCell>
                          <StyledTableCell>CGST 9 %</StyledTableCell>
                          <StyledTableCell>SGST 9%</StyledTableCell>
                          <StyledTableCell>IGST 18%</StyledTableCell>
                          <StyledTableCell>Final Amt</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                          <StyledTableCell>&nbsp;</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell colSpan={3}>
                            <Typography variant="h6">Total</Typography>
                          </StyledTableCell>
                          <StyledTableCell>-</StyledTableCell>
                          <StyledTableCell>-</StyledTableCell>
                          <StyledTableCell>-</StyledTableCell>
                          <StyledTableCell>-</StyledTableCell>
                          <StyledTableCell>-</StyledTableCell>
                          <StyledTableCell>-</StyledTableCell>
                          <StyledTableCell>-</StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Grid container spacing={2} alignItems="end">
                  <Grid item xs={6}>
                    <Box className="tableBox greenBorder">
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell colSpan={2}>
                                <Typography variant="subtitle2" className="textCenter">
                                  Bank Details
                                  <br />
                                  Real Value Finloan Services Pvt Ltd
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>Bank Name &#38; Branch</StyledTableCell>
                              <StyledTableCell>&nbsp;</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>Account No.</StyledTableCell>
                              <StyledTableCell>&nbsp;</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>IFSC Code</StyledTableCell>
                              <StyledTableCell>&nbsp;</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" className="textCenter">
                      Authorized Signatory
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
export default InvoiceGeneratePayable;
