import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function AddEditMaster() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [selectCategory, setSelectCategory] = useState(' ');

  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };
  return (
    <Page title="PULSE by Real Value Finloans || Master">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info={rowId ? 'Edit Master' : 'Add New Master'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Fill Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="" mb={3}>
                  <Typography variant="h6" className="formLabel">
                    Master Name
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Master Name"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="" mb={3}>
                  <Typography variant="h6" className="formLabel">
                    Master Category
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${selectCategory}`
                    }}
                    value={selectCategory}
                    onChange={(e) => setSelectCategory(e.target.value)}
                  >
                    <MenuItem value=" " disabled>
                      Select Category
                    </MenuItem>
                    <MenuItem value="Menu 1">Menu 1</MenuItem>
                    <MenuItem value="Menu 2">Menu 2</MenuItem>
                  </Select>
                </Box>
              </Grid>
            </Grid>
            {masterValue.map((item, index) => (
              <Box key={`${item.masterValue}_${index}`} className="positionRel">
                <Grid container spacing={3} alignItems="end" mb={3}>
                  <Grid item xs>
                    <Typography variant="h6" className="formLabel">
                      Master Value
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Master Value"
                      value={item.masterValue}
                      onChange={(e) => {
                        const prevMaster = masterValue;
                        prevMaster[index] = e.target.value;
                        setMasterValue(prevMaster);
                        setRefreshComp(new Date());
                      }}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    {index < masterValue.length - 1 ? (
                      <Box className="" sx={{ marginBottom: '4px' }}>
                        <Tooltip title="Add more" placement="top" arrow>
                          <IconButton
                            className="squareIconButton"
                            onClick={(e) => removeMasterValue(index)}
                          >
                            <Icon icon={remove} width={18} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Box className="" sx={{ marginBottom: '4px' }}>
                        <Tooltip title="Add more" placement="top" arrow>
                          <IconButton
                            className="squareIconButton"
                            onClick={(e) => addMoreMasterValue(e)}
                          >
                            <Icon icon={add} width={18} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
          <Box className="" textAlign="right">
            <Button variant="contained" className="mainBtn">
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
export default AddEditMaster;
