import { useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { MdOutlineAdd, MdOutlineUpdate, MdModeEdit } from 'react-icons/md';
import { AiOutlineMinus, AiFillDelete, AiFillEye, AiFillCloseCircle } from 'react-icons/ai';
import { Box } from '@mui/system';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import Loader from '../Global/Loader';
import partnerService from '../services/partnerService';

const { LoanTypes, BankList, PartnerCommission, ShowCommission } = partnerService;

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));

function SetCommissionOnBoarding() {
  const navigate = useNavigate();
  const [loanInterested, setLoanInterested] = useState(' ');
  const [bankName, setBankName] = useState(' ');
  const [loanType, setLoanType] = useState(' ');
  const [loanTypeItem, setLoanTypeItem] = useState([]);
  const [bankListOpt, setBankListOpt] = useState([]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [commissionList, setCommissionList] = useState([]);
  // Validation and open dialog
  const [validationMsg, setValidationMsg] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editcomm, setEditcomm] = useState('');
  const [autosugval, setAutosugval] = useState({
    loanTypeId: '',
    companyCommition: '',
    partnerCommition: ''
  });

  const [getbankname, setGetbankname] = useState({ bankName: '', bankId: '' });
  const [openLoader, setOpenLoader] = useState(false); // loader

  console.log('commissionList', commissionList);

  const handleClose = () => {
    setDialogOpen(false);
  };

  // Fired API of Call Loan added by man 31052022
  useEffect(() => {
    (async () => {
      const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
      const token = localJWTtoken.token.jwt;
      const ifPreviewDataAvailable = await LoanTypes(token);
      // console.log('ifPreviewDataAvailable', ifPreviewDataAvailable);
      if (ifPreviewDataAvailable.status === 'success') {
        setLoanTypeItem(ifPreviewDataAvailable.data.loanTypes);
      }
      // Fired Api of Bank name
      const ifPreviewDataBank = await BankList(token);
      // console.log('ifPreviewDataBank', ifPreviewDataBank);
      if (ifPreviewDataBank.status === 'success') {
        ifPreviewDataBank.data.banks[0] = {
          bankId: 0,
          bankName: 'All'
        };
        setBankListOpt(ifPreviewDataBank.data.banks);
      }
      // Show set commission commission log on set commission page
      const companyId = localJWTtoken.token.companyId;
      const partnerId = JSON.parse(localStorage.getItem('partnerId'));
      const credential = {
        companyId: companyId,
        partnerId: partnerId,
        sortOrder: 'asc'
      };
      const ifPreviewShowCommission = await ShowCommission(credential, token);
      // console.log('ifPreviewShowCommission', ifPreviewShowCommission);
      if (ifPreviewShowCommission.status === 'success') {
        setCommissionList(ifPreviewShowCommission.data.commissions);
      }
    })();
  }, [refreshComp]);

  // Repeat Loan requirement
  const [partnerCommission, setPartnerCommission] = useState([
    {
      companyCommition: '',
      partnerCommition: '',
      bankId: '',
      bankName: '',
      loanTypeId: ' '
    }
  ]);
  const [value, setValue] = useState('');

  // Loan Requirement Array replica after clicking button of add button (Bank, Loan Type, company commision, partner share)
  const handleClickReplica = () => {
    const prevArray = partnerCommission;
    prevArray.push({
      companyCommition: '',
      partnerCommition: '',
      bankId: '',
      bankName: '',
      loanTypeId: ' '
    });
    setPartnerCommission(prevArray);
    setRefreshComp(new Date());
  };
  const handleClickRemove = () => {
    const prevArray = partnerCommission;
    prevArray.pop({
      companyCommition: '',
      partnerCommition: '',
      bankId: '',
      bankName: '',
      loanTypeId: ' '
    });
    setPartnerCommission(prevArray);
    setRefreshComp(new Date());
  };
  // Loan Requirement Array replica after clicking button of add button end

  const firedApiPartner = () => {
    if (partnerCommission.length > 0) {
      let isCond = true;
      partnerCommission.forEach((item, index) => {
        if (partnerCommission[index].bankId === '') {
          setDialogOpen(true);
          isCond = false;
          setValidationMsg('Please select bank name');
          return true;
        }
        if (partnerCommission[index].loanTypeId === ' ') {
          setDialogOpen(true);
          isCond = false;
          setValidationMsg('Please select loan type');
          return true;
        }
        if (partnerCommission[index].companyCommition === '') {
          setDialogOpen(true);
          isCond = false;
          setValidationMsg('Type company commission %');
          return true;
        }
        if (partnerCommission[index].partnerCommition === '') {
          setDialogOpen(true);
          isCond = false;
          setValidationMsg('Type partner share %');
          return true;
        }

        if (
          partnerCommission[index].companyCommition !== '' &&
          partnerCommission[index].partnerCommition !== ''
        ) {
          const total =
            Number(partnerCommission[index].companyCommition) +
            Number(partnerCommission[index].partnerCommition);

          if (total > 100) {
            setDialogOpen(true);
            isCond = false;
            setValidationMsg(
              'Sum of Company Commission & Partner Share should not be greater then 100%'
            );
            partnerCommission[index].companyCommition = '';
            partnerCommission[index].partnerCommition = '';
            return true;
          }
        }
      });

      if (isCond) {
        setOpenLoader(true);
        (async () => {
          const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
          const token = localJWTtoken.token.jwt;
          const partnerId = JSON.parse(localStorage.getItem('partnerId'));
          const credential = {
            companyId: localJWTtoken.token.companyId,
            partnerId: partnerId,
            partnerCommissions: partnerCommission
          };

          const ifFiredApiPartner = await PartnerCommission(credential, token);
          if (ifFiredApiPartner.status === 'success') {
            setOpenLoader(false);
            setRefreshComp(new Date());
            setPartnerCommission([
              {
                companyCommition: '',
                partnerCommition: '',
                bankId: '',
                bankName: '',
                loanTypeId: ' '
              }
            ]);

            // navigate('/onboarding');
          }
          // setCommissionList(ifPreviewShowCommission);
        })();
      }
    }

    // (async () => {
    //   const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    //   const token = localJWTtoken.token.jwt;
    //   const partnerId = JSON.parse(localStorage.getItem('partnerId'));
    //   const credential = {
    //     companyId: localJWTtoken.token.companyId,
    //     partnerId: partnerId,
    //     partnerCommissions: partnerCommission
    //   };

    //   const ifFiredApiPartner = await PartnerCommission(credential, token);
    //   if (ifFiredApiPartner.status === 'success') {
    //     setRefreshComp(new Date());
    //     navigate('/onboarding');
    //   }
    //   // setCommissionList(ifPreviewShowCommission);
    // })();
  };

  const firedApiPartnerUpdate = (commitionId, index) => {
    let isCond = true;
    if (
      commissionList[index].companyCommition !== '' &&
      commissionList[index].partnerCommition !== ''
    ) {
      const total = Number(autosugval.companyCommition) + Number(autosugval.partnerCommition);
      if (total > 100) {
        setDialogOpen(true);
        isCond = false;
        setValidationMsg(
          'Sum of Company Commission & Partner Share should not be greater then 100%'
        );
        autosugval.companyCommition = 0;
        autosugval.partnerCommition = 0;
        return true;
      }
    }

    if (isCond) {
      setOpenLoader(true);
      const obj = { ...autosugval, ...getbankname };
      console.log('obj', obj);
      (async () => {
        const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
        const token = localJWTtoken.token.jwt;
        const partnerId = JSON.parse(localStorage.getItem('partnerId'));
        const credential = {
          companyId: localJWTtoken.token.companyId,
          partnerId: partnerId,
          partnerCommissions: [
            {
              companyCommition: obj.companyCommition,
              partnerCommition: obj.partnerCommition,
              bankId: obj.bankId,
              loanTypeId: obj.loanTypeId,
              commitionId: commitionId
            }
          ]
        };
        const ifFiredApiPartner = await PartnerCommission(credential, token);
        if (ifFiredApiPartner.status === 'success') {
          setOpenLoader(false);
          setRefreshComp(new Date());
          removecommission();
          // setValue('');
        }
      })();
    }
  };
  // const updatecommission = (commitionId) => {
  //   const obj = { ...autosugval, ...getbankname };
  //   console.log(commitionId, obj);
  // };

  const editcommission = (data) => {
    const result = bankListOpt.filter((item, key) => item.bankId === data.bankId)[0];
    setEditcomm(data.commitionId);
    setGetbankname({ bankName: result.bankName, bankId: result.bankId });
    setAutosugval({
      loanTypeId: data.loanTypeId,
      companyCommition: data.companyCommition,
      partnerCommition: data.partnerCommition
    });
  };

  const removecommission = () => {
    setEditcomm('');
    setAutosugval({});
    setGetbankname({});
  };

  return (
    <Page title="PULSE by Real Value Finloans || On Boarding">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Set Commission" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Loan Requirement
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            {partnerCommission.map((item, index) => (
              <Grid container alignItems="end" key={index} mb={2}>
                <Grid item xs>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Bank
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo2"
                          value={partnerCommission[index].bankName}
                          // value={value !== undefined ? value : ''}
                          inputValue={value}
                          onInputChange={(e, newInputValue) => setValue(newInputValue)}
                          options={bankListOpt}
                          getOptionLabel={(option) =>
                            option.bankName ? option.bankName : partnerCommission[index].bankName
                          }
                          size="small"
                          renderInput={(params) => (
                            <TextField {...params} fullWidth placeholder="Select Bank Name" />
                          )}
                          onChange={(e, newValue) => {
                            console.log('newValue', newValue);
                            const prevArray = partnerCommission;
                            prevArray[index].bankId = newValue.bankId;
                            prevArray[index].bankName = newValue.bankName;
                            // setValue(newValue);
                            setPartnerCommission(prevArray);
                            console.log('prevArray', prevArray);
                            setRefreshComp(new Date());
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Loan Type
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${item.loanTypeId}`
                          }}
                          value={item.loanTypeId}
                          onChange={(e) => {
                            // console.log('newValue', e.target.value);
                            const prevArray = partnerCommission;
                            prevArray[index].loanTypeId = e.target.value;
                            setPartnerCommission(prevArray);
                            setRefreshComp(new Date());
                          }}
                        >
                          <MenuItem value=" " disabled>
                            Select Loan Type
                          </MenuItem>
                          {loanTypeItem.map((item, index) => (
                            <MenuItem value={item.loanId} key={index}>
                              {item.loanName}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Company Commission
                        </Typography>
                        <TextField
                          fullWidth
                          type="number"
                          inputProps={{
                            className: 'textInput'
                          }}
                          value={item.companyCommition}
                          placeholder="Type Company Commission %"
                          onChange={(e) => {
                            // if (!/^[0-9\s-.']+$/.test(e.target.value)) {
                            //   return;
                            // }
                            // console.log('e.target.value', e.target.value);
                            const prevArray = partnerCommission;
                            // prevArray[index].companyCommition = JSON.parse(e.target.value);
                            prevArray[index].companyCommition = e.target.value;
                            setPartnerCommission(prevArray);
                            setRefreshComp(new Date());
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Partner Share
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TextField
                            fullWidth
                            type="number"
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Type Partner Share %"
                            value={item.partnerCommition}
                            onChange={(e) => {
                              // if (!/^[0-9]+$/.test(e.target.value)) {
                              //   return;
                              // }
                              // console.log('e.target.value', e.target.value);
                              const prevArray = partnerCommission;
                              // prevArray[index].partnerCommition = JSON.parse(e.target.value);
                              prevArray[index].partnerCommition = e.target.value;
                              setPartnerCommission(prevArray);
                              setRefreshComp(new Date());
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs="auto">
                  <Tooltip
                    title="Add"
                    placement="top"
                    arrow
                    sx={{ marginLeft: '1rem', marginBottom: '4px' }}
                  >
                    <IconButton className="squareIconButton" onClick={(e) => handleClickReplica()}>
                      <MdOutlineAdd size={18} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {partnerCommission.length > 1 && (
                  <Grid item xs="auto">
                    <Tooltip
                      title="Remove"
                      placement="top"
                      arrow
                      sx={{ marginLeft: '1rem', marginBottom: '4px' }}
                    >
                      <IconButton className="squareIconButton" onClick={(e) => handleClickRemove()}>
                        <AiOutlineMinus size={18} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>
          <Box className="" mb={3} textAlign="right">
            <Button variant="contained" className="mainBtn" onClick={(e) => firedApiPartner()}>
              Submit & Close
            </Button>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Commission Log
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin viewCardPadding">
            <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ width: '220px' }}>Bank</StyledTableCell>
                    <StyledTableCell style={{ width: '230px' }} align="">
                      Loan Type
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '230px' }} align="">
                      Our Commission
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '230px' }} align="">
                      Partner Share
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '180px' }} align="">
                      Modified on
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '120px' }} align="">
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commissionList.map((item, index) => {
                    console.log('item', item);
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="">
                          {editcomm !== item.commitionId ? (
                            <>
                              {bankListOpt.filter(
                                (bankObj) =>
                                  bankObj.bankId === item.bankId && bankObj.bankId !== 'null'
                              ).length > 0
                                ? bankListOpt.filter(
                                    (bankObj) =>
                                      bankObj.bankId === item.bankId && bankObj.bankId !== 'null'
                                  )[0].bankName
                                : ''}
                              {/* {
                                bankListOpt.filter((bankObj) => bankObj.bankId === item.bankId)[0]
                                  .bankName
                              } */}
                            </>
                          ) : (
                            <Box className="">
                              <Autocomplete
                                value={getbankname.bankName}
                                id="combo-box-demo2"
                                options={bankListOpt}
                                getOptionLabel={(option) =>
                                  option.bankName ? option.bankName : getbankname.bankName
                                }
                                size="small"
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth placeholder="Select Bank Name" />
                                )}
                                sx={{
                                  background: '#fff'
                                }}
                                onChange={(e, newValue) => {
                                  console.log('newValue', newValue);
                                  setGetbankname({
                                    bankName: newValue.bankName,
                                    bankId: newValue.bankId
                                  });
                                  // const prevArray = partnerCommission;
                                  // prevArray[index].bankId = newValue.bankId;
                                  // setPartnerCommission(prevArray);
                                  // setRefreshComp(new Date());
                                }}
                              />
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="">
                          {editcomm !== item.commitionId ? (
                            <>
                              {/* {console.log('loanTypeItem1', loanTypeItem)}
                          {console.log('loanTypeItem1', item)} */}
                              {loanTypeItem.filter(
                                (bankObj) =>
                                  bankObj.loanId === item.loanTypeId && bankObj.loanId !== null
                              ).length > 0
                                ? loanTypeItem.filter(
                                    (bankObj) =>
                                      bankObj.loanId === item.loanTypeId && bankObj.loanId !== null
                                  )[0].loanName
                                : ''}
                              {/* {
                            bankListOpt.filter((bankObj) => bankObj.typeId === item.typeId)[0]
                              .loanName
                          } */}
                            </>
                          ) : (
                            <Box className="">
                              <Select
                                fullWidth
                                inputProps={{
                                  className: `textInput customSelectBox dissabledMenu${autosugval.loanTypeId}`
                                }}
                                value={autosugval.loanTypeId}
                                onChange={(e) => {
                                  // console.log('newValue', e.target.value);
                                  // const prevArray = partnerCommission;
                                  // prevArray[index].loanTypeId = e.target.value;
                                  // setPartnerCommission(prevArray);
                                  setAutosugval({ ...autosugval, loanTypeId: e.target.value });
                                  // setRefreshComp(new Date());
                                }}
                              >
                                <MenuItem value=" " disabled>
                                  Select Loan Type
                                </MenuItem>
                                {loanTypeItem.map((item, index) => (
                                  <MenuItem value={item.loanId} key={index}>
                                    {item.loanName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="">
                          {editcomm !== item.commitionId ? (
                            `${item.companyCommition}`
                          ) : (
                            <Box className="">
                              <TextField
                                fullWidth
                                type="number"
                                inputProps={{
                                  className: 'textInput'
                                }}
                                value={autosugval.companyCommition}
                                placeholder="Type Company Commission %"
                                onChange={(e) => {
                                  // if (!/^[0-9\s-.']+$/.test(e.target.value)) {
                                  //   return;
                                  // }
                                  setAutosugval({
                                    ...autosugval,
                                    companyCommition: e.target.value
                                  });
                                  // console.log('e.target.value', e.target.value);
                                  // const prevArray = partnerCommission;
                                  // prevArray[index].companyCommition = JSON.parse(e.target.value);
                                  // setPartnerCommission(prevArray);
                                  // setRefreshComp(new Date());
                                }}
                              />
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="">
                          {editcomm !== item.commitionId ? (
                            `${item.partnerCommition}`
                          ) : (
                            <Box className="">
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                  fullWidth
                                  type="number"
                                  inputProps={{
                                    className: 'textInput'
                                  }}
                                  placeholder="Type Partner Share %"
                                  value={autosugval.partnerCommition}
                                  onChange={(e) => {
                                    // if (!/^[0-9]+$/.test(e.target.value)) {
                                    //   return;
                                    // }
                                    setAutosugval({
                                      ...autosugval,
                                      partnerCommition: e.target.value
                                    });
                                    // console.log('e.target.value', e.target.value);
                                    // const prevArray = partnerCommission;
                                    // prevArray[index].partnerCommition = JSON.parse(e.target.value);
                                    // setPartnerCommission(prevArray);
                                    // setRefreshComp(new Date());
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="">NA</StyledTableCell>
                        {editcomm !== item.commitionId ? (
                          <StyledTableCell>
                            <Tooltip title="Edit" placement="top" arrow>
                              <StyledIconButton
                                onClick={() => editcommission(item)}
                                className="squareIconButton actionButton"
                              >
                                <MdModeEdit size={14} />
                              </StyledIconButton>
                            </Tooltip>
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell>
                            <Tooltip title="Remove" placement="top" arrow>
                              <StyledIconButton
                                onClick={removecommission}
                                className="squareIconButton actionButton"
                              >
                                <AiFillCloseCircle size={14} />
                              </StyledIconButton>
                            </Tooltip>
                            <Tooltip title="Update" placement="top" arrow>
                              <StyledIconButton
                                // onClick={() => updatecommission(item.commitionId)}
                                className="squareIconButton actionButton"
                                onClick={(e) => firedApiPartnerUpdate(item.commitionId, index)}
                              >
                                <MdOutlineUpdate size={14} />
                              </StyledIconButton>
                            </Tooltip>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Retry</Button>
            </DialogActions>
          </Dialog>
          <Loader openLoader={openLoader} />
        </Box>
      </Container>
    </Page>
  );
}
export default SetCommissionOnBoarding;

// Top Banks
const topBanks = [
  { label: 'Axis Bank' },
  { label: 'HDFC Bank' },
  { label: 'HDFC Ltd.' },
  { label: 'ICICI Bank' },
  { label: 'PNB Housing' },
  { label: 'Piramal Housing' },
  { label: 'Standard Chartered Bank' },
  { label: 'Yes Bank' },
  { label: 'Aditya Birla Housing Finance' },
  { label: 'IDFC Bank' },
  { label: 'RBL Bank' },
  { label: 'Federal Bank' },
  { label: 'Fedfina' },
  { label: 'HDB Financial' },
  { label: 'IIFL Finance' },
  { label: 'Tata Capital' },
  { label: 'ICICI Home Finance' },
  { label: 'Cholamandalam Finance' },
  { label: 'L&T Finance' },
  { label: 'Karur Vysya Bank' },
  { label: 'Hero Housing Finance' },
  { label: 'Kotak Mahindra Bank' },
  { label: 'Fullerton Grihashakti' },
  { label: 'Anand Rathi Financial' },
  { label: 'Aadhar Housing Finance' },
  { label: 'Godrej Housing Finance' },
  { label: 'Deutsche Bank' },
  { label: 'Jana Small Finance Bank' },
  { label: 'HSBC Bank' },
  { label: 'DCB Bank' },
  { label: 'Bank of Baroda' },
  { label: 'Bajaj Housing Finance' },
  { label: 'LIC Housing Finance' },
  { label: 'AU Small Finance Bank' },
  { label: 'Union Bank of India' },
  { label: 'State Bank of India' }
];
