import React, { useState } from 'react';
import {
  Step,
  Stepper,
  StepLabel,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  Tab,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  DialogTitle,
  FormLabel,
  FormControlLabel,
  Card
} from '@mui/material';
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';
import { IoIosAlert } from 'react-icons/io';

// table component
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ImFolderUpload } from 'react-icons/im';
import { MdOutlinePreview, MdUploadFile, MdUpload, MdOutlineAdd } from 'react-icons/md';
import { BiCloudUpload } from 'react-icons/bi';
import { DiGoogleDrive } from 'react-icons/di';
import { AiOutlineDropbox } from 'react-icons/ai';
import { CgDigitalocean } from 'react-icons/cg';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

// import { CloseIcon } from 'react-icons'
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import pages
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));
function ViewJourney(parent) {
  const navigate = useNavigate();
  return (
    <Page title="Partner Management System || Agreement">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="View Agreement" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Box className="submitLeadToolbar">
            <Tooltip
              title="Edit"
              placement="top"
              arrow
              onClick={(e) => navigate(`/agreement/edit`)}
            >
              <IconButton className="squareIconButton">
                <FiEdit size={18} />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Bank Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid item container spacing={{ xs: 1, sm: 2, md: 3 }} marginBottom={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  Bank Name
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  HDFC
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  Address
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  Ramesh Nagar, New Delhi
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  PAN number
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  AHVBGF24653S
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  GSTIN
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  GST12453687
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  Commission Structure
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  Bank
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  Turnover Benefit
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  Yes
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Branch Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Branch Name</StyledTableCell>
                    <StyledTableCell align="">Branch Address</StyledTableCell>
                    <StyledTableCell align="">Contact Person</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="">HDFC</StyledTableCell>
                    <StyledTableCell align="">Ramesh Vihar, New Delhi</StyledTableCell>
                    <StyledTableCell align="">Ramesh</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Commission Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Loan Type</StyledTableCell>
                    <StyledTableCell align="left">Branch</StyledTableCell>
                    <StyledTableCell align="left">Commission %</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left">Home loan</StyledTableCell>
                    <StyledTableCell align="left">HDFC Ramesh Vihar</StyledTableCell>
                    <StyledTableCell align="left">5%</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Turnover Benefit
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin">
            <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Loan Type</StyledTableCell>
                    <StyledTableCell align="left">Branch</StyledTableCell>
                    <StyledTableCell align="left">Duration</StyledTableCell>
                    <StyledTableCell align="left">Commission %</StyledTableCell>
                    <StyledTableCell align="left">Unit</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left">Home Loan</StyledTableCell>
                    <StyledTableCell align="left">HDFC Ramesh Vihar</StyledTableCell>
                    <StyledTableCell align="left">10 Yr</StyledTableCell>
                    <StyledTableCell align="left">5%</StyledTableCell>
                    <StyledTableCell align="left">10</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default ViewJourney;
