import React, { useEffect, useState } from 'react';
import {
  Step,
  Stepper,
  StepLabel,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  Tab,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  DialogTitle,
  FormLabel,
  FormControlLabel,
  Card,
  Autocomplete
} from '@mui/material';
import { Box } from '@mui/system';
import { Document, pdfjs, Page as Pages } from 'react-pdf';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';
import { IoIosAlert } from 'react-icons/io';

// table component
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ImFolderUpload } from 'react-icons/im';
import {
  MdOutlinePreview,
  MdUploadFile,
  MdUpload,
  MdOutlineAdd,
  MdNotListedLocation
} from 'react-icons/md';
import { BiCloudUpload } from 'react-icons/bi';
import { DiGoogleDrive } from 'react-icons/di';
import { AiOutlineDropbox, AiOutlineMinus } from 'react-icons/ai';
import { CgDigitalocean } from 'react-icons/cg';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

// import { CloseIcon } from 'react-icons'
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import pages
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

import partnerService from '../services/partnerService';
import AgreementService from '../services/AgreementService';
import IndividualCommission from './IndividualCommission';
import VolumeCommission from './VolumeCommission';
import { UserMoreMenu } from '../../components/_dashboard/user';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { StateList, CityList, BankList, LoanTypes, BankBranchList } = partnerService;
const {
  SaveBankInfo,
  SaveBranchInfo,
  SaveCommissionInfo,
  UploadDocument,
  Edit,
  BankListAgreement
} = AgreementService;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));
function AddAgreement() {
  const { rowId } = useParams();
  const location = useLocation();
  console.log('location', location);
  const [activeStep, setActiveStep] = useState(0);
  const [reviewData, setReviewData] = useState({});
  const [EditData, setEditData] = useState(null);
  const [aggrementIdEdit, setAggrementIdEdit] = useState(null);

  console.log('reviewData44', EditData);

  const handleNext = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  // edit agreement part
  useEffect(async () => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const authToken = localJWTtoken.token.jwt;
    const localJWTtoken2 = JSON.parse(localStorage.getItem('viewAgreementId'));
    setAggrementIdEdit(localJWTtoken2);

    const authReviewData = JSON.parse(localStorage.getItem('reviewData'));
    if (authReviewData !== null) {
      setReviewData(authReviewData);
    }

    if (location.pathname === '/agreement/edit' && authReviewData === null) {
      const credentials = {
        aggrementId: localJWTtoken2,
        companyId: localJWTtoken.token.companyId,
        userId: localJWTtoken.token.userId
      };
      const EditApiResponse = await Edit(credentials, authToken);
      if (EditApiResponse.status === 'success') {
        setEditData(EditApiResponse.data);
      }
    }
  }, []);
  useEffect(() => {
    console.log('reviewData4', reviewData);
  }, [reviewData]);
  return (
    <Page title="Partner Managemet System || Agreement">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            {/* <PageTitle info="Add New Agreement" /> */}
            {location.pathname === '/agreement/add' ? (
              <PageTitle info="Add New Agreement" />
            ) : (
              <PageTitle info="Edit Agreement" />
            )}
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Stepper
            connector=""
            activeStep={activeStep}
            sx={{ marginBottom: '25px' }}
            className="stepWizardOuter"
          >
            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Add Bank</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Add Branch</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Add Commission</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Upload Agreement</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Review</StepLabel>
            </Step>
          </Stepper>
          {activeStep === 0 ? (
            <Step1Content
              handleNext={handleNext}
              setActiveStep={setActiveStep}
              reviewData={reviewData}
              setReviewData={setReviewData}
              EditData={EditData}
              setEditData={setEditData}
              location={location}
              aggrementIdEdit={aggrementIdEdit}
            />
          ) : activeStep === 1 ? (
            <Step2Content
              handleNext={handleNext}
              handlePrev={handlePrev}
              setActiveStep={setActiveStep}
              reviewData={reviewData}
              setReviewData={setReviewData}
              EditData={EditData}
              setEditData={setEditData}
              location={location}
            />
          ) : activeStep === 2 ? (
            <Step3Content
              handleNext={handleNext}
              handlePrev={handlePrev}
              setActiveStep={setActiveStep}
              reviewData={reviewData}
              setReviewData={setReviewData}
              EditData={EditData}
              setEditData={setEditData}
              location={location}
            />
          ) : activeStep === 3 ? (
            <Step4Content
              handleNext={handleNext}
              handlePrev={handlePrev}
              setActiveStep={setActiveStep}
              reviewData={reviewData}
              setReviewData={setReviewData}
              EditData={EditData}
              setEditData={setEditData}
              location={location}
            />
          ) : (
            <Step5Content
              handlePrev={handlePrev}
              reviewData={reviewData}
              EditData={EditData}
              setEditData={setEditData}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
}

function Step1Content({
  handleNext,
  setActiveStep,
  reviewData,
  setReviewData,
  EditData,
  setEditData,
  location,
  aggrementIdEdit
}) {
  const navigate = useNavigate();
  const [selectCommissionStructure, setSelectCommissionStructure] = useState(' ');
  const [selectTurnoverBenefit, setSelectTurnoverBenefit] = useState(' ');
  const [selectLoginType, setSelectLoginType] = useState(' ');
  const [bankNameValue, setBankNameValue] = useState(null);
  const [inputBankName, setInputBankName] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [gstinNumber, setGstinNumber] = useState('');
  const [loginCode, setLoginCode] = useState('');
  const [open, setOpen] = useState(false); // dialog box for error handling
  const [validationMsg, setValidationMsg] = useState(''); // validation message
  const [token1, setToken1] = useState('');
  const [userDetails1, setUserDetails1] = useState({});
  const [bankListData, setBankListData] = useState([]);
  const [applicationId, setApplicationId] = useState(null);
  const [bankIdReview, setBankIdReview] = useState(null);
  const [bankIdEdit, setBankIdEdit] = useState(null);
  const [refreshComp, setRefreshComp] = useState(null);
  const [agreementIdReview, setAgreementIdReview] = useState(null);

  console.log('EditData1', EditData);
  console.log('location1', location);
  console.log('selectCommissionStructure', selectCommissionStructure);
  console.log('bankNameValue', bankNameValue);

  const LoginType = {
    DSA: {
      typeName: 'DSA',
      typeId: '62'
    },
    DST: {
      typeName: 'DST',
      typeId: '64'
    }
  };

  const CommissionType = {
    BRANCH: {
      typeName: 'BRANCH',
      typeId: '68'
    },
    BANK: {
      typeName: 'BANK',
      typeId: '66'
    }
  };

  const TurnoverBenefit = {
    YES: {
      typeName: 'YES',
      typeId: 'true'
    },
    NO: {
      typeName: 'NO',
      typeId: 'false'
    }
  };

  // closing dialog box of error handling
  const handleClose = () => {
    setOpen(false);
  };

  const handleNextStep1 = (e) => {
    const panformat = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // PAN number regex pattern
    const gstinformat = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/; // GSTIN regex pattern

    if (bankNameValue === null) {
      setOpen(true);
      setValidationMsg('Please select bank name');
      return;
    }
    if (selectLoginType === ' ') {
      setOpen(true);
      setValidationMsg('Please select login type');
      return;
    }
    if (!panformat.test(panNumber)) {
      setOpen(true);
      setValidationMsg('Please add valid PAN number');
      setPanNumber('');
      return;
    }
    if (!gstinformat.test(gstinNumber)) {
      setOpen(true);
      setValidationMsg('Please add valid GSTIN number');
      setGstinNumber('');
      return;
    }
    if (selectCommissionStructure === ' ') {
      setOpen(true);
      setValidationMsg('Please select Commission Structure');
      return;
    }
    if (selectTurnoverBenefit === ' ') {
      setOpen(true);
      setValidationMsg('Please select Turnover Benefit');
      return;
    }
    if (loginCode === '') {
      setOpen(true);
      setValidationMsg('Please select login code');
      return;
    }
    (async () => {
      const credential1 = {
        bankCommissionTypeId: Number(JSON.parse(selectCommissionStructure).typeId),
        // bankId:
        //   location.pathname === '/agreement/add'
        //     ? bankNameValue !== undefined
        //       ? bankNameValue.bankId
        //       : null
        //     : bankIdEdit,
        bankId:
          location.pathname === '/agreement/add' && Object.keys(reviewData).length === 0
            ? bankNameValue !== undefined
              ? bankNameValue.bankId
              : null
            : bankNameValue.bankId,

        // bankLoginTypeId: selectLoginType,

        // bankIdReview
        // aggrementId: location.pathname === '/agreement/add' ? null : Number(aggrementIdEdit),

        aggrementId:
          location.pathname === '/agreement/add' && Object.keys(reviewData).length === 0
            ? null
            : location.pathname === '/agreement/add' && Object.keys(reviewData).length > 0
            ? agreementIdReview
            : Number(aggrementIdEdit),

        bankLoginTypeId: Number(JSON.parse(selectLoginType).typeId),
        companyId: userDetails1.companyId,
        gstin: gstinNumber,
        loginCode: loginCode,
        panNumber: panNumber,
        // turnoverBenefit: JSON.parse(selectTurnoverBenefit).typeId,
        turnoverBenefit: JSON.parse(selectTurnoverBenefit).typeId === 'true' ? true : false,
        userId: userDetails1.userId
      };
      const SaveBankInfoApiResponse = await SaveBankInfo(credential1, token1);
      if (SaveBankInfoApiResponse.status === 'success') {
        // setApplicationId(SaveBankInfoApiResponse.data.agreementId);
        // localStorage.setItem('agreementId', SaveBankInfoApiResponse.data.agreementId);
        setApplicationId(SaveBankInfoApiResponse.data);
        localStorage.setItem('agreementId', JSON.stringify(SaveBankInfoApiResponse.data));
        setActiveStep((activeStep) => activeStep + 1);

        setReviewData({
          ...reviewData,
          bankDetails: {
            bankName: bankNameValue !== undefined ? bankNameValue : null,
            bankAddress: null,
            panNumber: panNumber,
            gstin: gstinNumber,
            bankCommissionTypeName: JSON.parse(selectCommissionStructure),
            turnoverBenefit: JSON.parse(selectTurnoverBenefit),
            loginType: JSON.parse(selectLoginType),
            loginCode: loginCode
          },
          agreementId: SaveBankInfoApiResponse.data.agreementId
        });

        localStorage.setItem(
          'reviewData',
          JSON.stringify({
            ...reviewData,
            bankDetails: {
              bankName: bankNameValue !== undefined ? bankNameValue : null,
              bankAddress: null,
              panNumber: panNumber,
              gstin: gstinNumber,
              bankCommissionTypeName: JSON.parse(selectCommissionStructure),
              turnoverBenefit: JSON.parse(selectTurnoverBenefit),
              loginType: JSON.parse(selectLoginType),
              loginCode: loginCode
            },
            agreementId: SaveBankInfoApiResponse.data.agreementId
          })
        );
        setRefreshComp(new Date());
      }
    })();
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const authToken = localJWTtoken.token.jwt;
    setToken1(authToken);
    const authUserDetails = localJWTtoken.token;
    setUserDetails1(authUserDetails);

    (async () => {
      const ifPreviewDataBank = await BankListAgreement(
        {
          companyId: localJWTtoken.token.companyId
        },
        authToken
      );
      if (ifPreviewDataBank.status === 'success') {
        setBankListData(ifPreviewDataBank.data.banks);
      } else if (ifPreviewDataBank.status === 'error') {
        localStorage.clear();
        navigate('/');
      }
    })();

    const authReviewData = JSON.parse(localStorage.getItem('reviewData'));
    if (authReviewData !== null) {
      setReviewData(authReviewData);
      console.log('authReviewData12', authReviewData);
      setAgreementIdReview(authReviewData.agreementId);
      setBankNameValue(authReviewData.bankDetails.bankName);
      setBankIdEdit(authReviewData.bankDetails.bankName.bankId);
      setSelectLoginType(JSON.stringify(authReviewData.bankDetails.loginType));
      setPanNumber(authReviewData.bankDetails.panNumber);
      setGstinNumber(authReviewData.bankDetails.gstin);
      setSelectCommissionStructure(
        JSON.stringify(authReviewData.bankDetails.bankCommissionTypeName)
      );
      setSelectTurnoverBenefit(JSON.stringify(authReviewData.bankDetails.turnoverBenefit));
      setLoginCode(authReviewData.bankDetails.loginCode);
    }

    if (location.pathname === '/agreement/edit' && authReviewData === null) {
      console.log('EditData1', EditData);
      if (EditData !== null) {
        // setBankNameValue(EditData.bankDetails.bankName);
        // setBankIdEdit(EditData.bankId);

        const BankName = {
          bankName: EditData.bankDetails.bankName,
          bankId: EditData.bankId
        };
        setBankNameValue(BankName);

        if (EditData.bankDetails.bankLoginTypeName === 'DSA') {
          const LoginType = {
            typeName: 'DSA',
            typeId: '62'
          };
          setSelectLoginType(JSON.stringify(LoginType));
        } else {
          const LoginType = {
            typeName: 'DST',
            typeId: '64'
          };
          setSelectLoginType(JSON.stringify(LoginType));
        }
        setPanNumber(EditData.bankDetails.panNumber);
        setGstinNumber(EditData.bankDetails.gstIn);

        if (EditData.bankDetails.bankCommissionTypeName === 'Branch') {
          const CommissionType = {
            typeName: 'BRANCH',
            typeId: '68'
          };
          setSelectCommissionStructure(JSON.stringify(CommissionType));
        } else {
          const CommissionType = {
            typeName: 'BANK',
            typeId: '66'
          };
          setSelectCommissionStructure(JSON.stringify(CommissionType));
        }

        if (EditData.bankDetails.turnoverBenefit === true) {
          const TurnoverBenefit = {
            typeName: 'YES',
            typeId: 'true'
          };
          setSelectTurnoverBenefit(JSON.stringify(TurnoverBenefit));
        } else {
          const TurnoverBenefit = {
            typeName: 'NO',
            typeId: 'false'
          };
          setSelectTurnoverBenefit(JSON.stringify(TurnoverBenefit));
        }

        setLoginCode(EditData.bankDetails.bankLoginCode);
      }
    }
  }, [EditData]);
  return (
    <>
      <Box className="viewLeadCard">
        <Grid container spacing={3}>
          {location.pathname === '/agreement/add' && Object.keys(reviewData).length === 0 ? (
            <Grid item xs={4}>
              <Box className="">
                <Typography variant="h6" className="formLabel">
                  Bank Name
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo2"
                  options={bankListData}
                  size="small"
                  getOptionLabel={(option) => option.bankName}
                  renderOption={(props, option) => <li {...props}>{option.bankName}</li>}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth placeholder="Select Bank Name" />
                  )}
                  value={bankNameValue}
                  onChange={(e, newValue) => {
                    console.log('newValue22', newValue);
                    setBankNameValue(newValue);
                  }}
                  inputValue={inputBankName}
                  onInputChange={(_, v) => setInputBankName(v)}
                />
              </Box>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Box className="">
                <Typography variant="h6" className="formLabel">
                  Bank Name
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo2"
                  options={bankListData}
                  size="small"
                  getOptionLabel={(option) => option.bankName}
                  renderOption={(props, option) => <li {...props}>{option.bankName}</li>}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth placeholder="Select Bank Name" />
                  )}
                  value={bankNameValue}
                  onChange={(e, newValue) => {
                    console.log('newValue22', newValue);
                    setBankNameValue(newValue);
                  }}
                  disabled
                  inputValue={inputBankName}
                  onInputChange={(_, v) => setInputBankName(v)}
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Login Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectLoginType}`
                }}
                // value={selectLoginType.typeName}
                value={selectLoginType}
                onChange={(e) => {
                  setSelectLoginType(e.target.value);
                  console.log('e.target.value', e.target.value);
                }}
              >
                <MenuItem value=" " disabled>
                  Select Login Type
                </MenuItem>
                {/* <MenuItem value={LoginType.DSA.typeId}>DSA</MenuItem>
                <MenuItem value={LoginType.DST.typeId}>DST</MenuItem> */}
                <MenuItem value={JSON.stringify(LoginType.DSA)}>DSA</MenuItem>
                <MenuItem value={JSON.stringify(LoginType.DST)}>DST</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                PAN No
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type PAN No"
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                GSTIN
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type GSTIN"
                value={gstinNumber}
                onChange={(e) => setGstinNumber(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Commission Type
              </Typography>
              <Select
                fullWidth
                // value={selectCommissionStructure.typeName}
                value={selectCommissionStructure}
                onChange={(e) => {
                  setSelectCommissionStructure(e.target.value);
                  console.log('e.target.value', e.target.value);
                }}
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectCommissionStructure}`
                }}
              >
                <MenuItem value=" " disabled>
                  Select Commission Structure
                </MenuItem>
                {/* <MenuItem value={CommissionType.BRANCH.typeId}>Branch</MenuItem>
                <MenuItem value={CommissionType.BANK.typeId}>Bank</MenuItem> */}
                <MenuItem value={JSON.stringify(CommissionType.BRANCH)}>Branch</MenuItem>
                <MenuItem value={JSON.stringify(CommissionType.BANK)}>Bank</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Turnover Benefit
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectTurnoverBenefit}`
                }}
                // value={selectTurnoverBenefit.typeName}
                value={selectTurnoverBenefit}
                onChange={(e) => setSelectTurnoverBenefit(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Turnover Benefit
                </MenuItem>
                <MenuItem value={JSON.stringify(TurnoverBenefit.YES)}>Yes</MenuItem>
                <MenuItem value={JSON.stringify(TurnoverBenefit.NO)}>No</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                DSA Code
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type DSA Code"
                value={loginCode}
                onChange={(e) => setLoginCode(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          // onClick={handleNext}
          onClick={(e) => handleNextStep1(e)}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Retry</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Step2Content({
  handleNext,
  handlePrev,
  setActiveStep,
  reviewData,
  setReviewData,
  EditData,
  setEditData,
  location
}) {
  const [selectBranch, setSelectBranch] = useState(' ');
  const [branchEdit, setBranchEdit] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [open1, setOpen1] = useState(false); // dialog box for error handling
  const [validationMsg1, setValidationMsg1] = useState(''); // validation message
  const [branchName, setBranchName] = useState('');
  const [branchAddress, setBranchAddress] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [stateListData, setStateListData] = useState([]);
  const [cityListData, setCityListData] = useState([]);
  const [stateListValue, setStateListValue] = useState(null);
  const [cityListValue, setCityListValue] = useState(null);
  const [token, setToken] = useState('');
  const [refreshComp, setRefreshComp] = useState(null);
  const [value, setValue] = useState('');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [branchInfoData, setBranchInfoData] = useState({});
  const [branchesStep2, setBranchesStep2] = useState([]);
  const [branchIndexValue, setBranchIndexValue] = useState(null);
  const [itemBranchIndexValue, setitemBranchIndexValue] = useState({});
  const [addBranchObj, setAddBranchObj] = useState({
    branchName: '',
    branchId: null,
    branchAddress: '',
    contactPerson: '',
    stateId: '',
    stateName: '',
    cityId: '',
    cityName: '',
    // loginCode1: '',
    branchCode: ''
  });
  const [addBranchObjEdit, setAddBranchObjEdit] = useState({
    branchName: '',
    branchId: null,
    branchAddress: '',
    contactPerson: '',
    stateId: '',
    stateName: '',
    cityId: '',
    cityName: '',
    // loginCode1: '',
    branchCode: ''
  });
  const [branchList, setBranchList] = useState([]);
  console.log('branchListMain', branchList);

  // closing dialog box of error handling
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleAddBranch = (e) => {
    if (addBranchObj.branchName === '') {
      setOpen1(true);
      setValidationMsg1('Please select branch name');
      return;
    }
    if (addBranchObj.branchAddress === '') {
      setOpen1(true);
      setValidationMsg1('Please select branch address');
      return;
    }
    if (addBranchObj.contactPerson === '') {
      setOpen1(true);
      setValidationMsg1('Please add contact person');
      return;
    }
    if (addBranchObj.stateName === '') {
      setOpen1(true);
      setValidationMsg1('Please select state name');
      return;
    }
    if (addBranchObj.cityName === '') {
      setOpen1(true);
      setValidationMsg1('Please select city name');
      return;
    }
    if (addBranchObj.branchCode === '') {
      setOpen1(true);
      setValidationMsg1('Please add login code');
      return;
    }
    const newArray = branchList;
    newArray.push(addBranchObj);
    setBranchList(newArray);
    setAddBranchObj({
      branchName: '',
      branchAddress: '',
      contactPerson: '',
      stateId: '',
      stateName: '',
      cityId: '',
      cityName: '',
      // loginCode1: '',
      branchCode: ''
    });
    setRefreshComp(new Date());
  };

  // edit branch in row
  const handleAddBranchEdit = (e) => {
    const newArray = branchList;

    const index = newArray.indexOf(itemBranchIndexValue);

    console.log('branchIndex3', index);

    if (index === branchIndexValue) {
      newArray[index] = addBranchObjEdit;
    }
    console.log('newArray', newArray);
    branchEditDialogClose(e);
    setRefreshComp(new Date());
  };

  const branchEditDialogOpen = (e, itemBranchIndex, branchIndex) => {
    console.log('itemBranchIndex', itemBranchIndex);
    console.log('itemBranchIndex', branchIndex);
    setBranchEdit(true);
    setAddBranchObjEdit({
      branchName: itemBranchIndex ? itemBranchIndex.branchName : '',
      branchId: itemBranchIndex ? itemBranchIndex.branchId : '',
      branchAddress: itemBranchIndex ? itemBranchIndex.branchAddress : '',
      contactPerson: itemBranchIndex ? itemBranchIndex.contactPerson : '',
      stateId: itemBranchIndex ? itemBranchIndex.stateId : '',
      stateName: itemBranchIndex ? itemBranchIndex.stateName : '',
      cityId: itemBranchIndex ? itemBranchIndex.cityId : '',
      cityName: itemBranchIndex ? itemBranchIndex.cityName : '',
      // loginCode1: '',
      branchCode: itemBranchIndex ? itemBranchIndex.branchCode : ''
    });
    setBranchIndexValue(branchIndex);
    setitemBranchIndexValue(itemBranchIndex);
  };

  const branchEditDialogClose = (e) => {
    setBranchEdit(false);
  };

  const handleNextStep2 = async (e) => {
    const localJWTtoken = JSON.parse(localStorage.getItem('agreementId'));
    const authAgreementId = localJWTtoken.agreementId;
    const authBankId = localJWTtoken.bankId;
    const authCompanyId = localJWTtoken.companyId;
    const authUserId = localJWTtoken.userId;

    if (branchList.length === 0) {
      setOpen1(true);
      setValidationMsg1('Please Add bank branch');
      return;
    }

    const credentials2 = {
      aggrementId: authAgreementId,
      bankId: authBankId,
      branches: branchList.length > 0 ? branchList : null,
      companyId: authCompanyId,
      userId: authUserId
    };
    const SaveBranchInfoApi = await SaveBranchInfo(credentials2, token);
    console.log('SaveBranchInfoApi', SaveBranchInfoApi);
    if (SaveBranchInfoApi.status === 'success') {
      setBranchInfoData(SaveBranchInfoApi.data);
      // localStorage.setItem('agreementId', JSON.stringify(SaveBankInfoApiResponse.data));
      setActiveStep((activeStep) => activeStep + 1);
      setReviewData({
        ...reviewData,
        branchDetails: branchList
      });

      localStorage.setItem(
        'reviewData',
        JSON.stringify({
          ...reviewData,
          branchDetails: branchList
        })
      );
    }
    // } else {
    //   setActiveStep((activeStep) => activeStep + 1);
    //   setReviewData({
    //     ...reviewData,
    //     branchDetails: branchList
    //   });
    //   localStorage.setItem(
    //     'reviewData',
    //     JSON.stringify({
    //       ...reviewData,
    //       branchDetails: branchList
    //     })
    //   );
    // }

    // setActiveStep((activeStep) => activeStep + 1);
  };

  const getCityListApi = async (e, newValue) => {
    const CityListApiResponse = await CityList(
      {
        searchLiteral: '',
        stateId: newValue.stateId ? newValue.stateId : null
      },
      token
    );
    console.log('CityListApiResponse', CityListApiResponse);
    if (CityListApiResponse.action_status === 'success') {
      setCityListData(CityListApiResponse.data);
    } else {
      setCityListData([]);
    }
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const authToken = localJWTtoken.token.jwt;
    setToken(authToken);
    // setUserDetailsData(localJWTtoken.token);

    const localJWTtoken2 = JSON.parse(localStorage.getItem('agreementId'));
    const authAgreementId = localJWTtoken2.agreementId;
    const authBankId = localJWTtoken2.bankId;
    const authCompanyId = localJWTtoken2.companyId;
    const authUserId = localJWTtoken2.userId;

    (async () => {
      // state list api
      const StateCredentials = {
        countryId: 101,
        searchLiteral: ''
      };

      const StateListApiResponse = await StateList(StateCredentials, authToken);
      console.log('StateListApiResponse', StateListApiResponse);
      if (StateListApiResponse.action_status === 'success') {
        setStateListData(StateListApiResponse.data);
      } else {
        setStateListData([]);
      }

      const BankBranchListApi = await BankBranchList(
        {
          bankId: authBankId,
          pageSize: 100,
          pageNumber: 1,
          sortOrder: 'asc'
        },
        authToken
      );
      console.log('BankBranchListApi', BankBranchListApi);
      if (BankBranchListApi.status === 'success') {
        setBranchesStep2(BankBranchListApi.data.branches);
      } else {
        setBranchesStep2([]);
      }
    })();
    let authReviewData = '';
    setTimeout(() => {
      authReviewData = JSON.parse(localStorage.getItem('reviewData'));
      if (authReviewData !== null) {
        console.log('authReviewData1', authReviewData);
        if (authReviewData.branchDetails) {
          setBranchList(authReviewData.branchDetails);
        }
      }
      console.log('EditData121', authReviewData !== null ? authReviewData.branchDetails : 'hi');
    }, 500);

    if (location.pathname === '/agreement/edit' && authReviewData.branchDetails === undefined) {
      console.log('EditData12', EditData);
      if (EditData !== null) {
        console.log('EditData1', EditData.branches);
        // setBranchList(EditData.branches);

        const arData = [];
        EditData.branches.forEach((item, index) => {
          const branches1 = {
            branchName: item.branchName,
            branchId: null,
            branchAddress: item.branchAddress,
            contactPerson: item.contactPersonName,
            stateId: item.stateId,
            stateName: null,
            cityId: item.cityId,
            cityName: null,
            branchCode: item.branchCode
          };
          arData.push(branches1);
        });
        if (arData.length > 0) {
          setBranchList(arData);
        }

        console.log('arData', arData);
      }
    }
  }, [EditData]);

  return (
    <>
      <Box className="viewLeadCard" mb={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Branch Name
              </Typography>
              <Autocomplete
                disablePortal
                id="branchName1"
                options={branchesStep2}
                size="small"
                value={addBranchObj.branchName}
                inputValue={addBranchObj.branchName}
                onInputChange={(e, newInputValue) => {
                  const prevArray = addBranchObj;
                  prevArray.branchName = newInputValue;
                  prevArray.branchId = null;
                  setAddBranchObj(prevArray);
                  setRefreshComp(new Date());
                  console.log('newInputValue', newInputValue);
                }}
                onChange={(e, newValue) => {
                  console.log('newval3', newValue);
                  const prevArray = addBranchObj;
                  prevArray.branchName = newValue.branchName;
                  prevArray.branchId = newValue.branchId;
                  setAddBranchObj(prevArray);
                  setRefreshComp(new Date());
                }}
                getOptionLabel={(option) =>
                  option.branchName ? option.branchName : addBranchObj.branchName
                }
                renderOption={(props, option) => <li {...props}>{option.branchName}</li>}
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select Branch Name" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Branch Address
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Bank Address"
                value={addBranchObj.branchAddress}
                onChange={(e) => {
                  const prevArray = addBranchObj;
                  prevArray.branchAddress = e.target.value;
                  setAddBranchObj(prevArray);
                  setRefreshComp(new Date());
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Contact Person
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Contact Person"
                value={addBranchObj.contactPerson}
                onChange={(e) => {
                  const prevArray = addBranchObj;
                  prevArray.contactPerson = e.target.value;
                  setAddBranchObj(prevArray);
                  setRefreshComp(new Date());
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                State
              </Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo1"
                options={stateListData}
                size="small"
                value={addBranchObj.stateName}
                inputValue={value}
                onInputChange={(e, newInputValue) => setValue(newInputValue)}
                onChange={(e, newValue) => {
                  console.log('newValue', newValue);
                  getCityListApi(e, newValue);
                  const prevArray = addBranchObj;
                  prevArray.stateId = newValue.stateId;
                  prevArray.stateName = newValue.stateName;
                  setAddBranchObj(prevArray);
                  setRefreshComp(new Date());
                }}
                getOptionLabel={(option) =>
                  option.stateName ? option.stateName : addBranchObj.stateName
                }
                renderOption={(props, option) => <li {...props}>{option.stateName}</li>}
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select State" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                City
              </Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo2"
                options={cityListData}
                size="small"
                value={addBranchObj.cityName}
                inputValue={value1}
                onInputChange={(e, newInputValue) => setValue1(newInputValue)}
                onChange={(e, newValue) => {
                  const prevArray = addBranchObj;
                  prevArray.cityId = newValue.cityId;
                  prevArray.cityName = newValue.cityName;
                  setAddBranchObj(prevArray);
                  setRefreshComp(new Date());
                }}
                getOptionLabel={(option) =>
                  option.cityName ? option.cityName : addBranchObj.cityName
                }
                renderOption={(props, option) => <li {...props}>{option.cityName}</li>}
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select City" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Branch Code
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Branch Code"
                value={addBranchObj.branchCode}
                onChange={(e) => {
                  const prevArray = addBranchObj;
                  prevArray.branchCode = e.target.value;
                  setAddBranchObj(prevArray);
                  setRefreshComp(new Date());
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box textAlign="right">
          <Button
            className="mainBtn otherBtn"
            variant="contained"
            onClick={(e) => handleAddBranch(e)}
          >
            Add Branch
          </Button>
        </Box>
      </Box>
      <Box className="greenBorder tableBox" mt={3}>
        <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Branch Name</StyledTableCell>
                <StyledTableCell align="">Branch Address</StyledTableCell>
                <StyledTableCell align="">Contact Person</StyledTableCell>
                <StyledTableCell align="" width={80}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {branchList && branchList.length > 0
                ? branchList.map((item, index) => {
                    console.log(item);
                    return (
                      <StyledTableRow>
                        <StyledTableCell align="">{item.branchName}</StyledTableCell>
                        <StyledTableCell align="">{item.branchAddress}</StyledTableCell>
                        <StyledTableCell align="">{item.contactPerson}</StyledTableCell>
                        <StyledTableCell align="">
                          <UserMoreMenu
                            parent="add-branch-agreement"
                            setBranchEdit={setBranchEdit}
                            setDeleteConfirm={setDeleteConfirm}
                            itemBranchIndex={item}
                            branchIndex={index}
                            branchEditDialogOpen={branchEditDialogOpen}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                : ''}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          fullWidth
          className="PopupBox"
          open={branchEdit}
          // onClose={(e) => setBranchEdit(false)}
          onClose={(e) => branchEditDialogClose(e)}
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Edit Details</Typography>
            <IconButton onClick={(e) => branchEditDialogClose(e)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Branch Name
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="branchName2"
                    options={branchesStep2}
                    size="small"
                    value={addBranchObjEdit.branchName}
                    inputValue={addBranchObjEdit.branchName}
                    onInputChange={(e, newInputValue) => {
                      const prevArray = addBranchObjEdit;
                      prevArray.branchName = newInputValue;
                      prevArray.branchId = null;
                      setAddBranchObjEdit(prevArray);
                      setRefreshComp(new Date());
                      console.log('newInputValue', newInputValue);
                    }}
                    onChange={(e, newValue) => {
                      console.log('newval3', newValue);
                      const prevArray = addBranchObjEdit;
                      prevArray.branchName = newValue.branchName;
                      prevArray.branchId = newValue.branchId;
                      setAddBranchObjEdit(prevArray);
                      setRefreshComp(new Date());
                    }}
                    getOptionLabel={(option) =>
                      option.branchName ? option.branchName : addBranchObjEdit.branchName
                    }
                    renderOption={(props, option) => <li {...props}>{option.branchName}</li>}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth placeholder="Select Branch Name" />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Branch Address
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Bank Address"
                    value={addBranchObjEdit.branchAddress}
                    onChange={(e) => {
                      const prevArray = addBranchObjEdit;
                      prevArray.branchAddress = e.target.value;
                      setAddBranchObjEdit(prevArray);
                      setRefreshComp(new Date());
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Contact Person
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Contact Person"
                    value={addBranchObjEdit.contactPerson}
                    onChange={(e) => {
                      const prevArray = addBranchObjEdit;
                      prevArray.contactPerson = e.target.value;
                      setAddBranchObjEdit(prevArray);
                      setRefreshComp(new Date());
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    State
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo3"
                    options={stateListData}
                    size="small"
                    value={addBranchObjEdit.stateName}
                    // inputValue={value}
                    // onInputChange={(e, newInputValue) => setValue(newInputValue)}
                    onChange={(e, newValue) => {
                      console.log('newValue', newValue);
                      getCityListApi(e, newValue);
                      const prevArray = addBranchObjEdit;
                      prevArray.stateId = newValue.stateId;
                      prevArray.stateName = newValue.stateName;
                      setAddBranchObjEdit(prevArray);
                      setRefreshComp(new Date());
                    }}
                    getOptionLabel={(option) =>
                      option.stateName ? option.stateName : addBranchObjEdit.stateName
                    }
                    renderOption={(props, option) => <li {...props}>{option.stateName}</li>}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth placeholder="Select State" />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    City
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo3"
                    options={cityListData}
                    size="small"
                    value={addBranchObjEdit.cityName}
                    // inputValue={value1}
                    // onInputChange={(e, newInputValue) => setValue1(newInputValue)}
                    onChange={(e, newValue) => {
                      const prevArray = addBranchObjEdit;
                      prevArray.cityId = newValue.cityId;
                      prevArray.cityName = newValue.cityName;
                      setAddBranchObjEdit(prevArray);
                      setRefreshComp(new Date());
                    }}
                    getOptionLabel={(option) =>
                      option.cityName ? option.cityName : addBranchObjEdit.cityName
                    }
                    renderOption={(props, option) => <li {...props}>{option.cityName}</li>}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth placeholder="Select City" />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Branch Code
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Branch Code"
                    value={addBranchObjEdit.branchCode}
                    onChange={(e) => {
                      const prevArray = addBranchObjEdit;
                      prevArray.branchCode = e.target.value;
                      setAddBranchObjEdit(prevArray);
                      setRefreshComp(new Date());
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                handleAddBranchEdit(e);
                // setBranchEdit(false);
              }}
              variant="contained"
              className="mainBtn"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          className="PopupBox"
          open={deleteConfirm}
          onClose={(e) => setDeleteConfirm(false)}
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Delete Confirmation</Typography>
            <IconButton onClick={(e) => setDeleteConfirm(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Typography>Are you sure you want to delete?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => setDeleteConfirm(false)}
              variant="contained"
              className="mainBtn deleteBtn"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          // onClick={handleNext}
          onClick={(e) => handleNextStep2(e)}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{validationMsg1}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Retry</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Step3Content({
  handleNext,
  handlePrev,
  setActiveStep,
  reviewData,
  setReviewData,
  EditData,
  setEditData,
  location
}) {
  const navigate = useNavigate();
  const [selectLoan, setSelectLoan] = useState(' ');
  const [open2, setOpen2] = useState(false);
  const [validationMsg2, setValidationMsg2] = useState(''); // validation message
  const [selectBranch, setSelectBranch] = useState(' ');
  const [refreshComp, setRefreshComp] = useState(null);
  const [commission, setCommission] = useState('');
  const [token, setToken] = useState('');
  const [companyId, setCompanyId] = useState();
  const [userId, setUserId] = useState('');
  const [loanTypesList, setLoanTypesList] = useState([]); // select product data from api
  const [commissionInfoData, setCommissionInfoData] = useState([]);
  const [agreementId, setAgreementId] = useState(null);
  const [bankId, setBankId] = useState(null);
  const [companyId2, setCompanyId2] = useState(null);
  const [userId2, setUserId2] = useState(null);
  const [branches, setBranches] = useState([]);
  const [turnoverBenefitValue, setTurnoverBenefitValue] = useState(false);
  console.log('turnoverBenefitValue', turnoverBenefitValue);
  const [individualCommissionEdit, setIndividualCommissionEdit] = useState(false);
  const [volumeCommissionEdit, setVolumeCommissionEdit] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const commissionStructureTypes = {
    INDIVIDUAL: {
      typeName: 'INDIVIDUAL',
      typeId: 70
    },
    VOLUME: {
      typeName: 'VOLUME',
      typeId: 72
    },
    BOTH: {
      typeName: 'BOTH',
      typeId: 74
    }
  };
  const [showHide, setShowHide] = useState(commissionStructureTypes.INDIVIDUAL.typeId);

  console.log('showHide', showHide);

  const handleShowHide = (event) => {
    const getUserType = Number(event.target.value);
    setShowHide(getUserType);
    console.log('getUserType', getUserType);
    localStorage.setItem('commisssionType', getUserType);

    setIndividualCommission({
      loanType: ' ',
      branch: ' ',
      commission: ''
    });
    setVolumeArray({
      loanType: ' ',
      branch: ' ',
      amount: '',
      duration: '',
      commission: '',
      unit: ''
    });
  };
  // const [userType, setUserType] = useState('70');

  const [individualCommission, setIndividualCommission] = useState({
    loanType: ' ',
    branch: ' ',
    commission: '',
    isIndividualCommissionType: null
  });
  console.log('individualCommission', individualCommission);

  const [individualArrayList, setIndividualArrayList] = useState([]);
  console.log('individualArrayList', individualArrayList);

  const [individualCommissionEditList, setIndividualCommissionEditList] = useState({
    loanType: ' ',
    branch: ' ',
    commission: '',
    isIndividualCommissionType: null
  });

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [volumeArray, setVolumeArray] = useState({
    loanType: ' ',
    branch: ' ',
    amount: '',
    duration: '',
    commission: '',
    unit: '',
    isIndividualCommissionType: null
  });

  const [volumeArrayList, setVolumeArrayList] = useState([]);
  console.log('volumeArrayList', volumeArrayList);

  const [volumeArrayEditList, setVolumeArrayEditList] = useState({
    loanType: ' ',
    branch: ' ',
    amount: '',
    duration: '',
    commission: '',
    unit: '',
    isIndividualCommissionType: null
  });

  const [individualItemIndexEdit, setIndividualItemIndexEdit] = useState(null);
  const [individualItemListEdit, setIndividualItemListEdit] = useState({});

  const [volumeItemIndexEdit, setVolumeItemIndexEdit] = useState(null);
  const [volumeItemListEdit, setVolumeItemListEdit] = useState({});

  const handleAddIndividualEdit = (e) => {
    if (individualCommissionEditList.commission > 100) {
      setOpen2(true);
      setValidationMsg2('Please enter valid commission %');
      setIndividualCommissionEditList({
        ...individualCommissionEditList,
        commission: ''
      });
      return;
    }
    const prevArray = individualCommissionEditList;
    prevArray.isIndividualCommissionType = true;
    setIndividualCommissionEditList(prevArray);

    const newArray = individualArrayList;

    const index = newArray.indexOf(individualItemListEdit);
    console.log('branchIndex3', index);
    if (index === individualItemIndexEdit) {
      newArray[index] = individualCommissionEditList;
    }
    setIndividualCommissionEdit(false);
    setRefreshComp(new Date());
  };

  const individualEditDialogOpen = (e, itemList, indexValue) => {
    console.log('individualEditDialogOpen', itemList);
    console.log('individualEditDialogOpen', indexValue);
    setIndividualCommissionEdit(true);
    setIndividualCommissionEditList({
      loanType: itemList ? itemList.loanType : ' ',
      branch: itemList ? itemList.branch : ' ',
      commission: itemList ? itemList.commission : '',
      isIndividualCommissionType: itemList ? itemList.isIndividualCommissionType : null
    });
    setIndividualItemListEdit(itemList);
    setIndividualItemIndexEdit(indexValue);
  };

  const handleAddVolumeEdit = (e) => {
    if (volumeArrayEditList.commission > 100) {
      setOpen2(true);
      setValidationMsg2('Please enter valid commission %');
      setVolumeArrayEditList({
        ...volumeArrayEditList,
        commission: ''
      });
      return;
    }

    const prevArray = volumeArrayEditList;
    prevArray.isIndividualCommissionType = false;
    setVolumeArrayEditList(prevArray);
    const newArray = volumeArrayList;
    const index = newArray.indexOf(volumeItemListEdit);
    console.log('branchIndex3', index);
    if (index === volumeItemIndexEdit) {
      newArray[index] = volumeArrayEditList;
    }
    setVolumeCommissionEdit(false);
    setRefreshComp(new Date());
  };

  const volumeEditDialogOpen = (e, itemListVolume, indexValueVolume) => {
    console.log('individualEditDialogOpen', itemListVolume);
    console.log('individualEditDialogOpen', indexValueVolume);
    setVolumeCommissionEdit(true);
    setVolumeArrayEditList({
      loanType: itemListVolume ? itemListVolume.loanType : ' ',
      branch: itemListVolume ? itemListVolume.branch : ' ',
      amount: itemListVolume ? itemListVolume.amount : '',
      duration: itemListVolume ? itemListVolume.duration : '',
      commission: itemListVolume ? itemListVolume.commission : '',
      unit: itemListVolume ? itemListVolume.unit : '',
      isIndividualCommissionType: itemListVolume ? itemListVolume.isIndividualCommissionType : null
    });

    setVolumeItemListEdit(itemListVolume);
    setVolumeItemIndexEdit(indexValueVolume);
  };

  const handleNextStep3 = async (e) => {
    const localJWTtoken = JSON.parse(localStorage.getItem('agreementId'));
    const authAgreementId = localJWTtoken.agreementId;
    const authBankId = localJWTtoken.bankId;
    const authCompanyId = localJWTtoken.companyId;
    const authUserId = localJWTtoken.userId;

    const newArrayCredential = [];

    const combineArrayBoth = [];
    if (individualArrayList.length > 0 && volumeArrayList.length > 0) {
      combineArrayBoth.push(...individualArrayList, ...volumeArrayList);
      console.log('combineArrayBoth', combineArrayBoth);
    }

    if (showHide === commissionStructureTypes.INDIVIDUAL.typeId) {
      if (individualArrayList.length === 0) {
        setOpen2(true);
        setValidationMsg2('Please add individual commission structure');
        return;
      }
      individualArrayList.forEach((item, index) => {
        const credential = {
          aggrementCommissionPercentage: Number(item.commission),
          aggrementCommissionTypeId: showHide,
          aggrementId: authAgreementId,
          amount: null,
          bankId: authBankId,
          // branchId: item.branch,
          branchId: JSON.parse(item.branch).branchId,
          companyId: authCompanyId,
          duration: null,
          loanTypeId: JSON.parse(item.loanType).loanType,
          unitId: null,
          userId: authUserId,
          isIndividualCommissionType: item.isIndividualCommissionType
        };
        newArrayCredential.push(credential);
      });
    } else if (showHide === commissionStructureTypes.VOLUME.typeId) {
      if (volumeArrayList.length === 0) {
        setOpen2(true);
        setValidationMsg2('Please add volume commission structure');
        return;
      }
      volumeArrayList.forEach((item, index) => {
        const credential = {
          aggrementCommissionPercentage: Number(item.commission),
          aggrementCommissionTypeId: showHide,
          aggrementId: authAgreementId,
          amount: Number(item.amount),
          bankId: authBankId,
          // branchId: item.branch,
          branchId: JSON.parse(item.branch).branchId,
          companyId: authCompanyId,
          duration: Number(item.duration),
          loanTypeId: JSON.parse(item.loanType).loanType,
          unitId: Number(item.unit),
          userId: authUserId,
          isIndividualCommissionType: item.isIndividualCommissionType
        };
        newArrayCredential.push(credential);
      });
    } else {
      if (combineArrayBoth.length === 0) {
        setOpen2(true);
        setValidationMsg2('Please enter both individual and volume commission structure');
        return;
      }
      combineArrayBoth.forEach((item, index) => {
        const credential = {
          aggrementCommissionPercentage: Number(item.commission),
          aggrementCommissionTypeId: showHide,
          aggrementId: authAgreementId,
          amount: Number(item.amount),
          bankId: authBankId,
          // branchId: item.branch,
          branchId: JSON.parse(item.branch).branchId,
          companyId: authCompanyId,
          duration: Number(item.duration),
          loanTypeId: JSON.parse(item.loanType).loanType,
          unitId: Number(item.unit),
          userId: authUserId,
          isIndividualCommissionType: item.isIndividualCommissionType
        };
        newArrayCredential.push(credential);
      });

      // combineArrayBoth.push(...individualArrayList, ...volumeArrayList);

      // const obj = {
      //   individualArrayList:{
      //     data:[...individualArrayList],

      //   },
      //   volumeArrayList:{
      //     data:[...volumeArrayList],
      //   }
      // }
    }

    const SaveCommissionInfoApi = await SaveCommissionInfo(newArrayCredential, token);
    console.log('SaveCommissionInfoApi', SaveCommissionInfoApi);
    if (SaveCommissionInfoApi.status === 'success') {
      setCommissionInfoData(SaveCommissionInfoApi.data);
      setActiveStep((activeStep) => activeStep + 1);
      let individualCommissionData = [];
      let volumeCommissionData = [];
      if (showHide === commissionStructureTypes.INDIVIDUAL.typeId) {
        individualCommissionData = [];
        individualCommissionData = individualArrayList ? individualArrayList : null;
      } else if (showHide === commissionStructureTypes.VOLUME.typeId) {
        volumeCommissionData = [];
        volumeCommissionData = volumeArrayList ? volumeArrayList : null;
      } else {
        individualCommissionData = [];
        individualCommissionData = individualArrayList ? individualArrayList : null;
        volumeCommissionData = [];
        volumeCommissionData = volumeArrayList ? volumeArrayList : null;
      }

      setReviewData({
        ...reviewData,
        commissionDetails: {
          individual: individualCommissionData.length > 0 ? individualCommissionData : [],
          volume: volumeCommissionData.length > 0 ? volumeCommissionData : []
        }
      });

      localStorage.setItem(
        'reviewData',
        JSON.stringify({
          ...reviewData,
          commissionDetails: {
            individual: individualCommissionData.length > 0 ? individualCommissionData : [],
            volume: volumeCommissionData.length > 0 ? volumeCommissionData : []
          }
        })
      );
    }
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('agreementId'));
    const authAgreementId = localJWTtoken.agreementId;
    setAgreementId(authAgreementId);
    const authBankId = localJWTtoken.bankId;
    setBankId(authBankId);
    const authCompanyId = localJWTtoken.companyId;
    setCompanyId2(authCompanyId);
    const authUserId = localJWTtoken.userId;
    setUserId2(authUserId);

    const authCommisssionType = JSON.parse(localStorage.getItem('commisssionType'));
    if (authCommisssionType !== null) {
      setShowHide(Number(authCommisssionType));
    }

    (async () => {
      const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
      if (localJWTtoken === null) {
        return true;
      }
      console.log('localJWTtoken', localJWTtoken);
      const authToken = localJWTtoken.token.jwt;
      setToken(authToken);
      const authCompanyId = localJWTtoken.token.companyId;
      setCompanyId(authCompanyId);
      const authUserId = localJWTtoken.token.userId;
      setUserId(authUserId);

      const LoanTypesApiResponse = await LoanTypes(authToken);
      if (LoanTypesApiResponse.msg) {
        if (LoanTypesApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (LoanTypesApiResponse.status === 'success') {
        setLoanTypesList(LoanTypesApiResponse.data.loanTypes);
      }

      const BankBranchListApi = await BankBranchList(
        {
          bankId: authBankId,
          pageSize: 100,
          pageNumber: 1,
          sortOrder: 'asc'
        },
        authToken
      );
      console.log('BankBranchListApi', BankBranchListApi);
      if (BankBranchListApi.status === 'success') {
        setBranches(BankBranchListApi.data.branches);
      } else {
        setBranches([]);
      }
    })();

    let authReviewData = '';

    setTimeout(() => {
      authReviewData = JSON.parse(localStorage.getItem('reviewData'));
      if (authReviewData !== null) {
        console.log('authReviewData', authReviewData);
        const turnoverBenefit = authReviewData.bankDetails.turnoverBenefit.typeId;
        if (turnoverBenefit === 'false') {
          setTurnoverBenefitValue(true);
        } else {
          setTurnoverBenefitValue(false);
        }
        if (authReviewData.commissionDetails) {
          if (
            authReviewData.commissionDetails.individual.length > 0 &&
            authReviewData.commissionDetails.volume.length > 0
          ) {
            setIndividualArrayList(authReviewData.commissionDetails.individual);
            setVolumeArrayList(authReviewData.commissionDetails.volume);
          } else if (authReviewData.commissionDetails.individual.length > 0) {
            setIndividualArrayList(authReviewData.commissionDetails.individual);
          } else {
            setVolumeArrayList(authReviewData.commissionDetails.volume);
          }
        }
      }
    }, 500);

    if (location.pathname === '/agreement/edit' && authReviewData.commissionDetails === undefined) {
      console.log('EditData1', EditData);
      if (EditData !== null) {
        console.log('EditData1', EditData);
        const turnoverBenefit = EditData.bankDetails.turnoverBenefit;
        if (turnoverBenefit === false) {
          setTurnoverBenefitValue(true);
        } else {
          setTurnoverBenefitValue(false);
        }

        console.log('EditData11', EditData.commissionDetails.commissions);
        EditData.commissionDetails.commissions.forEach((item, index) => {
          console.log('EditData11', item);
          const commissionStructureTypes = {
            INDIVIDUAL: {
              typeName: 'INDIVIDUAL',
              typeId: 70
            },
            VOLUME: {
              typeName: 'VOLUME',
              typeId: 72
            },
            BOTH: {
              typeName: 'BOTH',
              typeId: 74
            }
          };
          if (item.aggrementCommissionTypeId === 70) {
            setShowHide(commissionStructureTypes.INDIVIDUAL.typeId);
            console.log('item9', item);
          } else if (item.aggrementCommissionTypeId === 72) {
            setShowHide(commissionStructureTypes.VOLUME.typeId);
          } else {
            setShowHide(commissionStructureTypes.BOTH.typeId);
          }
          const filtredData = EditData.commissionDetails.commissions.filter(
            (item) =>
              (item.aggrementCommissionTypeId.toString() === '74' &&
                item.isIndividualCommissionType === true) ||
              (item.aggrementCommissionTypeId.toString() === '70' &&
                item.isIndividualCommissionType === true)
          );
          console.log('filtredData', filtredData);
          const indArray = [];
          filtredData.forEach((item, index) => {
            const branchStr = {
              companyId: item.companyId,
              bankId: item.bankId,
              branchId: item.branchId,
              branchName: null,
              branchAddress: null,
              cityId: null,
              stateId: null,
              branchCode: null,
              contactPerson: null
            };
            const loanArray = {
              loanId: null,
              loanName: null,
              loanType: item.loanTypeId
            };
            const indData = {
              branch: JSON.stringify(branchStr),
              commission: item.aggrementCommissionPercentage,
              isIndividualCommissionType: true,
              loanType: JSON.stringify(loanArray)
            };
            indArray.push(indData);
          });

          if (indArray.length > 0) {
            setIndividualArrayList(indArray);
            console.log('indData2', indArray);
          }

          const filtredData2 = EditData.commissionDetails.commissions.filter(
            (item) =>
              (item.aggrementCommissionTypeId.toString() === '74' &&
                item.isIndividualCommissionType === false) ||
              (item.aggrementCommissionTypeId.toString() === '72' &&
                item.isIndividualCommissionType === false)
          );
          const volArray = [];
          filtredData2.forEach((item, index) => {
            const branchStr1 = {
              companyId: item.companyId,
              bankId: item.bankId,
              branchId: item.branchId,
              branchName: item.branchName ? item.branchName : null,
              branchAddress: null,
              cityId: null,
              stateId: null,
              branchCode: null,
              contactPerson: null
            };
            const loanArray1 = {
              loanId: null,
              loanName: item.loanTypeName ? item.loanTypeName : null,
              loanType: item.loanTypeId
            };
            const volData = {
              amount: item.amount,
              branch: JSON.stringify(branchStr1),
              commission: item.aggrementCommissionPercentage,
              duration: item.duration,
              isIndividualCommissionType: false,
              loanType: JSON.stringify(loanArray1),
              unit: item.unit ? item.unit : null
            };
            volArray.push(volData);
          });
          if (volArray.length > 0) {
            setVolumeArrayList(volArray);
            console.log('volArray', volArray);
          }
        });
      }
    }
  }, [EditData]);

  return (
    <>
      <Box className="">
        <Box className="displayFlex alignItemCenter" mb={2}>
          <Typography variant="body2" className="mr-2">
            Commision Structure
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={commissionStructureTypes.INDIVIDUAL.typeId}
              name="radio-buttons-group"
              // value={userType}
              value={showHide}
              row
              onChange={(e) => {
                handleShowHide(e);
                // setUserType(e.target.value);
              }}
            >
              <FormControlLabel
                value={commissionStructureTypes.INDIVIDUAL.typeId}
                control={<Radio size="small" />}
                sx={{ marginRight: '1rem' }}
                label={
                  <Typography variant="body2">
                    <strong>Individual</strong>
                  </Typography>
                }
              />
              <FormControlLabel
                value={commissionStructureTypes.VOLUME.typeId}
                control={<Radio size="small" />}
                sx={{ marginRight: '1rem' }}
                disabled={turnoverBenefitValue}
                label={
                  <Typography variant="body2">
                    <strong>Volume</strong>
                  </Typography>
                }
              />
              <FormControlLabel
                value={commissionStructureTypes.BOTH.typeId}
                control={<Radio size="small" />}
                sx={{ marginRight: '1rem' }}
                disabled={turnoverBenefitValue}
                label={
                  <Typography variant="body2">
                    <strong>Both</strong>
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {showHide === commissionStructureTypes.INDIVIDUAL.typeId && (
          <IndividualCommission
            StyledTableCell={StyledTableCell}
            StyledTableRow={StyledTableRow}
            setOpen2={setOpen2}
            setValidationMsg2={setValidationMsg2}
            individualArrayList={individualArrayList}
            setIndividualArrayList={setIndividualArrayList}
            loanTypesList={loanTypesList}
            setRefreshComp={setRefreshComp}
            individualCommission={individualCommission}
            setIndividualCommission={setIndividualCommission}
            branches={branches}
            setIndividualCommissionEdit={setIndividualCommissionEdit}
            setDeleteConfirm={setDeleteConfirm}
            individualEditDialogOpen={individualEditDialogOpen}
          />
        )}
        {showHide === commissionStructureTypes.VOLUME.typeId && (
          <VolumeCommission
            StyledTableCell={StyledTableCell}
            StyledTableRow={StyledTableRow}
            setOpen2={setOpen2}
            setValidationMsg2={setValidationMsg2}
            volumeArray={volumeArray}
            setVolumeArray={setVolumeArray}
            volumeArrayList={volumeArrayList}
            setVolumeArrayList={setVolumeArrayList}
            loanTypesList={loanTypesList}
            setRefreshComp={setRefreshComp}
            branches={branches}
            setDeleteConfirm={setDeleteConfirm}
            setVolumeCommissionEdit={setVolumeCommissionEdit}
            volumeEditDialogOpen={volumeEditDialogOpen}
          />
        )}
        {showHide === commissionStructureTypes.BOTH.typeId && (
          <>
            <Box mb={3}>
              <IndividualCommission
                StyledTableCell={StyledTableCell}
                StyledTableRow={StyledTableRow}
                setOpen2={setOpen2}
                setValidationMsg2={setValidationMsg2}
                individualArrayList={individualArrayList}
                setIndividualArrayList={setIndividualArrayList}
                loanTypesList={loanTypesList}
                setRefreshComp={setRefreshComp}
                individualCommission={individualCommission}
                setIndividualCommission={setIndividualCommission}
                branches={branches}
                setDeleteConfirm={setDeleteConfirm}
                setIndividualCommissionEdit={setIndividualCommissionEdit}
                individualEditDialogOpen={individualEditDialogOpen}
              />
            </Box>
            <VolumeCommission
              StyledTableCell={StyledTableCell}
              StyledTableRow={StyledTableRow}
              setOpen2={setOpen2}
              setValidationMsg2={setValidationMsg2}
              volumeArray={volumeArray}
              setVolumeArray={setVolumeArray}
              volumeArrayList={volumeArrayList}
              setVolumeArrayList={setVolumeArrayList}
              loanTypesList={loanTypesList}
              setRefreshComp={setRefreshComp}
              branches={branches}
              setDeleteConfirm={setDeleteConfirm}
              setVolumeCommissionEdit={setVolumeCommissionEdit}
              volumeEditDialogOpen={volumeEditDialogOpen}
            />
          </>
        )}
        {/* edit individual commission start */}
        <Dialog
          fullWidth
          className="PopupBox"
          open={individualCommissionEdit}
          onClose={(e) => setIndividualCommissionEdit(false)}
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Edit Details</Typography>
            <IconButton onClick={(e) => setIndividualCommissionEdit(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Loan Type
                  </Typography>
                  <Select
                    fullWidth
                    value={individualCommissionEditList.loanType}
                    onChange={(e) => {
                      const prevArray = individualCommissionEditList;
                      prevArray.loanType = e.target.value;
                      setIndividualCommissionEditList(prevArray);
                      setRefreshComp(new Date());
                    }}
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${individualCommissionEditList.loanType}`
                    }}
                  >
                    <MenuItem value=" " disabled>
                      Select Loan Type
                    </MenuItem>
                    {loanTypesList.length > 0
                      ? loanTypesList.map((item, index) => {
                          console.log(item);
                          return (
                            // <MenuItem value={item.loanId} key={index}>
                            //   {item.loanName}
                            // </MenuItem>
                            <MenuItem value={JSON.stringify(item)} key={index}>
                              {item.loanName}
                            </MenuItem>
                          );
                        })
                      : ''}
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Branch
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${individualCommissionEditList.branch}`
                    }}
                    value={individualCommissionEditList.branch}
                    // value={individualCommission.branchId}
                    onChange={(e) => {
                      const prevArray = individualCommissionEditList;
                      prevArray.branch = e.target.value;
                      // prevArray.branchId = e.target.value;
                      setIndividualCommissionEditList(prevArray);
                      setRefreshComp(new Date());
                    }}
                  >
                    <MenuItem value=" " disabled>
                      Select Branch
                    </MenuItem>
                    {branches !== undefined && branches.length > 0
                      ? branches.map((item, index) => {
                          console.log(item);
                          return (
                            // <MenuItem value={item.branchId}>{item.branchName}</MenuItem>
                            <MenuItem value={JSON.stringify(item)} key={index}>
                              {item.branchName}
                            </MenuItem>
                          );
                        })
                      : ''}
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Commission %
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Commission %"
                    value={individualCommissionEditList.commission}
                    // value={individualCommission.aggrementCommissionPercentage}
                    onChange={(e) => {
                      const prevArray = individualCommissionEditList;
                      prevArray.commission = e.target.value;
                      // prevArray.aggrementCommissionPercentage = e.target.value;
                      setIndividualCommissionEditList(prevArray);
                      setRefreshComp(new Date());
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                // setIndividualCommissionEdit(false);
                handleAddIndividualEdit(e);
              }}
              variant="contained"
              className="mainBtn"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* edit individual commission end */}
        {/* edit volune commission start */}
        <Dialog
          fullWidth
          className="PopupBox"
          open={volumeCommissionEdit}
          onClose={(e) => setVolumeCommissionEdit(false)}
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Edit Details</Typography>
            <IconButton onClick={(e) => setVolumeCommissionEdit(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Loan Type
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${volumeArrayEditList.loanType}`
                    }}
                    // value={selectLoan}
                    // onChange={(e) => setSelectLoan(e.target.value)}
                    value={volumeArrayEditList.loanType}
                    onChange={(e) => {
                      const prevArray = volumeArrayEditList;
                      prevArray.loanType = e.target.value;
                      setVolumeArrayEditList(prevArray);
                      setRefreshComp(new Date());
                    }}
                  >
                    <MenuItem value=" " disabled>
                      Select Loan Type
                    </MenuItem>
                    {loanTypesList.length > 0
                      ? loanTypesList.map((item, index) => {
                          console.log(item);
                          return (
                            // <MenuItem value={item.loanId} key={index}>
                            //   {item.loanName}
                            // </MenuItem>
                            <MenuItem value={JSON.stringify(item)} key={index}>
                              {item.loanName}
                            </MenuItem>
                          );
                        })
                      : ''}
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Branch
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${volumeArrayEditList.branch}`
                    }}
                    // value={selectBranch}
                    // onChange={(e) => setSelectBranch(e.target.value)}
                    value={volumeArrayEditList.branch}
                    onChange={(e) => {
                      const prevArray = volumeArrayEditList;
                      prevArray.branch = e.target.value;
                      setVolumeArrayEditList(prevArray);
                      setRefreshComp(new Date());
                    }}
                  >
                    <MenuItem value=" " disabled>
                      Select Branch
                    </MenuItem>
                    {branches !== undefined && branches.length > 0
                      ? branches.map((item, index) => {
                          console.log(item);
                          return (
                            // <MenuItem value={item.branchId}>{item.branchName}</MenuItem>
                            <MenuItem value={JSON.stringify(item)} key={index}>
                              {item.branchName}
                            </MenuItem>
                          );
                        })
                      : ''}
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Amount
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Amount"
                    value={volumeArrayEditList.amount}
                    onChange={(e) => {
                      const prevArray = volumeArrayEditList;
                      prevArray.amount = e.target.value;
                      setVolumeArrayEditList(prevArray);
                      setRefreshComp(new Date());
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Duration
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Duration"
                    value={volumeArrayEditList.duration}
                    onChange={(e) => {
                      const prevArray = volumeArrayEditList;
                      prevArray.duration = e.target.value;
                      setVolumeArrayEditList(prevArray);
                      setRefreshComp(new Date());
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Commission %
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Commission"
                    value={volumeArrayEditList.commission}
                    onChange={(e) => {
                      const prevArray = volumeArrayEditList;
                      prevArray.commission = e.target.value;
                      setVolumeArrayEditList(prevArray);
                      setRefreshComp(new Date());
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="mb-3">
                  <Typography variant="h6" className="formLabel">
                    Unit
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Unit"
                    sx={{ marginRight: '1rem' }}
                    value={volumeArrayEditList.unit}
                    onChange={(e) => {
                      const prevArray = volumeArrayEditList;
                      prevArray.unit = e.target.value;
                      setVolumeArrayEditList(prevArray);
                      setRefreshComp(new Date());
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                // setVolumeCommissionEdit(false);
                handleAddVolumeEdit(e);
              }}
              variant="contained"
              className="mainBtn"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* edit volune commission end */}
        {/* delete confirm start */}
        <Dialog
          fullWidth
          className="PopupBox"
          open={deleteConfirm}
          onClose={(e) => setDeleteConfirm(false)}
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Delete Confirmation</Typography>
            <IconButton onClick={(e) => setDeleteConfirm(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Typography>Are you sure you want to delete?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => setDeleteConfirm(false)}
              variant="contained"
              className="mainBtn deleteBtn"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* delete confirm end */}
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          // onClick={handleNext}
          onClick={(e) => handleNextStep3(e)}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{validationMsg2}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Retry</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Step4Content({
  handleNext,
  handlePrev,
  setActiveStep,
  reviewData,
  setReviewData,
  EditData,
  setEditData,
  location
}) {
  const [file, setFile] = useState();
  const [agreementPreview, setAgreementPreview] = useState(null);
  const [agreementUpload, setAgreementUpload] = useState();
  const [agreementPreviewtype1, setAgreementPreviewtype1] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // setting 1 to show fisrt page
  const [token, setToken] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [userId, setUserId] = useState('');
  const [agreementId, setAgreementId] = useState('');
  const [bankId, setBankId] = useState('');
  const [open3, setOpen3] = useState(false);
  const [validationMsg3, setValidationMsg3] = useState(''); // validation message
  const [uploadImageFileReview, setUploadImageFileReview] = useState(null);

  console.log('agreementUpload', agreementUpload);
  console.log('uploadImageFileReview', uploadImageFileReview);
  console.log('agreementPreviewtype1', agreementPreviewtype1);

  const handleClose3 = () => {
    setOpen3(false);
  };

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });

  // file upload part
  // const handleFileUpload = async (e, setPreview, files, setUploadImage, setLocaltype) => {
  //   if (e.target.files[0] !== undefined) {
  //     setLocaltype(e.target.files[0].name.split('.')[1]);
  //     setUploadImage(e.target.files[0]);
  //     setPreview(URL.createObjectURL(e.target.files[0]));
  //   }
  // };

  const handleFileUpload = async (e, setPreview, files, setUploadImage, setLocaltype) => {
    if (e.target.files[0] !== undefined) {
      setLocaltype(e.target.files[0].name.split('.')[1]);
      setUploadImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e, setPreview, setUploadImage, setLocaltype) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    // console.log(files[0]);
    handleFileUpload(e, setPreview, files[0], setUploadImage, setLocaltype);
  };

  const uploadFileClick = (e, btnId) => {
    const browseField = document.getElementById(btnId);
    browseField.click();
  };

  const handleNextStep4 = async (e) => {
    if (agreementUpload === undefined && agreementPreviewtype1 === '') {
      setOpen3(true);
      setValidationMsg3('Please upload documents');
      return;
    }
    if (agreementUpload !== undefined && agreementUpload.size > 0) {
      const formUploadData = new FormData();
      formUploadData.append('aggrementId', Number(agreementId));
      formUploadData.append('agreementFile', agreementUpload);

      formUploadData.append('bankId', Number(bankId));
      formUploadData.append('companyId', Number(companyId));
      formUploadData.append('userId', Number(userId));

      return fetch(UploadDocument(), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formUploadData
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'success') {
            console.log('upload doc', data.data.documentUrl);
            setActiveStep((activeStep) => activeStep + 1);
            setReviewData({
              ...reviewData,
              contentType: data.data.contentType,
              imagePath: data.data.documentUrl
              // agreementUpload: uploadImageFileReview
            });
            localStorage.setItem(
              'reviewData',
              JSON.stringify({
                ...reviewData,
                contentType: data.data.contentType,
                imagePath: data.data.documentUrl
                // agreementUpload: uploadImageFileReview
              })
            );
          }
        });
    } else {
      setActiveStep((activeStep) => activeStep + 1);
    }
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('agreementId'));
    const authAgreementId = localJWTtoken.agreementId;
    setAgreementId(authAgreementId);
    const authBankId = localJWTtoken.bankId;
    setBankId(authBankId);
    const authCompanyId = localJWTtoken.companyId;
    setCompanyId(authCompanyId);
    const authUserId = localJWTtoken.userId;
    setUserId(authUserId);

    const localJWTtoken2 = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken2 === null) {
      return true;
    }
    const authToken = localJWTtoken2.token.jwt;
    setToken(authToken);

    let authReviewData = '';
    setTimeout(() => {
      authReviewData = JSON.parse(localStorage.getItem('reviewData'));
      if (authReviewData !== null) {
        console.log('authReviewData', authReviewData);
        if (authReviewData.imagePath) {
          setAgreementPreviewtype1(authReviewData.contentType);
          setAgreementPreview(authReviewData.imagePath);
          // setAgreementUpload(authReviewData.agreementUpload);
          console.log('authReviewData.imagePath', authReviewData.imagePath);
        }
      }
    }, 500);

    if (location.pathname === '/agreement/edit' && authReviewData.imagePath === undefined) {
      console.log('EditData1', EditData);
      if (EditData !== null) {
        console.log('EditData1', EditData);
        // setAgreementPreviewtype1(EditData.documentUrl);
        setAgreementPreviewtype1(EditData.contentType);
        setAgreementPreview(EditData.documentUrl);
      }
    }
  }, [EditData]);

  return (
    <Box>
      {/* <Box variant="Container" className="uploadBox">
        <label htmlFor="contained-button-file" onChange={handleChange}>
          <Input accept="image/*" id="contained-button-file" multiple type="file" />
          <Box>
            <BiCloudUpload className="upladIcon" />
            <Typography variant="h5">Upload Agreement</Typography>
          </Box>
        </label>
      </Box> */}
      <Box variant="Container" className="uploadBox">
        <input
          accept="image/png, image/gif, image/jpeg, application/pdf"
          className="fileInput"
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e) =>
            handleFileUpload(
              e,
              setAgreementPreview,
              null,
              setAgreementUpload,
              setAgreementPreviewtype1
            )
          }
        />
        <label
          htmlFor="contained-button-file"
          className={
            agreementPreviewtype1 === 'pdf'
              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
              : `uploadDocBoxLabel uploadDocBoxLabelFull`
          }
        >
          {/* <Box
            variant="contained"
            color="primary"
            component="span"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={(e) =>
              fileDrop(e, setAgreementPreview, setAgreementUpload, setAgreementPreviewtype1)
            }
            display="flex"
            flexDirection="column"
            alignItems="center"
          > */}
          <Box>
            {agreementPreviewtype1 === '' ? (
              <>
                <BiCloudUpload className="upladIcon" />
                <Typography variant="h5">Upload Agreement</Typography>
              </>
            ) : (
              ''
            )}
            {agreementPreviewtype1 === 'pdf' ? (
              <Document
                file={agreementPreview}
                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
              >
                <Pages pageNumber={pageNumber} />
                {/* <p>
                  Page {pageNumber} of {numPages}
                </p> */}
              </Document>
            ) : (
              <img src={agreementPreview} alt="" className="previewImage" />
            )}
          </Box>
        </label>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          // onClick={handleNext}
          onClick={(e) => handleNextStep4(e)}
          endIcon={<HiOutlineArrowNarrowRight />}
          className="mainBtn nextBtn"
        >
          Next
        </Button>
      </Box>
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{validationMsg3}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3}>Retry</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function Step5Content({ handlePrev, reviewData, EditData, location }) {
  console.log('reviewData2', reviewData);
  const navigate = useNavigate();
  return (
    <Box>
      {reviewData.bankDetails && Object.keys(reviewData.bankDetails).length > 0 ? (
        <>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Bank Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid item container spacing={{ xs: 1, sm: 2, md: 3 }} marginBottom={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  Bank Name
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  {reviewData.bankDetails ? reviewData.bankDetails.bankName.bankName : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  Address
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  {/* {reviewData.bankDetails ? reviewData.bankDetails.bankAddress : 'N/A'} */}
                  N/A
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  PAN number
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  {reviewData.bankDetails ? reviewData.bankDetails.panNumber : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  GSTIN
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  {reviewData.bankDetails ? reviewData.bankDetails.gstin : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  Commission Structure
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  {reviewData.bankDetails
                    ? reviewData.bankDetails.bankCommissionTypeName.typeName
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="reviewLabel">
                  Turnover Benefit
                </Typography>
                <Typography variant="subtitle2" className="reviewValue">
                  {reviewData.bankDetails ? reviewData.bankDetails.turnoverBenefit.typeName : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        ''
      )}

      {reviewData.branchDetails && reviewData.branchDetails.length > 0 ? (
        <>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Branch Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Branch Name</StyledTableCell>
                    <StyledTableCell align="">Branch Address</StyledTableCell>
                    <StyledTableCell align="">Contact Person</StyledTableCell>
                  </TableRow>
                </TableHead>
                {reviewData.branchDetails.map((item, index) => {
                  console.log(item);
                  return (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="">{item.branchName}</StyledTableCell>
                        <StyledTableCell align="">{item.branchAddress}</StyledTableCell>
                        <StyledTableCell align="">{item.contactPerson}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </TableContainer>
            {/* <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Branch Name</StyledTableCell>
                <StyledTableCell align="">Branch Address</StyledTableCell>
                <StyledTableCell align="">Contact Person</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="">HDFC</StyledTableCell>
                <StyledTableCell align="">Ramesh Vihar, New Delhi</StyledTableCell>
                <StyledTableCell align="">Ramesh</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer> */}
          </Box>
        </>
      ) : (
        ''
      )}

      {reviewData.commissionDetails && reviewData.commissionDetails.individual.length > 0 ? (
        <>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Commission Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Loan Type</StyledTableCell>
                    <StyledTableCell align="left">Branch</StyledTableCell>
                    <StyledTableCell align="left">Commission %</StyledTableCell>
                  </TableRow>
                </TableHead>

                {reviewData.commissionDetails.individual.map((item, index) => {
                  console.log(item);
                  return (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left">
                          {JSON.parse(item.loanType).loanName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {JSON.parse(item.branch).branchName}
                        </StyledTableCell>
                        <StyledTableCell align="left">{item.commission}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        ''
      )}

      {reviewData.commissionDetails && reviewData.commissionDetails.volume.length > 0 ? (
        <>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Turnover Benefit
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin">
            <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Loan Type</StyledTableCell>
                    <StyledTableCell align="left">Branch</StyledTableCell>
                    <StyledTableCell align="left">Duration</StyledTableCell>
                    <StyledTableCell align="left">Commission %</StyledTableCell>
                    <StyledTableCell align="left">Unit</StyledTableCell>
                  </TableRow>
                </TableHead>
                {reviewData.commissionDetails.volume.map((item, index) => {
                  console.log(item);
                  return (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left">
                          {JSON.parse(item.loanType).loanName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {JSON.parse(item.branch).branchName}
                        </StyledTableCell>
                        <StyledTableCell align="left">{item.duration}</StyledTableCell>
                        <StyledTableCell align="left">{item.commission}</StyledTableCell>
                        <StyledTableCell align="left">{item.unit}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        ''
      )}

      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          endIcon={<HiOutlineArrowNarrowRight />}
          className="mainBtn nextBtn"
          onClick={(e) => {
            localStorage.removeItem('reviewData');
            localStorage.removeItem('commisssionType');
            navigate('/agreement');
          }}
        >
          Finish
        </Button>
      </Box>
    </Box>
  );
}

export default AddAgreement;

// Top Cities
const topCities = [
  { label: 'Mumbai' },
  { label: 'Delhi' },
  { label: 'Bengaluru' },
  { label: 'Ahmedabad' },
  { label: 'Hyderabad' },
  { label: 'Chennai' },
  { label: 'Kolkata' },
  { label: 'Pune' },
  { label: 'Jaipur' },
  { label: 'Surat' },
  { label: 'Lucknow' },
  { label: 'Kanpur' },
  { label: 'Nagpur' },
  { label: 'Patna' },
  { label: 'Indore' },
  { label: 'Thane' },
  { label: 'Bhopal' },
  { label: 'Visakhapatnam' },
  { label: 'Vadodara' },
  { label: 'Firozabad' },
  { label: 'Ludhiana' },
  { label: 'Rajkot' },
  { label: 'Agra' },
  { label: 'Siliguri' },
  { label: 'Nashik' },
  { label: 'Faridabad' },
  { label: 'Patiala' },
  { label: 'Meerut' },
  { label: 'Varanasi' },
  { label: 'Dhanbad' },
  { label: 'Jodhpur' },
  { label: 'Amritsar' },
  { label: 'Raipur' },
  { label: 'Allahabad' },
  { label: 'Coimbatore' },
  { label: 'Jabalpur' },
  { label: 'Gwalior' },
  { label: 'Vijayawada' },
  { label: 'Madurai' },
  { label: 'Guwahati' },
  { label: 'Chandigarh' },
  { label: 'Hubli-Dharwad' },
  { label: 'Amroha' },
  { label: 'Moradabad' },
  { label: 'Gurgaon' },
  { label: 'Aligarh' },
  { label: 'Solapur' },
  { label: 'Ranchi' },
  { label: 'Jalandhar' },
  { label: 'Tiruchirappalli' },
  { label: 'Bhubaneswar' },
  { label: 'Salem' },
  { label: 'Warangal' },
  { label: 'Mira-Bhayandar' },
  { label: 'Thiruvananthapuram' },
  { label: 'Bhiwandi' },
  { label: 'Saharanpur' },
  { label: 'Guntur' },
  { label: 'Amravati' },
  { label: 'Bikaner' },
  { label: 'Noida' },
  { label: 'Jamshedpur' },
  { label: 'Bhilai Nagar' },
  { label: 'Cuttack' },
  { label: 'Kochi' },
  { label: 'Udaipur' },
  { label: 'Bhavnagar' },
  { label: 'Dehradun' },
  { label: 'Asansol' },
  { label: 'Nanded-Waghala' },
  { label: 'Ajmer' },
  { label: 'Jamnagar' },
  { label: 'Ujjain' },
  { label: 'Sangli' },
  { label: 'Loni' },
  { label: 'Jhansi' },
  { label: 'Pondicherry' },
  { label: 'Nellore' },
  { label: 'Jammu' },
  { label: 'Belagavi' },
  { label: 'Raurkela' },
  { label: 'Mangaluru' },
  { label: 'Tirunelveli' },
  { label: 'Malegaon' },
  { label: 'Gaya' },
  { label: 'Tiruppur' },
  { label: 'Davanagere' },
  { label: 'Kozhikode' },
  { label: 'Akola' },
  { label: 'Kurnool' },
  { label: 'Bokaro' },
  { label: 'Rajahmundry' },
  { label: 'Ballari' },
  { label: 'Agartala' },
  { label: 'Bhagalpur' },
  { label: 'Latur' },
  { label: 'Dhule' },
  { label: 'Korba' },
  { label: 'Bhilwara' },
  { label: 'Brahmapur' },
  { label: 'Mysore' },
  { label: 'Muzaffarpur' },
  { label: 'Ahmednagar' },
  { label: 'Kollam' },
  { label: 'Bilaspur' },
  { label: 'Shahjahanpur' },
  { label: 'Thrissur' },
  { label: 'Alwar' },
  { label: 'Kakinada' },
  { label: 'Nizamabad' },
  { label: 'Sagar' },
  { label: 'Tumkur' },
  { label: 'Hisar' },
  { label: 'Rohtak' },
  { label: 'Panipat' },
  { label: 'Darbhanga' },
  { label: 'Kharagpur' },
  { label: 'Aizawl' },
  { label: 'Ichalkaranji' },
  { label: 'Tirupati' },
  { label: 'Karnal' },
  { label: 'Bathinda' },
  { label: 'Rampur' },
  { label: 'Shivamogga' },
  { label: 'Ratlam' },
  { label: 'Modinagar' },
  { label: 'Durg' },
  { label: 'Shillong' },
  { label: 'Imphal' },
  { label: 'Hapur' },
  { label: 'Ranipet' },
  { label: 'Anantapur' },
  { label: 'Arrah' },
  { label: 'Karimnagar' },
  { label: 'Etawah' },
  { label: 'Bharatpur' },
  { label: 'Begusarai' },
  { label: 'Chhapra' },
  { label: 'Kadapa' },
  { label: 'Pali' },
  { label: 'Satna' },
  { label: 'Vizianagaram' },
  { label: 'Katihar' },
  { label: 'Hardwar' },
  { label: 'Sonipat' },
  { label: 'Nagercoil' },
  { label: 'Thanjavur' },
  { label: 'Murwara' },
  { label: 'Naihati' },
  { label: 'Sambhal' },
  { label: 'Nadiad' },
  { label: 'Yamunanagar' },
  { label: 'Eluru' },
  { label: 'Munger' },
  { label: 'Panchkula' },
  { label: 'Deoghar' },
  { label: 'Ongole' },
  { label: 'Nandyal' },
  { label: 'Morena' },
  { label: 'Bhiwani' },
  { label: 'Porbandar' },
  { label: 'Palakkad' },
  { label: 'Anand' },
  { label: 'Purnia' },
  { label: 'Baharampur' },
  { label: 'Barmer' },
  { label: 'Morvi' },
  { label: 'Orai' },
  { label: 'Bahraich' },
  { label: 'Vellore' },
  { label: 'Khammam' },
  { label: 'Mahesana' },
  { label: 'Silchar' },
  { label: 'Sambalpur' },
  { label: 'Rewa' },
  { label: 'Unnao' },
  { label: 'Hugli-Chinsurah' },
  { label: 'Raiganj' },
  { label: 'Adityapur' },
  { label: 'Alappuzha' },
  { label: 'Bahadurgarh' },
  { label: 'Machilipatnam' },
  { label: 'Rae Bareli' },
  { label: 'Jalpaiguri' },
  { label: 'Bharuch' },
  { label: 'Hoshiarpur' },
  { label: 'Baramula' },
  { label: 'Adoni' },
  { label: 'Jind' },
  { label: 'Tonk' },
  { label: 'Tenali' },
  { label: 'Kancheepuram' },
  { label: 'Vapi' },
  { label: 'Navsari' },
  { label: 'Mahbubnagar' },
  { label: 'Puri' },
  { label: 'Erode' },
  { label: 'Batala' },
  { label: 'Haldwani' },
  { label: 'Vidisha' },
  { label: 'Saharsa' },
  { label: 'Thanesar' },
  { label: 'Chittoor' },
  { label: 'Veraval' },
  { label: 'Lakhimpur' },
  { label: 'Sitapur' },
  { label: 'Hindupur' },
  { label: 'Santipur' },
  { label: 'Balurghat' },
  { label: 'Ganjbasoda' },
  { label: 'Moga' },
  { label: 'Srinagar' },
  { label: 'Medinipur' },
  { label: 'Habra' },
  { label: 'Sasaram' },
  { label: 'Hajipur' },
  { label: 'Bhuj' },
  { label: 'Shimla' },
  { label: 'Tiruvannamalai' },
  { label: 'Kaithal' },
  { label: 'Rajnandgaon' },
  { label: 'Godhra' },
  { label: 'Hazaribag' },
  { label: 'Bhimavaram' },
  { label: 'Mandsaur' },
  { label: 'Dibrugarh' },
  { label: 'Kolar' },
  { label: 'Bankura' },
  { label: 'Mandya' },
  { label: 'Dehri-on-Sone' },
  { label: 'Madanapalle' },
  { label: 'Malerkotla' },
  { label: 'Lalitpur' },
  { label: 'Bettiah' },
  { label: 'Pollachi' },
  { label: 'Khanna' },
  { label: 'Neemuch' },
  { label: 'Palwal' },
  { label: 'Palanpur' },
  { label: 'Guntakal' },
  { label: 'Nabadwip' },
  { label: 'Udupi' },
  { label: 'Jagdalpur' },
  { label: 'Motihari' },
  { label: 'Pilibhit' },
  { label: 'Dimapur' },
  { label: 'Mohali' },
  { label: 'Dharmavaram' },
  { label: 'Kashipur' },
  { label: 'Sivakasi' },
  { label: 'Darjiling' },
  { label: 'Chikkamagaluru' },
  { label: 'Gudivada' },
  { label: 'Adilabad' },
  { label: 'Yavatmal' },
  { label: 'Barnala' },
  { label: 'Nagaon' },
  { label: 'Narasaraopet' },
  { label: 'Raigarh' },
  { label: 'Roorkee' },
  { label: 'Valsad' },
  { label: 'Ambikapur' },
  { label: 'Giridih' },
  { label: 'Chandausi' },
  { label: 'Purulia' },
  { label: 'Patan' },
  { label: 'Bagaha' },
  { label: 'Hardoi' },
  { label: 'Achalpur' },
  { label: 'Osmanabad' },
  { label: 'Deesa' },
  { label: 'Nandurbar' },
  { label: 'Azamgarh' },
  { label: 'Ramgarh' },
  { label: 'Firozpur' },
  { label: 'Baripada' },
  { label: 'Karwar' },
  { label: 'Siwan' },
  { label: 'Anantnag' },
  { label: 'Tadpatri' },
  { label: 'Satara' },
  { label: 'Bhadrak' },
  { label: 'Kishanganj' },
  { label: 'Wardha' },
  { label: 'Amreli' },
  { label: 'Neyveli' },
  { label: 'Jamalpur' },
  { label: 'Marmagao' },
  { label: 'Udgir' },
  { label: 'Tadepalligudem' },
  { label: 'Nagapattinam' },
  { label: 'Buxar' },
  { label: 'Aurangabad' },
  { label: 'Jehanabad' },
  { label: 'Khair' },
  { label: 'Sawai Madhopur' },
  { label: 'Kapurthala' },
  { label: 'Chilakaluripet' },
  { label: 'Malappuram' },
  { label: 'Rewari' },
  { label: 'Nagaur' },
  { label: 'Sultanpur' },
  { label: 'Nagda' },
  { label: 'Port Blair' },
  { label: 'Lakhisarai' },
  { label: 'Panaji' },
  { label: 'Tinsukia' },
  { label: 'Itarsi' },
  { label: 'Kohima' },
  { label: 'Balangir' },
  { label: 'Nawada' },
  { label: 'Jharsuguda' },
  { label: 'Jagtial' },
  { label: 'Viluppuram' },
  { label: 'Amalner' },
  { label: 'Zirakpur' },
  { label: 'Tanda' },
  { label: 'Tiruchengode' },
  { label: 'Nagina' },
  { label: 'Yemmiganur' },
  { label: 'Vaniyambadi' },
  { label: 'Theni Allinagaram' },
  { label: 'Margao' },
  { label: 'Akot' },
  { label: 'Mhow' },
  { label: 'Kot Kapura' },
  { label: 'Kadiri' },
  { label: 'Najibabad' },
  { label: 'Nirmal' },
  { label: 'Udhagamandalam' },
  { label: 'Jhumri Tilaiya' },
  { label: 'Aruppukkottai' },
  { label: 'Jamui' },
  { label: 'Sitamarhi' },
  { label: 'Chirala' },
  { label: 'Anjar' },
  { label: 'Karaikal' },
  { label: 'Hansi' },
  { label: 'Anakapalle' },
  { label: 'Mahasamund' },
  { label: 'Faridkot' },
  { label: 'Dhoraji' },
  { label: 'Balaghat' },
  { label: 'Khambhat' },
  { label: 'Kavali' },
  { label: 'Dhamtari' },
  { label: 'Ashok Nagar' },
  { label: 'Bargarh' },
  { label: 'Kamareddy' },
  { label: 'Kothagudem' },
  { label: 'Ramanagaram' },
  { label: 'Gokak' },
  { label: 'Tikamgarh' },
  { label: 'Araria' },
  { label: 'Rishikesh' },
  { label: 'Daltonganj' },
  { label: 'Arakkonam' },
  { label: 'Washim' },
  { label: 'Sangrur' },
  { label: 'Bodhan' },
  { label: 'Fazilka' },
  { label: 'Palacole' },
  { label: 'Keshod' },
  { label: 'Wadhwan' },
  { label: 'Gurdaspur' },
  { label: 'Vatakara' },
  { label: 'Tura' },
  { label: 'Narnaul' },
  { label: 'Kharar' },
  { label: 'Yadgir' },
  { label: 'Ambejogai' },
  { label: 'Ankleshwar' },
  { label: 'Paradip' },
  { label: 'Virudhachalam' },
  { label: 'Kanhangad' },
  { label: 'Kadi' },
  { label: 'Gobindgarh' },
  { label: 'Tindivanam' },
  { label: 'Taliparamba' },
  { label: 'Tanuku' },
  { label: 'Virudhunagar' },
  { label: 'Koyilandy' },
  { label: 'Jorhat' },
  { label: 'Karur' },
  { label: 'Valparai' },
  { label: 'Neyyattinkara' },
  { label: 'Bapatla' },
  { label: 'Fatehabad' },
  { label: 'Sankarankovil' },
  { label: 'Tenkasi' },
  { label: 'Ratnagiri' },
  { label: 'Sikandrabad' },
  { label: 'Chaibasa' },
  { label: 'Chirmiri' },
  { label: 'Bhawanipatna' },
  { label: 'Kayamkulam' },
  { label: 'Nabha' },
  { label: 'Dhenkanal' },
  { label: 'Uran Islampur' },
  { label: 'Gopalganj' },
  { label: 'Bongaigaon' },
  { label: 'Tarn Taran' },
  { label: 'Barbil' },
  { label: 'Koratla' },
  { label: 'Arambagh' },
  { label: 'Gohana' },
  { label: 'Ladnu' },
  { label: 'Tamluk' },
  { label: 'Jagraon' },
  { label: 'Alirajpur' },
  { label: 'Tandur' },
  { label: 'Naidupet' },
  { label: 'Tirupathur' },
  { label: 'Tohana' },
  { label: 'Dhubri' },
  { label: 'Visnagar' },
  { label: 'Vrindavan' },
  { label: 'Nokha' },
  { label: 'Nagari' },
  { label: 'Narwana' },
  { label: 'Ujhani' },
  { label: 'Samastipur' },
  { label: 'Laharpur' },
  { label: 'Nimbahera' },
  { label: 'Diphu' },
  { label: 'Jhargram' },
  { label: 'Tilhar' },
  { label: 'Udumalaipettai' },
  { label: 'Wanaparthy' },
  { label: 'Gudur' },
  { label: 'Kendujhar' },
  { label: 'Mandi' },
  { label: 'Nedumangad' },
  { label: 'North Lakhimpur' },
  { label: 'Vinukonda' },
  { label: 'Tiptur' },
  { label: 'Gobichettipalayam' },
  { label: 'Wani' },
  { label: 'Upleta' },
  { label: 'Narasapuram' },
  { label: 'Nuzvid' },
  { label: 'Tezpur' },
  { label: 'Una' },
  { label: 'Sheopur' },
  { label: 'Thiruvarur' },
  { label: 'Lonavla' },
  { label: 'Ponnur' },
  { label: 'Kagaznagar' },
  { label: 'Gadwal' },
  { label: 'Bhatapara' },
  { label: 'Kandukur' },
  { label: 'Unjha' },
  { label: 'Lunglei' },
  { label: 'Karimganj' },
  { label: 'Kannur' },
  { label: 'Bobbili' },
  { label: 'Talegaon Dabhade' },
  { label: 'Anjangaon' },
  { label: 'Mangrol' },
  { label: 'Gangarampur' },
  { label: 'Thiruvallur' },
  { label: 'Tirur' },
  { label: 'Jatani' },
  { label: 'Viramgam' },
  { label: 'Rajsamand' },
  { label: 'Yanam' },
  { label: 'Kottayam' },
  { label: 'Dhuri' },
  { label: 'Namakkal' },
  { label: 'Kasaragod' },
  { label: 'Kunnamkulam' },
  { label: 'Umred' },
  { label: 'Bellampalle' },
  { label: 'Ottappalam' },
  { label: 'Dumraon' },
  { label: 'Jaggaiahpet' },
  { label: 'Goalpara' },
  { label: 'Tuni' },
  { label: 'Lachhmangarh' },
  { label: 'Bhongir' },
  { label: 'Amalapuram' },
  { label: 'Firozpur Cantt.' },
  { label: 'Vikarabad' },
  { label: 'Thiruvalla' },
  { label: 'Palghar' },
  { label: 'Jangaon' },
  { label: 'Bheemunipatnam' },
  { label: 'Panna' },
  { label: 'Thodupuzha' },
  { label: 'Arwal' },
  { label: 'Venkatagiri' },
  { label: 'Kalpi' },
  { label: 'Churu' },
  { label: 'Arsikere' },
  { label: 'Ozar' },
  { label: 'Thirumangalam' },
  { label: 'Nasirabad' },
  { label: 'Nanjangud' },
  { label: 'Forbesganj' },
  { label: 'Tundla' },
  { label: 'Bhadrachalam' },
  { label: 'Madhepura' },
  { label: 'Pithoragarh' },
  { label: 'Mathura' },
  { label: 'Sultanganj' },
  { label: 'Raxaul' },
  { label: 'Pilani' },
  { label: 'Mapusa' },
  { label: 'Sivaganga' },
  { label: 'Shahpur' },
  { label: 'Ramnagar' },
  { label: 'Rameshwaram' },
  { label: 'Perambalur' },
  { label: 'Manvi' },
  { label: 'Murshidabad' },
  { label: 'Mahe' },
  { label: 'Solan' },
  { label: 'Sanawad' },
  { label: 'Bulandshahr' },
  { label: 'Satana' },
  { label: 'Sanand' },
  { label: 'Sohna' },
  { label: 'Rudrapur' },
  { label: 'Perumbavoor' },
  { label: 'Raikot' },
  { label: 'Pauri' },
  { label: 'Shahganj' },
  { label: 'Mount Abu' },
  { label: 'Silvassa' },
  { label: 'Sikanderpur' },
  { label: 'Navi Mumbai' }
];
// Top States
const topStates = [
  { label: 'Andhra Pradesh' },
  { label: 'Arunachal Pradesh' },
  { label: 'Assam' },
  { label: 'Bihar' },
  { label: 'Chhattisgarh' },
  { label: 'Goa' },
  { label: 'Gujarat' },
  { label: 'Haryana' },
  { label: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir' },
  { label: 'Jharkhand' },
  { label: 'Karnataka' },
  { label: 'Kerala' },
  { label: 'Madhya Pradesh' },
  { label: 'Maharashtra' },
  { label: 'Manipur' },
  { label: 'Meghalaya' },
  { label: 'Mizoram' },
  { label: 'Nagaland' },
  { label: 'Odisha' },
  { label: 'Punjab' },
  { label: 'Rajasthan' },
  { label: 'Sikkim' },
  { label: 'Tamil Nadu' },
  { label: 'Telangana' },
  { label: 'Tripura' },
  { label: 'Uttarakhand' },
  { label: 'Uttar Pradesh' },
  { label: 'West Bengal' },
  { label: 'Andaman and Nicobar Islands' },
  { label: 'Chandigarh' },
  { label: 'Dadra and Nagar Haveli' },
  { label: 'Daman and Diu' },
  { label: 'Delhi' },
  { label: 'Lakshadweep' },
  { label: 'Puducherry' }
];
