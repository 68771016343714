import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx, isopensidebar }) {
  return (
    <Box
      component="img"
      src={
        isopensidebar === 'true'
          ? '/static/logos/RVPulselogo.svg'
          : '/static/logos/RVPulselogoS.svg'
      }
      sx={{ width: isopensidebar === 'true' ? 95 : 40, height: 40, ...sx, transition: 'all 0.2s' }}
      // src={isopensidebar === 'true' ? '/static/logos/logo.svg' : '/static/logos/logo-icon.svg'}
      // sx={{ width: isopensidebar === 'true' ? 120 : 30, height: 30, ...sx }}
      // src={
      //   isopensidebar === 'true'
      //     ? '/static/logos/lessEMI-logo.png'
      //     : '/static/logos/lessEMI-logo-icon.png'
      // }
      // sx={{ width: isopensidebar === 'true' ? 155 : 30, height: 30, ...sx }}
      // src={
      //   isopensidebar === 'true'
      //     ? '/static/logos/realValue-logo.png'
      //     : '/static/logos/realValue-logo-icon.png'
      // }
      // sx={{ width: isopensidebar === 'true' ? 125 : 30, height: 30, ...sx }}
    />
  );
}
