import { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  styled,
  tableCellClasses
} from '@mui/material';
import { Box } from '@mui/system';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// icons
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
// material icons
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import UpdateIcon from '@mui/icons-material/Update';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import InterestsIcon from '@mui/icons-material/Interests';
import CampaignIcon from '@mui/icons-material/Campaign';
import SourceIcon from '@mui/icons-material/Source';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DateRangeIcon from '@mui/icons-material/DateRange';
// react icons
import { BsWindowDock, BsCardHeading, BsTelephone } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineIdcard } from 'react-icons/ai';
import { RiBankCardLine } from 'react-icons/ri';
import { FaRegAddressCard, FaRegUser } from 'react-icons/fa';
import { CgCalendarDates } from 'react-icons/cg';
import { HiOutlineMail } from 'react-icons/hi';
import { SiOpensourceinitiative } from 'react-icons/si';
import { GiSwapBag, GiModernCity } from 'react-icons/gi';
import { MdIncompleteCircle, MdOutlineDateRange } from 'react-icons/md';
import { BiCloudUpload, BiCloudDownload, BiBoltCircle } from 'react-icons/bi';
// components
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import prospectService from '../services/prospectService';

const { ProspectView } = prospectService;

function ViewProspect() {
  const { rowId } = useParams();
  const navigate = useNavigate();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');
  const [prospectViewData, setProspectViewData] = useState({});

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
      padding: '6px',
      height: '42.5px',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#0d4689'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px',
      height: '42.5px'
    }
  }));
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }
    const authToken = localJWTtoken.token.jwt;
    // setToken(authToken);
    const authCompanyId = localJWTtoken.token.companyId;
    // setCompanyId(authCompanyId);
    const authUserId = localJWTtoken.token.userId;
    // setUserId(authUserId);

    const localJWTtoken1 = JSON.parse(localStorage.getItem('prospectLeadId'));

    (async () => {
      const credentials = {
        companyId: authCompanyId,
        userId: authUserId,
        leadId: localJWTtoken1
      };
      // prospect list api
      const ProspectViewApiResponse = await ProspectView(credentials, authToken);
      console.log('ProspectViewApiResponse', ProspectViewApiResponse);
      if (ProspectViewApiResponse.msg) {
        if (ProspectViewApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ProspectViewApiResponse.status === 'success') {
        setProspectViewData(ProspectViewApiResponse.data);
      } else {
        setProspectViewData({});
      }
    })();
  }, []);

  return (
    <Page title="PULSE by Real Value Finloans || Prospect">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="View Prospect" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Application Details
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <PermContactCalendarIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Prospect ID
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {prospectViewData.leadId ? prospectViewData.leadId : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <FaRegAddressCard className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Customer ID
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {prospectViewData.applicationDetails
                          ? prospectViewData.applicationDetails.customerId
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <AppRegistrationIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Application ID
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {prospectViewData.applicationId ? prospectViewData.applicationId : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <StayCurrentPortraitIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Current Stage
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {prospectViewData.applicationDetails
                          ? prospectViewData.applicationDetails.currentStage
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <MdOutlineDateRange className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Current Status
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {prospectViewData.applicationDetails
                          ? prospectViewData.applicationDetails.currentStatus
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <UpdateIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Last Update Date
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {prospectViewData.applicationDetails
                          ? prospectViewData.applicationDetails.lastUpdatedOn
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <FaRegUser className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Sales Person
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {prospectViewData.leadSource
                          ? prospectViewData.leadSource.salesPerson
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <AssignmentIndIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Assigned On
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        N/A
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <FaRegUser className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Assigned By
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        N/A
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs="12" sm="6" md="6" mb={3}>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Loan Requirement
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {prospectViewData.loanRequirement
                              ? prospectViewData.loanRequirement.customerName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <BsTelephone className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Phone
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {prospectViewData.loanRequirement
                              ? prospectViewData.loanRequirement.mobile
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <HiOutlineMail className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Email
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {prospectViewData.loanRequirement
                              ? prospectViewData.loanRequirement.emailAddress
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <InterestsIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Interested in
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {prospectViewData.loanRequirement
                              ? prospectViewData.loanRequirement.loanTypeName
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiSwapBag className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Amount
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {prospectViewData.loanRequirement
                              ? prospectViewData.loanRequirement.loanAmountRequested
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiModernCity className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            City
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {prospectViewData.loanRequirement
                              ? prospectViewData.loanRequirement.cityName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs="12" sm="6" md="6" mb={3}>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Lead Source
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <CampaignIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Campaign Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {prospectViewData.leadSource
                              ? prospectViewData.leadSource.salesPerson
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <SourceIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Campaign Source
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            N/A
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <SupervisorAccountIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Prospect Count
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            N/A
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <DateRangeIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Prospect Date
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            N/A
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <AssignmentIndIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Assigned On
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            N/A
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Assigned By
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            N/A
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {/* <Box className="formRowHolder">
              <Button variant="contained" className="mainBtn">
                Submit
              </Button>
            </Box> */}
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Call Log
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin viewCardPadding">
              <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Call Date</StyledTableCell>
                      <StyledTableCell align="">Call Agent</StyledTableCell>
                      <StyledTableCell align="">Status</StyledTableCell>
                      <StyledTableCell align="">Comments</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableHead>
                    <TableRow>
                      <TableCell align="left">Call Date</TableCell>
                      <TableCell align="left">Call Agent</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Comments</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody>
                    {prospectViewData.callLog && prospectViewData.callLog.length > 0 ? (
                      <StyledTableRow>
                        <StyledTableCell align="">27 April, 2022</StyledTableCell>
                        <StyledTableCell align="">Ramesh</StyledTableCell>
                        <StyledTableCell align="">Pending</StyledTableCell>
                        <StyledTableCell align="">Document Pending</StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      ''
                    )}
                    {/* <StyledTableRow>
                      <StyledTableCell align="">27 April, 2022</StyledTableCell>
                      <StyledTableCell align="">Ramesh</StyledTableCell>
                      <StyledTableCell align="">Pending</StyledTableCell>
                      <StyledTableCell align="">Document Pending</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="">27 April, 2022</StyledTableCell>
                      <StyledTableCell align="">Ramesh</StyledTableCell>
                      <StyledTableCell align="">Pending</StyledTableCell>
                      <StyledTableCell align="">Document Pending</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="">27 April, 2022</StyledTableCell>
                      <StyledTableCell align="">Ramesh</StyledTableCell>
                      <StyledTableCell align="">Pending</StyledTableCell>
                      <StyledTableCell align="">Document Pending</StyledTableCell>
                    </StyledTableRow> */}
                  </TableBody>
                  {/* <TableBody>
                    <TableRow>
                      <TableCell align="left">27 April, 2022</TableCell>
                      <TableCell align="left">Ankita</TableCell>
                      <TableCell align="left">Pending</TableCell>
                      <TableCell align="left">Document not correct</TableCell>
                    </TableRow>
                  </TableBody> */}
                </Table>
              </TableContainer>
            </Box>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
export default ViewProspect;
