import { useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import exportIcon from '@iconify/icons-ant-design/export-outline';
import { CgAssign, CgEnter } from 'react-icons/cg';
import { AiOutlineStop, AiFillSetting } from 'react-icons/ai';
import { MdNotificationsNone } from 'react-icons/md';
import { HiStatusOnline, HiOutlineStatusOffline, HiTemplate } from 'react-icons/hi';
import { FiEdit } from 'react-icons/fi';
import { RiListSettingsLine } from 'react-icons/ri';
import CloseIcon from '@mui/icons-material/Close';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from '@mui/material';
import { DateRange } from 'react-date-range';
import { navigate } from '@storybook/addon-links';
import { Link, useNavigate } from 'react-router-dom';
import { RootStyle, SearchStyle, DateRangeStyled } from '../Global/Styling';
// ---------------------------------------------------------
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------

export default function JourneyTemplateToolbar({ numSelected, filterName, onFilterName, parent }) {
  const navigate = useNavigate();
  const [isOpenFilter, openFilter] = useState(false);
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  const [openAdd, setOpenAdd] = useState(false);
  const [showTextfield, setShowTextfield] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [assignValue, setAssignValue] = useState(' ');
  console.log(filterDatesRange);
  return (
    <>
      {isOpenFilter && (
        <Box px={3} pt={2}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography variant="h6" className="formLabel">
                Product
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select Product
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3} sx={{ position: 'relative' }}>
              <Typography variant="h6" className="formLabel">
                Dates
              </Typography>
              <Box className="displayDateRange">
                {filterDatesRange[0].startDate !== null
                  ? `${fDate(filterDatesRange[0].startDate)} to `
                  : ''}
                {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
              </Box>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                MenuProps={{
                  className: 'menuOpened'
                }}
                value=" "
                className="datesSelect"
              >
                <DateRangeStyled
                  editableDateInputs={false}
                  onChange={(item) => setFilterDatesRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={filterDatesRange}
                  className="dateRangePicker"
                />
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className="formLabel">
                City
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select City
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className="formLabel">
                Status
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select Status
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className="formLabel">
                Source
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select Source
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
      )}

      <RootStyle
        sx={{
          minHeight: 45,
          borderBottom: '1px solid #eaedf1'
        }}
      >
        <Box>
          {/* <Tooltip title="Add" placement="top" arrow>
            <IconButton className="squareIconButton" onClick={(e) => navigate(`/${parent}/add`)}>
              <Icon icon={add} width={18} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Configure" placement="top" arrow>
            <IconButton
              className="squareIconButton"
              onClick={(e) => navigate(`/${parent}/configure`)}
            >
              <AiFillSetting size={18} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Template" placement="top" arrow>
            <IconButton
              className="squareIconButton"
              onClick={(e) => navigate(`/${parent}/template`)}
            >
              <HiTemplate size={18} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <Box>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search Agreement"
            classes={{
              root: 'searchHolder',
              input: 'searchInput'
            }}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          {/* &nbsp;&nbsp;&nbsp;
          <Tooltip title="Filter list">
            <IconButton
              onClick={(e) => {
                if (isOpenFilter) {
                  openFilter(false);
                } else {
                  openFilter(true);
                }
              }}
            >
              <Icon icon={roundFilterList} />
            </IconButton>
          </Tooltip> */}
        </Box>
      </RootStyle>
    </>
  );
}
