import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// table component
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { MdOutlinePreview, MdUploadFile, MdUpload, MdOutlineAdd } from 'react-icons/md';
import { UserMoreMenu } from '../../components/_dashboard/user';

function VolumeCommission({
  StyledTableCell,
  StyledTableRow,
  setOpen2,
  setValidationMsg2,
  volumeArray,
  setVolumeArray,
  volumeArrayList,
  setVolumeArrayList,
  loanTypesList,
  setRefreshComp,
  branches,
  setDeleteConfirm,
  setVolumeCommissionEdit,
  volumeEditDialogOpen
}) {
  const handleAddVolume = (e) => {
    if (volumeArray.loanType === ' ') {
      setOpen2(true);
      setValidationMsg2('Please select loan type');
      return;
    }
    if (volumeArray.branch === ' ') {
      setOpen2(true);
      setValidationMsg2('Please select branch');
      return;
    }
    if (volumeArray.amount === '') {
      setOpen2(true);
      setValidationMsg2('Please enter amount');
      return;
    }
    if (volumeArray.duration === '') {
      setOpen2(true);
      setValidationMsg2('Please enter duration');
      return;
    }
    if (volumeArray.commission === '') {
      setOpen2(true);
      setValidationMsg2('Please enter commission');
      return;
    }
    if (volumeArray.unit === '') {
      setOpen2(true);
      setValidationMsg2('Please enter unit');
      return;
    } else if (volumeArray.commission > 100) {
      setOpen2(true);
      setValidationMsg2('Please enter valid commission %');
      setVolumeArray({
        ...volumeArray,
        commission: ''
      });
      return;
    }
    const prevArray = volumeArray;
    prevArray.isIndividualCommissionType = false;
    setVolumeArray(prevArray);

    const newArray = volumeArrayList;
    newArray.push(prevArray);
    setVolumeArrayList(newArray);
    console.log('setIndividualArrayList', newArray);
    setVolumeArray({
      loanType: ' ',
      branch: ' ',
      amount: '',
      duration: '',
      commission: '',
      unit: '',
      isIndividualCommissionType: null
    });
    setRefreshComp(new Date());

    // const newArray = volumeArrayList;
    // newArray.push(volumeArray);
    // setVolumeArrayList(newArray);
    // console.log('setIndividualArrayList', newArray);
    // setVolumeArray({
    //   loanType: ' ',
    //   branch: ' ',
    //   amount: '',
    //   duration: '',
    //   commission: '',
    //   unit: ''
    // });
    // setRefreshComp(new Date());
  };

  return (
    <Box className="">
      <Box className="viewLeadCard" mb={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Loan Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${volumeArray.loanType}`
                }}
                // value={selectLoan}
                // onChange={(e) => setSelectLoan(e.target.value)}
                value={volumeArray.loanType}
                onChange={(e) => {
                  const prevArray = volumeArray;
                  prevArray.loanType = e.target.value;
                  setVolumeArray(prevArray);
                  setRefreshComp(new Date());
                }}
              >
                <MenuItem value=" " disabled>
                  Select Loan Type
                </MenuItem>
                {loanTypesList.length > 0
                  ? loanTypesList.map((item, index) => {
                      console.log(item);
                      return (
                        // <MenuItem value={item.loanId} key={index}>
                        //   {item.loanName}
                        // </MenuItem>
                        <MenuItem value={JSON.stringify(item)} key={index}>
                          {item.loanName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Branch
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${volumeArray.branch}`
                }}
                // value={selectBranch}
                // onChange={(e) => setSelectBranch(e.target.value)}
                value={volumeArray.branch}
                onChange={(e) => {
                  const prevArray = volumeArray;
                  prevArray.branch = e.target.value;
                  setVolumeArray(prevArray);
                  setRefreshComp(new Date());
                }}
              >
                <MenuItem value=" " disabled>
                  Select Branch
                </MenuItem>
                {branches !== undefined && branches.length > 0
                  ? branches.map((item, index) => {
                      console.log(item);
                      return (
                        // <MenuItem value={item.branchId}>{item.branchName}</MenuItem>
                        <MenuItem value={JSON.stringify(item)} key={index}>
                          {item.branchName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Amount
              </Typography>
              <TextField
                type="number"
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Amount"
                value={volumeArray.amount}
                onChange={(e) => {
                  const prevArray = volumeArray;
                  prevArray.amount = e.target.value;
                  setVolumeArray(prevArray);
                  setRefreshComp(new Date());
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Duration
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Duration"
                value={volumeArray.duration}
                onChange={(e) => {
                  const prevArray = volumeArray;
                  prevArray.duration = e.target.value;
                  setVolumeArray(prevArray);
                  setRefreshComp(new Date());
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Commission %
              </Typography>
              <TextField
                type="number"
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Commission"
                value={volumeArray.commission}
                onChange={(e) => {
                  const prevArray = volumeArray;
                  prevArray.commission = e.target.value;
                  setVolumeArray(prevArray);
                  setRefreshComp(new Date());
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography variant="h6" className="formLabel">
                Unit
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Unit"
                sx={{ marginRight: '1rem' }}
                value={volumeArray.unit}
                onChange={(e) => {
                  const prevArray = volumeArray;
                  prevArray.unit = e.target.value;
                  setVolumeArray(prevArray);
                  setRefreshComp(new Date());
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box textAlign="right">
          <Button
            className="mainBtn otherBtn"
            variant="contained"
            onClick={(e) => handleAddVolume(e)}
          >
            Add Commission
          </Button>
        </Box>
      </Box>
      <Box className="greenBorder tableBox">
        <TableContainer sx={{ minWidth: 800, overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Loan Type</StyledTableCell>
                <StyledTableCell align="">Branch</StyledTableCell>
                <StyledTableCell align="">Amount</StyledTableCell>
                <StyledTableCell align="">Duration</StyledTableCell>
                <StyledTableCell align="">Commission %</StyledTableCell>
                <StyledTableCell align="">Unit</StyledTableCell>
                <StyledTableCell align="" width={80}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {volumeArrayList &&
                volumeArrayList.map((item, index) => (
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {JSON.parse(item.loanType).loanName}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">{item.branch}</StyledTableCell> */}
                    <StyledTableCell align="left">
                      {JSON.parse(item.branch).branchName}
                    </StyledTableCell>
                    <StyledTableCell align="left">{item.amount}</StyledTableCell>
                    <StyledTableCell align="left">{item.duration}</StyledTableCell>
                    <StyledTableCell align="left">{item.commission}</StyledTableCell>
                    <StyledTableCell align="left">{item.unit}</StyledTableCell>
                    <StyledTableCell align="">
                      <UserMoreMenu
                        parent="volume-agreement"
                        setVolumeCommissionEdit={setVolumeCommissionEdit}
                        setDeleteConfirm={setDeleteConfirm}
                        itemListVolume={item}
                        indexValueVolume={index}
                        volumeEditDialogOpen={volumeEditDialogOpen}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default VolumeCommission;
