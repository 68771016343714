import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  Divider,
  TextareaAutosize,
  Autocomplete
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function ViewFile() {
  const navigate = useNavigate();
  const [loanInterested, setLoanInterested] = useState(' ');
  const [selectLeadSourceType, setSelectLeadSourceType] = useState(' ');
  const [selectProcessingFeeStatus, setSelectProcessingFeeStatus] = useState(' ');
  const [selectDate, setSelectDate] = useState(' ');
  const [loanType, setLoanType] = useState('Home Loan');
  const [misStatus, setMisStatus] = useState('Matched');

  return (
    <Page title="Partner Management System || Receivable">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="View File" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              File Details
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Bank Name
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      defaultValue="Hdfc Bank"
                      options={topBanks}
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} fullWidth placeholder="Select Bank" />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      File No
                    </Typography>
                    <TextField
                      fullWidth
                      defaultValue="0123456"
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type File No"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Borrower Name
                    </Typography>
                    <TextField
                      fullWidth
                      defaultValue="Rakesh Sharma"
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Borrower Name"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Branch Name
                    </Typography>
                    <Autocomplete
                      disablePortal
                      defaultValue="Jaipur"
                      id="combo-box-demo"
                      options={topCities}
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} fullWidth placeholder="Select Branch" />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Employee Class
                    </Typography>
                    <TextField
                      fullWidth
                      defaultValue="E"
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Employee Class"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Disbured Date
                    </Typography>
                    <TextField
                      fullWidth
                      defaultValue="27 June, 2022"
                      inputProps={{
                        className: `textInput dissabledMenu${selectDate}`
                      }}
                      value={selectDate}
                      onChange={(e) => setSelectDate(e.target.value)}
                      placeholder="Select Disbursed Date"
                      type="date"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Disbursed Amount
                    </Typography>
                    <TextField
                      defaultValue="50,40,500"
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Disbursed Amount"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Loan Product Type
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${loanType}`
                      }}
                      value={loanType}
                      onChange={(e) => setLoanType(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Loan Product Type
                      </MenuItem>
                      <MenuItem value="Home Loan">Home Loan</MenuItem>
                      <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                      <MenuItem value="Education Loan">Education Loan</MenuItem>
                      <MenuItem value="Credit Card">Credit Card</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                {/* <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Subvention Amount
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Subvention Amount"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Campaign Code
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Campaign Code"
                    />
                  </Box>
                </Grid> */}
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      MIS Month
                    </Typography>
                    <TextField
                      fullWidth
                      defaultValue="June, 2022"
                      type="month"
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type MIS Month"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      MIS Status
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${misStatus}`
                      }}
                      value={misStatus}
                      onChange={(e) => setMisStatus(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select MIS Status
                      </MenuItem>
                      <MenuItem value="Matched">Matched</MenuItem>
                      <MenuItem value="Not Matched">Not Matched</MenuItem>
                    </Select>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Deduction details
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Total Processing Fee
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Bank Waiver, if any
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Type Waiver Amount"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Applicant Share
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Company Share
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Processing Fee Status
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox`
                      }}
                      value={selectProcessingFeeStatus}
                      onChange={(e) => setSelectProcessingFeeStatus(e.target.value)}
                    >
                      {/* <MenuItem value=" ">Select Processing Fee Status</MenuItem> */}
                      <MenuItem value=" ">Paid</MenuItem>
                      <MenuItem value="Unpaid">Unpaid</MenuItem>
                      <MenuItem value="Waived Off">Waived Off</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Fee to be Deducted from Invoice
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Invoice Calculation
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Gross Invoice Value
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Deduction
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Adjusted Invoice Value
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      GST %
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Net Invoice Value
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="&nbsp;"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Partner Payout Details
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Partner Name
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Synergy Loan"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Applicable Commission
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="5%"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Payout Amount
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="15,018"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Comments
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Box className="">
                <Typography variant="h6" className="formLabel">
                  Comments
                </Typography>
                <TextField
                  InputProps={{
                    classes: { root: 'textInput' }
                  }}
                  fullWidth
                  multiline
                  rows={4}
                  value="All files done.."
                />
              </Box>
            </Box> */}
            <Box className="" textAlign="right">
              <Button
                variant="contained"
                className="mainBtn"
                onClick={(e) => navigate('/receivable/invoice-generate')}
              >
                Generate Invoice
              </Button>
            </Box>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
export default ViewFile;

// Top Cities
const topCities = [
  { label: 'Mumbai' },
  { label: 'Delhi' },
  { label: 'Bengaluru' },
  { label: 'Ahmedabad' },
  { label: 'Hyderabad' },
  { label: 'Chennai' },
  { label: 'Kolkata' },
  { label: 'Pune' },
  { label: 'Jaipur' },
  { label: 'Surat' },
  { label: 'Lucknow' },
  { label: 'Kanpur' },
  { label: 'Nagpur' },
  { label: 'Patna' },
  { label: 'Indore' },
  { label: 'Thane' },
  { label: 'Bhopal' },
  { label: 'Visakhapatnam' },
  { label: 'Vadodara' },
  { label: 'Firozabad' },
  { label: 'Ludhiana' },
  { label: 'Rajkot' },
  { label: 'Agra' },
  { label: 'Siliguri' },
  { label: 'Nashik' },
  { label: 'Faridabad' },
  { label: 'Patiala' },
  { label: 'Meerut' },
  { label: 'Varanasi' },
  { label: 'Dhanbad' },
  { label: 'Jodhpur' },
  { label: 'Amritsar' },
  { label: 'Raipur' },
  { label: 'Allahabad' },
  { label: 'Coimbatore' },
  { label: 'Jabalpur' },
  { label: 'Gwalior' },
  { label: 'Vijayawada' },
  { label: 'Madurai' },
  { label: 'Guwahati' },
  { label: 'Chandigarh' },
  { label: 'Hubli-Dharwad' },
  { label: 'Amroha' },
  { label: 'Moradabad' },
  { label: 'Gurgaon' },
  { label: 'Aligarh' },
  { label: 'Solapur' },
  { label: 'Ranchi' },
  { label: 'Jalandhar' },
  { label: 'Tiruchirappalli' },
  { label: 'Bhubaneswar' },
  { label: 'Salem' },
  { label: 'Warangal' },
  { label: 'Mira-Bhayandar' },
  { label: 'Thiruvananthapuram' },
  { label: 'Bhiwandi' },
  { label: 'Saharanpur' },
  { label: 'Guntur' },
  { label: 'Amravati' },
  { label: 'Bikaner' },
  { label: 'Noida' },
  { label: 'Jamshedpur' },
  { label: 'Bhilai Nagar' },
  { label: 'Cuttack' },
  { label: 'Kochi' },
  { label: 'Udaipur' },
  { label: 'Bhavnagar' },
  { label: 'Dehradun' },
  { label: 'Asansol' },
  { label: 'Nanded-Waghala' },
  { label: 'Ajmer' },
  { label: 'Jamnagar' },
  { label: 'Ujjain' },
  { label: 'Sangli' },
  { label: 'Loni' },
  { label: 'Jhansi' },
  { label: 'Pondicherry' },
  { label: 'Nellore' },
  { label: 'Jammu' },
  { label: 'Belagavi' },
  { label: 'Raurkela' },
  { label: 'Mangaluru' },
  { label: 'Tirunelveli' },
  { label: 'Malegaon' },
  { label: 'Gaya' },
  { label: 'Tiruppur' },
  { label: 'Davanagere' },
  { label: 'Kozhikode' },
  { label: 'Akola' },
  { label: 'Kurnool' },
  { label: 'Bokaro' },
  { label: 'Rajahmundry' },
  { label: 'Ballari' },
  { label: 'Agartala' },
  { label: 'Bhagalpur' },
  { label: 'Latur' },
  { label: 'Dhule' },
  { label: 'Korba' },
  { label: 'Bhilwara' },
  { label: 'Brahmapur' },
  { label: 'Mysore' },
  { label: 'Muzaffarpur' },
  { label: 'Ahmednagar' },
  { label: 'Kollam' },
  { label: 'Bilaspur' },
  { label: 'Shahjahanpur' },
  { label: 'Thrissur' },
  { label: 'Alwar' },
  { label: 'Kakinada' },
  { label: 'Nizamabad' },
  { label: 'Sagar' },
  { label: 'Tumkur' },
  { label: 'Hisar' },
  { label: 'Rohtak' },
  { label: 'Panipat' },
  { label: 'Darbhanga' },
  { label: 'Kharagpur' },
  { label: 'Aizawl' },
  { label: 'Ichalkaranji' },
  { label: 'Tirupati' },
  { label: 'Karnal' },
  { label: 'Bathinda' },
  { label: 'Rampur' },
  { label: 'Shivamogga' },
  { label: 'Ratlam' },
  { label: 'Modinagar' },
  { label: 'Durg' },
  { label: 'Shillong' },
  { label: 'Imphal' },
  { label: 'Hapur' },
  { label: 'Ranipet' },
  { label: 'Anantapur' },
  { label: 'Arrah' },
  { label: 'Karimnagar' },
  { label: 'Etawah' },
  { label: 'Bharatpur' },
  { label: 'Begusarai' },
  { label: 'Chhapra' },
  { label: 'Kadapa' },
  { label: 'Pali' },
  { label: 'Satna' },
  { label: 'Vizianagaram' },
  { label: 'Katihar' },
  { label: 'Hardwar' },
  { label: 'Sonipat' },
  { label: 'Nagercoil' },
  { label: 'Thanjavur' },
  { label: 'Murwara' },
  { label: 'Naihati' },
  { label: 'Sambhal' },
  { label: 'Nadiad' },
  { label: 'Yamunanagar' },
  { label: 'Eluru' },
  { label: 'Munger' },
  { label: 'Panchkula' },
  { label: 'Deoghar' },
  { label: 'Ongole' },
  { label: 'Nandyal' },
  { label: 'Morena' },
  { label: 'Bhiwani' },
  { label: 'Porbandar' },
  { label: 'Palakkad' },
  { label: 'Anand' },
  { label: 'Purnia' },
  { label: 'Baharampur' },
  { label: 'Barmer' },
  { label: 'Morvi' },
  { label: 'Orai' },
  { label: 'Bahraich' },
  { label: 'Vellore' },
  { label: 'Khammam' },
  { label: 'Mahesana' },
  { label: 'Silchar' },
  { label: 'Sambalpur' },
  { label: 'Rewa' },
  { label: 'Unnao' },
  { label: 'Hugli-Chinsurah' },
  { label: 'Raiganj' },
  { label: 'Adityapur' },
  { label: 'Alappuzha' },
  { label: 'Bahadurgarh' },
  { label: 'Machilipatnam' },
  { label: 'Rae Bareli' },
  { label: 'Jalpaiguri' },
  { label: 'Bharuch' },
  { label: 'Hoshiarpur' },
  { label: 'Baramula' },
  { label: 'Adoni' },
  { label: 'Jind' },
  { label: 'Tonk' },
  { label: 'Tenali' },
  { label: 'Kancheepuram' },
  { label: 'Vapi' },
  { label: 'Navsari' },
  { label: 'Mahbubnagar' },
  { label: 'Puri' },
  { label: 'Erode' },
  { label: 'Batala' },
  { label: 'Haldwani' },
  { label: 'Vidisha' },
  { label: 'Saharsa' },
  { label: 'Thanesar' },
  { label: 'Chittoor' },
  { label: 'Veraval' },
  { label: 'Lakhimpur' },
  { label: 'Sitapur' },
  { label: 'Hindupur' },
  { label: 'Santipur' },
  { label: 'Balurghat' },
  { label: 'Ganjbasoda' },
  { label: 'Moga' },
  { label: 'Srinagar' },
  { label: 'Medinipur' },
  { label: 'Habra' },
  { label: 'Sasaram' },
  { label: 'Hajipur' },
  { label: 'Bhuj' },
  { label: 'Shimla' },
  { label: 'Tiruvannamalai' },
  { label: 'Kaithal' },
  { label: 'Rajnandgaon' },
  { label: 'Godhra' },
  { label: 'Hazaribag' },
  { label: 'Bhimavaram' },
  { label: 'Mandsaur' },
  { label: 'Dibrugarh' },
  { label: 'Kolar' },
  { label: 'Bankura' },
  { label: 'Mandya' },
  { label: 'Dehri-on-Sone' },
  { label: 'Madanapalle' },
  { label: 'Malerkotla' },
  { label: 'Lalitpur' },
  { label: 'Bettiah' },
  { label: 'Pollachi' },
  { label: 'Khanna' },
  { label: 'Neemuch' },
  { label: 'Palwal' },
  { label: 'Palanpur' },
  { label: 'Guntakal' },
  { label: 'Nabadwip' },
  { label: 'Udupi' },
  { label: 'Jagdalpur' },
  { label: 'Motihari' },
  { label: 'Pilibhit' },
  { label: 'Dimapur' },
  { label: 'Mohali' },
  { label: 'Dharmavaram' },
  { label: 'Kashipur' },
  { label: 'Sivakasi' },
  { label: 'Darjiling' },
  { label: 'Chikkamagaluru' },
  { label: 'Gudivada' },
  { label: 'Adilabad' },
  { label: 'Yavatmal' },
  { label: 'Barnala' },
  { label: 'Nagaon' },
  { label: 'Narasaraopet' },
  { label: 'Raigarh' },
  { label: 'Roorkee' },
  { label: 'Valsad' },
  { label: 'Ambikapur' },
  { label: 'Giridih' },
  { label: 'Chandausi' },
  { label: 'Purulia' },
  { label: 'Patan' },
  { label: 'Bagaha' },
  { label: 'Hardoi' },
  { label: 'Achalpur' },
  { label: 'Osmanabad' },
  { label: 'Deesa' },
  { label: 'Nandurbar' },
  { label: 'Azamgarh' },
  { label: 'Ramgarh' },
  { label: 'Firozpur' },
  { label: 'Baripada' },
  { label: 'Karwar' },
  { label: 'Siwan' },
  { label: 'Anantnag' },
  { label: 'Tadpatri' },
  { label: 'Satara' },
  { label: 'Bhadrak' },
  { label: 'Kishanganj' },
  { label: 'Wardha' },
  { label: 'Amreli' },
  { label: 'Neyveli' },
  { label: 'Jamalpur' },
  { label: 'Marmagao' },
  { label: 'Udgir' },
  { label: 'Tadepalligudem' },
  { label: 'Nagapattinam' },
  { label: 'Buxar' },
  { label: 'Aurangabad' },
  { label: 'Jehanabad' },
  { label: 'Khair' },
  { label: 'Sawai Madhopur' },
  { label: 'Kapurthala' },
  { label: 'Chilakaluripet' },
  { label: 'Malappuram' },
  { label: 'Rewari' },
  { label: 'Nagaur' },
  { label: 'Sultanpur' },
  { label: 'Nagda' },
  { label: 'Port Blair' },
  { label: 'Lakhisarai' },
  { label: 'Panaji' },
  { label: 'Tinsukia' },
  { label: 'Itarsi' },
  { label: 'Kohima' },
  { label: 'Balangir' },
  { label: 'Nawada' },
  { label: 'Jharsuguda' },
  { label: 'Jagtial' },
  { label: 'Viluppuram' },
  { label: 'Amalner' },
  { label: 'Zirakpur' },
  { label: 'Tanda' },
  { label: 'Tiruchengode' },
  { label: 'Nagina' },
  { label: 'Yemmiganur' },
  { label: 'Vaniyambadi' },
  { label: 'Theni Allinagaram' },
  { label: 'Margao' },
  { label: 'Akot' },
  { label: 'Mhow' },
  { label: 'Kot Kapura' },
  { label: 'Kadiri' },
  { label: 'Najibabad' },
  { label: 'Nirmal' },
  { label: 'Udhagamandalam' },
  { label: 'Jhumri Tilaiya' },
  { label: 'Aruppukkottai' },
  { label: 'Jamui' },
  { label: 'Sitamarhi' },
  { label: 'Chirala' },
  { label: 'Anjar' },
  { label: 'Karaikal' },
  { label: 'Hansi' },
  { label: 'Anakapalle' },
  { label: 'Mahasamund' },
  { label: 'Faridkot' },
  { label: 'Dhoraji' },
  { label: 'Balaghat' },
  { label: 'Khambhat' },
  { label: 'Kavali' },
  { label: 'Dhamtari' },
  { label: 'Ashok Nagar' },
  { label: 'Bargarh' },
  { label: 'Kamareddy' },
  { label: 'Kothagudem' },
  { label: 'Ramanagaram' },
  { label: 'Gokak' },
  { label: 'Tikamgarh' },
  { label: 'Araria' },
  { label: 'Rishikesh' },
  { label: 'Daltonganj' },
  { label: 'Arakkonam' },
  { label: 'Washim' },
  { label: 'Sangrur' },
  { label: 'Bodhan' },
  { label: 'Fazilka' },
  { label: 'Palacole' },
  { label: 'Keshod' },
  { label: 'Wadhwan' },
  { label: 'Gurdaspur' },
  { label: 'Vatakara' },
  { label: 'Tura' },
  { label: 'Narnaul' },
  { label: 'Kharar' },
  { label: 'Yadgir' },
  { label: 'Ambejogai' },
  { label: 'Ankleshwar' },
  { label: 'Paradip' },
  { label: 'Virudhachalam' },
  { label: 'Kanhangad' },
  { label: 'Kadi' },
  { label: 'Gobindgarh' },
  { label: 'Tindivanam' },
  { label: 'Taliparamba' },
  { label: 'Tanuku' },
  { label: 'Virudhunagar' },
  { label: 'Koyilandy' },
  { label: 'Jorhat' },
  { label: 'Karur' },
  { label: 'Valparai' },
  { label: 'Neyyattinkara' },
  { label: 'Bapatla' },
  { label: 'Fatehabad' },
  { label: 'Sankarankovil' },
  { label: 'Tenkasi' },
  { label: 'Ratnagiri' },
  { label: 'Sikandrabad' },
  { label: 'Chaibasa' },
  { label: 'Chirmiri' },
  { label: 'Bhawanipatna' },
  { label: 'Kayamkulam' },
  { label: 'Nabha' },
  { label: 'Dhenkanal' },
  { label: 'Uran Islampur' },
  { label: 'Gopalganj' },
  { label: 'Bongaigaon' },
  { label: 'Tarn Taran' },
  { label: 'Barbil' },
  { label: 'Koratla' },
  { label: 'Arambagh' },
  { label: 'Gohana' },
  { label: 'Ladnu' },
  { label: 'Tamluk' },
  { label: 'Jagraon' },
  { label: 'Alirajpur' },
  { label: 'Tandur' },
  { label: 'Naidupet' },
  { label: 'Tirupathur' },
  { label: 'Tohana' },
  { label: 'Dhubri' },
  { label: 'Visnagar' },
  { label: 'Vrindavan' },
  { label: 'Nokha' },
  { label: 'Nagari' },
  { label: 'Narwana' },
  { label: 'Ujhani' },
  { label: 'Samastipur' },
  { label: 'Laharpur' },
  { label: 'Nimbahera' },
  { label: 'Diphu' },
  { label: 'Jhargram' },
  { label: 'Tilhar' },
  { label: 'Udumalaipettai' },
  { label: 'Wanaparthy' },
  { label: 'Gudur' },
  { label: 'Kendujhar' },
  { label: 'Mandi' },
  { label: 'Nedumangad' },
  { label: 'North Lakhimpur' },
  { label: 'Vinukonda' },
  { label: 'Tiptur' },
  { label: 'Gobichettipalayam' },
  { label: 'Wani' },
  { label: 'Upleta' },
  { label: 'Narasapuram' },
  { label: 'Nuzvid' },
  { label: 'Tezpur' },
  { label: 'Una' },
  { label: 'Sheopur' },
  { label: 'Thiruvarur' },
  { label: 'Lonavla' },
  { label: 'Ponnur' },
  { label: 'Kagaznagar' },
  { label: 'Gadwal' },
  { label: 'Bhatapara' },
  { label: 'Kandukur' },
  { label: 'Unjha' },
  { label: 'Lunglei' },
  { label: 'Karimganj' },
  { label: 'Kannur' },
  { label: 'Bobbili' },
  { label: 'Talegaon Dabhade' },
  { label: 'Anjangaon' },
  { label: 'Mangrol' },
  { label: 'Gangarampur' },
  { label: 'Thiruvallur' },
  { label: 'Tirur' },
  { label: 'Jatani' },
  { label: 'Viramgam' },
  { label: 'Rajsamand' },
  { label: 'Yanam' },
  { label: 'Kottayam' },
  { label: 'Dhuri' },
  { label: 'Namakkal' },
  { label: 'Kasaragod' },
  { label: 'Kunnamkulam' },
  { label: 'Umred' },
  { label: 'Bellampalle' },
  { label: 'Ottappalam' },
  { label: 'Dumraon' },
  { label: 'Jaggaiahpet' },
  { label: 'Goalpara' },
  { label: 'Tuni' },
  { label: 'Lachhmangarh' },
  { label: 'Bhongir' },
  { label: 'Amalapuram' },
  { label: 'Firozpur Cantt.' },
  { label: 'Vikarabad' },
  { label: 'Thiruvalla' },
  { label: 'Palghar' },
  { label: 'Jangaon' },
  { label: 'Bheemunipatnam' },
  { label: 'Panna' },
  { label: 'Thodupuzha' },
  { label: 'Arwal' },
  { label: 'Venkatagiri' },
  { label: 'Kalpi' },
  { label: 'Churu' },
  { label: 'Arsikere' },
  { label: 'Ozar' },
  { label: 'Thirumangalam' },
  { label: 'Nasirabad' },
  { label: 'Nanjangud' },
  { label: 'Forbesganj' },
  { label: 'Tundla' },
  { label: 'Bhadrachalam' },
  { label: 'Madhepura' },
  { label: 'Pithoragarh' },
  { label: 'Mathura' },
  { label: 'Sultanganj' },
  { label: 'Raxaul' },
  { label: 'Pilani' },
  { label: 'Mapusa' },
  { label: 'Sivaganga' },
  { label: 'Shahpur' },
  { label: 'Ramnagar' },
  { label: 'Rameshwaram' },
  { label: 'Perambalur' },
  { label: 'Manvi' },
  { label: 'Murshidabad' },
  { label: 'Mahe' },
  { label: 'Solan' },
  { label: 'Sanawad' },
  { label: 'Bulandshahr' },
  { label: 'Satana' },
  { label: 'Sanand' },
  { label: 'Sohna' },
  { label: 'Rudrapur' },
  { label: 'Perumbavoor' },
  { label: 'Raikot' },
  { label: 'Pauri' },
  { label: 'Shahganj' },
  { label: 'Mount Abu' },
  { label: 'Silvassa' },
  { label: 'Sikanderpur' },
  { label: 'Navi Mumbai' }
];
// Top States
const topStates = [
  { label: 'Andhra Pradesh' },
  { label: 'Arunachal Pradesh' },
  { label: 'Assam' },
  { label: 'Bihar' },
  { label: 'Chhattisgarh' },
  { label: 'Goa' },
  { label: 'Gujarat' },
  { label: 'Haryana' },
  { label: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir' },
  { label: 'Jharkhand' },
  { label: 'Karnataka' },
  { label: 'Kerala' },
  { label: 'Madhya Pradesh' },
  { label: 'Maharashtra' },
  { label: 'Manipur' },
  { label: 'Meghalaya' },
  { label: 'Mizoram' },
  { label: 'Nagaland' },
  { label: 'Odisha' },
  { label: 'Punjab' },
  { label: 'Rajasthan' },
  { label: 'Sikkim' },
  { label: 'Tamil Nadu' },
  { label: 'Telangana' },
  { label: 'Tripura' },
  { label: 'Uttarakhand' },
  { label: 'Uttar Pradesh' },
  { label: 'West Bengal' },
  { label: 'Andaman and Nicobar Islands' },
  { label: 'Chandigarh' },
  { label: 'Dadra and Nagar Haveli' },
  { label: 'Daman and Diu' },
  { label: 'Delhi' },
  { label: 'Lakshadweep' },
  { label: 'Puducherry' }
];
// Top Banks
const topBanks = [
  { label: 'Axis Bank' },
  { label: 'HDFC Bank' },
  { label: 'HDFC Ltd.' },
  { label: 'ICICI Bank' },
  { label: 'PNB Housing' },
  { label: 'Piramal Housing' },
  { label: 'Standard Chartered Bank' },
  { label: 'Yes Bank' },
  { label: 'Aditya Birla Housing Finance' },
  { label: 'IDFC Bank' },
  { label: 'RBL Bank' },
  { label: 'Federal Bank' },
  { label: 'Fedfina' },
  { label: 'HDB Financial' },
  { label: 'IIFL Finance' },
  { label: 'Tata Capital' },
  { label: 'ICICI Home Finance' },
  { label: 'Cholamandalam Finance' },
  { label: 'L&T Finance' },
  { label: 'Karur Vysya Bank' },
  { label: 'Hero Housing Finance' },
  { label: 'Kotak Mahindra Bank' },
  { label: 'Fullerton Grihashakti' },
  { label: 'Anand Rathi Financial' },
  { label: 'Aadhar Housing Finance' },
  { label: 'Godrej Housing Finance' },
  { label: 'Deutsche Bank' },
  { label: 'Jana Small Finance Bank' },
  { label: 'HSBC Bank' },
  { label: 'DCB Bank' },
  { label: 'Bank of Baroda' },
  { label: 'Bajaj Housing Finance' },
  { label: 'LIC Housing Finance' },
  { label: 'AU Small Finance Bank' },
  { label: 'Union Bank of India' },
  { label: 'State Bank of India' }
];
