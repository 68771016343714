import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import UserMoreMenu from '../../components/_dashboard/user/UserMoreMenu';
import UserListHead from '../../components/_dashboard/user/UserListHead';
import UserListToolbar from '../../components/_dashboard/user/UserListToolbar';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
import partnerService from '../services/partnerService';
import Loader from '../Global/Loader';

//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';

// styling
import { StyledTableRow, StyledTableCell } from '../Global/Styling';
import OnBoardingToolbar from './OnBoardingToolbar';

// ----------------------------------------------------------------------
const { OnBoardList, UpdatePartnerStatus, PartnerStatusTypes } = partnerService;
const TABLE_HEAD = [
  { id: 'id', label: 'Partner ID', alignRight: false },
  { id: 'partnerName', label: 'Partner Name', alignRight: false },
  { id: 'partnerType', label: 'Partnery Type', alignRight: false },
  { id: 'city', label: 'Location', alignRight: false },
  { id: 'randomPhoneNumber', label: 'Mobile', alignRight: false },
  { id: 'created', label: 'Date Of Birth', alignRight: false },
  { id: 'active', label: 'Status', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function OnBoarding() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [onboradSearchList, setonboradSearchList] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [token, setToken] = useState('');
  const [companyIdValue, setCompanyIdValue] = useState(null);
  const [pageMenu, setPageMenu] = useState([]); // State for store localstorage data so that we can provide data to others components
  const [openLoader, setOpenLoader] = useState(false); // loader
  console.log('pageMenu', pageMenu);

  const [refresh, setRefresh] = useState('');
  const [onBoardListResponse, setOnBoardListResponse] = useState('');
  const [partnerActiveStatusTypes, setPartnerActiveStatusTypes] = useState('');
  const [partnerStatusTypes, setPartnerStatusTypes] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = onBoardListResponse.map((n) => {
        console.log('newname', n);
        return n;
      });
      setSelected(newSelecteds);
      return;
    }
    // setSelected(newSelecteds);
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleClick = (event, partner) => {
    const selectedIndex = selected.indexOf(partner);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, partner);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - onBoardListResponse.length) : 0;

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;
  // Action Active status const
  const UpdatePartnerStatusApi = async (rowId, partner) => {
    const PartnerStatusTypesApi = await PartnerStatusTypes(token);
    console.log('PartnerStatusTypes', PartnerStatusTypesApi);
    if (PartnerStatusTypesApi.status === 'success') {
      let partnerStatusTypeId = '';
      PartnerStatusTypesApi.data.partnerStatusTypes.forEach((item, index) => {
        if (item.typeName === 'Activated') {
          partnerStatusTypeId = item.typeId;
        }
      });
      const statusCredential = {
        companyId: partner.companyId,
        partnerId: rowId,
        partnerStatusTypeId: partnerStatusTypeId
      };
      const UpdatePartnerStatusResponse = await UpdatePartnerStatus(statusCredential, token);
      if (UpdatePartnerStatusResponse.status === 'success') {
        setRefresh(new Date());
        // console.log('UpdatePartnerStatusResponse', UpdatePartnerStatusResponse.msg);
      }

      setPartnerStatusTypes(PartnerStatusTypesApi.data);
    }
  };
  // Action Active status const

  // api for search list in toolbar
  const handleSearch = (e) => {
    setonboradSearchList(e.target.value);

    const credentials = {
      companyId: companyIdValue,
      searchLiteral: e.target.value,
      pageSize: 500,
      pageNumber: 1,
      sortOrder: 'desc'
    };

    (async () => {
      const OnBoardListApiResponse = await OnBoardList(credentials, token);
      // setOpenLoader(true);
      if (OnBoardListApiResponse.status === 'success') {
        setOpenLoader(false);
        setOnBoardListResponse(OnBoardListApiResponse.data.partners);
      } else if (OnBoardListApiResponse.error === 'Forbidden') {
        localStorage.clear();
        navigate('/login');
      }
    })();
  };

  useEffect(() => {
    // get meneu access for user from localstorage which store in <NavSection /> component
    const sidebarMenu = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d74');
    if (sidebarMenu !== null) {
      const bytes = CryptoJS.AES.decrypt(sidebarMenu, 'sidebarMenu');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);

      const pageCode = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d75');
      const pageCodeBytes = CryptoJS.AES.decrypt(pageCode, 'pageCode');
      const pageCodeText = pageCodeBytes.toString(CryptoJS.enc.Utf8);

      console.log('ciphertext', pageCodeText);
      JSON.parse(originalText).accessPagesMapping.forEach((accessPageMap) => {
        console.log('accessPageMapaccessPageMap', accessPageMap, pageCodeText);
        if (accessPageMap.pageCode === pageCodeText) {
          setPageMenu(accessPageMap.accessSubPagesMappings);
        }
      });
    }
    setOpenLoader(true);
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const authToken = localJWTtoken.token.jwt;
    setToken(authToken);
    const companyId = localJWTtoken.token.companyId;
    setCompanyIdValue(companyId);
    const credentials = {
      companyId: companyId,
      searchLiteral: '',
      pageSize: 500,
      pageNumber: 1,
      sortOrder: 'desc'
    };

    const OnBoardListApi = async () => {
      const OnBoardListApiResponse = await OnBoardList(credentials, authToken);
      // setOpenLoader(true);
      if (OnBoardListApiResponse.status === 'success') {
        setOpenLoader(false);
        setOnBoardListResponse(OnBoardListApiResponse.data.partners);
      } else if (OnBoardListApiResponse.error === 'Forbidden') {
        localStorage.clear();
        navigate('/login');
      }
    };
    OnBoardListApi();
  }, [refresh]);
  return (
    <Page title="PULSE by Real Value Finloans || On Boarding">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="On Board Your Partner" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <OnBoardingToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="onboarding"
              pageMenu={pageMenu}
              setonboradSearchList={setonboradSearchList}
              onboradSearchList={onboradSearchList}
              handleSearch={handleSearch}
            />

            <Scrollbar>
              {onBoardListResponse && onBoardListResponse.length > 0 && (
                <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={onBoardListResponse.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      isCheckBox="true"
                    />
                    <TableBody>
                      {onBoardListResponse !== undefined
                        ? onBoardListResponse.map((partner, index) => {
                            console.log('partner', partner);
                            const isItemSelected = selected.indexOf(partner) !== -1;
                            // let active = '';
                            // if (partner.status === 'success') {
                            //   active = 'Yes';
                            // } else {
                            //   active = 'No';
                            // }

                            return (
                              <StyledTableRow
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    onChange={(event) => handleClick(event, partner)}
                                  />
                                </TableCell>

                                <StyledTableCell align="left">
                                  {partner.partnerState === null ||
                                  partner.partnerStatus.typeName === 'Rejected' ||
                                  partner.partnerStatus.typeName === 'Approved' ||
                                  partner.partnerStatus.typeName === 'Inactive'
                                    ? ''
                                    : partner.partnerIdToDisplay}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {partner.partnerName}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {partner.partnerType.typeName}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {partner.cityEntity !== null
                                    ? partner.cityEntity.cityName
                                    : 'N/A'}
                                </StyledTableCell>
                                <StyledTableCell align="left">{partner.mobile}</StyledTableCell>
                                <StyledTableCell align="left">{fDate(partner.dob)}</StyledTableCell>
                                <StyledTableCell align="left">
                                  {partner.partnerStatus !== null ? (
                                    <Label
                                      variant="ghost"
                                      color={
                                        (partner.partnerStatus.typeName === 'Rejected' &&
                                          'error') ||
                                        (partner.partnerStatus.typeName === 'Approved' &&
                                          'warning') ||
                                        (partner.partnerStatus.typeName === 'Activated' &&
                                          'success') ||
                                        (partner.partnerStatus.typeName === 'Inactive' &&
                                          'greymsg') ||
                                        'warning'
                                      }
                                    >
                                      {partner.partnerStatus.typeName}
                                    </Label>
                                  ) : (
                                    'No status'
                                  )}
                                </StyledTableCell>

                                <StyledTableCell align="" width={115}>
                                  <UserMoreMenu
                                    partner={partner}
                                    rowId={partner.partnerId}
                                    parent="on-boarding"
                                    UpdatePartnerStatusApi={UpdatePartnerStatusApi}
                                    pageMenu={pageMenu}
                                    partnerState={partner.partnerStatus}
                                    setPartnerActiveStatusTypes={setPartnerActiveStatusTypes}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                        : ''}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <StyledTableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {onBoardListResponse.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            colSpan={TABLE_HEAD.length + 1}
                            sx={{ py: 3 }}
                          >
                            <SearchNotFound searchQuery={filterName} />
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              )}
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={onBoardListResponse.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <Loader openLoader={openLoader} />
        </Box>
      </Container>
    </Page>
  );
}
