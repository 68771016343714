import { useState, useEffect } from 'react';
// material
import { Box, Grid, Container, Typography, Stack, Card } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
import PageTitle from '../components/PageHeading';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [userDetails, setUserDetailsData] = useState({ token: { userFullName: '' } });
  const [refreshComp, setRefreshComp] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      // Login list Api
      const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
      setUserDetailsData(localJWTtoken);
      setRefreshComp(new Date());
    }, 100);
  }, []);
  return (
    <Page title="PULSE by Real Value Finloans">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          {/* <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Hi, Welcome back</Typography>
          </Box> */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info={`Welcome ${userDetails.token.userFullName}`} />
          </Stack>
          <Card>
            <Box py={3} px={3}>
              <Box className="displayFlex directorImgBox">
                <Box className="directorPic" mb={2}>
                  <img className="" alt="Director" src="../../static/directorPic.png" />
                </Box>
              </Box>
              <Box mb={3} className="directPositionBox">
                <Typography className="messageFrom" variant="h3">
                  Message from
                </Typography>
                <Typography className="DirectorName" variant="h4">
                  MANAGING DIRECTOR
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="subtitle2" className="mb-2">
                    Dear Friend,
                  </Typography>
                  <Typography variant="body2" className="mb-2">
                    Congratulations on signing up to the Real Value PULSE Platform!
                  </Typography>

                  <Typography variant="body2" className="mb-2">
                    In our zeal to remain ahead of the curve and dominate the Loan Distribution
                    business, we cannot ignore the role that technology will increasingly play in
                    the days ahead.
                  </Typography>

                  <Typography variant="body2" className="mb-2">
                    We at Real Value wish to be the leaders in Technology adoption in our space.
                    Real Value PULSE is the first among many technology led initiatives that you
                    will experience in the days to come.
                  </Typography>

                  <Typography variant="body2" className="mb-2">
                    Just like a body has no value without a soul, a technology platform cannot
                    deliver any significant value without regular usage.
                  </Typography>
                  <Typography variant="body2" className="mb-2">
                    I sincerely urge you to use the PULSE Platform for day to day business planning,
                    operations and monitoring so that we can cumulatively refine the platform to
                    become the Gold Standard in our space in the future.
                  </Typography>
                  <Typography variant="body2" className="mb-2">
                    Our Technology team is always at your disposal to address any issues pertaining
                    to training, bug fixing or adding additional features to make your experience in
                    using this platform an enjoyable one.
                  </Typography>

                  <Typography variant="body2" className="mb-2">
                    Please feel free to connect with them on support@RealValue in case of any
                    queries.
                  </Typography>

                  <Typography variant="body2" className="mb-3">
                    Wishing you an enjoyable and fulfilling year ahead.
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="subtitle2" className="mb-3">
                Warm Regards,
              </Typography>

              <Typography variant="subtitle2" className="">
                Prasad Parsekar
              </Typography>
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
