import { useEffect, useState } from 'react';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Tooltip,
  MenuItem,
  Chip,
  Typography,
  Select,
  Button,
  Checkbox,
  Radio,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// components
import CloseIcon from '@mui/icons-material/Close';
import { AiOutlineMinus, AiOutlineCloudUpload } from 'react-icons/ai';
import { MdOutlineAdd, MdOutlineMoreHoriz, MdModeEdit } from 'react-icons/md';
import { BiSearch } from 'react-icons/bi';
import BrudCrumbs from '../../components/BreadCrumbs';
import PageTitle from '../../components/PageHeading';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { CommonApiforJourney } from './JourneyApi';
import Loader from '../Global/Loader';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '32px',
    fontWeight: 500,
    textTransform: 'uppercase',
    // whiteSpace: 'nowrap',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '32px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));

function JourneyConfigureLanding() {
  const navigate = useNavigate();
  const [selectLoanType, setSelectLoanType] = useState(' ');
  const [selectEmploymentType, setSelectEmploymentType] = useState(' ');
  const [chipView, setChipView] = useState(false);
  const [chipViewLoan, setChipViewLoan] = useState(false);
  const [chipViewEmployment, setChipViewEmployment] = useState(false);
  const [cardEdit, setCardEdit] = useState(false);
  const [selectLoanTypeImage, setSelectLoanTypeImage] = useState(' ');
  const [selectLoanSubType, setSelectLoanSubType] = useState(' ');
  const [loanType, setLoanType] = useState(false);
  const [loanSubType, setLoanSubType] = useState(false);
  const [employmentType, setEmploymentType] = useState(false);
  const [employmentSubType, setEmploymentSubType] = useState(false);
  const [selectLoanSubTypeImage, setSelectLoanSubTypeImage] = useState(' ');
  const [photoPreview, setPhotoPreview] = useState(); // photo
  const [uploadImageFile, setUploadImageFile] = useState(); // uploaded image file

  const [openLoader, setOpenLoader] = useState(false); // loader
  const [loantyperesdata, setLoantyperesdata] = useState(null);
  const [loansubtyperesdata, setLoansubtyperesdata] = useState(null);

  const [employmenttyperesdata, setEmploymenttyperesdata] = useState(null);
  const [employmentsubtyperesdata, setEmploymentsubtyperesdata] = useState(null);
  const [documentresdata, setDocumentresdata] = useState({ parentRowId: null, allDocuments: null });

  const [loanAllcombineData, setLoanAllcombineData] = useState(null);
  const [employmentAllcombineData, setEmploymentAllcombineData] = useState(null);
  const [mapDocumentData, setMapDocumentData] = useState(null);
  const [mapIndexchippopup, setMapIndexchippopup] = useState(null);

  const [loanTypestate, setLoanTypestate] = useState({
    loanTypeDetails: [
      {
        loanType: {
          companyId: null,
          currentSequence: null,
          defaultSequence: null,
          iconPath: null,
          isActive: false,
          loanCode: null,
          parentLoanDescription: null,
          parentLoanId: null,
          parentLoanName: null,
          loanTypeIcon: null
        },
        loanSubType: [],
        loanSubTypeChipPopUp: false
      }
    ],
    employmentTypeDetails: [
      {
        employmentType: {
          companyId: null,
          employmentCategoryCode: null,
          employmentCategoryCustomLogo: null,
          employmentCategoryCustomSeq: null,
          employmentCategoryDefaultLogo: null,
          employmentCategoryDefaultSeq: null,
          employmentCategoryDescription: null,
          employmentCategoryId: null,
          employmentCategoryIsActive: null,
          employmentCategoryName: null,
          employmentTypeIcon: null
        },
        employmentSubType: [],
        employmentSubTypeChipPopUp: false
      }
    ]
  });

  useEffect(() => {
    // if (loantyperesdata !== null) {
    //   loantyperesdata.forEach((item, key) => {
    //     if (loanTypestate.loanTypeDetails !== null && loanTypestate.loanTypeDetails.length > 0) {
    //       loanTypestate.loanTypeDetails.forEach((loanType, loanTypeIndex) => {
    //         if (loanType.loanType.parentLoanName !== null) {
    //           if (item.parentLoanName === loanType.loanType.parentLoanName) {
    //             item.isDisabled = true;
    //           }
    //         }
    //       });
    //     }
    //   });
    // }
    // console.log('itemxxxmx', loantyperesdata);

    if (loanAllcombineData !== null && loanAllcombineData.length > 0) {
      const mapDocument = [];
      loanAllcombineData.forEach((loanType, index) => {
        if (employmentAllcombineData !== null && employmentAllcombineData.length > 0) {
          employmentAllcombineData.forEach((employment, empindex) => {
            const obj = { loanType, employment, documentChipPopUp: false, documentRequired: [] };
            mapDocument.push(obj);
          });
        }
      });
      if (mapDocument.length > 0) {
        setMapDocumentData(mapDocument);
        // console.log('itemdata', mapDocument);
      }
    }
    console.log('loanTypestateArray', loanTypestate);
  }, [loanTypestate, loantyperesdata, loanAllcombineData, employmentAllcombineData]);

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    console.log('itemdata', mapDocumentData);
  }, [mapDocumentData]);

  const loantyprapiFire = async (type, condition) => {
    const localJWTtoken = await JSON.parse(localStorage.getItem('userDetails'));
    const companyIdObj = {};
    let methodName = '';
    let endapiPath = '';
    const token = localJWTtoken.token.jwt;

    if (type === 'loanType') {
      companyIdObj.companyId = localJWTtoken.token.companyId;
      methodName = 'POST';
      endapiPath = 'company/parent-loan-masters/list';
      setLoanType(true);
    } else if (type === 'loanSubType') {
      if (condition !== null) {
        companyIdObj.companyId = localJWTtoken.token.companyId;
        companyIdObj.parentLoanId = condition;
        methodName = 'POST';
        endapiPath = 'company/loan-masters/list';
        setLoanSubType(true);
      }
    } else if (type === 'employmentType') {
      companyIdObj.companyId = localJWTtoken.token.companyId;
      methodName = 'POST';
      endapiPath = 'company/parent-emp-types/list';
      setEmploymentType(true);
    } else if (type === 'employmentSubType') {
      if (condition !== null) {
        companyIdObj.companyId = localJWTtoken.token.companyId;
        companyIdObj.employmentCategoryId = condition;
        methodName = 'POST';
        endapiPath = 'company/emp-types/list';
        setEmploymentSubType(true);
      }
    } else if (type === 'documentType') {
      if (Object.keys(condition).length > 0) {
        if (
          condition.loanTypeId !== null &&
          condition.loanSubtypeId !== null &&
          condition.employmentTypeId !== null &&
          condition.employmentSubtypeId !== null &&
          condition.parentRowId !== null
        ) {
          companyIdObj.companyId = localJWTtoken.token.companyId;
          companyIdObj.userId = localJWTtoken.token.userId;
          companyIdObj.loanTypeId = condition.loanTypeId;
          companyIdObj.loanSubtypeId = condition.loanSubtypeId;
          companyIdObj.employmentTypeId = condition.employmentTypeId;
          companyIdObj.employmentSubtypeId = condition.employmentSubtypeId;
          companyIdObj.parentRowId = condition.parentRowId;
          methodName = 'POST';
          endapiPath = 'journey/mapped-documents';
          setCardEdit(true);
        }
      }
    } else if (type === 'submitFormData') {
      mapDocumentData.forEach((mapItem, mapIndex) => {
        console.log('hhhhhhhhhhh', mapItem);
        console.log('final form submit', mapItem);
        methodName = 'POST';
        endapiPath = 'journey/landing-page/submit';
        const formData = new FormData();
        formData.append('parentLoanCode', mapItem.loanType.loanType.loanCode);
        formData.append('parentLoanIsActive', mapItem.loanType.loanType.isActive);

        if (mapItem.loanType.loanType.loanTypeIcon !== null) {
          formData.append('loanTypeIcon', mapItem.loanType.loanType.loanTypeIcon);
        }
        formData.append('parentLoanId', mapItem.loanType.loanType.parentLoanId);
        formData.append('userId', localJWTtoken.token.userId);
        formData.append('companyId', mapItem.loanType.compId);
        formData.append('isActive', mapItem.loanType.isActive);
        formData.append('loanCode', mapItem.loanType.loanCode);
        formData.append('loanId', mapItem.loanType.loanId);

        if (mapItem.loanType.loanSubTypeIcon !== null) {
          formData.append('loanSubTypeIcon', mapItem.loanType.loanSubTypeIcon);
        }

        formData.append(
          'employmentCategoryId',
          mapItem.employment.employmentType.employmentCategoryId
        );
        formData.append(
          'employmentCategoryCode',
          mapItem.employment.employmentType.employmentCategoryCode
        );
        formData.append(
          'employmentCategoryIsActive',
          mapItem.employment.employmentType.employmentCategoryIsActive
        );

        if (mapItem.employment.employmentType.employmentTypeIcon !== null) {
          formData.append(
            'employmentTypeIcon',
            mapItem.employment.employmentType.employmentTypeIcon
          );
        }

        formData.append('employmentSubcategoryId', mapItem.employment.employmentSubcategoryId);

        if (mapItem.employment.employmentSubTypeIcon !== null) {
          formData.append('employmentSubTypeIcon', mapItem.employment.employmentSubTypeIcon);
        }

        formData.append('employmentSubcategoryCode', mapItem.employment.employmentSubcategoryCode);
        formData.append(
          'employmentSubcategoryIsActive',
          mapItem.employment.employmentSubcategoryIsActive
        );

        loanTypebycompany(type, methodName, endapiPath, formData, token, 'formdata');

        if (mapItem.documentRequired.length > 0) {
          methodName = 'POST';
          endapiPath = 'journey/landing-page/save-document-mapping-config';
          companyIdObj.documentMappingConfigs = [];
          mapItem.documentRequired.forEach((docItemmap, docItemmapIndex) => {
            companyIdObj.companyId = localJWTtoken.token.companyId;
            companyIdObj.userId = localJWTtoken.token.userId;
            companyIdObj.documentMappingConfigs.push({
              mappId: docItemmap.mappId,
              documentId: docItemmap.documentId,
              mappIsActive: docItemmap.mappIsActive,
              loanTypeId: mapItem.loanType.loanType.parentLoanId,
              loanSubtypeId: mapItem.loanType.loanId,
              employmentTypeId: mapItem.employment.employmentType.employmentCategoryId,
              employmentSubtypeId: mapItem.employment.employmentSubcategoryId
            });
          });
          console.log('hhhhhhhhhhh sub dock', companyIdObj);
          loanTypebycompany(type, methodName, endapiPath, JSON.stringify(companyIdObj), token, '');
        }
      });
    }

    if (type !== 'submitFormData') {
      loanTypebycompany(type, methodName, endapiPath, JSON.stringify(companyIdObj), token, '');
    }
  };

  const loanTypebycompany = async (type, method, endpoint, credentials, authToken, frmData) => {
    setOpenLoader(true);
    const responseData = await CommonApiforJourney(
      method,
      endpoint,
      credentials,
      authToken,
      frmData
    );
    if (responseData.status === 'success') {
      setOpenLoader(false);
      if (type === 'loanType') {
        setLoantyperesdata(responseData.data.loanMasters);
        console.log('loantype', responseData.data.loanMasters);
      } else if (type === 'loanSubType') {
        setLoansubtyperesdata(responseData.data.loanTypes);
        console.log('loantypesubtype', responseData.data.loanTypes);
      } else if (type === 'employmentType') {
        setEmploymenttyperesdata(responseData.data.empTypes);
        console.log('employement', responseData.data.empTypes);
      } else if (type === 'employmentSubType') {
        setEmploymentsubtyperesdata(responseData.data.subEmpTypes);
        console.log('employementsubtype', responseData.data.subEmpTypes);
      } else if (type === 'documentType') {
        const parentRowIdObj = JSON.parse(credentials);

        if (parentRowIdObj.parentRowId !== null && parentRowIdObj.parentRowId !== undefined) {
          setDocumentresdata({
            parentRowId: parentRowIdObj.parentRowId,
            allDocuments: responseData.data.mappedDocuments
          });

          console.log('documentresdata', {
            parentRowId: parentRowIdObj.parentRowId,
            allDocuments: responseData.data.mappedDocuments
          });
        }
      } else if (type === 'submitFormData') {
        console.log('okkkkk success');
      }
    }
  };

  const handleChangeloantype = (event, responseData, type, index, subindex) => {
    if (type === 'loanType') {
      const prevData = loanTypestate.loanTypeDetails;
      prevData[index][type] = {
        companyId: responseData.companyId,
        currentSequence: responseData.currentSequence,
        defaultSequence: responseData.defaultSequence,
        iconPath: responseData.iconPath,
        isActive: responseData.isActive,
        loanCode: responseData.loanCode,
        parentLoanDescription: responseData.parentLoanDescription,
        parentLoanId: responseData.parentLoanId,
        parentLoanName: responseData.parentLoanName,
        loanTypeIcon: null
      };
      setLoanTypestate({
        ...loanTypestate,
        loanTypeDetails: prevData
      });
    } else if (type === 'loanSubType') {
      if (subindex !== '') {
        const prevData = loanTypestate.loanTypeDetails;
        prevData[index][type][subindex] = {
          compId: responseData.compId,
          currentSequence: responseData.currentSequence,
          defaultSequence: responseData.defaultSequence,
          iconPath: responseData.iconPath,
          isActive: responseData.isActive,
          loanCode: responseData.loanCode,
          loanDescription: responseData.loanDescription,
          loanId: responseData.loanId,
          loanName: responseData.loanName,
          loanNameToDisplay: responseData.loanNameToDisplay,
          parentLoanId: responseData.parentLoanId,
          loanSubTypeIcon: null
        };

        if (event.target.checked !== true) {
          console.log(prevData, prevData[index], prevData[index][type], subindex);
          prevData[index][type].splice(subindex, 1);
        }
        setLoanTypestate({
          ...loanTypestate,
          loanTypeDetails: prevData
        });
      }
    } else if (type === 'employmentType') {
      console.log('dataemptype', type, loanTypestate);
      const prevData = loanTypestate.employmentTypeDetails;
      prevData[index][type] = {
        companyId: responseData.companyId,
        employmentCategoryCode: responseData.employmentCategoryCode,
        employmentCategoryCustomLogo: responseData.employmentCategoryCustomLogo,
        employmentCategoryCustomSeq: responseData.employmentCategoryCustomSeq,
        employmentCategoryDefaultLogo: responseData.employmentCategoryDefaultLogo,
        employmentCategoryDefaultSeq: responseData.employmentCategoryDefaultSeq,
        employmentCategoryDescription: responseData.employmentCategoryDescription,
        employmentCategoryId: responseData.employmentCategoryId,
        employmentCategoryIsActive: responseData.employmentCategoryIsActive,
        employmentCategoryName: responseData.employmentCategoryName,
        employmentTypeIcon: null
      };
      setLoanTypestate({
        ...loanTypestate,
        employmentTypeDetails: prevData
      });
    } else if (type === 'employmentSubType') {
      if (subindex !== '') {
        const prevData = loanTypestate.employmentTypeDetails;
        prevData[index][type][subindex] = {
          companyId: responseData.companyId,
          employmentCategoryId: responseData.employmentCategoryId,
          employmentSubcategoryCode: responseData.employmentSubcategoryCode,
          employmentSubcategoryCustomLogo: responseData.employmentSubcategoryCustomLogo,
          employmentSubcategoryCustomSeq: responseData.employmentSubcategoryCustomSeq,
          employmentSubcategoryDefaultLogo: responseData.employmentSubcategoryDefaultLogo,
          employmentSubcategoryDefaultSeq: responseData.employmentSubcategoryDefaultSeq,
          employmentSubcategoryDescription: responseData.employmentSubcategoryDescription,
          employmentSubcategoryId: responseData.employmentSubcategoryId,
          employmentSubcategoryIsActive: responseData.employmentSubcategoryIsActive,
          employmentSubcategoryName: responseData.employmentSubcategoryName,
          employmentSubTypeIcon: null
        };

        if (event.target.checked !== true) {
          console.log(prevData, prevData[index], prevData[index][type], subindex);
          prevData[index][type].splice(subindex, 1);
        }
        setLoanTypestate({
          ...loanTypestate,
          employmentTypeDetails: prevData
        });
      }
    } else if (type === 'documentRequired') {
      if (subindex !== '') {
        const prevData = mapDocumentData;
        if (event.target.checked === true) {
          prevData[index][type].push(responseData);
        } else {
          const indexOfObject = prevData[index][type].findIndex(
            (object) => object.mappId === responseData.mappId
          );
          prevData[index][type].splice(indexOfObject, 1);
        }
        setMapDocumentData(prevData);
        console.log('resdataaaa111', mapDocumentData);
      }
    }
  };

  const openCloseChipPopUp = (type, openClose, index) => {
    console.log('hiiiii', type, openClose, index);
    if (type === 'loanType') {
      const prevData = loanTypestate.loanTypeDetails;
      if (openClose === 'open') {
        prevData[index].loanSubTypeChipPopUp = true;
        setLoanTypestate({
          ...loanTypestate,
          loanTypeDetails: prevData
        });
      } else {
        prevData[index].loanSubTypeChipPopUp = false;
        setLoanTypestate({
          ...loanTypestate,
          loanTypeDetails: prevData
        });
      }
    } else if (type === 'employmentType') {
      const prevData = loanTypestate.employmentTypeDetails;
      if (openClose === 'open') {
        prevData[index].employmentSubTypeChipPopUp = true;
        setLoanTypestate({
          ...loanTypestate,
          employmentTypeDetails: prevData
        });
      } else {
        prevData[index].employmentSubTypeChipPopUp = false;
        setLoanTypestate({
          ...loanTypestate,
          employmentTypeDetails: prevData
        });
      }
    } else if (type === 'documentType') {
      const prevData = mapDocumentData;
      setMapIndexchippopup(index);
      if (openClose === 'open') {
        prevData[index].documentChipPopUp = true;
      } else {
        prevData[index].documentChipPopUp = false;
        setMapIndexchippopup(null);
      }
      setMapDocumentData(prevData);
      console.log('okkkkkkkk', mapDocumentData);
    }
  };

  const addmoreloanType = (type) => {
    if (type === 'loanSubType') {
      setLoanTypestate({
        ...loanTypestate,
        loanTypeDetails: [
          ...loanTypestate.loanTypeDetails,
          {
            loanType: {
              companyId: null,
              currentSequence: null,
              defaultSequence: null,
              iconPath: null,
              isActive: false,
              loanCode: null,
              parentLoanDescription: null,
              parentLoanId: null,
              parentLoanName: null,
              loanTypeIcon: null
            },
            loanSubType: [],
            loanSubTypeChipPopUp: false
          }
        ]
      });
    } else {
      setLoanTypestate({
        ...loanTypestate,
        employmentTypeDetails: [
          ...loanTypestate.employmentTypeDetails,
          {
            employmentType: {
              companyId: null,
              employmentCategoryCode: null,
              employmentCategoryCustomLogo: null,
              employmentCategoryCustomSeq: null,
              employmentCategoryDefaultLogo: null,
              employmentCategoryDefaultSeq: null,
              employmentCategoryDescription: null,
              employmentCategoryId: null,
              employmentCategoryIsActive: null,
              employmentCategoryName: null,
              employmentTypeIcon: null
            },
            employmentSubType: [],
            employmentSubTypeChipPopUp: false
          }
        ]
      });
    }
  };

  const createArrayData = (type) => {
    if (type === 'loanSubType') {
      const loantypeArray = [];
      const prevData = loanTypestate.loanTypeDetails;
      prevData.forEach((item, key) => {
        item.loanSubType.forEach((loansubdata, ind) => {
          const obj = {
            compId: loansubdata.compId,
            currentSequence: loansubdata.currentSequence,
            defaultSequence: loansubdata.defaultSequence,
            iconPath: loansubdata.iconPath,
            isActive: loansubdata.isActive,
            loanCode: loansubdata.loanCode,
            loanDescription: loansubdata.loanDescription,
            loanId: loansubdata.loanId,
            loanName: loansubdata.loanName,
            loanNameToDisplay: loansubdata.loanNameToDisplay,
            loanSubTypeIcon: loansubdata.loanSubTypeIcon,
            parentLoanId: loansubdata.parentLoanId,
            loanType: {}
          };
          obj.loanType = item.loanType;
          loantypeArray.push(obj);
        });
      });

      if (loantypeArray.length > 0) {
        setLoanAllcombineData(loantypeArray);
      } else {
        setLoanAllcombineData(null);
      }
    } else {
      const employmenttypeArray = [];
      const prevData = loanTypestate.employmentTypeDetails;
      prevData.forEach((item, key) => {
        item.employmentSubType.forEach((employmentsubdata, ind) => {
          const obj = {
            companyId: employmentsubdata.companyId,
            employmentCategoryId: employmentsubdata.employmentCategoryId,
            employmentSubTypeIcon: employmentsubdata.employmentSubTypeIcon,
            employmentSubcategoryCode: employmentsubdata.employmentSubcategoryCode,
            employmentSubcategoryCustomLogo: employmentsubdata.employmentSubcategoryCustomLogo,
            employmentSubcategoryCustomSeq: employmentsubdata.employmentSubcategoryCustomSeq,
            employmentSubcategoryDefaultLogo: employmentsubdata.employmentSubcategoryDefaultLogo,
            employmentSubcategoryDefaultSeq: employmentsubdata.employmentSubcategoryDefaultSeq,
            employmentSubcategoryDescription: employmentsubdata.employmentSubcategoryDescription,
            employmentSubcategoryId: employmentsubdata.employmentSubcategoryId,
            employmentSubcategoryIsActive: employmentsubdata.employmentSubcategoryIsActive,
            employmentSubcategoryName: employmentsubdata.employmentSubcategoryName,
            employmentType: {}
          };
          obj.employmentType = item.employmentType;
          employmenttypeArray.push(obj);
        });
      });
      if (employmenttypeArray.length > 0) {
        setEmploymentAllcombineData(employmenttypeArray);
      } else {
        setEmploymentAllcombineData(null);
      }
    }
  };

  const removeloanType = (index, type) => {
    if (type === 'loanSubType') {
      const prevData = loanTypestate.loanTypeDetails;
      prevData.splice(index, 1);
      setLoanTypestate({ ...loanTypestate, loanTypeDetails: prevData });
    } else {
      const prevData = loanTypestate.employmentTypeDetails;
      prevData.splice(index, 1);
      setLoanTypestate({ ...loanTypestate, employmentTypeDetails: prevData });
    }
    createArrayData(type);
  };

  const uploadIcon = async (event, type, index, subindex) => {
    if (event.target.files[0] !== undefined) {
      if (type === 'loanType') {
        const prevData = loanTypestate.loanTypeDetails;
        prevData[index][type] = {
          companyId: prevData[index][type].companyId,
          currentSequence: prevData[index][type].currentSequence,
          defaultSequence: prevData[index][type].defaultSequence,
          iconPath: prevData[index][type].iconPath,
          isActive: prevData[index][type].isActive,
          loanCode: prevData[index][type].loanCode,
          parentLoanDescription: prevData[index][type].parentLoanDescription,
          parentLoanId: prevData[index][type].parentLoanId,
          parentLoanName: prevData[index][type].parentLoanName,
          loanTypeIcon: event.target.files[0]
        };
        setLoanTypestate({
          ...loanTypestate,
          loanTypeDetails: prevData
        });
      } else if (type === 'loanSubType') {
        if (subindex !== '') {
          const prevData = loanTypestate.loanTypeDetails;
          prevData[index][type][subindex] = {
            compId: prevData[index][type][subindex].compId,
            currentSequence: prevData[index][type][subindex].currentSequence,
            defaultSequence: prevData[index][type][subindex].defaultSequence,
            iconPath: prevData[index][type][subindex].iconPath,
            isActive: prevData[index][type][subindex].isActive,
            loanCode: prevData[index][type][subindex].loanCode,
            loanDescription: prevData[index][type][subindex].loanDescription,
            loanId: prevData[index][type][subindex].loanId,
            loanName: prevData[index][type][subindex].loanName,
            loanNameToDisplay: prevData[index][type][subindex].loanNameToDisplay,
            parentLoanId: prevData[index][type][subindex].parentLoanId,
            loanSubTypeIcon: event.target.files[0]
          };
          setLoanTypestate({
            ...loanTypestate,
            loanTypeDetails: prevData
          });
        }
      } else if (type === 'employmentType') {
        console.log('dataemptype', type, loanTypestate);
        const prevData = loanTypestate.employmentTypeDetails;
        prevData[index][type] = {
          companyId: prevData[index][type].companyId,
          employmentCategoryCode: prevData[index][type].employmentCategoryCode,
          employmentCategoryCustomLogo: prevData[index][type].employmentCategoryCustomLogo,
          employmentCategoryCustomSeq: prevData[index][type].employmentCategoryCustomSeq,
          employmentCategoryDefaultLogo: prevData[index][type].employmentCategoryDefaultLogo,
          employmentCategoryDefaultSeq: prevData[index][type].employmentCategoryDefaultSeq,
          employmentCategoryDescription: prevData[index][type].employmentCategoryDescription,
          employmentCategoryId: prevData[index][type].employmentCategoryId,
          employmentCategoryIsActive: prevData[index][type].employmentCategoryIsActive,
          employmentCategoryName: prevData[index][type].employmentCategoryName,
          employmentTypeIcon: event.target.files[0]
        };
        setLoanTypestate({
          ...loanTypestate,
          employmentTypeDetails: prevData
        });
      } else if (type === 'employmentSubType') {
        if (subindex !== '') {
          const prevData = loanTypestate.employmentTypeDetails;
          prevData[index][type][subindex] = {
            companyId: prevData[index][type][subindex].companyId,
            employmentCategoryId: prevData[index][type][subindex].employmentCategoryId,
            employmentSubcategoryCode: prevData[index][type][subindex].employmentSubcategoryCode,
            employmentSubcategoryCustomLogo:
              prevData[index][type][subindex].employmentSubcategoryCustomLogo,
            employmentSubcategoryCustomSeq:
              prevData[index][type][subindex].employmentSubcategoryCustomSeq,
            employmentSubcategoryDefaultLogo:
              prevData[index][type][subindex].employmentSubcategoryDefaultLogo,
            employmentSubcategoryDefaultSeq:
              prevData[index][type][subindex].employmentSubcategoryDefaultSeq,
            employmentSubcategoryDescription:
              prevData[index][type][subindex].employmentSubcategoryDescription,
            employmentSubcategoryId: prevData[index][type][subindex].employmentSubcategoryId,
            employmentSubcategoryIsActive:
              prevData[index][type][subindex].employmentSubcategoryIsActive,
            employmentSubcategoryName: prevData[index][type][subindex].employmentSubcategoryName,
            employmentSubTypeIcon: event.target.files[0]
          };
          setLoanTypestate({
            ...loanTypestate,
            employmentTypeDetails: prevData
          });
        }
      }

      // setLocaltype(e.target.files[0].name.split('.')[1]);
      // setUploadImage(e.target.files[0]);
      // setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <Page title="Partner Management System || Journey">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Landing Page" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Loan Type Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container spacing={2} mb={2} alignItems="end">
              <Grid item xs="4">
                <Box className="">
                  <Typography variant="h6" className="formLabel mar_b_0">
                    Loan Type
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel mar_b_0">
                    Loan Sub Type
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {loanTypestate.loanTypeDetails.map((item, loanindex) => (
              <Grid container spacing={2} mb={2} alignItems="end">
                <Grid item xs="4">
                  <Box className="">
                    <Box sx={{ position: 'relative' }}>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput',
                          readOnly: true
                        }}
                        value={loanTypestate.loanTypeDetails[loanindex].loanType.parentLoanName}
                        placeholder="Loan Type"
                      />

                      <Tooltip title="Edit" placement="top" arrow>
                        <StyledIconButton
                          className="actionButton searchIconB"
                          onClick={(e) => loantyprapiFire('loanType', '')}
                        >
                          <BiSearch size={18} />
                        </StyledIconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  {/* loan type pop up start */}
                  <Dialog
                    fullWidth
                    className="PopupBox"
                    open={loanType}
                    onClose={(e) => setLoanType(false)}
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6">Edit Details</Typography>
                      <IconButton onClick={(e) => setLoanType(false)} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          placeholder="Search"
                          inputProps={{
                            className: 'textInput textInputTable'
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  component={Icon}
                                  icon={searchFill}
                                  sx={{ color: 'text.disabled' }}
                                />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box className="outerTableBox">
                        <Scrollbar>
                          <TableContainer
                            sx={{ height: '250px', overflow: 'auto', background: '' }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell />
                                  <StyledTableCell align="">Loan Type</StyledTableCell>
                                  <StyledTableCell align="" width={60}>
                                    Icon
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {loantyperesdata !== null &&
                                  loantyperesdata.map((item, index) => (
                                    <StyledTableRow>
                                      <StyledTableCell padding="checkbox">
                                        <Radio
                                          disabled={item.isDisabled === true}
                                          checked={
                                            loanTypestate.loanTypeDetails[loanindex].loanType
                                              .parentLoanName === item.parentLoanName
                                          }
                                          onChange={(event) =>
                                            handleChangeloantype(
                                              event,
                                              item,
                                              'loanType',
                                              loanindex,
                                              ''
                                            )
                                          }
                                          value={item.parentLoanName}
                                          name="Loan"
                                          inputProps={{ 'aria-label': 'A' }}
                                          size="small"
                                          sx={{ padding: '0' }}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="" disabled={item.isDisabled === true}>
                                        {item.parentLoanName}
                                      </StyledTableCell>
                                      <StyledTableCell align="">
                                        <Tooltip
                                          disabled={item.isDisabled === true}
                                          title={
                                            <img
                                              src="https://uxwing.com/wp-content/themes/uxwing/download/hand-gestures/good-icon.png"
                                              alt="demo"
                                              width={100}
                                            />
                                          }
                                          size=""
                                          placement="top"
                                          arrow
                                        >
                                          <StyledIconButton className="squareIconButton actionButton">
                                            <input
                                              accept="image/png, image/gif, image/jpeg"
                                              className="fileInput"
                                              id={`loanType_${loanindex}_${item.parentLoanName}`}
                                              multiple
                                              type="file"
                                              onChange={(event) =>
                                                uploadIcon(event, 'loanType', loanindex, '')
                                              }
                                            />
                                            <label
                                              htmlFor={`loanType_${loanindex}_${item.parentLoanName}`}
                                              style={{ height: '15px', lineHeight: '15px' }}
                                            >
                                              <MdModeEdit size={14} />
                                            </label>
                                          </StyledIconButton>
                                        </Tooltip>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={(e) => {
                          setLoanType(false);
                        }}
                        variant="contained"
                        className="mainBtn"
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* loan type pop up end */}
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Stack direction="row" spacing={1} className="chipBox">
                      {loanTypestate.loanTypeDetails[loanindex].loanSubType.length > 0 ? (
                        <>
                          {loanTypestate.loanTypeDetails[loanindex].loanSubType
                            .slice(0, 2)
                            .map((item, index) => (
                              <Chip label={item.loanName} size="small" />
                            ))}
                        </>
                      ) : (
                        <>
                          <Chip label="Fresh Loan" size="small" />
                          <Chip label="LAP" size="small" />
                        </>
                      )}

                      <IconButton
                        sx={{ padding: '0' }}
                        size="small"
                        variant="subtitle2"
                        onClick={() => openCloseChipPopUp('loanType', 'open', loanindex)}
                      >
                        <MdOutlineMoreHoriz size={16} />
                      </IconButton>
                      <Tooltip title="Edit" placement="top" arrow>
                        <StyledIconButton
                          className="actionButton searchIconB"
                          onClick={() =>
                            loantyprapiFire(
                              'loanSubType',
                              loanTypestate.loanTypeDetails[loanindex].loanType.parentLoanId
                            )
                          }
                        >
                          <BiSearch size={18} />
                        </StyledIconButton>
                      </Tooltip>
                    </Stack>
                  </Box>
                  {/* loan sub type pop up start */}
                  <Dialog
                    fullWidth
                    className="PopupBox"
                    open={loanSubType}
                    onClose={(e) => setLoanSubType(false)}
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6">Edit Details</Typography>
                      <IconButton onClick={(e) => setLoanSubType(false)} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          placeholder="Search"
                          inputProps={{
                            className: 'textInput textInputTable'
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  component={Icon}
                                  icon={searchFill}
                                  sx={{ color: 'text.disabled' }}
                                />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box className="outerTableBox">
                        <Scrollbar>
                          <TableContainer
                            sx={{ height: '250px', overflow: 'auto', background: '' }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell />
                                  <StyledTableCell align="">Loan Sub Type</StyledTableCell>
                                  <StyledTableCell align="" width={60}>
                                    Icon
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {loansubtyperesdata !== null &&
                                  loansubtyperesdata !== undefined &&
                                  loansubtyperesdata.map((item, index) => (
                                    <StyledTableRow>
                                      <StyledTableCell padding="checkbox">
                                        <Checkbox
                                          onChange={(event) =>
                                            handleChangeloantype(
                                              event,
                                              item,
                                              'loanSubType',
                                              loanindex,
                                              index
                                            )
                                          }
                                          value={item.loanName}
                                          size="small"
                                          sx={{ padding: '0' }}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="">{item.loanName}</StyledTableCell>
                                      <StyledTableCell align="">
                                        <Tooltip
                                          title={<MdModeEdit size={24} />}
                                          size=""
                                          placement="top"
                                          arrow
                                        >
                                          <StyledIconButton className="squareIconButton actionButton">
                                            <input
                                              accept="image/png, image/gif, image/jpeg"
                                              className="fileInput"
                                              id={`loanSubType_${loanindex}_${item.loanName}`}
                                              multiple
                                              type="file"
                                              onChange={(event) =>
                                                uploadIcon(event, 'loanSubType', loanindex, index)
                                              }
                                            />
                                            <label
                                              htmlFor={`loanSubType_${loanindex}_${item.loanName}`}
                                              style={{ height: '15px', lineHeight: '15px' }}
                                            >
                                              <MdModeEdit size={14} />
                                            </label>
                                          </StyledIconButton>
                                        </Tooltip>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={(e) => {
                          createArrayData('loanSubType');
                          setLoanSubType(false);
                        }}
                        variant="contained"
                        className="mainBtn"
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* loan sub type pop up end */}
                  {/* all chip pop up start */}
                  <Dialog
                    className="PopupBox"
                    open={loanTypestate.loanTypeDetails[loanindex].loanSubTypeChipPopUp}
                    onClick={() => openCloseChipPopUp('loanType', 'close', loanindex)}
                    fullWidth
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6" />
                      <IconButton
                        onClick={() => openCloseChipPopUp('loanType', 'close', loanindex)}
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Stack direction="row" sx={{ flexWrap: 'wrap' }} spacing={1}>
                        {loanTypestate.loanTypeDetails[loanindex].loanSubType.map((item, index) => (
                          <Chip
                            label={item.loanName}
                            sx={{ marginBottom: '0.5rem' }}
                            size="small"
                          />
                        ))}
                      </Stack>
                    </DialogContent>
                  </Dialog>
                  {/* all chip details pop up end */}
                </Grid>
                {loanindex > 0 ? (
                  <Grid item xs="auto">
                    <Tooltip title="Remove" placement="top" arrow>
                      <IconButton
                        onClick={() => removeloanType(loanindex, 'loanSubType')}
                        className="squareIconButton"
                        sx={{ marginBottom: '4px' }}
                      >
                        <AiOutlineMinus size={18} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid item xs="auto">
                    <Tooltip title="Add" placement="top" arrow>
                      <IconButton
                        onClick={() => addmoreloanType('loanSubType')}
                        className="squareIconButton"
                        sx={{ marginBottom: '4px' }}
                      >
                        <MdOutlineAdd size={18} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>

          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Employment Type Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container spacing={2} mb={2} alignItems="end">
              <Grid item xs="4">
                <Box className="">
                  <Typography variant="h6" className="formLabel mar_b_0">
                    Employment Type
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel mar_b_0">
                    Employment Sub Type
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {loanTypestate.employmentTypeDetails.map((item, employmentindex) => (
              <Grid container spacing={2} mb={2} alignItems="end">
                <Grid item xs={4}>
                  <Box className="">
                    <Box sx={{ position: 'relative' }}>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput',
                          readOnly: true
                        }}
                        value={
                          loanTypestate.employmentTypeDetails[employmentindex].employmentType
                            .employmentCategoryName
                        }
                        placeholder="Employment Type"
                      />
                      <Tooltip title="Edit" placement="top" arrow>
                        <StyledIconButton
                          className="actionButton searchIconB"
                          onClick={() => loantyprapiFire('employmentType', '')}
                        >
                          <BiSearch size={18} />
                        </StyledIconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  {/* employment type pop up start */}
                  <Dialog
                    fullWidth
                    className="PopupBox"
                    open={employmentType}
                    onClose={(e) => setEmploymentType(false)}
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6">Edit Details</Typography>
                      <IconButton onClick={(e) => setEmploymentType(false)} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          placeholder="Search"
                          inputProps={{
                            className: 'textInput textInputTable'
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  component={Icon}
                                  icon={searchFill}
                                  sx={{ color: 'text.disabled' }}
                                />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box className="outerTableBox">
                        <Scrollbar>
                          <TableContainer
                            sx={{ height: '250px', overflow: 'auto', background: '' }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell />
                                  <StyledTableCell align="">Employment Type</StyledTableCell>
                                  <StyledTableCell align="" width={60}>
                                    Icon
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {employmenttyperesdata !== null &&
                                  employmenttyperesdata.map((item, index) => (
                                    <StyledTableRow>
                                      <StyledTableCell padding="checkbox">
                                        <Radio
                                          checked={
                                            loanTypestate.employmentTypeDetails[employmentindex]
                                              .employmentType.employmentCategoryName ===
                                            item.employmentCategoryName
                                          }
                                          onChange={(event) =>
                                            handleChangeloantype(
                                              event,
                                              item,
                                              'employmentType',
                                              employmentindex,
                                              ''
                                            )
                                          }
                                          value={item.employmentCategoryName}
                                          name="emptype"
                                          inputProps={{ 'aria-label': 'A' }}
                                          size="small"
                                          sx={{ padding: '0' }}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="">
                                        {item.employmentCategoryName}
                                      </StyledTableCell>
                                      <StyledTableCell align="">
                                        <Tooltip
                                          title={
                                            <img
                                              src="https://uxwing.com/wp-content/themes/uxwing/download/hand-gestures/good-icon.png"
                                              alt="demo"
                                              width={100}
                                            />
                                          }
                                          size=""
                                          placement="top"
                                          arrow
                                        >
                                          <StyledIconButton className="squareIconButton actionButton">
                                            <input
                                              accept="image/png, image/gif, image/jpeg"
                                              className="fileInput"
                                              id={`employmentType_${employmentindex}_${item.employmentCategoryName}`}
                                              multiple
                                              type="file"
                                              onChange={(event) =>
                                                uploadIcon(
                                                  event,
                                                  'employmentType',
                                                  employmentindex,
                                                  ''
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={`employmentType_${employmentindex}_${item.employmentCategoryName}`}
                                              style={{ height: '15px', lineHeight: '15px' }}
                                            >
                                              <MdModeEdit size={14} />
                                            </label>
                                          </StyledIconButton>
                                        </Tooltip>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={(e) => setEmploymentType(false)}
                        variant="contained"
                        className="mainBtn"
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* employment type pop up end */}
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Stack direction="row" spacing={1} className="chipBox">
                      {loanTypestate.employmentTypeDetails[employmentindex].employmentSubType
                        .length > 0 ? (
                        <>
                          {loanTypestate.employmentTypeDetails[employmentindex].employmentSubType
                            .slice(0, 2)
                            .map((item, index) => (
                              <Chip label={item.employmentSubcategoryName} size="small" />
                            ))}
                        </>
                      ) : (
                        <>
                          <Chip label="Pay Slips" size="small" />
                          <Chip label="Cash" size="small" />
                        </>
                      )}

                      <IconButton
                        sx={{ padding: '0' }}
                        size="small"
                        variant="subtitle2"
                        onClick={() =>
                          openCloseChipPopUp('employmentType', 'open', employmentindex)
                        }
                      >
                        <MdOutlineMoreHoriz size={16} />
                      </IconButton>
                      <Tooltip title="Edit" placement="top" arrow>
                        <StyledIconButton
                          className="actionButton searchIconB"
                          onClick={() =>
                            loantyprapiFire(
                              'employmentSubType',
                              loanTypestate.employmentTypeDetails[employmentindex].employmentType
                                .employmentCategoryId
                            )
                          }
                        >
                          <BiSearch size={18} />
                        </StyledIconButton>
                      </Tooltip>
                    </Stack>
                  </Box>
                  {/* employment sub type pop up start */}
                  <Dialog
                    fullWidth
                    className="PopupBox"
                    open={employmentSubType}
                    onClose={(e) => setEmploymentSubType(false)}
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6">Edit Details</Typography>
                      <IconButton onClick={(e) => setEmploymentSubType(false)} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          placeholder="Search"
                          inputProps={{
                            className: 'textInput textInputTable'
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  component={Icon}
                                  icon={searchFill}
                                  sx={{ color: 'text.disabled' }}
                                />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box className="outerTableBox">
                        <Scrollbar>
                          <TableContainer
                            sx={{ height: '250px', overflow: 'auto', background: '' }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell />
                                  <StyledTableCell align="">Employment Sub Type</StyledTableCell>
                                  <StyledTableCell align="" width={60}>
                                    Icon
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {employmentsubtyperesdata !== null &&
                                  employmentsubtyperesdata !== undefined &&
                                  employmentsubtyperesdata.map((item, index) => (
                                    <StyledTableRow>
                                      <StyledTableCell padding="checkbox">
                                        <Checkbox
                                          onChange={(event) =>
                                            handleChangeloantype(
                                              event,
                                              item,
                                              'employmentSubType',
                                              employmentindex,
                                              index
                                            )
                                          }
                                          value={item.employmentSubcategoryName}
                                          size="small"
                                          sx={{ padding: '0' }}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="">
                                        {item.employmentSubcategoryName}
                                      </StyledTableCell>
                                      <StyledTableCell align="">
                                        <Tooltip
                                          title={<MdModeEdit size={24} />}
                                          size=""
                                          placement="top"
                                          arrow
                                        >
                                          <StyledIconButton className="squareIconButton actionButton">
                                            <input
                                              accept="image/png, image/gif, image/jpeg"
                                              className="fileInput"
                                              id={`employmentSubType_${employmentindex}_${item.employmentSubcategoryName}`}
                                              multiple
                                              type="file"
                                              onChange={(event) =>
                                                uploadIcon(
                                                  event,
                                                  'employmentSubType',
                                                  employmentindex,
                                                  index
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={`employmentSubType_${employmentindex}_${item.employmentSubcategoryName}`}
                                              style={{ height: '15px', lineHeight: '15px' }}
                                            >
                                              <MdModeEdit size={14} />
                                            </label>
                                          </StyledIconButton>
                                        </Tooltip>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={(e) => {
                          createArrayData('employmentSubType');
                          setEmploymentSubType(false);
                        }}
                        variant="contained"
                        className="mainBtn"
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* employment sub type pop up end */}
                  {/* all chip pop up start */}
                  <Dialog
                    className="PopupBox"
                    open={
                      loanTypestate.employmentTypeDetails[employmentindex]
                        .employmentSubTypeChipPopUp
                    }
                    onClick={() => openCloseChipPopUp('employmentType', 'close', employmentindex)}
                    fullWidth
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6" />
                      <IconButton
                        onClick={() =>
                          openCloseChipPopUp('employmentType', 'close', employmentindex)
                        }
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Stack direction="row" sx={{ flexWrap: 'wrap' }} spacing={1}>
                        {loanTypestate.employmentTypeDetails[employmentindex].employmentSubType.map(
                          (item, index) => (
                            <Chip
                              label={item.employmentSubcategoryName}
                              sx={{ marginBottom: '0.5rem' }}
                              size="small"
                            />
                          )
                        )}
                      </Stack>
                    </DialogContent>
                  </Dialog>
                  {/* all chip details pop up end */}
                </Grid>
                {employmentindex > 0 ? (
                  <Grid item xs="auto">
                    <Tooltip title="Remove" placement="top" arrow>
                      <IconButton
                        onClick={() => removeloanType(employmentindex, 'employmentSubType')}
                        className="squareIconButton"
                        sx={{ marginBottom: '4px' }}
                      >
                        <AiOutlineMinus size={18} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid item xs="auto">
                    <Tooltip title="Add" placement="top" arrow>
                      <IconButton
                        onClick={() => addmoreloanType('employmentSubType')}
                        className="squareIconButton"
                        sx={{ marginBottom: '4px' }}
                      >
                        <MdOutlineAdd size={18} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>

          {mapDocumentData !== null ? (
            <>
              <Typography variant="body1" className="headingLineUp headingLineUpOut">
                Map Document
              </Typography>
              <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                <Box className="tableBox outerTableBox">
                  <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: '' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Loan Type</StyledTableCell>
                          <StyledTableCell align="">Loan Sub Type</StyledTableCell>
                          <StyledTableCell align="">Employment Type</StyledTableCell>
                          <StyledTableCell align="">Employment Sub Type</StyledTableCell>
                          <StyledTableCell align="" width="40%">
                            Document Required
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mapDocumentData.map((item, mapDocumentIndex) => (
                          <StyledTableRow key={`mapallDoc_${mapDocumentIndex}`}>
                            <StyledTableCell>
                              {item.loanType.loanType.parentLoanName}
                            </StyledTableCell>
                            <StyledTableCell>{item.loanType.loanName}</StyledTableCell>
                            <StyledTableCell>
                              {item.employment.employmentType.employmentCategoryName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item.employment.employmentSubcategoryName}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Stack direction="row" spacing={1} sx={{}}>
                                {item.documentRequired.length > 0 ? (
                                  <>
                                    {item.documentRequired.slice(0, 2).map((chipItemx, index) => (
                                      <Chip
                                        label={chipItemx.journeyDocumentMasterEntity.documentName}
                                        size="small"
                                      />
                                    ))}
                                  </>
                                ) : (
                                  <Chip label="Document" size="small" />
                                )}

                                <IconButton
                                  sx={{ padding: '0.25rem' }}
                                  size="small"
                                  variant="subtitle2"
                                  onClick={() =>
                                    openCloseChipPopUp('documentType', 'open', mapDocumentIndex)
                                  }
                                >
                                  <MdOutlineMoreHoriz size={16} />
                                </IconButton>
                                <Tooltip title="Edit" placement="top" arrow>
                                  <StyledIconButton
                                    className="actionButton squareIconButton"
                                    onClick={(e) => {
                                      const obj = {
                                        loanTypeId: item.loanType.loanType.parentLoanId,
                                        loanSubtypeId: item.loanType.loanId,
                                        employmentTypeId:
                                          item.employment.employmentType.employmentCategoryId,
                                        employmentSubtypeId:
                                          item.employment.employmentSubcategoryId,
                                        parentRowId: mapDocumentIndex
                                      };
                                      loantyprapiFire('documentType', obj);
                                    }}
                                  >
                                    <BiSearch size={14} />
                                  </StyledIconButton>
                                </Tooltip>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                {documentresdata.parentRowId !== null ? (
                  <Dialog
                    fullWidth
                    className="PopupBox"
                    open={cardEdit}
                    onClose={(e) => setCardEdit(false)}
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6">Edit Details</Typography>
                      <IconButton onClick={(e) => setCardEdit(false)} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <FormGroup row>
                        {documentresdata.allDocuments.map((docItem, docIndex) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  handleChangeloantype(
                                    event,
                                    docItem,
                                    'documentRequired',
                                    documentresdata.parentRowId,
                                    docIndex
                                  )
                                }
                                value={docItem?.journeyDocumentMasterEntity?.documentName}
                              />
                            }
                            label={docItem?.journeyDocumentMasterEntity?.documentName}
                          />
                        ))}
                      </FormGroup>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={(e) => setCardEdit(false)}
                        variant="contained"
                        className="mainBtn"
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : (
                  ''
                )}

                {mapDocumentData.map((mapItemDoc, chippopIndexx) => (
                  <Dialog
                    key={`mapchippop_${chippopIndexx}`}
                    className="PopupBox"
                    open={mapItemDoc.documentChipPopUp}
                    onClick={() => openCloseChipPopUp('documentType', 'close', mapIndexchippopup)}
                    fullWidth
                  >
                    <DialogTitle className="popupTitle">
                      <Typography variant="h6">Document List</Typography>
                      <IconButton
                        onClick={() =>
                          openCloseChipPopUp('documentType', 'close', mapIndexchippopup)
                        }
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Stack direction="row" sx={{ flexWrap: 'wrap' }} spacing={1}>
                        {mapItemDoc.documentRequired.map((mapSubItemDoc, index) => (
                          <Chip
                            style={{ margin: '0 10px 10px 0' }}
                            label={mapSubItemDoc?.journeyDocumentMasterEntity?.documentName}
                            size="small"
                          />
                        ))}
                      </Stack>
                    </DialogContent>
                  </Dialog>
                ))}
              </Box>
              <Box className="" textAlign="right">
                <Button
                  variant="contained"
                  className="mainBtn"
                  onClick={(e) => loantyprapiFire('submitFormData', '')}
                >
                  Submit
                </Button>
              </Box>
            </>
          ) : (
            ''
          )}
        </Box>
        <Loader openLoader={openLoader} />
      </Container>
    </Page>
  );
}

export default JourneyConfigureLanding;
