import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard/DashboardLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
import AddEditProspect from './pages/Prospect/AddEditProspect';
import AddEditChildMaster from './pages/MasterChild/AddEditChildMaster';
import AddEditUser from './pages/Users/AddEditUser';
import CallCenterList from './pages/CallCenter/CallCenterList';
import UpdateCallCenter from './pages/CallCenter/UpdateCallCenter';
import Leads from './pages/Leads/Leads';
import RejectedList from './pages/Rejected/RejectedList';
import OnBoarding from './pages/OnBoarding/OnBoarding';
import AddEditLeads from './pages/Leads/AddeditLeads';
import AddEditOnBoarding from './pages/OnBoarding/AddeditOnBoarding';
import UploadLeadProspect from './pages/Prospect/UploadLeadProspect';
import UpdateLeads from './pages/Leads/UpdateLeads';
import SubmitLeads from './pages/Leads/SubmitLeads';
import ViewProspect from './pages/Prospect/ViewProspect';
import ViewLeads from './pages/Leads/ViewLeads';
import UpdateLogIn from './pages/LogIn/UpdateLogIn';
import RevenueList from './pages/Revenue/RevenueList';
import UpdateRevenue from './pages/Revenue/UpdateRevenue';
import ReapplyRejected from './pages/Rejected/ReapplyRejected';
import Users from './pages/Users/Users';
import MasterList from './pages/Master/MasterList';
import AddEditMaster from './pages/Master/AddEditMaster';
import Integration from './pages/Integration/Integration';
// import Invoice from './pages/Invoice/Invoice';
// import UpdateInvoice from './pages/Invoice/UpdateInvoice';
import PartnersList from './pages/Partners/PartnersList';
import AddEditPartners from './pages/Partners/AddEditPartners';
import CustomerList from './pages/Customer/CustomerList';
import LogIn from './pages/LogIn/LogIn';
import DecisionLogIn from './pages/LogIn/DecisionLogIn';
// import RaiseInvoice from './pages/Invoice/RaiseInvoice';
// import PaymentInvoice from './pages/Invoice/PaymentInvoice';
import ViewLogin from './pages/LogIn/ViewLogin';
// import ViewRevenue from './pages/Revenue/ViewRevenue';
import ReviewOnBoarding from './pages/OnBoarding/ReviewOnBoarding';
import SetCommissionOnBoarding from './pages/OnBoarding/SetCommissionOnBoarding';
import BusinessReviewOnBoarding from './pages/OnBoarding/BusinessReviewOnBoarding';
import Agreement from './pages/Agreement/Agreement';
import AddAgreement from './pages/Agreement/AddAgreement';
import ViewAgreement from './pages/Agreement/ViewAgreement';
import EditAgreement from './pages/Agreement/EditAgreement';
import ViewRejected from './pages/Rejected/ViewRejected';
import ViewApplicationSummary from './pages/Leads/ViewApplicationSummary';
import SignUp from './pages/SignUp';
import SignUpSec from './pages/SignUpSec';
import SignUpCompany from './pages/SignUpCompany';
import SignUpIndividual from './pages/SignUpIndividual';
import PrivacyPolicyText from './pages/PrivacyPolicyText';
import SubmitLogin from './pages/LogIn/SubmitLogin';
import ProspectListView from './pages/Prospect/ProspectListView';
import TermConditionsText from './pages/TermConditionsText';
import EditOnBoarding from './pages/OnBoarding/EditOnBoarding';
import AgreementPolicy from './pages/Agreement/AgreementPolicy';
import AddPolicy from './pages/Agreement/AddPolicy';
import JourneyConfigure from './pages/Journey/JourneyConfigure';
import JourneyTemplate from './pages/Journey/JourneyTemplate';
import JourneyConfigureLanding from './pages/Journey/JourneyConfigureLanding';
import JourneyPublish from './pages/Journey/JourneyPublish';
import ViewJourney from './pages/Journey/ViewJourney';
import AddJourney from './pages/Journey/AddJourney';
import Journey from './pages/Journey/Journey';
import PayoutList from './pages/Payout/PayoutList';
import AddEditPayout from './pages/Payout/AddEditPayout';
import ViewPayout from './pages/Payout/ViewPayout';
import InvoiceGeneratePayout from './pages/Payout/InvoiceGeneratePayout';
import UploadBulkFilePayout from './pages/Payout/UploadBulkFilePayout';
import ViewRevenueReceipt from './pages/Revenue/ViewRevenueReceipt';
import AddEditRevenueReceipt from './pages/Revenue/AddEditRevenueReceipt';
import UploadBulkFileReceipt from './pages/Revenue/UploadBulkFileReceipt';
import InvoiceGenerateRevenue from './pages/Revenue/InvoiceGenerateRevenue';
import AddEditRevenueApproved from './pages/Revenue/AddEditRevenueApproved';
import AddEditRevenueDisbursed from './pages/Revenue/AddEditRevenueDisbursed';
import ViewRevenueApproved from './pages/Revenue/ViewRevenueApproved';
import ViewRevenueDisbursed from './pages/Revenue/ViewRevenueDisbursed';
import UploadBulkFileDisbursed from './pages/Revenue/UploadBulkFileDisbursed';
import UploadBulkFileApproved from './pages/Revenue/UploadBulkFileApproved';
import UpdateInvoice from './pages/Receivable/UpdateInvoice';
import ViewFile from './pages/Receivable/ViewFile';
import PaymentInvoice from './pages/Receivable/PaymentInvoice';
import InvoiceGenerate from './pages/Receivable/InvoiceGenerate';
import Receivable from './pages/Receivable/Receivable';
import ViewMultipleFile from './pages/Receivable/ViewMultipleFile';
import Payable from './pages/Payable/Payable';
import ViewPayable from './pages/Payable/ViewPayable';
import InvoiceGeneratePayable from './pages/Payable/InvoiceGeneratePayable';
// import InvoiceGenerate from './pages/Invoice/InvoiceGenerate';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <DashboardApp />,
          children: [{ path: '', element: <DashboardApp /> }]
        }
      ]
    },
    {
      path: '/prospect',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ProspectListView /> },
        { path: 'add', element: <AddEditProspect /> },
        { path: 'edit/:rowId', element: <AddEditProspect /> },
        { path: 'view', element: <ViewProspect /> },
        { path: 'upload', element: <UploadLeadProspect /> }
      ]
    },
    {
      path: '/onboarding',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <OnBoarding /> },
        { path: 'add', element: <AddEditOnBoarding /> },
        { path: 'review', element: <ReviewOnBoarding /> },
        { path: 'business-review', element: <BusinessReviewOnBoarding /> },
        { path: 'set-commission', element: <SetCommissionOnBoarding /> },
        { path: 'edit/:rowId', element: <EditOnBoarding /> }
      ]
    },
    // {
    //   path: '/invoice',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <Invoice /> },
    //     { path: 'raise-invoice', element: <RaiseInvoice /> },
    //     { path: 'invoice-generate', element: <InvoiceGenerate /> },
    //     { path: 'payment-invoice', element: <PaymentInvoice /> },
    //     { path: 'update', element: <UpdateInvoice /> }
    //   ]
    // },
    {
      path: '/call-center',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CallCenterList /> },
        { path: 'update', element: <UpdateCallCenter /> },
        { path: 'add', element: <AddEditChildMaster /> },
        { path: 'edit/:rowId', element: <AddEditChildMaster /> }
      ]
    },
    {
      path: '/receivable',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Receivable /> },
        { path: 'view-file', element: <ViewFile /> },
        { path: 'view-multiple-invoice', element: <ViewMultipleFile /> },
        { path: 'payment-invoice', element: <PaymentInvoice /> },
        { path: 'invoice-generate', element: <InvoiceGenerate /> },
        { path: 'update', element: <UpdateInvoice /> }
      ]
    },
    {
      path: '/revenue',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <RevenueList /> },
        { path: 'update', element: <UpdateRevenue /> },
        { path: 'add-receipt', element: <AddEditRevenueReceipt /> },
        { path: 'add-approved', element: <AddEditRevenueApproved /> },
        { path: 'add-disbursed', element: <AddEditRevenueDisbursed /> },
        { path: 'upload-receipt', element: <UploadBulkFileReceipt /> },
        { path: 'upload-approved', element: <UploadBulkFileApproved /> },
        { path: 'upload-disbursed', element: <UploadBulkFileDisbursed /> },
        { path: 'view-receipt', element: <ViewRevenueReceipt /> },
        { path: 'view-approved', element: <ViewRevenueApproved /> },
        { path: 'view-disbursed', element: <ViewRevenueDisbursed /> },
        { path: 'invoice', element: <InvoiceGenerateRevenue /> },
        { path: 'edit/:rowId', element: <AddEditRevenueReceipt /> }
      ]
    },
    {
      path: '/payout',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PayoutList /> },
        { path: 'add', element: <AddEditPayout /> },
        { path: 'upload', element: <UploadBulkFilePayout /> },
        { path: 'view', element: <ViewPayout /> },
        { path: 'invoice', element: <InvoiceGeneratePayout /> },
        { path: 'edit/:rowId', element: <AddEditPayout /> }
      ]
    },
    {
      path: '/payable',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Payable /> },
        { path: 'view', element: <ViewPayable /> },
        { path: 'invoice', element: <InvoiceGeneratePayable /> }
      ]
    },
    // {
    //   path: '/revenue',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <RevenueList /> },
    //     { path: 'add', element: <AddEditUser /> },
    //     { path: 'update', element: <UpdateRevenue /> },
    //     { path: 'view', element: <ViewRevenue /> },
    //     { path: 'edit/:rowId', element: <AddEditUser /> }
    //   ]
    // },
    {
      path: '/leads',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Leads /> },
        { path: 'add', element: <AddEditLeads /> },
        { path: 'submit', element: <SubmitLeads /> },
        { path: 'update', element: <UpdateLeads /> },
        { path: 'view', element: <ViewLeads /> },
        { path: 'application-summary', element: <ViewApplicationSummary /> },
        { path: 'edit/:rowId', element: <AddEditLeads /> }
      ]
    },
    {
      path: '/journey',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Journey /> },
        { path: 'add', element: <AddJourney /> },
        { path: 'configure', element: <JourneyConfigure /> },
        { path: 'template', element: <JourneyTemplate /> },
        { path: 'landing-page', element: <JourneyConfigureLanding /> },
        { path: 'view', element: <ViewJourney /> },
        { path: 'publish', element: <JourneyPublish /> }
      ]
    },
    {
      path: '/rejected',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <RejectedList /> },
        { path: 'reapply', element: <ReapplyRejected /> },
        { path: 'view', element: <ViewRejected /> }
      ]
    },
    {
      path: '/login',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <LogIn /> },
        { path: 'decision', element: <DecisionLogIn /> },
        { path: 'view', element: <ViewLogin /> },
        { path: 'update', element: <UpdateLogIn /> },
        { path: 'submit', element: <SubmitLogin /> }
      ]
    },
    {
      path: '/integration',
      element: <DashboardLayout />,
      children: [{ path: '', element: <Integration /> }]
    },
    {
      path: '/master',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <MasterList /> },
        { path: 'add', element: <AddEditMaster /> },
        { path: 'edit/:rowId', element: <AddEditMaster /> }
      ]
    },
    {
      path: '/users',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Users /> },
        { path: 'add', element: <AddEditUser /> },
        { path: 'edit/:rowId', element: <AddEditUser /> }
      ]
    },
    {
      path: '/partners',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PartnersList /> },
        { path: 'add', element: <AddEditPartners /> }
      ]
    },
    {
      path: '/customer',
      element: <DashboardLayout />,
      children: [{ path: '', element: <CustomerList /> }]
    },
    {
      path: '/agreement',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Agreement /> },
        { path: 'add', element: <AddAgreement /> },
        { path: 'view', element: <ViewAgreement /> },
        { path: 'policy', element: <AgreementPolicy /> },
        { path: 'add-policy', element: <AddPolicy /> },
        // { path: 'edit', element: <EditAgreement /> }
        { path: 'edit', element: <AddAgreement /> }
      ]
    },
    {
      path: '/',
      element: <Login />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/signup',
      element: <SignUp />
    },
    {
      path: '/signup-form',
      element: <SignUpSec />
    },
    {
      path: '/signup-form/company',
      element: <SignUpCompany />
    },
    {
      path: '/signup-form/individual',
      element: <SignUpIndividual />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyText />
    },
    {
      path: '/term-conditions',
      element: <TermConditionsText />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
