import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputBase,
  Stack,
  TextField,
  Typography,
  List,
  ListItem,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  DialogContentText,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Document, pdfjs, Page as Pages } from 'react-pdf';
import { Link, useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { HiDocumentText, HiOutlineIdentification } from 'react-icons/hi';
import { ImCross } from 'react-icons/im';
import { TiUserOutline } from 'react-icons/ti';
import { MdOutlineBusiness } from 'react-icons/md';
import { RiGalleryUploadLine } from 'react-icons/ri';
import { BsShieldFillCheck, BsPatchCheck } from 'react-icons/bs';
import { LibraryAddCheckIcon } from '@mui/icons-material/LibraryAddCheck';
import { AiOutlineCloudUpload, AiOutlineClose } from 'react-icons/ai';
import CloseIcon from '@mui/icons-material/Close';
// import { Player } from 'video-react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css
import AgreementText from './AgreementText';
import partnerService from './services/partnerService';
import Loader from './Loader';
import ErrorDialogBox from './ErrorDialogBox';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const {
  StateList,
  CityList,
  DocumentUpload,
  DocumentTypes,
  CreatePartner,
  RequestOtp,
  VerifyOtp,
  OnBoardReview
} = partnerService;
// /video-react/dist/video-react.css
function SignUpIndividual() {
  const [signUpAuthToken, setSignUpAuthToken] = useState('');
  const navigate = useNavigate();
  const [secondary, setSecondary] = useState(false);
  const [signupSteps, setSignupSteps] = useState('verifyIdentity');
  const [verifySteps, setVerifySteps] = useState('verifyStepOne');
  const [requestOtpData, setReuestOtp] = useState('verifyStepOne');
  const [resendDisable, setResendDisable] = useState(true);
  const [error, setError] = useState(false);

  const [helperText, setHelperText] = useState('');
  const [mobileHelper, setMobileHelper] = useState('');
  const [mobileError, setMobileError] = useState(false);

  const [counter, setCounter] = useState(1);
  const [photoPreview, setPhotoPreview] = useState(); // photo
  const [panPreview, setPanPreview] = useState(); // pan card
  const [aadharFrontPreview, setAadharFrontPreview] = useState(); // aadhaar card frontside
  const [aadharBackPreview, setAadharBackPreview] = useState(); // aadhaar card backside
  const [cancelChequePreview, setCancelChequePreview] = useState(); // cancel cheque

  const [photoPreviewtype1, setPhotoPreviewtype1] = useState(''); // photo local
  const [panPreviewtype1, setPanPreviewtype1] = useState(''); // pan card local
  const [aadharFrontPreviewtype1, setAadharFrontPreviewtype1] = useState(''); // aadhaar card frontside local
  const [aadharBackPreviewtype1, setAadharBackPreviewtype1] = useState(''); // aadhaar card backside local
  const [cancelChequePreviewtype1, setCancelChequePreviewtype1] = useState(''); // cancel cheque local
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // setting 1 to show fisrt page

  const [uploadImageFile, setUploadImageFile] = useState(''); // uploaded image file
  const [uploadImageFileFront, setUploadImageFileFront] = useState(); // uploaded image file frontside
  const [uploadImageFileBack, setUploadImageFileBack] = useState(); // uploaded image file backside
  const [uploadPanImage, setUploadPanImage] = useState();
  const [uploadChequeImage, setUploadChequeImage] = useState();
  const [selectState, setSelectState] = useState(' ');
  const [selectCity, setSelectCity] = useState(' ');
  const [isActive, setActive] = useState(false);
  const [selectDate, setSelectDate] = useState('');
  const [openAssign, setOpenAssign] = useState(false);
  // api state
  const [stateListResponse, setStateListResponse] = useState([]);
  const [cityListResponse, setCityListResponse] = useState([]);
  const [stateID, setStateID] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [cityID, setCityID] = useState('');
  const [cityValue, setCityValue] = useState('');

  const [aadharFrontPreviewtype1live, setAadharFrontPreviewtype1live] = useState('');
  const [aadharBackPreviewtype1live, setAadharBackPreviewtype1live] = useState('');
  const [panPreviewtype1live, setPanPreviewtype1live] = useState('');
  const [cancelChequePreviewtype1live, setCancelChequePreviewtype1live] = useState('');
  const [photoPreviewtype1live, setPhotoPreviewtype1live] = useState('');

  const [documentTypesList, setDocumentTypesList] = useState({});
  const [createPartnerData, setCreatePartnerData] = useState({});
  //
  const [stateListData, setStateListData] = useState([]);
  const [cityListData, setCityListData] = useState([]);
  const [checked, setChecked] = useState('');
  const [currentDate, setCurrentDate] = useState({});
  const [indDobYear, setIndDobYear] = useState({});
  const toggleClass = (box) => {
    setActive(box);
  };

  const handleFileUpload = async (
    e,
    setPreview,
    files,
    setUploadImage,
    setLocaltype,
    setLivetype
  ) => {
    if (e.target.files[0] !== undefined) {
      setLivetype('');
      setLocaltype(e.target.files[0].name.split('.')[1]);
      setUploadImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const fileDrop = (e, setPreview, setUploadImage, setLocaltype, setLivetype) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log('e.dataTransfer.files', e.dataTransfer.files);
    handleFileUpload(e, setPreview, files[0], setUploadImage, setLocaltype, setLivetype);
  };

  // Signup verify identity const by man 27 May 2022
  const handleClose = () => {
    setOpen(false);
  };
  // Valid identity
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [emailAddr, setEmailAddr] = useState();
  // Otp section
  const [otp, setOtp] = useState('');
  // Personal details
  const [fname, setFname] = useState('');
  const [address, setAddress] = useState();
  // Validation and open dialog
  const [validationMsg, setValidationMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showErrorPopUp, setshowErrorPopUp] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // Signup const

  const handleSignUp = async (e) => {
    const typeId = JSON.parse(localStorage.getItem('typeID'));
    const trackuserData = await JSON.parse(localStorage.getItem('trackuser'));
    setHelperText('');
    setError(false);
    const pattern = /^[6-9][0-9]{9}$/; // mobile regex pattern
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // email regex pattern
    if (!name || name.trim() === '') {
      setOpen(true);
      setValidationMsg('Please enter your name');
      return;
    }
    // if (!phone || phone.trim() === '') {
    //   setOpen(true);
    //   setValidationMsg('Please enter your mobile number');
    //   return;
    // } else if (phone.length < 10 || phone.length > 10) {
    //   setOpen(true);
    //   setValidationMsg('Please enter your correct mobile number');
    //   return;
    // }

    // if (phone) {
    //   setOpen(true);
    //   setValidationMsg('Please enter your phone number');
    // }

    if (!pattern.test(phone)) {
      setOpen(true);
      setValidationMsg('Please enter valid number');
      return;
    }

    if (!mailformat.test(emailAddr)) {
      setOpen(true);
      setValidationMsg('Please enter your E-Mail address');
      return;
    }

    // if (!emailAddr || emailAddr.trim() === '') {
    //   setOpen(true);
    //   setValidationMsg('Please enter your E-Mail address');
    //   return;
    // } else if (typeof emailAddr !== 'undefined') {
    //   const lastAtPos = emailAddr.lastIndexOf('@');
    //   const lastDotPos = emailAddr.lastIndexOf('.');

    //   if (
    //     !(
    //       lastAtPos < lastDotPos &&
    //       lastAtPos > 0 &&
    //       emailAddr.indexOf('@@') === -1 &&
    //       lastDotPos > 2 &&
    //       emailAddr.length - lastDotPos > 2
    //     )
    //   ) {
    //     setLoader(false);
    //     setOpen(true);
    //     setValidationMsg('Please enter your E-Mail address correctly');
    //     return;
    //   }
    // }
    // setLoader(true);
    //  create partner here

    let localData = '';
    if (trackuserData != null) {
      localData = trackuserData.isPartnerSignupContinued;
    }

    console.log('hiiiiiiiiiiiiiiii', trackuserData.isPartnerSignupContinued);

    const createCredentials = {
      companyId: 36,
      typeId: typeId.typeId, // from localStorage
      partnerName: String(name),
      mobile: String(phone),
      emailAddress: String(emailAddr),
      isSignUpFlow: true,
      isPartnerSignupContinued: localData !== '' && localData !== undefined ? localData : false
    };
    const CreatePartnerApiResponse = await CreatePartner(createCredentials, signUpAuthToken);
    if (CreatePartnerApiResponse.status === 'success') {
      if (CreatePartnerApiResponse.data.mobileNumberCheckMsg) {
        setLoader(false);
        setMobileHelper('Mobile Number Already Available');
        setMobileError(true);
        return;
      }
      setLoader(false);
      setCreatePartnerData(CreatePartnerApiResponse.data);
      localStorage.setItem('reviewobj', JSON.stringify(CreatePartnerApiResponse.data));
    }

    /*  ---------------- open when otp required ------------------- */
    // const requestotp = await RequestOtp(
    //   {
    //     mobileNumber: phone,
    //     emailAddress: emailAddr
    //   },
    //   signUpAuthToken
    // );
    // console.log('requestotprequestotp', requestotp);
    // setLoader(false);
    // if (requestotp.status === 'success') {
    //   setReuestOtp(requestotp.data);
    //   setVerifySteps('verifyStepTwo');
    // } else {
    //   setshowErrorPopUp(true);
    //   setErrorMsg('Failed to send OTP - Please try again');
    // }
    /*  ---------------- open when otp required ------------------- */

    setSignupSteps('personalDetails');
  };

  const handleSignUpTwo = async () => {
    if (!otp || otp.trim() === '') {
      setOpen(true);
      setValidationMsg('Please enter OTP');
      return;
    }
    const verifytotp = await VerifyOtp(
      {
        mobileNumber: phone,
        otp: otp,
        emailAddress: emailAddr,
        token: requestOtpData.token,
        iat: requestOtpData.iat
      },
      signUpAuthToken
    );
    console.log('requestotprequestotp', verifytotp);
    setLoader(false);
    if (verifytotp.status === 'success') {
      setSignupSteps('personalDetails');
    } else {
      setResendDisable(false);
      setError(true);
      setHelperText('Unable to verify OTP - invalid OTP! Or OTP Expired');
    }
  };

  const handleDob = (e) => {
    setSelectDate(e.target.value);
    const fullYear = new Date(e.target.value).getFullYear();
    setIndDobYear(fullYear);
  };

  // Personal Details
  const handleSignUpThree = async () => {
    const typeId = JSON.parse(localStorage.getItem('typeID'));

    if (!fname || fname.trim() === '') {
      setOpen(true);
      setValidationMsg('Please enter your father name');
      return;
    }
    // if (!selectDate || selectDate.trim() === '') {
    //   setOpen(true);
    //   setValidationMsg('Date of birth cannot be empty');
    //   return;
    // }
    const diff = currentDate - indDobYear;
    if (selectDate === '') {
      setOpen(true);
      setValidationMsg('Please enter your Date of Birth');
      return;
    } else if (diff < 18) {
      console.log('over18', currentDate - indDobYear);
      setOpen(true);
      setValidationMsg('Age should be greater then 18 years');
      return;
    }

    if (!address || address.trim() === '') {
      setOpen(true);
      setValidationMsg('Please enter your address');
      return;
    }
    if (!stateID) {
      setOpen(true);
      setValidationMsg('Please enter your city');
      return;
    }
    if (!cityID) {
      setOpen(true);
      setValidationMsg('Please enter your state');
    }

    const createCredentials = {
      companyId: 36,
      typeId: typeId.typeId, // from localStorage
      partnerName: String(name),
      fathersName: String(fname),
      dob: String(selectDate),
      mobile: String(phone),
      address: String(address),
      emailAddress: String(emailAddr),
      districtId: Number(cityID),
      provinceId: Number(stateID),
      countryId: 101,
      isSignUpFlow: true,
      partnerId: createPartnerData.partnerId
    };
    const CreatePartnerApiResponse = await CreatePartner(createCredentials, signUpAuthToken);
    if (CreatePartnerApiResponse.status === 'success') {
      // isPartnerAlreadyExist
      setCreatePartnerData(CreatePartnerApiResponse.data);
      localStorage.setItem('reviewobj', JSON.stringify(CreatePartnerApiResponse.data));
    }
    setSignupSteps('uploadDocuments');
  };
  console.log('uploadImageFile', uploadImageFile);
  // document validation
  const documentValidation = (e) => {
    // if (!uploadImageFile) {
    //   setOpen(true);
    //   setValidationMsg('Please Upload Photo');
    //   return;
    // }
    // if (!uploadImageFileFront) {
    //   setOpen(true);
    //   setValidationMsg('Please upload aadhar card front photo');
    //   return;
    // } else if (!uploadImageFileBack) {
    //   setOpen(true);
    //   setValidationMsg('Please upload aadhar card Back photo');
    //   return;
    // }
    // if (!uploadPanImage) {
    //   setOpen(true);
    //   setValidationMsg('Please Upload Pan Card Photo');
    //   return;
    // }
    // if (!uploadChequeImage) {
    //   setOpen(true);
    //   setValidationMsg('Please Upload Cancel Cheque Photo');
    //   return;
    // }

    // if (uploadImageFileFront !== undefined && uploadImageFileBack === undefined) {
    //   setOpen(true);
    //   setValidationMsg('Please upload aadhaar card backside');
    //   return;
    // }
    // if (uploadImageFileBack !== undefined && uploadImageFileFront === undefined) {
    //   setOpen(true);
    //   setValidationMsg('Please upload aadhaar card frontside');
    //   return;
    // }

    fireAllDocumentApi(e);
    setSignupSteps('results');
  };

  // fire document apis
  const fireAllDocumentApi = (e) => {
    if (uploadImageFile) {
      (async () => {
        // user photo
        console.log('hhhhhhhhhhhhh', createPartnerData);
        const formData = new FormData();
        formData.append('partnerId', createPartnerData.partnerId);
        formData.append('companyId', 36);
        documentTypesList.forEach((item) => {
          console.log(item);
          if (item.typeCode.toLowerCase() === 'USER IMAGE'.toLowerCase()) {
            formData.append('documentTypeId', item.typeId);
          }
        });
        formData.append('userImage', uploadImageFile);
        return fetch(DocumentUpload(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${signUpAuthToken}`
          },
          body: formData
        }).then((response) => {
          response.json().then((data) => {
            if (data.status === 'success') {
              console.log('aadharData', data);
            }
          });
        });
      })();
    }

    if (uploadImageFileFront || uploadImageFileBack) {
      // adhar api
      (async () => {
        // aadhar card api
        const formData = new FormData();
        formData.append('partnerId', createPartnerData.partnerId);
        formData.append('companyId', 36);
        documentTypesList.forEach((item) => {
          console.log(item);
          if (item.typeName === 'AADHAR CARD') {
            formData.append('documentTypeId', item.typeId);
          }
        });
        if (uploadImageFileFront) {
          formData.append('aadharFront', uploadImageFileFront);
        }
        if (uploadImageFileBack) {
          formData.append('aadharBack', uploadImageFileBack);
        }
        return fetch(DocumentUpload(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${signUpAuthToken}`
          },
          body: formData
        }).then((response) => {
          response.json().then((data) => {
            if (data.status === 'success') {
              console.log('aadharData', data);
            }
          });
        });
      })();
    }

    if (uploadPanImage) {
      // pan card
      (async () => {
        // pan card api
        const formData1 = new FormData();
        formData1.append('partnerId', createPartnerData.partnerId);
        formData1.append('companyId', 36);
        documentTypesList.forEach((item) => {
          console.log(item);
          if (item.typeName === 'PAN CARD') {
            formData1.append('documentTypeId', item.typeId);
          }
        });
        formData1.append('panCard', uploadPanImage);
        return fetch(DocumentUpload(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${signUpAuthToken}`
          },
          body: formData1
        }).then((response) => {
          response.json().then((data) => {
            console.log('isdatasaved', data);
            if (data.status === 'success') {
              console.log('panData', data);
            }
          });
        });
      })();
    }

    if (uploadChequeImage) {
      // cheque
      (async () => {
        // cheque api
        const formData2 = new FormData();
        formData2.append('partnerId', createPartnerData.partnerId);
        formData2.append('companyId', 36);
        documentTypesList.forEach((item) => {
          console.log(item);
          if (item.typeName === 'Cheque') {
            formData2.append('documentTypeId', item.typeId);
          }
        });
        formData2.append('cheque', uploadChequeImage);

        return fetch(DocumentUpload(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${signUpAuthToken}`
          },
          body: formData2
        }).then((response) => {
          response.json().then((data) => {
            console.log('cheque', data);
          });
        });
      })();
    }
  };
  // Signup verify identity const end
  useEffect(() => {
    let cancel = false;
    const SignUpToken = JSON.parse(localStorage.getItem('guestUser'));
    console.log('signUpAuthToken', SignUpToken);
    const SignUpAuthToken = SignUpToken.token;
    setSignUpAuthToken(SignUpAuthToken);
    console.log('signUpAuthToken', SignUpAuthToken);
    // state apis
    const credential = {
      searchLiteral: null,
      countryId: 101
    };
    const StateListApi = async () => {
      const StateListResponse = await StateList(credential, SignUpAuthToken);
      if (StateListResponse.action_status === 'success') {
        // if (cancel) return;
        setStateListResponse(StateListResponse.data);
      } else {
        setStateListResponse([]);
      }
    };
    StateListApi();

    // document types api
    (async () => {
      const DocumentTypesApiResponse = await DocumentTypes(SignUpAuthToken);
      console.log('DocumentTypesApiResponse', DocumentTypesApiResponse);
      if (DocumentTypesApiResponse.status === 'success') {
        setDocumentTypesList(DocumentTypesApiResponse.data.documentTypes);
      } else {
        setDocumentTypesList({});
      }
    })();
    const today = new Date();
    setCurrentDate(today.getFullYear());

    return () => {
      cancel = true;
    };
  }, []);

  useEffect(async () => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      trackuserapi(getactivepage);
    }
    const token = JSON.parse(localStorage.getItem('guestUser'));
    const reviewIdd = JSON.parse(localStorage.getItem('reviewobj'));

    if (reviewIdd !== null) {
      setCreatePartnerData(reviewIdd);
    }
    getReviewbyid(reviewIdd, token.token);
  }, []);

  const [reviewData, setReviewData] = useState([]);
  const getReviewbyid = async (credential, token) => {
    const reviewResponse = await OnBoardReview(credential, token);
    if (reviewResponse.status === 'success') {
      setReviewData(reviewResponse.data.partnerDocuments);
    } else {
      setReviewData([]);
    }
  };

  const trackuserapi = async (active) => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      if (getactivepage.routepage === `${active.routepage}`) {
        console.log('hello ', getactivepage);

        if (getactivepage.stageone !== undefined) {
          setSignupSteps('verifyIdentity');
          if (Object.keys(getactivepage.stageone).length > 0) {
            if (getactivepage.stageone.name !== undefined) {
              setName(getactivepage.stageone.name);
            }

            if (getactivepage.stageone.phone !== undefined) {
              setPhone(getactivepage.stageone.phone);
            }

            if (getactivepage.stageone.emailAddr !== undefined) {
              setEmailAddr(getactivepage.stageone.emailAddr);
            }
          }
        }

        if (getactivepage.stagethree !== undefined) {
          setSignupSteps('personalDetails');
          if (Object.keys(getactivepage.stagethree).length > 0) {
            if (getactivepage.stagethree.fname !== undefined) {
              setFname(getactivepage.stagethree.fname);
            }
            if (getactivepage.stagethree.selectDate !== undefined) {
              setSelectDate(getactivepage.stagethree.selectDate);
            }
            if (getactivepage.stagethree.address !== undefined) {
              setAddress(getactivepage.stagethree.address);
            }
            if (getactivepage.stagethree.state !== undefined) {
              setStateValue(getactivepage.stagethree.state.stateName);
              setStateID(getactivepage.stagethree.state.stateId);
            }
            if (getactivepage.stagethree.city !== undefined) {
              setCityValue(getactivepage.stagethree.city.cityName);
              setCityID(getactivepage.stagethree.city.cityId);
            }
          }
        }

        if (getactivepage.stagefour !== undefined) {
          setSignupSteps('uploadDocuments');
          if (reviewData.length > 0) {
            reviewData.forEach((docItem) => {
              if (docItem.documentType.typeCode === 'CHEQUE') {
                setCancelChequePreviewtype1live(docItem.contentType);
                setCancelChequePreview(docItem.documentUrl);
              }

              if (docItem.documentType.typeCode === 'PAN CARD') {
                setPanPreviewtype1live(docItem.contentType);
                setPanPreview(docItem.documentUrl);
              }
              if (
                docItem.documentType.typeCode === 'AADHAR CARD' &&
                docItem.aadharFrontFace === false
              ) {
                setAadharBackPreviewtype1live(docItem.contentType);
                setAadharBackPreview(docItem.documentUrl);
              }

              if (docItem.documentType.typeCode === 'USER IMAGE') {
                setPhotoPreviewtype1live(docItem.contentType);
                setPhotoPreview(docItem.documentUrl);
              }

              if (
                docItem.documentType.typeCode === 'AADHAR CARD' &&
                docItem.aadharFrontFace === true
              ) {
                setAadharFrontPreviewtype1live(docItem.contentType);
                setAadharFrontPreview(docItem.documentUrl);
              }
            });
          }
        }

        if (getactivepage.stagefifth !== undefined) {
          setSignupSteps('results');
        }

        if (getactivepage.stagelast !== undefined) {
          setOpenAssign(true);
        }
      }
    }
  };

  const activetab = async (type, idd) => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      if (getactivepage.routepage === 'signup-form/Individual') {
        if (idd === 0) {
          if (getactivepage.stageone !== undefined) {
            setSignupSteps(type);
            if (Object.keys(getactivepage.stageone).length > 0) {
              if (getactivepage.stageone.name !== undefined) {
                setName(getactivepage.stageone.name);
              }

              if (getactivepage.stageone.phone !== undefined) {
                setPhone(getactivepage.stageone.phone);
              }

              if (getactivepage.stageone.emailAddr !== undefined) {
                setEmailAddr(getactivepage.stageone.emailAddr);
              }
            }
          }
        }

        if (idd === 1) {
          if (getactivepage.stagethree !== undefined) {
            setSignupSteps(type);
            if (Object.keys(getactivepage.stagethree).length > 0) {
              if (getactivepage.stagethree.fname !== undefined) {
                setFname(getactivepage.stagethree.fname);
              }
              if (getactivepage.stagethree.selectDate !== undefined) {
                setSelectDate(getactivepage.stagethree.selectDate);
              }
              if (getactivepage.stagethree.address !== undefined) {
                setAddress(getactivepage.stagethree.address);
              }
              if (getactivepage.stagethree.state !== undefined) {
                setStateValue(getactivepage.stagethree.state.stateName);
                setStateID(getactivepage.stagethree.state.stateId);
              }
              if (getactivepage.stagethree.city !== undefined) {
                setCityValue(getactivepage.stagethree.city.cityName);
                setCityID(getactivepage.stagethree.city.cityId);
              }
            }
          }
        }

        if (idd === 2) {
          if (getactivepage.stagefour !== undefined) {
            setSignupSteps(type);
            if (reviewData.length > 0) {
              reviewData.forEach((docItem) => {
                if (docItem.documentType.typeCode === 'CHEQUE') {
                  setCancelChequePreviewtype1live(docItem.contentType);
                  setCancelChequePreview(docItem.documentUrl);
                }

                if (docItem.documentType.typeCode === 'PAN CARD') {
                  setPanPreviewtype1live(docItem.contentType);
                  setPanPreview(docItem.documentUrl);
                }
                if (
                  docItem.documentType.typeCode === 'AADHAR CARD' &&
                  docItem.aadharFrontFace === false
                ) {
                  setAadharBackPreviewtype1live(docItem.contentType);
                  setAadharBackPreview(docItem.documentUrl);
                }

                if (docItem.documentType.typeCode === 'USER IMAGE') {
                  setPhotoPreviewtype1live(docItem.contentType);
                  setPhotoPreview(docItem.documentUrl);
                }

                if (
                  docItem.documentType.typeCode === 'AADHAR CARD' &&
                  docItem.aadharFrontFace === true
                ) {
                  setAadharFrontPreviewtype1live(docItem.contentType);
                  setAadharFrontPreview(docItem.documentUrl);
                }
              });
            }
          }
        }

        if (idd === 3) {
          if (getactivepage.stagefifth !== undefined) {
            setSignupSteps(type);
          }
        }

        if (idd === 4) {
          if (getactivepage.stagelast !== undefined) {
            setSignupSteps(type);
          }
        }
      }
    }
  };

  return (
    <Box className="signUpOuter" sx={{ width: '100%', height: '100vh' }}>
      {/* {loader && (
        <Dialog open={loader}>
          <Box
            style={{ width: '400px', height: '300px' }}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <CircularProgress />
          </Box>
          <DialogActions>
            <Typography marginRight="20px">
              Please wait ...
            </Typography>
          </DialogActions>
        </Dialog>
      )} */}
      {loader && <Loader loader={loader} />}
      {showErrorPopUp && (
        <ErrorDialogBox
          showErrorPopUp={showErrorPopUp}
          errorMsg={errorMsg}
          setshowErrorPopUp={setshowErrorPopUp}
        />
      )}
      <Container maxWidth="fullWidth" disableGutters="true">
        <Stack>
          <Box className="signUpInner">
            <Box className="signUpLeft">
              <Box className="signUpleftInner">
                <Box>
                  <Typography variant="h6" className="signUpHeading">
                    <img
                      className="signUpLogo"
                      alt="Logo"
                      src="../../static/logos/RVPulselogoW.svg"
                    />
                  </Typography>
                  <Box className="signUpFormLeft mt-2">
                    <Box
                      className={
                        signupSteps === 'verifyIdentity'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                      // onClick={(e) => {
                      //   setSignupSteps('verifyIdentity');
                      //   setVerifySteps('verifyStepOne');
                      // }}
                      // onClick={(e) => navigate(`/signup-form`)}
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Select User Type</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Select Your User Type for next step
                        </Typography>
                      </Box>
                      <Typography
                        onClick={() => activetab('verifyIdentity', 0)}
                        className="signUpFormIconOut"
                      >
                        <HiOutlineIdentification classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                    <Box
                      className={
                        signupSteps === 'personalDetails'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                      // onClick={(e) => setSignupSteps('personalDetails')}
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Personal Details</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Fill in Your Name, Mobile No and verify your contact number
                        </Typography>
                      </Box>
                      <Typography
                        onClick={() => activetab('personalDetails', 1)}
                        className="signUpFormIconOut"
                      >
                        <TiUserOutline classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                    <Box
                      className={
                        signupSteps === 'uploadDocuments'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                      // onClick={(e) => setSignupSteps('uploadDocuments')}
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Upload Documents</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Upload your KYC documents
                        </Typography>
                      </Box>
                      <Typography
                        onClick={() => activetab('uploadDocuments', 2)}
                        className="signUpFormIconOut"
                      >
                        <RiGalleryUploadLine classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                    <Box
                      className={
                        signupSteps === 'results'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                      // onClick={(e) => setSignupSteps('results')}
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Business Agreement</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Agree to the terms &#38; conditions of the business agreement and submit
                          for activation of your id
                        </Typography>
                      </Box>
                      <Typography
                        onClick={() => activetab('results', 3)}
                        className="signUpFormIconOut"
                      >
                        <BsShieldFillCheck classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="signUpRight">
              <Box className="signUpRightInner">
                <Box className="signUpFormRight">
                  {signupSteps === 'verifyIdentity' ? (
                    <>
                      {' '}
                      {verifySteps === 'verifyStepOne' ? (
                        ({
                          /* First Step Start Here */
                        },
                        (
                          <Grid container alignItems="center" spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="h6" className="mb-1">
                                Verify Identity
                              </Typography>
                              <Typography variant="body2" className="mb-3">
                                (Please fill in your contact details to start the Process)
                              </Typography>
                              <FormControl fullWidth display="flex">
                                <Box className="mb-3">
                                  <Typography variant="h6" className="formLabel">
                                    Full Name
                                  </Typography>
                                  <TextField
                                    value={name}
                                    onChange={(e) => {
                                      setName(e.target.value);
                                      let getactivepage = '';
                                      getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                      if (getactivepage != null) {
                                        if (getactivepage.routepage === 'signup-form/Individual') {
                                          getactivepage.stageone = {};
                                          getactivepage.stageone.name = e.target.value;
                                          localStorage.setItem(
                                            'trackuser',
                                            JSON.stringify(getactivepage)
                                          );
                                        }
                                      }
                                    }}
                                    type="text"
                                    variant="outlined"
                                    inputProps={{
                                      className: 'textInput'
                                    }}
                                    placeholder="Type Your Name"
                                    fullWidth
                                  />
                                </Box>
                                <Box className="mb-3">
                                  <Typography variant="h6" className="formLabel">
                                    Mobile Number
                                  </Typography>
                                  <TextField
                                    value={phone}
                                    onChange={(e) => {
                                      setPhone(e.target.value);
                                      setMobileHelper('');
                                      setMobileError(false);
                                      let getactivepage = '';
                                      getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                      if (getactivepage != null) {
                                        if (getactivepage.routepage === 'signup-form/Individual') {
                                          getactivepage.stageone.phone = e.target.value;
                                          localStorage.setItem(
                                            'trackuser',
                                            JSON.stringify(getactivepage)
                                          );
                                        }
                                      }
                                    }}
                                    type="number"
                                    variant="outlined"
                                    inputProps={{
                                      className: 'textInput'
                                    }}
                                    placeholder="Type Mobile Number"
                                    fullWidth
                                    error={mobileError}
                                    helperText={mobileHelper}
                                  />
                                </Box>
                                <Box className="mb-3">
                                  <Typography variant="h6" className="formLabel">
                                    Email
                                  </Typography>
                                  <TextField
                                    value={emailAddr}
                                    onChange={(e) => {
                                      setEmailAddr(e.target.value);
                                      let getactivepage = '';
                                      getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                      if (getactivepage != null) {
                                        if (getactivepage.routepage === 'signup-form/Individual') {
                                          getactivepage.stageone.emailAddr = e.target.value;
                                          localStorage.setItem(
                                            'trackuser',
                                            JSON.stringify(getactivepage)
                                          );
                                        }
                                      }
                                    }}
                                    type="text"
                                    variant="outlined"
                                    inputProps={{
                                      className: 'textInput'
                                    }}
                                    placeholder="Type Email"
                                    fullWidth
                                  />
                                </Box>
                                <Button
                                  className="mainBtn"
                                  onClick={(e) => {
                                    handleSignUp(e);
                                    let getactivepage = '';
                                    getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                    if (getactivepage != null) {
                                      if (getactivepage.routepage === 'signup-form/Individual') {
                                        getactivepage.stagethree = {};
                                        localStorage.setItem(
                                          'trackuser',
                                          JSON.stringify(getactivepage)
                                        );
                                      }
                                    }
                                  }}
                                >
                                  Next
                                </Button>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <img src="../static/applicatin.jpg" alt="application" />
                            </Grid>
                          </Grid>
                        ))
                      ) : verifySteps === 'verifyStepTwo' ? (
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={6}>
                            <Typography variant="h6" className="mb-1">
                              Verify Identity
                            </Typography>
                            <Typography variant="body2" className="mb-3">
                              (We have send a One Time Password to your Mobile. Please fill in your
                              Mobile OTP to proceed further.)
                            </Typography>
                            <Box className="mb-3">
                              <Typography variant="h6" className="formLabel">
                                Mobile OTP
                              </Typography>
                              <TextField
                                value={otp}
                                error={error}
                                helperText={helperText}
                                onChange={(e) => setOtp(e.target.value)}
                                type="number"
                                variant="outlined"
                                inputProps={{
                                  className: 'textInput'
                                }}
                                placeholder="Type Mobile OTP"
                                fullWidth
                              />
                            </Box>
                            <Button
                              className="mainBtn"
                              // onClick={(e) => setSignupSteps('personalDetails')
                              onClick={(e) => {
                                handleSignUpTwo(e);
                                // let getactivepage = '';
                                // getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                // if (getactivepage != null) {
                                //   if (getactivepage.routepage === 'signup-form/Individual') {
                                //     getactivepage.stagethree = {};
                                //     localStorage.setItem(
                                //       'trackuser',
                                //       JSON.stringify(getactivepage)
                                //     );
                                //   }
                                // }
                              }}
                            >
                              Next
                            </Button>
                            <Button
                              disabled={resendDisable}
                              className="mainBtn"
                              sx={{ marginLeft: '15px' }}
                              // onClick={(e) => setSignupSteps('personalDetails')
                              onClick={(e) => {
                                if (counter < 3) {
                                  setCounter(counter + 1);
                                  const requestotpApi = async () => {
                                    const requestotp = await RequestOtp(
                                      {
                                        mobileNumber: phone,
                                        emailAddress: emailAddr
                                      },
                                      signUpAuthToken
                                    );
                                    if (requestotp.status === 'success') {
                                      setReuestOtp(requestotp.data);
                                      setVerifySteps('verifyStepTwo');
                                    }
                                  };

                                  requestotpApi();
                                } else {
                                  setVerifySteps('verifyStepOne');
                                }
                              }}
                            >
                              Resend OTP
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <img src="../static/applicatin.jpg" alt="application" />
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </>
                  ) : signupSteps === 'personalDetails' ? (
                    <>
                      <Typography variant="h6" className="mb-1">
                        Personal Details
                      </Typography>
                      <Typography variant="body2" className="mb-3">
                        (Please fill in your personal details for the next step)
                      </Typography>
                      <Grid container spacing={3} mb={3}>
                        <Grid item xs={12} sm={6}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              Father Name
                            </Typography>
                            <TextField
                              value={fname}
                              onChange={(e) => {
                                setFname(e.target.value);
                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Individual') {
                                    getactivepage.stagethree.fname = e.target.value;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }
                              }}
                              type="text"
                              variant="outlined"
                              inputProps={{
                                className: 'textInput'
                              }}
                              placeholder="Type Father Name"
                              fullWidth
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              DOB
                            </Typography>
                            <TextField
                              variant="outlined"
                              inputProps={{
                                className: `textInput dissabledMenu${selectDate}`
                              }}
                              placeholder="Select Date of Birth"
                              fullWidth
                              type="date"
                              value={selectDate}
                              // onBlur={(e) => {
                              //   const currentDate = new Date().getFullYear();
                              //   const enterDate = new Date(e.target.value).getFullYear();
                              //   console.log('onblur', currentDate);
                              //   console.log('onblur', enterDate);
                              //   if (currentDate - enterDate > 18) {
                              //     console.log('compare', currentDate - enterDate);
                              //     setSelectDate(e.target.value);
                              //   } else {
                              //     console.log('compare', currentDate - enterDate);
                              //     setSelectDate(e.target.value);
                              //     setOpen(true);
                              //     setValidationMsg(' Age Should Be Greater Then 18');
                              //     setSelectDate('');
                              //   }
                              // }}
                              onChange={(e) => {
                                handleDob(e);

                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Individual') {
                                    getactivepage.stagethree.selectDate = e.target.value;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }

                                // const currentDate = new Date().getFullYear();
                                // const enterDate = new Date(e.target.value).getFullYear();
                                // console.log('currentDate', currentDate);
                                // console.log('currentDate', enterDate);

                                // setSelectDate(e.target.value);

                                // if (currentDate - enterDate > 18) {
                                //   console.log('compare', currentDate - enterDate);
                                //   setSelectDate(e.target.value);
                                // } else {
                                //   console.log('compare', currentDate - enterDate);
                                //   setSelectDate(e.target.value);
                                //   setOpen(true);
                                //   setValidationMsg('Please enter age greater then 18');
                                //   setSelectDate('');
                                // }
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              Address
                            </Typography>
                            <TextField
                              value={address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Individual') {
                                    getactivepage.stagethree.address = e.target.value;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }
                              }}
                              type="text"
                              variant="outlined"
                              inputProps={{
                                className: 'textInput'
                              }}
                              placeholder="Type Address"
                              fullWidth
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              State
                            </Typography>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo3"
                              options={stateListResponse}
                              size="small"
                              value={stateValue}
                              onChange={(e, newValue) => {
                                const credential = {
                                  searchLiteral: null,
                                  stateId: newValue.stateId
                                };
                                const CityListApi = async () => {
                                  const CityListResponse = await CityList(
                                    credential,
                                    signUpAuthToken
                                  );
                                  if (CityListResponse.action_status === 'success') {
                                    setCityListResponse(CityListResponse.data);
                                  }
                                };
                                CityListApi();
                                setStateValue(newValue.stateName);
                                setStateID(newValue.stateId);
                                // setCityListData([]);
                                // setCityListMenu([]);
                                setStateListData(newValue);
                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Individual') {
                                    getactivepage.stagethree.state = newValue;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }
                              }}
                              getOptionLabel={(option) =>
                                option.stateName ? option.stateName : stateValue
                              }
                              renderOption={(props, option) => (
                                <li {...props}>{option.stateName}</li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth placeholder="Select State" />
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              City
                            </Typography>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo3"
                              options={cityListResponse}
                              size="small"
                              value={cityValue}
                              onChange={(e, newValue) => {
                                // setCityListData([]);
                                // setCityListMenu([]);
                                setCityListData(newValue);
                                setCityValue(newValue.cityName);
                                setCityID(newValue.cityId);
                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Individual') {
                                    getactivepage.stagethree.city = newValue;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }
                              }}
                              getOptionLabel={(option) =>
                                option.cityName ? option.cityName : cityValue
                              }
                              renderOption={(props, option) => (
                                <li {...props}>{option.cityName}</li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth placeholder="Select City" />
                              )}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Button
                        className="mainBtn"
                        onClick={(e) => {
                          handleSignUpThree(e);
                          let getactivepage = '';
                          getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                          if (getactivepage != null) {
                            if (getactivepage.routepage === 'signup-form/Individual') {
                              getactivepage.stagefour = {};
                              localStorage.setItem('trackuser', JSON.stringify(getactivepage));
                            }
                          }
                        }}
                      >
                        Next
                      </Button>
                    </>
                  ) : signupSteps === 'uploadDocuments' ? (
                    <>
                      <Typography variant="h6" className="mb-1">
                        Upload Documents
                      </Typography>
                      <Typography variant="body2" className="mb-3">
                        (Please Upload your KYC documents for the next step)
                      </Typography>
                      <Grid container alignItems="center" spacing={3} mb={3}>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file1"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleFileUpload(
                                e,
                                setPhotoPreview,
                                null,
                                setUploadImageFile,
                                setPhotoPreviewtype1,
                                setPhotoPreviewtype1live
                              );
                            }}
                          />
                          <label
                            htmlFor="contained-button-file1"
                            className={
                              photoPreviewtype1 === 'pdf' ||
                              photoPreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) => {
                                fileDrop(
                                  e,
                                  setPhotoPreview,
                                  setUploadImageFile,
                                  setPhotoPreviewtype1,
                                  setPhotoPreviewtype1live
                                );
                              }}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {photoPreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    Photo
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {photoPreview !== undefined ? (
                                <img src={photoPreview} alt="pan" width="100%" height="" />
                              ) : (
                                ''
                              )} */}

                              {photoPreviewtype1 === 'pdf' ||
                              photoPreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={photoPreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img src={photoPreview} alt="" width="100%" height="" />
                              )}
                            </Box>
                          </label>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file2"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleFileUpload(
                                e,
                                setAadharFrontPreview,
                                null,
                                setUploadImageFileFront,
                                setAadharFrontPreviewtype1,
                                setAadharFrontPreviewtype1live
                              );
                            }}
                          />
                          <label
                            htmlFor="contained-button-file2"
                            className={
                              aadharFrontPreviewtype1 === 'pdf' ||
                              aadharFrontPreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) => {
                                fileDrop(
                                  e,
                                  setAadharFrontPreview,
                                  setUploadImageFileFront,
                                  setAadharFrontPreviewtype1,
                                  setAadharFrontPreviewtype1live
                                );
                              }}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {aadharFrontPreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    Aadhar Front
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {aadharFrontPreview !== undefined ? (
                                <img src={aadharFrontPreview} alt="pan" width="100%" height="" />
                              ) : (
                                ''
                              )} */}

                              {aadharFrontPreviewtype1 === 'pdf' ||
                              aadharFrontPreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={aadharFrontPreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img src={aadharFrontPreview} alt="" width="100%" height="" />
                              )}
                            </Box>
                          </label>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file3"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleFileUpload(
                                e,
                                setAadharBackPreview,
                                null,
                                setUploadImageFileBack,
                                setAadharBackPreviewtype1,
                                setAadharBackPreviewtype1live
                              );
                            }}
                          />
                          <label
                            htmlFor="contained-button-file3"
                            className={
                              aadharBackPreviewtype1 === 'pdf' ||
                              aadharBackPreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) => {
                                fileDrop(
                                  e,
                                  setAadharBackPreview,
                                  setUploadImageFileBack,
                                  setAadharBackPreviewtype1,
                                  setAadharBackPreviewtype1live
                                );
                              }}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {aadharBackPreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    Aadhar Back
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {aadharBackPreview !== undefined ? (
                                <img src={aadharBackPreview} alt="pan" width="100%" height="" />
                              ) : (
                                ''
                              )} */}

                              {aadharBackPreviewtype1 === 'pdf' ||
                              aadharBackPreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={aadharBackPreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img src={aadharBackPreview} alt="" width="100%" height="" />
                              )}
                            </Box>
                          </label>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file4"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleFileUpload(
                                e,
                                setPanPreview,
                                null,
                                setUploadPanImage,
                                setPanPreviewtype1,
                                setPanPreviewtype1live
                              );
                            }}
                          />
                          <label
                            htmlFor="contained-button-file4"
                            className={
                              panPreviewtype1 === 'pdf' || panPreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) => {
                                fileDrop(
                                  e,
                                  setPanPreview,
                                  setUploadPanImage,
                                  setPanPreviewtype1,
                                  setPanPreviewtype1live
                                );
                              }}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {panPreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    Pan Card
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {panPreview !== undefined ? (
                                <img src={panPreview} alt="pan" width="100%" height="" />
                              ) : (
                                ''
                              )} */}

                              {panPreviewtype1 === 'pdf' ||
                              panPreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={panPreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img src={panPreview} alt="" width="100%" height="" />
                              )}
                            </Box>
                          </label>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file5"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleFileUpload(
                                e,
                                setCancelChequePreview,
                                null,
                                setUploadChequeImage,
                                setCancelChequePreviewtype1,
                                setCancelChequePreviewtype1live
                              );
                            }}
                          />
                          <label
                            htmlFor="contained-button-file5"
                            className={
                              cancelChequePreviewtype1 === 'pdf' ||
                              cancelChequePreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={async (e) => {
                                fileDrop(
                                  e,
                                  setCancelChequePreview,
                                  setUploadChequeImage,
                                  setCancelChequePreviewtype1,
                                  setCancelChequePreviewtype1live
                                );
                              }}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {cancelChequePreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    Cancelled Cheque
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {cancelChequePreview !== undefined ? (
                                <img src={cancelChequePreview} alt="pan" width="100%" height="" />
                              ) : (
                                ''
                              )} */}

                              {cancelChequePreviewtype1 === 'pdf' ||
                              cancelChequePreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={cancelChequePreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img src={cancelChequePreview} alt="" width="100%" height="" />
                              )}
                            </Box>
                          </label>
                        </Grid>
                      </Grid>
                      <Button
                        className="mainBtn"
                        onClick={(e) => {
                          documentValidation(e);
                          let getactivepage = '';
                          getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                          if (getactivepage != null) {
                            if (getactivepage.routepage === 'signup-form/Individual') {
                              getactivepage.stagefifth = {};
                              localStorage.setItem('trackuser', JSON.stringify(getactivepage));
                            }
                          }
                        }}
                      >
                        Next
                      </Button>
                    </>
                  ) : signupSteps === 'results' ? (
                    <>
                      <Typography className="mb-3" variant="h5" textAlign="">
                        Associate Partner/Channel Partner/Authorised Person/Franchisee Service
                        Agreement
                      </Typography>
                      <AgreementText />
                      <Box className="displayFlex alignItemTop" mb={3}>
                        <Checkbox
                          size="small"
                          sx={{ padding: '0 10px 0 0' }}
                          onClick={(e) => setChecked(e.target.checked)}
                          disableRipple
                        />
                        <Typography variant="caption" color="#0d4689">
                          Yes, I understand and agree to the Real Value Finloan Terms and
                          Conditions, including User Agreement and Privacy Policy. I declare that
                          the information I have provided is accurate and complete to the best of my
                          knowledge. I hereby authorize Real Value Finloan and its affiliates to
                          call, email, send a text through the Short messaging Service (SMS) and/or
                          WhatsApp. The consent herein shall override any registration for DNC/NDNC.
                        </Typography>
                      </Box>
                      <Box textAlign="">
                        <Button
                          className="mainBtn"
                          disabled={checked ? false : true}
                          onClick={async (e) => {
                            const typeId = JSON.parse(localStorage.getItem('typeID'));
                            const createCredentials = {
                              companyId: 36,
                              typeId: typeId.typeId, // from localStorage
                              partnerName: String(name),
                              fathersName: String(fname),
                              dob: String(selectDate),
                              mobile: String(phone),
                              address: String(address),
                              emailAddress: String(emailAddr),
                              districtId: Number(cityID),
                              provinceId: Number(stateID),
                              countryId: 101,
                              isSignUpFlow: true,
                              partnerId: createPartnerData.partnerId,
                              isAgreementChecked: checked
                            };
                            setLoader(true);
                            const CreatePartnerApiResponse = await CreatePartner(
                              createCredentials,
                              signUpAuthToken
                            );
                            if (CreatePartnerApiResponse.status === 'success') {
                              setLoader(false);
                              setCreatePartnerData(CreatePartnerApiResponse.data);
                            }
                            setOpenAssign(true);

                            let getactivepage = '';
                            getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                            if (getactivepage != null) {
                              if (getactivepage.routepage === 'signup-form/Individual') {
                                getactivepage.stagelast = {};
                                localStorage.setItem('trackuser', JSON.stringify(getactivepage));
                              }
                            }
                          }}
                        >
                          Agree &#38; Submit
                        </Button>
                      </Box>
                      {/* onClose={(e) => setOpenAssign(false)} */}
                      <Dialog open={openAssign} fullWidth>
                        <DialogTitle className="popupTitle">
                          <Typography variant="h6">Congratulations</Typography>
                          <IconButton
                            onClick={(e) => {
                              setOpenAssign(false);
                              let getactivepage = '';
                              getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                              if (getactivepage != null) {
                                if (getactivepage.routepage === 'signup-form/Individual') {
                                  localStorage.removeItem('trackuser');
                                  localStorage.removeItem('reviewobj');
                                }
                              }
                              navigate('/');
                            }}
                            size="small"
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                          <Typography textAlign="center" className="mb-2">
                            <BsPatchCheck fontSize={50} color="#a6ce39" />
                          </Typography>
                          <Typography variant="body1" textAlign="center" className="mb-2">
                            Your digital application have been successfully processed and your
                            Partner account has been opened successfully.
                          </Typography>
                          <Typography variant="body1" textAlign="center">
                            We have mailed your Partner account User Id and Password on your
                            registered email id
                          </Typography>
                          <Typography variant="body1" textAlign="center">
                            <Link to="/">{emailAddr}</Link>
                          </Typography>
                          {/* <Typography variant="body1" className="mb-3" textAlign="center">
                            You can Download our App by clicking below and log in with your details
                            to proceed further.
                          </Typography> */}
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={(e) => {
                              setOpenAssign(false);
                              let getactivepage = '';
                              getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                              if (getactivepage != null) {
                                if (getactivepage.routepage === 'signup-form/Individual') {
                                  localStorage.removeItem('trackuser');
                                  localStorage.removeItem('reviewobj');
                                }
                              }
                              navigate('/');
                            }}
                            variant="contained"
                            className="mainBtn"
                          >
                            close
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
        {/* Dialog for validation verify Identity */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">&nbsp;</Typography>
            <IconButton onClick={(e) => setOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box py={1} px={4} textAlign="center">
                <Typography variant="subtitle2">{validationMsg}</Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" className="mainBtn" onClick={handleClose}>
              Retry
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog for validation verify Identity end */}
      </Container>
    </Box>
  );
}

export default SignUpIndividual;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
