import { Box, Stack, Container } from '@mui/material';
import React from 'react';
import Page from '../components/Page';
import PageTitle from '../components/PageHeading';

function TermConditionsText() {
  return (
    <Page title="PULSE by Real Value Finloans || Term Conditions">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Term &#38; Conditions" />
          </Stack>
          <Box>
            <p>
              By accessing these applications / portals / platforms:
              https://www.pulse.realvalue.loans (PULSE) and other as may be launched from time to
              time(hereinafter referred to as "Website”, “Platform" and/or “Application”, which
              shall mean and be used interchangeably having the same meaning) is developed, operated
              and maintained by Real Value Finloan Services Pvt. Ltd. ("The Company"), a company /
              Proprietorship firm, partnership firm, LLP, having registered office at 401/4th Foor,
              Shivalaya Building, Above AU Small Finance Bank, Chandavarkar Road, Near.Ghanta
              Panwala, Borivali (W), Mumbai - 400092, Maharashtra, you agree to be legally bound by
              following terms and conditions and other incidental or related aspects for use.
            </p>
            <p>
              Before you use the platform, you must read all of the terms and conditions (''Terms")
              herein and the Privacy Policy provided on the Website. Please also refer the
              additional legal information as may be applicable to you. You must be at least [18]
              years of age to use our platform / website; by using our platform and agreeing to
              these terms and conditions, you warrant and represent to us that you are at least [18]
              years of age; Audience less than 18 years of age should register with credentials of
              Parent(s) and/or Legal Guardian. Platform shall not be responsible to validate and
              authenticate your age, however, platform reserves the right to optionally / randomly
              conduct checks to verify the correctness of legal age of your’ s / your legal
              guardian’s age registering on platform on your behalf.
            </p>
            <p>
              "we", "our" and "us" means "the company"; "you", “user” and "your" means any person
              and/or the institutions / organizations etc., but not limited to, who accesses and
              uses this Platform along with updating their users on this platform;
            </p>
            <p>
              It is strongly recommended to you to visit this page periodically to review the most
              current version of the Terms and Conditions. The company reserves the right at any
              time, at its sole discretion, to change or otherwise modify the Terms without prior
              notice, and your continued access or use of this platform signifies your acceptance of
              the updated or modified Terms. If you object to these Terms or any subsequent
              modifications to these Terms or become dissatisfied with the platform in any way, you
              should immediately terminate use of the platform.
            </p>
            <h3 className="mb-1 mt-2">
              <strong>PART A: GENERAL TERMS AND CONDITIONS OF USE</strong>
            </h3>
            <p>
              This Terms and Conditions/Agreement is an Electronic Record published in terms of
              Information Technology Act, 2000 of India and generated by a computer system and does
              not require any physical or digital signatures and is in accordance with the
              provisions of Rule 3 of the Information Technology (Intermediaries Guidelines and
              Digital Media Ethics Code) Rules, 2021 issued under the aforesaid Act.
            </p>
            <p>
              Before you further use this Platform, you must read all of the terms and conditions
              specified (all sections of this Platform including but not limited to the General
              Terms and Conditions of Use, the Special and Additional Terms and Conditions of use
              and Privacy Policy provided on this Platform) collectively hereinafter referred to as
              the Agreement.
            </p>
            <p>
              By using these Products, Software, Services and the Platform (hereinafter referred to
              as Services), you hereby confirm to having accepted the Agreement, with immediate
              effect.
            </p>
            <p>
              If you do not agree to the aforesaid paragraph, please do not proceed to further on
              this Platform.
            </p>
            <p>
              If this Agreement conflicts with any other document(s), this Agreement will override
              and supersede such other document(s) and in case of conflict between Part A (General
              Terms and Conditions of Use) and Part B (Special and Additional Terms and Conditions
              of Use), the specific provisions of Part B shall prevail. Apart from it, all the terms
              and conditions which are obligatory to or binding on you will be applicable.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>A. </strong>
              <strong>GENERAL</strong>
            </h4>
            <p>
              To the extent that any provision of this Agreement is found to be invalid, unlawful or
              unenforceable you agree that the courts at Mumbai shall have exclusive jurisdiction
              over the matter and shall endeavor to give effect to the Parties' intentions as
              reflected in the provision, and the unenforceable condition shall be deemed severable
              and shall not affect the validity and enforceability of any remaining terms and
              conditions.
            </p>
            <p>
              Headings are for reference purposes only and do not limit the scope or extent of such
              section.
              <br />
              The failure of the company to act with respect to a breach by you or others does not
              waive its right to act with respect to subsequent or similar breaches. The company
              does not guarantee it will take action against all breaches of this Agreement.
            </p>
            <p>
              Except as otherwise expressly provided in this Agreement there shall be no third-party
              beneficiaries to this Agreement. This Agreement constitutes the entire agreement,
              subject to specific provisions herein, between you and the company and governs your
              use of the Platform and the Services, superseding any prior agreements or any
              communication written or oral by any mode between you and the company with respect to
              the Platform and/or the Services.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>B. </strong>
              <strong>DESCRIPTION OF SERVICES/ PURPOSE:</strong>
            </h4>
            <p>
              (https://www.pulse.realvalue.loans) a Partner Management platform via which Associate
              Partner / Channel Partner / Authorised Person / Franchisee could register themselves,
              login their loan applications, check application status, raise invoice etc.
            </p>
            <p>
              Further details related to the services may be availed from the above mentioned
              website/s.
            </p>
            <p>
              The User shall be bound by the Terms of Use and all copyright policies and other
              applicable laws for any purpose regardless of whether the User is availing any paid
              services or not. Content hosted on the platform is licensed, and not sold to the User.
              Company reserves the right to introduce additional terms in future as may be mandated
              by compliant authorities and/or otherwise. User may use company’s Applications /portal
              only on Supported Devices (as may be conveyed from time to time by company) that are
              compatible with the software. To access the application, User may be required to
              download, install and run certain software, and updates and upgrades thereto, owned or
              controlled by company and/or its service provider(s). Such software shall be governed
              by the accompanying software or end-user license agreement, and User shall comply with
              the terms thereof, the terms of which are incorporated herein.
            </p>
            <p>
              While using and accessing this platform, user provides consent to store various
              information of user like login information, name, address, email, phone number, user’s
              option to register through Gmail/Facebook, geographical location etc. User understands
              that some or all of these may be specified under personal information and user
              unconditionally provides Company their consent to store, use, and process, transmit
              etc., this information. Additionally, user provides consent to company allowing
              company to use various analytics tools like google / web analytics etc., over such
              data of user. Company further collects and store other information that may include,
              users name, number email etc., but not limited to, to which user completely provides
              their consent hereof.
            </p>
            <p>
              In course of providing user-friendly and hassle-free service/experience to the user
              and to provide end services, the company may be using third-party services. In such
              case, direct or indirect integrations will be made with such associated third-party
              service providers to render certain services.
            </p>
            <p>
              Any data provided by you shall be used preliminary for this purpose or any other
              purpose connected to fulfill the above purpose or to enhance the user experience.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>C. </strong>
              <strong>REGISTRATION:</strong>
            </h4>
            <p>
              Registration with the Platform is by way of creating Partner ID. You are solely
              responsible and liable for the veracity and accuracy of all personal and other details
              furnished by you as well as for authentication of all documents uploaded by you at the
              time of registration with the Platform and afterwards.
            </p>
            <p>
              You hereby agree that the company may contact you either electronically, through phone
              or through its online and offline centers or associates, to understand your interest
              in the selected products and services and to fulfill your demand. You also agree that
              the company /the platform reserves the right to make your details available to its
              partners and affiliates and you may be contacted by such partners and affiliates for
              information through email, telephone, SMS and/or any other mode of communication.
            </p>
            <p>
              You hereby specifically grant a consent to receive, Transactional or Service
              Explicit/Implicit communications, promotional materials and/or special offers from the
              company through email, SMS and/or any other mode of communication.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>D. </strong>
              <strong>PROPRIETARY RIGHTS:</strong>
            </h4>
            <p>
              You acknowledge and agree that the company owns all legal rights, titles and interests
              in and to the Platform and the Services offered herein, including any intellectual
              property rights which subsist in the Platform, technology, other intellectual property
              rights and/or the Services (whether those rights are registered or not, and wherever
              in the world those rights exist). All the content displayed on the Site is the sole
              property and is in the exclusive right, title and ownership of the Company; it may be
              noted that the term “Company” in context and meaning to the preceding line shall means
              and be interpreted as the respective institutes, organizations, publications etc.,
              shall continue retaining their respective IPR and proprietary rights of their
              respective IPRs being published, accessed, hosted etc., on the platform. The copyright
              exists in the content on the Site as well as the Site itself is and shall always
              remain in the sole custody, possession, control and ownership of the Company.
              Copyright for this purpose includes registered as well as any unregistered copyright.
              You further acknowledge that the Platform/Services may contain information which is
              designated confidential by the company and that you shall not disclose such
              information without the company's prior written consent. You may not copy, create a
              derivative work from, modify, reverse engineer, reverse assemble or otherwise attempt
              to discover any source code, sell, assign, sublicense, grant a security interest in or
              otherwise transfer any right in the Platform and/or the Services.
            </p>
            <p>
              The trademarks, logos, designs and service marks ("Marks") displayed on this Site are
              the property of the Company (and/or the respective institutes, organizations,
              publications etc., respectively) and shall always remain the sole property of the
              Company. Trademarks and domain name of the Site also vests with the Company. Trade
              marks that have been applied for registration and the unregistered trademarks shall
              also be the sole property of the Company. You do not have the right to use any of the
              company's trade names, trademarks, service marks, logos, domain names, and other
              distinctive brand features. You do not have the right to remove, obscure, or alter any
              proprietary rights notices (including trademark and copyright notices), which may be
              affixed to or contained within the Services or on the Platform. You will not copy or
              transmit any of the Services, components of service, data, details or information. You
              are strictly prohibited to modify, copy, alter, change, distribute, display, publish,
              transform, transfigure and/or mutate any part of the Platform and/or the Services
              outside the platform and/or in any manner apart from the one permissible on the
              platform from time to time.
            </p>
            <p>
              Company’s websites (current as well as any other launches of future) respect the
              intellectual property rights of our users, Content Providers, organizations,
              institutes, publications and other third parties and expects our users to do the same
              when using the Services. Users shall be equally bound by confidentiality clauses and
              all other applicable clauses towards maintaining the sanity of the meaning of the
              clauses mentioned in this agreement, not only towards the company but also towards the
              respective content providers, source code providers, service providers, 3<sup>rd</sup>
              party etc., but not limited to.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>E. </strong>
              <strong>LICENSE AND PLATFORM ACCESS/USAGE OF THE PLATFORM:</strong>
            </h4>
            <p>
              The company grants you a limited license to access and make use of the Platform, the
              Products/Services offered purely for commercial purpose only.
            </p>
            <p>
              This license does not include any copying of any kind of information or in any forms
              such as extract or reformatted for the benefit of another individual, vendor or any
              other third party who have not subscribed for the platform’s services; caching,
              unauthorized hypertext links to the Platform and the framing of any content available
              through the Platform, uploading, posting, or transmitting any content that you do not
              have a right to make available (such as the intellectual property of another party)
              outside the application; uploading, posting, or transmitting any material that
              contains software viruses or any other computer code, files or programs designed to
              interrupt, destroy or limit the functionality of any computer software or hardware or
              telecommunications equipment; any action that imposes or may impose (in the company's
              sole discretion) an unreasonable or disproportionately large load on the company's
              infrastructure; or any use of data mining, robots, or similar data gathering and
              extraction tools.
            </p>
            <p>
              You may not bypass any measures used by the company to prevent or restrict access to
              the Platform and/or the Services. Any unauthorized use by you shall terminate the
              permission or the limited license granted to you by the company and shall entail
              charges of compensation to be paid by you as may be determined by the company.
            </p>
            <p>By using the Platform, you (user) agree not to:</p>
            <p>
              1. Use this Platform or its contents for any purpose other than as defined in the
              Platform;
            </p>
            <p>
              2. Make any speculative, false, or fraudulent transaction or any transaction in
              anticipation of demand;
            </p>
            <p>
              3. Access, monitor or copy any content or information of this Platform using any
              robot, spider, scraper or other automated means or any manual process for any purpose
              without our express written permission;
            </p>
            <p>
              4. Violate the restrictions in any robot exclusion headers on this Platform or bypass
              or circumvent other measures employed to prevent or limit access to this Platform;
            </p>
            <p>
              5. Take any action that imposes, or may impose, in our discretion, an unreasonable or
              disproportionately large load on our infrastructure;
            </p>
            <p>
              6. Deep link to any portion of this Platform (including, without limitation, the
              purchase path for any service) for any purpose without our express written permission;
              or
            </p>
            <p>
              7. "Frame", "Mirror" or otherwise incorporate any part of this Platform into any other
              Platform without our prior written authorization.
            </p>
            <p>
              8. Use, post etc., any content which is defamatory, obscene, pornographic, pedophilic,
              invasive of another’s privacy, including bodily privacy, insulting or harassing on the
              basis of gender, libelous, racially or ethnically objectionable, relating or
              encouraging money laundering or gambling, or otherwise inconsistent with or contrary
              to the laws in force. Such act may constitute of criminal proceedings against the user
              at his/her own risk, damage and consequences. Notwithstanding the fact that recorded
              contents or other parties involved in creating, producing, or delivering the Site may
              monitor or review any links to the Site, company and such parties assume no
              responsibility or liability which may arise from the content thereof.
            </p>
            <p>9. Is harmful to child and/or impersonates any other person</p>
            <p>
              10. Infringes any patents, trademarks, copyrights, Proprietary rights and /or violates
              any law for the time being in force
            </p>
            <p>
              11. deceives or misleads the addressee about the origin of the message or knowingly
              and intentionally communicates any information which is patently false or misleading
              in nature but may reasonably be perceived as a fact
            </p>
            <p>
              12. threatens the unity, integrity, defense, security or sovereignty of India,
              friendly relations with foreign States, or public order, or causes incitement to the
              commission of any cognizable offence or prevents investigation of any offence or is
              insulting other nation
            </p>
            <p>
              13. is patently false and untrue, and is written or published in any form, with the
              intent to mislead or harass a person, entity or agency for financial gain or to cause
              any injury to any person;
            </p>
            <p>
              14. Breach any restrictions, guidelines that may be imposed by competent authorities
              from time to time
            </p>
            <p>
              15. Use Downloaded Content upon or after the expiration of the Use Period and
              acknowledge that any expired Download Content will be automatically deleted from User
              without further notice to user
            </p>
            <p>16. Remove any proprietary notices or labels on the Site or Site Content.</p>
            <p>
              17. Use the Site, Site Content or Service, or any portion thereof, for any purposes
              which are unlawful in any nation or jurisdiction in the world or for any commercial
              purpose whatsoever.
            </p>
            <p>
              18. Use the Site, Site Content or Service in conjunction with any device or service
              designed to circumvent technological measures employed to control access to, or the
              rights in, a content file or other work protected by the copyright laws of any
              jurisdiction
            </p>
            <h4 className="mb-1 mt-2">
              <strong>F. </strong>
              <strong>YOUR ACCOUNT</strong>
            </h4>
            <p>
              You represent and warrant that you/ user on whose behalf this account is being created
              /to be created and used (Parent or Legal Guardian; shall be jointly, severally and
              interchangeably used as “You”, “Your” etc.), are of legal age and not a minor,
              competent to contract, having a sound mind, and under no coercion or undue influence
              of any person or substance or incapacitated in any manner whatsoever to form a binding
              contract and are not a competitor of the company or in any way or manner associated
              with competitor or potential competitor or a person barred from receiving the Services
              offered by the company under the laws of India or other applicable jurisdiction. You
              will use the Platform to make legitimate use for you or for any another person for
              whom you are legally authorized to act (and will inform such other persons about the
              Agreement and/or Privacy Policy) that apply to the use of the Platform and the
              Services on their behalf (including all rules and restrictions applicable thereto).
              You also agree to provide true, accurate, current and complete information about
              yourself as required by the Platform. If you provide any information that is untrue,
              inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or
              incomplete), or the company has reasonable grounds to suspect that such information is
              untrue, inaccurate, not current or incomplete, the company has the right to refuse
              and/or deny access of any and all existing or future use by you of the Platform (or
              any portion thereof).
            </p>
            <p>
              Notwithstanding the above, the company retains the right at its sole discretion to
              deny access to anyone to the Platform and/or the Services it offers, at any time and
              for any reason, including, but not limited to, for violation of the Agreement or
              Privacy Policy or any other policy that maybe in force with or without reason.
            </p>
            <p>
              User shall be responsible for creation and saving of their own user Id and password
              (Strong password containing alpha numeric usage along with special character should be
              used) and company recommends the same to be changed at regular periodic intervals.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>G. </strong>
              <strong>OUR PARTNERS &amp; CONSENT</strong>
            </h4>
            <p>
              The company's display on or through the Platform of any product options or by any
              other mode of communication (whether public or private) offered by third parties does
              not in any way imply, suggest, or constitute any sponsorship, recommendation or
              approval of the company of any such third parties or of their products. The company
              does not, through its Platform display, content or in any other manner, provide any
              recommendation, opinion or advice to you on the third parties or their products. You
              agree that the company and the Platform is in no way responsible for the accuracy,
              timeliness or completeness of information it may obtain from these third parties. It
              is specifically clarified that the only role that the company is playing through its
              Platform is as mentioned in the services / purpose and any interaction with such third
              party introduced through the Platform is at your own risk, and the company will have
              no liability or responsibility with respect to the acts, omissions, errors,
              representations, warranties, breaches or negligence of any such third parties or for
              any personal injuries, death, property damage, non-performance, delays, defaults, any
              loss of business or profit or other damages or expenses resulting from your
              interactions with such third parties.
            </p>
            <p>
              You hereby undertake and confirm that the information and data furnished by you to the
              platform is true and correct. Pursuant to this agreement, you acknowledge and confirm
              that you have consented to share sensitive data provided by you with platform as well
              as any partners of the platform in future. By registering and proceeding with the
              Application, you hereby authorize the platform and give your consent for the purposes
              as may be envisioned by platform from time to time.
            </p>
            <p>
              While company takes the best in industry measures to safeguard user’s data, it clearly
              disclaims any obligation as may be imposable onto company. Company reserves the right
              to delete the entire data of user at any point of time wherein a breach to the stated
              terms is found, at sole discretion of the company. Company further reserves the rights
              to segment different user categories like patron users and others etc., and store the
              information in an archived manner and/or delete the same upon expiration of the
              subscription / license period. Company hereby confirms that upon request of user for
              deletion of his/her respective data, company upon evaluation and the then existing
              rules of retaining data, shall do the needful as requested by user.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>H. </strong>
              <strong>LOCAL LAWS</strong>
            </h4>
            <p>
              The company controls and operates this Platform from its headquarters in Mumbai
              (Maharashtra), India and makes no representation that the Services offered on the
              Platform are appropriate or available for use in other locations or jurisdictions. If
              you use this Platform from other locations/jurisdiction, you are responsible for
              compliance with applicable local laws (including the taxation aspect, Fiscal and
              Economic Laws) and regulations including but not limited to the export and import
              regulations of other countries. Unless otherwise explicitly stated, all marketing or
              promotional materials found on this Platform are solely directed to individuals,
              companies or other entities interested to getting on boarded onto platform for the
              services as may be enlisted therein from time to time. By agreeing to these terms and
              conditions of use, you confirm and undertake that neither you nor any of your
              representatives shall have the right to challenge the governing law and jurisdiction
              as more specifically set out herein and/or in Part B (Special and Additional Terms and
              Conditions) as the case maybe.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>I. </strong>
              <strong>SUBMITTED CONTENT</strong>
            </h4>
            <p>
              The company does not claim ownership and/or usability/suitability of any information,
              data, documents and/or materials ("Materials") of any kind made available by you
              through the Platform; however, there are various institutions which may have been
              on-boarded as one of the key user group of this platform which has subscribed for
              various 3<sup>rd</sup> party contents. These contents are owned by respective 3
              <sup>rd</sup> party content providers, publishers etc., and platform in no way claims
              ownership, accuracy, veracity etc., of such content which may be subscribed or made
              available by institutions and/or by such 3<sup>rd</sup> party publishers. At the
              company's sole discretion, such Materials may be included in the Services in whole or
              in part or in a modified form. With respect to such Materials, you submit or make
              available for inclusion on the Platform, you grant the company a perpetual,
              irrevocable, non-terminable, worldwide, royalty-free and non-exclusive license to use,
              copy, distribute, publicly display, modify, create derivative works, and sublicense
              such Materials or any part thereof (as well as use the name that you submit in
              connection with such submitted content) unless specially agreed otherwise by the
              company.
            </p>
            <p>
              You hereby represent, warrant and covenant that any Material you provide do not
              include anything (including, but not limited to, text, images, music or video) to
              which you do not have the full right to grant the license specified in this Section.
              In case any liability arises on the company then you shall be solely liable and shall
              indemnify the company. We take no responsibility and assume no liability for any
              Material and its content posted or submitted by you. We have no obligation to post
              your Material or comments; we reserve the right in our absolute discretion to
              determine which Material/comments are published on the Platform. If you do not agree
              to these terms and conditions, please do not provide us with any Material/content for
              submission. You agree that you are fully and solely responsible for genuineness,
              veracity and authentication of the Material and content you submit.
            </p>
            <p>
              You are prohibited from posting or transmitting, including but not limited to the
              following, to or from this Platform:
            </p>
            <p>
              1. Any commercial material or content (including, but not limited to, solicitation of
              funds, advertising, or marketing of any good or services) unless specifically required
              by the company;
            </p>
            <p>
              2. Any unauthenticated or manipulated data (in any form) leading to forgery or
              business deal failures.
            </p>
            <p>
              Apart from the above four mentioned clauses, this section also includes all the
              clauses (related to uploading and usage of content on/to platform) mention under title
              "Platform-Provided Message Postings (Including Forum)" in the document below.
            </p>
            <p>
              Any noncompliance/breach/infringement of the above shall entitle the company to take
              strict actions as it deems fit against you. You shall be solely liable for any
              damages, claim, costs and expenses resulting from any violation of the foregoing
              restrictions, or any other harm resulting from your posting of content to this
              Platform.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>J. </strong>
              <strong>LIMITATION OF LIABILITY</strong>
            </h4>
            <p>
              The company and its Partners, Subsidiaries, Joint Ventures, Associates, Officers,
              Directors, Employees, Representatives, Affiliates, and providers (hereinafter referred
              to ''The company's Associates") will not be responsible or liable for:
            </p>
            <p>
              (a) any injury, death, loss, claim, act of god, accident, delay, or any direct,
              special, exemplary, punitive, indirect, incidental or consequential damages of any
              kind (including without limitation lost profits or lost savings), whether based in
              contract, tort, strict liability or otherwise, that arise out of or is in any way
              connected with:
            </p>
            <p>
              1. any failure or delay (including without limitation the use of or inability to use
              any component of the Platform), or
            </p>
            <p>2. any use of the Platform or content or Services, or</p>
            <p>
              3. the performance or non-performance by us or the company's Associates, even if we
              have been advised of the possibility of damages to such parties or any other party, or
            </p>
            <p>
              4. any errors or omissions in any content or for any loss or damage incurred as a
              result of the use of any content posted, emailed, transmitted, or otherwise made
              available through the service; and/or
            </p>
            <p>
              5. user content or the defamatory, offensive, or illegal conduct of any third party
            </p>
            <p>
              (b) any damages to or viruses that may infect your computer equipment or other
              property as the result of your access to the Platform or your downloading of any
              Service/content from the Platform.
            </p>
            <p>
              (c) any unanticipated, unforeseen damages, risk and/or claims that may arise due to
              usage of this platform
            </p>
            <p>
              In no event shall company, its affiliates, directors, employees, advisors, service
              providers and/or licensors etc., be liable to user for any claims, proceedings,
              liabilities, obligations, damages, losses and/or costs in an amount exceeding the
              amount user / client has paid to company hereunder.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>K. </strong>
              <strong>INDEMNITY</strong>
            </h4>
            <p>
              You agree to indemnify, release and hold harmless the company and the company's
              Associates, employees, advisors, third party service providers from any and against
              all liabilities, claims, causes of action, demands, recoveries, losses, damages,
              fines, penalties, interests or other costs or expenses of any kind or nature,
              including reasonable attorneys' fees, or arising out of or related to breach of this
              Agreement, violation of any law or the rights of a third party, or use of the
              Platform/Services.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>L. </strong>
              <strong>ELECTRONIC COMMUNICATION</strong>
            </h4>
            <p>
              When you use the Platform, you are communicating with the company electronically. You
              consent to receive communications from the company electronically. The company may
              communicate with you by email or by posting notices on the Platform or by phone or
              usually available means of communication.
            </p>
            <p>
              You agree that all agreements, notices, disclosures and other communications that we
              provide to you electronically satisfy any legal requirement that such communications
              be in writing. You agree to visit our terms and condition &amp; Privacy policy on
              company’s website periodically (at least once in a year) and in case you do not agree
              to the same, kindly cease the usage of the platform immediately.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>M. </strong>
              <strong>PLATFORM-PROVIDED MESSAGE POSTINGS (INCLUDING FORUM)</strong>
            </h4>
            <p>
              The Platform may provide users with the ability to post messages on the Platform. The
              Company is under no obligation to validate in any manner to review any messages,
              information, data or content ("Postings") posted on the Platform by users and assumes
              no responsibility or liability relating to any such Postings.
            </p>
            <p>
              Notwithstanding the above, the Company may from time to time monitor the Postings on
              the Platform and may decline to accept and/or remove any Postings. You understand and
              agree not to use any functionality provided by the Platform to post content or
              initiate communications that contain:
            </p>
            <p>
              1. Any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar,
              obscene, profane, hateful, racially, terrorism, religious harmony, ethnically or
              otherwise objectionable material of any kind, including, but not limited to, any
              material which encourages conduct that would constitute a criminal offense, give rise
              to civil liability or otherwise violate any applicable local, state, national or
              international law;
            </p>
            <p>2. Advertisements or solicitations of any kind;</p>
            <p>3. Impersonate others or provide any kind of false information;</p>
            <p>
              4. Personal information such as messages which state phone numbers, account numbers,
              addresses, or employer references.;
            </p>
            <p>
              5. Messages by non-spokesperson employees of the company purporting to speak on behalf
              of the company or containing confidential information or expressing opinions
              concerning the company;
            </p>
            <p>
              6. Messages that offer unauthorized downloads of any copyrighted or private
              information;
            </p>
            <p>
              7. Multiple messages placed within individual folders by the same user restating the
              same point;
            </p>
            <p>8. Chain letters of any kind; or</p>
            <p>
              9. Identical (or substantially similar) messages to multiple recipients, advertising
              any product or service, expressing a political or other similar message(s), or any
              other type of unsolicited commercial message.
            </p>
            <p>This prohibition includes but is not limited to:</p>
            <p>
              1. Using the Platform to send messages to people who don't know you or who are
              unlikely to recognize you as a known contact;
            </p>
            <p>
              2. Using the Platform to connect to people who don't know you and then sending
              unsolicited promotional messages to those direct connections without their permission;
              and/or
            </p>
            <p>3. Sending messages to distribution lists, newsgroup aliases, or group aliases</p>
            <h4 className="mb-1 mt-2">
              <strong>N. </strong>
              <strong>BLOGS</strong>
            </h4>
            <p>
              The Company May post blogs on its Platform, which may be written by the Company or its
              contributing authors. The Company Blogs and their contents are made available for
              informational and educational purposes only, and do not provide any legal, business,
              professional or tax advice. You agree and understand that your use of the company's
              Blogs does not create an attorney-client relationship, and the contents of the
              company's Blogs do not constitute legal advice. Information provided through Blogs may
              not reflect the most current legal developments and is not guaranteed to be correct,
              complete, up-to-date, or applicable to your particular transaction or situation. You
              should contact an appropriate professional for advice on your particular transaction
              or situation. The company and all contributing authors to Blogs expressly disclaim any
              and all liability to any persons or entities with respect to actions they take or do
              not take based on the contents of Blogs, and you agree that by visiting and viewing
              Blogs, the company, the company's Associates and the contributing authors to Blogs are
              not liable or responsible for the contents of the blogs.
            </p>
            <p>
              The information provided and comments posted in Blogs are exclusively the personal
              views of the authors, unless otherwise attributed. Information and comments provided
              through Blogs do not necessarily represent the views of the company or Blog editors
              and should not be attributed to them unless expressly indicated. The posting of an
              item on Blogs does not mean that the company or Blog editors approve or disapprove of
              the selection or contents of that item.
            </p>
            <p>
              The text, graphics, and their selection and arrangement on Blogs are the copyrighted
              works of the company and/or its licensors, as applicable. All rights are reserved.
            </p>
            <p>
              No articles on the Blog may be reproduced without the express written permission of
              the company. If the company does grant permission to a Platform to reproduce a portion
              of an article, the text must be accompanied by a link to the full article on the Blog.
              The text must also be accompanied by a statement asserting that the text has been
              reproduced with the permission of the company. The company may terminate a grant of
              permission at any time if it determines that such grant is no longer in the best
              interests of the company. To obtain a permission to reproduce the article and/or in
              case of any queries pertaining to functioning of the platform, kindly write to the
              email id as may be mentioned in Contact us / Support Segment of Platform, from time to
              time.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>O. </strong>
              <strong>LINKS AND BROWSER EXTENSION</strong>
            </h4>
            <p>
              The Platform or third parties may provide links to other World Wide Web sites or
              third-party resources. Such third-party contents and links are provided solely for
              your convenience and information only. As the company has no control over such sites
              and resources, you acknowledge and agree that the company is not responsible for the
              availability of such third party external sites or resources, and does not endorse and
              is not responsible or liable in any manner whatsoever for any such third party
              content, advertisements, products or other materials on or available from such third
              party sites or resources. You further acknowledge and agree that the company shall not
              be responsible or liable, directly or indirectly, for any damage or loss caused or
              alleged to be caused by or in connection with use of or reliance on any such third
              party content, goods or services available on or through any such third party site or
              resource. Company reserves the rights to store and process the browsing history as may
              be required for internal analysis purposes.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>P. </strong>
              <strong>ACCESS TO PASSWORD PROTECTED/SECURE AREAS</strong>
            </h4>
            <p>
              Access to and use of password protected and/or secure areas of the Platform is
              restricted to authorized users only. Unauthorized individuals attempting to access or
              accessing these areas of the Platform may be subject to prosecution and/or legal
              action under the applicable laws.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>Q. </strong>
              <strong>MODIFICATION AND NOTIFICATION OF CHANGES</strong>
            </h4>
            <p>
              The company reserves the right to make changes to the Platform, Services, related
              policies, payment terms and agreements, this Agreement (all sections) and the Privacy
              Policy at any time. If the company makes a material modification to this Agreement,
              you are advised to refer this Agreement from time to time. If you choose to continue
              using the Platform, you agree that by doing so you will be deemed to accept the
              Agreement.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>R. </strong>
              <strong>SURVIVAL OF TERMS AFTER AGREEMENT ENDS</strong>
            </h4>
            <p>
              Notwithstanding any other provisions of this Agreement, or any general legal
              principles to the contrary, any provision of this Agreement that imposes or
              contemplates continuing obligations on a party will survive the expiration or
              termination of this Agreement.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>S. </strong>
              <strong>YOUR TELEPHONE CALLS</strong>
            </h4>
            <p>
              Telephone calls that you make to our customer service help line on the numbers
              mentioned in the Contact Us section of the Platform or calls made by our support desk
              may be monitored or recorded. This will help us to train our staff and improve our
              services to you. A recording will only be used under proper and careful supervision.
              Our customer service help line is available on the times notified in the Contact Us
              section of the Platform. Your telecom provider will charge you at local rates for
              calls made to non-toll-free numbers. Any solicitation talks shall not be entertained
              and you as a user of the Platform are bound to maintain the dignity as a tele-talker.
              All rules and regulations as applicable to telephone calls/Tele Talks in this regard
              shall apply and you as a user are bound to comply with the same
            </p>
            <h4 className="mb-1 mt-2">
              <strong>T. </strong>
              <strong>FEEDBACK</strong>
            </h4>
            <p>
              Your feedback makes us better. Please feel free to share it with us. We will assume no
              responsibility for reviewing unsolicited ideas and will not incur any liability as a
              result of any similarities between those ideas and materials that may appear in future
              the company programs. Please do not reveal trade secrets or other confidential
              information in your messages to the company. Any and all rights to materials submitted
              to us become the exclusive property of the company. The company is not bound to act or
              implement any discretionary measures against any complaint/feedback received. However,
              the company in good faith shall use its best efforts to use the same in a positive way
              and shall endeavor to take reasonable efforts to improve/improvise the Platform to the
              extent possible.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>U. </strong>
              <strong>ADDITIONAL TERMS AND CONDITIONS ON CERTAIN SECTIONS OF THE SITE</strong>
            </h4>
            <p>
              In addition to Part A of this Agreement, users who desire to access and use specific
              categories/sections on this Platform for the purpose of accessing the Services, are
              bound by such special and additional terms and conditions of use governing those
              categories/sections as more specifically defined in Part B of this Agreement. Users
              are mandatorily required to review, accept and confirm such special and additional
              terms and conditions before accessing and using the Services available in such
              specific categories/sections. By accessing these categories/ sections, you agree to be
              bound by any such special and additional terms and conditions. In the event the
              special and additional terms and conditions are inconsistent with any part or portion
              of Part A of this Agreement, the special and additional terms and conditions of Part B
              being specific in nature shall control and take precedence.
            </p>
            <h3 className="mb-1 mt-2">
              <strong>PART B: SPECIAL AND ADDITIONAL TERMS AND CONDITIONS</strong>
            </h3>
            <p>
              [In addition to the terms and conditions set out in Part A, these Special and
              Additional Terms and Conditions set out herein are applicable to a service applicant.
              In Case of conflict between Part A (General Terms and Conditions of Use) and Part B
              (Special and Additional Terms and Conditions of Use) the specific provisions of Part B
              will prevail.]
            </p>
            <p>
              All registered users shall ensure compliance with the following terms and conditions
              at all times through the Platform of the company (the term shall hereinafter deem to
              include its third-party partners, employees, affiliates, agents and representatives
              where the context so requires):
            </p>
            <h4 className="mb-1 mt-2">
              <strong>A. </strong>
              <strong>CONFIDENTIALITY</strong>
            </h4>
            <p>
              The provisions of this Section are in addition to the provisions of the Privacy Policy
              required to be accepted by the user. All information shared by the user as a
              registered account holder with the Platform and/or the company shall deem to be
              confidential information and the Platform has put in reasonable and appropriate
              protective measures to ensure that confidentiality of the same is protected.
            </p>
            <p>
              However, if such information is shared with any third parties such party shall be
              responsible for maintaining the confidentiality of the same as per the applicable laws
              and shall be liable in case of any breach.
            </p>
            <p>
              The Platform shall have the right to share the user information with the 3{' '}
              <sup>rd</sup> parties, service provider, regulatory and/or statutory authorities
              and/or any other agency as maybe required under applicable laws as well as with its
              partners, employees, alliances, affiliates, agents and representatives on need to know
              basis.
            </p>
            <p>
              All information shared by third parties with the user on the Platform shall also be
              treated as confidential information (unless the same is available in public) and the
              user shall take appropriate and reasonable measures in its system to protect the same
              at its end. Any breach or negligence in this regard at the user’s end leading to any
              third party claims on the user, the company and/or the Platform shall be handled and
              settled by the user at its own cost and expense and the company shall not be
              responsible or liable for the same.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>B. </strong>
              <strong>INDEMNITY</strong>
            </h4>
            <p>
              A breach or noncompliance of these Special Terms and Conditions by the user can cause
              severe and irreparable loss and damage to the company, the Platform and/or a third
              party. The company and the Platform reserve their right to recover all losses, costs
              and expenses incurred in case the users breaches or fails to comply with any of the
              provisions of this Part B.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>C. </strong>
              <strong>DISPUTE RESOLUTION</strong>
            </h4>
            <h5>
              <strong>Amicable Settlement</strong>
            </h5>
            <p>
              If any dispute arises between the Parties in connection with the validity,
              interpretation, implementation or alleged breach of any provision of this Agreement
              (Terms of Use, Part A, B and Disclaimers) the disputing Parties hereto shall endeavor
              to settle such Dispute amicably. The attempt to bring about an amicable settlement
              shall be considered to have failed if not resolved within thirty (30) days from the
              date of the Dispute.
            </p>
            <h5>
              <strong>Arbitration</strong>
            </h5>
            <p>
              If the disputing Parties are unable to amicably settle the Dispute in accordance with
              above, within the period specified therein, any party to the Dispute shall be entitled
              to serve a notice invoking this Clause and making a reference to arbitration.
            </p>
            <p>
              The arbitration shall be conducted and managed by the Arbitration and Conciliation Act
              1996 as amended by the Arbitration and Conciliation (Amendment) Act 2015 as in force.
              All disputes and questions whatsoever which shall arise either during the currency of
              this Agreement or afterwards, between the Parties concerning this Agreement to a
              single Arbitrator in case the Parties agree upon one. Otherwise each party will
              appoint one Arbitrator within fifteen (15) days and both the arbitrators shall jointly
              appoint the presiding arbitrator within a period of ten (10) days. The panel of the
              three arbitrators shall attempt to resolve the Dispute within a period of thirty (30)
              business days from reference. The decision of the arbitrators shall be final and
              binding on the Parties.
            </p>
            <p>
              The place of arbitration shall be Mumbai (India) and all arbitration proceedings shall
              be conducted in the English language. Judgment upon any arbitral award rendered
              hereunder may be entered in court, having the above jurisdiction at Mumbai, or
              application may be made to such court for a judicial acceptance of the award and an
              order of enforcement, as the case may be.
            </p>
            <p>
              The Courts at Mumbai, India shall have sole and exclusive jurisdiction on all
              questions relating to the validity of the arbitration provisions and the law governing
              interpretation of these arbitration provisions.
            </p>
            <p>
              Notwithstanding the above, you hereby accept that by agreeing to these Terms and
              Conditions of Use that neither you nor any person acting for and on your behalf shall
              raise a ZERO COMPLAIN (from any jurisdiction) and you hereby accept and bind yourself
              to the dispute resolution and jurisdiction clause as set out herein.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>D. </strong>
              <strong>GOVERNING LAWS AND JURISDICTION</strong>
            </h4>
            <p>
              This Agreement (Terms of Use-including part A and B) and the relationship between you
              and the company will be governed by the laws of the India without regard to its
              conflict of law provisions. Subject to the above, the courts located in Mumbai (India)
              shall have exclusive jurisdiction with respect to any legal proceedings that may arise
              in connection with this Agreement.
            </p>
            <p>
              Notwithstanding the above, you hereby accept that by agreeing to these Terms and
              Conditions of Use that neither you nor any person acting for and on your behalf shall
              raise a Zero Complain/ Zero FIR (from any Jurisdiction) and you hereby accept and bind
              yourself to the dispute resolution and jurisdiction clause as set out herein and/or in
              the Special and Additional Terms and Conditions as the case maybe.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>E. </strong>
              <strong>WARRANTIES</strong>
            </h4>
            <p>
              The company reserves the right to discontinue or alter any or all of website services,
              and to stop publishing website, at any time at company’s sole discretion without
              notice or explanation; and save to the extent expressly provided otherwise in these
              terms and conditions, you will not be entitled to any compensation or other payment
              upon the discontinuance or alteration of any website services, or if we stop
              publishing the website.
            </p>
            <p>
              To the maximum extent permitted by applicable law, we exclude all representations and
              warranties relating to the subject matter of these terms and conditions, our website
              and the use of our website.
            </p>
            <p>
              No warranty: The company sites, services, and software are provided “as is,” with no
              warranty, implied or statutory, including, without limitation, warranties of
              merchantability, fitness for a particular purpose, and non-infringement of proprietary
              rights. The company disclaims any implied, express, or statutory warranties regarding
              the security, reliability, timeliness, and performance of the solutions &amp;
              services, including information or advice obtained through the company.
            </p>
            <p>
              Other than as expressly set out in these terms or additional, in no event shall
              Company, its officers, directors, employees, advisors, vendors, service providers and/
              or agents, be liable to the user for any direct, indirect, incidental, special,
              punitive, or consequential damages whatsoever resulting from any (i) errors, mistakes,
              or inaccuracies of content, (ii) personal injury or property damage, of any nature
              whatsoever, resulting from users access to and use of our service, (iii) any
              unauthorized access to or use of our secure servers and/or any and all personal
              information and/or financial information stored therein, (iv) any interruption or
              cessation of transmission to or from our servers, (iv) any bugs, viruses, Trojan
              horses, or the like, which may be transmitted to or through our service by any third
              party, (v) any errors or omissions in any content or for any loss or damage of any
              kind incurred as a result of use of any content posted, emailed, transmitted, or
              otherwise made available via the Company’s client, whether based on warranty,
              contract, tort, or any other legal theory, and whether or not company is advised of
              the possibility of such damages, and/or (vi) the disclosure of information pursuant to
              these terms of service or privacy policy. The foregoing limitation of liability shall
              apply to the fullest extent permitted by law in the applicable jurisdiction. Some
              jurisdictions may allow the prudence and enforceability of certain warranties, like
              the implied warranty of services fitness for a particular purpose etc.; however, to
              the extent permitted by law, company excludes all such warranties and strongly advises
              user to proceed with usage of the platform only if he/she agrees with the Terms and
              Conditions and Privacy Policy of the company as stated herein.
            </p>
            <p>
              Although the Application may link to other websites (“External Sites”), company is
              not, directly or indirectly, implying any approval, association, sponsorship,
              endorsement, or affiliation with any External Site, unless otherwise specifically
              stated herein. By accessing company’s portal/application/website, User acknowledge and
              agree that company has not reviewed the External Sites and is not responsible for the
              content contained on any External Site. User access and use of any External Site is at
              Users own risk, damages and consequences. The Site may contain information or
              advertising for third parties. Company expressly denies any endorsement, sponsorship,
              approval, or agreement with any third party communication made available via the Site.
              Company shall not be deemed to have considered, reviewed, screened or approved any
              such third party communication.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>F. </strong>
              <strong>TERMINATION</strong>
            </h4>
            <p>
              In addition to any other rights of the parties set forth herein, Company may in its
              sole discretion, restrict, suspend or terminate User access to the Content and
              Service, in whole or in part and without notice, even if the end client / customer
              continues to offer access to the services of portal Content and Service to its other
              members or representatives. If User terminates User access to the Application and/or
              Service based on a breach of any portion of these Terms, Company reserves the right to
              refuse to provide other services to User in the future, even if User subscribes to
              such services and offers the benefits of such subscription to its other members or
              representatives.
            </p>
            <h4 className="mb-1 mt-2">
              <strong>G. </strong>
              <strong>FORCE MAJUERE</strong>
            </h4>
            <p>
              Neither party shall be liable to the other for any failure to perform any obligation
              under any Agreement which is due to an event beyond the control of such party
              including but not limited to any Act of God, terrorism, war, Political insurgence,
              insurrection, riot, civil unrest, act of civil or military authority, uprising,
              earthquake, flood or any other natural or manmade eventuality outside of our control,
              which causes the termination of an agreement or contract entered into, nor which could
              have been reasonably foreseen. Any Party affected by such event shall forthwith inform
              the other Party of the same and shall use all reasonable endeavors to comply with the
              terms and conditions of any Agreement contained herein.
            </p>
            <p>
              You hereby accept the above Special and Additional Terms and Conditions and agree to
              be bound by the same. Any breach, violation or non-compliance shall entitle the
              company, the Platform and/or its representatives to take necessary action against
              me/us. You hereby declare that you are of major age and an individual or an entity
              legally capable and compatible to enter into this Agreement / contract.
            </p>
            <p>
              You hereby confirm by clicking on "I Accept" [ ] for having read and understood the
              Agreement (Terms of Use, all sections - Part A &amp; Part B, Privacy Policy, Refund
              Policy and Disclaimers, are construed to make total and complete agreement) and accept
              the same in its entirety. You agree to be bound by all the provisions and sections of
              this Agreement (as applicable to you). Any breach, violation or non-compliance of the
              same shall entitle the Company, its representative and/or the Platform to take
              necessary action including legal action against me/us.
            </p>
            <p>
              You acknowledge that you have read and have understood these Terms, and that these
              Terms and Conditions have the same force and effect as a signed agreement by you.
            </p>
            <h5 align="right" className="mt-2">
              <strong>VERSION 1.0</strong>
            </h5>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default TermConditionsText;
