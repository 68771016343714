import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Drawer, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
// import account from '../../_mocks_/account';

import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 200;
const DRAWER_WIDTH_CLOSE = 50;
const DRAWER_WIDTH_CLOSE_MOB = 0;

const RootStyle = styled('div')(({ theme, isopensidebar }) => ({
  [theme.breakpoints.up('360')]: {
    flexShrink: 0,
    width: isopensidebar === 'true' ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE_MOB
  },
  [theme.breakpoints.up('600')]: {
    flexShrink: 0,
    width: isopensidebar === 'true' ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE
  }
}));

// ----------------------------------------------------------------------

export default function DashboardSidebar({ isopensidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  console.log(isopensidebar);
  useEffect(() => {
    if (isopensidebar === 'true') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        background: '#0d4689',
        '& .simplebar-content': {
          background: '#0d4689',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          px: 2,
          py: 1,
          display: 'flex',
          alignItems: 'center',
          // background: 'rgb(79 187 200 / 10%)'
          background: 'rgb(255 255 255 / 100%)',
          borderBottom: '1px solid #eff1f5',
          paddingTop: '4px',
          paddingBottom: '3px',
          justifyContent: 'center'
        }}
      >
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo isopensidebar={isopensidebar} />
        </Box>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle isopensidebar={isopensidebar}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpen}
          onClose={onCloseSidebar}
          variant="persistent"
          PaperProps={{
            sx: {
              width: isopensidebar === 'true' ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE_MOB,
              bgcolor: 'background.default',
              transition: 'all 0.2s'
            },
            className: 'sliderPaperC'
          }}
        >
          Hello
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: isopensidebar === 'true' ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE,
              bgcolor: 'background.default',
              transition: 'all 0.2s'
            },
            className: 'sliderPaperB'
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
