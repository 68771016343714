import { useState, useEffect } from 'react';
import {
  Container,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  Paper,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Document, pdfjs, Page as Pages } from 'react-pdf';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import photoDummy from './dummy/photoDummy.jpg';
import aadharFrontDummy from './dummy/aadharFrontDummy.jpg';
import aadharBackDummy from './dummy/aadharBackDummy.png';
import panCardDummy from './dummy/panCardDummy.jpg';
import cancelChequeDummy from './dummy/cancelChequeDummy.png';
import gstRegistrationCertificate from './dummy/GSTRegistrationCertificate.jpg';
// import gstin from './dummy/gstin.pdf';
import incorporationCertificate from './dummy/certificateIncorporation.jpg';
import logoDummy from './dummy/logoDummy.png';

import partnerService from '../services/partnerService';
import Loader from '../Global/Loader';
import ErrorDialog from '../Global/ErrorDialog';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const {
  PartnerTypes,
  DocumentTypes,
  StateList,
  CityList,
  CreatePartner,
  DocumentUpload,
  CompanyType,
  OnBoardReview
} = partnerService;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function EditOnBoarding() {
  const { rowId } = useParams();
  const navigate = useNavigate();
  const [partnerType1, setPartnerType1] = useState('');
  console.log('partnerType1', partnerType1);
  const [curFileName, setCurFileName] = useState('');

  const [photoPreview, setPhotoPreview] = useState(photoDummy);
  const [aadharFrontPreview, setAadharFrontPreview] = useState(aadharFrontDummy);
  const [aadharBackPreview, setAadharBackPreview] = useState(aadharBackDummy);
  const [panPreview, setPanPreview] = useState(panCardDummy);
  const [chequePreview, setChequePreview] = useState(cancelChequeDummy);
  const [token, setToken] = useState('');
  const [partnerTypeList, setPartnerTypeList] = useState([]);
  const [stateListMenu, setStateListMenu] = useState([]);
  const [documentTypesList, setDocumentTypesList] = useState({});
  const [cityListMenu, setCityListMenu] = useState([]);
  const [stateListData, setStateListData] = useState(null);
  const [cityListData, setCityListData] = useState(null);
  const [stateToken, setStateToken] = useState('');
  const [userDetailsData, setUserDetailsData] = useState(null);
  const [indName, setIndName] = useState('');
  const [indFatherName, setIndFatherName] = useState('');
  const [indDob, setIndDob] = useState('');
  const [indMobile, setMobile] = useState('');
  const [mobileHelper, setMobileHelper] = useState('');
  const [mobileError, setMobileError] = useState(false);
  const [indEmail, setIndEmail] = useState('');
  const [indAddress, setIndAddress] = useState('');
  const [createPartnerData, setCreatePartnerData] = useState({});
  const [uploadPhoto, setUploadPhoto] = useState(); // uploaded image file frontside
  const [uploadImageFileFront, setUploadImageFileFront] = useState(null); // uploaded image file frontside
  const [uploadImageFileBack, setUploadImageFileBack] = useState(null); // uploaded image file backside
  const [uploadPanImage, setUploadPanImage] = useState();
  const [uploadChequeImage, setUploadChequeImage] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [open, setOpen] = useState(false); // dialog box for error handling
  const [validationMsg, setValidationMsg] = useState(''); // validation message
  const [currentDate, setCurrentDate] = useState({});
  const [indDobYear, setIndDobYear] = useState({});

  // partner type -company
  const [companyName, setCompanyName] = useState('');
  const [authorisedPersonName, setAuthorisedPersonName] = useState('');
  const [compMobile, setCompMobile] = useState('');
  const [compEmail, setCompEmail] = useState('');
  const [selectDate, setSelectDate] = useState('');
  const [compGst, setCompGst] = useState('');
  const [compPan, setCompPan] = useState('');
  const [compAddress, setCompAddress] = useState('');
  const [stateListValueComp, setStateListValueComp] = useState(null);
  const [cityListValueComp, setCityListValueComp] = useState(null);
  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [selectCompanyType, setSelectCompanyType] = useState(' ');
  const [gstImage, setGstImage] = useState();
  const [panPreview1, setPanPreview1] = useState(panCardDummy);
  const [gstPreview, setGstPreview] = useState(gstRegistrationCertificate);
  const [gstFileType, setGstFileType] = useState('jpg');

  // const [gstPreview, setGstPreview] = useState(gstin);
  const [incorporationPreview, setIncorporationPreview] = useState(incorporationCertificate);
  const [incorporationUploadComp, setIncorporationUploadComp] = useState();
  const [uploadPanImageCompany, setUploadPanImageCompany] = useState();
  const [chequePreview1, setChequePreview1] = useState(cancelChequeDummy);
  const [uploadChequeCompany, setUploadChequeCompany] = useState();
  const [logoPreview, setLogoPreview] = useState(logoDummy);
  const [logoUpload, setLogoUpload] = useState();

  const [inputValueStateInd, setInputValueStateInd] = useState('');
  const [inputValueCityInd, setInputValueCityInd] = useState('');
  const [inputValueStateComp, setInputValueStateComp] = useState('');
  const [inputValueCityComp, setInputValueCityComp] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // setting 1 to show fisrt page

  const [chequePreviewtype, setChequePreviewtype] = useState('');
  const [panPreviewtype, setPanPreviewtype] = useState('');
  const [aadharBackPreviewtype, setAadharBackPreviewtype] = useState('');
  const [photoPreviewtype, setPhotoPreviewtype] = useState('');
  const [aadharFrontPreviewtype, setAadharFrontPreviewtype] = useState('');

  const [chequePreview1type, setChequePreview1type] = useState('');
  const [panPreview1type, setPanPreview1type] = useState('');
  const [gstPreviewtype, setGstPreviewtype] = useState('');
  const [incorporationPreviewtype, setIncorporationPreviewtype] = useState('');
  const [logoPreviewtype, setLogoPreviewtype] = useState('');

  const [chequePreviewtype1, setChequePreviewtype1] = useState('');
  const [panPreviewtype1, setPanPreviewtype1] = useState('');
  const [aadharBackPreviewtype1, setAadharBackPreviewtype1] = useState('');
  const [photoPreviewtype1, setPhotoPreviewtype1] = useState('');
  const [aadharFrontPreviewtype1, setAadharFrontPreviewtype1] = useState('');

  const [chequePreview1type1, setChequePreview1type1] = useState('');
  const [panPreview1type1, setPanPreview1type1] = useState('');
  const [gstPreviewtype1, setGstPreviewtype1] = useState('');
  const [incorporationPreviewtype1, setIncorporationPreviewtype1] = useState('');
  const [logoPreviewtype1, setLogoPreviewtype1] = useState('');

  // OnBoardReview api data
  const [reviewData, setReviewData] = useState(null);
  // partner id from local Storage
  const [partnerIdLS, setPartnerIdLS] = useState(null);
  console.log('reviewData', reviewData);
  console.log('partnerIdLS', partnerIdLS);

  console.log('selectCompanyType', selectCompanyType);

  // File upload functions UI
  const handleFileUpload = async (
    e,
    setPreview,
    files,
    setUploadImage,
    setLocaltype,
    setLivetype
  ) => {
    if (e.target.files[0] !== undefined) {
      setLivetype('');
      setLocaltype(e.target.files[0].name.split('.')[1]);
      setUploadImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e, setPreview, setUploadImage, setLocaltype, setLivetype) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    // console.log(files[0]);
    handleFileUpload(e, setPreview, files[0], setUploadImage, setLocaltype, setLivetype);
  };

  const uploadFileClick = (e, btnId) => {
    const browseField = document.getElementById(btnId);
    browseField.click();
  };

  const getCityList = async (e, newValue) => {
    const CityListApiResponse = await CityList(
      {
        searchLiteral: '',
        stateId: newValue.stateId ? newValue.stateId : null
      },
      token
    );
    console.log('CityListApiResponse', CityListApiResponse);
    if (CityListApiResponse.action_status === 'success') {
      setCityListMenu(CityListApiResponse.data);
    } else {
      setCityListMenu([]);
    }
  };

  /// closing dialog box of error handling
  const handleClose = () => {
    setOpen(false);
  };
  // handle dob
  const handleDob = (e) => {
    setIndDob(e.target.value);
    const fullYear = new Date(e.target.value).getFullYear();
    setIndDobYear(fullYear);
  };

  const handleSave = (e) => {
    console.log('over18', currentDate - indDobYear);
    const pattern = /^[6-9][0-9]{9}$/; // mobile regex pattern
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // email regex pattern
    if (JSON.parse(partnerType1).typeCode === 'INDIVIDUAL') {
      // INDIVIDUAL vaildation
      if (indName === '') {
        setOpen(true);
        setValidationMsg('Please enter your Name');
        return;
      }
      // if (indFatherName === '') {
      //   setOpen(true);
      //   setValidationMsg('Please enter your Father Name');
      //   return;
      // }
      const diff = currentDate - indDobYear;
      if (indDob === '') {
        setOpen(true);
        setValidationMsg('Please enter your Date of Birth');
        return;
      } else if (diff < 18) {
        console.log('over18', currentDate - indDobYear);
        setOpen(true);
        setValidationMsg('Age should be greater then 18 years');
        return;
      }
      if (!pattern.test(indMobile)) {
        setOpen(true);
        setValidationMsg('Please enter valid number');
        return;
      }

      if (!mailformat.test(indEmail)) {
        setOpen(true);
        setValidationMsg('Please enter your E-Mail address');
        return;
      }
      // if (indEmail === '') {
      //   setOpen(true);
      //   setValidationMsg('Please enter your Email');
      //   return;
      // } else if (!mailformat.test(indEmail)) {
      //   setOpen(true);
      //   setValidationMsg('Please enter your correct email id');
      //   return;
      // }
      // if (indAddress === '') {
      //   setOpen(true);
      //   setValidationMsg('Please enter your Address');
      //   return;
      // }
      // if (stateListData === null) {
      //   setOpen(true);
      //   setValidationMsg('Please select state');
      //   return;
      // }
      // console.log('cityListDatacityListData', cityListData);
      // if (cityListData === null) {
      //   setOpen(true);
      //   setValidationMsg('Please select city');
      //   return;
      // }

      // if (uploadImageFileFront !== undefined && uploadImageFileBack === undefined) {
      //   setOpen(true);
      //   setValidationMsg('Please upload aadhaar card backside');
      //   return;
      // }
      // if (uploadImageFileBack !== undefined && uploadImageFileFront === undefined) {
      //   setOpen(true);
      //   setValidationMsg('Please upload aadhaar card frontside');
      //   return;
      // }
      // if (photoPreview === photoDummy) {
      //   setOpen(true);
      //   setValidationMsg('Please upload profile photo');
      //   return;
      // }
      // if (aadharFrontPreview === aadharFrontDummy) {
      //   setOpen(true);
      //   setValidationMsg('Please upload aadhaar card frontside');
      //   return;
      // }
      // if (aadharBackPreview === aadharBackDummy) {
      //   setOpen(true);
      //   setValidationMsg('Please upload aadhaar card backside');
      //   return;
      // }
      // if (panPreview === panCardDummy) {
      //   setOpen(true);
      //   setValidationMsg('Please upload pancard');
      //   return;
      // }
      // if (chequePreview === cancelChequeDummy) {
      //   setOpen(true);
      //   setValidationMsg('Please upload cancel cheque');
      //   return;
      // }
    } else {
      if (companyName === '') {
        setOpen(true);
        setValidationMsg('Please enter company Name');
        return;
      }
      if (!pattern.test(compMobile)) {
        setOpen(true);
        setValidationMsg('Please enter valid number');
        return;
      }
      if (!mailformat.test(compEmail)) {
        setOpen(true);
        setValidationMsg('Please enter your E-Mail address');
        return;
      }
    }
    setOpenLoader(true);
    (async () => {
      const createCredentials = {
        companyId: userDetailsData.companyId,
        typeId:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'INDIVIDUAL'
            ? JSON.parse(partnerType1).typeId
            : partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'COMPANY'
            ? JSON.parse(partnerType1).typeId
            : '',
        partnerName:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'INDIVIDUAL'
            ? String(indName)
            : String(companyName),
        fathersName:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'INDIVIDUAL'
            ? String(indFatherName)
            : null,
        dob:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'INDIVIDUAL'
            ? String(indDob)
            : String(selectDate),
        mobile:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'INDIVIDUAL'
            ? String(indMobile)
            : String(compMobile),
        address:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'INDIVIDUAL'
            ? String(indAddress)
            : String(compAddress),
        emailAddress:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'INDIVIDUAL'
            ? String(indEmail)
            : String(compEmail),
        // districtId:
        //   partnerType1 !== '' &&
        //   JSON.parse(partnerType1).typeCode === 'INDIVIDUAL' &&
        //   cityListData.cityId
        //     ? cityListData.cityId
        //     : cityListValueComp.cityId,
        // provinceId:
        //   partnerType1 !== '' &&
        //   JSON.parse(partnerType1).typeCode === 'INDIVIDUAL' &&
        //   stateListData.stateId
        //     ? stateListData.stateId
        //     : stateListValueComp.stateId,

        provinceId:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'INDIVIDUAL' && stateListData
            ? stateListData.stateId
            : null ||
              (partnerType1 !== '' &&
                JSON.parse(partnerType1).typeCode === 'COMPANY' &&
                stateListValueComp)
            ? stateListValueComp.stateId
            : null,
        districtId:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'INDIVIDUAL' && cityListData
            ? cityListData.cityId
            : null ||
              (partnerType1 !== '' &&
                JSON.parse(partnerType1).typeCode === 'COMPANY' &&
                cityListValueComp)
            ? cityListValueComp.cityId
            : null,

        authorisedPersonName:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'COMPANY'
            ? String(authorisedPersonName)
            : null,
        partnerCompanyTypeId:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'COMPANY'
            ? selectCompanyType
            : null,
        gstNumber:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'COMPANY'
            ? String(compGst)
            : null,
        panNumber:
          partnerType1 !== '' && JSON.parse(partnerType1).typeCode === 'COMPANY'
            ? String(compPan)
            : null,
        countryId: 101,
        isSignUpFlow: false,
        partnerId: rowId ? rowId : null
      };
      const CreatePartnerApiResponse = await CreatePartner(createCredentials, token);
      console.log('apifired');
      if (CreatePartnerApiResponse.status === 'success') {
        if (CreatePartnerApiResponse.data.isPartnerAlreadyExist) {
          setMobileHelper('Mobile Number Already Available');
          setMobileError(true);
          setOpenLoader(false);
          return;
        }
        // isPartnerAlreadyExist

        setCreatePartnerData(CreatePartnerApiResponse.data);

        // photo api
        if (uploadPhoto && uploadPhoto.size > 0) {
          (async () => {
            // user photo
            const formData = new FormData();
            formData.append('partnerId', CreatePartnerApiResponse.data.partnerId);
            formData.append('companyId', CreatePartnerApiResponse.data.companyId);
            documentTypesList.forEach((item) => {
              console.log(item);
              if (item.typeCode === 'USER IMAGE') {
                formData.append('documentTypeId', item.typeId);
              }
            });
            formData.append('userImage', uploadPhoto);
            return fetch(DocumentUpload(), {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: formData
            }).then((response) => {
              response.json().then((data) => {
                if (data.status === 'success') {
                  console.log('aadharData', data);
                }
              });
            });
          })();
        }

        // aadhar card api
        if (
          uploadImageFileFront ||
          uploadImageFileBack
          // uploadImageFileFront.size > 0 &&
          // uploadImageFileBack.size > 0
        ) {
          (async () => {
            // user photo
            const formData = new FormData();
            formData.append('partnerId', CreatePartnerApiResponse.data.partnerId);
            formData.append('companyId', CreatePartnerApiResponse.data.companyId);
            documentTypesList.forEach((item) => {
              console.log(item);
              if (item.typeCode === 'AADHAR CARD') {
                formData.append('documentTypeId', item.typeId);
              }
            });
            if (uploadImageFileFront) {
              formData.append('aadharFront', uploadImageFileFront);
            }
            if (uploadImageFileBack) {
              formData.append('aadharBack', uploadImageFileBack);
            }
            return fetch(DocumentUpload(), {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: formData
            }).then((response) => {
              response.json().then((data) => {
                if (data.status === 'success') {
                  console.log('aadharData', data);
                }
              });
            });
          })();
        }
        // pan card api
        if (uploadPanImage && uploadPanImage.size > 0) {
          (async () => {
            // user photo
            const formData = new FormData();
            formData.append('partnerId', CreatePartnerApiResponse.data.partnerId);
            formData.append('companyId', CreatePartnerApiResponse.data.companyId);
            documentTypesList.forEach((item) => {
              console.log(item);
              if (item.typeCode === 'PAN CARD') {
                formData.append('documentTypeId', item.typeId);
              }
            });
            formData.append('panCard', uploadPanImage);
            return fetch(DocumentUpload(), {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: formData
            }).then((response) => {
              response.json().then((data) => {
                if (data.status === 'success') {
                  console.log('aadharData', data);
                }
              });
            });
          })();
        }
        // cheque api
        if (uploadChequeImage && uploadChequeImage.size > 0) {
          (async () => {
            // user photo
            const formData = new FormData();
            formData.append('partnerId', CreatePartnerApiResponse.data.partnerId);
            formData.append('companyId', CreatePartnerApiResponse.data.companyId);
            documentTypesList.forEach((item) => {
              console.log(item);
              if (item.typeCode === 'CHEQUE') {
                formData.append('documentTypeId', item.typeId);
              }
            });
            formData.append('cheque', uploadChequeImage);
            return fetch(DocumentUpload(), {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: formData
            }).then((response) => {
              response.json().then((data) => {
                if (data.status === 'success') {
                  console.log('aadharData', data);
                }
              });
            });
          })();
        }

        // logo Upload company
        if (logoUpload && logoUpload.size > 0) {
          (async () => {
            // user photo
            const formData = new FormData();
            formData.append('partnerId', CreatePartnerApiResponse.data.partnerId);
            formData.append('companyId', CreatePartnerApiResponse.data.companyId);
            documentTypesList.forEach((item) => {
              console.log(item);
              if (item.typeCode === 'COMPANY_LOGO') {
                formData.append('documentTypeId', item.typeId);
              }
            });
            formData.append('companyLogo', logoUpload);
            return fetch(DocumentUpload(), {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: formData
            }).then((response) => {
              response.json().then((data) => {
                if (data.status === 'success') {
                  console.log('aadharData', data);
                }
              });
            });
          })();
        }

        // gst certificate company
        if (gstImage && gstImage.size > 0) {
          (async () => {
            const formData = new FormData();
            formData.append('partnerId  ', CreatePartnerApiResponse.data.partnerId);
            formData.append('companyId', CreatePartnerApiResponse.data.companyId);
            documentTypesList.forEach((item) => {
              console.log(item);
              if (item.typeCode === 'GSTIN') {
                formData.append('documentTypeId', item.typeId);
              }
            });
            formData.append('gstInDocument', gstImage);
            return fetch(DocumentUpload(), {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: formData
            }).then((response) => {
              response.json().then((data) => {
                if (data.status === 'success') {
                  console.log('aadharData', data);
                }
              });
            });
          })();
        }

        // incorporation Upload Company
        if (incorporationUploadComp && incorporationUploadComp.size > 0) {
          (async () => {
            const formData = new FormData();
            formData.append('partnerId  ', CreatePartnerApiResponse.data.partnerId);
            formData.append('companyId', CreatePartnerApiResponse.data.companyId);
            documentTypesList.forEach((item) => {
              console.log(item);
              if (item.typeCode === 'INCORPORATION_CERTIFICATE') {
                formData.append('documentTypeId', item.typeId);
              }
            });
            formData.append('incorporationCertificate', incorporationUploadComp);
            return fetch(DocumentUpload(), {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: formData
            }).then((response) => {
              response.json().then((data) => {
                if (data.status === 'success') {
                  console.log('aadharData', data);
                }
              });
            });
          })();
        }

        // pancard upload api company
        if (uploadPanImageCompany && uploadPanImageCompany.size > 0) {
          (async () => {
            // user photo
            const formData = new FormData();
            formData.append('partnerId', CreatePartnerApiResponse.data.partnerId);
            formData.append('companyId', CreatePartnerApiResponse.data.companyId);
            documentTypesList.forEach((item) => {
              console.log(item);
              if (item.typeCode === 'PAN CARD') {
                formData.append('documentTypeId', item.typeId);
              }
            });
            formData.append('panCard', uploadPanImageCompany);
            return fetch(DocumentUpload(), {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: formData
            }).then((response) => {
              response.json().then((data) => {
                if (data.status === 'success') {
                  console.log('aadharData', data);
                }
              });
            });
          })();
        }

        // cheque api company
        if (uploadChequeCompany && uploadChequeCompany.size > 0) {
          (async () => {
            // user photo
            const formData = new FormData();
            formData.append('partnerId', CreatePartnerApiResponse.data.partnerId);
            formData.append('companyId', CreatePartnerApiResponse.data.companyId);
            documentTypesList.forEach((item) => {
              console.log(item);
              if (item.typeCode === 'CHEQUE') {
                formData.append('documentTypeId', item.typeId);
              }
            });
            formData.append('cheque', uploadChequeCompany);
            return fetch(DocumentUpload(), {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: formData
            }).then((response) => {
              response.json().then((data) => {
                if (data.status === 'success') {
                  console.log('aadharData', data);
                }
              });
            });
          })();
        }

        setOpenLoader(false);
        localStorage.setItem('partnerId', CreatePartnerApiResponse.data.partnerId);
        navigate('/onboarding');
      } else {
        setOpenLoader(false);
        setErrorOpen(true);
      }
    })();
  };
  const handlePartnerType = (e) => {
    setPartnerType1(e.target.value);
    setStateListData([]);
    setCityListMenu([]);
    setCityListData(null);
    setSelectCompanyType(' ');
    setIndName('');
    setIndFatherName('');
    setIndDob('');
    setMobile('');
    setIndEmail('');
    setIndAddress('');
    setPhotoPreview(photoDummy);
    setAadharFrontPreview(aadharFrontDummy);
    setAadharBackPreview(aadharBackDummy);
    setPanPreview(panCardDummy);
    setChequePreview(cancelChequeDummy);
    setCompanyName('');
    setAuthorisedPersonName('');
    setCompMobile('');
    setCompEmail('');
    setSelectDate('');
    setCompGst('');
    setCompPan('');
    setCompAddress('');
    setGstPreview(gstRegistrationCertificate);
    setPanPreview1(panCardDummy);
    setChequePreview1(cancelChequeDummy);
    setIncorporationPreview(incorporationCertificate);
    setLogoPreview(logoDummy);
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const authToken = localJWTtoken.token.jwt;
    setToken(authToken);
    setUserDetailsData(localJWTtoken.token);

    (async () => {
      const PartnerListResponse = await PartnerTypes(authToken);
      console.log('PartnerListResponse', PartnerListResponse);
      if (PartnerListResponse.status === 'success') {
        setPartnerTypeList(PartnerListResponse.data.partnerTypes);
        // setPartnerType1(JSON.stringify(PartnerListResponse.data.partnerTypes[0]));
      } else {
        setPartnerTypeList([]);
        // setPartnerType1('');
      }

      // state list api
      const StateCredentials = {
        countryId: 101,
        searchLiteral: ''
      };

      const StateListApiResponse = await StateList(StateCredentials, authToken);
      console.log('StateListApiResponse', StateListApiResponse);
      if (StateListApiResponse.action_status === 'success') {
        setStateListMenu(StateListApiResponse.data);
      } else {
        setStateListMenu([]);
      }

      // document types api
      const DocumentTypesApiResponse = await DocumentTypes(authToken);
      console.log('DocumentTypesApiResponse', DocumentTypesApiResponse);
      if (DocumentTypesApiResponse.status === 'success') {
        setDocumentTypesList(DocumentTypesApiResponse.data.documentTypes);
      } else {
        setDocumentTypesList({});
      }

      // Company Type
      const CompanyTypeApiResponse = await CompanyType(authToken);
      console.log('CompanyTypeApiResponse', CompanyTypeApiResponse);
      if (CompanyTypeApiResponse.status === 'success') {
        setCompanyTypeList(CompanyTypeApiResponse.data.partnerCompanyTypes);
      } else {
        setCompanyTypeList([]);
      }

      // review api for editing the data
      const authPartnerId = JSON.parse(localStorage.getItem('partnerId'));
      setPartnerIdLS(authPartnerId);
      const credential = {
        companyId: localJWTtoken.token.companyId,
        partnerId: authPartnerId
      };
      const ifPrevieDataAvailable = await OnBoardReview(credential, authToken);
      // console.log('ifPrevieDataAvailabled', ifPrevieDataAvailable);
      if (ifPrevieDataAvailable.status === 'success') {
        setReviewData(ifPrevieDataAvailable.data);
        setPartnerType1(JSON.stringify(ifPrevieDataAvailable.data.partnerType));

        if (ifPrevieDataAvailable.data.partnerType.typeCode === 'INDIVIDUAL') {
          // console.log('fhfhfhfhfhfhj', ifPrevieDataAvailable.data.partnerDocuments[1].documentUrl);
          setIndName(ifPrevieDataAvailable.data.partnerName);
          setIndFatherName(ifPrevieDataAvailable.data.fathersName);
          setIndDob(ifPrevieDataAvailable.data.dob);
          setMobile(ifPrevieDataAvailable.data.mobile);
          setIndEmail(ifPrevieDataAvailable.data.emailAddress);
          setIndAddress(ifPrevieDataAvailable.data.address);
          setStateListData(ifPrevieDataAvailable.data.stateEntity);
          setInputValueStateInd(ifPrevieDataAvailable.data.stateEntity);
          setCityListData(ifPrevieDataAvailable.data.cityEntity);
          setInputValueCityInd(ifPrevieDataAvailable.data.cityEntity);
          if (ifPrevieDataAvailable.data.partnerDocuments.length > 0) {
            ifPrevieDataAvailable.data.partnerDocuments.forEach((docItem) => {
              console.log('hiiiiiiiiiiiiiiiii', docItem);
              if (docItem.documentType.typeCode === 'CHEQUE') {
                setChequePreview(docItem.documentUrl);
                setChequePreviewtype(docItem.contentType);
              }
              if (docItem.documentType.typeCode === 'PAN CARD') {
                setPanPreview(docItem.documentUrl);
                setPanPreviewtype(docItem.contentType);
              }
              if (
                docItem.documentType.typeCode === 'AADHAR CARD' &&
                docItem.aadharFrontFace === false
              ) {
                setAadharBackPreview(docItem.documentUrl);
                setAadharBackPreviewtype(docItem.contentType);
              }
              if (docItem.documentType.typeCode === 'USER IMAGE') {
                setPhotoPreview(docItem.documentUrl);
                setPhotoPreviewtype(docItem.contentType);
              }
              if (
                docItem.documentType.typeCode === 'AADHAR CARD' &&
                docItem.aadharFrontFace === true
              ) {
                setAadharFrontPreview(docItem.documentUrl);
                setAadharFrontPreviewtype(docItem.contentType);
              }
            });
          }
          // setPhotoPreview(
          //   ifPrevieDataAvailable.data.partnerDocuments[4]
          //     ? ifPrevieDataAvailable.data.partnerDocuments[4].documentUrl
          //     : photoDummy
          // );
          // setAadharFrontPreview(
          //   ifPrevieDataAvailable.data.partnerDocuments[3]
          //     ? ifPrevieDataAvailable.data.partnerDocuments[3].documentUrl
          //     : aadharFrontDummy
          // );
          // setAadharBackPreview(
          //   ifPrevieDataAvailable.data.partnerDocuments[2]
          //     ? ifPrevieDataAvailable.data.partnerDocuments[2].documentUrl
          //     : aadharBackDummy
          // );
          // setPanPreview(
          //   ifPrevieDataAvailable.data.partnerDocuments[1]
          //     ? ifPrevieDataAvailable.data.partnerDocuments[1].documentUrl
          //     : panCardDummy
          // );
          // setChequePreview(
          //   ifPrevieDataAvailable.data.partnerDocuments[0]
          //     ? ifPrevieDataAvailable.data.partnerDocuments[0].documentUrl
          //     : cancelChequeDummy
          // );
        } else {
          setCompanyName(ifPrevieDataAvailable.data.partnerName);
          setAuthorisedPersonName(ifPrevieDataAvailable.data.authorisedPersonName);
          setCompMobile(ifPrevieDataAvailable.data.mobile);
          setCompEmail(ifPrevieDataAvailable.data.emailAddress);
          setSelectDate(ifPrevieDataAvailable.data.dob);
          setSelectCompanyType(ifPrevieDataAvailable.data.partnerCompanyTypeId);
          setStateListValueComp(ifPrevieDataAvailable.data.stateEntity);
          setInputValueStateComp(ifPrevieDataAvailable.data.stateEntity);
          setCityListValueComp(ifPrevieDataAvailable.data.cityEntity);
          setInputValueCityComp(ifPrevieDataAvailable.data.cityEntity);
          setCompGst(ifPrevieDataAvailable.data.gstNumber);
          setCompPan(ifPrevieDataAvailable.data.panNumber);
          setCompAddress(ifPrevieDataAvailable.data.address);

          if (ifPrevieDataAvailable.data.partnerDocuments.length > 0) {
            ifPrevieDataAvailable.data.partnerDocuments.forEach((docItem) => {
              if (docItem.documentType.typeCode === 'CHEQUE') {
                setChequePreview1(docItem.documentUrl);
                setChequePreview1type(docItem.contentType);
              }
              if (docItem.documentType.typeCode === 'PAN CARD') {
                setPanPreview1(docItem.documentUrl);
                setPanPreview1type(docItem.contentType);
              }
              if (docItem.documentType.typeCode === 'GSTIN') {
                setGstPreview(docItem.documentUrl);
                setGstPreviewtype(docItem.contentType);
              }
              if (docItem.documentType.typeCode === 'INCORPORATION_CERTIFICATE') {
                setIncorporationPreview(docItem.documentUrl);
                setIncorporationPreviewtype(docItem.contentType);
              }
              if (docItem.documentType.typeCode === 'COMPANY_LOGO') {
                setLogoPreview(docItem.documentUrl);
                setLogoPreviewtype(docItem.contentType);
              }
            });
          }

          // setGstPreview(ifPrevieDataAvailable.data.partnerDocuments[2].documentUrl);
          // setPanPreview1(ifPrevieDataAvailable.data.partnerDocuments[1].documentUrl);
          // setChequePreview1(ifPrevieDataAvailable.data.partnerDocuments[0].documentUrl);
          // setIncorporationPreview(ifPrevieDataAvailable.data.partnerDocuments[3].documentUrl);
          // setLogoPreview(ifPrevieDataAvailable.data.partnerDocuments[4].documentUrl);
        }
      }
    })();
    const today = new Date();
    setCurrentDate(today.getFullYear());
  }, []);
  return (
    <Page title="PULSE by Real Value Finloans || On Boarding">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info={rowId ? 'Edit Partner' : 'Add New Partner'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box mb={3}>
                <Typography variant="h6" className="formLabel">
                  Partner Type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  value={partnerType1}
                  onChange={(e) => {
                    handlePartnerType(e);
                  }}
                >
                  {partnerTypeList.length > 0
                    ? partnerTypeList.map((item, index) => {
                        console.log('item22', item);
                        return (
                          <MenuItem value={JSON.stringify(item)} key={index}>
                            {item.typeName}
                          </MenuItem>
                        );
                      })
                    : ''}
                </Select>
              </Box>
            </Grid>
          </Grid>

          {partnerType1 !== '' ? (
            JSON.parse(partnerType1).typeCode === 'INDIVIDUAL' ? (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Individual Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Full Name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Full Name"
                          value={indName}
                          onChange={(e) => setIndName(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Father Name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Father's Name"
                          value={indFatherName}
                          onChange={(e) => setIndFatherName(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          DOB
                        </Typography>
                        <TextField
                          variant="outlined"
                          inputProps={{
                            className: `textInput dissabledMenu${indDob}`
                          }}
                          placeholder="Select Date of Birth"
                          fullWidth
                          type="date"
                          value={indDob}
                          onChange={(e) => handleDob(e)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Mobile
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Mobile Number"
                          value={indMobile}
                          helperText={mobileHelper}
                          error={mobileError}
                          onChange={(e) => {
                            setMobile(e.target.value);
                            setMobileError(false);
                            setMobileHelper('');
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Email
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Email"
                          value={indEmail}
                          onChange={(e) => setIndEmail(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Address
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Address"
                          value={indAddress}
                          onChange={(e) => setIndAddress(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          State
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo1"
                          options={stateListMenu}
                          size="small"
                          value={stateListData}
                          inputValue={inputValueStateInd}
                          onChange={(e, newValue) => {
                            setCityListData(null);
                            // setCityListMenu([]);
                            // setStateListData(newValue.stateId);
                            setStateListData(newValue);
                            console.log('newValue', newValue);
                            // getCityList(newValue.stateId);
                            getCityList(e, newValue);
                          }}
                          onInputChange={(event, newInputValue) => {
                            setInputValueStateInd(newInputValue);
                          }}
                          getOptionLabel={(option) => option.stateName}
                          renderOption={(props, option) => <li {...props}>{option.stateName}</li>}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth placeholder="Select State" />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          City
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo2"
                          options={cityListMenu}
                          size="small"
                          value={cityListData}
                          inputValue={inputValueCityInd}
                          onChange={(e, newValue) => {
                            // setCityListData(newValue.cityId);
                            setCityListData(newValue);
                            console.log('newValue', newValue);
                          }}
                          onInputChange={(event, newInputValue) => {
                            setInputValueCityInd(newInputValue);
                          }}
                          getOptionLabel={(option) => option.cityName}
                          renderOption={(props, option) => <li {...props}>{option.cityName}</li>}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth placeholder="Select City" />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Upload Documents
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Photo
                        </Typography>
                        <input
                          accept="image/png, image/gif, image/jpeg, application/pdf"
                          className="fileInput"
                          id="contained-button-file1"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setPhotoPreview,
                              null,
                              setUploadPhoto,
                              setPhotoPreviewtype1,
                              setPhotoPreviewtype
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file1"
                          className={
                            photoPreviewtype1 === 'pdf' || photoPreviewtype === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(
                                e,
                                setPhotoPreview,
                                setUploadPhoto,
                                setPhotoPreviewtype1,
                                setPhotoPreviewtype
                              )
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {/* <Typography variant="h6" className="partnerTypeIconO mb-2">
                            <AiOutlineCloudUpload fontSize={40} />
                          </Typography>
                          <Typography variant="subtitle1" className="textAlignCenter">
                            Photo
                          </Typography> */}

                            {photoPreviewtype1 === 'pdf' ||
                            photoPreviewtype === 'application/pdf' ? (
                              <Document
                                file={photoPreview}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={photoPreview}
                                alt="Cancel Cheque"
                                className="previewImage"
                              />
                            )}

                            {/* <Typography className="globalDragDropButton">
                            <Button
                              onClick={(e) => uploadFileClick(e, 'contained-button-file')}
                              variant="contained"
                              className="UploadBtn globalUploadButton"
                            >
                              Browser
                            </Button>
                          </Typography>
                          <Typography
                            variant="body1"
                            className="globalDragDropSubFileHeading"
                            style={{ fontSize: 12, marginTop: '10px' }}
                          >
                            Maximum upload file size: 1GB
                          </Typography> */}
                            {/* {curFileName} */}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Aadhar Front
                        </Typography>
                        <input
                          accept="image/png, image/gif, image/jpeg, application/pdf"
                          className="fileInput"
                          id="contained-button-file2"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setAadharFrontPreview,
                              null,
                              setUploadImageFileFront,
                              setAadharFrontPreviewtype1,
                              setAadharFrontPreviewtype
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file2"
                          className={
                            aadharFrontPreviewtype1 === 'pdf' ||
                            aadharFrontPreviewtype === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(e, setAadharFrontPreview, setUploadImageFileFront)
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {aadharFrontPreviewtype1 === 'pdf' ||
                            aadharFrontPreviewtype === 'application/pdf' ? (
                              <Document
                                file={aadharFrontPreview}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={aadharFrontPreview}
                                alt="Cancel Cheque"
                                className="previewImage"
                              />
                            )}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Aadhar Back
                        </Typography>
                        <input
                          accept="image/png, image/gif, image/jpeg, application/pdf"
                          className="fileInput"
                          id="contained-button-file3"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setAadharBackPreview,
                              null,
                              setUploadImageFileBack,
                              setAadharBackPreviewtype1,
                              setAadharBackPreviewtype
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file3"
                          className={
                            aadharBackPreviewtype1 === 'pdf' ||
                            aadharBackPreviewtype === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(
                                e,
                                setAadharBackPreview,
                                setUploadImageFileBack,
                                setAadharBackPreviewtype1,
                                setAadharBackPreviewtype
                              )
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {aadharBackPreviewtype1 === 'pdf' ||
                            aadharBackPreviewtype === 'application/pdf' ? (
                              <Document
                                file={aadharBackPreview}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={aadharBackPreview}
                                alt="Cancel Cheque"
                                className="previewImage"
                              />
                            )}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          PAN Card
                        </Typography>
                        <input
                          accept="image/png, image/gif, image/jpeg, application/pdf"
                          className="fileInput"
                          id="contained-button-file4"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setPanPreview,
                              null,
                              setUploadPanImage,
                              setPanPreviewtype1,
                              setPanPreviewtype
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file4"
                          className={
                            panPreviewtype1 === 'pdf' || panPreviewtype === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(
                                e,
                                setPanPreview,
                                setUploadPanImage,
                                setPanPreviewtype1,
                                setPanPreviewtype
                              )
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {panPreviewtype1 === 'pdf' || panPreviewtype === 'application/pdf' ? (
                              <Document
                                file={panPreview}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img src={panPreview} alt="Cancel Cheque" className="previewImage" />
                            )}
                          </Box>
                        </label>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Cancelled Cheque
                        </Typography>
                        <input
                          accept="image/png, image/gif, image/jpeg, application/pdf"
                          className="fileInput"
                          id="contained-button-file5"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setChequePreview,
                              null,
                              setUploadChequeImage,
                              setChequePreviewtype1,
                              setChequePreviewtype
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file5"
                          className={
                            chequePreviewtype1 === 'pdf' || chequePreviewtype === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(
                                e,
                                setChequePreview,
                                setUploadChequeImage,
                                setChequePreviewtype1,
                                setChequePreviewtype
                              )
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {chequePreviewtype1 === 'pdf' ||
                            chequePreviewtype === 'application/pdf' ? (
                              <Document
                                file={chequePreview}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={chequePreview}
                                alt="Cancel Cheque"
                                className="previewImage"
                              />
                            )}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Company Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Company Name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Company Name"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Authorised Person Name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Authorised Person Name"
                          value={authorisedPersonName}
                          onChange={(e) => setAuthorisedPersonName(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Mobile Number
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Mobile Number"
                          value={compMobile}
                          helperText={mobileHelper}
                          error={mobileError}
                          onChange={(e) => {
                            setCompMobile(e.target.value);
                            setMobileError(false);
                            setMobileHelper('');
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Email
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Email"
                          value={compEmail}
                          onChange={(e) => setCompEmail(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Company Type
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectCompanyType}`
                          }}
                          value={selectCompanyType}
                          onChange={(e) => setSelectCompanyType(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Company Type
                          </MenuItem>
                          {companyTypeList.length > 0
                            ? companyTypeList.map((item, index) => {
                                console.log('item', item);
                                return (
                                  <MenuItem value={item.typeId} key={index}>
                                    {item.typeName}
                                  </MenuItem>
                                );
                              })
                            : ''}
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Date of Incorporation
                        </Typography>
                        <TextField
                          variant="outlined"
                          inputProps={{
                            className: `textInput dissabledMenu${selectDate}`
                          }}
                          placeholder="Select Date of Incorporation"
                          fullWidth
                          type="date"
                          value={selectDate}
                          onChange={(e) => setSelectDate(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          GST Number
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type GST Number"
                          value={compGst}
                          onChange={(e) => setCompGst(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          PAN Number
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type PAN Number"
                          value={compPan}
                          onChange={(e) => setCompPan(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Address
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Address"
                          value={compAddress}
                          onChange={(e) => setCompAddress(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          State
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo3"
                          options={stateListMenu}
                          size="small"
                          value={stateListValueComp}
                          inputValue={inputValueStateComp}
                          onChange={(e, newValue) => {
                            setCityListValueComp(null);
                            setStateListValueComp(newValue);
                            getCityList(e, newValue);
                          }}
                          onInputChange={(event, newInputValue) => {
                            setInputValueStateComp(newInputValue);
                          }}
                          getOptionLabel={(option) => option.stateName}
                          renderOption={(props, option) => <li {...props}>{option.stateName}</li>}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth placeholder="Select State" />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          City
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo4"
                          options={cityListMenu}
                          size="small"
                          value={cityListValueComp}
                          inputValue={inputValueCityComp}
                          onChange={(e, newValue) => {
                            setCityListValueComp(newValue);
                            console.log('newValue', newValue);
                          }}
                          onInputChange={(event, newInputValue) => {
                            setInputValueCityComp(newInputValue);
                          }}
                          getOptionLabel={(option) => option.cityName}
                          renderOption={(props, option) => <li {...props}>{option.cityName}</li>}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth placeholder="Select City" />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Upload Documents
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Company Logo
                        </Typography>
                        <input
                          accept="image/png, image/gif, image/jpeg, application/pdf"
                          className="fileInput"
                          id="contained-button-file6"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setLogoPreview,
                              null,
                              setLogoUpload,
                              setLogoPreviewtype1,
                              setLogoPreviewtype
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file6"
                          className={
                            logoPreviewtype1 === 'pdf' || logoPreviewtype === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(
                                e,
                                setLogoPreview,
                                setLogoUpload,
                                setLogoPreviewtype1,
                                setLogoPreviewtype
                              )
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {/* <Typography variant="h6" className="partnerTypeIconO mb-2">
                            <AiOutlineCloudUpload fontSize={40} />
                          </Typography>
                          <Typography variant="subtitle1" className="textAlignCenter">
                            Photo
                          </Typography> */}

                            {logoPreviewtype1 === 'pdf' || logoPreviewtype === 'application/pdf' ? (
                              <Document
                                file={logoPreview}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img src={logoPreview} alt="Cancel Cheque" className="previewImage" />
                            )}

                            {/* <Typography className="globalDragDropButton">
                            <Button
                              onClick={(e) => uploadFileClick(e, 'contained-button-file')}
                              variant="contained"
                              className="UploadBtn globalUploadButton"
                            >
                              Browser
                            </Button>
                          </Typography>
                          <Typography
                            variant="body1"
                            className="globalDragDropSubFileHeading"
                            style={{ fontSize: 12, marginTop: '10px' }}
                          >
                            Maximum upload file size: 1GB
                          </Typography> */}
                            {/* {curFileName} */}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          GST Certificate
                        </Typography>
                        <input
                          // accept="image/png, image/gif, image/jpeg"
                          className="fileInput"
                          id="contained-button-file7"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setGstPreview,
                              null,
                              setGstImage,
                              setGstPreviewtype1,
                              setGstPreviewtype
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file7"
                          className={
                            gstPreviewtype1 === 'pdf' || gstPreviewtype === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(
                                e,
                                setGstPreview,
                                setGstImage,
                                setGstPreviewtype1,
                                setGstPreviewtype
                              )
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {/* <img src={gstPreview} alt="GST Certificate" className="previewImage" /> */}

                            {gstPreviewtype1 === 'pdf' || gstPreviewtype === 'application/pdf' ? (
                              <Document
                                file={gstPreview}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img src={gstPreview} alt="Cancel Cheque" className="previewImage" />
                            )}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Incoporation Certificate
                        </Typography>
                        <input
                          accept="image/png, image/gif, image/jpeg, application/pdf"
                          className="fileInput"
                          id="contained-button-file8"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setIncorporationPreview,
                              null,
                              setIncorporationUploadComp,
                              setIncorporationPreviewtype1,
                              setIncorporationPreviewtype
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file8"
                          className={
                            incorporationPreviewtype1 === 'pdf' ||
                            incorporationPreviewtype === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(
                                e,
                                setIncorporationPreview,
                                setIncorporationUploadComp,
                                setIncorporationPreviewtype1,
                                setIncorporationPreviewtype
                              )
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {incorporationPreviewtype1 === 'pdf' ||
                            incorporationPreviewtype === 'application/pdf' ? (
                              <Document
                                file={incorporationPreview}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={incorporationPreview}
                                alt="Cancel Cheque"
                                className="previewImage"
                              />
                            )}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          PAN Card ccc
                        </Typography>
                        <input
                          accept="image/png, image/gif, image/jpeg, application/pdf"
                          className="fileInput"
                          id="contained-button-file9"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setPanPreview1,
                              null,
                              setUploadPanImageCompany,
                              setPanPreview1type1,
                              setPanPreview1type
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file9"
                          className={
                            panPreview1type1 === 'pdf' || panPreview1type === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(
                                e,
                                setPanPreview1,
                                setUploadPanImageCompany,
                                setPanPreview1type1,
                                setPanPreview1type
                              )
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {panPreview1type1 === 'pdf' || panPreview1type === 'application/pdf' ? (
                              <Document
                                file={panPreview1}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img src={panPreview1} alt="Cancel Cheque" className="previewImage" />
                            )}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Cancelled Cheque
                        </Typography>
                        <input
                          accept="image/png, image/gif, image/jpeg, application/pdf"
                          className="fileInput"
                          id="contained-button-file10"
                          multiple
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              setChequePreview1,
                              null,
                              setUploadChequeCompany,
                              setChequePreview1type1,
                              setChequePreview1type
                            )
                          }
                        />
                        <label
                          htmlFor="contained-button-file10"
                          className={
                            chequePreview1type1 === 'pdf' ||
                            chequePreview1type === 'application/pdf'
                              ? `pdfBox uploadDocBoxLabel uploadDocBoxLabelFull`
                              : `uploadDocBoxLabel uploadDocBoxLabelFull`
                          }
                        >
                          <Box
                            variant="contained"
                            color="primary"
                            component="span"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) =>
                              fileDrop(
                                e,
                                setChequePreview1,
                                setUploadChequeCompany,
                                setChequePreview1type1,
                                setChequePreview1type
                              )
                            }
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {chequePreview1type1 === 'pdf' ||
                            chequePreview1type === 'application/pdf' ? (
                              <Document
                                file={chequePreview1}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={chequePreview1}
                                alt="Cancel Cheque"
                                className="previewImage"
                              />
                            )}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )
          ) : (
            ''
          )}
          <Box textAlign="right">
            <Button variant="contained" className="mainBtn" onClick={(e) => handleSave(e)}>
              Save
            </Button>
          </Box>
          <Loader openLoader={openLoader} />
          <ErrorDialog errorOpen={errorOpen} setErrorOpen={setErrorOpen} />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Retry</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}
export default EditOnBoarding;

// function ShowFile({ documentElements }) {
//   return (
//     <FileViewer
//       fileType={documentElements.contentType ? documentElements.contentType.split('/')[1] : 'jpg'}
//       filePath={documentElements.documentUrl}
//       className="uploadeImgViewver"
//     />
//   );
// }
