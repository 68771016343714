import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

function ErrorDialogBox({ showErrorPopUp, errorMsg, setshowErrorPopUp }) {
  console.log('erorMsg', showErrorPopUp);
  console.log('erorMsg', errorMsg);
  console.log('erorMsg', setshowErrorPopUp);
  return (
    <Dialog open={showErrorPopUp} fullWidth>
      <DialogTitle className="popupTitle" sx={{ backgroundColor: 'error', textAlign: 'center' }}>
        <Typography variant="h6">Error</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography textAlign="center" variant="body1" className="mb-2">
          {errorMsg}
        </Typography>
        {/* <Typography textAlign="center" className="mb-2">
          <BsPatchCheck fontSize={50} color="#a6ce39" />
        </Typography>
        <Typography variant="body1" textAlign="center" className="mb-2">
          Your Digital application have been succeefully processed and your Partner account has been
          opened successfully.
        </Typography>
        <Typography variant="body1" textAlign="center">
          We have emailed your Partner account User Id and Password on your registered email id
        </Typography>
        <Typography variant="body1" textAlign="center">
          <Link to="/">{emailAddr}</Link>
        </Typography> */}
        {/* <Typography variant="body1" className="mb-3" textAlign="center">
    You can Download our App by clicking below and log in with your details
    to proceed further.
  </Typography> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            setshowErrorPopUp(false);
          }}
          variant="contained"
          className="mainBtn"
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialogBox;
