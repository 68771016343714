import { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  TextareaAutosize,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { FiEye } from 'react-icons/fi';
import { BiCloudDownload } from 'react-icons/bi';
import { AiFillPrinter, AiFillDelete, AiFillEye } from 'react-icons/ai';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MdOutlineAdd } from 'react-icons/md';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import partnerService from '../services/partnerService';
import leadsService from '../services/leadsService';

const { BankList } = partnerService;
const {
  LeadsSubmissionMode,
  BankBranchList,
  AddBankBranch,
  ContactPersonList,
  AddContactPerson,
  SubmitApplication,
  ListSubmittedApplication
} = leadsService;

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));

function SubmitLeads() {
  // const [loanInterested, setLoanInterested] = useState(' ');
  // const [selectBank, setSelectBank] = useState(' ');
  const navigate = useNavigate();
  const [selectMode, setSelectMode] = useState(' ');
  const [selectModeList, setSelectModeList] = useState([]);
  const [bankListOpt, setBankListOpt] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [inputBankName, setInputBankName] = useState('');
  const [branches, setBranches] = useState([]);
  const [processCenter, setProcessCenter] = useState('');
  const [processCenterId, setProcessCenterId] = useState(null);
  const [inputProcessingCenter, setInputProcessingCenter] = useState('');
  const [addcontPerson, setAddContPerson] = useState('');
  const [inputContactPerson, setInputContactPerson] = useState('');
  const [bankcontactPersonId, setBankcontactPersonId] = useState(null);
  const [contactPerson, setContactPerson] = useState([]);
  const [summaryComment, setSummaryComment] = useState('');
  const [applicationList, setApplicationList] = useState([]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [validationMsg, setValidationMsg] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  // console.log('applicationList', applicationList.submittedApplications);
  // console.log('contactPerson', contactPerson);
  // console.log('processCenter', processCenter);
  // console.log('selectedBankId', selectedBankId);

  // Fill details API
  const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
  const token = localJWTtoken.token.jwt;
  const companyId = localJWTtoken.token.companyId;
  const leadId = JSON.parse(localStorage.getItem('leadId'));
  const applicationId = JSON.parse(localStorage.getItem('applicationId'));

  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    (async () => {
      // Fired Api of Bank name
      const ifPreviewDataBank = await BankList(token);
      if (ifPreviewDataBank.status === 'success') {
        setBankListOpt(ifPreviewDataBank.data.banks);
      } else if (ifPreviewDataBank.status === 'error') {
        localStorage.clear();
        navigate('/');
      }
      // Fired Api of Mode of submission
      const ifFiredLeadsSubmissionModeAPI = await LeadsSubmissionMode(token);
      if (ifFiredLeadsSubmissionModeAPI.status === 'success') {
        setSelectModeList(ifFiredLeadsSubmissionModeAPI.data.leadSubmissionModes);
      }
      // Fired Api of APPLICATION SUMMARY to show the list
      const credential = {
        leadId: leadId,
        applicationId: applicationId
      };
      const ifFiredListApplication = await ListSubmittedApplication(credential, token);
      // console.log('ifFiredListApplication', ListSubmittedApplication);
      if (ifFiredListApplication.status === 'success') {
        // console.log('ifFiredListApplication', ifFiredListApplication);
        setApplicationList(ifFiredListApplication.data.submittedApplications);
      }
    })();
  }, [refreshComp]);

  // Fired Api of Processing Center
  const handleProcessing = () => {
    (async () => {
      const credential = {
        bankId: selectedBankId.bankId,
        branchName: processCenter
      };
      const ifFiredAddBankBranch = await AddBankBranch(credential, token);
      // console.log('ifFiredAddBankBranch', ifFiredAddBankBranch);
      if (ifFiredAddBankBranch.status === 'success') {
        const ifFiredBankBranchList = await BankBranchList(
          {
            bankId: selectedBankId.bankId,
            pageSize: 100,
            pageNumber: 1,
            sortOrder: 'asc'
          },
          token
        );
        console.log('ifFiredBankBranchList', ifFiredBankBranchList);
        setBranches(ifFiredBankBranchList.data.branches);
        // setRefreshComp(new Date());
      }
    })();
  };

  // Fired Api of Branch contact person to Add person
  const handleAddBankPerson = () => {
    (async () => {
      const credential = {
        bankId: selectedBankId.bankId,
        contactPersonName: addcontPerson
      };
      const ifFiredAddContactPersonApi = await AddContactPerson(credential, token);
      // console.log('ifFiredAddContactPersonApi', ifFiredAddContactPersonApi);

      if (ifFiredAddContactPersonApi.status === 'success') {
        const credential = {
          bankId: selectedBankId.bankId,
          searchLiteral: '',
          pageSize: 100,
          pageNumber: 1,
          sortOrder: 'asc'
        };
        const ifFiredContactPersonListApi = await ContactPersonList(credential, token);
        // console.log('ifFiredContactPersonListApi', ifFiredContactPersonListApi);
        setContactPerson(ifFiredContactPersonListApi.data.contactPersons);
      }
    })();
  };

  // Fired Api to submit form of APPLICATION SUMMARY page
  const handleSubmitfrm = async () => {
    if (selectedBankId === null) {
      setDialogOpen(true);
      setValidationMsg('Please select bank name');
      return true;
    }
    if (selectMode === ' ') {
      setDialogOpen(true);
      setValidationMsg('Please select mode');
      return true;
    }
    if (processCenterId === null) {
      setDialogOpen(true);
      setValidationMsg('Please select processing center');
      return true;
    }
    if (bankcontactPersonId === null) {
      setDialogOpen(true);
      setValidationMsg('Please select bank contact person');
      return true;
    }
    if (summaryComment === '') {
      setDialogOpen(true);
      setValidationMsg('Please enter comments');
      return true;
    }

    const credential = {
      submittedToBankId: selectedBankId.bankId,
      leadId: leadId,
      submissionModeTypeId: selectMode,
      processingBankBranch: processCenterId.branchId,
      bankContactPersonId: bankcontactPersonId.contactId,
      submissionComments: summaryComment,
      applicationId: applicationId,
      companyId: companyId
    };
    const ifFiredSubmitApplicationApi = await SubmitApplication(credential, token);
    if (ifFiredSubmitApplicationApi.status === 'success') {
      setInputBankName('');
      setSelectedBankId(null);
      setSelectMode(' ');
      setInputProcessingCenter('');
      setProcessCenterId(null);
      setInputContactPerson('');
      setBankcontactPersonId(null);
      setSummaryComment('');
      setRefreshComp(new Date());
    }
  };

  return (
    <Page title="PULSE by Real Value Finloans || Leads">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Application Summary" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Box className="submitLeadToolbar">
              <Tooltip title="Application View" placement="top" arrow>
                <IconButton className="squareIconButton">
                  <FiEye size={18} />
                </IconButton>
              </Tooltip>
              &nbsp;&nbsp;&nbsp;
              <Tooltip title="Print" placement="top" arrow>
                <IconButton className="squareIconButton">
                  <AiFillPrinter size={18} />
                </IconButton>
              </Tooltip>
              &nbsp;&nbsp;&nbsp;
              <Tooltip title="Download" placement="top" arrow>
                <IconButton className="squareIconButton">
                  <BiCloudDownload size={18} />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Fill Details
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <Typography variant="h6" className="formLabel">
                      Bank Name
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo2"
                      options={bankListOpt}
                      size="small"
                      getOptionLabel={(option) => option.bankName}
                      renderOption={(props, option) => <li {...props}>{option.bankName}</li>}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth placeholder="Select Bank Name" />
                      )}
                      value={selectedBankId}
                      onChange={async (e, newValue) => {
                        console.log('newValue22', newValue);
                        setSelectedBankId(newValue);
                        // Fired API of Bank branch list
                        const ifFiredBankBranchList = await BankBranchList(
                          {
                            bankId: newValue.bankId,
                            pageSize: 100,
                            pageNumber: 1,
                            sortOrder: 'asc'
                          },
                          token
                        );
                        // console.log('ifFiredBankBranchList', ifFiredBankBranchList);
                        setBranches(ifFiredBankBranchList.data.branches);
                        // Fired API of Bank contact person
                        const credential = {
                          bankId: newValue.bankId,
                          searchLiteral: '',
                          pageSize: 100,
                          pageNumber: 1,
                          sortOrder: 'asc'
                        };
                        const ifFiredContactPersonListApi = await ContactPersonList(
                          credential,
                          token
                        );
                        // console.log('ifFiredContactPersonListApi', ifFiredContactPersonListApi);
                        setContactPerson(ifFiredContactPersonListApi.data.contactPersons);
                      }}
                      inputValue={inputBankName}
                      onInputChange={(_, v) => setInputBankName(v)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <Typography variant="h6" className="formLabel">
                      Mode of Submission
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${selectMode}`
                      }}
                      value={selectMode}
                      onChange={(e) => setSelectMode(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Mode
                      </MenuItem>
                      {selectModeList.map((item, index) => {
                        console.log();
                        return (
                          <MenuItem value={item.typeId} key={index}>
                            {item.typeName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Processing Center
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Autocomplete
                        sx={{ mr: 2 }}
                        disablePortal
                        fullWidth
                        id="combo-box-demo2"
                        size="small"
                        // options={topBanks}
                        options={branches}
                        getOptionLabel={(option) => option.branchName}
                        renderOption={(props, option) => <li {...props}>{option.branchName}</li>}
                        renderInput={(params) => (
                          <TextField
                            onChange={async (e) => {
                              setProcessCenter(e.target.value);
                            }}
                            {...params}
                            fullWidth
                            placeholder="Type Processing Center"
                          />
                        )}
                        value={processCenterId}
                        onChange={(e, newValue) => {
                          console.log('newValue3', newValue);
                          // setProcessCenterId(newValue.branchId);
                          setProcessCenterId(newValue);
                        }}
                        inputValue={inputProcessingCenter}
                        onInputChange={(_, v) => setInputProcessingCenter(v)}
                      />
                      <Tooltip title="Add" placement="top" arrow>
                        <IconButton
                          className="squareIconButton"
                          onClick={(e) => handleProcessing()}
                        >
                          <MdOutlineAdd size={18} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Bank Contact Person
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Autocomplete
                        sx={{ mr: 2 }}
                        disablePortal
                        fullWidth
                        id="combo-box-demo2"
                        options={contactPerson}
                        size="small"
                        getOptionLabel={(option) => option.contactPersonName}
                        renderOption={(props, option) => (
                          <li {...props}>{option.contactPersonName}</li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            onChange={(e) => setAddContPerson(e.target.value)}
                            {...params}
                            fullWidth
                            placeholder="Type Bank Contact Person"
                          />
                        )}
                        value={bankcontactPersonId}
                        onChange={(e, newValue) => {
                          // setBankcontactPersonId(newValue.contactId);
                          setBankcontactPersonId(newValue);
                        }}
                        inputValue={inputContactPerson}
                        onInputChange={(_, v) => setInputContactPerson(v)}
                      />
                      <Tooltip title="Add" placement="top" arrow>
                        <IconButton
                          className="squareIconButton"
                          onClick={(e) => handleAddBankPerson()}
                        >
                          <MdOutlineAdd size={18} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Comments
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Comments"
                      multiline
                      rows={4}
                      value={summaryComment}
                      onChange={(e) => setSummaryComment(e.target.value)}
                    />
                    {/* <TextareaAutosize
                      minRows={5}
                      placeholder="Write Your Comments"
                      className="textInput textareaBox"
                    /> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Scrollbar>
          <Box className="" textAlign="right" mb={3}>
            <Button variant="contained" className="mainBtn" onClick={(e) => handleSubmitfrm()}>
              Submit
            </Button>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Summary
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin viewCardPadding">
            <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Bank Name</StyledTableCell>
                    <StyledTableCell align="">Mode of Submission</StyledTableCell>
                    <StyledTableCell align="">Processing Center</StyledTableCell>
                    <StyledTableCell align="">Contact Person Name</StyledTableCell>
                    <StyledTableCell align="">Comments</StyledTableCell>
                    <StyledTableCell align="">Submited On</StyledTableCell>
                    <StyledTableCell align="">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicationList.map((item, index) => {
                    console.log();
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{item.bankEntity.bankName}</StyledTableCell>
                        <StyledTableCell>{item.submissionMode.typeName}</StyledTableCell>
                        <StyledTableCell>{item.branch.branchName}</StyledTableCell>
                        <StyledTableCell>{item.contactPerson.contactPersonName}</StyledTableCell>
                        <StyledTableCell>{item.submissionComments}</StyledTableCell>
                        <StyledTableCell>{item.submissionDate}</StyledTableCell>
                        <StyledTableCell>
                          <Tooltip title="Delete" placement="top" arrow>
                            <StyledIconButton className="squareIconButton actionButton">
                              <AiFillDelete size={14} />
                            </StyledIconButton>
                          </Tooltip>
                          <Tooltip title="View" placement="top" arrow>
                            <StyledIconButton className="squareIconButton actionButton">
                              <AiFillEye size={14} />
                            </StyledIconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                  {/* <StyledTableRow>
                    <StyledTableCell align="">HDFC</StyledTableCell>
                    <StyledTableCell align="">Email</StyledTableCell>
                    <StyledTableCell align="">Delhi</StyledTableCell>
                    <StyledTableCell align="">Hemant</StyledTableCell>
                    <StyledTableCell align="">Not Contacted</StyledTableCell>
                    <StyledTableCell align="">27 April, 2022</StyledTableCell>
                    <StyledTableCell align="">
                      <Tooltip title="Delete" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillDelete size={14} />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="View" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillEye size={14} />
                        </StyledIconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow> */}
                  {/* <StyledTableRow>
                    <StyledTableCell align="">HDFC</StyledTableCell>
                    <StyledTableCell align="">Email</StyledTableCell>
                    <StyledTableCell align="">Delhi</StyledTableCell>
                    <StyledTableCell align="">Hemant</StyledTableCell>
                    <StyledTableCell align="">Not Contacted</StyledTableCell>
                    <StyledTableCell align="">27 April, 2022</StyledTableCell>
                    <StyledTableCell align="">
                      <Tooltip title="Delete" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillDelete size={14} />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="View" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillEye size={14} />
                        </StyledIconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="">HDFC</StyledTableCell>
                    <StyledTableCell align="">Email</StyledTableCell>
                    <StyledTableCell align="">Delhi</StyledTableCell>
                    <StyledTableCell align="">Hemant</StyledTableCell>
                    <StyledTableCell align="">Not Contacted</StyledTableCell>
                    <StyledTableCell align="">27 April, 2022</StyledTableCell>
                    <StyledTableCell align="" width="80">
                      <Tooltip title="Delete" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillDelete size={14} />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="View" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillEye size={14} />
                        </StyledIconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Retry</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}
export default SubmitLeads;

// Top Banks
// const topBanks = [
//   { label: 'Axis Bank' },
//   { label: 'HDFC Bank' },
//   { label: 'HDFC Ltd.' },
//   { label: 'ICICI Bank' },
//   { label: 'PNB Housing' },
//   { label: 'Piramal Housing' },
//   { label: 'Standard Chartered Bank' },
//   { label: 'Yes Bank' },
//   { label: 'Aditya Birla Housing Finance' },
//   { label: 'IDFC Bank' },
//   { label: 'RBL Bank' },
//   { label: 'Federal Bank' },
//   { label: 'Fedfina' },
//   { label: 'HDB Financial' },
//   { label: 'IIFL Finance' },
//   { label: 'Tata Capital' },
//   { label: 'ICICI Home Finance' },
//   { label: 'Cholamandalam Finance' },
//   { label: 'L&T Finance' },
//   { label: 'Karur Vysya Bank' },
//   { label: 'Hero Housing Finance' },
//   { label: 'Kotak Mahindra Bank' },
//   { label: 'Fullerton Grihashakti' },
//   { label: 'Anand Rathi Financial' },
//   { label: 'Aadhar Housing Finance' },
//   { label: 'Godrej Housing Finance' },
//   { label: 'Deutsche Bank' },
//   { label: 'Jana Small Finance Bank' },
//   { label: 'HSBC Bank' },
//   { label: 'DCB Bank' },
//   { label: 'Bank of Baroda' },
//   { label: 'Bajaj Housing Finance' },
//   { label: 'LIC Housing Finance' },
//   { label: 'AU Small Finance Bank' },
//   { label: 'Union Bank of India' },
//   { label: 'State Bank of India' }
// ];
