import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  Paper,
  Checkbox
} from '@mui/material';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function AddEditUser() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [showHide, setShowHide] = useState('1');
  const handleShowHide = (event) => {
    const getUserType = event.target.value;
    setShowHide(getUserType);
  };
  const [userType, setUserType] = useState('1');
  const [selectBranch, setSelectBranch] = useState(' ');
  const [selectDepartment, setSelectDepartment] = useState(' ');
  const [selectDepartmentLevel, setSelectDepartmentLevel] = useState(' ');
  const [selectReportingManager, setSelectReportingManager] = useState(' ');
  const [selectAccess, setSelectAccess] = useState(' ');
  const [selectUserOrganization, setSelectUserOrganization] = useState(' ');
  const [selectInternalSalesManager, setSelectInternalSalesManager] = useState(' ');

  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };
  return (
    <Page title="PULSE by Real Value Finloans || User">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info={rowId ? 'Edit User' : 'Add New User'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="" mb={3}>
                <Typography variant="h6" className="formLabel">
                  User Type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  value={userType}
                  onChange={(e) => {
                    handleShowHide(e);
                    setUserType(e.target.value);
                  }}
                >
                  {/* <MenuItem disabled="disabled" value="0">
                    User Type
                  </MenuItem> */}
                  <MenuItem value="1">Internal</MenuItem>
                  <MenuItem value="2">External</MenuItem>
                </Select>
              </Box>
            </Grid>
          </Grid>
          <Box className="">
            {showHide === '1' && (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  General
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          User Name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type User Name"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Mobile
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Mobile Number"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Email
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Email"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Branch
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectBranch}`
                          }}
                          value={selectBranch}
                          onChange={(e) => setSelectBranch(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Branch
                          </MenuItem>
                          <MenuItem value="Mumbai">Mumbai</MenuItem>
                          <MenuItem value="Pune">Pune</MenuItem>
                          <MenuItem value="Nagpur">Nagpur</MenuItem>
                          <MenuItem value="Ahmedabad">Ahmedabad</MenuItem>
                          <MenuItem value="Baroda">Baroda</MenuItem>
                          <MenuItem value="Surat">Surat</MenuItem>
                          <MenuItem value="Jaipur">Jaipur</MenuItem>
                          <MenuItem value="Delhi NCR">Delhi NCR</MenuItem>
                          <MenuItem value="Bengaluru">Bengaluru</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Department
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectDepartment}`
                          }}
                          value={selectDepartment}
                          onChange={(e) => setSelectDepartment(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Department
                          </MenuItem>
                          <MenuItem value="Loan">Loan</MenuItem>
                          <MenuItem value="Credit">Credit</MenuItem>
                          <MenuItem value="Field">Field</MenuItem>
                          <MenuItem value="Legal">Legal</MenuItem>
                          <MenuItem value="Valuation">Valuation</MenuItem>
                          <MenuItem value="Sales">Sales</MenuItem>
                          <MenuItem value="Branch">Branch</MenuItem>
                          <MenuItem value="Data Entry">Data Entry</MenuItem>
                          <MenuItem value="Fraud">Fraud</MenuItem>
                          <MenuItem value="KYC">KYC</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Department Level
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectDepartmentLevel}`
                          }}
                          value={selectDepartmentLevel}
                          onChange={(e) => setSelectDepartmentLevel(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Department Level
                          </MenuItem>
                          <MenuItem value="L1"> L1</MenuItem>
                          <MenuItem value="L2"> L2</MenuItem>
                          <MenuItem value="L3"> L3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="h6" className="formLabel">
                          Reporting Manager
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectReportingManager}`
                          }}
                          value={selectReportingManager}
                          onChange={(e) => setSelectReportingManager(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Reporting Manager
                          </MenuItem>
                          <MenuItem value="Manager1">Manager1</MenuItem>
                          <MenuItem value="Manager2">Manager2</MenuItem>
                          <MenuItem value="Manager3">Manager3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Access
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectAccess}`
                          }}
                          value={selectAccess}
                          onChange={(e) => setSelectAccess(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Access
                          </MenuItem>
                          <MenuItem value="Menu 1"> Menu 1</MenuItem>
                          <MenuItem value="Menu 2"> Menu 2</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            {showHide === '2' && (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  General
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="h6" className="formLabel">
                          User Organization
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectUserOrganization}`
                          }}
                          value={selectUserOrganization}
                          onChange={(e) => setSelectUserOrganization(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select User Organization
                          </MenuItem>
                          <MenuItem value="Organization1">Organization1</MenuItem>
                          <MenuItem value="Organization2">Organization2</MenuItem>
                          <MenuItem value="Organization3">Organization3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          User Name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type User Name"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Mobile Number
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Mobile Number"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Email
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Email"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="h6" className="formLabel">
                          Reporting Manager
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectReportingManager}`
                          }}
                          value={selectReportingManager}
                          onChange={(e) => setSelectReportingManager(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Reporting Manager
                          </MenuItem>
                          <MenuItem value="Manager1">Manager1</MenuItem>
                          <MenuItem value="Manager2">Manager2</MenuItem>
                          <MenuItem value="Manager3">Manager3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="h6" className="formLabel">
                          Internal Sales Manager
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectInternalSalesManager}`
                          }}
                          value={selectInternalSalesManager}
                          onChange={(e) => setSelectInternalSalesManager(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Internal Sales Manager
                          </MenuItem>
                          <MenuItem value="Manager1">Manager1</MenuItem>
                          <MenuItem value="Manager2">Manager2</MenuItem>
                          <MenuItem value="Manager3">Manager3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Access
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectAccess}`
                          }}
                          value={selectAccess}
                          onChange={(e) => setSelectAccess(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Access
                          </MenuItem>
                          <MenuItem value="Menu 1"> Menu 1</MenuItem>
                          <MenuItem value="Menu 2"> Menu 2</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            <Box className="" textAlign="right">
              <Button variant="contained" className="mainBtn">
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
export default AddEditUser;
