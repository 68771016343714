import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Divider
} from '@mui/material';
import { Box } from '@mui/system';
import { Document, pdfjs, Page as Pages } from 'react-pdf';
import CircularProgress from '@mui/material/CircularProgress';
// other icons
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
// react icons
import { GoChecklist } from 'react-icons/go';
import { VscCheckAll } from 'react-icons/vsc';
import { FcApproval } from 'react-icons/fc';
import { FaTwitterSquare, FaFacebookSquare, FaRegAddressCard, FaRegUser } from 'react-icons/fa';
import {
  MdRemoveDone,
  MdSpellcheck,
  MdIncompleteCircle,
  MdOutlineDateRange,
  MdCheckBox,
  MdThumbDown,
  MdModeEdit
} from 'react-icons/md';
import { BsWindowDock, BsCardHeading, BsTelephone } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineIdcard } from 'react-icons/ai';
import { RiBankCardLine } from 'react-icons/ri';
import { CgCalendarDates } from 'react-icons/cg';
import { HiOutlineMail } from 'react-icons/hi';
import { SiOpensourceinitiative } from 'react-icons/si';
import { GiSwapBag, GiModernCity } from 'react-icons/gi';
import { BiCloudUpload, BiCloudDownload, BiBoltCircle } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
// material icons
import DeckIcon from '@mui/icons-material/Deck';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import VillaIcon from '@mui/icons-material/Villa';
// components
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import partnerService from '../services/partnerService';

const { OnBoardReview, PartnerStatusTypes, UpdatePartnerStatus } = partnerService;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ReviewOnBoarding() {
  const navigate = useNavigate();
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');

  const [reviewData, setReviewData] = useState(null);
  const [partnerStatusTypes, setPartnerStatusTypes] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // setting 1 to show fisrt page

  // State for FileViewer
  const [imageState, setImageState] = useState();
  const [imageDocType, setImageDocType] = useState('jpg');
  const [docType, setDocType] = useState('jpg');

  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  // const fireUpdateStateApi = async (e, typeid, code) => {
  //   console.log('idddddd', typeid);
  //   console.log('idddddd', code);
  //   const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
  //   const partnerId = JSON.parse(localStorage.getItem('partnerId'));
  //   const token = localJWTtoken.token.jwt;
  //   const statusCredential = {
  //     companyId: localJWTtoken.token.companyId,
  //     partnerId: partnerId,
  //     partnerStatusTypeId: typeid
  //   };
  //   const UpdatePartnerStatusResponse = await UpdatePartnerStatus(statusCredential, token);
  //   if (UpdatePartnerStatusResponse.status === 'success') {
  //     navigate('/onboarding');
  //   }
  // };

  const fireUpdateStateApi = async (e, typeid, code) => {
    console.log('idddddd', typeid);
    console.log('idddddd', code);
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const partnerId = JSON.parse(localStorage.getItem('partnerId'));
    const token = localJWTtoken.token.jwt;
    const statusCredential = {
      companyId: localJWTtoken.token.companyId,
      partnerId: partnerId,
      partnerStatusTypeId: typeid
    };
    const UpdatePartnerStatusResponse = await UpdatePartnerStatus(statusCredential, token);
    if (UpdatePartnerStatusResponse.status === 'success') {
      navigate('/onboarding');
      // console.log('UpdatePartnerStatusResponse', UpdatePartnerStatusResponse.msg);
    }
  };

  useEffect(() => {
    (async () => {
      const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
      const partnerId = JSON.parse(localStorage.getItem('partnerId'));
      const credential = {
        companyId: localJWTtoken.token.companyId,
        partnerId: partnerId
      };
      const token = localJWTtoken.token.jwt;
      const ifPrevieDataAvailable = await OnBoardReview(credential, token);
      console.log('ifPrevieDataAvailabled', ifPrevieDataAvailable);
      if (ifPrevieDataAvailable.status === 'success') {
        setReviewData(ifPrevieDataAvailable.data);
      }
    })();
    (async () => {
      const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
      const partnerId = JSON.parse(localStorage.getItem('partnerId'));
      const token = localJWTtoken.token.jwt;
      const PartnerStatusTypesApi = await PartnerStatusTypes(token);
      console.log('PartnerStatusTypes', PartnerStatusTypesApi);
      if (PartnerStatusTypesApi.status === 'success') {
        setPartnerStatusTypes(PartnerStatusTypesApi.data);
      }
    })();
  }, []);

  return (
    <Page title="PULSE by Real Value Finloans || On Boarding">
      <Container maxWidth="xl">
        {reviewData !== null ? (
          <Box pt={3} pb={3} mb={4}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="pageHeading"
            >
              <PageTitle info="Review" />
              <Box>
                <BrudCrumbs />
              </Box>
            </Stack>
            <Box className="submitLeadToolbar" mb={1}>
              {partnerStatusTypes.partnerStatusTypes !== undefined &&
              partnerStatusTypes.partnerStatusTypes.length > 0
                ? partnerStatusTypes.partnerStatusTypes.map((status, index) => {
                    // console.log('myyyyyyyyyyyyyyyyyyyyyyy', reviewData.partnerDocuments[index]);
                    if (status.typeCode === 'APPROVED') {
                      return (
                        <>
                          <Tooltip key={index} title={status.typeName} placement="top" arrow>
                            <IconButton
                              className="squareIconButton"
                              onClick={(e) => fireUpdateStateApi(e, status.typeId, status.typeName)}
                            >
                              {status.typeName === 'Approved' && <MdCheckBox size={18} />}
                            </IconButton>
                          </Tooltip>
                          {/*                           
                          <Tooltip title="Activate" placement="top" arrow>
                            <StyledIconButton
                              classes={{
                                disabled: 'disableButton'
                              }}
                              className="squareIconButton actionButton"
                              onClick={(e) => {
                                UpdatePartnerStatusApi(rowId, partner);
                              }}
                              disabled={
                                partnerState === null ||
                                partner.partnerStatus.typeName === 'Rejected' ||
                                partner.partnerStatus.typeName === 'Inactive'
                                  ? true
                                  : false
                              }
                            >
                              <AiOutlineCheck size={14} />
                            </StyledIconButton>
                          </Tooltip> */}
                          &nbsp;&nbsp;&nbsp;
                        </>
                      );
                    }
                    if (status.typeCode === 'REJECTED') {
                      return (
                        <>
                          <Tooltip key={index} title={status.typeName} placement="top" arrow>
                            <IconButton
                              className="squareIconButton"
                              onClick={(e) => fireUpdateStateApi(e, status.typeId, status.typeName)}
                            >
                              {status.typeName === 'Rejected' && <MdThumbDown size={18} />}
                            </IconButton>
                          </Tooltip>
                          {/*                           
                          <Tooltip title="Activate" placement="top" arrow>
                            <StyledIconButton
                              classes={{
                                disabled: 'disableButton'
                              }}
                              className="squareIconButton actionButton"
                              onClick={(e) => {
                                UpdatePartnerStatusApi(rowId, partner);
                              }}
                              disabled={
                                partnerState === null ||
                                partner.partnerStatus.typeName === 'Rejected' ||
                                partner.partnerStatus.typeName === 'Inactive'
                                  ? true
                                  : false
                              }
                            >
                              <AiOutlineCheck size={14} />
                            </StyledIconButton>
                          </Tooltip> */}
                          &nbsp;&nbsp;&nbsp;
                        </>
                      );
                    }
                    if (status.typeCode === 'ACTIVATED') {
                      return (
                        <>
                          <Tooltip key={index} title={status.typeName} placement="top" arrow>
                            <IconButton
                              className="squareIconButton"
                              onClick={(e) => fireUpdateStateApi(e, status.typeId, status.typeName)}
                              disabled={
                                !(
                                  reviewData.partnerStatus.typeCode === 'ACTIVATED' ||
                                  reviewData.partnerStatus.typeCode === 'APPROVED'
                                )
                              }
                            >
                              {status.typeName === 'Activated' && <MdSpellcheck size={18} />}
                            </IconButton>
                          </Tooltip>
                          {/*                           
                          <Tooltip title="Activate" placement="top" arrow>
                            <StyledIconButton
                              classes={{
                                disabled: 'disableButton'
                              }}
                              className="squareIconButton actionButton"
                              onClick={(e) => {
                                UpdatePartnerStatusApi(rowId, partner);
                              }}
                              disabled={
                                partnerState === null ||
                                partner.partnerStatus.typeName === 'Rejected' ||
                                partner.partnerStatus.typeName === 'Inactive'
                                  ? true
                                  : false
                              }
                            >
                              <AiOutlineCheck size={14} />
                            </StyledIconButton>
                          </Tooltip> */}
                          &nbsp;&nbsp;&nbsp;
                        </>
                      );
                    }
                    return true;
                  })
                : ''}
            </Box>

            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Partner Details
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs="auto">
                  {/* <FileViewer fileType="jpg" filePath="../../../static/dummy/photoDummy.jpg" /> */}

                  {/* <FileViewer
                    fileType="jpg"
                    filePath={
                      reviewData.partnerDocuments.filter(
                        (doc) =>
                          doc.documentType.typeCode.toLowerCase() === 'USER IMAGE'.toLowerCase()
                      ).length > 0
                        ? reviewData.partnerDocuments
                            .filter(
                              (doc) =>
                                doc.documentType.typeCode.toLowerCase() ===
                                'USER IMAGE'.toLowerCase()
                            )
                            .map(
                              (url, index) =>
                                url.contentType ? url.contentType.split('/')[1] : 'jpg'
                              // url.documentUrl === null
                              // ? '../../../static/dummy/photoDummy.jpg'
                              // : url.documentUrl
                            )
                        : 'jpg'
                    }
                  /> */}

                  {reviewData.partnerDocuments.filter(
                    (doc) => doc.documentType.typeCode.toLowerCase() === 'USER IMAGE'.toLowerCase()
                  ).length > 0 ||
                  reviewData.partnerDocuments.filter(
                    (doc) =>
                      doc.documentType.typeCode.toLowerCase() === 'COMPANY_LOGO'.toLowerCase()
                  ).length > 0
                    ? reviewData.partnerDocuments
                        .filter(
                          (doc) =>
                            doc.documentType.typeCode.toLowerCase() ===
                              'USER IMAGE'.toLowerCase() ||
                            doc.documentType.typeCode.toLowerCase() === 'COMPANY_LOGO'.toLowerCase()
                        )
                        .map((url, index) =>
                          url.contentType === 'application/pdf' ? (
                            <Document
                              className="pickBox"
                              file={url.documentUrl}
                              onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                            >
                              <Pages pageNumber={pageNumber} />
                            </Document>
                          ) : (
                            <img
                              src={
                                reviewData.partnerDocuments.filter(
                                  (doc) =>
                                    doc.documentType.typeCode.toLowerCase() ===
                                    'USER IMAGE'.toLowerCase()
                                ).length > 0 ||
                                reviewData.partnerDocuments.filter(
                                  (doc) =>
                                    doc.documentType.typeCode.toLowerCase() ===
                                    'COMPANY_LOGO'.toLowerCase()
                                ).length > 0
                                  ? reviewData.partnerDocuments
                                      .filter(
                                        (doc) =>
                                          doc.documentType.typeCode.toLowerCase() ===
                                            'USER IMAGE'.toLowerCase() ||
                                          doc.documentType.typeCode.toLowerCase() ===
                                            'COMPANY_LOGO'.toLowerCase()
                                      )
                                      .map((url, index) =>
                                        url.documentUrl === null
                                          ? '../../../static/dummy/photoDummy.jpg'
                                          : url.documentUrl
                                      )
                                  : '../../../static/dummy/photoDummy.jpg'
                              }
                              alt="profile"
                              className="profileImg"
                            />
                          )
                        )
                    : ''}
                </Grid>
                <Grid item xs>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="displayFlex">
                        <DeckIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Partner Type
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {reviewData.partnerType.typeCode
                              ? reviewData.partnerType.typeCode
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="displayFlex">
                        <GroupIcon className="viewLabelIcon" size={24} />
                        <Box>
                          {reviewData.typeId === 66 ? (
                            <>
                              <Typography variant="body2" className="labelText">
                                Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {reviewData.partnerName ? reviewData.partnerName : 'N/A'}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="body2" className="labelText">
                                Company Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {reviewData.partnerName ? reviewData.partnerName : 'N/A'}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="displayFlex">
                        <PersonIcon className="viewLabelIcon" size={24} />
                        <Box>
                          {reviewData.typeId === 66 ? (
                            <>
                              <Typography variant="body2" className="labelText">
                                Father's Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {reviewData.fathersName ? reviewData.fathersName : 'N/A'}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="body2" className="labelText">
                                Authorised Person Name
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {reviewData.authorisedPersonName
                                  ? reviewData.authorisedPersonName
                                  : 'N/A'}
                              </Typography>
                            </>
                          )}
                          {/* <Typography variant="body2" className="labelText">
                            Father's Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {reviewData.fathersName ? reviewData.fathersName : 'N/A'}
                          </Typography> */}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="displayFlex">
                        <CalendarMonthIcon className="viewLabelIcon" size={24} />
                        <Box>
                          {reviewData.typeId === 66 ? (
                            <>
                              <Typography variant="body2" className="labelText">
                                DOB
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {reviewData.dob ? reviewData.dob : 'N/A'}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="body2" className="labelText">
                                Date of Incorporation
                              </Typography>
                              <Typography variant="subtitle2" className="labelValue">
                                {reviewData.dob ? reviewData.dob : 'N/A'}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="displayFlex">
                        <BsTelephone className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Phone
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {reviewData.mobile ? reviewData.mobile : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="displayFlex">
                        <EmergencyShareIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Address
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {reviewData.address ? reviewData.address : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="displayFlex">
                        <GiModernCity className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            City
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {reviewData.cityEntity !== null
                              ? reviewData.cityEntity.cityName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="displayFlex">
                        <VillaIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            State
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {reviewData.stateEntity !== null
                              ? reviewData.stateEntity.stateName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="displayFlex">
                        <VillaIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Email Id
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {reviewData.emailAddress !== null ? reviewData.emailAddress : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Document Verfication
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin">
              {reviewData.partnerDocuments.map((documentElements, documentElementsIndex) => {
                console.log('documentTypecccccccccccccc', documentElements);
                return (
                  <fragment key={documentElementsIndex}>
                    {documentElements.documentType.typeCode === 'AADHAR CARD' ? (
                      <>
                        {documentElements.aadharFrontFace !== null &&
                        documentElements.aadharFrontFace === true ? (
                          <Grid container spacing={3} mb={3}>
                            <Grid item xs="12" sm="4" md="3">
                              <Typography
                                variant="body2"
                                className="displayFlex alignItemsCenter justifyContentBetween mb-1"
                              >
                                <strong>Aadhar Card Front Image</strong>
                                <FcApproval size={20} className="ml-1" />
                              </Typography>
                              <Box
                                className={
                                  documentElements.contentType === 'application/pdf'
                                    ? `pdfBoxreview documentImgBox`
                                    : `documentImgBox`
                                }
                              >
                                {documentElements.contentType === 'application/pdf' ? (
                                  <Document
                                    file={documentElements.documentUrl}
                                    onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                  >
                                    <Pages pageNumber={pageNumber} />
                                  </Document>
                                ) : (
                                  <img
                                    src={documentElements.documentUrl}
                                    alt={documentElements.documentType.typeCode}
                                  />
                                )}

                                {/* {documentElements.documentUrl ? (
                                  <img
                                    className="documentImg"
                                    src={documentElements.documentUrl}
                                    alt={documentElements.documentType.typeCode}
                                  />
                                ) : (
                                  ''
                                )} */}
                              </Box>
                            </Grid>
                            <Grid item xs="12" sm="8" md="9">
                              <Typography variant="body2" className="mb-1">
                                <strong>Aadhar Card Front Details</strong>
                              </Typography>
                              <Box className="displayTable w-100">
                                <Box className="displayTableRow">
                                  <Typography
                                    variant="body2"
                                    className="displayTableCell pb-05 w-30"
                                  >
                                    Name
                                  </Typography>
                                  <Typography
                                    variant=""
                                    className="displayTableCell pr-05 pl-05 pb-05"
                                  >
                                    :
                                  </Typography>
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    <strong>
                                      {documentElements.documentOcrJson === null
                                        ? 'N/A'
                                        : documentElements.documentOcrJson.name}
                                    </strong>
                                  </Typography>
                                </Box>
                                <Box className="displayTableRow">
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    Gender
                                  </Typography>
                                  <Typography
                                    variant=""
                                    className="displayTableCell pr-05 pl-05 pb-05"
                                  >
                                    :
                                  </Typography>
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    <strong>
                                      {documentElements.documentOcrJson === null ||
                                      Object.keys(documentElements.documentOcrJson).length === 0
                                        ? 'N/A'
                                        : documentElements.documentOcrJson.gender
                                        ? documentElements.documentOcrJson.gender
                                            .charAt(0)
                                            .toUpperCase() +
                                          documentElements.documentOcrJson.gender.slice(1)
                                        : 'N/A'}
                                    </strong>
                                  </Typography>
                                </Box>
                                <Box className="displayTableRow">
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    Fathers/Spouse Name
                                  </Typography>
                                  <Typography
                                    variant=""
                                    className="displayTableCell pr-05 pl-05 pb-05"
                                  >
                                    :
                                  </Typography>
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    <strong>
                                      {documentElements.documentOcrJson === null
                                        ? 'N/A'
                                        : documentElements.documentOcrJson.father_name
                                        ? documentElements.documentOcrJson.father_name
                                        : documentElements.documentOcrJson.husband_name
                                        ? documentElements.documentOcrJson.husband_name
                                        : 'N/A'}
                                    </strong>
                                  </Typography>
                                </Box>
                                <Box className="displayTableRow">
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    DOB/YEAR
                                  </Typography>
                                  <Typography
                                    variant=""
                                    className="displayTableCell pr-05 pl-05 pb-05"
                                  >
                                    :
                                  </Typography>
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    <strong>
                                      {documentElements.documentOcrJson === null
                                        ? 'N/A'
                                        : documentElements.documentOcrJson.date_of_birth}
                                    </strong>
                                  </Typography>
                                </Box>
                                <Box className="displayTableRow">
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    Aadhar No
                                  </Typography>
                                  <Typography
                                    variant=""
                                    className="displayTableCell pr-05 pl-05 pb-05"
                                  >
                                    :
                                  </Typography>
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    <strong>
                                      {documentElements.documentOcrJson === null
                                        ? 'N/A'
                                        : documentElements.documentOcrJson.aadhaar_number}
                                    </strong>
                                  </Typography>
                                </Box>
                                <Box className="displayTableRow">
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    Authenticity Check
                                  </Typography>
                                  <Typography
                                    variant=""
                                    className="displayTableCell pr-05 pl-05 pb-05"
                                  >
                                    :
                                  </Typography>
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    <strong>NA</strong>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : documentElements.aadharFrontFace !== null &&
                          documentElements.aadharFrontFace === false ? (
                          <Grid container spacing={3} mb={3}>
                            <Grid item xs="12" sm="4" md="3">
                              <Typography
                                variant="body2"
                                className="displayFlex alignItemsCenter justifyContentBetween mb-1"
                              >
                                <strong>Aadhar Card Back Image</strong>
                                <FcApproval size={20} className="ml-1" />
                              </Typography>
                              <Box
                                className={
                                  documentElements.contentType === 'application/pdf'
                                    ? `pdfBoxreview documentImgBox`
                                    : `documentImgBox`
                                }
                              >
                                {documentElements.contentType === 'application/pdf' ? (
                                  <Document
                                    file={documentElements.documentUrl}
                                    onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                  >
                                    <Pages pageNumber={pageNumber} />
                                  </Document>
                                ) : (
                                  <img
                                    src={documentElements.documentUrl}
                                    alt={documentElements.documentType.typeCode}
                                  />
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs="12" sm="8" md="9">
                              <Typography variant="body2" className="mb-1">
                                <strong>Aadhar Card Back Details</strong>
                              </Typography>
                              <Box className="displayTable w-100">
                                <Box className="displayTableRow">
                                  <Typography
                                    variant="body2"
                                    className="displayTableCell pb-05 w-30"
                                  >
                                    Address
                                  </Typography>
                                  <Typography
                                    variant=""
                                    className="displayTableCell pr-05 pl-05 pb-05"
                                  >
                                    :
                                  </Typography>
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    <strong>
                                      {documentElements.documentOcrJson === null ? (
                                        'N/A'
                                      ) : documentElements.documentOcrJson.address ? (
                                        documentElements.documentOcrJson.address
                                      ) : (
                                        <>
                                          <span>{documentElements.documentOcrJson.street}</span>,
                                          &nbsp;
                                          <span>
                                            {documentElements.documentOcrJson['city/district']}
                                          </span>
                                          , &nbsp;
                                          <span>{documentElements.documentOcrJson.state}</span>,
                                          &nbsp;
                                          <span>{documentElements.documentOcrJson.country}</span>
                                        </>
                                      )}
                                    </strong>
                                  </Typography>
                                </Box>
                                <Box className="displayTableRow">
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    Aadhar No
                                  </Typography>
                                  <Typography
                                    variant=""
                                    className="displayTableCell pr-05 pl-05 pb-05"
                                  >
                                    :
                                  </Typography>
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    <strong>
                                      {documentElements.documentOcrJson === null
                                        ? 'N/A'
                                        : documentElements.documentOcrJson.aadhaar_number}
                                    </strong>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : (
                          ''
                        )}
                        {}
                      </>
                    ) : documentElements.documentType.typeCode === 'PAN CARD' ? (
                      <Grid container spacing={3} mb={3}>
                        <Grid item xs="12" sm="4" md="3">
                          <Typography
                            variant="body2"
                            className="displayFlex alignItemsCenter justifyContentBetween mb-1"
                          >
                            <strong>Pan Card Image</strong>
                            <FcApproval size={20} className="ml-1" />
                          </Typography>
                          <Box
                            className={
                              documentElements.contentType === 'application/pdf'
                                ? `pdfBoxreview documentImgBox`
                                : `documentImgBox`
                            }
                          >
                            {/* <img
                              className="documentImg"
                              src={
                                documentElements.documentUrl === null
                                  ? '../../static/review/dummy-pan-card.jpg'
                                  : documentElements.documentUrl
                              }
                              alt="Pan Card"
                            /> */}
                            {/* {documentElements.documentUrl ? (
                              <img
                                className="documentImg"
                                src={documentElements.documentUrl}
                                alt="AAdhar Card"
                              />
                            ) : (
                              ''
                            )} */}
                            {documentElements.contentType === 'application/pdf' ? (
                              <Document
                                file={documentElements.documentUrl}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={documentElements.documentUrl}
                                alt={documentElements.documentType.typeCode}
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs="12" sm="8" md="9">
                          <Typography variant="body2" className="mb-1">
                            <strong>Pan Card Details</strong>
                          </Typography>
                          <Box className="displayTable w-100">
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                Name
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.name}
                                </strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Fathers/Spouse Name
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.father_name}
                                </strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                DOB
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.date_of_birth}
                                </strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Pan No
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.pan_card_number}
                                </strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Authenticity Check
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>NA</strong>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : documentElements.documentType.typeCode === 'CHEQUE' ? (
                      <Grid container spacing={3} mb={3}>
                        <Grid item xs="12" sm="4" md="3">
                          <Typography variant="body2" className="mb-1">
                            <strong>Cancelled Cheque Image</strong>
                          </Typography>
                          <Box
                            className={
                              documentElements.contentType === 'application/pdf'
                                ? `pdfBoxreview documentImgBox`
                                : `documentImgBox`
                            }
                          >
                            {/* <img
                              className="documentImg"
                              src={
                                documentElements.documentUrl === null
                                  ? '../../static/review/cancel-cheque.png'
                                  : documentElements.documentUrl
                              }
                              alt="Cancel Check"
                            /> */}
                            {/* {documentElements.documentUrl ? (
                              <img
                                className="documentImg"
                                src={documentElements.documentUrl}
                                alt={documentElements.documentType.typeName}
                              />
                            ) : (
                              ''
                            )} */}

                            {documentElements.contentType === 'application/pdf' ? (
                              <Document
                                file={documentElements.documentUrl}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={documentElements.documentUrl}
                                alt={documentElements.documentType.typeCode}
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs="12" sm="8" md="9">
                          <Typography variant="body2" className="mb-1">
                            <strong>Cancelled Cheque Details</strong>
                          </Typography>
                          <Box className="displayTable w-100">
                            {/* <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                Issuer Name
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.issuer_name}
                                </strong>
                              </Typography>
                            </Box> */}
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                Bank Name
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.bank_name}
                                </strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                RTGS/NEFT IFSC
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.IFS_Code}
                                </strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Account Number
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.account_number}
                                </strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Customer ID
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>NA</strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Bank Address
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.branch_address}
                                </strong>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : documentElements.documentType.typeCode === 'GSTIN' ? (
                      <Grid container spacing={3} mb={3}>
                        <Grid item xs="12" sm="4" md="3">
                          <Typography variant="body2" className="mb-1">
                            <strong>GST Certificate</strong>
                          </Typography>
                          <Box
                            className={
                              documentElements.contentType === 'application/pdf'
                                ? `pdfBoxreview documentImgBox`
                                : `documentImgBox`
                            }
                          >
                            {/* {documentElements.documentUrl ? (
                              <img
                                className="documentImg"
                                src={documentElements.documentUrl}
                                alt={documentElements.documentType.typeName}
                              />
                            ) : (
                              ''
                            )} */}
                            {documentElements.contentType === 'application/pdf' ? (
                              <Document
                                file={documentElements.documentUrl}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={documentElements.documentUrl}
                                alt={documentElements.documentType.typeCode}
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs="12" sm="8" md="9">
                          <Typography variant="body2" className="mb-1">
                            <strong>GST Certificate Details</strong>
                          </Typography>
                          <Box className="displayTable w-100">
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Registration Number
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.registration_Number}
                                </strong>
                              </Typography>
                            </Box>

                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                GSTIN Number
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.gstin}
                                </strong>
                              </Typography>
                            </Box>

                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Date of issue of Certificate
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.date_of_issue_of_Certificate}
                                </strong>
                              </Typography>
                            </Box>

                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Type of Registration
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.type_of_Registration}
                                </strong>
                              </Typography>
                            </Box>

                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Legal Name
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.legal_Name}
                                </strong>
                              </Typography>
                            </Box>

                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Trade Name
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.trade_Name}
                                </strong>
                              </Typography>
                            </Box>

                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Constitution of Business
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.constitution_of_Business}
                                </strong>
                              </Typography>
                            </Box>

                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                Address
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.address}
                                </strong>
                              </Typography>
                            </Box>

                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                Director Details
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>
                                  {documentElements.documentOcrJson === null
                                    ? 'N/A'
                                    : documentElements.documentOcrJson.director_details}
                                </strong>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : documentElements.documentType.typeCode === 'INCORPORATION_CERTIFICATE' ? (
                      <Grid container spacing={3} mb={3}>
                        <Grid item xs="12" sm="4" md="3">
                          <Typography variant="body2" className="mb-1">
                            <strong>Incoporation Certificate</strong>
                          </Typography>
                          <Box
                            className={
                              documentElements.contentType === 'application/pdf'
                                ? `pdfBoxreview documentImgBox`
                                : `documentImgBox`
                            }
                          >
                            {/* {documentElements.documentUrl ? (
                              <img
                                className="documentImg"
                                src={documentElements.documentUrl}
                                alt={documentElements.documentType.typeName}
                              />
                            ) : (
                              ''
                            )} */}
                            {documentElements.contentType === 'application/pdf' ? (
                              <Document
                                file={documentElements.documentUrl}
                                onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                              >
                                <Pages pageNumber={pageNumber} />
                              </Document>
                            ) : (
                              <img
                                src={documentElements.documentUrl}
                                alt={documentElements.documentType.typeCode}
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs="12" sm="8" md="9">
                          <Typography variant="body2" className="mb-1">
                            <strong>Incoporation Certificate Details</strong>
                          </Typography>
                          {/* <Box className="displayTable w-100">
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                Address
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>N/A</strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Constitution of Business
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>N/A</strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Number
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>N/A</strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Legal Name
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>N/A</strong>
                              </Typography>
                            </Box>
                            <Box className="displayTableRow">
                              <Typography variant="body2" className="displayTableCell pb-05">
                                Registration Number
                              </Typography>
                              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                                :
                              </Typography>
                              <Typography variant="body2" className="displayTableCell pb-05">
                                <strong>N/A</strong>
                              </Typography>
                            </Box>
                          </Box> */}
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </fragment>
                );
              })}
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Container>
    </Page>
  );
}
export default ReviewOnBoarding;
