import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Divider
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { GoChecklist } from 'react-icons/go';
import { VscCheckAll } from 'react-icons/vsc';
import { FcApproval } from 'react-icons/fc';
import { FaTwitterSquare, FaFacebookSquare } from 'react-icons/fa';
import { MdRemoveDone, MdSpellcheck } from 'react-icons/md';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function BusinessReviewOnBoarding() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');

  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };
  return (
    <Page title="PULSE by Real Value Finloans || On Boarding">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Review" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <Scrollbar>
              <Box className="submitLeadToolbar">
                <Tooltip title="Approved" placement="top" arrow>
                  <IconButton className="squareIconButton">
                    <VscCheckAll size={16} />
                  </IconButton>
                </Tooltip>
                &nbsp;&nbsp;&nbsp;
                <Tooltip title="Rejected" placement="top" arrow>
                  <IconButton className="squareIconButton">
                    <MdRemoveDone size={16} />
                  </IconButton>
                </Tooltip>
                &nbsp;&nbsp;&nbsp;
                <Tooltip title="Activate" placement="top" arrow>
                  <IconButton className="squareIconButton">
                    <MdSpellcheck size={16} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box className="cardOuter mb-3">
                <Grid container spacing={3}>
                  <Grid item xs="12" sm="4" md="4">
                    <Box className="displayFlex">
                      <img
                        src="'../../../static/review/profileImg.jpg"
                        alt="profile"
                        className="profileImg"
                      />
                      <Box className="ml-2">
                        <Typography variant="subtitle1">Ashish Kumar</Typography>
                        <Typography variant="body2">Male, 11-10-1973</Typography>
                        <Typography variant="body2" className="mb-1">
                          B104, Ramesh Vihar, New Delhi
                        </Typography>
                        <Box className="displayFlex mb-2">
                          <FaFacebookSquare color="#4267B2" size={28} className="mr-1" />
                          <FaTwitterSquare color="#00acee" size={28} />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="displayTable w-100">
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05 w-40">
                          Mobile No
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>+91-9999911223</strong>
                            <FcApproval size={20} className="ml-1" />
                          </Typography>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Email
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>sandeep.ranjan11@gmail.com</strong>
                            <FcApproval size={20} className="ml-1" />
                          </Typography>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Aadhar No
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>5479 2586 1456</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Pan No
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>AFEFG4521K</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="4" md="4">
                    <Typography variant="h6" className="textAlignCenter mb-1">
                      Low Risk
                    </Typography>
                    <Box className="circleProgress mb-1">
                      <CircularProgress
                        size={120}
                        thickness={2.33}
                        variant="determinate"
                        value={90}
                        color="success"
                      />
                      <Typography className="progressValue" variant="h4">
                        900
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="4" md="4">
                    <Box className="displayTable w-100">
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05 w-50">
                          KYC No
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>5789545</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Application No
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>I-246039</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Loan Type
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Home Loan</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          No of Sales Person Log in Required
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>15</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Web Access
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Yes</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Mobile App Access
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Yes</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Internal Sales Person
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>20</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Typography variant="body2" className="textAlignCenter">
                  As on 1st April 2022
                </Typography>
              </Box>
              <Box className="cardOuter mb-4">
                <Typography variant="subtitle1" className="mb-2">
                  Document Verfication
                </Typography>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs="12" sm="4" md="3">
                    <Typography
                      variant="body2"
                      className="displayFlex alignItemsCenter justifyContentBetween mb-1"
                    >
                      <strong>Aadhar Card Image</strong>
                      <FcApproval size={20} className="ml-1" />
                    </Typography>
                    <Box className="documentImgBox">
                      <img
                        className="documentImg"
                        src="../../static/review/dummy-aadhaar-card.jpg"
                        alt="AAdhar Card"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="8" md="9">
                    <Typography variant="body2" className="mb-1">
                      <strong>Aadhar Card Details</strong>
                    </Typography>
                    <Box className="displayTable w-100">
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05 w-30">
                          Name
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Ashish Kumar</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Fathers/Spouse Name
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Balram Yadav</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          DOB
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>11-10-1973</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Aadhar No
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>5416 8764 9326</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Authenticity Check
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Checked on 10th June 2020 by NDSL</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Physical verification
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Checked on 5th June by Bank employee (156272)</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs="12" sm="4" md="3">
                    <Typography
                      variant="body2"
                      className="displayFlex alignItemsCenter justifyContentBetween mb-1"
                    >
                      <strong>Pan Card Image</strong>
                      <FcApproval size={20} className="ml-1" />
                    </Typography>
                    <Box className="documentImgBox">
                      <img
                        className="documentImg"
                        src="../../static/review/dummy-pan-card.jpg"
                        alt="AAdhar Card"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="8" md="9">
                    <Typography variant="body2" className="mb-1">
                      <strong>Pan Card Details</strong>
                    </Typography>
                    <Box className="displayTable w-100">
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05 w-30">
                          Name
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Ashish Kumar</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Fathers/Spouse Name
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Balram Yadav</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          DOB
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>11-10-1973</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          placement No
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>AABCE2354K</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Authenticity Check
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Checked on 10th June 2020 by NDSL</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Physical verification
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Checked on 5th June by Bank employee (156272)</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs="12" sm="4" md="3">
                    <Typography variant="body2" className="mb-1">
                      <strong>Cancelled Cheque Image</strong>
                    </Typography>
                    <Box className="documentImgBox">
                      <img
                        className="documentImg"
                        src="../../static/review/cancel-cheque.png"
                        alt="AAdhar Card"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="8" md="9">
                    <Typography variant="body2" className="mb-1">
                      <strong>Cancelled Cheque Details</strong>
                    </Typography>
                    <Box className="displayTable w-100">
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05 w-30">
                          Bank Name
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>HDFC Bank</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          RTGS/NEFT IFSC
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>HDFC0000063</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Account Number
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>36524895140</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Customer ID
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>60350262</strong>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05">
                          Bank Address
                        </Typography>
                        <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>Ramesh Vihar, New Delhi</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default BusinessReviewOnBoarding;
