import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import globalService from '../pages/services/globalServices';

// ----------------------------------------------------------------------
const { AccessLevel } = globalService;
const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2.5),
    color: 'rgba(255, 255, 255, 0.6)',
    '&:before': {
      top: 0,
      right: 0,
      width: 5,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.seeserpent.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children, pageCode } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'common.white',
    // fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.seeserpent.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary'
    // fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  // component={RouterLink}
                  // to={path}
                  onClick={() => console.log('helloworld')}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      // component={RouterLink}
      // to={path}
      onClick={() => {
        navigate(path);
        const ciphertext = CryptoJS.AES.encrypt(pageCode, 'pageCode').toString();
        localStorage.setItem('e13ee06b-72e6-4581-86a3-c0a465e63d75', ciphertext);
      }}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  console.log('elements.pageCode', navConfig);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const [sidebarInfo, setSidebarInfo] = useState(null);
  console.log('isSidebarAvailable', sidebarInfo);
  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }
    const authToken = localJWTtoken.token.jwt;
    (async () => {
      const isSidebarAvailable = await AccessLevel(
        {
          companyId: localJWTtoken.token.companyId,
          accessLevelId: localJWTtoken.token.accessLevelId
        },
        authToken
      );
      console.log('isSidebarAvailable', isSidebarAvailable);
      if (isSidebarAvailable.msg) {
        if (isSidebarAvailable.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (isSidebarAvailable.status) {
        if (isSidebarAvailable.status === 403) {
          localStorage.clear();
          navigate('/');
        }
      }
      if (isSidebarAvailable.status === undefined) {
        setSidebarInfo(isSidebarAvailable);
        const ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(isSidebarAvailable),
          'sidebarMenu'
        ).toString();
        localStorage.setItem('e13ee06b-72e6-4581-86a3-c0a465e63d74', ciphertext);
      }
    })();
  }, []);
  return (
    <Box {...other}>
      <List disablePadding>
        <NavItem item={navConfig[0]} active={match} />
        {sidebarInfo &&
          sidebarInfo.accessPagesMapping.map((item) => {
            console.log('accessPagesMapping', item);
            const obj = item;
            obj.title = item.pageName;
            obj.path = item.pageName;
            obj.icon = null;
            const filteredNav = navConfig.filter((elements) => {
              console.log('elements.pageCode', elements.pageCode, item.pageCode);
              return elements.pageCode === item.pageCode;
            });
            console.log('filteredNav', filteredNav);
            if (filteredNav.length > 0) {
              obj.path = filteredNav[0].path;
              obj.icon = filteredNav[0].icon;
            }
            console.log('accessPagesMapping', obj);
            if (item.isEnable) {
              return <NavItem key={item.pageName} item={obj} active={match} />;
            }
            return '';
          })}
      </List>
    </Box>
  );
}
