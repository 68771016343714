import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputBase,
  Stack,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import React, { useState } from 'react';

function AgreementText() {
  return (
    <Box container sx={{ height: '210px', overflow: 'auto', marginBottom: '1rem' }}>
      <Box>
        <p>
          THIS AGREEMENT CONTAINS THE TERMS AND CONDITIONS THAT GOVERN YOUR ACCESS TO AND USE OF THE
          SERVICES THROUGH A PARTICULAR ACCOUNT OR ACCOUNTS AND IS AN AGREEMENT BETWEEN YOU OR THE
          BUSINESS YOU REPRESENT ("<strong>YOU</strong>") AND REAL VALUE FINLOAN SERVICES PVT. LTD.,
          (RVFSPL). BY REGISTERING FOR OR USING THE SERVICES, YOU (ON BEHALF OF YOURSELF OR THE
          BUSINESS YOU REPRESENT) AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, INCLUDING THE
          SERVICE TERMS AND PROGRAM POLICIES FOR EACH SERVICE YOU REGISTER FOR OR USE IN CONNECTION
          WITH THE REAL VALUE FINLOAN SITE.
        </p>
        <p>&nbsp;</p>
        <p>
          These Channel Partner Terms and Conditions (&ldquo;<strong>Terms &amp; Conditions</strong>
          &rdquo;), together with such Principal Terms (as defined below) as may be executed by the
          parties from time to time which reference these Associate Partner/Channel
          Partner/Authorised Person/Franchisee Service Terms and Conditions (collectively, the
          &ldquo;<strong>Agreement</strong>&rdquo;), set forth the terms under which you (&ldquo;
          <strong>you</strong>&rdquo;, &ldquo;<strong>your</strong>&rdquo; or &ldquo;
          <strong>Company</strong>&rdquo;) may sell the Financial Products offered by REAL VALUE
          FINLOAN SERVICES PVT. LTD., (RVFSPL), which are further described herein and on
          www.realvalue.loans. Please read these Terms and Conditions carefully. By clicking the
          &ldquo;Submit&rdquo; box and/or otherwise indicating electronic acceptance of these Terms
          and Conditions, or by otherwise physically executing these Terms and Conditions, you agree
          to become bound thereby. If you do not agree to all the terms and conditions of the
          Agreement, you will not have any right to use or sell the Financial Services. Real Value
          Finloan&rsquo;s acceptance is expressly conditioned upon your assent to all the terms and
          conditions of the Agreement. In the event of a conflict between these Terms &amp;
          Conditions and the Principal Terms, the following order of precedence shall apply: the
          Principal Terms, followed by these Terms &amp; Conditions, except to the extent expressly
          stated otherwise in the Principal Terms. If these Terms &amp; Conditions are considered an
          offer by Real Value Finloan, acceptance is expressly limited to these terms.
        </p>
        <p>&nbsp;</p>
        <p>
          This Associate Partner/Channel Partner/Authorised Person/Franchisee Service Agreement (the
          &ldquo;Agreement&rdquo;) is effective as of the Effective Date (Date of Business Partner
          Registration) by and between
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          REAL VALUE FINLOAN SERVICES PVT. LTD., (RVFSPL), a company incorporated under the
          provisions of the Companies Act, 1956 and having its registered office at 401/4th Foor,
          Shivalaya bldg, Above AU Small Finance Bank, Chandavarkar Road, Nr. Ghanta Panwala,
          Borivali (W), 400092 (hereinafter referred to as the &ldquo;Company&rdquo;, which
          expression shall, unless it be repugnant to the subject or context thereof include its
          Affiliates, Group Company/Firm, Associate concern, successors and permitted assigns).
        </p>
        <p>
          <strong>AND</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Associate Partner/Channel Partner/Authorised Person/Franchisee (hereinafter referred to as
          &ldquo;Business Partner&rdquo;) be it an individual or any firm or a company.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            Company and Channel Partners are hereinafter also collectively referred to as 'Parties'
            and individually as 'Party'
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>WHEREAS: </strong>
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            Company is a Financial Service provider, <em>inter-alia</em>, engaged in the business of
            arranging and distribution of various loan products from time to time, as detailed in
            Annexure I (hereinafter referred to as &ldquo;<strong>Financial Products</strong>
            &rdquo;);
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            The Business Partner has approached the Company and offered to provide the Company and
            its Affiliates or associates or group companies/firm, certain services relating to
            marketing the Financial Products distributed by the Company, source business in this
            regard and operation services at front end to the prospective customers.;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            The Business Partner has represented to the Company that it is legally entitled to and
            also has the requisite skill, knowledge, experiences, expertise, infrastructure and
            capability to market the Financial Products introduced by the Company and source
            business and also has trained and experienced person(s) having requisite skills,
            knowledge to perform the functions in terms of the Agreement and hence is desirous of
            offering the Company its services with respect to the various Financial Products;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            Relying on the abovementioned representation of the Channel Partner, the Company has
            agreed to appoint the Business Partner for the Company and/or its
            Affiliates/associates/group company/firm;
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>NOW THEREFORE, </strong>in consideration of the foregoing, and the premises,
          mutual covenants, promises, agreements and provisions set forth hereinafter, the Parties
          hereby agree as follows:
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong> DEFINITIONS AND INTERPRETATION </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong>DEFINITIONS </strong>
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          In this Agreement, unless the context otherwise requires, the following expressions shall
          have the following meanings:
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;<strong>Act</strong>&rdquo; means the means any statute, law, regulation,
          ordinance, rule, judgment, order, decree, by-law, Government Approval, directive,
          guideline, requirement or other governmental restriction or any decision or determination
          by, or any
        </p>
        <p>
          interpretation, policy or administration of any of the foregoing, by any Government
          Authority having jurisdiction over the matter in question, whether in effect as of the
          date of this Agreement or thereafter or any other succeeding enactment for the time being
          in force;
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;<strong>Affiliate/Associate/Group Company/firm of the Company&rsquo; </strong>means
          shall mean and include any entity that controls, is controlled by, or is under common
          control with the Company including but not limited to group companies, associate
          companies, subsidiary companies of the Company;
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;<strong>Confidential Information</strong>&rdquo; has the meaning assigned to it in
          Clause 10
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;<strong>Code of Conduct</strong>&rdquo; shall mean and include the Code of Conduct
          formulated by the Company, in line with the provisions mandated by its regulator, for
          persons providing direct selling services while operating as its agents, together with any
          modifications and amendments thereto and any additional provisions thereto as mandated by
          the Company and/or as formulated by the Company and/or the Company from time to time, the
          latest version of which is annexed hereto as Annexure II.
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;<strong>Dispute</strong>&rdquo; has the meaning assigned to it in Clause 20
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;<strong>Effective Date</strong>&rdquo; shall means the date of execution of this
          Agreement
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;<strong>Event of default</strong>&rdquo; has the meaning assigned to it in Clause
          13
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;<strong>Financial Product(s)</strong>&rdquo; means and includes the services more
          particularly specified in Annexure I hereof and subject to change from time to time and
          shall include any processes/procedures thereof.
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;<strong>Term</strong>&rdquo; has the meaning assigned to it in Clause 12;
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong>INTERPRETATION </strong>
          </li>
        </ul>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>&nbsp;</p>
        <p>In this Agreement, unless the context otherwise requires:</p>
        <p>&nbsp;</p>
        <ol>
          <li>words of either gender are deemed to include the other gender;</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            words using the singular or plural number also include the plural or singular number,
            respectively;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ul>
          <li>
            the terms &ldquo;hereof&rdquo;, &ldquo;herein&rdquo;, &ldquo;hereby&rdquo;,
            &ldquo;hereto&rdquo; and derivative or similar words refer to this entire Agreement;
          </li>
        </ul>
        <p>&nbsp;</p>
        <ol>
          <li>
            the term &ldquo;Clause&rdquo;, &ldquo;Recital&rdquo; or &ldquo;Schedule&rdquo; refers to
            the specified clause of, recital to, or Schedule to this Agreement;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            headings, sub-headings and bold typeface are only for convenience and shall be ignored
            for the purposes of interpretation;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            references to any legislation or law or to any provision thereof shall include
            references to any such law as it may, after the date hereof, from time to time, be
            amended, supplemented or re-enacted, and any reference to a statutory provision shall
            include any subordinate legislation made from time to time under that provision;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ul>
          <li>
            any term or expression used but not defined herein shall have the same meaning
            attributable to it under Applicable Law;
          </li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>
            references to the word &ldquo;include&rdquo; or &ldquo;including&rdquo; shall be
            construed without limitation;
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <ol start="2">
          <li>
            <strong>BACKGROUND &amp; RATIONAL AND THE SPIRIT OF THIS AGREEMENT </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          2.1. This Agreement between partners defines the co-operation principles between the
          Partners, and related measures and responsibilities and duties.
        </p>
        <p>&nbsp;</p>
        <p>
          The Company on its parts has established its business of distribution of various Financial
          products namely Bonds, Mutual Funds, Fixed Deposits which also includes General Insurance
          as well as Specific Insurance of various Banks/NBFCs &nbsp;and other financial
          institutions and providing allied financial services relating to such financial products
          through online web/ email marketing/digital marketing etc, offline &ndash;tele
          marketing/print media / awareness seminar / events etc, or through their respective
          offices/extended office across the country.
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          2.2. The Company is interested in furthering this business through Business Partner on
          non- exclusive basis maintaining a uniform standard of services against the remuneration
          as specified herein after in this agreement
        </p>
        <p>&nbsp;</p>
        <p>
          2.3. The purpose of this Agreement is to protect the interests of the Parties. It is not
          meant to punish a Parties who unintentionally breaches this Agreement and discontinues his
          or her or its misconduct after notification from other Party.
        </p>
        <p>&nbsp;</p>
        <ol start="3">
          <li>
            <strong> APPOINTMENT AND RELATIONSHIP </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          3.1. The Company has agreed to appoint the Business Partner for, inter-alia, marketing
          and/or promoting its business relating to the Financial Product, and/or sourcing of files
          of borrowers/ investors and handing over the complete set of documents as required by
          Banks/NBFCs and other Financial institutions across the City/State/country on a
          non-exclusive basis for mutually agreed period and for doing all such acts, deeds and
          things as stated in the Agreement and on the terms and conditions stated in this
          Agreement.
        </p>
        <p>&nbsp;</p>
        <p>
          3.2. The relationship between the Company and Business Partner shall be governed
          exclusively by this Agreement and notwithstanding any nomenclature used in this Agreement,
          no presumption of any relation in the nature of agency, joint venture, partnership or
          functionality shall be presumed or shall exist. Neither Party is acting as a fiduciary for
          or as an advisor to the other Party in respect of this Agreement.
        </p>
        <p>&nbsp;</p>
        <p>
          3.3. Nothing contained herein shall be deemed to create any relationship of principal and
          agent or, master and servant or, employer and employee between the Parties hereto or
          subsidiaries thereof or, to provide either Party with the right, power or authority,
          whether expressed or implied to create any such duty or obligation on behalf of the other
          Party.
        </p>
        <p>&nbsp;</p>
        <p>
          3.4. The Employees of Business Partner shall be allowed to represent themselves as
          Associate Employees of RVFSPL to any third party including Clients and Prospective Clients
          and Banks/ NBFCs.
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <ol start="4">
          <li>
            <strong> BUSINESS PARTNER&rsquo;S REPRESENATTIONS AND WARRENTIES </strong>
          </li>
        </ol>
        <p>
          The Business Partner hereby represents, warrants and confirms to the Company that the
          Business Partner-
        </p>
        <p>&nbsp;</p>
        <p>
          4.1. Has full capacity, power and authority to enter into this Agreement; and during the
          continuance of this Agreement, will continue to have full capacity, power and authority to
          act as Business Partner under this Agreement and to carry out and perform all its duties
          and obligations as contemplated herein and has taken and will continue to take necessary
          and further actions (including without limitation the obtaining of all governmental and
          other necessary approvals/ consents under Applicable Laws) to authorize the execution,
          delivery and performance of this Agreement.
        </p>
        <p>&nbsp;</p>
        <p>
          4.2. Has the necessary skill, knowledge, experience, expertise, capital, net worth,
          adequate and competent Personnel, computer systems and procedures, infrastructure
          including adequate office space to duly perform its obligations in accordance with the
          terms of this Agreement and to the satisfaction of the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          4.3. Has not and will not violate, breach any covenants, stipulations or conditions of any
          agreement, deed entered into by the Business Partner with any third party / ies.
        </p>
        <p>&nbsp;</p>
        <p>
          4.4. Has complied with all Applicable Laws and taken all required permission for
          performing under this Agreement.
        </p>
        <p>&nbsp;</p>
        <ol start="5">
          <li>
            <strong> OBLIGATIONS/DUTIES OF BUSINESS PARTNER </strong>
          </li>
        </ol>
        <p>
          The Business Partner agrees and undertakes that, during the subsistence of this Agreement,
          the Business Partner shall:
        </p>
        <p>&nbsp;</p>
        <p>
          5.1. Ensure that adequate infrastructure and sufficient financial resource is available to
          the Business Partner by way of working capital and otherwise ensure that the Business
          Partner is able to fulfil all the obligations herein contained.
        </p>
        <p>&nbsp;</p>
        <p>
          5.2. Understand the Financial Products of the Company in order to be in a position to
          explain and highlight the beneficial aspects of the Financial Products to its Customers
          and shall not launch any promotion scheme pertaining to the Financial Product of the
          Company without prior approval of the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          5.3. Collect all pre-disbursement documents from its Customers to whom through the
          Company, Bank/NBFC/Financial institution has either provided finance or agreed to provide
          finance and submit the same to the Company without any undue delay. And provide such
          operating ancillary services which are prerequisite for the company in sanctioning and
          disbursing loans and other financial products from various Banks/NBFCs/Financial
          institutions.
        </p>
        <p>&nbsp;</p>
        <p>
          5.4. Endeavour to identify genuine Customers based on the standards and norms prescribed
          by the Company from time to time for availing Financial Products of the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          5.5. If the Business Partner will fully suppress any information or conducts itself in
          such a manner to be guilty of inducing the Company to accept any proposal for the ulterior
          benefit of the Business Partner, the Business Partner shall be held liable for the loss
          (if any) caused to the Company and it shall be upon the discretion of the Company to
          terminate this Agreement and take recourse to appropriate legal proceedings for recovery
          of losses, damages etc. suffered by the Company. Notwithstanding anything contained in
          this Agreement, Business Partner shall not take any personal responsibility for any
          Customer or his dues towards Company. The liability of Business Partner is limited to
          proper due diligence as per the standards and norms prescribed by the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          5.6. Operate the Business strictly in accordance with the terms stipulated in this
          Agreement and System prescribed by the Company, from time to time and conform in all
          respects and all times to the System for the purpose of marketing and sourcing of the
          Financial Product and improving and increasing the market potentiality of the Financial
          Products. The Business Partner shall not use any additional trade name or symbol nor do or
          permit anything to be done which is not in accordance with System without the prior
          consent in writing of the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          5.7. Employ all such persons (&ldquo;Personnel&rdquo;) to carry out the work undertaken by
          the Business Partner under this Agreement and thoroughly verify the background of the
          Personnel recruited, from time to time, for affirming the integrity and honesty of the
          Personnel.
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          5.8. Immediately notify the Company, in writing, if any of the Personnel or any other
          person engaged by the Business Partner has committed any act amounting to moral turpitude
          or has been arrested by the police or against whom any complaint has been filed in a
          police station or has been removed from the employment by the Business Partner or has
          committed any act affecting the integrity of such Personnel or person, as the case may be.
        </p>
        <p>&nbsp;</p>
        <p>
          5.9. Ensure courteous service to the Customers and maintain the service standard as laid
          down by the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          5.10. Ensure that the Personnel shall, at all times, conduct themselves within the
          parameters of all Applicable Laws and shall not commit or permit the commission of any
          offence; and in the event any such offence is committed by any Personnel, the Business
          Partner and such Personnel shall be liable for all consequences thereof; and under no
          circumstances, shall the Company be directly or vicariously liable and for such offence.
          The Business Partner shall adequately compensate the Company with an amount not less than
          the actual damages accrued to the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          5.11. Obtain all licenses / permissions / authorizations, as may be required, under all
          the Applicable Laws and keep the same valid by renewing it from time to time as required
          under all such Applicable Laws.
        </p>
        <p>&nbsp;</p>
        <p>5.12. Maintain the registers and records in accordance with various Applicable Laws.</p>
        <p>&nbsp;</p>
        <p>
          5.13. Keep/hold in trust in its hands all documents and valuables belonging to the
          prospective Customer of the Company which comes into the custody of the Business Partner
          during the course of rendering of services or in any other manner and treat the same as
          property of the Company and the Business Partner shall have no right of lien, set off or
          any other right in respect of such property and shall be obligated to deliver the same to
          the Company at the earliest possible opportunity.
        </p>
        <p>&nbsp;</p>
        <p>
          5.14. Use only such letter head, invoices, signs, display materials, promotional
          literature, equipment and other items in-connection with the Business as shall be approved
          in writing by The Company and to immediately desist from the use of display of and signs
          materials or objects, if The Company so directs.
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          5.15. Maintain the interior and exterior of the Premises and all parts thereof to the
          satisfaction of the Company and to ensure that any requirements of the Company in this
          regard are fulfilled.
        </p>
        <p>&nbsp;</p>
        <p>
          5.16. Operate the Business during the term of this Agreement on such days and between such
          hours, as the Company shall specify.
        </p>
        <p>&nbsp;</p>
        <p>
          5.17. Permit the Company and its representatives to enter the Premises as and when
          required for the limited purposes of ascertaining whether the provisions of this Agreement
          are being compiled with.
        </p>
        <p>&nbsp;</p>
        <p>
          5.18. Adhere to the code of conduct, as specified in Annexure II, issued by the Company
        </p>
        <p>&nbsp;</p>
        <p>
          5.19. Adhere to the prospective customer eligibility criteria issued by the Company from
          time to time.
        </p>
        <p>&nbsp;</p>
        <p>
          5.20. Ensure that cases that have been cancelled by any bank or have been rejected by any
          other NBFCs/Financial Institutions are not offered to the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          5.21. Ensure that it holds sole responsibility in matters which require obtaining license
          as a pre-requisite under the various governing statutes such as IRDA, SEBI etc. Failure to
          obtain so, compels the Company to withhold the Commission payable to the Business Partner.
        </p>
        <p>&nbsp;</p>
        <ol start="6">
          <li>
            <strong> RESTRICTION ON BUSINESS PARTNER </strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>Unless otherwise agreed to by the Company, the Business Partner shall:</p>
        <p>&nbsp;</p>
        <p>
          6.1. not use the name or corporate logo of the Company or any part thereof except as
          authorized by the Company in writing or under this Agreement.
        </p>
        <p>&nbsp;</p>
        <p>
          6.2. not do or omit to do any act or thing which may in the sole opinion of the Company
          bring the name of the Company or the corporate logo of the Company into disrepute or which
          may in the sole opinion of the Company damage or conflict with the interests of the
          Company.
        </p>
        <p>&nbsp;</p>
        <p>
          6.3. not to use or publish any advertisements, signs, directory entries or other forms of
          publicity whether or not relating in whole or in part to the Business or display the same
          on or at the Premises unless the same have first been submitted to and approved in writing
          by the Company.
        </p>
        <p>
          6.4. not to work in a manner which in the opinion of the Company may be detrimental to the
          interest of the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          6.5. not to make any call (telephonic or otherwise) to any Customer without verifying the
          telephone details of such Customer from the NDNC Registry (&ldquo;National Do Not Call
          Registry&rdquo;). In the event of any such violation, the Company shall not be responsible
          for the resulting cascading consequences, if any.
        </p>
        <p>&nbsp;</p>
        <p>
          6.6. not to receive money in any form including by way of commission or brokerage or
          equated monthly instalments from any Customer of the company or from any third party for
          sourcing business in any manner whatsoever and not to induce any Customer or borrower of
          the Company to be influenced by any extraneous thing other than the qualitative facts in
          this regard.
        </p>
        <p>&nbsp;</p>
        <p>
          6.7. Not to offer gift or bribe or freebies directly or indirectly to any officers of the
          Banks/NBFCs/Financial institutions with whom customer case is under consideration.
        </p>
        <p>&nbsp;</p>
        <p>
          6.8. not to use any Confidential Information or contact for any of the other customers of
          the Business Partner, who are not the Customer of the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          6.9. not to make any statement / representation / warranty / promises or assurances on
          behalf of the Company to its Customers beyond what is contained in the brochures or
          catalogues setting out the Financial Product as issued by the Company.
        </p>
        <p>&nbsp;</p>
        <ol start="7">
          <li>
            <strong> NO WARRANTIES WITHOUT AUTHORITY </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          7.1. The Business Partner shall make no statement, representation or claim and shall give
          no warranty to any person in respect of the Financial Products of the Company save as may
          be specifically authorized in writing by the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          7.2. Any representations or warranties by the Business Partner to any Personnel employed
          or engaged by the Business Partner (whether or not under a contract for service) that they
          are being engaged or employed by the Company shall be a breach of this Agreement and
          entirely without the explicit or implied authority of the Company.
        </p>
        <p>&nbsp;</p>
        <ol start="8">
          <li>
            <strong> FACILITIES </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          8.1. The Company may provide training to the Personnel engaged or employed by the Business
          Partner at its request.
        </p>
        <p>&nbsp;</p>
        <p>
          8.2. The Company may, at its sole discretion, carry out a special program through
          advertisements or other such mode for the benefit of the Business Partner.
        </p>
        <p>&nbsp;</p>
        <p>
          8.3. The Company shall provide to Business Partner, all the literature of the Banks/NBFCs
          regarding their T &amp; C, latest schemes and all other things are made available to the
          Company as a whole.
        </p>
        <p>&nbsp;</p>
        <p>
          8.4. Business Partner shall be independent to work or ally with take guidance/support from
          any official of the company in course of any difficulties in performance of its services
          under this agreement.
        </p>
        <p>&nbsp;</p>
        <ol start="9">
          <li>
            <strong> ADVERTISING </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          9.1. The Business Partner can undertake with the consent of the Company the responsibility
          of advertising in the area of its operation for the purpose of this Agreement.
        </p>
        <p>&nbsp;</p>
        <p>
          9.2. The Company may make available to the Business Partner advertising materials
          including posters, leaflets, displays, flyers, stickers, signs, cards and notices and the
          Business Partner shall at its own expense prominently display, maintain and distribute the
          same as the case may be. However, if any additional advertising material is required by
          the Business Partner, the Company shall supply the same at the cost price.
        </p>
        <p>&nbsp;</p>
        <p>
          9.3. The Business Partner shall co-operate with the Company and the other Business
          Partners of the Company in any special advertising or sales promotional or other special
          activity and will engage in other promotional advertising activities as the Company may
          direct.
        </p>
        <p>&nbsp;</p>
        <p>
          9.4. In case of any advertising campaign conducted by the Business Partner and the Company
          jointly, all expenses shall be shared as mutually agreed.
        </p>
        <p>&nbsp;</p>
        <ol start="10">
          <li>
            <strong> CONFIDENTIALITY </strong>
          </li>
        </ol>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          10.1. Of the Company shall mean and include any non-public information whether or not
          designated as being confidential or which under the circumstances surrounding disclosure
          ought to be treated as confidential. &ldquo;Confidential Information&rdquo; for purposes
          of confidentiality includes, but is not limited to, the following types of information and
          other information of a similar nature (whether or not reduced to writing): marketing
          techniques and materials, marketing and development plans, Customer names and other
          information related to Customers, information about the employees (including, addresses,
          phone numbers, e-mail addresses, and all other information relating to the employees),
          brokerage/commission structure, pricing policies and financial information, discoveries,
          ideas, concepts, software in various stages of development, drawings, specifications,
          techniques, models, data, mark-up languages (HTML, XML), documentation, diagrams,
          flowcharts, research, development, formulas, computer programs, processes (both business
          and technical), technical specifications, data, ideas, inventions, algorithms, source
          code, object code, know-how, software listings, schematics and discussions between the
          Company and the Business Partner. Confidential Information also includes any information
          described above which the Company treats as proprietary whether or not owned or developed
          by the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          10.2. The Confidential Information further includes, without limitation, information
          relating to the Company&rsquo;s released or unreleased services or products, the marketing
          or promotion of any of the Company Product, Company&rsquo;s business policy or practices,
          and information received from others that the Company is obligated to treat as
          confidential. Confidential Information disclosed to the Business Partner by an
          Affiliate/Associates of the Company shall also be considered as Confidential Information.
          Further, all of the Company&rsquo;s financial projections, data and other related
          information and documents (including any verbal indication) as well as results and
          conclusion arising from the scrutiny process and examination of such data and information
          which is acquired by the Company, documents as well as standardized product/ instrument
          concerning the work assigned which are provided by the Company shall also constitute
          Confidential Information. And in each case whether such information was disclosed before
          or after the date of this Agreement, either in writing, in disk or electronic form or
          orally or visually and whether directly or indirectly by the Company or any of its
          Affiliates, other group companies or advisers
        </p>
        <p>&nbsp;</p>
        <ol start="11">
          <li>
            <strong> FEES AND PAYMENT </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          11.1. In consideration of the Business Partner carrying out the activities as per this
          Agreement, the Company shall pay 40% commission of net revenue to the Business,
          (&ldquo;Fees&rdquo;).
        </p>
        <p>&nbsp;</p>
        <p>
          11.2. The Fees paid to the Business Partner for services rendered, shall be net of
          applicable TDS deduction under Income Tax and GST Act and other laws, as may be notified
          by the Government and, at all times, it shall be the obligation of the Business Partner to
          collect such taxes/ duties/ liabilities from the Company and pay such taxes/ duties/
          liabilities to the appropriate statutory authority.
        </p>
        <p>&nbsp;</p>
        <p>
          11.3. Business Partner Undertakes to indemnify the Company for any loss etc. suffered by
          the Company owing to failure on the part of the Business Partner to comply with the GST
          laws and any rules etc. framed thereunder.
        </p>
        <p>&nbsp;</p>
        <p>
          11.4. Business Partner authorizes the Company to withhold the payment or to adjust the
          same to the extent of GST credit loss (including any interest or penalty imposed on the
          Company on account of the same) owing to non-compliance by the Business Partner with GST
          laws and rules etc. framed thereunder including but not limited to the below:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>a) Non issuance of invoices on time;</li>
          <li>b) Issuance of invoice with incorrect / incomplete particulars;</li>
          <li>c) Timely payment of taxes to the Government;</li>
          <li>d) Timely filing of returns;</li>
          <li>e) Timely reconciliation of mismatches;</li>
          <li>f) Errors on Vendors&rsquo; part in filing the returns; or</li>
          <li>g) Any other reasons due to fault of Vendor.</li>
        </ol>
        <p>&nbsp;</p>
        <p>
          11.5. As per the GST provision as an Anti-profiteering measure, any reduction in the rate
          of tax on any supply of goods or services or the benefit of input tax credit shall be
          passed on to the Company by way of commensurate reduction in the prices.
        </p>
        <p>&nbsp;</p>
        <p>
          11.6. Business Partner undertakes to issue Receipt Voucher in the prescribed format
          against advances, if any, paid by the Company. Likewise, undertakes to issue refund
          voucher on refund of such advances.
        </p>
        <p>&nbsp;</p>
        <p>
          11.7. The Company shall also have the right to set off, deduct and recover from the Fees
          or any other amount payable to the Business Partner, any/or all amounts which may be or
          become payable or which the Business Partner is liable to pay to the Company under this
          Agreement on any other account whatsoever.
        </p>
        <p>&nbsp;</p>
        <p>
          11.8. The Business Partner shall ensure that the invoice for payment (including any
          statutory dues) should be raised by the fifth of the next month. The terms of payment may
          be changed from time to time, by mutual consent of Parties, recorded in writing. The
          Company shall endeavour to make the payment towards Fees and other charges, as may be
          payable by the Company, to the Business Partner within 30 (thirty) days from the receipt
          of the invoice from the Business Partner.
        </p>
        <p>&nbsp;</p>
        <p>
          11.9. In the event of termination of this Agreement by the Company for breach of this
          Agreement by the Business Partner or due to insolvency of the Business Partner or
          cessation of this Agreement, for any reason whatsoever, or any of its provisions thereof,
          the Fees which are due and payable for the business done by the Business Partner prior to
          the termination shall be paid at the sole discretion of the Company.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <ol start="12">
          <li>
            <strong> TERMINATION </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          12.1. This Agreement shall be in force for 11 MONTHS from the Effective Date of this
          Agreement and shall continue in full force and effect unless terminated in accordance with
          Clause 12.2, 12.3 or 12.4 (&ldquo;Term&rdquo;). This Agreement shall be renewed
          automatically upon termination (on expiry of agreement tenure) by the Company, at the sole
          option of the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          12.2. The Company may terminate this Agreement without cause by giving the Business
          Partner a prior written notice of 1 (One) month.
        </p>
        <p>&nbsp;</p>
        <p>
          12.3. The Company may terminate this Agreement forthwith for reasons specified in Clause
          13.
        </p>
        <p>&nbsp;</p>
        <p>
          12.4. The Business Partner may terminate this Agreement by giving a prior notice of three
          (3) months to the Company and all obligations, liabilities of the Partner shall survive
          the termination thereof until all such obligations and liabilities of the Business Partner
          are discharged to the complete satisfaction of the Company.
        </p>
        <p>&nbsp;</p>
        <ol start="13">
          <li>
            <strong> EVENTS OF TERMINATION </strong>
          </li>
        </ol>
        <p>
          The occurrence of any of the following events, or events similar thereto, shall each
          constitute an event of default of the Business Partner (&ldquo;
          <strong>Event of Default</strong>&rdquo;):
        </p>
        <p>&nbsp;</p>
        <p>
          13.1. the Business Partner fails to or neglects to observe or commits or allows to be
          committed any breach of the terms, conditions, provisions or stipulations of this
          Agreement &amp; Code of Conduct on its part to be performed;
        </p>
        <p>&nbsp;</p>
        <p>
          13.2. the Business Partner wilfully defaults or abstains from rendering services as per
          the terms of this Agreement;
        </p>
        <p>&nbsp;</p>
        <p>
          13.3. any or all representations or warranties made by the Business Partner are found to
          be false or wrong or misleading
        </p>
        <p>&nbsp;</p>
        <p>
          13.4. the Business Partner commits any act or deed having adverse effects on the interests
          and affairs of the Company.
        </p>
        <p>&nbsp;</p>
        <p>
          13.5. any act or omission of the Business Partner which may prejudice or jeopardize the
          rights and interests of the Company in any manner whatsoever.
        </p>
        <p>&nbsp;</p>
        <p>
          13.6. the Business Partner or any of the affiliates, representative of the Company or any
          other person authorized by the Company, has been engaged in identical or similar business
          as that of the Company in any manner whatsoever.
        </p>
        <p>&nbsp;</p>
        <p>
          13.7. if at any time it is found or observed that the Business Partner has collected for
          and on behalf of the Company, any amount from the Customer either as initial amount,
          equated monthly installments, management fee and the like.
        </p>
        <p>&nbsp;</p>
        <p>
          13.8. the act or omission of the Business Partner results in loss or damage to the
          Company.
        </p>
        <p>&nbsp;</p>
        <ol start="14">
          <li>
            <strong> CONFIDENTIALITY OF OPERATION </strong>
          </li>
        </ol>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          14.1. All Confidential Information which may be communicated to the Business Partner and/
          or the employees of the Business Partner shall be treated as absolutely confidential and
          the Business Partner irrevocably agrees and undertakes and ensures that the Business
          Partner and its employees shall keep the same secret and confidential and not disclose the
          same, in whole or in part, to any person without the prior written approval or consent of
          the Company and shall use or allowed to be used any information only to the extent as may
          be necessary for the due performance of the Business Partner&rsquo;s obligation hereunder.
        </p>
        <p>&nbsp;</p>
        <p>14.2. The Business Partner agrees to:</p>
        <ol>
          <li>
            a) take all necessary action to protect the Confidential Information against misuse,
            loss, destruction, deletion and/ or alteration. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </li>
          <li>
            b) not to misuse or permit misuse directly or indirectly, commercially exploit the
            Confidential Information for economic or other benefit.
          </li>
          <li>
            c) not to make or retain any copies or records of any Confidential Information submitted
            by the Company other than as may be required for the performance of the Business
            Partner&rsquo;s obligations under this Agreement.
          </li>
          <li>
            d) notify the Company promptly of any unauthorized or improper use or disclosure of the
            Confidential Information;
          </li>
          <li>
            e) return all the information which is in the custody of the Business Partner at the end
            of the specific assignment.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          14.3. The Business Partner hereby unconditionally agrees and undertakes that it shall not
          and that the Personnel shall not disclose or publish the terms and conditions of this
          Agreement or disclose the Confidential Information submitted by the Company under this
          Agreement to any third party unless such disclosure is required by law or for the purpose
          of any performing the Business Partner&rsquo;s obligations under this Agreement.
        </p>
        <p>&nbsp;</p>
        <ol start="15">
          <li>
            <strong> INDEMNITY </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          15.1. The Business Partner shall indemnify and hold harmless, the company against any
          direct monetary losses, costs or claims which it might incur/suffer as a result of:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            the contents of brochures or any other material distributed by the Business Partner to
            the company or prospective clients containing an untrue statement of a material fact or
            an omission of a material fact; or
          </li>
          <li>
            the gross negligence, fraud or willful misconduct of the Business Partner , or their
            employees, agents and representatives
          </li>
          <li>
            breach of any of the terms, conditions, declarations, representations, undertakings and
            warranties contained in this Agreement, on part of the Business Partner or its
            employees, agents and representatives
          </li>
          <li>
            any act or omission of the Business Partner not being in accordance with applicable
            rules, regulations, circulars or notice issued by the regulatory authorities
          </li>
          <li>
            any and all actions, suits, proceedings, assessments, settlement, arbitration judgments,
            cost and expenses, including attorneys&rsquo; fees, resulting from any of the matters
            set forth above.
          </li>
        </ul>
        <p>&nbsp;</p>
        <ol start="16">
          <li>
            <strong> WAIVER </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          16.1. No failure or delay in exercising any right, power or privilege under this Agreement
          by either of the Parties shall operate as a waiver thereof nor shall any single or partial
          exercise of any right, power or privilege preclude any other or further exercise thereof
          or the exercise of any other right, power or privilege.
        </p>
        <p>&nbsp;</p>
        <p>
          16.2. No waiver by either of the Parties of any breach of any of the terms of this
          Agreement shall be effective unless such waiver is expressed in writing signed by either
          of the Parties and the waiver by either of the Parties of breach of any of the terms of
          this Agreement shall not prevent the subsequent enforcement of that term and shall not be
          deemed to be a waiver of any subsequent breach.
        </p>
        <p>&nbsp;</p>
        <ol start="17">
          <li>
            <strong> THIRD PARTY LIABILITY </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          17.1. The Business Partner specifically agrees that for any act of the Business Partner,
          no claim, either in tort or otherwise, shall lie against the Company from any third party
          and Company shall not be held liable under any circumstances for the acts or inactions of
          the Business Partner. All claims of third parties, whether in tort or otherwise, brought
          against the Company at any time, whether during the continuation of this Agreement or
          termination thereof, shall be defended solely by the Business Partner without the
          involvement of the Company in any manner whatsoever and the Company shall be kept
          indemnified at all times against all claims, losses, damages, charges etc. This provision
          shall survive termination of this Agreement.
        </p>
        <p>&nbsp;</p>
        <ol start="18">
          <li>
            <strong> ASSIGNMENT </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          18.1. The Business Partner shall itself perform its obligations under this Agreement and
        </p>
        <p>
          Shall not assign, transfer or sub-contract any of its rights and obligation under this
          Agreement except with prior written permission of the Company. However, the Company shall
          be entitled to assign/transfer its rights and benefits under this Agreement.
        </p>
        <p>&nbsp;</p>
        <ol start="19">
          <li>
            <strong> NON SOLICITATION </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          19.1. The Parties herein agree that during the Term of this Agreement and for a period of
          24 months thereafter the Business Partner shall without the express written consent of the
          Company directly or indirectly:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            a) recruit, hire, appoint or engage or attempt to recruit, hire, appoint or engage or
            discuss employment with or otherwise utilize the services of any person who is or has
            been associated with the Company (including any of its affiliates sub-contractors,
            suppliers, etc.) in rendering services relating to marketing the Financial Products
            under this Agreement; or
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            b) induce any person who is or shall have been an associate of the Company (including
            any of its affiliates, sub-contractors, suppliers, etc.) at any time to terminate
            his/her relationship with the Company.&rdquo;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol start="20">
          <li>
            <strong> GOVERNING LAW AND ARBITRATION </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          20.1. This Agreement shall be governed by and construed exclusively in accordance with the
          laws of India. Courts of Mumbai shall have exclusive jurisdiction in suits or matters
          arising out of or in connection with this Agreement.
        </p>
        <p>&nbsp;</p>
        <p>
          20.2. The Parties agree that any dispute or difference relating to either Party&rsquo;s
          rights or obligations under this Agreement, breach thereof and/or existence or validity of
          this agreement (each, a &ldquo;<strong>Dispute</strong>&rdquo;) shall be resolved by
          reference to arbitration by a arbitral tribunal. Either Party may, by notice to the other,
          refer the Dispute for resolution by arbitration.
        </p>
        <p>&nbsp;</p>
        <p>
          20.3. The arbitration shall be conducted in accordance with the Arbitration and
          Conciliation Act, 1996.
        </p>
        <p>&nbsp;</p>
        <p>20.4. The decision of the arbitrators shall be final and binding upon the Parties.</p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>20.5. The proceedings shall be held at Mumbai and conducted in English language.</p>
        <p>&nbsp;</p>
        <p>
          20.6. Nothing in this Clause shall, in any way, affect the right of any Party to seek such
          interim relief, and only such interim relief as may be needed to maintain the{' '}
          <em>status quo</em>, in aid of the arbitration in any court of competent jurisdiction in
          Mumbai.
        </p>
        <p>&nbsp;</p>
        <p>
          20.7. This Agreement shall be governed by and construed and enforced in accordance with
          the laws of India, without regard to its principles of conflict of laws, the Parties agree
          to submit to the exclusive jurisdiction of the courts in Mumbai alone.
        </p>
        <p>&nbsp;</p>
        <ol start="21">
          <li>
            <strong> NOTICE </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          21.1. Any notice under this agreement shall be in writing and shall be addressed to the
          Business Partner and the Company at the address mentioned hereinabove and for proving the
          service it shall be sufficient to show the envelope containing the notice was properly
          addressed and posted. Any notice shall be sent by registered post or by hand against
          written acknowledgement or receipt, or sent by registered mail, or by facsimile followed
          by a confirmation letter by registered mail, or by electronic mail followed by an
          acknowledgment sent through electronic mail, at or to each of the Parties at the addresses
          mentioned above.
        </p>
        <p>&nbsp;</p>
        <ol start="22">
          <li>
            <strong> FORCE MAJEURE </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          22.1. Notwithstanding anything else contained in this Agreement, neither Party shall be
          liable to the other for any failure or delay in the performance of any of its obligations
          under this Agreement for the time and/or to the extent such failure or delay is caused by
          Force Majeure or reasons beyond the reasonable control of the Parties.
        </p>
        <p>&nbsp;</p>
        <p>
          22.2. For the purpose of this Agreement, &ldquo;Force Majeure&rdquo; refers to any event,
          including but not limited to acts of God, acts of any governmental or statutory
          authorities, malicious third party attacks that are unforeseeable or the occurrence and
          effect of which is unavoidable and insurmountable, or any other unforeseeable, unavoidable
          and uncontrollable circumstances or causes beyond the control of either of the Parties.
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <ol start="23">
          <li>
            <strong> AMENDMENT </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          23.1. No modification, alteration, amendment, cancellation or any other change in any term
          or condition of this Agreement shall be valid and binding on any Party unless set out in
          writing and signed by both Parties.
        </p>
        <p>&nbsp;</p>
        <ol start="24">
          <li>
            <strong> INDEPENDENT RIGHTS </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          24.1. Each of the rights of the Parties are independent, cumulative and without prejudice
          to all other rights available to them under law, equity or otherwise, and the exercise or
          non-exercise of any such rights shall not prejudice or constitute a waiver of any other
          right of the Party, whether under this Agreement or otherwise.
        </p>
        <p>&nbsp;</p>
        <ol start="25">
          <li>
            <strong> FURTHER ASSURANCES </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          25.1. Each Party hereby covenants and agrees that it shall execute and deliver such deeds
          and other documents as may be required to implement any of the provisions of this
          Agreement.
        </p>
        <p>&nbsp;</p>
        <ol start="26">
          <li>
            <strong> ENTIRE AGREEMENT </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          26.1. This Agreement constitutes the entire agreement between the Parties with respect to
          the subject matter hereof to the exclusion of all other understandings and assurances,
          either written or oral.
        </p>
        <p>&nbsp;</p>
        <ol start="27">
          <li>
            <strong> COUNTERPARTS </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          27.1. This Agreement may be executed in any number of counterparts and all of which taken
          together shall constitute one and the same instrument. The Parties may enter into this
          Agreement by signing any such counterpart.
        </p>
      </Box>
    </Box>
  );
}

export default AgreementText;
