import { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import leadsService from '../services/leadsService';

const { ListStageStatus, UpdateStageStatuses } = leadsService;

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));

function UpdateLeads() {
  const navigate = useNavigate();
  const [loanInterested, setLoanInterested] = useState(' ');
  const [statusList, setStatusList] = useState([]); // select status data from api
  const [token, setToken] = useState('');
  const [companyId, setCompanyId] = useState();
  const [userId, setUserId] = useState('');
  const [leadData, setLeadData] = useState([]);
  const [comment, setComment] = useState('');
  const [selectedLeadId, setSelectedLeadId] = useState();
  console.log('leadData', leadData);
  console.log('selectedLeadId', selectedLeadId);
  const [refreshComp, setRefreshComp] = useState(null);
  // Validation and open dialog
  const [validationMsg, setValidationMsg] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitCred, setSubmitCred] = useState({
    updatableStatuses: []
  });

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleUpdate = (e) => {
    if (leadData.length > 0) {
      let isCond = true;
      leadData.forEach((item, index) => {
        if (submitCred.updatableStatuses[index].leadStageStatusId === '~') {
          setDialogOpen(true);
          isCond = false;
          setValidationMsg('Please select status');
          return true;
        }
        if (submitCred.updatableStatuses[index].additionalCommemts === '') {
          setDialogOpen(true);
          isCond = false;
          setValidationMsg('Please write your comments');
          return true;
        }
      });
      if (isCond) {
        (async () => {
          const UpdateStageStatusesApiResponse = await UpdateStageStatuses(submitCred, token);
          if (UpdateStageStatusesApiResponse.msg) {
            if (UpdateStageStatusesApiResponse.msg === 'Forbidden') {
              navigate('/');
            }
          }
          if (UpdateStageStatusesApiResponse.status === 'success') {
            navigate('/leads');
          }
        })();
      }
    }
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }
    console.log('localJWTtoken', localJWTtoken);
    const authToken = localJWTtoken.token.jwt;
    setToken(authToken);
    const authCompanyId = localJWTtoken.token.companyId;
    setCompanyId(authCompanyId);
    const authUserId = localJWTtoken.token.userId;
    setUserId(authUserId);

    const localJWTtoken1 = JSON.parse(localStorage.getItem('leadData'));
    if (localJWTtoken1 === null) {
      return true;
    }
    const updatableStatusesArray = [];
    localJWTtoken1.forEach((element) => {
      console.log('localJWTtoken1', element);
      const obj = {
        userId: localJWTtoken.token.userId,
        leadId: element.leadId,
        stageId: 56,
        leadStageStatusId: '~',
        additionalCommemts: ''
      };
      updatableStatusesArray.push(obj);
      setSubmitCred({
        ...submitCred,
        updatableStatuses: updatableStatusesArray
      });
    });
    setLeadData(localJWTtoken1);
    (async () => {
      // select status api
      const credentials1 = {
        stageId: 56
      };
      const ListStageStatusApiResponse = await ListStageStatus(credentials1, authToken);
      if (ListStageStatusApiResponse.msg) {
        if (ListStageStatusApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ListStageStatusApiResponse.status === 'success') {
        setStatusList(ListStageStatusApiResponse.data.leadsStageStatuses);
      }
    })();
  }, []);

  return (
    <Page title="PULSE by Real Value Finloans || Leads">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Update Lead Status" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Box className="tableBox" mb={3}>
              <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Lead ID</StyledTableCell>
                      <StyledTableCell align="">Lead Name</StyledTableCell>
                      <StyledTableCell align="">Status</StyledTableCell>
                      <StyledTableCell align="">Comments</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leadData.length > 0
                      ? leadData.map((item, index) => {
                          console.log(item);
                          return (
                            <StyledTableRow>
                              <StyledTableCell align="">
                                {item.leadId ? item.leadId : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {item.leadName ? item.leadName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <Select
                                  fullWidth
                                  inputProps={{
                                    className: `textInput textInputTable customSelectBox dissabledMenu${loanInterested}`
                                  }}
                                  value={submitCred.updatableStatuses[index].leadStageStatusId}
                                  onChange={(e) => {
                                    setLoanInterested(e.target.value);
                                    setSelectedLeadId(item.leadId);
                                    const prevData = submitCred;
                                    prevData.updatableStatuses[index].leadStageStatusId =
                                      e.target.value;
                                    setSubmitCred(prevData);
                                    setRefreshComp(new Date());
                                  }}
                                  sx={{ minWidth: '200px' }}
                                >
                                  <MenuItem value="~">Select Status</MenuItem>
                                  {statusList.length > 0
                                    ? statusList.map((item, index) => {
                                        console.log(item);
                                        return (
                                          <MenuItem value={item.stageStatusId} key={index}>
                                            {item.stageStatusName}
                                          </MenuItem>
                                        );
                                      })
                                    : ''}
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TextField
                                  fullWidth
                                  inputProps={{
                                    className: 'textInput textInputTable'
                                  }}
                                  placeholder="Write Your Comments"
                                  sx={{ minWidth: '200px' }}
                                  value={submitCred.updatableStatuses[index].additionalCommemts}
                                  onChange={(e) => {
                                    setComment(e.target.value);
                                    const prevData = submitCred;
                                    prevData.updatableStatuses[index].additionalCommemts =
                                      e.target.value;
                                    setSubmitCred(prevData);
                                    setRefreshComp(new Date());
                                  }}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      : ''}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box className="" textAlign="right">
              <Button variant="contained" className="mainBtn" onClick={(e) => handleUpdate(e)}>
                Update
              </Button>
            </Box>
          </Scrollbar>

          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Retry</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}
export default UpdateLeads;
