import { useState } from 'react';
// material
import { Container, Stack, Grid, Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// components
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import { CgFacebook } from 'react-icons/cg';
import { TbBrandMastercard } from 'react-icons/tb';
import { AiFillYoutube, AiFillInstagram, AiOutlineFileProtect } from 'react-icons/ai';
import {
  MdOutlineLocalLaundryService,
  MdOutlineHomeWork,
  MdOutlineBrandingWatermark,
  MdOutlineSecurity
} from 'react-icons/md';
import BrudCrumbs from '../../components/BreadCrumbs';
import PageTitle from '../../components/PageHeading';
import Page from '../../components/Page';

function JourneyConfigure() {
  const navigate = useNavigate();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <Page title="Partner Management System || Journey">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Configure" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={4} md={3}>
              <Box className="cardOuter cardOuterGreenBorder">
                <Box className="cardTop" container>
                  <Box className="cardIconLeft">
                    <Typography variant="caption" className="cardIconInner">
                      <MdOutlineHomeWork className="cardIconIcon" color="#4fbbc8" />
                    </Typography>
                  </Box>
                  <Box className="cardIconRight">
                    <Typography className="" variant="subtitle2" component="div" mb={1}>
                      Landing Page
                    </Typography>
                    <Typography className="cardText">10 April, 2022</Typography>
                    <Typography className="cardText">Amit Mishra</Typography>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="6">
                      <IconButton
                        className="cardIconFooter"
                        onClick={(e) => navigate('/journey/landing-page')}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs="6" textAlign="right">
                      <Switch
                        {...label}
                        defaultChecked
                        color="success"
                        disableRipple="disable"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Box className="cardOuter cardOuterGreenBorder">
                <Box className="cardTop" container>
                  <Box className="cardIconLeft">
                    <Typography variant="caption" className="cardIconInner">
                      <MdOutlineBrandingWatermark className="cardIconIcon" color="#4fbbc8" />
                    </Typography>
                  </Box>
                  <Box className="cardIconRight">
                    <Typography className="" variant="subtitle2" component="div" mb={1}>
                      Watermark
                    </Typography>
                    <Typography className="cardText">10 April, 2022</Typography>
                    <Typography className="cardText">Amit Mishra</Typography>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="6">
                      <IconButton className="cardIconFooter">
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs="6" textAlign="right">
                      <Switch
                        {...label}
                        defaultChecked
                        color="success"
                        disableRipple="disable"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Box className="cardOuter cardOuterGreenBorder">
                <Box className="cardTop" container>
                  <Box className="cardIconLeft">
                    <Typography variant="caption" className="cardIconInner">
                      <AiOutlineFileProtect className="cardIconIcon" color="#4fbbc8" />
                    </Typography>
                  </Box>
                  <Box className="cardIconRight">
                    <Typography className="" variant="subtitle2" component="div" mb={1}>
                      Consent
                    </Typography>
                    <Typography className="cardText">10 April, 2022</Typography>
                    <Typography className="cardText">Amit Mishra</Typography>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="6">
                      <IconButton className="cardIconFooter">
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs="6" textAlign="right">
                      <Switch
                        {...label}
                        defaultChecked
                        color="success"
                        disableRipple="disable"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Box className="cardOuter cardOuterGreenBorder">
                <Box className="cardTop" container>
                  <Box className="cardIconLeft">
                    <Typography variant="caption" className="cardIconInner">
                      <MdOutlineSecurity className="cardIconIcon" color="#4fbbc8" />
                    </Typography>
                  </Box>
                  <Box className="cardIconRight">
                    <Typography className="" variant="subtitle2" component="div" mb={1}>
                      Security
                    </Typography>
                    <Typography className="cardText">10 April, 2022</Typography>
                    <Typography className="cardText">Amit Mishra</Typography>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="6">
                      <IconButton className="cardIconFooter">
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs="6" textAlign="right">
                      <Switch
                        {...label}
                        defaultChecked
                        color="success"
                        disableRipple="disable"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Box className="cardOuter cardOuterGreenBorder">
                <Box className="cardTop" container>
                  <Box className="cardIconLeft">
                    <Typography variant="caption" className="cardIconInner">
                      <TbBrandMastercard className="cardIconIcon" color="#4fbbc8" />
                    </Typography>
                  </Box>
                  <Box className="cardIconRight">
                    <Typography className="" variant="subtitle2" component="div" mb={1}>
                      Fields Master
                    </Typography>
                    <Typography className="cardText">10 April, 2022</Typography>
                    <Typography className="cardText">Amit Mishra</Typography>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="6">
                      <IconButton className="cardIconFooter">
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs="6" textAlign="right">
                      <Switch
                        {...label}
                        defaultChecked
                        color="success"
                        disableRipple="disable"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}

export default JourneyConfigure;
