import React, { useEffect, useState } from 'react';
import {
  Step,
  Stepper,
  StepLabel,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  Tab,
  Checkbox,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  DialogTitle,
  FormLabel,
  FormControlLabel,
  Card,
  Autocomplete,
  Chip,
  ButtonGroup
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/system';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';

// table component
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ImFolderUpload } from 'react-icons/im';
import {
  MdOutlinePreview,
  MdUploadFile,
  MdUpload,
  MdOutlineAdd,
  MdOutlineMoreHoriz
} from 'react-icons/md';
import { BiCloudUpload, BiSearch } from 'react-icons/bi';
import { DiGoogleDrive } from 'react-icons/di';
import { AiOutlineDropbo, AiOutlineMinus, AiFillEdit, AiOutlineCloudUpload } from 'react-icons/ai';
import { CgDigitalocean } from 'react-icons/cg';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useParams } from 'react-router-dom';
import RemoveIcon from '@mui/icons-material/Remove';

// import { CloseIcon } from 'react-icons'
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AliceCarousel from 'react-alice-carousel';
import { IoIosArrowDropleft, IoIosAlert } from 'react-icons/io';
// import pages
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import { SearchStyle } from '../Global/Styling';
import Loader from '../Global/Loader';
import { CommonApiforJourney } from './JourneyApi';

import '../styles/alicestyle.css';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));
function AddJourney() {
  const { rowId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  return (
    <Page title="Partner Managemet System || Journey">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Add New Journey" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Stepper
            connector=""
            activeStep={activeStep}
            sx={{ marginBottom: '25px' }}
            className="stepWizardOuter"
          >
            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Journey Details</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Design Flow</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Review</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Preview &#38; Submit</StepLabel>
            </Step>

            {/* <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Select Other Page</StepLabel>
            </Step> */}
          </Stepper>
          {activeStep === 0 ? (
            <Step1Content handleNext={handleNext} />
          ) : activeStep === 1 ? (
            <Step2Content handleNext={handleNext} handlePrev={handlePrev} />
          ) : activeStep === 2 ? (
            <Step3Content handleNext={handleNext} handlePrev={handlePrev} />
          ) : (
            <Step4Content handlePrev={handlePrev} />
          )}
        </Box>
      </Container>
    </Page>
  );
}

function Step1Content({ handleNext }) {
  const [selectCommissionStructure, setSelectCommissionStructure] = useState(' ');
  const [selectTurnoverBenefit, setSelectTurnoverBenefit] = useState(' ');
  const [selectLoanType, setSelectLoanType] = useState(' ');
  const [selectLoanSubType, setSelectLoanSubType] = useState(' ');
  const [selectEmploymentType, setSelectEmploymentType] = useState(' ');
  const [selectEmploymentSubType, setSelectEmploymentSubType] = useState(' ');
  const [selectTemplate, setSelectTemplate] = useState(' ');
  const [selectJourneyType, setSelectJourneyType] = useState(' ');
  const [selectTargetAudience, setSelectTargetAudience] = useState(' ');

  const [openLoader, setOpenLoader] = useState(false); // loader
  const [journeyStateData, setJourneyStateData] = useState({
    journeyDetails: {
      userId: null,
      companyId: null,
      journeyId: null,
      journeyName: null,
      parentLoanId: null,
      parentLoanName: ' ',
      loanSubTypeId: null,
      loanSubTypeName: null,
      employmentCategoryId: null,
      employmentCategoryName: ' ',
      employmentSubTypeId: null,
      employmentSubTypeName: null,
      journeyTypeId: null,
      journeyTypeName: null,
      targetAudienceId: null,
      targetAudienceName: null,
      loanType: [],
      loanSubType: [],
      employmentType: [],
      employmentSubType: []
    }
    // ,
    // designFlow: [
    //   {
    //     wizardSequence: null,
    //     wizardTitle: null,
    //     cardList: [
    //       {
    //         cardSequence: null,
    //         cardHeading: null,
    //         cardTypeId: null,
    //         cardTypeName: null,
    //         cardNameList: [
    //           {
    //             cardId: null,
    //             cardName: null
    //           }
    //         ],
    //         cardNamePopUp: false,
    //         CardId: null,
    //         CardName: null,
    //         cardContentPopUp: false,
    //         cardContentList: [
    //           {
    //             isChecked: null,
    //             fieldName: null,
    //             fieldType: null,
    //             fieldSequence: null,
    //             validationRequired: null,
    //             optional: null
    //           }
    //         ],
    //         selectedCardContentList: [
    //           {
    //             isChecked: null,
    //             fieldName: null,
    //             fieldType: null,
    //             fieldSequence: null,
    //             validationRequired: null,
    //             optional: null
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]
  });

  useEffect(() => {
    loantyprapiFire('loanType', null);
    loantyprapiFire('employmentType', null);
  }, []);

  useEffect(() => {
    console.log('jgjgjggjgjg', journeyStateData);
  }, [journeyStateData]);

  const loantyprapiFire = async (type, condition) => {
    const localJWTtoken = await JSON.parse(localStorage.getItem('userDetails'));
    const companyIdObj = {
      companyId: localJWTtoken.token.companyId,
      userId: localJWTtoken.token.userId
    };
    let methodName = '';
    let endapiPath = '';
    const token = localJWTtoken.token.jwt;

    if (type === 'loanType') {
      methodName = 'POST';
      endapiPath = 'company/parent-loan-masters/list';
    } else if (type === 'loanSubType') {
      if (condition !== null) {
        companyIdObj.parentLoanId = condition;
        methodName = 'POST';
        endapiPath = 'company/loan-masters/list';
      }
    } else if (type === 'employmentType') {
      methodName = 'POST';
      endapiPath = 'company/parent-emp-types/list';
    } else if (type === 'employmentSubType') {
      if (condition !== null) {
        companyIdObj.employmentCategoryId = condition;
        methodName = 'POST';
        endapiPath = 'company/emp-types/list';
      }
    }

    loanTypebycompany(type, methodName, endapiPath, JSON.stringify(companyIdObj), token);
  };

  const loanTypebycompany = async (type, method, endpoint, credentials, authToken) => {
    // setOpenLoader(true);
    const responseData = await CommonApiforJourney(method, endpoint, credentials, authToken);
    if (responseData.status === 'success') {
      // setOpenLoader(false);
      if (type === 'loanType') {
        const prevData = journeyStateData.journeyDetails;
        prevData.loanType = responseData.data.loanMasters;
        setJourneyStateData({
          ...journeyStateData,
          journeyDetails: prevData
        });
        console.log('loantype', responseData.data.loanMasters);
      } else if (type === 'loanSubType') {
        const prevData = journeyStateData.journeyDetails;
        prevData.loanSubType = responseData.data.loanTypes;
        setJourneyStateData({
          ...journeyStateData,
          journeyDetails: prevData
        });
        console.log('loantypesubtype', responseData.data.loanTypes);
      } else if (type === 'employmentType') {
        const prevData = journeyStateData.journeyDetails;
        prevData.employmentType = responseData.data.empTypes;
        setJourneyStateData({
          ...journeyStateData,
          journeyDetails: prevData
        });
        console.log('employement', responseData.data.empTypes);
      } else if (type === 'employmentSubType') {
        const prevData = journeyStateData.journeyDetails;
        prevData.employmentSubType = responseData.data.subEmpTypes;
        setJourneyStateData({
          ...journeyStateData,
          journeyDetails: prevData
        });
        console.log('employementsubtype', responseData.data.subEmpTypes);
      }
    }
  };

  const handleChange = (event, type) => {
    const inputVal = JSON.parse(event.target.value);
    const prevData = journeyStateData.journeyDetails;
    if (type === 'loanType') {
      prevData.parentLoanId = inputVal.parentLoanId;
      prevData.parentLoanName = event.target.value;
    } else if (type === 'loanSubType') {
      // prevData.employmentTypeId = inputVal.employmentCategoryId;
      // prevData.parentLoanName = event.target.value;
    } else if (type === 'employmentType') {
      prevData.employmentCategoryId = inputVal.employmentCategoryId;
      prevData.employmentCategoryName = event.target.value;
    } else if (type === 'employmentSubType') {
      // prevData.employmentTypeId = inputVal.employmentCategoryId;
      // prevData.employmentCategoryName = event.target.value;
    }

    setJourneyStateData({
      ...journeyStateData,
      journeyDetails: prevData
    });
  };

  return (
    <>
      <Box className="viewLeadCard">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Journey Name
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value={journeyStateData.journeyDetails.journeyName}
                placeholder="Type Journey Name"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Loan Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${journeyStateData.journeyDetails.parentLoanName}`
                }}
                value={journeyStateData.journeyDetails.parentLoanName}
                onChange={(event) => handleChange(event, 'loanType')}
              >
                <MenuItem value=" " disabled>
                  Select Loan Type
                </MenuItem>

                {journeyStateData.journeyDetails.loanType.map((loanItem, loanIndex) => (
                  <MenuItem key={loanIndex} value={JSON.stringify(loanItem)}>
                    {loanItem.parentLoanName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Loan Sub Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${journeyStateData.journeyDetails.parentLoanName}`
                }}
                value={journeyStateData.journeyDetails.parentLoanName}
                onChange={(event) => handleChange(event, 'loanSubType')}
              >
                <MenuItem value=" " disabled>
                  Select Loan Sub Type
                </MenuItem>
                {journeyStateData.journeyDetails.loanSubType.map((loanSubItem, loanSubIndex) => (
                  <MenuItem value={JSON.stringify(loanSubItem)}>
                    {loanSubItem.parentLoanName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Employment Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${journeyStateData.journeyDetails.employmentCategoryName}`
                }}
                value={journeyStateData.journeyDetails.employmentCategoryName}
                onChange={(event) => handleChange(event, 'employmentType')}
              >
                <MenuItem value=" " disabled>
                  Select Employment Type
                </MenuItem>
                {journeyStateData.journeyDetails.employmentType.map(
                  (employmentTypeItem, employmentTypeIndex) => (
                    <MenuItem value={JSON.stringify(employmentTypeItem)}>
                      {employmentTypeItem.employmentCategoryName}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Employment Sub Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${journeyStateData.journeyDetails.employmentSubTypeName}`
                }}
                value={journeyStateData.journeyDetails.employmentSubTypeName}
                onChange={(event) => handleChange(event, 'employmentSubType')}
              >
                <MenuItem value=" " disabled>
                  Select Employment Type
                </MenuItem>
                {journeyStateData.journeyDetails.employmentSubType.map(
                  (employmentSubTypeItem, employmentSubTypeIndex) => (
                    <MenuItem value={JSON.stringify(employmentSubTypeItem)}>
                      {employmentSubTypeItem.parentLoanName}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Journey Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${journeyStateData.journeyDetails.journeyTypeName}`
                }}
                value={journeyStateData.journeyDetails.journeyTypeName}
                onChange={(e) => setSelectJourneyType(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Journey Type
                </MenuItem>
                <MenuItem value="Recommendation">Recommendation</MenuItem>
                <MenuItem value="Application">Application</MenuItem>
                <MenuItem value="Onboarding">Onboarding</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Target Audience
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${journeyStateData.journeyDetails.targetAudienceName}`
                }}
                value={journeyStateData.journeyDetails.targetAudienceName}
                onChange={(e) => setSelectTargetAudience(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Target Audience
                </MenuItem>
                <MenuItem value="Customer">Customer</MenuItem>
                <MenuItem value="Sales">Sales</MenuItem>
              </Select>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handleNext}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
      <Loader openLoader={openLoader} />
    </>
  );
}

function Step2Content({ handleNext, handlePrev }) {
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const [selectCardType, setSelectCardType] = useState(' ');
  const [cardEdit, setCardEdit] = useState(false);
  const [docCardEdit, setDocCardEdit] = useState(false);
  const [chipView, setChipView] = useState(false);
  const [cardName, setCardName] = useState(false);
  const [cardDocument, setCardDocument] = useState(false);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
      padding: '6px',
      height: '32px',
      fontWeight: 500,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      color: '#0d4689'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px',
      height: '32px'
    }
  }));

  useEffect(() => {}, []);
  return (
    <>
      <Box className="viewLeadCard mb-3">
        <Box className="innerBoxBg" mb={3}>
          <Grid container spacing={3} alignItems="end">
            <Grid item xs={4}>
              <Box className="">
                <Typography variant="h6" className="formLabel">
                  Wizard Sequence
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Wizard Sequence"
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="">
                <Typography variant="h6" className="formLabel">
                  Wizard Title
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Wizard Title"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2} mb={2} alignItems="end">
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Sequence
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Card Sequence"
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Heading
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Card Heading"
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectCardType}`
                }}
                value={selectCardType}
                onChange={(e) => setSelectCardType(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Card Type
                </MenuItem>
                <MenuItem value="Data Capture">Data Capture</MenuItem>
                <MenuItem value="Consent">Consent</MenuItem>
                <MenuItem value="Document">Document</MenuItem>
                <MenuItem value="Verification">Verification</MenuItem>
                <MenuItem value="Display">Display</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Name
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Card Name"
                  defaultValue="Income"
                />
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton
                    className="actionButton searchIconB"
                    onClick={(e) => setCardName(true)}
                  >
                    <BiSearch size={18} />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Content
              </Typography>
              <Stack direction="row" spacing={1} className="chipBox">
                <Chip label="Name" size="small" />
                <Chip label="Age" size="small" />
                <IconButton
                  sx={{ padding: '0' }}
                  size="small"
                  variant="subtitle2"
                  onClick={(e) => setChipView(true)}
                >
                  <MdOutlineMoreHoriz size={16} />
                </IconButton>
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton
                    className="actionButton searchIconB"
                    onClick={(e) => setCardEdit(true)}
                  >
                    <BiSearch size={18} />
                  </StyledIconButton>
                </Tooltip>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
              <IconButton className="squareIconButton">
                <MdOutlineAdd size={18} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="end">
          <Grid item xs>
            <TextField
              fullWidth
              inputProps={{
                className: 'textInput'
              }}
              placeholder="Type Card Sequence"
            />
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              inputProps={{
                className: 'textInput'
              }}
              placeholder="Type Card Heading"
            />
          </Grid>
          <Grid item xs>
            <Select
              fullWidth
              inputProps={{
                className: `textInput customSelectBox dissabledMenu${selectCardType}`
              }}
              value={selectCardType}
              onChange={(e) => setSelectCardType(e.target.value)}
            >
              <MenuItem value=" " disabled>
                Select Card Type
              </MenuItem>
              <MenuItem value="Data Capture">Data Capture</MenuItem>
              <MenuItem value="Consent">Consent</MenuItem>
              <MenuItem value="Document">Document</MenuItem>
              <MenuItem value="Verification">Verification</MenuItem>
              <MenuItem value="Display">Display</MenuItem>
            </Select>
          </Grid>
          <Grid item xs>
            <Box sx={{ position: 'relative' }}>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Card Name"
                defaultValue="Employment"
              />
              <Tooltip title="Edit" placement="top" arrow>
                <StyledIconButton
                  className="actionButton searchIconB"
                  onClick={(e) => setCardDocument(true)}
                >
                  <BiSearch size={18} />
                </StyledIconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs>
            <Stack direction="row" spacing={1} className="chipBox">
              <Chip label="Name" size="small" />
              <Chip label="Age" size="small" />
              <IconButton
                sx={{ padding: '0' }}
                size="small"
                variant="subtitle2"
                onClick={(e) => setChipView(true)}
              >
                <MdOutlineMoreHoriz size={16} />
              </IconButton>
              <Tooltip title="Edit" placement="top" arrow>
                <StyledIconButton
                  className="actionButton searchIconB"
                  onClick={(e) => setDocCardEdit(true)}
                >
                  <BiSearch size={18} />
                </StyledIconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
              <IconButton className="squareIconButton">
                <AiOutlineMinus size={18} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {/* card Name pop up start */}
        <Dialog fullWidth className="PopupBox" open={cardName} onClose={(e) => setCardName(false)}>
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Select Card Name</Typography>
            <IconButton onClick={(e) => setCardName(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                fullWidth
                placeholder="Search"
                inputProps={{
                  className: 'textInput textInputTable'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="Income" control={<Radio />} label="Income" />
              <FormControlLabel value="Employment" control={<Radio />} label="Employment" />
              <FormControlLabel value="Personal" control={<Radio />} label="Personal" />
              <FormControlLabel value="Property" control={<Radio />} label="Property" />
              <FormControlLabel
                value="Upload Document"
                control={<Radio />}
                label="Upload Document"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => setCardName(false)} variant="contained" className="mainBtn">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* card Name pop up end */}
        {/* Edit card details pop up start */}
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          className="PopupBox"
          open={cardEdit}
          onClose={(e) => setCardEdit(false)}
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Edit Details</Typography>
            <IconButton onClick={(e) => setCardEdit(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                fullWidth
                placeholder="Search"
                inputProps={{
                  className: 'textInput textInputTable'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box className="outerTableBox">
              <Scrollbar>
                <TableContainer sx={{ height: '250px', overflow: 'auto', background: '' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">Field Name</StyledTableCell>
                        <StyledTableCell align="">Field Type</StyledTableCell>
                        <StyledTableCell align="">Field Sequence</StyledTableCell>
                        <StyledTableCell align="">Validation Required</StyledTableCell>
                        <StyledTableCell align="">Optional</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">Name</StyledTableCell>
                        <StyledTableCell align="">Text</StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="1"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">First Name</StyledTableCell>
                        <StyledTableCell align="">Text</StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="2"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">Last Name</StyledTableCell>
                        <StyledTableCell align="">Text</StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="3"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">Age</StyledTableCell>
                        <StyledTableCell align="">Number</StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="4"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">DOB</StyledTableCell>
                        <StyledTableCell align="">Date</StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="5"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">DOB</StyledTableCell>
                        <StyledTableCell align="">Date</StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="6"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">DOB</StyledTableCell>
                        <StyledTableCell align="">Date</StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="7"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">DOB</StyledTableCell>
                        <StyledTableCell align="">Date</StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="8"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => setCardEdit(false)} variant="contained" className="mainBtn">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* Edit card details pop up end */}
        {/* card document pop up start */}
        <Dialog
          fullWidth
          className="PopupBox"
          open={cardDocument}
          onClose={(e) => setCardDocument(false)}
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Select Card Name</Typography>
            <IconButton onClick={(e) => setCardDocument(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                fullWidth
                placeholder="Search"
                inputProps={{
                  className: 'textInput textInputTable'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="Applicant Card" control={<Radio />} label="Applicant Card" />
              <FormControlLabel value="Director Card" control={<Radio />} label="Director Card" />
              <FormControlLabel
                value="Co Applicant Card"
                control={<Radio />}
                label="Co Applicant Card"
              />
              <FormControlLabel value="Gurantor Card" control={<Radio />} label="Gurantor Card" />
              <FormControlLabel
                value="Proprietor Card"
                control={<Radio />}
                label="Proprietor Card"
              />
              <FormControlLabel value="Company Card" control={<Radio />} label="Company Card" />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => setCardDocument(false)} variant="contained" className="mainBtn">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* card document pop up end */}
        {/* Edit document details pop up start */}
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          className="PopupBox"
          open={docCardEdit}
          onClose={(e) => setDocCardEdit(false)}
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Edit Details</Typography>
            <IconButton onClick={(e) => setDocCardEdit(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                fullWidth
                placeholder="Search"
                inputProps={{
                  className: 'textInput textInputTable'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box className="outerTableBox">
              <Scrollbar>
                <TableContainer sx={{ height: '250px', overflow: 'auto', background: '' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">List of Document Card</StyledTableCell>
                        <StyledTableCell align="">OCR Required</StyledTableCell>
                        <StyledTableCell align="">Field Sequence</StyledTableCell>
                        <StyledTableCell align="">Verification Required</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">Aadhar</StyledTableCell>
                        <StyledTableCell align="">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="1"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">Pan</StyledTableCell>
                        <StyledTableCell align="">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="2"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">ITR 2019</StyledTableCell>
                        <StyledTableCell align="">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TextField
                            type="number"
                            placeholder=""
                            defaultValue="3"
                            inputProps={{ className: 'textInput textInputTable' }}
                            size="small"
                            sx={{ width: '60px' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => setCardEdit(false)} variant="contained" className="mainBtn">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* Edit document details pop up end */}
        {/* all chip pop up start */}
        <Dialog className="PopupBox" open={chipView} onClose={(e) => setChipView(false)} fullWidth>
          <DialogTitle className="popupTitle">
            <Typography variant="h6" />
            <IconButton onClick={(e) => setChipView(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Stack direction="row" sx={{ flexWrap: 'wrap' }} spacing={1}>
              <Chip label="Name" sx={{ marginBottom: '0.5rem' }} size="small" />
              <Chip label="Age" sx={{ marginBottom: '0.5rem' }} size="small" />
              <Chip label="DOB" sx={{ marginBottom: '0.5rem' }} size="small" />
              <Chip label="Name" sx={{ marginBottom: '0.5rem' }} size="small" />
              <Chip label="Age" sx={{ marginBottom: '0.5rem' }} size="small" />
              <Chip label="DOB" sx={{ marginBottom: '0.5rem' }} size="small" />
            </Stack>
          </DialogContent>
        </Dialog>
        {/* all chip details pop up end */}
      </Box>
      <Box className="viewLeadCard">
        <Box className="innerBoxBg" mb={3}>
          <Grid container spacing={3} alignItems="end">
            <Grid item xs={4}>
              <Box className="">
                <Typography variant="h6" className="formLabel">
                  Wizard Sequence
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Wizard Sequence"
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="">
                <Typography variant="h6" className="formLabel">
                  Wizard Title
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Wizard Title"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2} mb={2} alignItems="end">
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Sequence
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Card Sequence"
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Heading
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Card Heading"
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectCardType}`
                }}
                value={selectCardType}
                onChange={(e) => setSelectCardType(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Card Type
                </MenuItem>
                <MenuItem value="Data Capture">Data Capture</MenuItem>
                <MenuItem value="Consent">Consent</MenuItem>
                <MenuItem value="Document">Document</MenuItem>
                <MenuItem value="Verification">Verification</MenuItem>
                <MenuItem value="Display">Display</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Name
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Card Name"
                  defaultValue="Income"
                />
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton
                    className="actionButton searchIconB"
                    onClick={(e) => setCardName(true)}
                  >
                    <BiSearch size={18} />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Content
              </Typography>
              <Stack direction="row" spacing={1} className="chipBox">
                <Chip label="Name" size="small" />
                <Chip label="Age" size="small" />
                <IconButton
                  sx={{ padding: '0' }}
                  size="small"
                  variant="subtitle2"
                  onClick={(e) => setChipView(true)}
                >
                  <MdOutlineMoreHoriz size={16} />
                </IconButton>
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton
                    className="actionButton searchIconB"
                    onClick={(e) => setCardEdit(true)}
                  >
                    <BiSearch size={18} />
                  </StyledIconButton>
                </Tooltip>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
              <IconButton className="squareIconButton">
                <MdOutlineAdd size={18} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="end">
          <Grid item xs>
            <TextField
              fullWidth
              inputProps={{
                className: 'textInput'
              }}
              placeholder="Type Card Sequence"
            />
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              inputProps={{
                className: 'textInput'
              }}
              placeholder="Type Card Heading"
            />
          </Grid>
          <Grid item xs>
            <Select
              fullWidth
              inputProps={{
                className: `textInput customSelectBox dissabledMenu${selectCardType}`
              }}
              value={selectCardType}
              onChange={(e) => setSelectCardType(e.target.value)}
            >
              <MenuItem value=" " disabled>
                Select Card Type
              </MenuItem>
              <MenuItem value="Data Capture">Data Capture</MenuItem>
              <MenuItem value="Consent">Consent</MenuItem>
              <MenuItem value="Document">Document</MenuItem>
              <MenuItem value="Verification">Verification</MenuItem>
              <MenuItem value="Display">Display</MenuItem>
            </Select>
          </Grid>
          <Grid item xs>
            <Box sx={{ position: 'relative' }}>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Card Name"
                defaultValue="Employment"
              />
              <Tooltip title="Edit" placement="top" arrow>
                <StyledIconButton
                  className="actionButton searchIconB"
                  onClick={(e) => setCardName(true)}
                >
                  <BiSearch size={18} />
                </StyledIconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs>
            <Stack direction="row" spacing={1} className="chipBox">
              <Chip label="Name" size="small" />
              <Chip label="Age" size="small" />
              <IconButton
                sx={{ padding: '0' }}
                size="small"
                variant="subtitle2"
                onClick={(e) => setChipView(true)}
              >
                <MdOutlineMoreHoriz size={16} />
              </IconButton>
              <Tooltip title="Edit" placement="top" arrow>
                <StyledIconButton
                  className="actionButton searchIconB"
                  onClick={(e) => setCardEdit(true)}
                >
                  <BiSearch size={18} />
                </StyledIconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
              <IconButton className="squareIconButton">
                <AiOutlineMinus size={18} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button className="mainBtn nextBtn" variant="contained">
          Add Wizard
        </Button>
        <Button
          onClick={handleNext}
          className="mainBtn ml-2"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function Step3Content({ handleNext, handlePrev }) {
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const [show, setShow] = useState(true);
  const [addValueInpt, setAddValueInpt] = useState('Untitled');
  const [photoPreview, setPhotoPreview] = useState(); // photo
  const [uploadImageFile, setUploadImageFile] = useState(); // uploaded image file
  const [selectReviewLayout, setSelectReviewLayout] = useState(' ');
  const [selectFieldsName, setSelectFieldsName] = useState(' ');
  const [selectCardName, setSelectCardName] = useState(' ');
  const [selectDataSource, setSelectDataSource] = useState(' ');
  const [selectDBMapping, setSelectDBMapping] = useState(' ');
  const [selectEditable, setSelectEditable] = useState(' ');
  const handleFileUpload = async (e, setPreview, files, setUploadImage) => {
    let file = '';
    if (files) {
      file = files;
    } else {
      file = e.target.files[0];
    }
    setUploadImage(file);
    setPreview(URL.createObjectURL(file));
  };
  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const fileDrop = (e, setPreview, setUploadImage) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log('e.dataTransfer.files', e.dataTransfer.files);
    handleFileUpload(e, setPreview, files[0], setUploadImage);
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [cardEditable, setCardEditable] = useState('cardEditableYes');
  const [fieldsEditable, setFieldsEditable] = useState('fieldsEditableYes');
  const [cardEdit, setCardEdit] = useState(false);
  return (
    <>
      {/* <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Bank Details
      </Typography> */}
      <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} mb={3}>
        <Grid item xs={4}>
          <Select
            inputProps={{
              className: `textInput customSelectBox dissabledMenu${selectReviewLayout}`
            }}
            fullWidth
            value={selectReviewLayout}
            onChange={(e) => setSelectReviewLayout(e.target.value)}
          >
            <MenuItem value=" " disabled>
              Select Review Template
            </MenuItem>
            <MenuItem value="Layout1">Layout 1</MenuItem>
            <MenuItem value="Layout2">Layout 2</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Box className="viewLeadCard" mb={3}>
        <Box className="innerBoxBg" mb={3}>
          <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs="auto">
              <Typography variant="body1" className="formLabel">
                Card Editable
              </Typography>
              {/* <Switch
                {...label}
                defaultChecked
                color="success"
                disableRipple="disable"
                size="small"
              /> */}
              <ButtonGroup disableElevation variant="contained">
                <Button
                  className={cardEditable === 'cardEditableYes' ? 'yesNoBtn active' : 'yesNoBtn'}
                  onClick={(e) => {
                    setCardEditable('cardEditableYes');
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={cardEditable === 'cardEditableNo' ? 'yesNoBtn active' : 'yesNoBtn'}
                  onClick={(e) => {
                    setCardEditable('cardEditableNo');
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1" className="formLabel">
                Card Sequence
              </Typography>
              <TextField
                inputProps={{
                  className: 'textInput'
                }}
                fullWidth
                placeholder="Type Card Sequence"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1" className="formLabel">
                Card Heading
              </Typography>
              <TextField
                inputProps={{
                  className: 'textInput'
                }}
                fullWidth
                placeholder="Type Card Heading"
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }} alignItems="flex-end" mb={2}>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Name
              </Typography>
              <Select
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectCardName}`
                }}
                value={selectCardName}
                onChange={(e) => setSelectCardName(e.target.value)}
                fullWidth
              >
                <MenuItem value=" " disabled>
                  Select Card Name
                </MenuItem>
                <MenuItem value="Name1">Name1</MenuItem>
                <MenuItem value="Name2">Name2</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Fields Name
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <Select
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectFieldsName}`
                  }}
                  value={selectFieldsName}
                  onChange={(e) => setSelectFieldsName(e.target.value)}
                  fullWidth
                >
                  <MenuItem value=" " disabled>
                    Select Fields Name
                  </MenuItem>
                  <MenuItem value="Name1">Name1</MenuItem>
                  <MenuItem value="Name2">Name2</MenuItem>
                </Select>
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton
                    className="actionButton searchIconB"
                    onClick={(e) => setCardEdit(true)}
                  >
                    <BiSearch size={18} />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Fields Heading
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                defaultValue="Personal Details"
                placeholder="Type Fields Heading"
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Fields Icon
              </Typography>
              <input
                accept="image/png, image/gif, image/jpeg"
                className="fileInput"
                id="contained-button-file1"
                multiple
                type="file"
                onChange={(e) => handleFileUpload(e, setPhotoPreview, null, setUploadImageFile)}
              />
              <label
                htmlFor="contained-button-file1"
                className="uploadDocBoxLabel uploadDocBoxLabelSec"
              >
                <Box
                  variant="contained"
                  color="primary"
                  component="span"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={(e) => fileDrop(e, setPhotoPreview, setUploadImageFile)}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {photoPreview === undefined ? (
                    <>
                      <Box className="UploadIconBox">
                        <AiOutlineCloudUpload fontSize={18} />
                      </Box>
                      {/* <Typography variant="subtitle1" className="textAlignCenter">
                        Icon
                      </Typography> */}
                    </>
                  ) : (
                    ''
                  )}
                  {photoPreview !== undefined ? (
                    <img src={photoPreview} alt="Icon" className="uploadedImg" />
                  ) : (
                    ''
                  )}
                </Box>
              </label>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Fields Editable
              </Typography>
              <ButtonGroup disableElevation variant="contained">
                <Button
                  className={
                    fieldsEditable === 'fieldsEditableYes' ? 'yesNoBtn active' : 'yesNoBtn'
                  }
                  onClick={(e) => {
                    setFieldsEditable('fieldsEditableYes');
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={fieldsEditable === 'fieldsEditableNo' ? 'yesNoBtn active' : 'yesNoBtn'}
                  onClick={(e) => {
                    setFieldsEditable('fieldsEditableNo');
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
              <IconButton className="squareIconButton">
                <MdOutlineAdd size={18} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {/* Edit card details pop up start */}
        <Dialog fullWidth className="PopupBox" open={cardEdit} onClose={(e) => setCardEdit(false)}>
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Edit Details</Typography>
            <IconButton onClick={(e) => setCardEdit(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                fullWidth
                placeholder="Search"
                inputProps={{
                  className: 'textInput textInputTable'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box className="outerTableBox">
              <Scrollbar>
                <TableContainer sx={{ height: '250px', overflow: 'auto', background: '' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">Field Name</StyledTableCell>
                        <StyledTableCell align="">Manual/OCR</StyledTableCell>
                        <StyledTableCell align="">Select Document</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">Name</StyledTableCell>
                        <StyledTableCell align="">
                          <Switch {...label} color="success" disableRipple="disable" size="small" />
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <Select
                            inputProps={{
                              className: `textInput textInputTable customSelectBox dissabledMenu${selectCardName}`
                            }}
                            value={selectCardName}
                            onChange={(e) => setSelectCardName(e.target.value)}
                            fullWidth
                          >
                            <MenuItem value=" " disabled>
                              Select Document
                            </MenuItem>
                            <MenuItem value="Aadhar Card">Aadhar Card</MenuItem>
                            <MenuItem value="Pan Card">Pan Card</MenuItem>
                          </Select>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">Age</StyledTableCell>
                        <StyledTableCell align="">
                          <Switch
                            {...label}
                            defaultChecked
                            color="success"
                            disableRipple="disable"
                            size="small"
                          />
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <Select
                            inputProps={{
                              className: `textInput textInputTable customSelectBox dissabledMenu${selectCardName}`
                            }}
                            value={selectCardName}
                            onChange={(e) => setSelectCardName(e.target.value)}
                            fullWidth
                          >
                            <MenuItem value=" " disabled>
                              Select Document
                            </MenuItem>
                            <MenuItem value="Aadhar Card">Aadhar Card</MenuItem>
                            <MenuItem value="Pan Card">Pan Card</MenuItem>
                          </Select>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">State</StyledTableCell>
                        <StyledTableCell align="">
                          <Switch {...label} color="success" disableRipple="disable" size="small" />
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <Select
                            inputProps={{
                              className: `textInput textInputTable customSelectBox dissabledMenu${selectCardName}`
                            }}
                            value={selectCardName}
                            onChange={(e) => setSelectCardName(e.target.value)}
                            fullWidth
                          >
                            <MenuItem value=" " disabled>
                              Select Document
                            </MenuItem>
                            <MenuItem value="Aadhar Card">Aadhar Card</MenuItem>
                            <MenuItem value="Pan Card">Pan Card</MenuItem>
                          </Select>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox size="small" sx={{ padding: '0' }} />
                        </StyledTableCell>
                        <StyledTableCell align="">City</StyledTableCell>
                        <StyledTableCell align="">
                          <Switch
                            {...label}
                            defaultChecked
                            color="success"
                            disableRipple="disable"
                            size="small"
                          />
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <Select
                            inputProps={{
                              className: `textInput textInputTable customSelectBox dissabledMenu${selectCardName}`
                            }}
                            value={selectCardName}
                            onChange={(e) => setSelectCardName(e.target.value)}
                            fullWidth
                          >
                            <MenuItem value=" " disabled>
                              Select Document
                            </MenuItem>
                            <MenuItem value="Aadhar Card">Aadhar Card</MenuItem>
                            <MenuItem value="Pan Card">Pan Card</MenuItem>
                          </Select>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => setCardEdit(false)} variant="contained" className="mainBtn">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* Edit card details pop up end */}
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }} alignItems="flex-end">
          <Grid item xs>
            <Box className="">
              <Select
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectCardName}`
                }}
                value={selectCardName}
                onChange={(e) => setSelectCardName(e.target.value)}
                fullWidth
              >
                <MenuItem value=" " disabled>
                  Select Card Name
                </MenuItem>
                <MenuItem value="Name1">Name1</MenuItem>
                <MenuItem value="Name2">Name2</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Box sx={{ position: 'relative' }}>
                <Select
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectFieldsName}`
                  }}
                  value={selectFieldsName}
                  onChange={(e) => setSelectFieldsName(e.target.value)}
                  fullWidth
                >
                  <MenuItem value=" " disabled>
                    Select Fields Name
                  </MenuItem>
                  <MenuItem value="Name1">Name1</MenuItem>
                  <MenuItem value="Name2">Name2</MenuItem>
                </Select>
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton
                    className="actionButton searchIconB"
                    onClick={(e) => setCardEdit(true)}
                  >
                    <BiSearch size={18} />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                defaultValue="Personal Details"
                placeholder="Type Fields Heading"
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box className="">
              <input
                accept="image/png, image/gif, image/jpeg"
                className="fileInput"
                id="contained-button-file1"
                multiple
                type="file"
                onChange={(e) => handleFileUpload(e, setPhotoPreview, null, setUploadImageFile)}
              />
              <label
                htmlFor="contained-button-file1"
                className="uploadDocBoxLabel uploadDocBoxLabelSec"
              >
                <Box
                  variant="contained"
                  color="primary"
                  component="span"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={(e) => fileDrop(e, setPhotoPreview, setUploadImageFile)}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {photoPreview === undefined ? (
                    <>
                      <Box className="UploadIconBox">
                        <AiOutlineCloudUpload fontSize={18} />
                      </Box>
                      {/* <Typography variant="subtitle1" className="textAlignCenter">
                        Icon
                      </Typography> */}
                    </>
                  ) : (
                    ''
                  )}
                  {photoPreview !== undefined ? (
                    <img src={photoPreview} alt="Icon" className="uploadedImg" />
                  ) : (
                    ''
                  )}
                </Box>
              </label>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="">
              <ButtonGroup disableElevation variant="contained">
                <Button
                  className={
                    fieldsEditable === 'fieldsEditableYes' ? 'yesNoBtn active' : 'yesNoBtn'
                  }
                  onClick={(e) => {
                    setFieldsEditable('fieldsEditableYes');
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={fieldsEditable === 'fieldsEditableNo' ? 'yesNoBtn active' : 'yesNoBtn'}
                  onClick={(e) => {
                    setFieldsEditable('fieldsEditableNo');
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
              <IconButton className="squareIconButton">
                <AiOutlineMinus size={18} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box className="viewLeadCard">
        <Box className="innerBoxBg" mb={3}>
          <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs="auto">
              <Typography variant="body1" className="formLabel">
                Card Editable
              </Typography>
              {/* <Switch
                {...label}
                defaultChecked
                color="success"
                disableRipple="disable"
                size="small"
              /> */}
              <ButtonGroup disableElevation variant="contained">
                <Button
                  className={cardEditable === 'cardEditableYes' ? 'yesNoBtn active' : 'yesNoBtn'}
                  onClick={(e) => {
                    setCardEditable('cardEditableYes');
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={cardEditable === 'cardEditableNo' ? 'yesNoBtn active' : 'yesNoBtn'}
                  onClick={(e) => {
                    setCardEditable('cardEditableNo');
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1" className="formLabel">
                Card Sequence
              </Typography>
              <TextField
                inputProps={{
                  className: 'textInput'
                }}
                fullWidth
                placeholder="Type Card Sequence"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1" className="formLabel">
                Card Heading
              </Typography>
              <TextField
                inputProps={{
                  className: 'textInput'
                }}
                fullWidth
                placeholder="Type Card Heading"
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }} alignItems="flex-end" mb={2}>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Card Name
              </Typography>
              <Select
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectCardName}`
                }}
                value={selectCardName}
                onChange={(e) => setSelectCardName(e.target.value)}
                fullWidth
              >
                <MenuItem value=" " disabled>
                  Select Card Name
                </MenuItem>
                <MenuItem value="Name1">Name1</MenuItem>
                <MenuItem value="Name2">Name2</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Fields Name
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <Select
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectFieldsName}`
                  }}
                  value={selectFieldsName}
                  onChange={(e) => setSelectFieldsName(e.target.value)}
                  fullWidth
                >
                  <MenuItem value=" " disabled>
                    Select Fields Name
                  </MenuItem>
                  <MenuItem value="Name1">Name1</MenuItem>
                  <MenuItem value="Name2">Name2</MenuItem>
                </Select>
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton
                    className="actionButton searchIconB"
                    onClick={(e) => setCardEdit(true)}
                  >
                    <BiSearch size={18} />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Fields Heading
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                defaultValue="Personal Details"
                placeholder="Type Fields Heading"
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Fields Icon
              </Typography>
              <input
                accept="image/png, image/gif, image/jpeg"
                className="fileInput"
                id="contained-button-file1"
                multiple
                type="file"
                onChange={(e) => handleFileUpload(e, setPhotoPreview, null, setUploadImageFile)}
              />
              <label
                htmlFor="contained-button-file1"
                className="uploadDocBoxLabel uploadDocBoxLabelSec"
              >
                <Box
                  variant="contained"
                  color="primary"
                  component="span"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={(e) => fileDrop(e, setPhotoPreview, setUploadImageFile)}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {photoPreview === undefined ? (
                    <>
                      <Box className="UploadIconBox">
                        <AiOutlineCloudUpload fontSize={18} />
                      </Box>
                      {/* <Typography variant="subtitle1" className="textAlignCenter">
                        Icon
                      </Typography> */}
                    </>
                  ) : (
                    ''
                  )}
                  {photoPreview !== undefined ? (
                    <img src={photoPreview} alt="Icon" className="uploadedImg" />
                  ) : (
                    ''
                  )}
                </Box>
              </label>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Fields Editable
              </Typography>
              <ButtonGroup disableElevation variant="contained">
                <Button
                  className={
                    fieldsEditable === 'fieldsEditableYes' ? 'yesNoBtn active' : 'yesNoBtn'
                  }
                  onClick={(e) => {
                    setFieldsEditable('fieldsEditableYes');
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={fieldsEditable === 'fieldsEditableNo' ? 'yesNoBtn active' : 'yesNoBtn'}
                  onClick={(e) => {
                    setFieldsEditable('fieldsEditableNo');
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
              <IconButton className="squareIconButton">
                <MdOutlineAdd size={18} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }} alignItems="flex-end">
          <Grid item xs>
            <Box className="">
              <Select
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectCardName}`
                }}
                value={selectCardName}
                onChange={(e) => setSelectCardName(e.target.value)}
                fullWidth
              >
                <MenuItem value=" " disabled>
                  Select Card Name
                </MenuItem>
                <MenuItem value="Name1">Name1</MenuItem>
                <MenuItem value="Name2">Name2</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs>
            <Box sx={{ position: 'relative' }}>
              <Select
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectFieldsName}`
                }}
                value={selectFieldsName}
                onChange={(e) => setSelectFieldsName(e.target.value)}
                fullWidth
              >
                <MenuItem value=" " disabled>
                  Select Fields Name
                </MenuItem>
                <MenuItem value="Name1">Name1</MenuItem>
                <MenuItem value="Name2">Name2</MenuItem>
              </Select>
              <Tooltip title="Edit" placement="top" arrow>
                <StyledIconButton
                  className="actionButton searchIconB"
                  onClick={(e) => setCardEdit(true)}
                >
                  <BiSearch size={18} />
                </StyledIconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="">
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                defaultValue="Personal Details"
                placeholder="Type Fields Heading"
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box className="">
              <input
                accept="image/png, image/gif, image/jpeg"
                className="fileInput"
                id="contained-button-file1"
                multiple
                type="file"
                onChange={(e) => handleFileUpload(e, setPhotoPreview, null, setUploadImageFile)}
              />
              <label
                htmlFor="contained-button-file1"
                className="uploadDocBoxLabel uploadDocBoxLabelSec"
              >
                <Box
                  variant="contained"
                  color="primary"
                  component="span"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={(e) => fileDrop(e, setPhotoPreview, setUploadImageFile)}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {photoPreview === undefined ? (
                    <>
                      <Box className="UploadIconBox">
                        <AiOutlineCloudUpload fontSize={18} />
                      </Box>
                      {/* <Typography variant="subtitle1" className="textAlignCenter">
                        Icon
                      </Typography> */}
                    </>
                  ) : (
                    ''
                  )}
                  {photoPreview !== undefined ? (
                    <img src={photoPreview} alt="Icon" className="uploadedImg" />
                  ) : (
                    ''
                  )}
                </Box>
              </label>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="">
              <ButtonGroup disableElevation variant="contained">
                <Button
                  className={
                    fieldsEditable === 'fieldsEditableYes' ? 'yesNoBtn active' : 'yesNoBtn'
                  }
                  onClick={(e) => {
                    setFieldsEditable('fieldsEditableYes');
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={fieldsEditable === 'fieldsEditableNo' ? 'yesNoBtn active' : 'yesNoBtn'}
                  onClick={(e) => {
                    setFieldsEditable('fieldsEditableNo');
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
              <IconButton className="squareIconButton">
                <AiOutlineMinus size={18} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button className="mainBtn nextBtn" variant="contained">
          Add Card
        </Button>
        <Button
          onClick={handleNext}
          className="mainBtn ml-2"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function Step4Content({ handlePrev }) {
  const [photoPreview, setPhotoPreview] = useState(); // photo
  const [uploadImageFile, setUploadImageFile] = useState(); // uploaded image file
  const [selectReviewLayout, setSelectReviewLayout] = useState(' ');
  const [selectFieldsName, setSelectFieldsName] = useState(' ');
  const [selectDataSource, setSelectDataSource] = useState(' ');
  const [selectDBMapping, setSelectDBMapping] = useState(' ');
  const [selectEditable, setSelectEditable] = useState(' ');
  const handleFileUpload = async (e, setPreview, files, setUploadImage) => {
    let file = '';
    if (files) {
      file = files;
    } else {
      file = e.target.files[0];
    }
    setUploadImage(file);
    setPreview(URL.createObjectURL(file));
  };
  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const fileDrop = (e, setPreview, setUploadImage) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log('e.dataTransfer.files', e.dataTransfer.files);
    handleFileUpload(e, setPreview, files[0], setUploadImage);
  };
  const [itemSlide, setItemSlide] = useState(0);
  const [selectLoan, setSelectLoan] = useState(' ');
  const [selectObjective, setSelectObjective] = useState(' ');
  const [employmentType, setEmploymentType] = useState(' ');
  return (
    <>
      {/* <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Bank Details
      </Typography> */}
      <AliceCarousel
        onSlideChanged={(e) => setItemSlide(e.slide)}
        autoPlay
        autoPlayInterval="3000"
        disableDotsControls="true"
        infinite="true"
      >
        <Box className="sliderOuter">
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Branch Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin">
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Loan Type
                </Typography>
                <Select
                  fullWidth
                  value={selectLoan}
                  onChange={(e) => setSelectLoan(e.target.value)}
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectLoan}`
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Loan Type
                  </MenuItem>
                  <MenuItem value="Home Loan">Home Loan</MenuItem>
                  <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                  <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                  <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                  <MenuItem value="Education Loan">Education Loan</MenuItem>
                  <MenuItem value="Credit Card">Credit Card</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Objective
                </Typography>
                <Select
                  fullWidth
                  value={selectObjective}
                  onChange={(e) => setSelectObjective(e.target.value)}
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectObjective}`
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Objective
                  </MenuItem>
                  <MenuItem value="Reduce EMI">Reduce EMI</MenuItem>
                  <MenuItem value="Max. Amount">Max. Amount</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Loan Amount
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Loan Amount"
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Loan Tenure (Months)
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Loan Tenure"
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Lead Type
                </Typography>
                <Select
                  fullWidth
                  value={selectObjective}
                  onChange={(e) => setSelectObjective(e.target.value)}
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectObjective}`
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Lead Type
                  </MenuItem>
                  <MenuItem value="Self Fullfilled">Self Fullfilled</MenuItem>
                  <MenuItem value="Referral">Referral</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Prefered Bank
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo2"
                  options={topBanks}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} fullWidth placeholder="Select Bank Name" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="formLabel">
                  Employment Type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${employmentType}`
                  }}
                  value={employmentType}
                  onChange={(e) => {
                    setEmploymentType(e.target.value);
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Employment Type
                  </MenuItem>
                  <MenuItem value="Salaried (Pay Slips)">Salaried (Pay Slips)</MenuItem>
                  <MenuItem value="Salaried (Cash)">Salaried (Cash)</MenuItem>
                  <MenuItem value="Self Eemployed (Pvt. Ltd.)">Self Eemployed (Pvt. Ltd.)</MenuItem>
                  <MenuItem value="Self Eemployed (Partnership)">
                    Self Eemployed (Partnership)
                  </MenuItem>
                  <MenuItem value="Self Eemployed (Propritorship)">
                    Self Eemployed (Propritorship)
                  </MenuItem>
                  <MenuItem value="Self Employed (LLP)">Self Employed (LLP)</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="sliderOuter">
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Branch Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin">
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Loan Type
                </Typography>
                <Select
                  fullWidth
                  value={selectLoan}
                  onChange={(e) => setSelectLoan(e.target.value)}
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectLoan}`
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Loan Type
                  </MenuItem>
                  <MenuItem value="Home Loan">Home Loan</MenuItem>
                  <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                  <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                  <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                  <MenuItem value="Education Loan">Education Loan</MenuItem>
                  <MenuItem value="Credit Card">Credit Card</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Objective
                </Typography>
                <Select
                  fullWidth
                  value={selectObjective}
                  onChange={(e) => setSelectObjective(e.target.value)}
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectObjective}`
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Objective
                  </MenuItem>
                  <MenuItem value="Reduce EMI">Reduce EMI</MenuItem>
                  <MenuItem value="Max. Amount">Max. Amount</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Loan Amount
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Loan Amount"
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Loan Tenure (Months)
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Loan Tenure"
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Lead Type
                </Typography>
                <Select
                  fullWidth
                  value={selectObjective}
                  onChange={(e) => setSelectObjective(e.target.value)}
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectObjective}`
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Lead Type
                  </MenuItem>
                  <MenuItem value="Self Fullfilled">Self Fullfilled</MenuItem>
                  <MenuItem value="Referral">Referral</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Prefered Bank
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo2"
                  options={topBanks}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} fullWidth placeholder="Select Bank Name" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="formLabel">
                  Employment Type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${employmentType}`
                  }}
                  value={employmentType}
                  onChange={(e) => {
                    setEmploymentType(e.target.value);
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Employment Type
                  </MenuItem>
                  <MenuItem value="Salaried (Pay Slips)">Salaried (Pay Slips)</MenuItem>
                  <MenuItem value="Salaried (Cash)">Salaried (Cash)</MenuItem>
                  <MenuItem value="Self Eemployed (Pvt. Ltd.)">Self Eemployed (Pvt. Ltd.)</MenuItem>
                  <MenuItem value="Self Eemployed (Partnership)">
                    Self Eemployed (Partnership)
                  </MenuItem>
                  <MenuItem value="Self Eemployed (Propritorship)">
                    Self Eemployed (Propritorship)
                  </MenuItem>
                  <MenuItem value="Self Employed (LLP)">Self Employed (LLP)</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="sliderOuter">
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Branch Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin">
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Loan Type
                </Typography>
                <Select
                  fullWidth
                  value={selectLoan}
                  onChange={(e) => setSelectLoan(e.target.value)}
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectLoan}`
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Loan Type
                  </MenuItem>
                  <MenuItem value="Home Loan">Home Loan</MenuItem>
                  <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                  <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                  <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                  <MenuItem value="Education Loan">Education Loan</MenuItem>
                  <MenuItem value="Credit Card">Credit Card</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Objective
                </Typography>
                <Select
                  fullWidth
                  value={selectObjective}
                  onChange={(e) => setSelectObjective(e.target.value)}
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectObjective}`
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Objective
                  </MenuItem>
                  <MenuItem value="Reduce EMI">Reduce EMI</MenuItem>
                  <MenuItem value="Max. Amount">Max. Amount</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Loan Amount
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Loan Amount"
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Loan Tenure (Months)
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Loan Tenure"
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Lead Type
                </Typography>
                <Select
                  fullWidth
                  value={selectObjective}
                  onChange={(e) => setSelectObjective(e.target.value)}
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectObjective}`
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Lead Type
                  </MenuItem>
                  <MenuItem value="Self Fullfilled">Self Fullfilled</MenuItem>
                  <MenuItem value="Referral">Referral</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className="formLabel">
                  Prefered Bank
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo2"
                  options={topBanks}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} fullWidth placeholder="Select Bank Name" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="formLabel">
                  Employment Type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${employmentType}`
                  }}
                  value={employmentType}
                  onChange={(e) => {
                    setEmploymentType(e.target.value);
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Employment Type
                  </MenuItem>
                  <MenuItem value="Salaried (Pay Slips)">Salaried (Pay Slips)</MenuItem>
                  <MenuItem value="Salaried (Cash)">Salaried (Cash)</MenuItem>
                  <MenuItem value="Self Eemployed (Pvt. Ltd.)">Self Eemployed (Pvt. Ltd.)</MenuItem>
                  <MenuItem value="Self Eemployed (Partnership)">
                    Self Eemployed (Partnership)
                  </MenuItem>
                  <MenuItem value="Self Eemployed (Propritorship)">
                    Self Eemployed (Propritorship)
                  </MenuItem>
                  <MenuItem value="Self Employed (LLP)">Self Employed (LLP)</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </AliceCarousel>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button endIcon={<HiOutlineArrowNarrowRight />} className="mainBtn nextBtn">
          Finish
        </Button>
      </Box>
    </>
  );
}

function Step5Content({ handleNext, handlePrev }) {
  const [selectLoan, setSelectLoan] = useState(' ');
  const [selectCard, setSelectCard] = useState(' ');
  const [selectBranch, setSelectBranch] = useState(' ');
  const [showHide, setShowHide] = useState('Individual');
  const handleShowHide = (event) => {
    const getUserType = event.target.value;
    setShowHide(getUserType);
  };
  const [userType, setUserType] = useState('Individual');
  return (
    <>
      <Box className="greenBorder tableBox">
        <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: '' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
                <StyledTableCell align="" width="30%">
                  Card Name
                </StyledTableCell>
                <StyledTableCell align="">Document List</StyledTableCell>
                <StyledTableCell align="" width="20%">
                  OCR Required
                </StyledTableCell>
                <StyledTableCell align="" width="15%">
                  Optional
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox textInputTable dissabledMenu${selectCard}`
                    }}
                    value={selectCard}
                    onChange={(e) => setSelectCard(e.target.value)}
                  >
                    <MenuItem value=" " disabled>
                      Select Card Name
                    </MenuItem>
                    <MenuItem value="Card1">Card1</MenuItem>
                    <MenuItem value="Card2">Card2</MenuItem>
                  </Select>
                </StyledTableCell>
                <StyledTableCell align="">Pan Card</StyledTableCell>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
                <StyledTableCell align="">
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox textInputTable dissabledMenu${selectCard}`
                    }}
                    value={selectCard}
                    onChange={(e) => setSelectCard(e.target.value)}
                  >
                    <MenuItem value=" " disabled>
                      Select Card Name
                    </MenuItem>
                    <MenuItem value="Card1">Card1</MenuItem>
                    <MenuItem value="Card2">Card2</MenuItem>
                  </Select>
                </StyledTableCell>
                <StyledTableCell align="">Aadhar Card</StyledTableCell>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
                <StyledTableCell align="">
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox textInputTable dissabledMenu${selectCard}`
                    }}
                    value={selectCard}
                    onChange={(e) => setSelectCard(e.target.value)}
                  >
                    <MenuItem value=" " disabled>
                      Select Card Name
                    </MenuItem>
                    <MenuItem value="Card1">Card1</MenuItem>
                    <MenuItem value="Card2">Card2</MenuItem>
                  </Select>
                </StyledTableCell>
                <StyledTableCell align="">Salaried</StyledTableCell>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
                <StyledTableCell padding="checkbox">
                  <Checkbox sx={{ padding: '0' }} />
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function Step6Content({ handleNext, handlePrev }) {
  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };
  const [cardEdit, setCardEdit] = useState(false);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
      padding: '6px',
      height: '32px',
      fontWeight: 500,
      textTransform: 'uppercase',
      // whiteSpace: 'nowrap',
      color: '#0d4689'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px',
      height: '32px'
    }
  }));
  return (
    <>
      <Box className="greenBorder tableBox">
        <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: '' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Card Sequence</StyledTableCell>
                <StyledTableCell align="">Card Name</StyledTableCell>
                <StyledTableCell align="" width="55%">
                  Selected Fields
                </StyledTableCell>
                <StyledTableCell align="">Created By</StyledTableCell>
                <StyledTableCell align="">Modified On</StyledTableCell>
                <StyledTableCell align="">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>2</StyledTableCell>
                <StyledTableCell>Personal Info</StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" spacing={1}>
                    <Chip label="Personal" size="small" onDelete={handleDelete} />
                    <Chip label="Property" size="small" onDelete={handleDelete} />
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>Prakash</StyledTableCell>
                <StyledTableCell>20 July, 2022</StyledTableCell>
                <StyledTableCell>
                  <Tooltip title="Edit" placement="top" arrow>
                    <StyledIconButton
                      className="squareIconButton actionButton"
                      onClick={(e) => setCardEdit(true)}
                    >
                      <AiFillEdit size={14} />
                    </StyledIconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* Edit card details pop up start */}
      <Dialog className="PopupBox" open={cardEdit} onClose={(e) => setCardEdit(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">Edit Details</Typography>
          <IconButton onClick={(e) => setCardEdit(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer sx={{ overflow: 'auto', background: '' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell align="">Field Name</StyledTableCell>
                  <StyledTableCell align="">Field Type</StyledTableCell>
                  <StyledTableCell align="">Validation Required</StyledTableCell>
                  <StyledTableCell align="">Optional</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell align="">Name</StyledTableCell>
                  <StyledTableCell align="">Text</StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell align="">First Name</StyledTableCell>
                  <StyledTableCell align="">Text</StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell align="">Last Name</StyledTableCell>
                  <StyledTableCell align="">Text</StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell align="">Age</StyledTableCell>
                  <StyledTableCell align="">Number</StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell align="">DOB</StyledTableCell>
                  <StyledTableCell align="">Date</StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox size="small" sx={{ padding: '0' }} />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setCardEdit(false)} variant="contained" className="mainBtn">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit card details pop up end */}
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          endIcon={<HiOutlineArrowNarrowRight />}
          className="mainBtn nextBtn"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

export default AddJourney;

// Top Banks
const topBanks = [
  { label: 'Axis Bank' },
  { label: 'HDFC Bank' },
  { label: 'HDFC Ltd.' },
  { label: 'ICICI Bank' },
  { label: 'PNB Housing' },
  { label: 'Piramal Housing' },
  { label: 'Standard Chartered Bank' },
  { label: 'Yes Bank' },
  { label: 'Aditya Birla Housing Finance' },
  { label: 'IDFC Bank' },
  { label: 'RBL Bank' },
  { label: 'Federal Bank' },
  { label: 'Fedfina' },
  { label: 'HDB Financial' },
  { label: 'IIFL Finance' },
  { label: 'Tata Capital' },
  { label: 'ICICI Home Finance' },
  { label: 'Cholamandalam Finance' },
  { label: 'L&T Finance' },
  { label: 'Karur Vysya Bank' },
  { label: 'Hero Housing Finance' },
  { label: 'Kotak Mahindra Bank' },
  { label: 'Fullerton Grihashakti' },
  { label: 'Anand Rathi Financial' },
  { label: 'Aadhar Housing Finance' },
  { label: 'Godrej Housing Finance' },
  { label: 'Deutsche Bank' },
  { label: 'Jana Small Finance Bank' },
  { label: 'HSBC Bank' },
  { label: 'DCB Bank' },
  { label: 'Bank of Baroda' },
  { label: 'Bajaj Housing Finance' },
  { label: 'LIC Housing Finance' },
  { label: 'AU Small Finance Bank' },
  { label: 'Union Bank of India' },
  { label: 'State Bank of India' }
];

// Top Cities
const topCities = [
  { label: 'Mumbai' },
  { label: 'Delhi' },
  { label: 'Bengaluru' },
  { label: 'Ahmedabad' },
  { label: 'Hyderabad' },
  { label: 'Chennai' },
  { label: 'Kolkata' },
  { label: 'Pune' },
  { label: 'Jaipur' },
  { label: 'Surat' },
  { label: 'Lucknow' },
  { label: 'Kanpur' },
  { label: 'Nagpur' },
  { label: 'Patna' },
  { label: 'Indore' },
  { label: 'Thane' },
  { label: 'Bhopal' },
  { label: 'Visakhapatnam' },
  { label: 'Vadodara' },
  { label: 'Firozabad' },
  { label: 'Ludhiana' },
  { label: 'Rajkot' },
  { label: 'Agra' },
  { label: 'Siliguri' },
  { label: 'Nashik' },
  { label: 'Faridabad' },
  { label: 'Patiala' },
  { label: 'Meerut' },
  { label: 'Varanasi' },
  { label: 'Dhanbad' },
  { label: 'Jodhpur' },
  { label: 'Amritsar' },
  { label: 'Raipur' },
  { label: 'Allahabad' },
  { label: 'Coimbatore' },
  { label: 'Jabalpur' },
  { label: 'Gwalior' },
  { label: 'Vijayawada' },
  { label: 'Madurai' },
  { label: 'Guwahati' },
  { label: 'Chandigarh' },
  { label: 'Hubli-Dharwad' },
  { label: 'Amroha' },
  { label: 'Moradabad' },
  { label: 'Gurgaon' },
  { label: 'Aligarh' },
  { label: 'Solapur' },
  { label: 'Ranchi' },
  { label: 'Jalandhar' },
  { label: 'Tiruchirappalli' },
  { label: 'Bhubaneswar' },
  { label: 'Salem' },
  { label: 'Warangal' },
  { label: 'Mira-Bhayandar' },
  { label: 'Thiruvananthapuram' },
  { label: 'Bhiwandi' },
  { label: 'Saharanpur' },
  { label: 'Guntur' },
  { label: 'Amravati' },
  { label: 'Bikaner' },
  { label: 'Noida' },
  { label: 'Jamshedpur' },
  { label: 'Bhilai Nagar' },
  { label: 'Cuttack' },
  { label: 'Kochi' },
  { label: 'Udaipur' },
  { label: 'Bhavnagar' },
  { label: 'Dehradun' },
  { label: 'Asansol' },
  { label: 'Nanded-Waghala' },
  { label: 'Ajmer' },
  { label: 'Jamnagar' },
  { label: 'Ujjain' },
  { label: 'Sangli' },
  { label: 'Loni' },
  { label: 'Jhansi' },
  { label: 'Pondicherry' },
  { label: 'Nellore' },
  { label: 'Jammu' },
  { label: 'Belagavi' },
  { label: 'Raurkela' },
  { label: 'Mangaluru' },
  { label: 'Tirunelveli' },
  { label: 'Malegaon' },
  { label: 'Gaya' },
  { label: 'Tiruppur' },
  { label: 'Davanagere' },
  { label: 'Kozhikode' },
  { label: 'Akola' },
  { label: 'Kurnool' },
  { label: 'Bokaro' },
  { label: 'Rajahmundry' },
  { label: 'Ballari' },
  { label: 'Agartala' },
  { label: 'Bhagalpur' },
  { label: 'Latur' },
  { label: 'Dhule' },
  { label: 'Korba' },
  { label: 'Bhilwara' },
  { label: 'Brahmapur' },
  { label: 'Mysore' },
  { label: 'Muzaffarpur' },
  { label: 'Ahmednagar' },
  { label: 'Kollam' },
  { label: 'Bilaspur' },
  { label: 'Shahjahanpur' },
  { label: 'Thrissur' },
  { label: 'Alwar' },
  { label: 'Kakinada' },
  { label: 'Nizamabad' },
  { label: 'Sagar' },
  { label: 'Tumkur' },
  { label: 'Hisar' },
  { label: 'Rohtak' },
  { label: 'Panipat' },
  { label: 'Darbhanga' },
  { label: 'Kharagpur' },
  { label: 'Aizawl' },
  { label: 'Ichalkaranji' },
  { label: 'Tirupati' },
  { label: 'Karnal' },
  { label: 'Bathinda' },
  { label: 'Rampur' },
  { label: 'Shivamogga' },
  { label: 'Ratlam' },
  { label: 'Modinagar' },
  { label: 'Durg' },
  { label: 'Shillong' },
  { label: 'Imphal' },
  { label: 'Hapur' },
  { label: 'Ranipet' },
  { label: 'Anantapur' },
  { label: 'Arrah' },
  { label: 'Karimnagar' },
  { label: 'Etawah' },
  { label: 'Bharatpur' },
  { label: 'Begusarai' },
  { label: 'Chhapra' },
  { label: 'Kadapa' },
  { label: 'Pali' },
  { label: 'Satna' },
  { label: 'Vizianagaram' },
  { label: 'Katihar' },
  { label: 'Hardwar' },
  { label: 'Sonipat' },
  { label: 'Nagercoil' },
  { label: 'Thanjavur' },
  { label: 'Murwara' },
  { label: 'Naihati' },
  { label: 'Sambhal' },
  { label: 'Nadiad' },
  { label: 'Yamunanagar' },
  { label: 'Eluru' },
  { label: 'Munger' },
  { label: 'Panchkula' },
  { label: 'Deoghar' },
  { label: 'Ongole' },
  { label: 'Nandyal' },
  { label: 'Morena' },
  { label: 'Bhiwani' },
  { label: 'Porbandar' },
  { label: 'Palakkad' },
  { label: 'Anand' },
  { label: 'Purnia' },
  { label: 'Baharampur' },
  { label: 'Barmer' },
  { label: 'Morvi' },
  { label: 'Orai' },
  { label: 'Bahraich' },
  { label: 'Vellore' },
  { label: 'Khammam' },
  { label: 'Mahesana' },
  { label: 'Silchar' },
  { label: 'Sambalpur' },
  { label: 'Rewa' },
  { label: 'Unnao' },
  { label: 'Hugli-Chinsurah' },
  { label: 'Raiganj' },
  { label: 'Adityapur' },
  { label: 'Alappuzha' },
  { label: 'Bahadurgarh' },
  { label: 'Machilipatnam' },
  { label: 'Rae Bareli' },
  { label: 'Jalpaiguri' },
  { label: 'Bharuch' },
  { label: 'Hoshiarpur' },
  { label: 'Baramula' },
  { label: 'Adoni' },
  { label: 'Jind' },
  { label: 'Tonk' },
  { label: 'Tenali' },
  { label: 'Kancheepuram' },
  { label: 'Vapi' },
  { label: 'Navsari' },
  { label: 'Mahbubnagar' },
  { label: 'Puri' },
  { label: 'Erode' },
  { label: 'Batala' },
  { label: 'Haldwani' },
  { label: 'Vidisha' },
  { label: 'Saharsa' },
  { label: 'Thanesar' },
  { label: 'Chittoor' },
  { label: 'Veraval' },
  { label: 'Lakhimpur' },
  { label: 'Sitapur' },
  { label: 'Hindupur' },
  { label: 'Santipur' },
  { label: 'Balurghat' },
  { label: 'Ganjbasoda' },
  { label: 'Moga' },
  { label: 'Srinagar' },
  { label: 'Medinipur' },
  { label: 'Habra' },
  { label: 'Sasaram' },
  { label: 'Hajipur' },
  { label: 'Bhuj' },
  { label: 'Shimla' },
  { label: 'Tiruvannamalai' },
  { label: 'Kaithal' },
  { label: 'Rajnandgaon' },
  { label: 'Godhra' },
  { label: 'Hazaribag' },
  { label: 'Bhimavaram' },
  { label: 'Mandsaur' },
  { label: 'Dibrugarh' },
  { label: 'Kolar' },
  { label: 'Bankura' },
  { label: 'Mandya' },
  { label: 'Dehri-on-Sone' },
  { label: 'Madanapalle' },
  { label: 'Malerkotla' },
  { label: 'Lalitpur' },
  { label: 'Bettiah' },
  { label: 'Pollachi' },
  { label: 'Khanna' },
  { label: 'Neemuch' },
  { label: 'Palwal' },
  { label: 'Palanpur' },
  { label: 'Guntakal' },
  { label: 'Nabadwip' },
  { label: 'Udupi' },
  { label: 'Jagdalpur' },
  { label: 'Motihari' },
  { label: 'Pilibhit' },
  { label: 'Dimapur' },
  { label: 'Mohali' },
  { label: 'Dharmavaram' },
  { label: 'Kashipur' },
  { label: 'Sivakasi' },
  { label: 'Darjiling' },
  { label: 'Chikkamagaluru' },
  { label: 'Gudivada' },
  { label: 'Adilabad' },
  { label: 'Yavatmal' },
  { label: 'Barnala' },
  { label: 'Nagaon' },
  { label: 'Narasaraopet' },
  { label: 'Raigarh' },
  { label: 'Roorkee' },
  { label: 'Valsad' },
  { label: 'Ambikapur' },
  { label: 'Giridih' },
  { label: 'Chandausi' },
  { label: 'Purulia' },
  { label: 'Patan' },
  { label: 'Bagaha' },
  { label: 'Hardoi' },
  { label: 'Achalpur' },
  { label: 'Osmanabad' },
  { label: 'Deesa' },
  { label: 'Nandurbar' },
  { label: 'Azamgarh' },
  { label: 'Ramgarh' },
  { label: 'Firozpur' },
  { label: 'Baripada' },
  { label: 'Karwar' },
  { label: 'Siwan' },
  { label: 'Anantnag' },
  { label: 'Tadpatri' },
  { label: 'Satara' },
  { label: 'Bhadrak' },
  { label: 'Kishanganj' },
  { label: 'Wardha' },
  { label: 'Amreli' },
  { label: 'Neyveli' },
  { label: 'Jamalpur' },
  { label: 'Marmagao' },
  { label: 'Udgir' },
  { label: 'Tadepalligudem' },
  { label: 'Nagapattinam' },
  { label: 'Buxar' },
  { label: 'Aurangabad' },
  { label: 'Jehanabad' },
  { label: 'Khair' },
  { label: 'Sawai Madhopur' },
  { label: 'Kapurthala' },
  { label: 'Chilakaluripet' },
  { label: 'Malappuram' },
  { label: 'Rewari' },
  { label: 'Nagaur' },
  { label: 'Sultanpur' },
  { label: 'Nagda' },
  { label: 'Port Blair' },
  { label: 'Lakhisarai' },
  { label: 'Panaji' },
  { label: 'Tinsukia' },
  { label: 'Itarsi' },
  { label: 'Kohima' },
  { label: 'Balangir' },
  { label: 'Nawada' },
  { label: 'Jharsuguda' },
  { label: 'Jagtial' },
  { label: 'Viluppuram' },
  { label: 'Amalner' },
  { label: 'Zirakpur' },
  { label: 'Tanda' },
  { label: 'Tiruchengode' },
  { label: 'Nagina' },
  { label: 'Yemmiganur' },
  { label: 'Vaniyambadi' },
  { label: 'Theni Allinagaram' },
  { label: 'Margao' },
  { label: 'Akot' },
  { label: 'Mhow' },
  { label: 'Kot Kapura' },
  { label: 'Kadiri' },
  { label: 'Najibabad' },
  { label: 'Nirmal' },
  { label: 'Udhagamandalam' },
  { label: 'Jhumri Tilaiya' },
  { label: 'Aruppukkottai' },
  { label: 'Jamui' },
  { label: 'Sitamarhi' },
  { label: 'Chirala' },
  { label: 'Anjar' },
  { label: 'Karaikal' },
  { label: 'Hansi' },
  { label: 'Anakapalle' },
  { label: 'Mahasamund' },
  { label: 'Faridkot' },
  { label: 'Dhoraji' },
  { label: 'Balaghat' },
  { label: 'Khambhat' },
  { label: 'Kavali' },
  { label: 'Dhamtari' },
  { label: 'Ashok Nagar' },
  { label: 'Bargarh' },
  { label: 'Kamareddy' },
  { label: 'Kothagudem' },
  { label: 'Ramanagaram' },
  { label: 'Gokak' },
  { label: 'Tikamgarh' },
  { label: 'Araria' },
  { label: 'Rishikesh' },
  { label: 'Daltonganj' },
  { label: 'Arakkonam' },
  { label: 'Washim' },
  { label: 'Sangrur' },
  { label: 'Bodhan' },
  { label: 'Fazilka' },
  { label: 'Palacole' },
  { label: 'Keshod' },
  { label: 'Wadhwan' },
  { label: 'Gurdaspur' },
  { label: 'Vatakara' },
  { label: 'Tura' },
  { label: 'Narnaul' },
  { label: 'Kharar' },
  { label: 'Yadgir' },
  { label: 'Ambejogai' },
  { label: 'Ankleshwar' },
  { label: 'Paradip' },
  { label: 'Virudhachalam' },
  { label: 'Kanhangad' },
  { label: 'Kadi' },
  { label: 'Gobindgarh' },
  { label: 'Tindivanam' },
  { label: 'Taliparamba' },
  { label: 'Tanuku' },
  { label: 'Virudhunagar' },
  { label: 'Koyilandy' },
  { label: 'Jorhat' },
  { label: 'Karur' },
  { label: 'Valparai' },
  { label: 'Neyyattinkara' },
  { label: 'Bapatla' },
  { label: 'Fatehabad' },
  { label: 'Sankarankovil' },
  { label: 'Tenkasi' },
  { label: 'Ratnagiri' },
  { label: 'Sikandrabad' },
  { label: 'Chaibasa' },
  { label: 'Chirmiri' },
  { label: 'Bhawanipatna' },
  { label: 'Kayamkulam' },
  { label: 'Nabha' },
  { label: 'Dhenkanal' },
  { label: 'Uran Islampur' },
  { label: 'Gopalganj' },
  { label: 'Bongaigaon' },
  { label: 'Tarn Taran' },
  { label: 'Barbil' },
  { label: 'Koratla' },
  { label: 'Arambagh' },
  { label: 'Gohana' },
  { label: 'Ladnu' },
  { label: 'Tamluk' },
  { label: 'Jagraon' },
  { label: 'Alirajpur' },
  { label: 'Tandur' },
  { label: 'Naidupet' },
  { label: 'Tirupathur' },
  { label: 'Tohana' },
  { label: 'Dhubri' },
  { label: 'Visnagar' },
  { label: 'Vrindavan' },
  { label: 'Nokha' },
  { label: 'Nagari' },
  { label: 'Narwana' },
  { label: 'Ujhani' },
  { label: 'Samastipur' },
  { label: 'Laharpur' },
  { label: 'Nimbahera' },
  { label: 'Diphu' },
  { label: 'Jhargram' },
  { label: 'Tilhar' },
  { label: 'Udumalaipettai' },
  { label: 'Wanaparthy' },
  { label: 'Gudur' },
  { label: 'Kendujhar' },
  { label: 'Mandi' },
  { label: 'Nedumangad' },
  { label: 'North Lakhimpur' },
  { label: 'Vinukonda' },
  { label: 'Tiptur' },
  { label: 'Gobichettipalayam' },
  { label: 'Wani' },
  { label: 'Upleta' },
  { label: 'Narasapuram' },
  { label: 'Nuzvid' },
  { label: 'Tezpur' },
  { label: 'Una' },
  { label: 'Sheopur' },
  { label: 'Thiruvarur' },
  { label: 'Lonavla' },
  { label: 'Ponnur' },
  { label: 'Kagaznagar' },
  { label: 'Gadwal' },
  { label: 'Bhatapara' },
  { label: 'Kandukur' },
  { label: 'Unjha' },
  { label: 'Lunglei' },
  { label: 'Karimganj' },
  { label: 'Kannur' },
  { label: 'Bobbili' },
  { label: 'Talegaon Dabhade' },
  { label: 'Anjangaon' },
  { label: 'Mangrol' },
  { label: 'Gangarampur' },
  { label: 'Thiruvallur' },
  { label: 'Tirur' },
  { label: 'Jatani' },
  { label: 'Viramgam' },
  { label: 'Rajsamand' },
  { label: 'Yanam' },
  { label: 'Kottayam' },
  { label: 'Dhuri' },
  { label: 'Namakkal' },
  { label: 'Kasaragod' },
  { label: 'Kunnamkulam' },
  { label: 'Umred' },
  { label: 'Bellampalle' },
  { label: 'Ottappalam' },
  { label: 'Dumraon' },
  { label: 'Jaggaiahpet' },
  { label: 'Goalpara' },
  { label: 'Tuni' },
  { label: 'Lachhmangarh' },
  { label: 'Bhongir' },
  { label: 'Amalapuram' },
  { label: 'Firozpur Cantt.' },
  { label: 'Vikarabad' },
  { label: 'Thiruvalla' },
  { label: 'Palghar' },
  { label: 'Jangaon' },
  { label: 'Bheemunipatnam' },
  { label: 'Panna' },
  { label: 'Thodupuzha' },
  { label: 'Arwal' },
  { label: 'Venkatagiri' },
  { label: 'Kalpi' },
  { label: 'Churu' },
  { label: 'Arsikere' },
  { label: 'Ozar' },
  { label: 'Thirumangalam' },
  { label: 'Nasirabad' },
  { label: 'Nanjangud' },
  { label: 'Forbesganj' },
  { label: 'Tundla' },
  { label: 'Bhadrachalam' },
  { label: 'Madhepura' },
  { label: 'Pithoragarh' },
  { label: 'Mathura' },
  { label: 'Sultanganj' },
  { label: 'Raxaul' },
  { label: 'Pilani' },
  { label: 'Mapusa' },
  { label: 'Sivaganga' },
  { label: 'Shahpur' },
  { label: 'Ramnagar' },
  { label: 'Rameshwaram' },
  { label: 'Perambalur' },
  { label: 'Manvi' },
  { label: 'Murshidabad' },
  { label: 'Mahe' },
  { label: 'Solan' },
  { label: 'Sanawad' },
  { label: 'Bulandshahr' },
  { label: 'Satana' },
  { label: 'Sanand' },
  { label: 'Sohna' },
  { label: 'Rudrapur' },
  { label: 'Perumbavoor' },
  { label: 'Raikot' },
  { label: 'Pauri' },
  { label: 'Shahganj' },
  { label: 'Mount Abu' },
  { label: 'Silvassa' },
  { label: 'Sikanderpur' },
  { label: 'Navi Mumbai' }
];
// Top States
const topStates = [
  { label: 'Andhra Pradesh' },
  { label: 'Arunachal Pradesh' },
  { label: 'Assam' },
  { label: 'Bihar' },
  { label: 'Chhattisgarh' },
  { label: 'Goa' },
  { label: 'Gujarat' },
  { label: 'Haryana' },
  { label: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir' },
  { label: 'Jharkhand' },
  { label: 'Karnataka' },
  { label: 'Kerala' },
  { label: 'Madhya Pradesh' },
  { label: 'Maharashtra' },
  { label: 'Manipur' },
  { label: 'Meghalaya' },
  { label: 'Mizoram' },
  { label: 'Nagaland' },
  { label: 'Odisha' },
  { label: 'Punjab' },
  { label: 'Rajasthan' },
  { label: 'Sikkim' },
  { label: 'Tamil Nadu' },
  { label: 'Telangana' },
  { label: 'Tripura' },
  { label: 'Uttarakhand' },
  { label: 'Uttar Pradesh' },
  { label: 'West Bengal' },
  { label: 'Andaman and Nicobar Islands' },
  { label: 'Chandigarh' },
  { label: 'Dadra and Nagar Haveli' },
  { label: 'Daman and Diu' },
  { label: 'Delhi' },
  { label: 'Lakshadweep' },
  { label: 'Puducherry' }
];
