import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import editFill from '@iconify/icons-ic/drag-handle';
import { Icon } from '@iconify/react';
import CryptoJS from 'crypto-js';
import { useState } from 'react';
import {
  AiFillEye,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineFileAdd,
  AiFillEdit
} from 'react-icons/ai';
import { BsCheckCircle, BsFillReplyAllFill } from 'react-icons/bs';
import { CgEnter } from 'react-icons/cg';
import { FaFileInvoice, FaRegMoneyBillAlt, FaWpforms } from 'react-icons/fa';
import { FiEye } from 'react-icons/fi';
import { GoFilePdf } from 'react-icons/go';
import { RiFileCopyLine } from 'react-icons/ri';
import {
  MdAppRegistration,
  MdModeEdit,
  MdOutlineSendToMobile,
  MdPayments,
  MdReviews,
  MdPublishedWithChanges
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
// material
import styled from '@emotion/styled';
import { Box, IconButton, Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

import { PARTNER_TO_OFFLINE_URL } from '../../../pages/services/globalApi';
import partnerService from '../../../pages/services/partnerService';

const { OnBoardReview } = partnerService;

export default function UserMoreMenu({
  partner,
  rowId,
  assignedTo,
  parent,
  setOpenStatus,
  UpdatePartnerStatusApi,
  partnerState,
  setOpenOpportunity,
  setOpenSanctioned,
  setOpenRejected,
  setOpenSendMsg,
  pageMenu,
  companyId,
  applicationId,
  statusId,
  statusName,
  item,
  itemObj,
  handleDelete,
  handleOpenDelete,
  bankId,
  bankName,
  setOpenPayment,
  setBranchEdit,
  setIndividualCommissionEdit,
  setVolumeCommissionEdit,
  setDeleteConfirm,
  itemBranchIndex,
  branchIndex,
  branchEditDialogOpen,
  itemList,
  indexValue,
  individualEditDialogOpen,
  itemListVolume,
  indexValueVolume,
  volumeEditDialogOpen
}) {
  const [partnerReviewData, setPartnerReviewData] = useState({});
  // console.log('partnerReviewData', partnerReviewData);
  const [token, setToken] = useState('');
  // OnBoardReview Veiw Api
  const OnBoardReviewApi = async (rowId) => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    console.log('localJWTtoken', localJWTtoken);
    const companyId = localJWTtoken.token.companyId;
    const authToken = localJWTtoken.token.jwt;
    const partnerId = JSON.parse(localStorage.getItem('Partner Id'));
    setToken(authToken);
    const statusViewCredential = {
      companyId: companyId,
      partnerId: partnerId
    };
    const OnBoardReviewResponse = await OnBoardReview(statusViewCredential, authToken);
    console.log('OnBoardReviewResponse', OnBoardReviewResponse);
    if (OnBoardReviewResponse.status === 'success') {
      setPartnerReviewData(OnBoardReviewResponse.data);
    } else {
      setPartnerReviewData({});
    }
  };
  // OnBoardReview Veiw Api end

  const navigate = useNavigate();
  // Styled icon button in Action
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));

  if (parent === 'leads') {
    return (
      <>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'APPLICATION' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Application"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    console.log(companyId);
                    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
                    const authToken = localJWTtoken.token.jwt;
                    const userId = localJWTtoken.token.userId;
                    const appToken = CryptoJS.AES.encrypt(
                      applicationId ? String(applicationId) : 'null',
                      'appToken'
                    ).toString();
                    const companyToken = CryptoJS.AES.encrypt(
                      companyId ? String(companyId) : 'null',
                      'companyToken'
                    ).toString();
                    const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
                    const leadToken = CryptoJS.AES.encrypt(String(rowId), 'leadToken').toString();
                    const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
                    const loanIdToken = CryptoJS.AES.encrypt(
                      String(itemObj.loanId),
                      'loanIdToken'
                    ).toString();
                    const loanTypeIdToken = CryptoJS.AES.encrypt(
                      String(itemObj.loanTypeId),
                      'loanTypeIdToken'
                    ).toString();
                    console.log('appToken', userToken);
                    console.log('appToken', encodeURIComponent(userToken));
                    console.log('appToken', companyToken);
                    console.log('appToken', appToken);
                    console.log('appToken', leadToken);
                    window.open(
                      `${PARTNER_TO_OFFLINE_URL}/loan-application?u=${encodeURIComponent(
                        userToken
                      )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                        appToken
                      )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                        userIdToken
                      )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                        loanTypeIdToken
                      )}`,
                      `_blank`
                    );
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <MdAppRegistration size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'VIEW' && pageElement.isEnable === true ? (
                <Tooltip
                  title="View"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    localStorage.setItem('leadId', rowId);
                    localStorage.setItem('assignedTo', assignedTo);
                    navigate(`/${parent}/view`);
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <FiEye size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'SUBMIT' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Submit"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    localStorage.setItem('leadId', rowId);
                    localStorage.setItem('applicationId', rowId);
                    navigate(`/${parent}/submit`);
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <CgEnter size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
        {}
      </>
    );
  }
  if (parent === 'login') {
    return (
      <>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'VIEW' && pageElement.isEnable === true ? (
                <Tooltip
                  title="View"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    localStorage.setItem('loginLeadId', rowId);
                    navigate(`/${parent}/view`);
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <FiEye size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'SUBMIT' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Submit"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    localStorage.setItem('loginLeadId', rowId);
                    localStorage.setItem('loginApplicationId', applicationId);
                    navigate(`/${parent}/submit`);
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <CgEnter size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'DECISION' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Decision"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    localStorage.setItem('loginLeadId', rowId);
                    localStorage.setItem('loginApplicationId', applicationId);
                    localStorage.setItem('loginStatusId', statusId);
                    localStorage.setItem('loginStatusName', statusName);
                    localStorage.setItem('statusName', JSON.stringify(item.stageStatusName));
                    navigate(`/${parent}/decision`);
                  }}
                >
                  <StyledIconButton
                    classes={{
                      disabled: 'disableButton'
                    }}
                    className="squareIconButton actionButton"
                    disabled={
                      item.stageStatusName === 'Disbursed' || item.stageStatusName === 'Rejected'
                        ? false
                        : true
                    }
                  >
                    {/* <FcMakeDecision size={14} /> */}
                    <img
                      src="../static/icons/decision.svg"
                      alt="Decision"
                      className="decisionImg"
                      height={14}
                    />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
        &nbsp;
      </>
    );
  }
  // if (parent === 'revenue') {
  //   return (
  //     <>
  //       {pageMenu.length > 0 &&
  //         pageMenu.map((pageElement, index) => (
  //           <>
  //             {pageElement.subPageCode === 'VIEW' && pageElement.isEnable === true ? (
  //               <Tooltip
  //                 title="View"
  //                 placement="top"
  //                 arrow
  //                 onClick={(e) => navigate(`/${parent}/view`)}
  //               >
  //                 <StyledIconButton className="squareIconButton actionButton">
  //                   <FiEye size={14} />
  //                 </StyledIconButton>
  //               </Tooltip>
  //             ) : pageElement.subPageCode === 'SEND_MESSAGE' && pageElement.isEnable === true ? (
  //               <Tooltip
  //                 title="Send Message"
  //                 placement="top"
  //                 arrow
  //                 onClick={(e) => {
  //                   setOpenSendMsg(true);
  //                 }}
  //               >
  //                 <StyledIconButton className="squareIconButton actionButton">
  //                   <MdOutlineSendToMobile size={14} />
  //                 </StyledIconButton>
  //               </Tooltip>
  //             ) : (
  //               ''
  //             )}
  //             {}
  //           </>
  //         ))}
  //       &nbsp;
  //     </>
  //   );
  // }
  if (parent === 'revenue') {
    return (
      <>
        <Tooltip title="View File" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/${parent}/view`)}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Generate Invoice" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/${parent}/invoice`)}
          >
            <FaFileInvoice size={14} />
          </StyledIconButton>
        </Tooltip>
        {/* <Tooltip
          title="Send Message"
          placement="top"
          arrow
          onClick={(e) => {
            setOpenSendMsg(true);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdOutlineSendToMobile size={14} />
          </StyledIconButton>
        </Tooltip> */}
      </>
    );
  }
  if (parent === 'revenue-receipt') {
    return (
      <Tooltip title="View File" placement="top" arrow>
        <StyledIconButton
          className="squareIconButton actionButton"
          onClick={(e) => navigate(`/revenue/view-receipt`)}
        >
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'revenue-approved') {
    return (
      <Tooltip title="View File" placement="top" arrow>
        <StyledIconButton
          className="squareIconButton actionButton"
          onClick={(e) => navigate(`/revenue/view-approved`)}
        >
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'revenue-disbursed') {
    return (
      <Tooltip title="View File" placement="top" arrow>
        <StyledIconButton
          className="squareIconButton actionButton"
          onClick={(e) => navigate(`/revenue/view-disbursed`)}
        >
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'payout') {
    return (
      <>
        <Tooltip title="View File" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/${parent}/view`)}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Confirm Invoice" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/${parent}/invoice`)}
          >
            <FaFileInvoice size={14} />
          </StyledIconButton>
        </Tooltip>
        {/* <Tooltip
          title="Send Message"
          placement="top"
          arrow
          onClick={(e) => {
            setOpenSendMsg(true);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdOutlineSendToMobile size={14} />
          </StyledIconButton>
        </Tooltip> */}
      </>
    );
  }
  if (parent === 'rejected') {
    return (
      <>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'RE_APPLY' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Re Apply"
                  placement="top"
                  arrow
                  onClick={(e) => navigate(`/${parent}/reapply`)}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <BsFillReplyAllFill size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'VIEW' && pageElement.isEnable === true ? (
                <Tooltip
                  title="View"
                  placement="top"
                  arrow
                  onClick={(e) => navigate(`/${parent}/view`)}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <FiEye size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
        &nbsp;
      </>
    );
  }
  if (parent === 'call-center') {
    return (
      <>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'COMPLETE_FORM' && pageElement.isEnable === true ? (
                <Tooltip title="Complete Form" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <FaWpforms size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'UPDATE_STATUS' && pageElement.isEnable === true ? (
                <Tooltip title="Update Status" placement="top" arrow>
                  <StyledIconButton
                    className="squareIconButton actionButton"
                    onClick={(e) => setOpenStatus(true)}
                  >
                    <BsCheckCircle size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'CLOSE' && pageElement.isEnable === true ? (
                <Tooltip title="Close" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <AiOutlineClose size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
        &nbsp;
      </>
    );
  }
  if (parent === 'on-boarding') {
    return (
      <Box sx={{ display: 'flex' }}>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'ADD_NEW' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Edit"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    localStorage.setItem('partnerId', rowId);
                    navigate(`/onboarding/edit/${rowId}`);
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <MdModeEdit size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'REVIEW' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Review"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    localStorage.setItem('partnerId', rowId);
                    navigate(`/onboarding/review`);
                    // OnBoardReviewApi(rowId);
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <MdReviews size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'SET_COMMISION' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Set Commission"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    localStorage.setItem('partnerId', rowId);
                    navigate(`/onboarding/set-commission`);
                    // OnBoardReviewApi(rowId);
                  }}
                  // onClick={(e) => navigate(`/onboarding/set-commission`)}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <FaRegMoneyBillAlt size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'ACTIVATE' && pageElement.isEnable === true ? (
                <Tooltip title="Activate" placement="top" arrow>
                  <StyledIconButton
                    classes={{
                      disabled: 'disableButton'
                    }}
                    className="squareIconButton actionButton"
                    onClick={(e) => {
                      UpdatePartnerStatusApi(rowId, partner);
                    }}
                    disabled={
                      partnerState === null ||
                      partner.partnerStatus.typeName === 'Rejected' ||
                      partner.partnerStatus.typeName === 'Inactive'
                        ? true
                        : false
                    }
                  >
                    <AiOutlineCheck size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
      </Box>
    );
  }
  if (parent === 'invoice') {
    return (
      <>
        &nbsp;
        <Box sx={{ display: 'flex' }}>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => (
              <>
                {pageElement.subPageCode === 'RAISE_INVOICE' && pageElement.isEnable === true ? (
                  <>
                    <Tooltip
                      title="Raise Invoice"
                      placement="top"
                      arrow
                      onClick={(e) => navigate(`/${parent}/raise-invoice`)}
                    >
                      <StyledIconButton className="squareIconButton actionButton">
                        <FaFileInvoice size={14} />
                      </StyledIconButton>
                    </Tooltip>
                    &nbsp;
                  </>
                ) : pageElement.subPageCode === 'PAYMENT' && pageElement.isEnable === true ? (
                  <Tooltip
                    title="Payment"
                    placement="top"
                    arrow
                    onClick={(e) => navigate(`/${parent}/payment-invoice`)}
                  >
                    <StyledIconButton className="squareIconButton actionButton">
                      <MdPayments size={14} />
                    </StyledIconButton>
                  </Tooltip>
                ) : (
                  ''
                )}
                {}
              </>
            ))}
        </Box>
      </>
    );
  }
  if (parent === 'receivable-file') {
    return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="View" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/receivable/view-file`)}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
      </Box>
    );
  }
  if (parent === 'receivable-invoice') {
    return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Pay" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              setOpenPayment(true);
            }}
          >
            <MdPayments size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Invoice as Pdf" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/receivable/invoice-generate`)}
          >
            <GoFilePdf size={14} />
          </StyledIconButton>
        </Tooltip>
      </Box>
    );
  }
  if (parent === 'receivable-multiple-file') {
    return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            // onClick={(e) => navigate(`/receivable/view`)}
          >
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
      </Box>
    );
  }
  if (parent === 'payable') {
    return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Invoice as Pdf" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate('/payable/invoice')}
          >
            <GoFilePdf size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/payable/view`)}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Pay" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              setOpenPayment(true);
            }}
          >
            <MdPayments size={14} />
          </StyledIconButton>
        </Tooltip>
      </Box>
    );
  }
  if (parent === 'prospect') {
    return (
      <>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'DELETE' && pageElement.isEnable === true ? (
                <Tooltip title="Delete" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    {/* <Icon icon={trash2Outline} width={14} onClick={(e) => handleDelete(e, rowId)} /> */}
                    <Icon
                      icon={trash2Outline}
                      width={14}
                      onClick={(e) => handleOpenDelete(e, rowId)}
                    />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'VIEW' && pageElement.isEnable === true ? (
                <Tooltip
                  title="View"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    localStorage.setItem('prospectLeadId', rowId);
                    navigate(`/${parent}/view`);
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <AiFillEye size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
        &nbsp;
      </>
    );
  }
  if (parent === 'master') {
    return (
      <>
        <Tooltip title="Delete" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View" placement="top" arrow onClick={(e) => navigate(`/${parent}/add`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'agreement') {
    return (
      <>
        <Tooltip
          title="View &#38; Edit"
          placement="top"
          arrow
          onClick={(e) => {
            localStorage.setItem('viewAgreementId', rowId);
            localStorage.removeItem('reviewData');
            localStorage.removeItem('commisssionType');
            navigate(`/${parent}/view`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Add Policy"
          placement="top"
          arrow
          onClick={(e) => {
            localStorage.removeItem('reviewData');
            localStorage.removeItem('commisssionType');
            localStorage.setItem('viewAgreementId', rowId);
            localStorage.setItem(
              'bankNameDetails',
              JSON.stringify({
                bankName: bankName,
                bankId: bankId
              })
            );
            navigate(`/${parent}/policy`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiOutlineFileAdd size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'add-branch-agreement') {
    return (
      <>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          // onClick={(e) => {
          //   setBranchEdit(true);
          // }}
          onClick={(e) => branchEditDialogOpen(e, itemBranchIndex, branchIndex)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top" arrow onClick={(e) => setDeleteConfirm(true)}>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'individual-agreement') {
    return (
      <>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          // onClick={(e) => setIndividualCommissionEdit(true)}
          onClick={(e) => individualEditDialogOpen(e, itemList, indexValue)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top" arrow onClick={(e) => setDeleteConfirm(true)}>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'volume-agreement') {
    return (
      <>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          onClick={(e) => {
            // setVolumeCommissionEdit(true);
            volumeEditDialogOpen(e, itemListVolume, indexValueVolume);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top" arrow onClick={(e) => setDeleteConfirm(true)}>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'journey') {
    return (
      <>
        <Tooltip title="Edit" placement="top" arrow onClick={(e) => navigate(`/${parent}/edit`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Publish"
          placement="top"
          arrow
          onClick={(e) => navigate(`/${parent}/publish`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdPublishedWithChanges size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="top"
          arrow
          // onClick={(e) => navigate(`/${parent}/view`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View" placement="top" arrow onClick={(e) => navigate(`/`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'journey-template') {
    return (
      <>
        <Tooltip title="Copy" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <RiFileCopyLine size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View"
          placement="top"
          arrow
          // onClick={(e) => navigate(`/${parent}/view`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  // if (parent === 'payable') {
  //   return (
  //     <Box sx={{ display: 'flex' }}>
  //       <Tooltip title="Invoice as Pdf" placement="top" arrow>
  //         <StyledIconButton className="squareIconButton actionButton">
  //           <GoFilePdf size={14} />
  //         </StyledIconButton>
  //       </Tooltip>
  //       <Tooltip title="View" placement="top" arrow>
  //         <StyledIconButton
  //           className="squareIconButton actionButton"
  //           onClick={(e) => navigate(`/payable`)}
  //         >
  //           <FiEye size={14} />
  //         </StyledIconButton>
  //       </Tooltip>
  //       <Tooltip title="Pay" placement="top" arrow>
  //         <StyledIconButton
  //           className="squareIconButton actionButton"
  //           onClick={(e) => navigate(`/payable`)}
  //         >
  //           <MdPayments size={14} />
  //         </StyledIconButton>
  //       </Tooltip>
  //     </Box>
  //   );
  // }
  if (parent === 'users') {
    return (
      <>
        <Tooltip title="Delete" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          onClick={(e) => navigate(`/${parent}/edit/${rowId}`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdModeEdit size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'customer') {
    return (
      <>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'DELETE' && pageElement.isEnable === true ? (
                <Tooltip title="Delete" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <Icon icon={trash2Outline} width={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'EDIT' && pageElement.isEnable === true ? (
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <MdModeEdit size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
        &nbsp;
      </>
    );
  }
  if (parent === 'partner') {
    return (
      <>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'DELETE' && pageElement.isEnable === true ? (
                <Tooltip title="Delete" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <Icon icon={trash2Outline} width={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'EDIT' && pageElement.isEnable === true ? (
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <MdModeEdit size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
        &nbsp;
      </>
    );
  }
  return (
    <>
      <Tooltip title="Delete" placement="top" arrow>
        <StyledIconButton className="squareIconButton actionButton">
          <Icon icon={trash2Outline} width={14} />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Edit" placement="top" arrow>
        <StyledIconButton className="squareIconButton actionButton">
          <Icon icon={editFill} width={14} />
        </StyledIconButton>
      </Tooltip>
    </>
  );
}
