import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Box className="footerBox">
      <Typography variant="body2" className="footerContent">
        Copyright © 2022 &nbsp;
        {/* <a href="https://www.realvalue.loans/" target="_blank">
          Real Value
        </a> */}
        {/* <a href="https://www.lessemi.com/lessEMI" target="_blank">
          LessEMI
        </a> */}
        {/* <a href="http://kalolytic.com/" target="_blank">
          Kalolytic
        </a> */}
        <a href="https://www.realvalue.loans/" target="_blank">
          Real Value Finloans
        </a>
        . All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
