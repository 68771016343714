import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------
console.log(faker);
const users = [...Array(24)].map((_, index) => ({
  id: sample([
    '	183100	',
    '	180327	',
    '	181547	',
    '	180361	',
    '	176709	',
    '	180166	',
    '	183365	',
    '	182558	',
    '	184863	',
    '	182424	',
    '	188901	',
    '	178224	',
    '	186838	',
    '	178679	',
    '	184380	',
    '	182960	',
    '	176891	',
    '	186164	',
    '	190142	',
    '	179988	',
    '	187922	',
    '	186869	',
    '	181745	',
    '	176954	'
  ]),
  leadId: sample([
    '	183100	',
    '	180327	',
    '	181547	',
    '	180361	',
    '	176709	',
    '	180166	',
    '	183365	',
    '	182558	',
    '	184863	',
    '	182424	',
    '	188901	',
    '	178224	',
    '	186838	',
    '	178679	',
    '	184380	',
    '	182960	',
    '	176891	',
    '	186164	',
    '	190142	',
    '	179988	',
    '	187922	',
    '	186869	',
    '	181745	',
    '	176954	'
  ]),
  avatarUrl: mockImgAvatar(index + 1),
  name: sample([
    '	Jagdish Joshi	',
    '	Kumar Sani	',
    '	sandeep	',
    '	Radhakrushna Sabat	',
    '	Puttaswamy Gowda	',
    '	Shaike Basheer	',
    '	Priyanka Shanmugam	',
    '	chandrashekara B	',
    '	Anil Kumar Parnami	',
    '	Murali Dharan	',
    '	Jignesh Ondhiya	',
    '	Karthick	',
    '	Naser K Cmk	',
    '	David Sasi	',
    '	Vipin mathew	',
    '	Deepak Sonar	',
    '	Rabiya Begum	',
    '	Abhinav Sharma	',
    '	Joyal. S.Japson	',
    '	Vinod C	',
    '	Piyush garodhara	',
    '	Sham Jagtap	',
    '	Dhirendra Singh Bhadoriya	',
    '	Rakesh Kumar Yadav	'
  ]),
  name2: faker.name.findName(),
  name3: faker.name.findName(),
  randomPhoneNumber: sample([
    '	919799825967	',
    '	919861591966	',
    '	919009383062	',
    '	919861102145	',
    '	919964623596	',
    '	918056797253	',
    '	919481089601	',
    '	917760868667	',
    '	918072854359	',
    '	919898531604	',
    '	919841727187	',
    '	918891280284	',
    '	919500767210	',
    '	919447038434	',
    '	919823317142	',
    '	918792312094	',
    '	918968833226	',
    '	919567072576	',
    '	919497654475	',
    '	918401004248	',
    '	919822531988	',
    '	919990220330	',
    '	919327307193	',
    '	917976405996	'
  ]),
  users: faker.helpers.userCard(),
  source: sample(['	Facebook	', '	Instagram	', '	Email	', '	Google	']),
  city: sample([
    '	Udaipur	',
    '	Cuttack	',
    '	Saru	',
    '	Saru	',
    '	Bangalore	',
    '	Ranipet	',
    '	Lalgudi	',
    '	Mysore	',
    '	Bangalore	',
    '	Coimbatore	',
    '	Rajkot	',
    '	Chennai	',
    '	Tirurangadi	',
    '	Coimbatore	',
    '	Nasik	',
    '	Nasik	',
    '	Bengaluru	',
    '	Chandigarh	',
    '	Thiruvananthapuram	',
    '	Thrissur	',
    '	Jamnagar	',
    '	Jalgaon	',
    '	Kanpur	',
    '	Jaipur	'
  ]),
  completion: sample(['30%', '50%', '70%', '100%']),
  company: faker.company.companyName(),
  category: faker.database.engine(),
  created: sample([
    '	Mon Feb 21 2022 13:38:57 GMT+0530 (India Standard Time)	',
    '	Mon Feb 18 2022 13:38:57 GMT+0530 (India Standard Time)	',
    '	Mon Feb 20 2022 13:38:57 GMT+0530 (India Standard Time)	',
    '	Mon Feb 17 2022 13:38:57 GMT+0530 (India Standard Time)	',
    ' Mon Feb 19 2022 13:38:57 GMT+0530 (India Standard Time) '
  ]),
  createdBy: sample([
    '	Amit Dungrani	',
    '	Avanish Singh	',
    '	Nivriti Raj	',
    '	Ritesh Bhagat	',
    '	Tarun gupta	',
    '	Riya	',
    '	Madhuri	',
    '	Deepali	',
    '	Preeti	'
  ]),
  modifiedOn: faker.date.past(),
  amount: sample([
    '50,00,000',
    '70,00,000',
    '80,00,000',
    '85,00,000',
    '75,00,000',
    '95,00,000',
    '100,00,000'
  ]),
  amount2: faker.finance.amount(),
  amount3: faker.finance.amount(),
  routingNumber: faker.finance.routingNumber(),
  transactionType: sample(['sbi', 'hdfc', 'idfc', 'scb', 'rbl', 'city', 'yml']),
  bankName: sample([
    'HDFC',
    'Kotak',
    'India Bulls',
    'Bajaj Finance',
    'ICICI',
    'Piramal Finance',
    'L&T Finance',
    'IDBI',
    'SBI Home'
  ]),
  isVerified: faker.datatype.boolean(),
  status: sample(['Assigned', 'Not Assigned']),
  statusCallCenter: sample(['Follow Up', 'Lead', 'Not Doable', 'Not interested', 'Do not contact']),
  statusLeads: sample([
    'New',
    'Contacted',
    'Not Interested',
    'Not Contactable',
    'Partial Complete',
    'Completed',
    'Submitted'
  ]),
  statusLogin: sample([
    'U-Log in',
    'Log in ',
    'U-Sanction',
    'Sanctioned',
    'U-Disbursement',
    'Disbursed',
    'Closed',
    'Query',
    'Rejected'
  ]),
  partnerName: sample([
    'Synergy Loans',
    'Hifi Fintech',
    'Qucik Loans',
    'Sumit Associates',
    'Kartik Broker',
    'Sumit Agarwal',
    'Sameet Thakur',
    'Sunil Bhadoria',
    'Aryan Traders'
  ]),
  active: sample(['Yes', 'No']),
  leadSource: sample(['Internal', 'External']),
  noofcalls: sample(['1', '2', '3', '4', '5']),
  interestRate: sample(['6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10']),
  processingFees: sample(['5999', '4999', '3999', '6499', '3499', '5499', '4499']),
  loanType: sample(['Home loan', 'LAP', 'Balance transfer', 'Education Loan', 'Personal Loan']),
  loanSubType: sample(['LAP', 'Balance transfer', 'Fresh Loan']),
  partnerType: sample(['Individual', 'Company']),
  applicationType: sample(['Customer', 'Sales', 'Refferal']),
  segment: sample(['Leads', 'Influencer', 'Coordinator']),
  role: sample([
    'Leader',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer'
  ]),
  roleLavel: sample(['Admin', 'Super Admin', 'User', 'Client', 'Freelancer']),
  invoiceNo: sample([
    '101',
    '102',
    '103',
    '104',
    'New',
    '105',
    '106',
    '107',
    '108',
    '109',
    'New',
    '110',
    '111',
    '112',
    '113',
    '114',
    '115',
    '116',
    '117',
    '118',
    'New',
    '119',
    '120'
  ]),
  statusInvoice: sample(['Invoice Raised', 'Payment Received', 'Payment Released']),
  journeyName: sample(['Journey Name1', 'Journey Name2', 'Journey Name3']),
  templateName: sample(['Template Name1', 'Template Name2', 'Template Name3']),
  employmentType: sample(['Salaried', 'Business', 'Self Employed']),
  reason: sample(['Income Mis match', 'Credit Score Issues']),
  documentList: sample(['Aadhar Card', 'Pan Card', 'Salary Slip', 'ITR']),
  verifiedStatus: sample(['Verified', 'Not Verified']),
  misStatus: sample(['Matched', 'Not Matched']),
  payoutStatus: sample(['MIS Matched', 'Invoice', 'Payment Due', 'Paid', 'MIS Not Matched']),
  receivableStatus: sample(['Pending', 'Paid']),
  statusPayable: sample(['Payment Due', 'Paid']),
  dueDays: sample(['4 Days', '6 Days', '7 Days', '5 Days', '3 Days']),
  monthName: sample([
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ])
}));

export default users;
