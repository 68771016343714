import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// table component
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { MdOutlinePreview, MdUploadFile, MdUpload, MdOutlineAdd } from 'react-icons/md';
import { UserMoreMenu } from '../../components/_dashboard/user';

function IndividualCommission({
  StyledTableCell,
  StyledTableRow,
  setOpen2,
  setValidationMsg2,
  individualArray,
  individualArrayList,
  setIndividualArrayList,
  loanTypesList,
  setRefreshComp,
  individualCommission,
  setIndividualCommission,
  branches,
  setDeleteConfirm,
  setIndividualCommissionEdit,
  individualEditDialogOpen
}) {
  const handleAddIndividual = (e) => {
    if (individualCommission.loanType === ' ') {
      setOpen2(true);
      setValidationMsg2('Please select loan type');
      return;
    }
    if (individualCommission.branch === ' ') {
      setOpen2(true);
      setValidationMsg2('Please select branch');
      return;
    }
    if (individualCommission.commission === '') {
      setOpen2(true);
      setValidationMsg2('Please enter commission %');
      return;
    } else if (individualCommission.commission > 100) {
      setOpen2(true);
      setValidationMsg2('Please enter valid commission %');
      setIndividualCommission({
        ...individualCommission,
        commission: ''
      });
      return;
    }

    const prevArray = individualCommission;
    prevArray.isIndividualCommissionType = true;
    setIndividualCommission(prevArray);

    const newArray = individualArrayList;
    newArray.push(prevArray);
    setIndividualArrayList(newArray);
    console.log('setIndividualArrayList', newArray);
    setIndividualCommission({
      loanType: ' ',
      branch: ' ',
      commission: '',
      isIndividualCommissionType: null
    });
    setRefreshComp(new Date());

    // const newArray = individualArrayList;
    // newArray.push(individualCommission);
    // setIndividualArrayList(newArray);
    // console.log('setIndividualArrayList', newArray);
    // setIndividualCommission({
    //   loanType: ' ',
    //   branch: ' ',
    //   commission: ''
    // });
    // setRefreshComp(new Date());
  };

  return (
    <Box className="">
      <Box className="viewLeadCard" mb={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Loan Type
              </Typography>
              <Select
                fullWidth
                value={individualCommission.loanType}
                // value={individualCommission.loanTypeId}
                onChange={(e) => {
                  const prevArray = individualCommission;
                  prevArray.loanType = e.target.value;
                  // prevArray.loanTypeId = e.target.value;
                  setIndividualCommission(prevArray);
                  setRefreshComp(new Date());
                }}
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${individualCommission.loanType}`
                }}
              >
                <MenuItem value=" " disabled>
                  Select Loan Type
                </MenuItem>
                {loanTypesList.length > 0
                  ? loanTypesList.map((item, index) => {
                      console.log(item);
                      return (
                        // <MenuItem value={item.loanId} key={index}>
                        //   {item.loanName}
                        // </MenuItem>
                        <MenuItem value={JSON.stringify(item)} key={index}>
                          {item.loanName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Branch
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${individualCommission.branch}`
                }}
                value={individualCommission.branch}
                // value={individualCommission.branchId}
                onChange={(e) => {
                  const prevArray = individualCommission;
                  prevArray.branch = e.target.value;
                  // prevArray.branchId = e.target.value;
                  setIndividualCommission(prevArray);
                  setRefreshComp(new Date());
                }}
              >
                <MenuItem value=" " disabled>
                  Select Branch
                </MenuItem>
                {branches !== undefined && branches.length > 0
                  ? branches.map((item, index) => {
                      console.log(item);
                      return (
                        // <MenuItem value={item.branchId}>{item.branchName}</MenuItem>
                        <MenuItem value={JSON.stringify(item)} key={index}>
                          {item.branchName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Commission %
              </Typography>
              <TextField
                type="number"
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Commission %"
                value={individualCommission.commission}
                // value={individualCommission.aggrementCommissionPercentage}
                onChange={(e) => {
                  const prevArray = individualCommission;
                  prevArray.commission = e.target.value;
                  // prevArray.aggrementCommissionPercentage = e.target.value;
                  setIndividualCommission(prevArray);
                  setRefreshComp(new Date());
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box textAlign="right">
          <Button
            className="mainBtn otherBtn"
            variant="contained"
            onClick={(e) => handleAddIndividual(e)}
          >
            Add Commission
          </Button>
        </Box>

        {/* {individualArray.map((item, index) => (
          <Grid container spacing={3} alignItems="end">
            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Loan Type
                    </Typography>
                    <Select
                      fullWidth
                      // value={selectLoan}
                      // onChange={(e) => setSelectLoan(e.target.value)}
                      value={item.loanType}
                      onChange={(e) => {
                        const prevArray = individualArray;
                        prevArray[index].loanType = e.target.value;
                        setIndividualArray(prevArray);
                        setRefreshComp(new Date());
                      }}
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${item.loanType}`
                      }}
                    >
                      <MenuItem value=" " disabled>
                        Select Loan Type
                      </MenuItem>
                      <MenuItem value="Home Loan">Home Loan</MenuItem>
                      <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                      <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                      <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                      <MenuItem value="Education Loan">Education Loan</MenuItem>
                      <MenuItem value="Credit Card">Credit Card</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Branch
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${item.branch}`
                      }}
                      // value={selectBranch}
                      // onChange={(e) => setSelectBranch(e.target.value)}
                      value={item.branch}
                      onChange={(e) => {
                        const prevArray = individualArray;
                        prevArray[index].branch = e.target.value;
                        setIndividualArray(prevArray);
                        setRefreshComp(new Date());
                      }}
                    >
                      <MenuItem value=" " disabled>
                        Select Branch
                      </MenuItem>
                      <MenuItem value="HDFC">HDFC</MenuItem>
                      <MenuItem value="ICICI">ICICI</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Commission %
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Commission %"
                      // value={commission}
                      // onChange={(e) => setCommission(e.target.value)}
                      value={item.commission}
                      onChange={(e) => {
                        const prevArray = individualArray;
                        prevArray[index].commission = e.target.value;
                        setIndividualArray(prevArray);
                        setRefreshComp(new Date());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
                <IconButton className="squareIconButton" onClick={(e) => handleAddIndividual(e)}>
                  <MdOutlineAdd size={18} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ))} */}
      </Box>
      <Box className="greenBorder tableBox">
        <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: '' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Loan Type</StyledTableCell>
                <StyledTableCell align="">Branch</StyledTableCell>
                <StyledTableCell align="">Commission %</StyledTableCell>
                <StyledTableCell align="" width={80}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {individualArrayList &&
                individualArrayList.map((item, index) => {
                  console.log(item);
                  return (
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        {JSON.parse(item.loanType).loanName}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">{item.branch}</StyledTableCell> */}
                      <StyledTableCell align="left">
                        {JSON.parse(item.branch).branchName}
                      </StyledTableCell>
                      <StyledTableCell align="left">{item.commission}</StyledTableCell>
                      <StyledTableCell align="">
                        <UserMoreMenu
                          parent="individual-agreement"
                          setIndividualCommissionEdit={setIndividualCommissionEdit}
                          setDeleteConfirm={setDeleteConfirm}
                          itemList={item}
                          indexValue={index}
                          individualEditDialogOpen={individualEditDialogOpen}
                        />
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">
                        {JSON.parse(item.loanTypeId).loanName}
                      </StyledTableCell>
                      <StyledTableCell align="left">{item.branchId}</StyledTableCell>
                      <StyledTableCell align="left">
                        {item.aggrementCommissionPercentage}
                      </StyledTableCell> */}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default IndividualCommission;
