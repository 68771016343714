import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses,
  Tabs,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  FormControl,
  TextField,
  selectDate,
  DialogActions,
  Tab
} from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';
// styling
import { StyledTableRow, StyledTableCell } from '../Global/Styling';
import ReceivableFileToolbar from './ReceivableFileToolbar';
import ReceivableInvoiceToolbar from './ReceivableInvoiceToolbar';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'bankName', label: 'Bank Name', alignRight: false },
  { id: 'id', label: 'File No', alignRight: false },
  { id: 'name', label: 'Borrower Name', alignRight: false },
  { id: 'bankName', label: 'Branch Name', alignRight: false },
  { id: 'role', label: 'Emp Class', alignRight: false },
  { id: 'created', label: 'Disb Date', alignRight: false },
  { id: 'amount', label: 'Disb Amount', alignRight: false },
  { id: 'loanType', label: 'Loan Product Type', alignRight: false },
  { id: 'invoiceNo', label: 'Invoice No', alignRight: false },
  { id: '', label: 'ACTION' }
];
const TABLE_HEAD_INVOICE = [
  { id: 'invoiceNo', label: 'Invoice No', alignRight: false },
  { id: 'created', label: 'Invoice Date', alignRight: false },
  { id: 'bankName', label: 'Bank', alignRight: false },
  { id: 'city', label: 'Branch', alignRight: false },
  { id: 'amount', label: 'Net Amount', alignRight: false },
  { id: 'amount', label: 'Total GST', alignRight: false },
  { id: 'amount', label: 'Partner Payout', alignRight: false },
  { id: 'name', label: 'Partner NAME', alignRight: false },
  { id: 'created', label: 'Due Date', alignRight: false },
  { id: 'dueDays', label: 'Due Past Dues', alignRight: false },
  { id: 'receivableStatus', label: 'Status', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function Receivable() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [openPayment, setOpenPayment] = useState(false);
  const [selectDate, setSelectDate] = useState('27 June, 2022');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'bold',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px'
    }
  }));
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Partner Management System || Receivable">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Receivable List" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Tabs
            value={value}
            onChange={handleChange}
            className="tabOuter"
            aria-label="basic tabs example"
            classes={{ indicator: 'tabIndicator' }}
          >
            <Tab
              label="Files"
              {...a11yProps(0)}
              classes={{ selected: 'tabSelected', root: 'tabDefault' }}
            />
            <Tab
              label="Invoice"
              {...a11yProps(1)}
              classes={{ selected: 'tabSelected', root: 'tabDefault' }}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <ReceivableFileToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="receivable"
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          routingNumber,
                          invoiceNo,
                          id,
                          name,
                          leadId,
                          amount,
                          company,
                          role,
                          noofcalls,
                          name2,
                          city,
                          bankName,
                          partnerName,
                          transactionType,
                          status,
                          segment,
                          active,
                          created,
                          partnerType,
                          loanType,
                          statusInvoice,
                          verifiedStatus,
                          monthName,
                          users
                        } = row;
                        console.log('users', users);
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{bankName}</StyledTableCell>
                            <StyledTableCell align="left">{id}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{role}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="left">
                              <RouterLink to="/receivable/invoice-generate">{invoiceNo}</RouterLink>
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <UserMoreMenu rowId={id} parent="receivable-file" />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReceivableInvoiceToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="receivable"
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD_INVOICE}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          routingNumber,
                          invoiceNo,
                          id,
                          name,
                          leadId,
                          amount,
                          amount2,
                          amount3,
                          name1,
                          name2,
                          role,
                          city,
                          bankName,
                          partnerName,
                          transactionType,
                          status,
                          segment,
                          active,
                          created,
                          partnerType,
                          loanType,
                          statusInvoice,
                          dueDays,
                          receivableStatus,
                          users
                        } = row;
                        console.log('users', users);
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{invoiceNo}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{bankName}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{dueDays}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (receivableStatus === 'Pending' && 'error') ||
                                  (receivableStatus === 'Paid' && 'success') ||
                                  'success'
                                }
                              >
                                {sentenceCase(receivableStatus)}
                              </Label>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <UserMoreMenu
                                rowId={id}
                                parent="receivable-invoice"
                                setOpenPayment={setOpenPayment}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            {/* payment pop up start */}
            <Dialog open={openPayment} onClose={(e) => setOpenPayment(false)} fullWidth>
              <DialogTitle className="popupTitle">
                <Typography variant="h6">Receivable</Typography>
                <IconButton onClick={(e) => setOpenPayment(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Received On
                      </Typography>
                      <TextField
                        fullWidth
                        type="date"
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={selectDate}
                        onChange={(e) => setSelectDate(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Payment Mode
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value="xxxxx"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Net Amount Received
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value="00000"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        GST Charged
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value="0000"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        TDS Deduction
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value="0000"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Net Amount for Commision
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value="00000"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={(e) => setOpenPayment(false)}
                  variant="contained"
                  className="mainBtn"
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            {/* payment pop up end */}
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '1rem' }}>
          <Card>{children}</Card>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
