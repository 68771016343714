import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';

function ErrorDialog({ errorOpen, setErrorOpen }) {
  return (
    <Box>
      <Dialog open={errorOpen} onClose={(e) => setErrorOpen(false)} maxWidth="sm">
        {/* <DialogTitle className="loader-title">Please wait!</DialogTitle> */}
        <DialogContent sx={{ textAlign: 'center' }}>
          <Typography mt={1} mb={2}>
            Internal Server Error, Please try again later!
          </Typography>
          <Button onClick={(e) => setErrorOpen(false)}>Close</Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export default ErrorDialog;
