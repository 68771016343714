import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import dashboard from '@iconify/icons-ic/dashboard';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-ic/card-membership';
import access from '@iconify/icons-ic/lock-open';
import fileExclamationFilled from '@iconify/icons-ant-design/file-exclamation-filled';
import { FaUserTie, FaFileInvoiceDollar } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';
import { HiUserGroup } from 'react-icons/hi';
import { BsNewspaper } from 'react-icons/bs';
import { GiJourney } from 'react-icons/gi';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GroupIcon from '@mui/icons-material/Group';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import GavelIcon from '@mui/icons-material/Gavel';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LoginIcon from '@mui/icons-material/Login';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SnowboardingIcon from '@mui/icons-material/Snowboarding';
import HandshakeIcon from '@mui/icons-material/Handshake';
// import { IoNewspaperSharp } from 'react-icons/io';
import { JourneyIcon } from '../../vector/index';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Prospect',
    path: '/prospect',
    icon: <PermContactCalendarIcon />,
    pageCode: 'PROSPECT_PAGE'
  },
  {
    title: 'Call center',
    path: '/call-center',
    icon: <SupportAgentIcon />,
    pageCode: 'CALL_CENTER_PAGE'
  },
  {
    title: 'Leads',
    path: '/leads',
    icon: getIcon(peopleFill),
    pageCode: 'LEADS_PAGE'
  },
  {
    title: 'Log In',
    path: '/login',
    icon: <LoginIcon />,
    pageCode: 'LOG_IN_PAGE'
  },
  {
    title: 'Revenue',
    path: '/revenue',
    icon: <CurrencyRupeeIcon />,
    pageCode: 'REVENUE_PAGE'
  },
  {
    title: 'Payout',
    path: '/payout',
    icon: <CurrencyRupeeIcon />,
    pageCode: 'PAYOUT_PAGE'
  },
  {
    title: 'Receivable',
    path: '/receivable',
    icon: <CurrencyRupeeIcon />
  },
  {
    title: 'Payable',
    path: '/payable',
    icon: <CurrencyRupeeIcon />
  },
  {
    title: 'Rejected',
    path: '/rejected',
    icon: <ThumbDownAltIcon />,
    pageCode: 'REJECTED_PAGE'
  },
  {
    title: 'On Boarding',
    path: '/onboarding',
    icon: <SnowboardingIcon />,
    pageCode: 'ONBOARDING_PAGE'
  },
  {
    title: 'Agreement',
    path: '/agreement',
    icon: <GavelIcon />,
    pageCode: 'AGREEMENT_PAGE'
  },
  {
    title: 'Partners',
    path: '/partners',
    icon: <HandshakeIcon />,
    pageCode: 'PARTNER_PAGE'
  },
  {
    title: 'Customer',
    path: '/customer',
    icon: <IoIosPeople fontSize="1.5rem" />,
    pageCode: 'CUSTOMER_PAGE'
  },
  {
    title: 'Journey',
    path: '/journey',
    icon: <GiJourney fontSize="1.5rem" />,
    pageCode: 'JOURNEY_PAGE'
  },
  // {
  //   title: 'Admin',
  //   path: '/admin',
  //   icon: getIcon(peopleFill)
  // }
  {
    title: 'Integration',
    path: '/integration',
    icon: <IntegrationInstructionsIcon />,
    pageCode: 'INTEGRATION_PAGE'
  },
  {
    title: 'Master',
    path: '/master',
    icon: <DomainVerificationIcon />,
    pageCode: 'MASTER_PAGE'
  },
  {
    title: 'Users',
    path: '/users',
    icon: <GroupIcon />,
    pageCode: 'USER_PAGE'
  }
];

export default sidebarConfig;
