import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import faker from 'faker';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, Link, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  IconButton,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
//
import { fDate } from '../../utils/formatTime';
// styling
import { StyledTableRow, StyledTableCell } from '../Global/Styling';
import AgreementToolbar from './AgreementToolbar';
import PolicyToolbar from './PolicyToolbar';
import Loader from '../Global/Loader';

import AgreementService from '../services/AgreementService';

const { AgreementpolicyList } = AgreementService;

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'id', label: 'Policy ID', alignRight: false },
  { id: 'name', label: 'Policy Name', alignRight: false },
  { id: 'loanType', label: 'Loan Type', alignRight: false },
  { id: 'employmentType', label: 'Employment Type', alignRight: false },
  { id: 'interestRate', label: 'Interest Rate', alignRight: false },
  { id: 'processingFees', label: 'Processing Fee', alignRight: false },
  { id: 'created', label: 'Start Date', alignRight: false },
  { id: 'created', label: 'End Date', alignRight: false }
  // { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function AgreementPolicy(parent) {
  console.log('pathhh', parent);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(600);
  const [policyListData, setPolicyListData] = useState([]);
  const [open, setOpen] = useState(false);
  const [agreementId, setAgreementId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [userId, setUserId] = useState('');
  const [openLoader, setOpenLoader] = useState(false); // loader

  console.log('policyListData', policyListData);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = policyListData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - policyListData.length) : 0;

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'bold',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px'
    }
  }));

  const handleCloseA = () => {
    setOpen(false);
    navigate(`/agreement/add-policy`);
  };

  useEffect(async () => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const authToken = localJWTtoken.token.jwt;
    // fireagreementlistapi(authToken);
    setCompanyId(localJWTtoken.token.companyId);
    setUserId(localJWTtoken.token.userId);
    const localJWTtoken2 = JSON.parse(localStorage.getItem('viewAgreementId'));
    setAgreementId(localJWTtoken2);

    const cretentials = {
      agreementId: localJWTtoken2,
      companyId: localJWTtoken.token.companyId,
      entityListRequestModel: {
        pageNumber: page,
        pageSize: rowsPerPage,
        searchLiteral: filterName,
        sortOrder: order
      },
      userId: localJWTtoken.token.userId
    };
    setOpenLoader(true);
    const ifPreviewDataBank = await AgreementpolicyList(cretentials, authToken);
    if (ifPreviewDataBank.status === 'success') {
      setOpenLoader(false);
      if (ifPreviewDataBank.data.policies.length > 0) {
        setPolicyListData(ifPreviewDataBank.data.policies);
        console.log('ifPreviewDataBank.data.policies', ifPreviewDataBank.data.policies);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  }, [filterName, rowsPerPage, page]);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setRowsPerPage(600);
    setPage(1);
  };

  // const fireagreementlistapi = async (authToken) => {
  //   const cretentials = {
  //     userId: userId,
  //     companyId: companyId,
  //     agreementId: agreementId
  //   };
  //   const ifPreviewDataBank = await AgreementpolicyList(cretentials, authToken);
  //   if (ifPreviewDataBank.status === 'success') {
  //     setPolicyListData(ifPreviewDataBank.data);
  //   } else {
  //     setOpen(true);
  //   }
  // };

  return (
    <Page title="Partner Managemet System || Agreement">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Policy" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card>
            <PolicyToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="agreement"
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={policyListData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    // isCheckBox="true"
                  />
                  <TableBody>
                    {policyListData &&
                      policyListData.map((item, index) => {
                        console.log(item);
                        const isItemSelected = selected.indexOf(item) !== -1;
                        return (
                          <StyledTableRow
                            hover
                            key={item.id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell> */}

                            <StyledTableCell align="left">{item.aggrementPolicyId}</StyledTableCell>
                            <StyledTableCell align="left">
                              {item.aggrementPolicyName}
                            </StyledTableCell>
                            <StyledTableCell align="left">{item.loanTypeName}</StyledTableCell>
                            <StyledTableCell align="left">
                              {item.employmentTypeName}
                            </StyledTableCell>
                            <StyledTableCell align="left">{item.interestRateMax}</StyledTableCell>
                            <StyledTableCell align="left">
                              {item.preProcessingCharge}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {fDate(item.policyStartDate)}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {fDate(item.policyEndDate)}
                            </StyledTableCell>
                            {/* <StyledTableCell align="" width={90}>
                              <UserMoreMenu rowId={id} parent="agreement" />
                            </StyledTableCell> */}
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {policyListData.length === 0 && (
                    <>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>

                      <Dialog
                        className="PopupBox"
                        open={open}
                        onClose={handleCloseA}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle
                          id="alert-dialog-title"
                          className="popupHeading"
                          style={{
                            borderBottom: 'none',
                            paddingBottom: '0px',
                            marginBottom: '0px'
                          }}
                        >
                          <Typography variant="h6" className="popupTitle">
                            Policies Not Found
                          </Typography>
                          <IconButton onClick={handleCloseA} size="small">
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent
                          dividers
                          className="PopupContent"
                          style={{ marginBottom: '10px' }}
                        >
                          <Typography variant="body2">
                            If u want to add new policy then click Add New Policy button below.
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleCloseA}
                            className="mainBtn"
                            style={{ padding: '8px 15px' }}
                          >
                            Add New Policy
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Card>
          <Loader openLoader={openLoader} />
        </Box>
      </Container>
    </Page>
  );
}
