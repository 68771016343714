import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputBase,
  Stack,
  TextField,
  Typography,
  List,
  ListItem,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { HiDocumentText, HiOutlineIdentification } from 'react-icons/hi';
import { ImCross } from 'react-icons/im';
import { TiUserOutline } from 'react-icons/ti';
import { MdOutlineBusiness } from 'react-icons/md';
import { RiGalleryUploadLine } from 'react-icons/ri';
import { BsShieldFillCheck, BsPatchCheck } from 'react-icons/bs';
import { LibraryAddCheckIcon } from '@mui/icons-material/LibraryAddCheck';
import { AiOutlineCloudUpload, AiOutlineClose } from 'react-icons/ai';
import CloseIcon from '@mui/icons-material/Close';
// import { Player } from 'video-react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css
// /video-react/dist/video-react.css
import partnerService from './services/partnerService';

const { PartnerTypes } = partnerService;

function SignUpSec() {
  // const [array, setArray] = useState({
  //   partnerTypes: [
  //     {
  //       typeId: 66,
  //       typeName: 'Individual',
  //       typeCode: 'INDIVIDUAL'
  //     },
  //     {
  //       typeId: 68,
  //       typeName: 'Company',
  //       typeCode: 'COMPANY'
  //     }
  //   ]
  // });
  const [signUpAuthToken, setSignUpAuthToken] = useState('');
  const navigate = useNavigate();
  const [secondary, setSecondary] = useState(false);
  const [signupSteps, setSignupSteps] = useState('verifyIdentity');
  const [verifySteps, setVerifySteps] = useState('verifyStepOne');
  const [photoPreview, setPhotoPreview] = useState(); // photo
  const [panPreview, setPanPreview] = useState(); // pan card
  const [aadharFrontPreview, setAadharFrontPreview] = useState(); // aadhaar card frontside
  const [aadharBackPreview, setAadharBackPreview] = useState(); // aadhaar card backside
  const [cancelChequePreview, setCancelChequePreview] = useState(); // cancel cheque
  const [uploadImageFile, setUploadImageFile] = useState(); // uploaded image file
  const [selectState, setSelectState] = useState(' ');
  const [selectCity, setSelectCity] = useState(' ');
  const [isActive, setActive] = useState('');
  const [selectDate, setSelectDate] = useState(' ');
  const [openAssign, setOpenAssign] = useState(false);
  // api state
  const [partnerTypesResponse, setPartnerTypesResponse] = useState('');
  const [typeId, setTypeId] = useState('');
  const toggleClass = (box) => {
    setActive(box);
  };
  const handleFileUpload = async (e, setPreview, files, setUploadImage) => {
    let file = '';
    if (files) {
      file = files;
    } else {
      file = e.target.files[0];
    }
    setUploadImage(file);
    setPreview(URL.createObjectURL(file));
  };
  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const fileDrop = (e, setPreview, setUploadImage) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log('e.dataTransfer.files', e.dataTransfer.files);
    handleFileUpload(e, setPreview, files[0], setUploadImage);
  };
  useEffect(() => {
    let cancel = false;
    const SignUpToken = JSON.parse(localStorage.getItem('guestUser'));

    const SignUpAuthToken = SignUpToken.token;
    setSignUpAuthToken(SignUpAuthToken);

    // partner type
    const PartnerTypesApi = async () => {
      const PartnerTypesResponse = await PartnerTypes(SignUpAuthToken);
      if (PartnerTypesResponse.status === 'success') {
        if (cancel) return;
        setPartnerTypesResponse(PartnerTypesResponse.data);
      }
    };
    PartnerTypesApi();
    return () => {
      cancel = true;
    };
  }, []);

  useEffect(async () => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      trackuserapi(getactivepage.routepage);
    }
  }, []);

  const gettokenApi = async (type) => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      getactivepage.routepage = type;
      localStorage.setItem('trackuser', JSON.stringify(getactivepage));
    }
  };

  const trackuserapi = async (active) => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      if (getactivepage.routepage === `${active}`) {
        navigate(`/${active}`, { replace: true });
      }
    }
  };

  console.log('typeid', signupSteps);
  return (
    <Box className="signUpOuter" sx={{ width: '100%', height: '100vh' }}>
      <Container maxWidth="fullWidth" disableGutters="true">
        <Stack>
          <Box className="signUpInner">
            <Box className="signUpLeft">
              <Box className="signUpleftInner">
                <Box>
                  <Typography variant="h6" className="signUpHeading">
                    <img
                      className="signUpLogo"
                      alt="Logo"
                      src="../../static/logos/RVPulselogoW.svg"
                    />
                  </Typography>
                  <Box className="signUpFormLeft mt-2">
                    <Box
                      className={
                        signupSteps === 'verifyIdentity'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Select User Type</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Select Your User Type for next step
                        </Typography>
                      </Box>
                      <Typography className="signUpFormIconOut">
                        <HiOutlineIdentification classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                    <Box
                      className={
                        signupSteps === 'personalDetails'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Personal Details</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Fill in Your Name, Mobile No and verify your phone
                        </Typography>
                      </Box>
                      <Typography className="signUpFormIconOut">
                        <TiUserOutline classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                    <Box
                      className={
                        signupSteps === 'uploadDocuments'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Upload Documents</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Upload your KYC documents
                        </Typography>
                      </Box>
                      <Typography className="signUpFormIconOut">
                        <RiGalleryUploadLine classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                    <Box
                      className={
                        signupSteps === 'results'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Business Agreement</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Agree to the terms &#38; conditions of the business agreement and submit
                          for activation of your id
                        </Typography>
                      </Box>
                      <Typography className="signUpFormIconOut">
                        <BsShieldFillCheck classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="signUpRight">
              <Box className="signUpRightInner">
                <Box className="signUpFormRight">
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" className="mb-3">
                        Select User Type
                      </Typography>
                      {/* <Typography variant="body2" className="mb-3">
                                (Select Your Partner Type for next step)
                              </Typography> */}
                      <Box className="displayFlex">
                        {/* <Box className="partnerTypeCard mb-3 mr-3"> */}
                        {partnerTypesResponse.partnerTypes !== undefined &&
                        partnerTypesResponse.partnerTypes.length > 0
                          ? partnerTypesResponse.partnerTypes.map((item, index) => (
                              <Box
                                key={index}
                                className={
                                  isActive === item.typeName
                                    ? 'partnerTypeCard mb-3 mr-3 active'
                                    : 'partnerTypeCard mb-3 mr-3'
                                }
                                onClick={(e) => {
                                  toggleClass(`${item.typeName}`);
                                  setTypeId(item.typeId);
                                  localStorage.setItem(
                                    'typeID',
                                    JSON.stringify({
                                      typeId: item.typeId
                                    })
                                  );
                                }}
                              >
                                <Typography variant="h6" className="partnerTypeIconO mb-2">
                                  {item.typeName === 'Individual' && (
                                    <TiUserOutline className="signUpFormIcon" fontSize={40} />
                                  )}
                                  {item.typeName === 'Company' && (
                                    <MdOutlineBusiness className="signUpFormIcon" fontSize={40} />
                                  )}
                                </Typography>
                                <Typography variant="subtitle1" className="partnerTypeText">
                                  {item.typeName}
                                </Typography>
                              </Box>
                            ))
                          : ''}
                        {/* <Box
                          className={
                            isActive === 'individual'
                              ? 'partnerTypeCard mb-3 mr-3 active'
                              : 'partnerTypeCard mb-3 mr-3'
                          }
                          onClick={(e) => toggleClass('individual')}
                        >
                          <Typography variant="h6" className="partnerTypeIconO mb-2">
                            <TiUserOutline classname="signUpFormIcon" fontSize={40} />
                          </Typography>
                          <Typography variant="subtitle1" className="partnerTypeText">
                            Individual
                          </Typography>
                        </Box>
                        <Box
                          className={
                            isActive === 'company'
                              ? 'partnerTypeCard mb-3 mr-3 active'
                              : 'partnerTypeCard mb-3 mr-3'
                          }
                          onClick={(e) => toggleClass('company')}
                        >
                          <Typography variant="h6" className="partnerTypeIconO mb-2">
                            <MdOutlineBusiness classname="signUpFormIcon" fontSize={40} />
                          </Typography>
                          <Typography variant="subtitle1" className="partnerTypeText">
                            Company
                          </Typography>
                        </Box> */}
                      </Box>
                      <Button
                        className="mainBtn"
                        // onClick={(e) => setVerifySteps('verifyStepTwo')}
                        onClick={(e) => {
                          gettokenApi(`signup-form/${isActive}`);
                          navigate(`/signup-form/${isActive}`);
                        }}
                      >
                        Next
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <img src="../static/applicatin.jpg" alt="application" />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

export default SignUpSec;
