import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
//
/**
 * @module AdminServiceService
 * @returns  it return All DataSource Apis
 */

const prospectService = {
  // List all propsect
  ProspectListApi: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/prospect/list`, credential, token), // TASK#6, TASK#7, TASK#9  // done api response pending

  // Fetch Prospect Status for filter
  ProspectListStageStatuses: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/prospect/list-stage-statuses`, credential, token), // TASK#8

  // View individual prospect
  ProspectView: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/prospect/view`, credential, token), // TASK#10

  // Delete prospect
  ProspectDelete: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/prospect/delete`, credential, token), // TASK#11

  // Bulk Upload the Prospect
  ProspectBulkUpload: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/prospect/bulk-upload`, credential, token), // TASK#12

  // Download "Bulk Upload" Template
  ProspectDownloadTemplate: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/prospect/download-template`, token), // TASK#13

  // Save Prospect to database
  ProspectCreate: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/prospect/create`, credential, token), // TASK#5  // done api response pending

  // assign leads
  AssignProspects: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/prospect/assign-prospects`, credential, token),

  // Employment Types
  EmploymentTypes: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/master/all-combined-employment-types`, token),

  // Property Configuration Type
  PropertyConfigurationType: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/master/property-configuration-type`, token)
};

export default prospectService;
