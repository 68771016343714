// ## Below are the API URLs for Partner and Offline web apps.:
// for PRODUCTION
export const globalAdminUrl = 'http://216.48.184.110:8080/lms-api';

// for PRODUCTION (Over HTTPS)
// export const globalAdminUrl = 'https://216.48.184.110:8443/lms-api';

// for DEVELOPMENT
// export const globalAdminUrl = 'http://164.52.218.84:8080/lms-api';

// for LOCALHOST
// export const globalAdminUrl = 'http://localhost:8080/lms-api';

// ##Below are the "Partner to Offline" web app. redirection links:
// for PRODUCTION
export const PARTNER_TO_OFFLINE_URL = 'http://164.52.208.46';

// for LOCALHOST
// export const PARTNER_TO_OFFLINE_URL = 'http://localhost:3001';

// for DEVELOPMENT
// export const PARTNER_TO_OFFLINE_URL = 'http://offline-prod.kalolytic.com';
