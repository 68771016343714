import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  TextareaAutosize,
  InputLabel,
  FormControl,
  IconButton
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { AiOutlineClose } from 'react-icons/ai';
import CloseIcon from '@mui/icons-material/Close';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu
} from '../../components/_dashboard/user/index';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';
import CallCenterToolbar from './CallCenterToolbar';
// import ProspectToolbar from './ProspectToolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'randomPhoneNumber', label: 'Mobile Number', alignRight: false },
  { id: 'loanType', label: 'Loan Type', alignRight: false },
  { id: 'created', label: 'date', alignRight: false },
  { id: 'name', label: 'NAME', alignRight: false },
  { id: 'city', label: 'CITY', alignRight: false },
  { id: 'source', label: 'SOURCE', alignRight: false },
  { id: 'statusCallCenter', label: 'STATUS', alignRight: false },
  { id: 'modifiedOn', label: 'Last update', alignRight: false },
  { id: 'noofcalls', label: 'NO OF CALLS', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (user) => user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function CallCenterList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [openStatus, setOpenStatus] = useState(false);
  const [statusValue, setStatusValue] = useState(' ');
  const [pageMenu, setPageMenu] = useState([]); // State for store localstorage data so that we can provide data to others components

  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    { field: 'calldate', headerName: 'CALL DATE', width: 210 },
    { field: 'callagent', headerName: 'CALL AGENT', width: 180 },
    { field: 'calltime', headerName: 'CALL TIME', width: 220 },
    { field: 'callstatus', headerName: 'CALL STATUS', width: 200 },
    { field: 'comments', headerName: 'COMMENTS', width: 200 }
  ];
  const rows = [
    {
      id: 1,
      calldate: 'Lorem',
      callagent: 'Lorem',
      calltime: 'Lorem',
      callstatus: 'Lorem',
      comments: 'Lorem'
    },
    {
      id: 2,
      calldate: 'Lorem',
      callagent: 'Lorem',
      calltime: 'Lorem',
      callstatus: 'Lorem',
      comments: 'Lorem'
    },
    {
      id: 3,
      calldate: 'Lorem',
      callagent: 'Lorem',
      calltime: 'Lorem',
      callstatus: 'Lorem',
      comments: 'Lorem'
    }
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'normal',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: 4
    }
  }));
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  useEffect(() => {
    // get meneu access for user from localstorage which store in <NavSection /> component
    const sidebarMenu = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d74');
    if (sidebarMenu !== null) {
      const bytes = CryptoJS.AES.decrypt(sidebarMenu, 'sidebarMenu');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      console.log('ciphertext', JSON.parse(originalText));

      const pageCode = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d75');
      const pageCodeBytes = CryptoJS.AES.decrypt(pageCode, 'pageCode');
      const pageCodeText = pageCodeBytes.toString(CryptoJS.enc.Utf8);
      console.log('ciphertext', pageCodeText);
      JSON.parse(originalText).accessPagesMapping.forEach((accessPageMap) => {
        if (accessPageMap.pageCode === pageCodeText) {
          setPageMenu(accessPageMap.accessSubPagesMappings);
        }
      });
    }
  }, []);
  return (
    <Page title="PULSE by Real Value Finloans || Call Center">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Call List" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <CallCenterToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="call-center"
              pageMenu={pageMenu}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          randomPhoneNumber,
                          name,
                          loanType,
                          status,
                          users,
                          source,
                          completion,
                          city,
                          created,
                          noofcalls,
                          modifiedOn,
                          statusCallCenter
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{randomPhoneNumber}</StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{source}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (statusCallCenter === 'Not interested' && 'error') ||
                                  (statusCallCenter === 'Not Doable' && 'warning') ||
                                  'success'
                                }
                              >
                                {sentenceCase(statusCallCenter)}
                              </Label>
                            </StyledTableCell>
                            <StyledTableCell align="left">{fDate(modifiedOn)}</StyledTableCell>
                            <StyledTableCell align="left">{noofcalls}</StyledTableCell>

                            <StyledTableCell align="" width="115">
                              <UserMoreMenu
                                rowId={id}
                                parent="call-center"
                                setOpenStatus={setOpenStatus}
                                pageMenu={pageMenu}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Card>
          <Dialog open={openStatus} onClose={(e) => setOpenStatus(false)} fullWidth>
            <DialogTitle className="popupTitle">
              <Typography variant="h6">Update Status</Typography>
              <IconButton onClick={(e) => setOpenStatus(false)} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Box mb={3}>
                <FormControl fullWidth>
                  <Select
                    id="demo-simple-select"
                    fullWidth
                    value={statusValue}
                    onChange={(e) => setStatusValue(e.target.value)}
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${statusValue}`
                    }}
                  >
                    <MenuItem value=" " disabled>
                      Update Status
                    </MenuItem>
                    <MenuItem value="Connected">Connected</MenuItem>
                    <MenuItem value="Not Connected">Not Connected</MenuItem>
                    <MenuItem value="Disconnected">Disconnected</MenuItem>
                    <MenuItem value="Not Interested">Not Interested</MenuItem>
                    <MenuItem value="Followups">Followups</MenuItem>
                    <MenuItem value="Wrong No">Wrong No</MenuItem>
                    <MenuItem value="Leads">Leads</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={3}>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Comments"
                  rows={2}
                  multiline
                  sx={{ padding: '0' }}
                />
              </Box>
              <Box height={100}>
                <DataGrid
                  headerHeight={40}
                  rowHeight={38}
                  className="customDataGrid globalDataGridRoot"
                  rows={rows}
                  columns={columns}
                  pageSize={100}
                  hideFooter
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => setOpenStatus(false)} variant="contained" className="mainBtn">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}
