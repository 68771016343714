import { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  styled,
  TableRow,
  TableCell,
  tableCellClasses,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete
} from '@mui/material';
import { Box } from '@mui/system';
// other icons
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
// react icons
import { BsWindowDock, BsCardHeading, BsTelephone } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineIdcard, AiFillEdit } from 'react-icons/ai';
import { RiBankCardLine } from 'react-icons/ri';
import { FaRegAddressCard, FaRegUser } from 'react-icons/fa';
import { CgCalendarDates } from 'react-icons/cg';
import { SiOpensourceinitiative } from 'react-icons/si';
import {
  MdIncompleteCircle,
  MdOutlineDateRange,
  MdOutlineSendToMobile,
  MdOutlineMoreHoriz
} from 'react-icons/md';
import { BiCloudUpload, BiCloudDownload, BiBoltCircle } from 'react-icons/bi';
import { TbReceiptTax } from 'react-icons/tb';
import { HiOutlineMail, HiReceiptTax } from 'react-icons/hi';
import { GiSwapBag, GiModernCity, GiMoneyStack, GiPayMoney, GiTakeMyMoney } from 'react-icons/gi';
// material icons
import EventNoteIcon from '@mui/icons-material/EventNote';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupIcon from '@mui/icons-material/Group';
import InterestsIcon from '@mui/icons-material/Interests';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddCardIcon from '@mui/icons-material/AddCard';
// componenets
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));

function ViewRevenueApproved() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');
  const [showProspectDetail, setShowProspectDetail] = useState(false);
  const [showCallCenterDetail, setShowCallCenterDetail] = useState(false);
  const [showLeadsDetail, setShowLeadsDetail] = useState(false);
  const [openSendMsg, setOpenSendMsg] = useState(false);
  const [editFile, setEditFile] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const [loanSubType, setLoanSubType] = useState(' ');
  const [loanType, setLoanType] = useState('Home Loan');
  const [selectStatus, setSelectStatus] = useState('Receipt');
  const [selectDate, setSelectDate] = useState('12 June, 2022');
  const [misStatus, setMisStatus] = useState('Status1');
  const [partnerDetail, setPartnerDetail] = useState(false);
  const [viewMorePopup, setViewMorePopup] = useState(false);

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  return (
    <Page title="Partner Management System || Revenue">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="View File" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Box container textAlign="right">
            <Box className="statusBox">
              <Typography variant="body2">MIS Status:</Typography>
              <Typography variant="subtitle2" className="disbursedText">
                Matched
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            File Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Box className="extraTopIcon">
              <Tooltip
                title="Send Message"
                placement="top"
                arrow
                onClick={(e) => {
                  setEditFile(true);
                }}
              >
                <StyledIconButton className="squareIconButton squareIconButtonFilled">
                  <AiFillEdit size={14} />
                </StyledIconButton>
              </Tooltip>
            </Box>
            <Dialog
              className="PopupBox"
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={editFile}
              onClose={(e) => setEditFile(false)}
            >
              <DialogTitle className="popupTitle">
                <Typography variant="h6">Edit File</Typography>
                <IconButton onClick={(e) => setEditFile(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Bank Name
                      </Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={topBanks}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} fullWidth placeholder="Select Bank" />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        File No
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type File No"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Borrower Name
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type Borrower Name"
                        value={nameValue}
                        onChange={(event) => setNameValue(event.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Branch Name
                      </Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={topCities}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} fullWidth placeholder="Select Branch" />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Employee Class
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type Employee Class"
                        value={nameValue}
                        onChange={(event) => setNameValue(event.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        File Type
                      </Typography>
                      <Select
                        fullWidth
                        inputProps={{
                          className: `textInput customSelectBox dissabledMenu${selectStatus}`
                        }}
                        value={selectStatus}
                        onChange={(e) => setSelectStatus(e.target.value)}
                      >
                        <MenuItem value=" " disabled>
                          Select File Type
                        </MenuItem>
                        <MenuItem value="Receipt">Receipt</MenuItem>
                        <MenuItem value="Approved">Approved</MenuItem>
                        <MenuItem value="Disursed">Disursed</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Total Loan Amount
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type Loan Amount"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Approval Date
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: `textInput dissabledMenu${selectDate}`
                        }}
                        value={selectDate}
                        onChange={(e) => setSelectDate(e.target.value)}
                        placeholder="Select Request Date"
                        type="date"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Loan Product Type
                      </Typography>
                      <Select
                        fullWidth
                        inputProps={{
                          className: `textInput customSelectBox dissabledMenu${loanType}`
                        }}
                        value={loanType}
                        onChange={(e) => setLoanType(e.target.value)}
                      >
                        <MenuItem value=" " disabled>
                          Select Loan Product Type
                        </MenuItem>
                        <MenuItem value="Home Loan">Home Loan</MenuItem>
                        <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                        <MenuItem value="Education Loan">Education Loan</MenuItem>
                        <MenuItem value="Credit Card">Credit Card</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        MIS Month
                      </Typography>
                      <TextField
                        fullWidth
                        type="month"
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type MIS Month"
                      />
                    </Box>
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        MIS Status
                      </Typography>
                      <Select
                        fullWidth
                        inputProps={{
                          className: `textInput customSelectBox dissabledMenu${loanType}`
                        }}
                        value={loanType}
                        onChange={(e) => setLoanType(e.target.value)}
                      >
                        <MenuItem value=" " disabled>
                          Select MIS Status
                        </MenuItem>
                        <MenuItem value="Status1">Status1</MenuItem>
                        <MenuItem value="Status2">Status2</MenuItem>
                      </Select>
                    </Box>
                  </Grid> */}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => setEditFile(false)} variant="contained" className="mainBtn">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <EventNoteIcon className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Bank Name
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      HDFC Bank
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <EventNoteIcon className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      File No
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      0123456
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <EventIcon className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Borrower Name
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      Ramesh Singh
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <CalendarTodayIcon className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Branch Name
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      Jaipur
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <GroupIcon className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Employee Class
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      E
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <MdOutlineDateRange className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Total Loan Amount
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      50,40,000
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <MdOutlineDateRange className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Approval Date
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      24 June, 2022
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <MdOutlineDateRange className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Loan Product Type
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      HOU
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <MdOutlineDateRange className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      SM Contact Name
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      Mahendra
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <MdOutlineDateRange className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      SM Mobile Number
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      879526413
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <MdOutlineDateRange className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      SM Email
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      mahin@gmail.com
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="displayFlex">
                  <MdOutlineDateRange className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      MIS Month
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      June, 2022
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Partner Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Box className="extraTopIcon">
              <Tooltip
                title="Send Message"
                placement="top"
                arrow
                onClick={(e) => {
                  setPartnerDetail(true);
                }}
              >
                <StyledIconButton className="squareIconButton squareIconButtonFilled">
                  <AiFillEdit size={14} />
                </StyledIconButton>
              </Tooltip>
            </Box>
            <Dialog
              className="PopupBox"
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              open={partnerDetail}
              onClose={(e) => setPartnerDetail(false)}
            >
              <DialogTitle className="popupTitle">
                <Typography variant="h6">Edit File</Typography>
                <IconButton onClick={(e) => setPartnerDetail(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Partner ID
                      </Typography>
                      <TextField
                        fullWidth
                        value="PART021354H"
                        inputProps={{
                          className: 'textInput'
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Partner Name
                      </Typography>
                      <TextField
                        fullWidth
                        value="Ramesh Singh"
                        inputProps={{
                          className: 'textInput'
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Partner Type
                      </Typography>
                      <TextField
                        fullWidth
                        value="Partner Type"
                        inputProps={{
                          className: 'textInput'
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Partner Commission
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value=""
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={(e) => setPartnerDetail(false)}
                  variant="contained"
                  className="mainBtn"
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box className="displayFlex">
                  <GiPayMoney className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Partner ID
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      P10245
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="displayFlex">
                  <GiTakeMyMoney className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Partner Name
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      Finserv
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="displayFlex">
                  <AdUnitsIcon className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Partner Type
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      External
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="displayFlex">
                  <GroupIcon className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Partner Commission
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      10%
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Comments Log
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin viewCardPadding">
            <Box className="extraTopIcon">
              <Tooltip
                title="Send Message"
                placement="top"
                arrow
                onClick={(e) => {
                  setOpenSendMsg(true);
                }}
              >
                <StyledIconButton className="squareIconButton squareIconButtonFilled">
                  <MdOutlineSendToMobile size={14} />
                </StyledIconButton>
              </Tooltip>
            </Box>
            <Dialog open={openSendMsg} onClose={(e) => setOpenSendMsg(false)} fullWidth>
              <DialogTitle className="popupTitle">
                <Typography variant="h6">Add New Message</Typography>
                <IconButton onClick={(e) => setOpenSendMsg(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Box>
                  <FormControl fullWidth>
                    <TextField
                      type="number"
                      variant="outlined"
                      placeholder="Write Your Comments"
                      fullWidth
                      multiline
                      rows={5}
                    />
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={(e) => setOpenSendMsg(false)}
                  variant="contained"
                  className="mainBtn"
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell align="">Person Name</StyledTableCell>
                    <StyledTableCell align="">Comments</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="">27 April, 2022</StyledTableCell>
                    <StyledTableCell align="">Ramesh</StyledTableCell>
                    <StyledTableCell align="">
                      Document Pending
                      <Tooltip title="More" placement="top" arrow>
                        <IconButton
                          // sx={{ padding: '0' }}
                          size="small"
                          variant="subtitle2"
                          onClick={(e) => setViewMorePopup(true)}
                        >
                          <MdOutlineMoreHoriz size={16} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="">27 April, 2022</StyledTableCell>
                    <StyledTableCell align="">Ramesh</StyledTableCell>
                    <StyledTableCell align="">
                      Document Pending
                      <Tooltip title="More" placement="top" arrow>
                        <IconButton
                          // sx={{ padding: '0' }}
                          size="small"
                          variant="subtitle2"
                          onClick={(e) => setViewMorePopup(true)}
                        >
                          <MdOutlineMoreHoriz size={16} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Dialog open={viewMorePopup} onClose={(e) => setViewMorePopup(false)}>
              <DialogContent dividers>
                <Typography variant="body2">This is complete message info....</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={(e) => setViewMorePopup(false)}
                  variant="contained"
                  className="mainBtn"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
export default ViewRevenueApproved;
// Top Cities
const topCities = [
  { label: 'Mumbai' },
  { label: 'Delhi' },
  { label: 'Bengaluru' },
  { label: 'Ahmedabad' },
  { label: 'Hyderabad' },
  { label: 'Chennai' },
  { label: 'Kolkata' },
  { label: 'Pune' },
  { label: 'Jaipur' },
  { label: 'Surat' },
  { label: 'Lucknow' },
  { label: 'Kanpur' },
  { label: 'Nagpur' },
  { label: 'Patna' },
  { label: 'Indore' },
  { label: 'Thane' },
  { label: 'Bhopal' },
  { label: 'Visakhapatnam' },
  { label: 'Vadodara' },
  { label: 'Firozabad' },
  { label: 'Ludhiana' },
  { label: 'Rajkot' },
  { label: 'Agra' },
  { label: 'Siliguri' },
  { label: 'Nashik' },
  { label: 'Faridabad' },
  { label: 'Patiala' },
  { label: 'Meerut' },
  { label: 'Varanasi' },
  { label: 'Dhanbad' },
  { label: 'Jodhpur' },
  { label: 'Amritsar' },
  { label: 'Raipur' },
  { label: 'Allahabad' },
  { label: 'Coimbatore' },
  { label: 'Jabalpur' },
  { label: 'Gwalior' },
  { label: 'Vijayawada' },
  { label: 'Madurai' },
  { label: 'Guwahati' },
  { label: 'Chandigarh' },
  { label: 'Hubli-Dharwad' },
  { label: 'Amroha' },
  { label: 'Moradabad' },
  { label: 'Gurgaon' },
  { label: 'Aligarh' },
  { label: 'Solapur' },
  { label: 'Ranchi' },
  { label: 'Jalandhar' },
  { label: 'Tiruchirappalli' },
  { label: 'Bhubaneswar' },
  { label: 'Salem' },
  { label: 'Warangal' },
  { label: 'Mira-Bhayandar' },
  { label: 'Thiruvananthapuram' },
  { label: 'Bhiwandi' },
  { label: 'Saharanpur' },
  { label: 'Guntur' },
  { label: 'Amravati' },
  { label: 'Bikaner' },
  { label: 'Noida' },
  { label: 'Jamshedpur' },
  { label: 'Bhilai Nagar' },
  { label: 'Cuttack' },
  { label: 'Kochi' },
  { label: 'Udaipur' },
  { label: 'Bhavnagar' },
  { label: 'Dehradun' },
  { label: 'Asansol' },
  { label: 'Nanded-Waghala' },
  { label: 'Ajmer' },
  { label: 'Jamnagar' },
  { label: 'Ujjain' },
  { label: 'Sangli' },
  { label: 'Loni' },
  { label: 'Jhansi' },
  { label: 'Pondicherry' },
  { label: 'Nellore' },
  { label: 'Jammu' },
  { label: 'Belagavi' },
  { label: 'Raurkela' },
  { label: 'Mangaluru' },
  { label: 'Tirunelveli' },
  { label: 'Malegaon' },
  { label: 'Gaya' },
  { label: 'Tiruppur' },
  { label: 'Davanagere' },
  { label: 'Kozhikode' },
  { label: 'Akola' },
  { label: 'Kurnool' },
  { label: 'Bokaro' },
  { label: 'Rajahmundry' },
  { label: 'Ballari' },
  { label: 'Agartala' },
  { label: 'Bhagalpur' },
  { label: 'Latur' },
  { label: 'Dhule' },
  { label: 'Korba' },
  { label: 'Bhilwara' },
  { label: 'Brahmapur' },
  { label: 'Mysore' },
  { label: 'Muzaffarpur' },
  { label: 'Ahmednagar' },
  { label: 'Kollam' },
  { label: 'Bilaspur' },
  { label: 'Shahjahanpur' },
  { label: 'Thrissur' },
  { label: 'Alwar' },
  { label: 'Kakinada' },
  { label: 'Nizamabad' },
  { label: 'Sagar' },
  { label: 'Tumkur' },
  { label: 'Hisar' },
  { label: 'Rohtak' },
  { label: 'Panipat' },
  { label: 'Darbhanga' },
  { label: 'Kharagpur' },
  { label: 'Aizawl' },
  { label: 'Ichalkaranji' },
  { label: 'Tirupati' },
  { label: 'Karnal' },
  { label: 'Bathinda' },
  { label: 'Rampur' },
  { label: 'Shivamogga' },
  { label: 'Ratlam' },
  { label: 'Modinagar' },
  { label: 'Durg' },
  { label: 'Shillong' },
  { label: 'Imphal' },
  { label: 'Hapur' },
  { label: 'Ranipet' },
  { label: 'Anantapur' },
  { label: 'Arrah' },
  { label: 'Karimnagar' },
  { label: 'Etawah' },
  { label: 'Bharatpur' },
  { label: 'Begusarai' },
  { label: 'Chhapra' },
  { label: 'Kadapa' },
  { label: 'Pali' },
  { label: 'Satna' },
  { label: 'Vizianagaram' },
  { label: 'Katihar' },
  { label: 'Hardwar' },
  { label: 'Sonipat' },
  { label: 'Nagercoil' },
  { label: 'Thanjavur' },
  { label: 'Murwara' },
  { label: 'Naihati' },
  { label: 'Sambhal' },
  { label: 'Nadiad' },
  { label: 'Yamunanagar' },
  { label: 'Eluru' },
  { label: 'Munger' },
  { label: 'Panchkula' },
  { label: 'Deoghar' },
  { label: 'Ongole' },
  { label: 'Nandyal' },
  { label: 'Morena' },
  { label: 'Bhiwani' },
  { label: 'Porbandar' },
  { label: 'Palakkad' },
  { label: 'Anand' },
  { label: 'Purnia' },
  { label: 'Baharampur' },
  { label: 'Barmer' },
  { label: 'Morvi' },
  { label: 'Orai' },
  { label: 'Bahraich' },
  { label: 'Vellore' },
  { label: 'Khammam' },
  { label: 'Mahesana' },
  { label: 'Silchar' },
  { label: 'Sambalpur' },
  { label: 'Rewa' },
  { label: 'Unnao' },
  { label: 'Hugli-Chinsurah' },
  { label: 'Raiganj' },
  { label: 'Adityapur' },
  { label: 'Alappuzha' },
  { label: 'Bahadurgarh' },
  { label: 'Machilipatnam' },
  { label: 'Rae Bareli' },
  { label: 'Jalpaiguri' },
  { label: 'Bharuch' },
  { label: 'Hoshiarpur' },
  { label: 'Baramula' },
  { label: 'Adoni' },
  { label: 'Jind' },
  { label: 'Tonk' },
  { label: 'Tenali' },
  { label: 'Kancheepuram' },
  { label: 'Vapi' },
  { label: 'Navsari' },
  { label: 'Mahbubnagar' },
  { label: 'Puri' },
  { label: 'Erode' },
  { label: 'Batala' },
  { label: 'Haldwani' },
  { label: 'Vidisha' },
  { label: 'Saharsa' },
  { label: 'Thanesar' },
  { label: 'Chittoor' },
  { label: 'Veraval' },
  { label: 'Lakhimpur' },
  { label: 'Sitapur' },
  { label: 'Hindupur' },
  { label: 'Santipur' },
  { label: 'Balurghat' },
  { label: 'Ganjbasoda' },
  { label: 'Moga' },
  { label: 'Srinagar' },
  { label: 'Medinipur' },
  { label: 'Habra' },
  { label: 'Sasaram' },
  { label: 'Hajipur' },
  { label: 'Bhuj' },
  { label: 'Shimla' },
  { label: 'Tiruvannamalai' },
  { label: 'Kaithal' },
  { label: 'Rajnandgaon' },
  { label: 'Godhra' },
  { label: 'Hazaribag' },
  { label: 'Bhimavaram' },
  { label: 'Mandsaur' },
  { label: 'Dibrugarh' },
  { label: 'Kolar' },
  { label: 'Bankura' },
  { label: 'Mandya' },
  { label: 'Dehri-on-Sone' },
  { label: 'Madanapalle' },
  { label: 'Malerkotla' },
  { label: 'Lalitpur' },
  { label: 'Bettiah' },
  { label: 'Pollachi' },
  { label: 'Khanna' },
  { label: 'Neemuch' },
  { label: 'Palwal' },
  { label: 'Palanpur' },
  { label: 'Guntakal' },
  { label: 'Nabadwip' },
  { label: 'Udupi' },
  { label: 'Jagdalpur' },
  { label: 'Motihari' },
  { label: 'Pilibhit' },
  { label: 'Dimapur' },
  { label: 'Mohali' },
  { label: 'Dharmavaram' },
  { label: 'Kashipur' },
  { label: 'Sivakasi' },
  { label: 'Darjiling' },
  { label: 'Chikkamagaluru' },
  { label: 'Gudivada' },
  { label: 'Adilabad' },
  { label: 'Yavatmal' },
  { label: 'Barnala' },
  { label: 'Nagaon' },
  { label: 'Narasaraopet' },
  { label: 'Raigarh' },
  { label: 'Roorkee' },
  { label: 'Valsad' },
  { label: 'Ambikapur' },
  { label: 'Giridih' },
  { label: 'Chandausi' },
  { label: 'Purulia' },
  { label: 'Patan' },
  { label: 'Bagaha' },
  { label: 'Hardoi' },
  { label: 'Achalpur' },
  { label: 'Osmanabad' },
  { label: 'Deesa' },
  { label: 'Nandurbar' },
  { label: 'Azamgarh' },
  { label: 'Ramgarh' },
  { label: 'Firozpur' },
  { label: 'Baripada' },
  { label: 'Karwar' },
  { label: 'Siwan' },
  { label: 'Anantnag' },
  { label: 'Tadpatri' },
  { label: 'Satara' },
  { label: 'Bhadrak' },
  { label: 'Kishanganj' },
  { label: 'Wardha' },
  { label: 'Amreli' },
  { label: 'Neyveli' },
  { label: 'Jamalpur' },
  { label: 'Marmagao' },
  { label: 'Udgir' },
  { label: 'Tadepalligudem' },
  { label: 'Nagapattinam' },
  { label: 'Buxar' },
  { label: 'Aurangabad' },
  { label: 'Jehanabad' },
  { label: 'Khair' },
  { label: 'Sawai Madhopur' },
  { label: 'Kapurthala' },
  { label: 'Chilakaluripet' },
  { label: 'Malappuram' },
  { label: 'Rewari' },
  { label: 'Nagaur' },
  { label: 'Sultanpur' },
  { label: 'Nagda' },
  { label: 'Port Blair' },
  { label: 'Lakhisarai' },
  { label: 'Panaji' },
  { label: 'Tinsukia' },
  { label: 'Itarsi' },
  { label: 'Kohima' },
  { label: 'Balangir' },
  { label: 'Nawada' },
  { label: 'Jharsuguda' },
  { label: 'Jagtial' },
  { label: 'Viluppuram' },
  { label: 'Amalner' },
  { label: 'Zirakpur' },
  { label: 'Tanda' },
  { label: 'Tiruchengode' },
  { label: 'Nagina' },
  { label: 'Yemmiganur' },
  { label: 'Vaniyambadi' },
  { label: 'Theni Allinagaram' },
  { label: 'Margao' },
  { label: 'Akot' },
  { label: 'Mhow' },
  { label: 'Kot Kapura' },
  { label: 'Kadiri' },
  { label: 'Najibabad' },
  { label: 'Nirmal' },
  { label: 'Udhagamandalam' },
  { label: 'Jhumri Tilaiya' },
  { label: 'Aruppukkottai' },
  { label: 'Jamui' },
  { label: 'Sitamarhi' },
  { label: 'Chirala' },
  { label: 'Anjar' },
  { label: 'Karaikal' },
  { label: 'Hansi' },
  { label: 'Anakapalle' },
  { label: 'Mahasamund' },
  { label: 'Faridkot' },
  { label: 'Dhoraji' },
  { label: 'Balaghat' },
  { label: 'Khambhat' },
  { label: 'Kavali' },
  { label: 'Dhamtari' },
  { label: 'Ashok Nagar' },
  { label: 'Bargarh' },
  { label: 'Kamareddy' },
  { label: 'Kothagudem' },
  { label: 'Ramanagaram' },
  { label: 'Gokak' },
  { label: 'Tikamgarh' },
  { label: 'Araria' },
  { label: 'Rishikesh' },
  { label: 'Daltonganj' },
  { label: 'Arakkonam' },
  { label: 'Washim' },
  { label: 'Sangrur' },
  { label: 'Bodhan' },
  { label: 'Fazilka' },
  { label: 'Palacole' },
  { label: 'Keshod' },
  { label: 'Wadhwan' },
  { label: 'Gurdaspur' },
  { label: 'Vatakara' },
  { label: 'Tura' },
  { label: 'Narnaul' },
  { label: 'Kharar' },
  { label: 'Yadgir' },
  { label: 'Ambejogai' },
  { label: 'Ankleshwar' },
  { label: 'Paradip' },
  { label: 'Virudhachalam' },
  { label: 'Kanhangad' },
  { label: 'Kadi' },
  { label: 'Gobindgarh' },
  { label: 'Tindivanam' },
  { label: 'Taliparamba' },
  { label: 'Tanuku' },
  { label: 'Virudhunagar' },
  { label: 'Koyilandy' },
  { label: 'Jorhat' },
  { label: 'Karur' },
  { label: 'Valparai' },
  { label: 'Neyyattinkara' },
  { label: 'Bapatla' },
  { label: 'Fatehabad' },
  { label: 'Sankarankovil' },
  { label: 'Tenkasi' },
  { label: 'Ratnagiri' },
  { label: 'Sikandrabad' },
  { label: 'Chaibasa' },
  { label: 'Chirmiri' },
  { label: 'Bhawanipatna' },
  { label: 'Kayamkulam' },
  { label: 'Nabha' },
  { label: 'Dhenkanal' },
  { label: 'Uran Islampur' },
  { label: 'Gopalganj' },
  { label: 'Bongaigaon' },
  { label: 'Tarn Taran' },
  { label: 'Barbil' },
  { label: 'Koratla' },
  { label: 'Arambagh' },
  { label: 'Gohana' },
  { label: 'Ladnu' },
  { label: 'Tamluk' },
  { label: 'Jagraon' },
  { label: 'Alirajpur' },
  { label: 'Tandur' },
  { label: 'Naidupet' },
  { label: 'Tirupathur' },
  { label: 'Tohana' },
  { label: 'Dhubri' },
  { label: 'Visnagar' },
  { label: 'Vrindavan' },
  { label: 'Nokha' },
  { label: 'Nagari' },
  { label: 'Narwana' },
  { label: 'Ujhani' },
  { label: 'Samastipur' },
  { label: 'Laharpur' },
  { label: 'Nimbahera' },
  { label: 'Diphu' },
  { label: 'Jhargram' },
  { label: 'Tilhar' },
  { label: 'Udumalaipettai' },
  { label: 'Wanaparthy' },
  { label: 'Gudur' },
  { label: 'Kendujhar' },
  { label: 'Mandi' },
  { label: 'Nedumangad' },
  { label: 'North Lakhimpur' },
  { label: 'Vinukonda' },
  { label: 'Tiptur' },
  { label: 'Gobichettipalayam' },
  { label: 'Wani' },
  { label: 'Upleta' },
  { label: 'Narasapuram' },
  { label: 'Nuzvid' },
  { label: 'Tezpur' },
  { label: 'Una' },
  { label: 'Sheopur' },
  { label: 'Thiruvarur' },
  { label: 'Lonavla' },
  { label: 'Ponnur' },
  { label: 'Kagaznagar' },
  { label: 'Gadwal' },
  { label: 'Bhatapara' },
  { label: 'Kandukur' },
  { label: 'Unjha' },
  { label: 'Lunglei' },
  { label: 'Karimganj' },
  { label: 'Kannur' },
  { label: 'Bobbili' },
  { label: 'Talegaon Dabhade' },
  { label: 'Anjangaon' },
  { label: 'Mangrol' },
  { label: 'Gangarampur' },
  { label: 'Thiruvallur' },
  { label: 'Tirur' },
  { label: 'Jatani' },
  { label: 'Viramgam' },
  { label: 'Rajsamand' },
  { label: 'Yanam' },
  { label: 'Kottayam' },
  { label: 'Dhuri' },
  { label: 'Namakkal' },
  { label: 'Kasaragod' },
  { label: 'Kunnamkulam' },
  { label: 'Umred' },
  { label: 'Bellampalle' },
  { label: 'Ottappalam' },
  { label: 'Dumraon' },
  { label: 'Jaggaiahpet' },
  { label: 'Goalpara' },
  { label: 'Tuni' },
  { label: 'Lachhmangarh' },
  { label: 'Bhongir' },
  { label: 'Amalapuram' },
  { label: 'Firozpur Cantt.' },
  { label: 'Vikarabad' },
  { label: 'Thiruvalla' },
  { label: 'Palghar' },
  { label: 'Jangaon' },
  { label: 'Bheemunipatnam' },
  { label: 'Panna' },
  { label: 'Thodupuzha' },
  { label: 'Arwal' },
  { label: 'Venkatagiri' },
  { label: 'Kalpi' },
  { label: 'Churu' },
  { label: 'Arsikere' },
  { label: 'Ozar' },
  { label: 'Thirumangalam' },
  { label: 'Nasirabad' },
  { label: 'Nanjangud' },
  { label: 'Forbesganj' },
  { label: 'Tundla' },
  { label: 'Bhadrachalam' },
  { label: 'Madhepura' },
  { label: 'Pithoragarh' },
  { label: 'Mathura' },
  { label: 'Sultanganj' },
  { label: 'Raxaul' },
  { label: 'Pilani' },
  { label: 'Mapusa' },
  { label: 'Sivaganga' },
  { label: 'Shahpur' },
  { label: 'Ramnagar' },
  { label: 'Rameshwaram' },
  { label: 'Perambalur' },
  { label: 'Manvi' },
  { label: 'Murshidabad' },
  { label: 'Mahe' },
  { label: 'Solan' },
  { label: 'Sanawad' },
  { label: 'Bulandshahr' },
  { label: 'Satana' },
  { label: 'Sanand' },
  { label: 'Sohna' },
  { label: 'Rudrapur' },
  { label: 'Perumbavoor' },
  { label: 'Raikot' },
  { label: 'Pauri' },
  { label: 'Shahganj' },
  { label: 'Mount Abu' },
  { label: 'Silvassa' },
  { label: 'Sikanderpur' },
  { label: 'Navi Mumbai' }
];
// Top States
const topStates = [
  { label: 'Andhra Pradesh' },
  { label: 'Arunachal Pradesh' },
  { label: 'Assam' },
  { label: 'Bihar' },
  { label: 'Chhattisgarh' },
  { label: 'Goa' },
  { label: 'Gujarat' },
  { label: 'Haryana' },
  { label: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir' },
  { label: 'Jharkhand' },
  { label: 'Karnataka' },
  { label: 'Kerala' },
  { label: 'Madhya Pradesh' },
  { label: 'Maharashtra' },
  { label: 'Manipur' },
  { label: 'Meghalaya' },
  { label: 'Mizoram' },
  { label: 'Nagaland' },
  { label: 'Odisha' },
  { label: 'Punjab' },
  { label: 'Rajasthan' },
  { label: 'Sikkim' },
  { label: 'Tamil Nadu' },
  { label: 'Telangana' },
  { label: 'Tripura' },
  { label: 'Uttarakhand' },
  { label: 'Uttar Pradesh' },
  { label: 'West Bengal' },
  { label: 'Andaman and Nicobar Islands' },
  { label: 'Chandigarh' },
  { label: 'Dadra and Nagar Haveli' },
  { label: 'Daman and Diu' },
  { label: 'Delhi' },
  { label: 'Lakshadweep' },
  { label: 'Puducherry' }
];
// Top Banks
const topBanks = [
  { label: 'Axis Bank' },
  { label: 'HDFC Bank' },
  { label: 'HDFC Ltd.' },
  { label: 'ICICI Bank' },
  { label: 'PNB Housing' },
  { label: 'Piramal Housing' },
  { label: 'Standard Chartered Bank' },
  { label: 'Yes Bank' },
  { label: 'Aditya Birla Housing Finance' },
  { label: 'IDFC Bank' },
  { label: 'RBL Bank' },
  { label: 'Federal Bank' },
  { label: 'Fedfina' },
  { label: 'HDB Financial' },
  { label: 'IIFL Finance' },
  { label: 'Tata Capital' },
  { label: 'ICICI Home Finance' },
  { label: 'Cholamandalam Finance' },
  { label: 'L&T Finance' },
  { label: 'Karur Vysya Bank' },
  { label: 'Hero Housing Finance' },
  { label: 'Kotak Mahindra Bank' },
  { label: 'Fullerton Grihashakti' },
  { label: 'Anand Rathi Financial' },
  { label: 'Aadhar Housing Finance' },
  { label: 'Godrej Housing Finance' },
  { label: 'Deutsche Bank' },
  { label: 'Jana Small Finance Bank' },
  { label: 'HSBC Bank' },
  { label: 'DCB Bank' },
  { label: 'Bank of Baroda' },
  { label: 'Bajaj Housing Finance' },
  { label: 'LIC Housing Finance' },
  { label: 'AU Small Finance Bank' },
  { label: 'Union Bank of India' },
  { label: 'State Bank of India' }
];
