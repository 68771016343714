import { useState, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', refreshPageComponent, ...other }, ref) => {
  const [refreshComp, setRefreshComp] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    let fetchUserDetailsLocalStorage = localStorage.getItem('userDetails');
    if (fetchUserDetailsLocalStorage === 'null' || fetchUserDetailsLocalStorage === null) {
      fetchUserDetailsLocalStorage = '{"isLogedIn":false}';
    }
    if (!JSON.parse(fetchUserDetailsLocalStorage).isLogedIn) {
      navigate('/');
    }
  }, [refreshComp, refreshPageComponent]);
  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
