import { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  Divider,
  TextareaAutosize
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import loginListService from '../services/loginListApplication';

const {
  LoginDecisionInfo,
  ListProcessingFeeStatuses,
  ListRejectionStatuses,
  AddDecisionInfo,
  AddRejectedReason
} = loginListService;

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    fontWeight: 'normal',
    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: 4
  }
}));

function DecisionLogIn() {
  const navigate = useNavigate();
  const [selectReason, setSelectReason] = useState(' ');
  const [selectProcessingFeeStatus, setSelectProcessingFeeStatus] = useState(' ');
  const [decisionData, setDecisionData] = useState({});
  const [processingFeeStatuses, setProcessingFeeStatuses] = useState([]);
  const [mutate, setMutate] = useState();
  const [rejectionList, setRejectionList] = useState([]);
  const [token, setToken] = useState();
  const [leadId, setLeadId] = useState();
  const [applicationId, setApplicationId] = useState();
  const [statusId1, setStatusId1] = useState();
  const [statusName, setStatusName] = useState();
  const [stageStatuesName, setStageStatuesName] = useState('');
  const [selectedRowData, setSelectedRowData] = useState('');
  const [rejectComment, setRejectComment] = useState('');
  console.log('statusId1', statusId1);
  console.log('statusId1', statusName);

  const handleSubmit = async (e) => {
    const credential = {
      applicationId: Number(applicationId),
      leadId: leadId,
      submissionDate: null,
      requestedLoanAmount: Number(decisionData.sanctionedLoanAmount),
      disbursedLoanAmount: Number(decisionData.disbursedLoanAmount),
      totalProcessingFee: Number(decisionData.totalProcessingFee),
      bankWaiver: Number(decisionData.bankWaiver),
      applicantShare: Number(decisionData.applicantShare),
      companyShare: Number(decisionData.companyShare),
      countryId: null,
      processingFeeStatusId: selectProcessingFeeStatus,
      feeToBeDeductedFromInvoice: Number(decisionData.feeToBeDeductedFromInvoice),
      ownerShare: Number(decisionData.ownerShare),
      coOwnerShare: Number(decisionData.coOwnerShare),
      incentive: null, // value
      statusId: 110, // value
      comment: null, // value
      sanctionedLoanAmount: 400000 // value
    };
    console.log('decisionDatacredential', credential);
    const AddDecisionInfoApiResponse = await AddDecisionInfo(credential, token);
    if (AddDecisionInfoApiResponse.msg) {
      if (AddDecisionInfoApiResponse.msg === 'Forbidden') {
        navigate('/');
      }
    }
    if (AddDecisionInfoApiResponse.status === 'success') {
      console.log('decisionDatasubmitinfo', AddDecisionInfoApiResponse.data);
      navigate('/login');
    }
  };

  const rejectionHandler = async (e) => {
    const rejectionCredentials = {
      leadId: leadId,
      applicationId: Number(applicationId),
      rejectedReason: selectReason,
      comment: rejectComment
    };

    const AddRejectedReasonResponse = await AddRejectedReason(rejectionCredentials, token);
    if (AddRejectedReasonResponse.status === 'success') {
      navigate('/login');
    }
  };
  useEffect(() => {
    const selectedRowData = JSON.parse(localStorage.getItem('selectedRow'));
    setSelectedRowData(selectedRowData);
    const statusName = JSON.parse(localStorage.getItem('statusName'));
    setStageStatuesName(statusName);
    console.log('statusName', statusName);
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }

    console.log('localJWTtoken', localJWTtoken);
    const authToken = localJWTtoken.token.jwt;
    setToken(authToken);
    const authCompanyId = localJWTtoken.token.companyId;
    const authUserId = localJWTtoken.token.userId;

    const authleadId = JSON.parse(localStorage.getItem('loginLeadId'));
    setLeadId(authleadId);

    const authLoginApplicationId = JSON.parse(localStorage.getItem('loginApplicationId'));
    setApplicationId(authLoginApplicationId);

    // const authLoginStatusId = JSON.parse(localStorage.getItem('loginStatusId'));
    // setStatusId1(authLoginStatusId);

    // const authLoginStatusName = JSON.parse(localStorage.getItem('loginStatusName'));
    // setStatusName(authLoginStatusName);

    const credentials = {
      companyId: authCompanyId,
      userId: authUserId,
      leadId: authleadId
    };

    (async () => {
      const LoginDecisionInfoApiResponse = await LoginDecisionInfo(credentials, authToken);
      if (LoginDecisionInfoApiResponse.msg) {
        if (LoginDecisionInfoApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (LoginDecisionInfoApiResponse.status === 'success') {
        LoginDecisionInfoApiResponse.data.processingFeePercentage = ''; // need to delete after getting upated api
        LoginDecisionInfoApiResponse.data.leadOwner = ''; // need to delete after getting upated api
        LoginDecisionInfoApiResponse.data.leadCoOwner = ''; // need to delete after getting upated api
        // setDecisionDataApi('LoginDecisionInfoApiResponse.data');
        setDecisionData(LoginDecisionInfoApiResponse.data);
      } else {
        setDecisionData({});
      }

      const ListProcessingFeeStatusesApiResponse = await ListProcessingFeeStatuses(authToken);
      if (ListProcessingFeeStatusesApiResponse.msg) {
        if (ListProcessingFeeStatusesApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ListProcessingFeeStatusesApiResponse.status === 'success') {
        setProcessingFeeStatuses(ListProcessingFeeStatusesApiResponse.data.processingFeeStatuses);
      } else {
        setProcessingFeeStatuses([]);
      }

      const ListRejectionStatusesApiResponse = await ListRejectionStatuses(authToken);
      if (ListRejectionStatusesApiResponse.msg) {
        if (ListRejectionStatusesApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ListRejectionStatusesApiResponse.status === 'success') {
        setRejectionList(ListRejectionStatusesApiResponse.data.rejectionStageStatuses);
      } else {
        setRejectionList([]);
      }
    })();
  }, []);
  console.log('decisionData', decisionData);
  return (
    <Page title="PULSE by Real Value Finloans || Log In">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Application Closure Details" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          {/* condition apply from here to check if this is disbursed */}
          {stageStatuesName === 'Disbursed' && (
            <Scrollbar>
              <Box container textAlign="right">
                <Box className="statusBox">
                  <Typography variant="body2">Status:</Typography>
                  <Typography variant="subtitle2" className="disbursedText">
                    {stageStatuesName}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body1" className="headingLineUp headingLineUpOut">
                Disbursement Details
              </Typography>
              <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Amount Sanctioned
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.sanctionedLoanAmount}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.sanctionedLoanAmount = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Amount Disbursed
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.disbursedLoanAmount}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.disbursedLoanAmount = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Typography variant="body1" className="headingLineUp headingLineUpOut">
                Processing Fee Details
              </Typography>
              <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Processing Fee Percentage
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.processingFeePercentage}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.processingFeePercentage = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Total Processing Fee
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.totalProcessingFee}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.totalProcessingFee = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Bank Waiver
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.bankWaiver}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.bankWaiver = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Applicant Share
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.applicantShare}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.applicantShare = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Company Share
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.companyShare}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.companyShare = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Processing Fee Status
                      </Typography>
                      <Select
                        fullWidth
                        inputProps={{
                          className: `textInput customSelectBox`
                        }}
                        value={selectProcessingFeeStatus}
                        onChange={(e) => setSelectProcessingFeeStatus(e.target.value)}
                      >
                        <MenuItem value=" ">Select</MenuItem>
                        {processingFeeStatuses &&
                          processingFeeStatuses.map((item, index) => {
                            console.log(item);
                            return <MenuItem value={item.typeId}>{item.typeName}</MenuItem>;
                          })}
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Fee to be Deducted from Invoice
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.feeToBeDeductedFromInvoice}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.feeToBeDeductedFromInvoice = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Typography variant="body1" className="headingLineUp headingLineUpOut">
                Commission Sharing
              </Typography>
              <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Lead Owner
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        // value={
                        //   decisionData.leadOwner
                        //     ? decisionData.leadOwner
                        //     : selectedRowData.customerName
                        // }
                        value={decisionData && decisionData.leadOwner ? decisionData.leadOwner : ''}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.leadOwner = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Lead Co Owner
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.leadCoOwner}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.leadCoOwner = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Owner Share
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.ownerShare}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.ownerShare = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="12" sm="6" md="4">
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Co owner Share
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={decisionData && decisionData.coOwnerShare}
                        onChange={(e) => {
                          const prevData = decisionData;
                          prevData.coOwnerShare = e.target.value;
                          setDecisionData(prevData);
                          setMutate(new Date());
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className="" textAlign="right">
                <Button variant="contained" className="mainBtn" onClick={(e) => handleSubmit(e)}>
                  Submit
                </Button>
              </Box>
            </Scrollbar>
          )}

          {/* <Divider sx={{ marginBottom: '1.5rem', marginTop: '1rem' }} /> */}
          {/* If Rejected Status than show this code */}
          {stageStatuesName === 'Rejected' && (
            <Scrollbar>
              <Box container textAlign="right">
                <Box className="statusBox statusBoxSec">
                  <Typography variant="body2">Status:</Typography>
                  <Typography variant="subtitle2" className="rejectedText">
                    {stageStatuesName}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body1" className="headingLineUp headingLineUpOut">
                Rejection Details
              </Typography>
              <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                <Box className="mb-3">
                  <Typography variant="h6" className="formLabel">
                    Reject Reasons
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${selectReason}`
                    }}
                    value={selectReason}
                    onChange={(e) => setSelectReason(e.target.value)}
                  >
                    <MenuItem value=" " disabled>
                      Select Reason
                    </MenuItem>
                    {rejectionList &&
                      rejectionList.map((item, index) => {
                        console.log(item);
                        return <MenuItem value={item.typeId}>{item.typeName}</MenuItem>;
                      })}
                  </Select>
                </Box>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Comments
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Write Your Comments"
                    multiline
                    rows={5}
                    value={rejectComment}
                    onChange={(e) => setRejectComment(e.target.value)}
                  />
                </Box>
              </Box>
              <Box textAlign="right">
                <Button
                  variant="contained"
                  className="mainBtn"
                  onClick={(e) => rejectionHandler(e)}
                >
                  Submit
                </Button>
              </Box>
            </Scrollbar>
          )}
        </Box>
      </Container>
    </Page>
  );
}
export default DecisionLogIn;
