import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputBase,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// material icons
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
// components
import partnerService from './services/partnerService';

const { LoginForm } = partnerService;

function Login() {
  const navigate = useNavigate();
  const [emailAddr, setEmailAddr] = useState();
  const [password, setPassword] = useState();
  const [open, setOpen] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const [values, setValues] = React.useState({
    showPassword: false
  });
  const [loginToken, setLoginToken] = useState(null);

  const handleLogin = async () => {
    if (!emailAddr || emailAddr.trim() === '') {
      setOpen(true);
      setValidationMsg('Please enter your E-Mail address');
      return false;
    }
    if (!password || password?.trim() === '') {
      setOpen(true);
      setValidationMsg('Please enter your password');
    }
    if (password.length > 6 && password.length < 24) {
      const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,24}$/;
      if (!regularExpression.test(password)) {
        setOpen(true);
        setValidationMsg(
          'Password must be alphanumeric, should contain at least one capital letter, one number, one special character'
        );
      }
    } else {
      setOpen(true);
      setValidationMsg(
        'Password must be of minimum length of 6 characters and max. length of 24 characters'
      );
    }
    const credential = {
      username: emailAddr,
      password: password
    };

    const loginResponse = await LoginForm(credential);
    if (loginResponse.status === 'OK') {
      const loginResponseData = loginResponse;
      setLoginToken(loginResponseData);
      localStorage.setItem(
        'userDetails',
        JSON.stringify({
          isLogedIn: true,
          token: loginResponseData
        })
      );
      setTimeout(() => navigate('/dashboard', { replace: true }), 200);
    } else {
      return '';
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    let login = '';
    login = await JSON.parse(localStorage.getItem('userDetails'));
    if (login != null) {
      navigate('/dashboard', { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  };

  return (
    <Box className="LoginOuter" sx={{ width: '100%', height: '100vh' }}>
      <Container maxWidth="fullWidth" disableGutters="true">
        <Stack>
          <Grid
            container
            className="loginInner"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={6} container justifyContent="center" alignContent="center">
              <Grid item xs={11} sm={9} md={7}>
                <Box container>
                  <img
                    src="../../static/logos/RVPulselogo.svg"
                    alt="Pulse Logo"
                    className="loginLogo"
                  />
                </Box>
                {/* <Typography variant="h4" className="loginTitle">
                  Partner Management System
                </Typography>
                <Typography variant="body2" className="loginSubTitle">
                  by Real Value Finloans
                </Typography> */}
                <Typography variant="h5" className="mb-1">
                  Sign In
                </Typography>
                <Typography variant="subtitle2" className="mb-3">
                  Enter your credentials
                </Typography>
                <FormControl fullWidth variant="standard" className="formGroupLogin mb-2">
                  <MailOutlineIcon className="formControlIcon" />
                  <InputBase
                    onChange={(e) => {
                      setEmailAddr(e.target.value);
                    }}
                    label="fullWidth"
                    placeholder="Email Address"
                    inputProps={{
                      'aria-label': 'email address',
                      className: 'formControlFull formControlFullE'
                    }}
                  />
                </FormControl>
                <FormControl fullWidth variant="standard" className="formGroupLogin mb-2">
                  <LockOpenIcon className="formControlIcon" />
                  <InputBase
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    label="fullWidth"
                    type="password"
                    placeholder="Password"
                    inputProps={{
                      'aria-label': 'password',
                      className: 'formControlFull formControlFullP'
                    }}
                  />
                </FormControl>
                <Grid
                  container
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem'
                  }}
                >
                  <Grid item>
                    <Checkbox size="medium" />
                    <Typography variant="">Remember Me</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="" className="mr-1">
                      Forgot Password?
                    </Typography>
                    <Link to="/" className="loginLink">
                      Reset
                    </Link>
                  </Grid>
                </Grid>
                <Button className="loginBtn mb-2" variant="button" onClick={(e) => handleLogin(e)}>
                  Sign In
                </Button>
                {/* <Box className="orDivider mb-2">or</Box> */}
                <Box className="mb-3 textAlignCenter">
                  Want to Become Partner Please &nbsp;
                  <Link to="/signup" className="">
                    <strong>Sign Up</strong>
                  </Link>
                  &nbsp;Here
                </Box>
                <Divider className="mb-2" />
                {/* <Grid
                  container
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem'
                  }}
                >
                  <Grid item>
                    <Button className="mainBtn" variant="button" onClick={handleLogin}>
                      Login
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button className="mainBtn" variant="button">
                      Sign Up
                    </Button>
                  </Grid>
                </Grid> */}
                <Grid container>
                  <Typography variant="">
                    Copyright © 2022{' '}
                    <a href="https://www.realvalue.loans/" target="_blank">
                      Real Value Finloans
                    </a>
                    {/* <a href="http://kalolytic.com/" target="_blank">
                      Kalolytic
                    </a> */}
                    {/* <a href="https://www.lessemi.com/lessEMI" target="_blank">
                      LessEMI
                    </a> */}
                    . All rights reserved.
                  </Typography>
                  {/* <Typography variant="">
                    Copyright © 2020{' '}
                    <a href="https://www.lessemi.com/lessEMI" target="_blank">
                      LessEMI
                    </a>
                    . All rights reserved.
                  </Typography> */}
                  {/* <Typography variant="">
                    Copyright © 2020{' '}
                    <a href="https://www.realvalue.loans/" target="_blank">
                      RealValue
                    </a>
                    . All rights reserved.
                  </Typography> */}
                  <Grid item>
                    {/* <Link href="/privacy-policy" target="_blank" className="loginLink mr-2"> */}
                    <Link
                      to="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="loginLink mr-2"
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      to="/term-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="loginLink"
                    >
                      Term of Use
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Box className="loginBanner">
                <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item sm={11} md={10} lg={10}>
                    <Box>
                      <img
                        src="../../static/login/personImage.png"
                        alt="Person"
                        className="loginRightImg"
                      />
                    </Box>
                    <Divider className="mb-2" />
                    <Typography variant="subtitle1" className="loginRightText">
                      The Pulse of Prosperity and Profits brought to you by
                    </Typography>
                    <Typography variant="h4" className="loginRightTextB">
                      Real Value Finloans
                    </Typography>
                    <Divider className="mt-2" />
                    {/* <Typography variant="body">
                      The Pulse of Prosperity and Profits brought to you by Real Value FinLoans.
                    </Typography> */}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle className="popupTitle">
                <Typography variant="h6">&nbsp;</Typography>
                <IconButton onClick={(e) => setOpen(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Box py={1} px={4} textAlign="center">
                    <Typography variant="subtitle2">{validationMsg}</Typography>
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" className="mainBtn" onClick={handleClose}>
                  Retry
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default Login;
