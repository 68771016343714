import { useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import exportIcon from '@iconify/icons-ant-design/export-outline';
import { CgAssign } from 'react-icons/cg';
import { FiEdit } from 'react-icons/fi';
import { MdNotificationsNone, MdSystemUpdateAlt } from 'react-icons/md';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Select,
  MenuItem
} from '@mui/material';
import { DateRange } from 'react-date-range';
import { navigate } from '@storybook/addon-links';
import { useNavigate } from 'react-router-dom';
import { RootStyle, SearchStyle, DateRangeStyled } from '../Global/Styling';
// ---------------------------------------------------------
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------

export default function MasterToolbar({ numSelected, filterName, onFilterName, parent }) {
  const navigate = useNavigate();
  const [isOpenFilter, openFilter] = useState(false);
  const [selectProduct, setSelectProduct] = useState(' ');
  const [selectCity, setSelectCity] = useState(' ');
  const [selectStatus, setSelectStatus] = useState(' ');
  const [selectSource, setSelectSource] = useState(' ');
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  console.log(filterDatesRange);
  return (
    <>
      {isOpenFilter && (
        <Box px={3} pt={2}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectProduct}`
                }}
                value={selectProduct}
                onChange={(e) => setSelectProduct(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Product
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs sx={{ position: 'relative' }}>
              <Box className="displayDateRange">
                {filterDatesRange[0].startDate !== null
                  ? `${fDate(filterDatesRange[0].startDate)} to `
                  : ''}
                {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
                {filterDatesRange[0].startDate === null && filterDatesRange[0].endDate === null ? (
                  <Typography className="dissabledMenu">Select Date Range</Typography>
                ) : (
                  ''
                )}
              </Box>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                MenuProps={{
                  className: 'menuOpened'
                }}
                value=" "
                className="datesSelect"
              >
                <DateRangeStyled
                  editableDateInputs={false}
                  onChange={(item) => setFilterDatesRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={filterDatesRange}
                  className="dateRangePicker"
                />
              </Select>
            </Grid>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectCity}`
                }}
                value={selectCity}
                onChange={(e) => setSelectCity(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select City
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectStatus}`
                }}
                value={selectStatus}
                onChange={(e) => setSelectStatus(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Status
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectSource}`
                }}
                value={selectSource}
                onChange={(e) => setSelectSource(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Source
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
      )}

      <RootStyle
        sx={{
          minHeight: 45,
          borderBottom: '1px solid #eaedf1'
        }}
      >
        <Box>
          <Tooltip title="Add new master" placement="top" arrow>
            <IconButton className="squareIconButton" onClick={(e) => navigate(`/${parent}/add`)}>
              <Icon icon={add} width={18} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Export data" placement="top" arrow>
            <IconButton className="squareIconButton">
              <MdSystemUpdateAlt size={18} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search Master"
            classes={{
              root: 'searchHolder',
              input: 'searchInput'
            }}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Filter list">
            <IconButton
              onClick={(e) => {
                if (isOpenFilter) {
                  openFilter(false);
                } else {
                  openFilter(true);
                }
              }}
            >
              <Icon icon={roundFilterList} />
            </IconButton>
          </Tooltip>
        </Box>
      </RootStyle>
    </>
  );
}
