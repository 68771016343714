import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  Grid,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses,
  Dialog,
  IconButton,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  Tabs,
  TextField,
  Tab
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';
// styling
import { StyledTableRow, StyledTableCell } from '../Global/Styling';
import PayableToolbar from './PayableToolbar';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'id', label: 'Invoice Id', alignRight: false },
  { id: 'created', label: 'Invoice Date', alignRight: false },
  { id: 'name', label: 'Partner Name', alignRight: false },
  { id: 'amount', label: 'Invoice Amount', alignRight: false },
  { id: 'amount', label: 'GST', alignRight: false },
  { id: 'amount', label: 'Total Amount', alignRight: false },
  { id: 'created', label: 'Due Date', alignRight: false },
  { id: 'statusPayable', label: 'Status', alignRight: false },
  { id: 'modifiedOn', label: 'Paid on', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function Payable() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [openPayment, setOpenPayment] = useState(false);
  const [selectDate, setSelectDate] = useState(' ');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'bold',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px'
    }
  }));
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Partner Management System || Payable">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="List of Partner Invoice" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Card>
              <PayableToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                parent="payable"
              />
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          routingNumber,
                          invoiceNo,
                          id,
                          name,
                          leadId,
                          amount,
                          amount2,
                          amount3,
                          name1,
                          name2,
                          role,
                          city,
                          partnerName,
                          transactionType,
                          status,
                          segment,
                          active,
                          created,
                          partnerType,
                          loanType,
                          statusPayable,
                          users
                        } = row;
                        console.log('users', users);
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="">{id}</StyledTableCell>
                            <StyledTableCell align="">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="">{partnerName}</StyledTableCell>
                            <StyledTableCell align="">{amount}</StyledTableCell>
                            <StyledTableCell align="">{amount}</StyledTableCell>
                            <StyledTableCell align="">{amount}</StyledTableCell>
                            <StyledTableCell align="">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (statusPayable === 'Payment Due' && 'error') ||
                                  (statusPayable === 'Paid' && 'success') ||
                                  'success'
                                }
                              >
                                {sentenceCase(statusPayable)}
                              </Label>
                            </StyledTableCell>
                            <StyledTableCell align="">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="" width={110}>
                              <UserMoreMenu
                                rowId={id}
                                parent="payable"
                                setOpenPayment={setOpenPayment}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {/* payment pop up start */}
              <Dialog open={openPayment} onClose={(e) => setOpenPayment(false)} fullWidth>
                <DialogTitle className="popupTitle">
                  <Typography variant="h6">Payable</Typography>
                  <IconButton onClick={(e) => setOpenPayment(false)} size="small">
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Typography variant="body2" className="formLabel">
                          Payment Date
                        </Typography>
                        <TextField
                          type="date"
                          inputProps={{
                            className: `textInput dissabledMenu${selectDate}`
                          }}
                          placeholder="Select Date"
                          value={selectDate}
                          onChange={(e) => setSelectDate(e.target.value)}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Typography variant="body2" className="formLabel">
                          Invoice Amount
                        </Typography>
                        <TextField
                          inputProps={{
                            className: 'textInput'
                          }}
                          value="49,540"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Typography variant="body2" className="formLabel">
                          TDS Deduction
                        </Typography>
                        <TextField
                          inputProps={{
                            className: 'textInput'
                          }}
                          value="5,693"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Typography variant="body2" className="formLabel">
                          GST Paid
                        </Typography>
                        <TextField
                          inputProps={{
                            className: 'textInput'
                          }}
                          value="9,450"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Typography variant="body2" className="formLabel">
                          Net Amount Paid
                        </Typography>
                        <TextField
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Net Amount Paid"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Typography variant="body2" className="formLabel">
                          Payment Mode
                        </Typography>
                        <TextField
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Payment Mode"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* <Box mb={2}>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        inputProps={{
                          className: `textInput customSelectBox dissabledMenu${personValue}`
                        }}
                        value={personValue}
                        onChange={(e) => setPersonValue(e.target.value)}
                      >
                        <MenuItem value=" " disabled>
                          Select Persons
                        </MenuItem>
                        <MenuItem value="Ramesh">Ramesh</MenuItem>
                        <MenuItem value="Mahesh">Mahesh</MenuItem>
                      </Select>
                    </FormControl>
                  </Box> */}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={(e) => setOpenPayment(false)}
                    variant="contained"
                    className="mainBtn"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
              {/* payment pop up end */}
            </Card>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
