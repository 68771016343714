import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { AiOutlineClose } from 'react-icons/ai';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import UserMoreMenu from '../../components/_dashboard/user/UserMoreMenu';
import UserListHead from '../../components/_dashboard/user/UserListHead';
import UserListToolbar from '../../components/_dashboard/user/UserListToolbar';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
import Loader from '../Global/Loader';
//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';
import LogInToolbar from './LogInToolbar';
import leadsService from '../services/leadsService';

// API
import loginListService from '../services/loginListApplication';
import partnerService from '../services/partnerService';

const { LoginList, ListStageStatusLogin } = loginListService;
const { LoanTypes, CityList, SourceList } = partnerService;
// const { SourceList } = leadsService;

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'APPLICATION ID', alignRight: false },
  { id: 'created', label: 'Submission Date', alignRight: false },
  { id: 'name', label: 'NAME', alignRight: false },
  { id: 'loanType', label: 'Product', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'bankName', label: 'Bank', alignRight: false },
  { id: 'status', label: 'status', alignRight: false },
  { id: 'created', label: 'Last update', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function LogIn() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('created');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [pageMenu, setPageMenu] = useState([]); // State for store localstorage data so that we can provide data to others components

  const [openOpportunity, setOpenOpportunity] = useState(false);
  const [statusValue, setStatusValue] = useState('');

  const [loginList, setLoginList] = useState([]);
  const [selectProduct, setSelectProduct] = useState(' ');
  const [loanTypeItem, setLoanTypeItem] = useState([]);
  const [refresh, setRefresh] = useState('');
  // filter
  const [searchTerms, setSearchTerms] = useState(' ');
  const [selectStatus, setSelectStatus] = useState(' '); // select status
  const [statusList, setStatusList] = useState([]); // select status data from api
  const [selectSource, setSelectSource] = useState(' '); // select source
  const [sourceListData, setSourceListData] = useState([]); // source list data from api
  const [cityListMenu, setCityListMenu] = useState([]);
  const [cityListData, setCityListData] = useState(null);
  const [openLoader, setOpenLoader] = useState(false); // loader
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  console.log('loginList', loginList);
  console.log('selected21', selected);

  const columns = [
    { field: 'leadId', headerName: 'ID', width: 90, hide: true },
    { field: 'calldate', headerName: 'CALL DATE', width: 210 },
    { field: 'callagent', headerName: 'CALL AGENT', width: 180 },
    { field: 'calltime', headerName: 'CALL TIME', width: 220 },
    { field: 'callstatus', headerName: 'CALL STATUS', width: 200 },
    { field: 'comments', headerName: 'COMMENTS', width: 200 }
  ];
  const rows = [
    {
      id: 1,
      calldate: 'Lorem',
      callagent: 'Lorem',
      calltime: 'Lorem',
      callstatus: 'Lorem',
      comments: 'Lorem'
    },
    {
      id: 2,
      calldate: 'Lorem',
      callagent: 'Lorem',
      calltime: 'Lorem',
      callstatus: 'Lorem',
      comments: 'Lorem'
    },
    {
      id: 3,
      calldate: 'Lorem',
      callagent: 'Lorem',
      calltime: 'Lorem',
      callstatus: 'Lorem',
      comments: 'Lorem'
    }
  ];

  const handleRequestSort = (event, property) => {
    if (property === 'created') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = loginList.map((n) => {
        console.log(n);
        return n;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    // setSearchTerms(event.target.value);
    setFilterName(event.target.value);
  };

  // select status
  const handleSelectStatus = (e) => {
    setSelectStatus(e.target.value);
  };

  // select source
  const handleSelectSource = (e) => {
    setSelectSource(e.target.value);
  };

  // select product field
  const handleSelectProduct = (e) => {
    setSelectProduct(e.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - loginList.length) : 0;

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'normal',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: 4
    }
  }));
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }
    const token1 = localJWTtoken.token.jwt;
    const companyId = localJWTtoken.token.companyId;
    const userId = localJWTtoken.token.userId;
    (async () => {
      // Fired Api of Bank name
      const ifPreviewDataAvailable = await LoanTypes(token1);
      if (ifPreviewDataAvailable.status === 'success') {
        setLoanTypeItem(ifPreviewDataAvailable.data.loanTypes);
      }

      // select status api
      const credentials1 = {
        stageId: 58
      };
      const ListStageStatusLoginApiResponse = await ListStageStatusLogin(credentials1, token1);
      if (ListStageStatusLoginApiResponse.msg) {
        if (ListStageStatusLoginApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ListStageStatusLoginApiResponse.status === 'success') {
        setStatusList(ListStageStatusLoginApiResponse.data.loginStageStatuses);
      }

      // select source
      const SourceListApiResponse = await SourceList(token1);
      if (SourceListApiResponse.msg) {
        if (SourceListApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (SourceListApiResponse.status === 'success') {
        setSourceListData(SourceListApiResponse.data.prospectSourceTypes);
      }

      // select city  api for filter
      const CityListApiResponse = await CityList(
        {
          searchLiteral: '',
          stateId: null
        },
        token1
      );
      console.log('CityListApiResponse', CityListApiResponse);
      if (CityListApiResponse.action_status === 'success') {
        setCityListMenu(CityListApiResponse.data);
      } else {
        setCityListMenu([]);
      }
    })();
  }, []);
  useEffect(() => {
    // get meneu access for user from localstorage which store in <NavSection /> component
    const sidebarMenu = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d74');
    if (sidebarMenu !== null) {
      const bytes = CryptoJS.AES.decrypt(sidebarMenu, 'sidebarMenu');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      const pageCode = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d75');
      const pageCodeBytes = CryptoJS.AES.decrypt(pageCode, 'pageCode');
      const pageCodeText = pageCodeBytes.toString(CryptoJS.enc.Utf8);
      console.log('ciphertext', pageCodeText);
      JSON.parse(originalText).accessPagesMapping.forEach((accessPageMap) => {
        if (accessPageMap.pageCode === pageCodeText) {
          setPageMenu(accessPageMap.accessSubPagesMappings);
        }
      });
    }
    setOpenLoader(true);
    // Login list Api
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }
    const token = localJWTtoken.token.jwt;
    const companyId = localJWTtoken.token.companyId;
    const userId = localJWTtoken.token.userId;
    setTimeout(async () => {
      const credential = {
        companyId: companyId,
        userId: userId,
        entityListRequestModel: {
          searchLiteral: filterName !== '' ? filterName : null,
          pageSize: 500,
          pageNumber: 1,
          sortOrder: order
        },
        leadsFilterModel: {
          loanId: selectProduct !== ' ' ? selectProduct : null,
          leadStageStatusId: selectStatus !== ' ' ? selectStatus : null,
          sourceTypeId: selectSource !== ' ' ? selectSource : null,
          cityId: cityListData === null ? null : cityListData.cityId,
          fromLeadDate: filterDatesRange[0].startDate,
          toLeadDate: filterDatesRange[0].endDate
        }
      };
      // Fired Api of Login list
      const ifPreviewLoginListAPI = await LoginList(credential, token);
      console.log('ifPreviewLoginListAPI', ifPreviewLoginListAPI);
      if (ifPreviewLoginListAPI.status === 'success') {
        setOpenLoader(false);
        setLoginList(ifPreviewLoginListAPI.data.logins);
      } else if (ifPreviewLoginListAPI.status === 'error') {
        localStorage.clear();
        Navigate('/');
      }
    }, 1000);
    // // Fired Api of Bank name
    // const ifPreviewDataAvailable = await LoanTypes(token);
    // if (ifPreviewDataAvailable.status === 'success') {
    //   setLoanTypeItem(ifPreviewDataAvailable.data.loanTypes);
    // }

    // // select status api
    // const credentials1 = {
    //   stageId: 58
    // };
    // const ListStageStatusLoginApiResponse = await ListStageStatusLogin(credentials1, token);
    // if (ListStageStatusLoginApiResponse.msg) {
    //   if (ListStageStatusLoginApiResponse.msg === 'Forbidden') {
    //     navigate('/');
    //   }
    // }
    // if (ListStageStatusLoginApiResponse.status === 'success') {
    //   setStatusList(ListStageStatusLoginApiResponse.data.loginStageStatuses);
    // }

    // // select source
    // const SourceListApiResponse = await SourceList(token);
    // if (SourceListApiResponse.msg) {
    //   if (SourceListApiResponse.msg === 'Forbidden') {
    //     navigate('/');
    //   }
    // }
    // if (SourceListApiResponse.status === 'success') {
    //   setSourceListData(SourceListApiResponse.data.prospectSourceTypes);
    // }

    // // select city  api for filter
    // const CityListApiResponse = await CityList(
    //   {
    //     searchLiteral: '',
    //     stateId: null
    //   },
    //   token
    // );
    // console.log('CityListApiResponse', CityListApiResponse);
    // if (CityListApiResponse.action_status === 'success') {
    //   setCityListMenu(CityListApiResponse.data);
    // } else {
    //   setCityListMenu([]);
    // }
    // console.log('ifPreviewLoginListAPI', credential);
  }, [
    refresh,
    filterName,
    selectProduct,
    selectStatus,
    selectSource,
    cityListData,
    filterDatesRange,
    order
  ]);
  return (
    <Page title="PULSE by Real Value Finloans || Log In">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="List of Application" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <LogInToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="login"
              pageMenu={pageMenu}
              selectProduct={selectProduct}
              loanTypeItem={loanTypeItem}
              setSelectProduct={setSelectProduct}
              onFilterProduct={handleSelectProduct}
              onFilterStatus={handleSelectStatus}
              statusList={statusList}
              selectStatus={selectStatus}
              selectSource={selectSource}
              onFilterSource={handleSelectSource}
              sourceListData={sourceListData}
              selected={selected}
              filterDatesRange={filterDatesRange}
              setFilterDatesRange={setFilterDatesRange}
              cityListMenu={cityListMenu}
              cityListData={cityListData}
              setCityListData={setCityListData}
              setRefresh={setRefresh}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={loginList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {loginList !== undefined
                      ? loginList.map((item, index) => {
                          console.log(item);
                          const isItemSelected = selected.indexOf(item) !== -1;
                          return (
                            <StyledTableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, item)}
                                />
                              </TableCell>

                              <StyledTableCell align="left">
                                {item.applicationId ? item.applicationId : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {fDate(item.leadStagesCreatedOn)}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.customerName ? item.customerName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.loanName ? item.loanName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.cityName ? item.cityName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.loanAmountRequested ? item.loanAmountRequested : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.bankName ? item.bankName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Label
                                  variant="ghost"
                                  color={
                                    (item.stageStatusName === 'Log in' && 'success') ||
                                    (item.stageStatusName === 'U-Sanction' && 'success') ||
                                    (item.stageStatusName === null && 'error') ||
                                    (item.stageStatusName === 'Closed' && 'warning') ||
                                    (item.stageStatusName === 'Rejected' && 'warning') ||
                                    'success'
                                  }
                                >
                                  {sentenceCase(
                                    item.stageStatusName ? item.stageStatusName : 'null'
                                  )}
                                </Label>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.leadStagesLastUpdatedOn
                                  ? fDate(item.leadStagesLastUpdatedOn)
                                  : 'N/A'}
                              </StyledTableCell>

                              <StyledTableCell align="" width={110}>
                                <UserMoreMenu
                                  rowId={item.leadId}
                                  parent="login"
                                  setOpenOpportunity={setOpenOpportunity}
                                  pageMenu={pageMenu}
                                  companyId={item.companyId}
                                  applicationId={item.applicationId}
                                  statusId={item.leadStageStatusId}
                                  statusName={item.stageStatusName}
                                  item={item}
                                  // userId={item.userId}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      : ''}
                    {/* {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const {
                          id,
                          leadId,
                          amount,
                          name,
                          loanType,
                          status,
                          users,
                          city,
                          bankName,
                          statusLogin,
                          created,
                          transactionType,
                          isVerified
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{leadId}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{bankName}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (statusLogin === 'Log in' && 'success') ||
                                  (statusLogin === 'U-Sanction' && 'success') ||
                                  (statusLogin === 'Closed' && 'warning') ||
                                  (statusLogin === 'Rejected' && 'warning') ||
                                  'success'
                                }
                              >
                                {sentenceCase(statusLogin)}
                              </Label>
                            </StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>

                            <StyledTableCell align="" width={110}>
                              <UserMoreMenu
                                rowId={id}
                                parent="login"
                                setOpenOpportunity={setOpenOpportunity}
                                pageMenu={pageMenu}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })} */}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {loginList.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          align="center"
                          colSpan={TABLE_HEAD.length + 1}
                          sx={{ py: 3 }}
                        >
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Card>
          <Dialog open={openOpportunity} onClose={(e) => setOpenOpportunity(false)} fullWidth>
            <Box
              display="flex"
              justifyContent="space-between"
              backgroundColor="#166bac"
              color="white"
            >
              <Box>
                <DialogTitle>Update Status</DialogTitle>
              </Box>
              <Button onClick={(e) => setOpenOpportunity(false)}>
                <AiOutlineClose color="white" />
              </Button>
            </Box>
            <DialogContent>
              <Box mb={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-select-small">Update Status</InputLabel>
                  <Select
                    id="demo-simple-select"
                    fullWidth
                    value={statusValue}
                    label="Status"
                    onChange={(e) => setStatusValue(e.target.value)}
                  >
                    <MenuItem value="noContact">No Contact</MenuItem>
                    <MenuItem value="invalidNumber">Invalid Number</MenuItem>
                    <MenuItem value="abort">Abort</MenuItem>
                    <MenuItem value="contacted">Contacted</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <TextField
                  id="outlined-basic"
                  label="comments"
                  variant="outlined"
                  multiline
                  fullWidth
                />
              </Box>
              <Box height={100}>
                <DataGrid
                  headerHeight={40}
                  rowHeight={38}
                  className="customDataGrid globalDataGridRoot"
                  rows={rows}
                  columns={columns}
                  pageSize={100}
                  hideFooter
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => setOpenOpportunity(false)} variant="contained">
                SUBMIT
              </Button>
            </DialogActions>
          </Dialog>
          <Loader openLoader={openLoader} />
        </Box>
      </Container>
    </Page>
  );
}
