import React, { useState } from 'react';
import {
  Step,
  Stepper,
  StepLabel,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  Tab,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  DialogTitle,
  FormLabel,
  FormControlLabel,
  Card,
  Autocomplete
} from '@mui/material';
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';
import { IoIosAlert } from 'react-icons/io';

// table component
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ImFolderUpload } from 'react-icons/im';
import { MdOutlinePreview, MdUploadFile, MdUpload, MdOutlineAdd } from 'react-icons/md';
import { BiCloudUpload } from 'react-icons/bi';
import { DiGoogleDrive } from 'react-icons/di';
import { AiOutlineDropbox } from 'react-icons/ai';
import { CgDigitalocean } from 'react-icons/cg';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useParams } from 'react-router-dom';

// import { CloseIcon } from 'react-icons'
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import pages
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));
function EditAgreement() {
  const { rowId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };
  return (
    <Page title="Partner Managemet System || Agreement">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Edit Agreement" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Stepper
            connector=""
            activeStep={activeStep}
            sx={{ marginBottom: '25px' }}
            className="stepWizardOuter"
          >
            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Add Bank</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Add Branch</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Add Commission</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Upload Agreement</StepLabel>
            </Step>

            <Step className="stepWizard">
              <StepLabel className="stepWizardLabel">Review</StepLabel>
            </Step>
          </Stepper>
          {activeStep === 0 ? (
            <Step1Content handleNext={handleNext} />
          ) : activeStep === 1 ? (
            <Step2Content handleNext={handleNext} handlePrev={handlePrev} />
          ) : activeStep === 2 ? (
            <Step3Content handleNext={handleNext} handlePrev={handlePrev} />
          ) : activeStep === 3 ? (
            <Step4Content handleNext={handleNext} handlePrev={handlePrev} />
          ) : (
            <Step5Content handlePrev={handlePrev} />
          )}
        </Box>
      </Container>
    </Page>
  );
}

function Step1Content({ handleNext }) {
  const [selectCommissionStructure, setSelectCommissionStructure] = useState(' ');
  const [selectTurnoverBenefit, setSelectTurnoverBenefit] = useState(' ');
  const [selectLoginType, setSelectLoginType] = useState(' ');
  return (
    <>
      <Box className="viewLeadCard">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Bank Name
              </Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo2"
                options={topBanks}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select Bank Name" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Login Type
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectLoginType}`
                }}
                value={selectLoginType}
                onChange={(e) => setSelectLoginType(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Login Type
                </MenuItem>
                <MenuItem value="DSA">DSA</MenuItem>
                <MenuItem value="DST">DST</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                PAN No
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type PAN No"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                GSTIN
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type GSTIN"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Commission Type
              </Typography>
              <Select
                fullWidth
                value={selectCommissionStructure}
                onChange={(e) => setSelectCommissionStructure(e.target.value)}
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectCommissionStructure}`
                }}
              >
                <MenuItem value=" " disabled>
                  Select Commission Structure
                </MenuItem>
                <MenuItem value="Branch">Branch</MenuItem>
                <MenuItem value="Bank">Bank</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Turnover Benefit
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectTurnoverBenefit}`
                }}
                value={selectTurnoverBenefit}
                onChange={(e) => setSelectTurnoverBenefit(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Turnover Benefit
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Login Code
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Login Code"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handleNext}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function Step2Content({ handleNext, handlePrev }) {
  const [selectBranch, setSelectBranch] = useState(' ');
  return (
    <>
      <Box className="viewLeadCard" mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Branch Name
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Branch Name"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Branch Address
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Bank Address"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Contact Person
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Contact Person"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                City
              </Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={topCities}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select City" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                State
              </Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo1"
                options={topStates}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select State" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Login Code
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Login Type"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box textAlign="right">
        <Button className="mainBtn otherBtn" variant="contained">
          Add Branch
        </Button>
      </Box>
      <Box className="greenBorder tableBox" mt={3}>
        <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Branch Name</StyledTableCell>
                <StyledTableCell align="">Branch Address</StyledTableCell>
                <StyledTableCell align="">Contact Person</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="">HDFC</StyledTableCell>
                <StyledTableCell align="">Ramesh Vihar, New Delhi</StyledTableCell>
                <StyledTableCell align="">Ramesh</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function Step3Content({ handleNext, handlePrev }) {
  const [selectLoan, setSelectLoan] = useState(' ');
  const [selectBranch, setSelectBranch] = useState(' ');
  const [showHide, setShowHide] = useState('Individual');
  const handleShowHide = (event) => {
    const getUserType = event.target.value;
    setShowHide(getUserType);
  };
  const [userType, setUserType] = useState('Individual');
  return (
    <>
      <Box className="">
        <Box className="mb-3">
          <Typography variant="h6" className="formLabel">
            Commision Structure
          </Typography>
          <FormControl sx={{ width: '100%' }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Individual"
              name="radio-buttons-group"
              value={userType}
              onChange={(e) => {
                handleShowHide(e);
                setUserType(e.target.value);
              }}
            >
              <Box className="displayFlex alignItemCenter">
                <FormControlLabel
                  value="Individual"
                  control={<Radio size="small" />}
                  sx={{ marginRight: '1rem' }}
                  label="Individual"
                />
                <FormControlLabel
                  value="Volume"
                  control={<Radio size="small" />}
                  sx={{ marginRight: '1rem' }}
                  label="Volume"
                />
                <FormControlLabel
                  value="Both"
                  control={<Radio size="small" />}
                  sx={{ marginRight: '1rem' }}
                  label="Both"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
        {showHide === 'Individual' && (
          <>
            <Box className="viewLeadCard" mb={3}>
              <Grid container spacing={3} alignItems="end">
                <Grid item xs>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Loan Type
                        </Typography>
                        <Select
                          fullWidth
                          value={selectLoan}
                          onChange={(e) => setSelectLoan(e.target.value)}
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectLoan}`
                          }}
                        >
                          <MenuItem value=" " disabled>
                            Select Loan Type
                          </MenuItem>
                          <MenuItem value="Home Loan">Home Loan</MenuItem>
                          <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                          <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                          <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                          <MenuItem value="Education Loan">Education Loan</MenuItem>
                          <MenuItem value="Credit Card">Credit Card</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Branch
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectBranch}`
                          }}
                          value={selectBranch}
                          onChange={(e) => setSelectBranch(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Branch
                          </MenuItem>
                          <MenuItem value="HDFC">HDFC</MenuItem>
                          <MenuItem value="ICICI">ICICI</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Commission %
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Commission %"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
                    <IconButton className="squareIconButton">
                      <MdOutlineAdd size={18} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
            <Box className="greenBorder tableBox">
              <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: '' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Loan Type</StyledTableCell>
                      <StyledTableCell align="left">Branch</StyledTableCell>
                      <StyledTableCell align="left">Commission %</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="left">Home loan</StyledTableCell>
                      <StyledTableCell align="left">HDFC Ramesh Vihar</StyledTableCell>
                      <StyledTableCell align="left">5%</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
        {showHide === 'Volume' && (
          <>
            <Box className="viewLeadCard" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Loan Type
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${selectLoan}`
                      }}
                      value={selectLoan}
                      onChange={(e) => setSelectLoan(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Loan Type
                      </MenuItem>
                      <MenuItem value="Home Loan">Home Loan</MenuItem>
                      <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                      <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                      <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                      <MenuItem value="Education Loan">Education Loan</MenuItem>
                      <MenuItem value="Credit Card">Credit Card</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Branch
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${selectBranch}`
                      }}
                      value={selectBranch}
                      onChange={(e) => setSelectBranch(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Branch
                      </MenuItem>
                      <MenuItem value="HDFC">HDFC</MenuItem>
                      <MenuItem value="ICICI">ICICI</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Amount
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Amount"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Duration
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Duration"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Commission %
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Commission"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="mb-3">
                    <Typography variant="h6" className="formLabel">
                      Unit
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type Unit"
                        sx={{ marginRight: '1rem' }}
                      />
                      <Tooltip title="Add" placement="top" arrow>
                        <IconButton className="squareIconButton">
                          <MdOutlineAdd size={16} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="greenBorder tableBox">
              <TableContainer sx={{ minWidth: 800, overflow: 'auto' }}>
                <Table border={1} borderColor="#ebedf2">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Loan Type</StyledTableCell>
                      <StyledTableCell align="left">Branch</StyledTableCell>
                      <StyledTableCell align="left">Amount</StyledTableCell>
                      <StyledTableCell align="left">Duration</StyledTableCell>
                      <StyledTableCell align="left">Commission %</StyledTableCell>
                      <StyledTableCell align="left">Unit</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="left">Home Loan</StyledTableCell>
                      <StyledTableCell align="left">HDFC Ramesh Vihar</StyledTableCell>
                      <StyledTableCell align="left">10,0000</StyledTableCell>
                      <StyledTableCell align="left">10 Yr</StyledTableCell>
                      <StyledTableCell align="left">5%</StyledTableCell>
                      <StyledTableCell align="left">10</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
        {showHide === 'Both' && (
          <>
            <Box className="viewLeadCard" mb={3}>
              <Grid container spacing={3} alignItems="end">
                <Grid item xs>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Loan Type
                        </Typography>
                        <Select
                          fullWidth
                          value={selectLoan}
                          onChange={(e) => setSelectLoan(e.target.value)}
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectLoan}`
                          }}
                        >
                          <MenuItem value=" " disabled>
                            Select Loan Type
                          </MenuItem>
                          <MenuItem value="Home Loan">Home Loan</MenuItem>
                          <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                          <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                          <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                          <MenuItem value="Education Loan">Education Loan</MenuItem>
                          <MenuItem value="Credit Card">Credit Card</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Branch
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${selectBranch}`
                          }}
                          value={selectBranch}
                          onChange={(e) => setSelectBranch(e.target.value)}
                        >
                          <MenuItem value=" " disabled>
                            Select Branch
                          </MenuItem>
                          <MenuItem value="HDFC">HDFC</MenuItem>
                          <MenuItem value="ICICI">ICICI</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Commission %
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Commission %"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Add" placement="top" arrow sx={{ marginBottom: '4px' }}>
                    <IconButton className="squareIconButton">
                      <MdOutlineAdd size={18} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
            <Box className="greenBorder tableBox" mb={3}>
              <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: '' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Loan Type</StyledTableCell>
                      <StyledTableCell align="left">Branch</StyledTableCell>
                      <StyledTableCell align="left">Commission %</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="left">Home loan</StyledTableCell>
                      <StyledTableCell align="left">HDFC Ramesh Vihar</StyledTableCell>
                      <StyledTableCell align="left">5%</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box className="viewLeadCard" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Loan Type
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${selectLoan}`
                      }}
                      value={selectLoan}
                      onChange={(e) => setSelectLoan(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Loan Type
                      </MenuItem>
                      <MenuItem value="Home Loan">Home Loan</MenuItem>
                      <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                      <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                      <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                      <MenuItem value="Education Loan">Education Loan</MenuItem>
                      <MenuItem value="Credit Card">Credit Card</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Branch
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${selectBranch}`
                      }}
                      value={selectBranch}
                      onChange={(e) => setSelectBranch(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Branch
                      </MenuItem>
                      <MenuItem value="HDFC">HDFC</MenuItem>
                      <MenuItem value="ICICI">ICICI</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Amount
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Amount"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Duration
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Duration"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Commission %
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Commission"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="mb-3">
                    <Typography variant="h6" className="formLabel">
                      Unit
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type Unit"
                        sx={{ marginRight: '1rem' }}
                      />
                      <Tooltip title="Add" placement="top" arrow>
                        <IconButton className="squareIconButton">
                          <MdOutlineAdd size={16} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="greenBorder tableBox">
              <TableContainer sx={{ minWidth: 800, overflow: 'auto' }}>
                <Table border={1} borderColor="#ebedf2">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Loan Type</StyledTableCell>
                      <StyledTableCell align="left">Branch</StyledTableCell>
                      <StyledTableCell align="left">Amount</StyledTableCell>
                      <StyledTableCell align="left">Duration</StyledTableCell>
                      <StyledTableCell align="left">Commission %</StyledTableCell>
                      <StyledTableCell align="left">Unit</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="left">Home Loan</StyledTableCell>
                      <StyledTableCell align="left">HDFC Ramesh Vihar</StyledTableCell>
                      <StyledTableCell align="left">10,0000</StyledTableCell>
                      <StyledTableCell align="left">10 Yr</StyledTableCell>
                      <StyledTableCell align="left">5%</StyledTableCell>
                      <StyledTableCell align="left">10</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function Step4Content({ handleNext, handlePrev }) {
  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  return (
    <Box>
      <Box variant="Container" className="uploadBox">
        <label htmlFor="contained-button-file" onChange={handleChange}>
          <Input accept="image/*" id="contained-button-file" multiple type="file" />
          <Box>
            <BiCloudUpload className="upladIcon" />
            <Typography variant="h5">Upload Agreement</Typography>
          </Box>
        </label>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          endIcon={<HiOutlineArrowNarrowRight />}
          className="mainBtn nextBtn"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

function Step5Content({ handlePrev }) {
  return (
    <Box>
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Bank Details
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
        <Grid item container spacing={{ xs: 1, sm: 2, md: 3 }} marginBottom={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" className="reviewLabel">
              Bank Name
            </Typography>
            <Typography variant="subtitle2" className="reviewValue">
              HDFC
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" className="reviewLabel">
              Address
            </Typography>
            <Typography variant="subtitle2" className="reviewValue">
              Ramesh Nagar, New Delhi
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" className="reviewLabel">
              PAN number
            </Typography>
            <Typography variant="subtitle2" className="reviewValue">
              AHVBGF24653S
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" className="reviewLabel">
              GSTIN
            </Typography>
            <Typography variant="subtitle2" className="reviewValue">
              GST12453687
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" className="reviewLabel">
              Commission Structure
            </Typography>
            <Typography variant="subtitle2" className="reviewValue">
              Bank
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" className="reviewLabel">
              Turnover Benefit
            </Typography>
            <Typography variant="subtitle2" className="reviewValue">
              Yes
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Branch Details
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
        <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Branch Name</StyledTableCell>
                <StyledTableCell align="">Branch Address</StyledTableCell>
                <StyledTableCell align="">Contact Person</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="">HDFC</StyledTableCell>
                <StyledTableCell align="">Ramesh Vihar, New Delhi</StyledTableCell>
                <StyledTableCell align="">Ramesh</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Commission Details
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
        <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Loan Type</StyledTableCell>
                <StyledTableCell align="left">Branch</StyledTableCell>
                <StyledTableCell align="left">Commission %</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">Home loan</StyledTableCell>
                <StyledTableCell align="left">HDFC Ramesh Vihar</StyledTableCell>
                <StyledTableCell align="left">5%</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Turnover Benefit
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin">
        <TableContainer sx={{ minWidth: 800, overflow: 'auto' }} aria-label="simple table">
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Loan Type</StyledTableCell>
                <StyledTableCell align="left">Branch</StyledTableCell>
                <StyledTableCell align="left">Duration</StyledTableCell>
                <StyledTableCell align="left">Commission %</StyledTableCell>
                <StyledTableCell align="left">Unit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">Home Loan</StyledTableCell>
                <StyledTableCell align="left">HDFC Ramesh Vihar</StyledTableCell>
                <StyledTableCell align="left">10 Yr</StyledTableCell>
                <StyledTableCell align="left">5%</StyledTableCell>
                <StyledTableCell align="left">10</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button endIcon={<HiOutlineArrowNarrowRight />} className="mainBtn nextBtn">
          Finish
        </Button>
      </Box>
    </Box>
  );
}

export default EditAgreement;

// Top Banks
const topBanks = [
  { label: 'Axis Bank' },
  { label: 'HDFC Bank' },
  { label: 'HDFC Ltd.' },
  { label: 'ICICI Bank' },
  { label: 'PNB Housing' },
  { label: 'Piramal Housing' },
  { label: 'Standard Chartered Bank' },
  { label: 'Yes Bank' },
  { label: 'Aditya Birla Housing Finance' },
  { label: 'IDFC Bank' },
  { label: 'RBL Bank' },
  { label: 'Federal Bank' },
  { label: 'Fedfina' },
  { label: 'HDB Financial' },
  { label: 'IIFL Finance' },
  { label: 'Tata Capital' },
  { label: 'ICICI Home Finance' },
  { label: 'Cholamandalam Finance' },
  { label: 'L&T Finance' },
  { label: 'Karur Vysya Bank' },
  { label: 'Hero Housing Finance' },
  { label: 'Kotak Mahindra Bank' },
  { label: 'Fullerton Grihashakti' },
  { label: 'Anand Rathi Financial' },
  { label: 'Aadhar Housing Finance' },
  { label: 'Godrej Housing Finance' },
  { label: 'Deutsche Bank' },
  { label: 'Jana Small Finance Bank' },
  { label: 'HSBC Bank' },
  { label: 'DCB Bank' },
  { label: 'Bank of Baroda' },
  { label: 'Bajaj Housing Finance' },
  { label: 'LIC Housing Finance' },
  { label: 'AU Small Finance Bank' },
  { label: 'Union Bank of India' },
  { label: 'State Bank of India' }
];

// Top Cities
const topCities = [
  { label: 'Mumbai' },
  { label: 'Delhi' },
  { label: 'Bengaluru' },
  { label: 'Ahmedabad' },
  { label: 'Hyderabad' },
  { label: 'Chennai' },
  { label: 'Kolkata' },
  { label: 'Pune' },
  { label: 'Jaipur' },
  { label: 'Surat' },
  { label: 'Lucknow' },
  { label: 'Kanpur' },
  { label: 'Nagpur' },
  { label: 'Patna' },
  { label: 'Indore' },
  { label: 'Thane' },
  { label: 'Bhopal' },
  { label: 'Visakhapatnam' },
  { label: 'Vadodara' },
  { label: 'Firozabad' },
  { label: 'Ludhiana' },
  { label: 'Rajkot' },
  { label: 'Agra' },
  { label: 'Siliguri' },
  { label: 'Nashik' },
  { label: 'Faridabad' },
  { label: 'Patiala' },
  { label: 'Meerut' },
  { label: 'Varanasi' },
  { label: 'Dhanbad' },
  { label: 'Jodhpur' },
  { label: 'Amritsar' },
  { label: 'Raipur' },
  { label: 'Allahabad' },
  { label: 'Coimbatore' },
  { label: 'Jabalpur' },
  { label: 'Gwalior' },
  { label: 'Vijayawada' },
  { label: 'Madurai' },
  { label: 'Guwahati' },
  { label: 'Chandigarh' },
  { label: 'Hubli-Dharwad' },
  { label: 'Amroha' },
  { label: 'Moradabad' },
  { label: 'Gurgaon' },
  { label: 'Aligarh' },
  { label: 'Solapur' },
  { label: 'Ranchi' },
  { label: 'Jalandhar' },
  { label: 'Tiruchirappalli' },
  { label: 'Bhubaneswar' },
  { label: 'Salem' },
  { label: 'Warangal' },
  { label: 'Mira-Bhayandar' },
  { label: 'Thiruvananthapuram' },
  { label: 'Bhiwandi' },
  { label: 'Saharanpur' },
  { label: 'Guntur' },
  { label: 'Amravati' },
  { label: 'Bikaner' },
  { label: 'Noida' },
  { label: 'Jamshedpur' },
  { label: 'Bhilai Nagar' },
  { label: 'Cuttack' },
  { label: 'Kochi' },
  { label: 'Udaipur' },
  { label: 'Bhavnagar' },
  { label: 'Dehradun' },
  { label: 'Asansol' },
  { label: 'Nanded-Waghala' },
  { label: 'Ajmer' },
  { label: 'Jamnagar' },
  { label: 'Ujjain' },
  { label: 'Sangli' },
  { label: 'Loni' },
  { label: 'Jhansi' },
  { label: 'Pondicherry' },
  { label: 'Nellore' },
  { label: 'Jammu' },
  { label: 'Belagavi' },
  { label: 'Raurkela' },
  { label: 'Mangaluru' },
  { label: 'Tirunelveli' },
  { label: 'Malegaon' },
  { label: 'Gaya' },
  { label: 'Tiruppur' },
  { label: 'Davanagere' },
  { label: 'Kozhikode' },
  { label: 'Akola' },
  { label: 'Kurnool' },
  { label: 'Bokaro' },
  { label: 'Rajahmundry' },
  { label: 'Ballari' },
  { label: 'Agartala' },
  { label: 'Bhagalpur' },
  { label: 'Latur' },
  { label: 'Dhule' },
  { label: 'Korba' },
  { label: 'Bhilwara' },
  { label: 'Brahmapur' },
  { label: 'Mysore' },
  { label: 'Muzaffarpur' },
  { label: 'Ahmednagar' },
  { label: 'Kollam' },
  { label: 'Bilaspur' },
  { label: 'Shahjahanpur' },
  { label: 'Thrissur' },
  { label: 'Alwar' },
  { label: 'Kakinada' },
  { label: 'Nizamabad' },
  { label: 'Sagar' },
  { label: 'Tumkur' },
  { label: 'Hisar' },
  { label: 'Rohtak' },
  { label: 'Panipat' },
  { label: 'Darbhanga' },
  { label: 'Kharagpur' },
  { label: 'Aizawl' },
  { label: 'Ichalkaranji' },
  { label: 'Tirupati' },
  { label: 'Karnal' },
  { label: 'Bathinda' },
  { label: 'Rampur' },
  { label: 'Shivamogga' },
  { label: 'Ratlam' },
  { label: 'Modinagar' },
  { label: 'Durg' },
  { label: 'Shillong' },
  { label: 'Imphal' },
  { label: 'Hapur' },
  { label: 'Ranipet' },
  { label: 'Anantapur' },
  { label: 'Arrah' },
  { label: 'Karimnagar' },
  { label: 'Etawah' },
  { label: 'Bharatpur' },
  { label: 'Begusarai' },
  { label: 'Chhapra' },
  { label: 'Kadapa' },
  { label: 'Pali' },
  { label: 'Satna' },
  { label: 'Vizianagaram' },
  { label: 'Katihar' },
  { label: 'Hardwar' },
  { label: 'Sonipat' },
  { label: 'Nagercoil' },
  { label: 'Thanjavur' },
  { label: 'Murwara' },
  { label: 'Naihati' },
  { label: 'Sambhal' },
  { label: 'Nadiad' },
  { label: 'Yamunanagar' },
  { label: 'Eluru' },
  { label: 'Munger' },
  { label: 'Panchkula' },
  { label: 'Deoghar' },
  { label: 'Ongole' },
  { label: 'Nandyal' },
  { label: 'Morena' },
  { label: 'Bhiwani' },
  { label: 'Porbandar' },
  { label: 'Palakkad' },
  { label: 'Anand' },
  { label: 'Purnia' },
  { label: 'Baharampur' },
  { label: 'Barmer' },
  { label: 'Morvi' },
  { label: 'Orai' },
  { label: 'Bahraich' },
  { label: 'Vellore' },
  { label: 'Khammam' },
  { label: 'Mahesana' },
  { label: 'Silchar' },
  { label: 'Sambalpur' },
  { label: 'Rewa' },
  { label: 'Unnao' },
  { label: 'Hugli-Chinsurah' },
  { label: 'Raiganj' },
  { label: 'Adityapur' },
  { label: 'Alappuzha' },
  { label: 'Bahadurgarh' },
  { label: 'Machilipatnam' },
  { label: 'Rae Bareli' },
  { label: 'Jalpaiguri' },
  { label: 'Bharuch' },
  { label: 'Hoshiarpur' },
  { label: 'Baramula' },
  { label: 'Adoni' },
  { label: 'Jind' },
  { label: 'Tonk' },
  { label: 'Tenali' },
  { label: 'Kancheepuram' },
  { label: 'Vapi' },
  { label: 'Navsari' },
  { label: 'Mahbubnagar' },
  { label: 'Puri' },
  { label: 'Erode' },
  { label: 'Batala' },
  { label: 'Haldwani' },
  { label: 'Vidisha' },
  { label: 'Saharsa' },
  { label: 'Thanesar' },
  { label: 'Chittoor' },
  { label: 'Veraval' },
  { label: 'Lakhimpur' },
  { label: 'Sitapur' },
  { label: 'Hindupur' },
  { label: 'Santipur' },
  { label: 'Balurghat' },
  { label: 'Ganjbasoda' },
  { label: 'Moga' },
  { label: 'Srinagar' },
  { label: 'Medinipur' },
  { label: 'Habra' },
  { label: 'Sasaram' },
  { label: 'Hajipur' },
  { label: 'Bhuj' },
  { label: 'Shimla' },
  { label: 'Tiruvannamalai' },
  { label: 'Kaithal' },
  { label: 'Rajnandgaon' },
  { label: 'Godhra' },
  { label: 'Hazaribag' },
  { label: 'Bhimavaram' },
  { label: 'Mandsaur' },
  { label: 'Dibrugarh' },
  { label: 'Kolar' },
  { label: 'Bankura' },
  { label: 'Mandya' },
  { label: 'Dehri-on-Sone' },
  { label: 'Madanapalle' },
  { label: 'Malerkotla' },
  { label: 'Lalitpur' },
  { label: 'Bettiah' },
  { label: 'Pollachi' },
  { label: 'Khanna' },
  { label: 'Neemuch' },
  { label: 'Palwal' },
  { label: 'Palanpur' },
  { label: 'Guntakal' },
  { label: 'Nabadwip' },
  { label: 'Udupi' },
  { label: 'Jagdalpur' },
  { label: 'Motihari' },
  { label: 'Pilibhit' },
  { label: 'Dimapur' },
  { label: 'Mohali' },
  { label: 'Dharmavaram' },
  { label: 'Kashipur' },
  { label: 'Sivakasi' },
  { label: 'Darjiling' },
  { label: 'Chikkamagaluru' },
  { label: 'Gudivada' },
  { label: 'Adilabad' },
  { label: 'Yavatmal' },
  { label: 'Barnala' },
  { label: 'Nagaon' },
  { label: 'Narasaraopet' },
  { label: 'Raigarh' },
  { label: 'Roorkee' },
  { label: 'Valsad' },
  { label: 'Ambikapur' },
  { label: 'Giridih' },
  { label: 'Chandausi' },
  { label: 'Purulia' },
  { label: 'Patan' },
  { label: 'Bagaha' },
  { label: 'Hardoi' },
  { label: 'Achalpur' },
  { label: 'Osmanabad' },
  { label: 'Deesa' },
  { label: 'Nandurbar' },
  { label: 'Azamgarh' },
  { label: 'Ramgarh' },
  { label: 'Firozpur' },
  { label: 'Baripada' },
  { label: 'Karwar' },
  { label: 'Siwan' },
  { label: 'Anantnag' },
  { label: 'Tadpatri' },
  { label: 'Satara' },
  { label: 'Bhadrak' },
  { label: 'Kishanganj' },
  { label: 'Wardha' },
  { label: 'Amreli' },
  { label: 'Neyveli' },
  { label: 'Jamalpur' },
  { label: 'Marmagao' },
  { label: 'Udgir' },
  { label: 'Tadepalligudem' },
  { label: 'Nagapattinam' },
  { label: 'Buxar' },
  { label: 'Aurangabad' },
  { label: 'Jehanabad' },
  { label: 'Khair' },
  { label: 'Sawai Madhopur' },
  { label: 'Kapurthala' },
  { label: 'Chilakaluripet' },
  { label: 'Malappuram' },
  { label: 'Rewari' },
  { label: 'Nagaur' },
  { label: 'Sultanpur' },
  { label: 'Nagda' },
  { label: 'Port Blair' },
  { label: 'Lakhisarai' },
  { label: 'Panaji' },
  { label: 'Tinsukia' },
  { label: 'Itarsi' },
  { label: 'Kohima' },
  { label: 'Balangir' },
  { label: 'Nawada' },
  { label: 'Jharsuguda' },
  { label: 'Jagtial' },
  { label: 'Viluppuram' },
  { label: 'Amalner' },
  { label: 'Zirakpur' },
  { label: 'Tanda' },
  { label: 'Tiruchengode' },
  { label: 'Nagina' },
  { label: 'Yemmiganur' },
  { label: 'Vaniyambadi' },
  { label: 'Theni Allinagaram' },
  { label: 'Margao' },
  { label: 'Akot' },
  { label: 'Mhow' },
  { label: 'Kot Kapura' },
  { label: 'Kadiri' },
  { label: 'Najibabad' },
  { label: 'Nirmal' },
  { label: 'Udhagamandalam' },
  { label: 'Jhumri Tilaiya' },
  { label: 'Aruppukkottai' },
  { label: 'Jamui' },
  { label: 'Sitamarhi' },
  { label: 'Chirala' },
  { label: 'Anjar' },
  { label: 'Karaikal' },
  { label: 'Hansi' },
  { label: 'Anakapalle' },
  { label: 'Mahasamund' },
  { label: 'Faridkot' },
  { label: 'Dhoraji' },
  { label: 'Balaghat' },
  { label: 'Khambhat' },
  { label: 'Kavali' },
  { label: 'Dhamtari' },
  { label: 'Ashok Nagar' },
  { label: 'Bargarh' },
  { label: 'Kamareddy' },
  { label: 'Kothagudem' },
  { label: 'Ramanagaram' },
  { label: 'Gokak' },
  { label: 'Tikamgarh' },
  { label: 'Araria' },
  { label: 'Rishikesh' },
  { label: 'Daltonganj' },
  { label: 'Arakkonam' },
  { label: 'Washim' },
  { label: 'Sangrur' },
  { label: 'Bodhan' },
  { label: 'Fazilka' },
  { label: 'Palacole' },
  { label: 'Keshod' },
  { label: 'Wadhwan' },
  { label: 'Gurdaspur' },
  { label: 'Vatakara' },
  { label: 'Tura' },
  { label: 'Narnaul' },
  { label: 'Kharar' },
  { label: 'Yadgir' },
  { label: 'Ambejogai' },
  { label: 'Ankleshwar' },
  { label: 'Paradip' },
  { label: 'Virudhachalam' },
  { label: 'Kanhangad' },
  { label: 'Kadi' },
  { label: 'Gobindgarh' },
  { label: 'Tindivanam' },
  { label: 'Taliparamba' },
  { label: 'Tanuku' },
  { label: 'Virudhunagar' },
  { label: 'Koyilandy' },
  { label: 'Jorhat' },
  { label: 'Karur' },
  { label: 'Valparai' },
  { label: 'Neyyattinkara' },
  { label: 'Bapatla' },
  { label: 'Fatehabad' },
  { label: 'Sankarankovil' },
  { label: 'Tenkasi' },
  { label: 'Ratnagiri' },
  { label: 'Sikandrabad' },
  { label: 'Chaibasa' },
  { label: 'Chirmiri' },
  { label: 'Bhawanipatna' },
  { label: 'Kayamkulam' },
  { label: 'Nabha' },
  { label: 'Dhenkanal' },
  { label: 'Uran Islampur' },
  { label: 'Gopalganj' },
  { label: 'Bongaigaon' },
  { label: 'Tarn Taran' },
  { label: 'Barbil' },
  { label: 'Koratla' },
  { label: 'Arambagh' },
  { label: 'Gohana' },
  { label: 'Ladnu' },
  { label: 'Tamluk' },
  { label: 'Jagraon' },
  { label: 'Alirajpur' },
  { label: 'Tandur' },
  { label: 'Naidupet' },
  { label: 'Tirupathur' },
  { label: 'Tohana' },
  { label: 'Dhubri' },
  { label: 'Visnagar' },
  { label: 'Vrindavan' },
  { label: 'Nokha' },
  { label: 'Nagari' },
  { label: 'Narwana' },
  { label: 'Ujhani' },
  { label: 'Samastipur' },
  { label: 'Laharpur' },
  { label: 'Nimbahera' },
  { label: 'Diphu' },
  { label: 'Jhargram' },
  { label: 'Tilhar' },
  { label: 'Udumalaipettai' },
  { label: 'Wanaparthy' },
  { label: 'Gudur' },
  { label: 'Kendujhar' },
  { label: 'Mandi' },
  { label: 'Nedumangad' },
  { label: 'North Lakhimpur' },
  { label: 'Vinukonda' },
  { label: 'Tiptur' },
  { label: 'Gobichettipalayam' },
  { label: 'Wani' },
  { label: 'Upleta' },
  { label: 'Narasapuram' },
  { label: 'Nuzvid' },
  { label: 'Tezpur' },
  { label: 'Una' },
  { label: 'Sheopur' },
  { label: 'Thiruvarur' },
  { label: 'Lonavla' },
  { label: 'Ponnur' },
  { label: 'Kagaznagar' },
  { label: 'Gadwal' },
  { label: 'Bhatapara' },
  { label: 'Kandukur' },
  { label: 'Unjha' },
  { label: 'Lunglei' },
  { label: 'Karimganj' },
  { label: 'Kannur' },
  { label: 'Bobbili' },
  { label: 'Talegaon Dabhade' },
  { label: 'Anjangaon' },
  { label: 'Mangrol' },
  { label: 'Gangarampur' },
  { label: 'Thiruvallur' },
  { label: 'Tirur' },
  { label: 'Jatani' },
  { label: 'Viramgam' },
  { label: 'Rajsamand' },
  { label: 'Yanam' },
  { label: 'Kottayam' },
  { label: 'Dhuri' },
  { label: 'Namakkal' },
  { label: 'Kasaragod' },
  { label: 'Kunnamkulam' },
  { label: 'Umred' },
  { label: 'Bellampalle' },
  { label: 'Ottappalam' },
  { label: 'Dumraon' },
  { label: 'Jaggaiahpet' },
  { label: 'Goalpara' },
  { label: 'Tuni' },
  { label: 'Lachhmangarh' },
  { label: 'Bhongir' },
  { label: 'Amalapuram' },
  { label: 'Firozpur Cantt.' },
  { label: 'Vikarabad' },
  { label: 'Thiruvalla' },
  { label: 'Palghar' },
  { label: 'Jangaon' },
  { label: 'Bheemunipatnam' },
  { label: 'Panna' },
  { label: 'Thodupuzha' },
  { label: 'Arwal' },
  { label: 'Venkatagiri' },
  { label: 'Kalpi' },
  { label: 'Churu' },
  { label: 'Arsikere' },
  { label: 'Ozar' },
  { label: 'Thirumangalam' },
  { label: 'Nasirabad' },
  { label: 'Nanjangud' },
  { label: 'Forbesganj' },
  { label: 'Tundla' },
  { label: 'Bhadrachalam' },
  { label: 'Madhepura' },
  { label: 'Pithoragarh' },
  { label: 'Mathura' },
  { label: 'Sultanganj' },
  { label: 'Raxaul' },
  { label: 'Pilani' },
  { label: 'Mapusa' },
  { label: 'Sivaganga' },
  { label: 'Shahpur' },
  { label: 'Ramnagar' },
  { label: 'Rameshwaram' },
  { label: 'Perambalur' },
  { label: 'Manvi' },
  { label: 'Murshidabad' },
  { label: 'Mahe' },
  { label: 'Solan' },
  { label: 'Sanawad' },
  { label: 'Bulandshahr' },
  { label: 'Satana' },
  { label: 'Sanand' },
  { label: 'Sohna' },
  { label: 'Rudrapur' },
  { label: 'Perumbavoor' },
  { label: 'Raikot' },
  { label: 'Pauri' },
  { label: 'Shahganj' },
  { label: 'Mount Abu' },
  { label: 'Silvassa' },
  { label: 'Sikanderpur' },
  { label: 'Navi Mumbai' }
];
// Top States
const topStates = [
  { label: 'Andhra Pradesh' },
  { label: 'Arunachal Pradesh' },
  { label: 'Assam' },
  { label: 'Bihar' },
  { label: 'Chhattisgarh' },
  { label: 'Goa' },
  { label: 'Gujarat' },
  { label: 'Haryana' },
  { label: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir' },
  { label: 'Jharkhand' },
  { label: 'Karnataka' },
  { label: 'Kerala' },
  { label: 'Madhya Pradesh' },
  { label: 'Maharashtra' },
  { label: 'Manipur' },
  { label: 'Meghalaya' },
  { label: 'Mizoram' },
  { label: 'Nagaland' },
  { label: 'Odisha' },
  { label: 'Punjab' },
  { label: 'Rajasthan' },
  { label: 'Sikkim' },
  { label: 'Tamil Nadu' },
  { label: 'Telangana' },
  { label: 'Tripura' },
  { label: 'Uttarakhand' },
  { label: 'Uttar Pradesh' },
  { label: 'West Bengal' },
  { label: 'Andaman and Nicobar Islands' },
  { label: 'Chandigarh' },
  { label: 'Dadra and Nagar Haveli' },
  { label: 'Daman and Diu' },
  { label: 'Delhi' },
  { label: 'Lakshadweep' },
  { label: 'Puducherry' }
];
