import { useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import exportIcon from '@iconify/icons-ant-design/export-outline';
import { FiEdit } from 'react-icons/fi';
import { MdUpdate } from 'react-icons/md';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Select,
  MenuItem
} from '@mui/material';
import { DateRange } from 'react-date-range';
import { navigate } from '@storybook/addon-links';
import { useNavigate } from 'react-router-dom';
import { RootStyle, SearchStyle, DateRangeStyled } from '../Global/Styling';
// ---------------------------------------------------------
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------

export default function CallCenterToolbar({
  numSelected,
  filterName,
  onFilterName,
  parent,
  pageMenu
}) {
  const navigate = useNavigate();
  const [isOpenFilter, openFilter] = useState(false);
  const [selectSource, setSelectSource] = useState(' ');
  const [selectLoanType, setSelectLoanType] = useState(' ');
  const [selectStatus, setSelectStatus] = useState(' ');
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  console.log(filterDatesRange);
  return (
    <>
      {isOpenFilter && (
        <Box px={3} pt={2}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectSource}`
                }}
                value={selectSource}
                onChange={(e) => setSelectSource(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Source
                </MenuItem>
                <MenuItem value="Facebook">Facebook</MenuItem>
                <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                <MenuItem value="Twitter">Twitter</MenuItem>
                <MenuItem value="Google">Google</MenuItem>
                <MenuItem value="Affiliate">Affiliate</MenuItem>
                <MenuItem value="Emailer">Emailer</MenuItem>
                <MenuItem value="Channel Partner">Channel Partner</MenuItem>
                <MenuItem value="SMS Campaign">SMS Campaign</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectLoanType}`
                }}
                value={selectLoanType}
                onChange={(e) => setSelectLoanType(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Loan Type
                </MenuItem>
                <MenuItem value="Home Loan">Home Loan</MenuItem>
                <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                <MenuItem value="Education Loan">Education Loan</MenuItem>
                <MenuItem value="Credit Card">Credit Card</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectStatus}`
                }}
                value={selectStatus}
                onChange={(e) => setSelectStatus(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Status
                </MenuItem>
                <MenuItem value="Connected">Connected</MenuItem>
                <MenuItem value="Not Connected">Not Connected</MenuItem>
                <MenuItem value="Disconnected">Disconnected</MenuItem>
                <MenuItem value="Not Interested">Not Interested</MenuItem>
                <MenuItem value="Followups">Followups</MenuItem>
                <MenuItem value="Wrong No">Wrong No</MenuItem>
                <MenuItem value="Leads">Leads</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3} sx={{ position: 'relative' }}>
              <Box className="displayDateRange">
                {filterDatesRange[0].startDate !== null
                  ? `${fDate(filterDatesRange[0].startDate)} to `
                  : ''}
                {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
                {filterDatesRange[0].startDate === null && filterDatesRange[0].endDate === null ? (
                  <Typography className="dissabledMenu">Select Date Range</Typography>
                ) : (
                  ''
                )}
              </Box>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                MenuProps={{
                  className: 'menuOpened'
                }}
                value=" "
                className="datesSelect"
              >
                <DateRangeStyled
                  editableDateInputs={false}
                  onChange={(item) => setFilterDatesRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={filterDatesRange}
                  className="dateRangePicker"
                />
              </Select>
            </Grid>
          </Grid>
        </Box>
      )}

      <RootStyle
        sx={{
          minHeight: 45,
          borderBottom: '1px solid #eaedf1'
        }}
      >
        <Box>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => {
              console.log('pageElement', pageElement);
              return pageElement.subPageCode === 'UPDATE' && pageElement.isEnable === true ? (
                <Tooltip title="Update" placement="top" arrow>
                  <IconButton
                    className="squareIconButton"
                    onClick={(e) => navigate(`/${parent}/update`)}
                  >
                    <MdUpdate size={18} />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              );
            })}
        </Box>
        <Box>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => (
              <>
                {pageElement.subPageCode === 'SEARCH' && pageElement.isEnable === true ? (
                  <>
                    <SearchStyle
                      value={filterName}
                      onChange={onFilterName}
                      placeholder="Search Prospect"
                      classes={{
                        root: 'searchHolder',
                        input: 'searchInput'
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                        </InputAdornment>
                      }
                    />
                    &nbsp;&nbsp;&nbsp;
                  </>
                ) : pageElement.subPageCode === 'FILTER' && pageElement.isEnable === true ? (
                  <Tooltip title="Filter list">
                    <IconButton
                      onClick={(e) => {
                        if (isOpenFilter) {
                          openFilter(false);
                        } else {
                          openFilter(true);
                        }
                      }}
                    >
                      <Icon icon={roundFilterList} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ''
                )}
                &nbsp;
              </>
            ))}
        </Box>
      </RootStyle>
    </>
  );
}
