import React, { useState, useEffect } from 'react';
import {
  Step,
  Stepper,
  StepLabel,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  Tab,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  DialogTitle,
  FormLabel,
  FormControlLabel,
  Card
} from '@mui/material';
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';
import { IoIosAlert } from 'react-icons/io';

// table component
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ImFolderUpload } from 'react-icons/im';
import { MdOutlinePreview, MdUploadFile, MdUpload, MdOutlineAdd } from 'react-icons/md';
import { BiCloudUpload } from 'react-icons/bi';
import { DiGoogleDrive } from 'react-icons/di';
import { AiOutlineDropbox } from 'react-icons/ai';
import { CgDigitalocean } from 'react-icons/cg';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

// import { CloseIcon } from 'react-icons'
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import pages
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

import AgreementService from '../services/AgreementService';

const { Viewagreement } = AgreementService;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));
function ViewAgreement(parent) {
  const navigate = useNavigate();
  const [viewagreementData, setViewagreementData] = useState(null);

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const authToken = localJWTtoken.token.jwt;
    const aggrementId = JSON.parse(localStorage.getItem('viewAgreementId'));

    const obj = {
      aggrementId: Number(aggrementId),
      companyId: localJWTtoken.token.companyId,
      userId: localJWTtoken.token.userId
    };
    fireagreementlistapi(obj, authToken);
  }, []);

  const fireagreementlistapi = async (obj, authToken) => {
    const ifPreviewDataBank = await Viewagreement(obj, authToken);
    console.log('dataaaa', ifPreviewDataBank);
    if (ifPreviewDataBank.status === 'success') {
      setViewagreementData(ifPreviewDataBank.data);
    }
  };

  return (
    <Page title="Partner Management System || Agreement">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="View Agreement" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Box className="submitLeadToolbar">
            <Tooltip
              title="Edit"
              placement="top"
              arrow
              onClick={(e) => navigate(`/agreement/edit`)}
            >
              <IconButton className="squareIconButton">
                <FiEdit size={18} />
              </IconButton>
            </Tooltip>
          </Box>
          {viewagreementData !== null && viewagreementData.bankDetails !== undefined ? (
            Object.keys(viewagreementData.bankDetails) !== null ? (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Bank Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <Grid item container spacing={{ xs: 1, sm: 2, md: 3 }} marginBottom={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="reviewLabel">
                        Bank Name
                      </Typography>
                      <Typography variant="subtitle2" className="reviewValue">
                        {viewagreementData.bankDetails.bankName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="reviewLabel">
                        Address
                      </Typography>
                      <Typography variant="subtitle2" className="reviewValue">
                        {viewagreementData.bankDetails.bankAddress
                          ? viewagreementData.bankDetails.bankAddress
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="reviewLabel">
                        PAN number
                      </Typography>
                      <Typography variant="subtitle2" className="reviewValue">
                        {viewagreementData.bankDetails.panNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="reviewLabel">
                        GSTIN
                      </Typography>
                      <Typography variant="subtitle2" className="reviewValue">
                        {viewagreementData.bankDetails.gstIn}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="reviewLabel">
                        Commission Structure
                      </Typography>
                      <Typography variant="subtitle2" className="reviewValue">
                        {viewagreementData.bankDetails.bankCommissionTypeName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="reviewLabel">
                        Turnover Benefit
                      </Typography>
                      <Typography variant="subtitle2" className="reviewValue">
                        {viewagreementData.bankDetails.turnoverBenefit
                          ? viewagreementData.bankDetails.turnoverBenefit
                              .toString()
                              .charAt(0)
                              .toUpperCase() +
                            viewagreementData.bankDetails.turnoverBenefit.toString().slice(1)
                          : 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              ''
            )
          ) : (
            ''
          )}

          {viewagreementData !== null && viewagreementData.branchDetails ? (
            viewagreementData.branchDetails.length > 0 ? (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Branch Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <TableContainer
                    sx={{ minWidth: 800, overflow: 'auto' }}
                    aria-label="simple table"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Branch Name</StyledTableCell>
                          <StyledTableCell align="">Branch Address</StyledTableCell>
                          <StyledTableCell align="">Contact Person</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewagreementData.branchDetails.map((item, index) => {
                          console.log(item);
                          return (
                            <StyledTableRow>
                              <StyledTableCell align="">
                                {item.branchName ? item.branchName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {item.branchAddress ? item.branchAddress : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {item.contactPersonName ? item.contactPersonName : 'N/A'}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            ) : (
              ''
            )
          ) : (
            ''
          )}

          {viewagreementData !== null && viewagreementData.commissionDetails ? (
            viewagreementData.commissionDetails.length > 0 ? (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Commission Details
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
                  <TableContainer
                    sx={{ minWidth: 800, overflow: 'auto' }}
                    aria-label="simple table"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Loan Type</StyledTableCell>
                          <StyledTableCell align="left">Branch</StyledTableCell>
                          <StyledTableCell align="left">Commission %</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewagreementData.commissionDetails.map((item, index) => {
                          console.log(item);
                          return (
                            <StyledTableRow>
                              <StyledTableCell align="left">
                                {item.loanTypeName ? item.loanTypeName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.branchName ? item.branchName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.aggrementCommissionPercentage}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            ) : (
              ''
            )
          ) : (
            ''
          )}

          {viewagreementData !== null && viewagreementData.turnoverBenefits ? (
            viewagreementData.turnoverBenefits.length > 0 ? (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Turnover Benefit
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin">
                  <TableContainer
                    sx={{ minWidth: 800, overflow: 'auto' }}
                    aria-label="simple table"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Loan Type</StyledTableCell>
                          <StyledTableCell align="left">Branch</StyledTableCell>
                          <StyledTableCell align="left">Duration</StyledTableCell>
                          <StyledTableCell align="left">Commission %</StyledTableCell>
                          <StyledTableCell align="left">Unit</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewagreementData.turnoverBenefits.map((item, index) => {
                          console.log(item);
                          return (
                            <StyledTableRow>
                              <StyledTableCell align="left">
                                {item.loanTypeName ? item.loanTypeName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.branchName ? item.branchName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.duration ? item.duration : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.aggrementCommissionPercentage
                                  ? item.aggrementCommissionPercentage
                                  : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.unitName ? item.unitName : 'N/A'}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </Box>
      </Container>
    </Page>
  );
}

export default ViewAgreement;
