import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
//
/**
 * @module AdminServiceService
 * @returns  it return All DataSource Apis
 */

const AgreementService = {
  // Bulk Upload the Prospect
  SaveBankInfo: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/agreement/save-bank-info`, credential, token),

  SaveBranchInfo: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/agreement/save-branch-info`, credential, token),

  Addpolicyaction: (credential, token) =>
    useApiServices().postpolicyApi(`${globalAdminUrl}/agreement/add-new-policy`, credential, token),

  AgreementpolicyList: (credential, token) =>
    useApiServices().policylistApi(`${globalAdminUrl}/agreement/policies-list`, credential, token),

  AgreementList: (credential, token) =>
    useApiServices().agreementlistApi(`${globalAdminUrl}/agreement/list`, credential, token),

  SaveCommissionInfo: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/agreement/save-commission-info`, credential, token),

  UploadDocument: () => `${globalAdminUrl}/agreement/uploadDocument`,

  Edit: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/agreement/edit`, credential, token),

  Viewagreement: (credential, token) =>
    useApiServices().viewagreementpostApi(`${globalAdminUrl}/agreement/view`, credential, token),

  BankListAgreement: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/agreement/bank/list`, credential, token)
};

export default AgreementService;
