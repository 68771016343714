import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
//
/**
 * @module AdminServiceService
 * @returns  it return All DataSource Apis
 */

const leadsService = {
  // Leads Submit API (fill details) man 03/06/2022
  LeadsSubmissionMode: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/master/lead-submission-modes`, token),
  BankBranchList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/bank-branch/list`, credential, token),
  AddBankBranch: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/branch/add-branch`, credential, token),
  ContactPersonList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/bank/contact-persons`, credential, token),
  AddContactPerson: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/bank/add-contact-person`, credential, token),
  SubmitApplication: (credential, token) =>
    useApiServices().postApi(
      `${globalAdminUrl}/leads/submit-application-record`,
      credential,
      token
    ),
  ListSubmittedApplication: (credential, token) =>
    useApiServices().postApi(
      `${globalAdminUrl}/leads/list-submitted-application`,
      credential,
      token
    ),

  // Arundhuti
  // listing of leads
  LeadsList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/leads/list`, credential, token),

  // select status in leads page
  ListStageStatus: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/leads/list-stage-statuses`, credential, token),

  // select source
  // SourceList: (token) => useApiServices().getApi(`${globalAdminUrl}/prospect-source/list`, token),

  // view application summary
  ApplicationSummary: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/leads/application-summary`, credential, token),

  // view leads report
  ViewReport: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/leads/view-report`, credential, token),

  // update lead stage
  UpdateStageStatuses: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/leads/update-stage-statuses`, credential, token),

  // assign leads
  AssignLeads: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/leads/assign-leads`, credential, token),

  // internal sales manage list
  InternalSalesManager: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/internal-sales-manager/list`, credential, token)
};

export default leadsService;
