import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled
} from '@mui/material';
import { Box } from '@mui/system';
import { Icon } from '@iconify/react';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// icons
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
// material icons
import CloseIcon from '@mui/icons-material/Close';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import InterestsIcon from '@mui/icons-material/Interests';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SourceIcon from '@mui/icons-material/Source';
import LinkIcon from '@mui/icons-material/Link';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import UpdateIcon from '@mui/icons-material/Update';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// react icons
import { BsWindowDock, BsCardHeading, BsTelephone } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineIdcard } from 'react-icons/ai';
import { RiBankCardLine } from 'react-icons/ri';
import { FiEye } from 'react-icons/fi';
import { FaRegAddressCard, FaRegUser } from 'react-icons/fa';
import { CgCalendarDates } from 'react-icons/cg';
import { HiOutlineMail } from 'react-icons/hi';
import { SiOpensourceinitiative } from 'react-icons/si';
import { GiSwapBag, GiModernCity } from 'react-icons/gi';
import {
  MdIncompleteCircle,
  MdOutlineDateRange,
  MdOutlinePreview,
  MdAppRegistration
} from 'react-icons/md';
import FileViewer from 'react-file-viewer';
// components
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import aadharFrontDummy from './aadharFrontDummy.jpg';
import { fDate } from '../../utils/formatTime';
import partnerService from '../services/partnerService';
import leadsService from '../services/leadsService';

const { ViewReport } = leadsService;

function ViewLeads() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');
  const [showProspectDetail, setShowProspectDetail] = useState(false);
  const [showCallCenterDetail, setShowCallCenterDetail] = useState(false);
  const [showLeadsDetail, setShowLeadsDetail] = useState(false);
  const [documentPreview, setDocumentPreview] = useState(false);
  const [viewReportData, setViewReportData] = useState({});
  const [imagePreviewData, setImagePreviewData] = useState();
  console.log('imagePreviewData', imagePreviewData);
  console.log('viewReportData', viewReportData);

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  const navigate = useNavigate();
  // Styled icon button in Action
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
      padding: '6px',
      height: '42.5px',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#0d4689'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px',
      height: '42.5px'
    }
  }));
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }

    console.log('localJWTtoken', localJWTtoken);
    const authToken = localJWTtoken.token.jwt;
    const authCompanyId = localJWTtoken.token.companyId;
    const authUserId = localJWTtoken.token.userId;
    const leadId = JSON.parse(localStorage.getItem('leadId'));
    const assignedTo = JSON.parse(localStorage.getItem('assignedTo'));

    const credentials = {
      companyId: authCompanyId,
      userId: authUserId,
      leadId: leadId,
      stageId: 56,
      assignedTo: assignedTo
    };

    (async () => {
      const ViewReportApiResponse = await ViewReport(credentials, authToken);
      if (ViewReportApiResponse.msg) {
        if (ViewReportApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ViewReportApiResponse.status === 'success') {
        setViewReportData(ViewReportApiResponse.data.leads);
      } else {
        setViewReportData({});
      }
    })();
  }, []);

  return (
    <Page title="PULSE by Real Value Finloans || Leads">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Application Progress Report" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Box className="submitLeadToolbar">
              <Tooltip
                title="View Application Summary"
                placement="top"
                arrow
                onClick={(e) => navigate(`/leads/application-summary`)}
              >
                <IconButton className="squareIconButton">
                  <MdAppRegistration size={18} />
                </IconButton>
              </Tooltip>
            </Box>
            <Grid container spacing={3} mb={3}>
              <Grid item xs="12" sm="6" md="6">
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Loan Requirement
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin">
                  <Grid container spacing={3}>
                    {/* <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.loanRequirement &&
                            viewReportData.loanRequirement.customerName
                              ? viewReportData.loanRequirement.customerName
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            First Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.loanRequirement &&
                            viewReportData.loanRequirement.customerFirstName
                              ? viewReportData.loanRequirement.customerFirstName
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Middle Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.loanRequirement &&
                            viewReportData.loanRequirement.customerMiddleName
                              ? viewReportData.loanRequirement.customerMiddleName
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Last Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.loanRequirement &&
                            viewReportData.loanRequirement.customerLastName
                              ? viewReportData.loanRequirement.customerLastName
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <BsTelephone className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Phone
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.loanRequirement && viewReportData.loanRequirement.mobile
                              ? viewReportData.loanRequirement.mobile
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <HiOutlineMail className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Email
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.loanRequirement &&
                            viewReportData.loanRequirement.emailAddress
                              ? viewReportData.loanRequirement.emailAddress
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <InterestsIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Interested in
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.loanRequirement &&
                            viewReportData.loanRequirement.loanTypeName
                              ? viewReportData.loanRequirement.loanTypeName
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiSwapBag className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Amount
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.loanRequirement &&
                            viewReportData.loanRequirement.loanAmountRequested
                              ? viewReportData.loanRequirement.loanAmountRequested
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiModernCity className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            City
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.loanRequirement &&
                            viewReportData.loanRequirement.cityName
                              ? viewReportData.loanRequirement.cityName
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs="12" sm="6" md="6">
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Current Status
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <MdAppRegistration className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Lead ID/Application ID
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData && viewReportData.leadId
                              ? viewReportData.leadId
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <SourceIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Source
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.currentStatus && viewReportData.currentStatus.leadSource
                              ? viewReportData.currentStatus.leadSource
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <LinkIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Application URL
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.currentStatus &&
                            viewReportData.currentStatus.applicationUrl
                              ? viewReportData.currentStatus.applicationUrl
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Sales Person
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.currentStatus &&
                            viewReportData.currentStatus.salesPerson
                              ? viewReportData.currentStatus.salesPerson
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <AutoModeIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Completion %
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {viewReportData.currentStatus &&
                            viewReportData.currentStatus.completionPercent
                              ? viewReportData.currentStatus.completionPercent
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <UpdateIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Last Update
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {/* {viewReportData.currentStatus &&
                              viewReportData.currentStatus.lastUpdatedOn !== 'N/A' &&
                              fDate(viewReportData.currentStatus.lastUpdatedOn)} */}
                            {viewReportData.currentStatus &&
                            viewReportData.currentStatus.lastUpdatedOn
                              ? fDate(viewReportData.currentStatus.lastUpdatedOn)
                              : 'N/A'}
                            &nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {/* <Box className="formRowHolder">
              <Button variant="contained" className="mainBtn">
                Submit
              </Button>
            </Box> */}
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Applicant Document Checklist
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Box className="displayFlex flexWrap">
                {viewReportData.documentCheckList && viewReportData.documentCheckList.length > 0 ? (
                  viewReportData.documentCheckList.map((item, index) => {
                    console.log('item23', item);
                    return item.docUrls.map((URL, URLIndex) => (
                      <>
                        {URL.isCoApplicantDoc === false && (
                          <Box
                            key={URLIndex}
                            // className="uploadFilePreview uploadFilePreviewGreen"
                            className={
                              // item.isUploaded === true
                              URL.isUploaded === true
                                ? 'uploadFilePreview uploadFilePreviewGreen'
                                : 'uploadFilePreview uploadFilePreviewRed'
                            }
                            mb={2}
                            onClick={(e) => {
                              if (URL.isUploaded === true) {
                                setImagePreviewData(URL);
                                setDocumentPreview(true);
                              }
                              // setImagePreviewData(URL.docUrl);
                              // setImagePreviewData(URL);
                            }}
                          >
                            <RemoveRedEyeIcon className="ImageViewIcon" />
                            <Typography variant="body2" className="docName">
                              {item.docType ? item.docType.typeName : 'N/A'}
                            </Typography>
                          </Box>
                        )}
                        {}
                      </>
                    ));
                  })
                ) : (
                  <Typography>No documents uploaded</Typography>
                )}
              </Box>
            </Box>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Co Applicant Document Checklist
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Box className="displayFlex">
                {viewReportData.documentCheckList && viewReportData.documentCheckList.length > 0 ? (
                  viewReportData.documentCheckList.map((item, index) => {
                    console.log('item23', item);
                    return item.docUrls.map((URL, URLIndex) => (
                      <>
                        {URL.isCoApplicantDoc === true && (
                          <Box
                            key={URLIndex}
                            className={
                              // item.isUploaded === true
                              URL.isUploaded === true
                                ? 'uploadFilePreview uploadFilePreviewGreen'
                                : 'uploadFilePreview uploadFilePreviewRed'
                            }
                            onClick={(e) => {
                              if (URL.isUploaded === true) {
                                setImagePreviewData(URL);
                                setDocumentPreview(true);
                              }
                            }}
                          >
                            <RemoveRedEyeIcon className="ImageViewIcon" />
                            <Typography variant="body2" className="docName">
                              {item.docType ? item.docType.typeName : 'N/A'}
                            </Typography>
                          </Box>
                        )}
                        {}
                      </>
                    ));
                  })
                ) : (
                  <Typography>No documents uploaded</Typography>
                )}
                {/* <Box
                    className="uploadFilePreview uploadFilePreviewGreen"
                    onClick={(e) => setDocumentPreview(true)}
                  >
                    <MdOutlinePreview className="ImageViewIcon" />
                    <Typography variant="body2">Aadhar Card</Typography>
                  </Box>
                  <Box
                    className="uploadFilePreview uploadFilePreviewGreen"
                    onClick={(e) => setDocumentPreview(true)}
                  >
                    <MdOutlinePreview className="ImageViewIcon" />
                    <Typography variant="body2">Pan Card</Typography>
                  </Box>
                  <Box
                    className="uploadFilePreview uploadFilePreviewRed"
                    onClick={(e) => setDocumentPreview(true)}
                  >
                    <MdOutlinePreview className="ImageViewIcon" />
                    <Typography variant="body2">Address Proof</Typography>
                  </Box>
                  <Box
                    className="uploadFilePreview uploadFilePreviewGreen"
                    onClick={(e) => setDocumentPreview(true)}
                  >
                    <MdOutlinePreview className="ImageViewIcon" />
                    <Typography variant="body2">ITR 2019</Typography>
                  </Box>
                  <Box
                    className="uploadFilePreview uploadFilePreviewRed"
                    onClick={(e) => setDocumentPreview(true)}
                  >
                    <MdOutlinePreview className="ImageViewIcon" />
                    <Typography variant="body2">ITR 2020</Typography>
                  </Box>
                  <Box
                    className="uploadFilePreview uploadFilePreviewGreen"
                    onClick={(e) => setDocumentPreview(true)}
                  >
                    <MdOutlinePreview className="ImageViewIcon" />
                    <Typography variant="body2">Salary Slip March</Typography>
                  </Box>
                  <Box
                    className="uploadFilePreview uploadFilePreviewRed"
                    onClick={(e) => setDocumentPreview(true)}
                  >
                    <MdOutlinePreview className="ImageViewIcon" />
                    <Typography variant="body2">Photo</Typography>
                  </Box> */}
                {/* {viewReportData.documentCheckList &&
                    viewReportData.documentCheckList.length > 0 ? (
                      viewReportData.documentCheckList.map((item, index) => {
                        console.log('item23', item);
                        return item.docUrls.map((URL, URLIndex) => (
                          <>
                            {URL.isCoApplicantDoc === true && (
                              <Box
                                key={URLIndex}
                                className="uploadFilePreview uploadFilePreviewGreen"
                                onClick={(e) => {
                                  setDocumentPreview(true);
                                  setImagePreviewData(URL);
                                }}
                              >
                                <RemoveRedEyeIcon className="ImageViewIcon" />
                                <Typography variant="body2" className="docName">
                                  {item.docType.typeName}
                                </Typography>
                              </Box>
                            )}
                            {}
                          </>
                        ));
                      })
                    ) : (
                      <Typography>No documents uploaded</Typography>
                    )} */}
              </Box>
              {/* <Dialog open={documentPreview} onClose={(e) => setDocumentPreview(false)} fullWidth>
                  <DialogTitle className="popupTitle">
                    <Typography variant="h6">Document Preview</Typography>
                    <IconButton onClick={(e) => setDocumentPreview(false)} size="small">
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <img src={aadharFrontDummy} alt="Aadhar Front" className="" />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={(e) => setDocumentPreview(false)}
                      variant="contained"
                      className="mainBtn"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog> */}
            </Box>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Activity Log
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin">
              <Box container>
                <Box className="statusBox">
                  <Typography variant="body2">Current Status:</Typography>
                  <Typography variant="subtitle2" className="disbursedText">
                    {viewReportData.activityLog &&
                      viewReportData.activityLog.currentStageStatusName}
                  </Typography>
                </Box>
              </Box>
              <Box className="timelineB">
                {viewReportData.activityLog &&
                viewReportData.activityLog.stages &&
                viewReportData.activityLog.stages.length > 0
                  ? viewReportData.activityLog.stages.map((item, index) => {
                      console.log(item);
                      return (
                        <>
                          {item.stageName === 'Prospect' && (
                            <Box className="timelineContentB timelineContentBGreen">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName && item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>{item.startDate ? fDate(item.startDate) : 'N/A'}</strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>{item.endDate ? fDate(item.endDate) : 'N/A'}</strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  if (Object.values(item.more).length === 0) {
                                    return true;
                                  }
                                  setShowProspectDetail(true);
                                  setShowCallCenterDetail(false);
                                  setShowLeadsDetail(false);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}

                          {item.stageName === 'Call Centre' && (
                            <Box className="timelineContentB timelineContentBGreen">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName && item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>{item.startDate ? fDate(item.startDate) : 'N/A'}</strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>{item.endDate ? fDate(item.endDate) : 'N/A'}</strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  if (Object.values(item.more).length === 0) {
                                    return true;
                                  }
                                  setShowProspectDetail(false);
                                  setShowCallCenterDetail(true);
                                  setShowLeadsDetail(false);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}

                          {item.stageName === 'Leads' && (
                            <Box className="timelineContentB timelineContentBOrange">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName && item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>{item.startDate ? fDate(item.startDate) : 'N/A'}</strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>{item.endDate ? fDate(item.endDate) : 'N/A'}</strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  if (Object.values(item.more).length === 0) {
                                    return true;
                                  }
                                  setShowProspectDetail(false);
                                  setShowCallCenterDetail(false);
                                  setShowLeadsDetail(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'Log In' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName && item.stageName}
                              </Typography>
                            </Box>
                          )}
                          {item.stageName === 'Invoice' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName && item.stageName}
                              </Typography>
                            </Box>
                          )}

                          {item.stageName === 'Rejection' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName && item.stageName}
                              </Typography>
                            </Box>
                          )}
                        </>
                      );
                    })
                  : ''}
              </Box>

              {showProspectDetail ? (
                <Box className="viewLeadCard" mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Prospect ID
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Campaign Name
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Source
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Leads Assigned on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Assigned by
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Generated on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Qualified on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Qualified by
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Status
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Agents Comments
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Sales Person
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
              {showCallCenterDetail ? (
                <Box className="viewLeadCard" mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Application ID
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Campaign Name
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Source
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Leads Assigned on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Assigned by
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Generated on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Qualified on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Qualified by
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Status
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Agents Comments
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Sales Person
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          &nbsp;
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
              {showLeadsDetail ? (
                <Box className="greenBorder tableBox" mt={3}>
                  <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Date</StyledTableCell>
                          <StyledTableCell align="">Status</StyledTableCell>
                          <StyledTableCell align="">Comments</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell align="">
                            {viewReportData.activityLog &&
                              fDate(viewReportData.activityLog.stages[2].more.createdOn)}
                          </StyledTableCell>
                          <StyledTableCell align="">
                            {viewReportData.activityLog &&
                              viewReportData.activityLog.stages[2].more.stageStatusName}
                          </StyledTableCell>
                          <StyledTableCell align="">
                            {viewReportData.activityLog &&
                              viewReportData.activityLog.stages[2].more.agentComment}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : null}
            </Box>
          </Scrollbar>
          <Dialog
            open={documentPreview}
            onClose={(e) => {
              setDocumentPreview(false);
              setImagePreviewData();
            }}
            fullWidth
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6">Document Preview</Typography>
              <IconButton onClick={(e) => setDocumentPreview(false)} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {/* <img
                src={imagePreviewData ? imagePreviewData.docUrl : aadharFrontDummy}
                alt=""
                className=""
              /> */}
              <FileViewer
                className="uploadeImgViewver"
                fileType={
                  imagePreviewData
                    ? imagePreviewData.contentType
                      ? imagePreviewData.contentType.split('/')[1]
                      : 'jpg'
                    : 'jpg'
                }
                filePath={imagePreviewData ? imagePreviewData.docUrl : aadharFrontDummy}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => setDocumentPreview(false)}
                variant="contained"
                className="mainBtn"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}
export default ViewLeads;
