import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, useMemo } from 'react';
import faker from 'faker';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
//
import { fDate } from '../../utils/formatTime';
// styling
import { StyledTableRow, StyledTableCell } from '../Global/Styling';
import AgreementToolbar from './AgreementToolbar';
import AgreementService from '../services/AgreementService';
import Loader from '../Global/Loader';

const { AgreementList } = AgreementService;

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'bankName', label: 'Bank Name', alignRight: false },
  { id: 'noofcalls', label: 'No of Branch', alignRight: false },
  { id: 'created', label: 'Created On', alignRight: false },
  { id: 'created', label: 'Modified On', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */

export default function Agreement() {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(600);
  const [agreementListData, setAgreementListData] = useState([]);
  const [openLoader, setOpenLoader] = useState(false); // loader

  const myRef = useRef();

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const obj = {
      companyId: localJWTtoken.token.companyId,
      entityListRequestModel: {
        pageNumber: page,
        pageSize: rowsPerPage,
        searchLiteral: filterName,
        sortOrder: order
      },
      userId: localJWTtoken.token.userId
    };
    fireagreementlistapi(obj, localJWTtoken.token.jwt);
  }, [filterName, rowsPerPage, page]);

  // const handleScroll = () => {
  //   const scrollTop = Math.ceil(myRef.current.scrollTop);
  //   if (page > 0) {
  //     if (scrollTop === 0) {
  //       setPage((c) => c - 1);
  //       console.log('okkkk top', scrollTop, page, myRef.current.clientHeight - 66);
  //     }
  //     if (scrollTop === myRef.current.clientHeight - 66) {
  //       setPage((c) => c + 1);
  //       console.log('okkkk bottom', scrollTop, page, myRef.current.clientHeight - 66);
  //     }
  //   } else {
  //     setPage(1);
  //     console.log('okkkk', scrollTop, page, myRef.current.clientHeight - 66);
  //   }
  // };

  const fireagreementlistapi = async (obj, authToken) => {
    setOpenLoader(true);
    const ifPreviewDataBank = await AgreementList(obj, authToken);
    if (ifPreviewDataBank.status === 'success') {
      setOpenLoader(false);
      console.log('olllll', ifPreviewDataBank.data);
      setAgreementListData(ifPreviewDataBank.data.agreements);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = agreementListData.map((n) => {
        console.log(n);
        return n;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setRowsPerPage(600);
    setPage(1);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - agreementListData.length) : 0;

  console.log('countttt', page, rowsPerPage, emptyRows);

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'bold',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px'
    }
  }));
  return (
    <Page title="Partner Managemet System || Agreement">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="List of Agreement" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card>
            <AgreementToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="agreement"
            />

            <Scrollbar>
              <TableContainer
                sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}
                // ref={myRef}
                // onScroll={handleScroll}
              >
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={agreementListData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {agreementListData.map((item, index) => {
                      console.log(item);
                      const isItemSelected = selected.indexOf(item) !== -1;
                      return (
                        <StyledTableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, item)}
                            />
                          </TableCell>

                          <StyledTableCell align="left">
                            {item.bankName ? item.bankName : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.noOfBranch ? item.noOfBranch : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.createdOn ? fDate(item.createdOn) : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.lastUpdatedOn ? fDate(item.lastUpdatedOn) : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell align="" width={90}>
                            <UserMoreMenu
                              rowId={item.agreementId}
                              parent="agreement"
                              bankId={item.bankId}
                              bankName={item.bankName}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {agreementListData.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Card>
          <Loader openLoader={openLoader} />
        </Box>
      </Container>
    </Page>
  );
}
