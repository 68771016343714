import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));

function UpdateInvoice() {
  const [loanInterested, setLoanInterested] = useState(' ');
  return (
    <Page title="Partner Management System || Invoice">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Update Invoice" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Box className="tableBox" mb={3}>
              <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Lead ID</StyledTableCell>
                      <StyledTableCell align="">Lead Name</StyledTableCell>
                      <StyledTableCell align="">Status</StyledTableCell>
                      <StyledTableCell align="">Comments</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="">12456789</StyledTableCell>
                      <StyledTableCell align="">Blanche Turner</StyledTableCell>
                      <StyledTableCell align="">
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput textInputTable customSelectBox dissabledMenu${loanInterested}`
                          }}
                          value={loanInterested}
                          onChange={(e) => setLoanInterested(e.target.value)}
                          sx={{ minWidth: '200px' }}
                        >
                          <MenuItem value=" " disabled>
                            Select Status
                          </MenuItem>
                          <MenuItem value="Raised">Raised</MenuItem>
                          <MenuItem value="Discrepancy">Discrepancy</MenuItem>
                          <MenuItem value="Realised">Realised</MenuItem>
                          <MenuItem value="Paid">Paid</MenuItem>
                        </Select>
                      </StyledTableCell>
                      <StyledTableCell align="">
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput textInputTable'
                          }}
                          placeholder="Write Your Comments"
                          sx={{ minWidth: '200px' }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="">12456789</StyledTableCell>
                      <StyledTableCell align="">Blanche Turner</StyledTableCell>
                      <StyledTableCell align="">
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput textInputTable customSelectBox dissabledMenu${loanInterested}`
                          }}
                          value={loanInterested}
                          onChange={(e) => setLoanInterested(e.target.value)}
                          sx={{ minWidth: '200px' }}
                        >
                          <MenuItem value=" " disabled>
                            Select Status
                          </MenuItem>
                          <MenuItem value="Raised">Raised</MenuItem>
                          <MenuItem value="Discrepancy">Discrepancy</MenuItem>
                          <MenuItem value="Realised">Realised</MenuItem>
                          <MenuItem value="Paid">Paid</MenuItem>
                        </Select>
                      </StyledTableCell>
                      <StyledTableCell align="">
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput textInputTable'
                          }}
                          placeholder="Write Your Comments"
                          sx={{ minWidth: '200px' }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="">12456789</StyledTableCell>
                      <StyledTableCell align="">Blanche Turner</StyledTableCell>
                      <StyledTableCell align="">
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput textInputTable customSelectBox dissabledMenu${loanInterested}`
                          }}
                          value={loanInterested}
                          onChange={(e) => setLoanInterested(e.target.value)}
                          sx={{ minWidth: '200px' }}
                        >
                          <MenuItem value=" " disabled>
                            Select Status
                          </MenuItem>
                          <MenuItem value="Raised">Raised</MenuItem>
                          <MenuItem value="Discrepancy">Discrepancy</MenuItem>
                          <MenuItem value="Realised">Realised</MenuItem>
                          <MenuItem value="Paid">Paid</MenuItem>
                        </Select>
                      </StyledTableCell>
                      <StyledTableCell align="">
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput textInputTable'
                          }}
                          placeholder="Write Your Comments"
                          sx={{ minWidth: '200px' }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box className="" textAlign="right">
              <Button variant="contained" className="mainBtn">
                Update
              </Button>
            </Box>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
export default UpdateInvoice;
