import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  TextareaAutosize,
  Autocomplete
} from '@mui/material';
import { FiEye } from 'react-icons/fi';
import { BiCloudDownload } from 'react-icons/bi';
import { AiFillPrinter, AiFillDelete, AiFillEye } from 'react-icons/ai';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));
function ReapplyRejected() {
  const [selectBank, setSelectBank] = useState(' ');
  const [selectMode, setSelectMode] = useState(' ');
  return (
    <Page title="PULSE by Real Value Finloans || Rejected">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Submit Application to Bank" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Box className="submitLeadToolbar">
            <Tooltip title="Application View" placement="top" arrow>
              <IconButton className="squareIconButton">
                <FiEye size={18} />
              </IconButton>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Print" placement="top" arrow>
              <IconButton className="squareIconButton">
                <AiFillPrinter size={18} />
              </IconButton>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Download" placement="top" arrow>
              <IconButton className="squareIconButton">
                <BiCloudDownload size={18} />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Fill Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="h6" className="formLabel">
                    Bank
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo2"
                    options={topBanks}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth placeholder="Select Bank Name" />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="h6" className="formLabel">
                    Mode of Submission
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${selectMode}`
                    }}
                    value={selectMode}
                    onChange={(e) => setSelectMode(e.target.value)}
                  >
                    <MenuItem value=" " disabled>
                      Select Mode
                    </MenuItem>
                    <MenuItem value="Email">Email</MenuItem>
                    <MenuItem value="API">API</MenuItem>
                    <MenuItem value="Manual">Manual</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Processing Center
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Processing Center"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Bank Contact Person
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Bank Contact Person"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Comments
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Write Your Comments"
                    multiline
                    rows={5}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="" textAlign="right" mb={3}>
            <Button variant="contained" className="mainBtn">
              Submit
            </Button>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Submmited Application
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin viewCardPadding">
            <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Bank Name</StyledTableCell>
                    <StyledTableCell align="">Mode of Submission</StyledTableCell>
                    <StyledTableCell align="">Processing Center</StyledTableCell>
                    <StyledTableCell align="">Contact Person Name</StyledTableCell>
                    <StyledTableCell align="">Comments</StyledTableCell>
                    <StyledTableCell align="">Submited On</StyledTableCell>
                    <StyledTableCell align="">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="">HDFC</StyledTableCell>
                    <StyledTableCell align="">Email</StyledTableCell>
                    <StyledTableCell align="">Delhi</StyledTableCell>
                    <StyledTableCell align="">Hemant</StyledTableCell>
                    <StyledTableCell align="">Document Pending</StyledTableCell>
                    <StyledTableCell align="">27 April, 2022</StyledTableCell>
                    <StyledTableCell align="">
                      <Tooltip title="Delete" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillDelete size={14} />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="View" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillEye size={14} />
                        </StyledIconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="">HDFC</StyledTableCell>
                    <StyledTableCell align="">Email</StyledTableCell>
                    <StyledTableCell align="">Delhi</StyledTableCell>
                    <StyledTableCell align="">Hemant</StyledTableCell>
                    <StyledTableCell align="">Document Pending</StyledTableCell>
                    <StyledTableCell align="">27 April, 2022</StyledTableCell>
                    <StyledTableCell align="">
                      <Tooltip title="Delete" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillDelete size={14} />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="View" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillEye size={14} />
                        </StyledIconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="">HDFC</StyledTableCell>
                    <StyledTableCell align="">Email</StyledTableCell>
                    <StyledTableCell align="">Delhi</StyledTableCell>
                    <StyledTableCell align="">Hemant</StyledTableCell>
                    <StyledTableCell align="">Document Pending</StyledTableCell>
                    <StyledTableCell align="">27 April, 2022</StyledTableCell>
                    <StyledTableCell align="" width="80">
                      <Tooltip title="Delete" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillDelete size={14} />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="View" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillEye size={14} />
                        </StyledIconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
export default ReapplyRejected;

// Top Banks
const topBanks = [
  { label: 'Axis Bank' },
  { label: 'HDFC Bank' },
  { label: 'HDFC Ltd.' },
  { label: 'ICICI Bank' },
  { label: 'PNB Housing' },
  { label: 'Piramal Housing' },
  { label: 'Standard Chartered Bank' },
  { label: 'Yes Bank' },
  { label: 'Aditya Birla Housing Finance' },
  { label: 'IDFC Bank' },
  { label: 'RBL Bank' },
  { label: 'Federal Bank' },
  { label: 'Fedfina' },
  { label: 'HDB Financial' },
  { label: 'IIFL Finance' },
  { label: 'Tata Capital' },
  { label: 'ICICI Home Finance' },
  { label: 'Cholamandalam Finance' },
  { label: 'L&T Finance' },
  { label: 'Karur Vysya Bank' },
  { label: 'Hero Housing Finance' },
  { label: 'Kotak Mahindra Bank' },
  { label: 'Fullerton Grihashakti' },
  { label: 'Anand Rathi Financial' },
  { label: 'Aadhar Housing Finance' },
  { label: 'Godrej Housing Finance' },
  { label: 'Deutsche Bank' },
  { label: 'Jana Small Finance Bank' },
  { label: 'HSBC Bank' },
  { label: 'DCB Bank' },
  { label: 'Bank of Baroda' },
  { label: 'Bajaj Housing Finance' },
  { label: 'LIC Housing Finance' },
  { label: 'AU Small Finance Bank' },
  { label: 'Union Bank of India' },
  { label: 'State Bank of India' }
];
