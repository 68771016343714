import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import exportIcon from '@iconify/icons-ant-design/export-outline';
import { CgAssign } from 'react-icons/cg';
import { AiOutlineStop } from 'react-icons/ai';
import { MdNotificationsNone, MdSystemUpdateAlt, MdUpdate } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Select,
  MenuItem,
  Autocomplete,
  TextField
} from '@mui/material';
import { DateRange } from 'react-date-range';
import { navigate } from '@storybook/addon-links';
import { useNavigate } from 'react-router-dom';
import { RootStyle, SearchStyle, DateRangeStyled } from '../Global/Styling';
// ---------------------------------------------------------
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------

export default function OnBoardingToolbar({
  numSelected,
  filterName,
  onFilterName,
  parent,
  pageMenu,
  loanTypeItem,
  selectProduct,
  setSelectProduct,
  onFilterStatus,
  onFilterProduct,
  statusList,
  selectStatus,
  selectSource,
  onFilterSource,
  sourceListData,
  setonboradSearchList,
  onboradSearchList,
  handleSearch
}) {
  const navigate = useNavigate();
  const [isOpenFilter, openFilter] = useState(false);
  const [selectCity, setSelectCity] = useState(' ');
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  console.log(filterDatesRange);
  return (
    <>
      {isOpenFilter && (
        <Box px={3} pt={2}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectProduct}`
                }}
                value={selectProduct}
                onChange={onFilterProduct}
              >
                <MenuItem value=" " disabled>
                  Select Product
                </MenuItem>
                {loanTypeItem.length > 0
                  ? loanTypeItem.map((item, index) => {
                      console.log(item);
                      return (
                        <MenuItem value={item.loanId} key={index}>
                          {item.loanName}
                        </MenuItem>
                      );
                    })
                  : ''}
                {/* <MenuItem value="Home Loan">Home Loan</MenuItem>
                <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                <MenuItem value="Education Loan">Education Loan</MenuItem>
                <MenuItem value="Credit Card">Credit Card</MenuItem> */}
              </Select>
            </Grid>
            <Grid item xs sx={{ position: 'relative' }}>
              <Box className="displayDateRange">
                {filterDatesRange[0].startDate !== null
                  ? `${fDate(filterDatesRange[0].startDate)} to `
                  : ''}
                {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
                {filterDatesRange[0].startDate === null && filterDatesRange[0].endDate === null ? (
                  <Typography className="dissabledMenu">Select Date Range</Typography>
                ) : (
                  ''
                )}
              </Box>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                MenuProps={{
                  className: 'menuOpened'
                }}
                value=" "
                className="datesSelect"
              >
                <DateRangeStyled
                  editableDateInputs={false}
                  onChange={(item) => setFilterDatesRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={filterDatesRange}
                  className="dateRangePicker"
                />
              </Select>
            </Grid>
            <Grid item xs>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={topCities}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select City" />
                )}
              />
            </Grid>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectStatus}`
                }}
                value={selectStatus}
                onChange={onFilterStatus}
              >
                <MenuItem value=" ">Select Status</MenuItem>
                {statusList.length > 0
                  ? statusList.map((item, index) => {
                      console.log(item);
                      return (
                        <MenuItem value={item.stageStatusId} key={index}>
                          {item.stageStatusName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Grid>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectSource}`
                }}
                value={selectSource}
                onChange={onFilterSource}
              >
                <MenuItem value=" ">Select Source</MenuItem>
                {sourceListData.length > 0
                  ? sourceListData.map((item, index) => {
                      console.log(item);
                      return (
                        <MenuItem value={item.typeId} key={index}>
                          {item.typeCode}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Grid>
          </Grid>
        </Box>
      )}

      <RootStyle
        sx={{
          minHeight: 45,
          borderBottom: '1px solid #eaedf1'
        }}
      >
        <Box>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => {
              console.log('pageElement', pageElement);
              return (
                <React.Fragment key={pageElement.subPageId + index}>
                  {pageElement.subPageCode === 'ADD_NEW' && pageElement.isEnable === true ? (
                    <>
                      <Tooltip title="Add New" placement="top" arrow>
                        <IconButton
                          className="squareIconButton"
                          onClick={(e) => navigate(`/${parent}/add`)}
                        >
                          <Icon icon={add} width={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : pageElement.subPageCode === 'EXPORT_DATA' && pageElement.isEnable === true ? (
                    <>
                      <Tooltip title="Export data" placement="top" arrow>
                        <IconButton className="squareIconButton">
                          <MdSystemUpdateAlt size={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              );
            })}
        </Box>
        <Box>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => (
              <>
                {' '}
                {pageElement.subPageCode === 'SEARCH' && pageElement.isEnable === true ? (
                  <SearchStyle
                    value={onboradSearchList}
                    // onChange={(e) => setonboradSearchList(e.target.value)}
                    onChange={(e) => handleSearch(e)}
                    placeholder="Search Prospect"
                    classes={{
                      root: 'searchHolder',
                      input: 'searchInput'
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    }
                  />
                ) : pageElement.subPageCode === 'FILTER_LIST' && pageElement.isEnable === true ? (
                  <Tooltip title="Filter list">
                    <IconButton
                      onClick={(e) => {
                        if (isOpenFilter) {
                          openFilter(false);
                        } else {
                          openFilter(true);
                        }
                      }}
                    >
                      <Icon icon={roundFilterList} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </>
            ))}
        </Box>
      </RootStyle>
    </>
  );
}

// Top Cities
const topCities = [
  { label: 'Mumbai' },
  { label: 'Delhi' },
  { label: 'Bengaluru' },
  { label: 'Ahmedabad' },
  { label: 'Hyderabad' },
  { label: 'Chennai' },
  { label: 'Kolkata' },
  { label: 'Pune' },
  { label: 'Jaipur' },
  { label: 'Surat' },
  { label: 'Lucknow' },
  { label: 'Kanpur' },
  { label: 'Nagpur' },
  { label: 'Patna' },
  { label: 'Indore' },
  { label: 'Thane' },
  { label: 'Bhopal' },
  { label: 'Visakhapatnam' },
  { label: 'Vadodara' },
  { label: 'Firozabad' },
  { label: 'Ludhiana' },
  { label: 'Rajkot' },
  { label: 'Agra' },
  { label: 'Siliguri' },
  { label: 'Nashik' },
  { label: 'Faridabad' },
  { label: 'Patiala' },
  { label: 'Meerut' },
  { label: 'Varanasi' },
  { label: 'Dhanbad' },
  { label: 'Jodhpur' },
  { label: 'Amritsar' },
  { label: 'Raipur' },
  { label: 'Allahabad' },
  { label: 'Coimbatore' },
  { label: 'Jabalpur' },
  { label: 'Gwalior' },
  { label: 'Vijayawada' },
  { label: 'Madurai' },
  { label: 'Guwahati' },
  { label: 'Chandigarh' },
  { label: 'Hubli-Dharwad' },
  { label: 'Amroha' },
  { label: 'Moradabad' },
  { label: 'Gurgaon' },
  { label: 'Aligarh' },
  { label: 'Solapur' },
  { label: 'Ranchi' },
  { label: 'Jalandhar' },
  { label: 'Tiruchirappalli' },
  { label: 'Bhubaneswar' },
  { label: 'Salem' },
  { label: 'Warangal' },
  { label: 'Mira-Bhayandar' },
  { label: 'Thiruvananthapuram' },
  { label: 'Bhiwandi' },
  { label: 'Saharanpur' },
  { label: 'Guntur' },
  { label: 'Amravati' },
  { label: 'Bikaner' },
  { label: 'Noida' },
  { label: 'Jamshedpur' },
  { label: 'Bhilai Nagar' },
  { label: 'Cuttack' },
  { label: 'Kochi' },
  { label: 'Udaipur' },
  { label: 'Bhavnagar' },
  { label: 'Dehradun' },
  { label: 'Asansol' },
  { label: 'Nanded-Waghala' },
  { label: 'Ajmer' },
  { label: 'Jamnagar' },
  { label: 'Ujjain' },
  { label: 'Sangli' },
  { label: 'Loni' },
  { label: 'Jhansi' },
  { label: 'Pondicherry' },
  { label: 'Nellore' },
  { label: 'Jammu' },
  { label: 'Belagavi' },
  { label: 'Raurkela' },
  { label: 'Mangaluru' },
  { label: 'Tirunelveli' },
  { label: 'Malegaon' },
  { label: 'Gaya' },
  { label: 'Tiruppur' },
  { label: 'Davanagere' },
  { label: 'Kozhikode' },
  { label: 'Akola' },
  { label: 'Kurnool' },
  { label: 'Bokaro' },
  { label: 'Rajahmundry' },
  { label: 'Ballari' },
  { label: 'Agartala' },
  { label: 'Bhagalpur' },
  { label: 'Latur' },
  { label: 'Dhule' },
  { label: 'Korba' },
  { label: 'Bhilwara' },
  { label: 'Brahmapur' },
  { label: 'Mysore' },
  { label: 'Muzaffarpur' },
  { label: 'Ahmednagar' },
  { label: 'Kollam' },
  { label: 'Bilaspur' },
  { label: 'Shahjahanpur' },
  { label: 'Thrissur' },
  { label: 'Alwar' },
  { label: 'Kakinada' },
  { label: 'Nizamabad' },
  { label: 'Sagar' },
  { label: 'Tumkur' },
  { label: 'Hisar' },
  { label: 'Rohtak' },
  { label: 'Panipat' },
  { label: 'Darbhanga' },
  { label: 'Kharagpur' },
  { label: 'Aizawl' },
  { label: 'Ichalkaranji' },
  { label: 'Tirupati' },
  { label: 'Karnal' },
  { label: 'Bathinda' },
  { label: 'Rampur' },
  { label: 'Shivamogga' },
  { label: 'Ratlam' },
  { label: 'Modinagar' },
  { label: 'Durg' },
  { label: 'Shillong' },
  { label: 'Imphal' },
  { label: 'Hapur' },
  { label: 'Ranipet' },
  { label: 'Anantapur' },
  { label: 'Arrah' },
  { label: 'Karimnagar' },
  { label: 'Etawah' },
  { label: 'Bharatpur' },
  { label: 'Begusarai' },
  { label: 'Chhapra' },
  { label: 'Kadapa' },
  { label: 'Pali' },
  { label: 'Satna' },
  { label: 'Vizianagaram' },
  { label: 'Katihar' },
  { label: 'Hardwar' },
  { label: 'Sonipat' },
  { label: 'Nagercoil' },
  { label: 'Thanjavur' },
  { label: 'Murwara' },
  { label: 'Naihati' },
  { label: 'Sambhal' },
  { label: 'Nadiad' },
  { label: 'Yamunanagar' },
  { label: 'Eluru' },
  { label: 'Munger' },
  { label: 'Panchkula' },
  { label: 'Deoghar' },
  { label: 'Ongole' },
  { label: 'Nandyal' },
  { label: 'Morena' },
  { label: 'Bhiwani' },
  { label: 'Porbandar' },
  { label: 'Palakkad' },
  { label: 'Anand' },
  { label: 'Purnia' },
  { label: 'Baharampur' },
  { label: 'Barmer' },
  { label: 'Morvi' },
  { label: 'Orai' },
  { label: 'Bahraich' },
  { label: 'Vellore' },
  { label: 'Khammam' },
  { label: 'Mahesana' },
  { label: 'Silchar' },
  { label: 'Sambalpur' },
  { label: 'Rewa' },
  { label: 'Unnao' },
  { label: 'Hugli-Chinsurah' },
  { label: 'Raiganj' },
  { label: 'Adityapur' },
  { label: 'Alappuzha' },
  { label: 'Bahadurgarh' },
  { label: 'Machilipatnam' },
  { label: 'Rae Bareli' },
  { label: 'Jalpaiguri' },
  { label: 'Bharuch' },
  { label: 'Hoshiarpur' },
  { label: 'Baramula' },
  { label: 'Adoni' },
  { label: 'Jind' },
  { label: 'Tonk' },
  { label: 'Tenali' },
  { label: 'Kancheepuram' },
  { label: 'Vapi' },
  { label: 'Navsari' },
  { label: 'Mahbubnagar' },
  { label: 'Puri' },
  { label: 'Erode' },
  { label: 'Batala' },
  { label: 'Haldwani' },
  { label: 'Vidisha' },
  { label: 'Saharsa' },
  { label: 'Thanesar' },
  { label: 'Chittoor' },
  { label: 'Veraval' },
  { label: 'Lakhimpur' },
  { label: 'Sitapur' },
  { label: 'Hindupur' },
  { label: 'Santipur' },
  { label: 'Balurghat' },
  { label: 'Ganjbasoda' },
  { label: 'Moga' },
  { label: 'Srinagar' },
  { label: 'Medinipur' },
  { label: 'Habra' },
  { label: 'Sasaram' },
  { label: 'Hajipur' },
  { label: 'Bhuj' },
  { label: 'Shimla' },
  { label: 'Tiruvannamalai' },
  { label: 'Kaithal' },
  { label: 'Rajnandgaon' },
  { label: 'Godhra' },
  { label: 'Hazaribag' },
  { label: 'Bhimavaram' },
  { label: 'Mandsaur' },
  { label: 'Dibrugarh' },
  { label: 'Kolar' },
  { label: 'Bankura' },
  { label: 'Mandya' },
  { label: 'Dehri-on-Sone' },
  { label: 'Madanapalle' },
  { label: 'Malerkotla' },
  { label: 'Lalitpur' },
  { label: 'Bettiah' },
  { label: 'Pollachi' },
  { label: 'Khanna' },
  { label: 'Neemuch' },
  { label: 'Palwal' },
  { label: 'Palanpur' },
  { label: 'Guntakal' },
  { label: 'Nabadwip' },
  { label: 'Udupi' },
  { label: 'Jagdalpur' },
  { label: 'Motihari' },
  { label: 'Pilibhit' },
  { label: 'Dimapur' },
  { label: 'Mohali' },
  { label: 'Dharmavaram' },
  { label: 'Kashipur' },
  { label: 'Sivakasi' },
  { label: 'Darjiling' },
  { label: 'Chikkamagaluru' },
  { label: 'Gudivada' },
  { label: 'Adilabad' },
  { label: 'Yavatmal' },
  { label: 'Barnala' },
  { label: 'Nagaon' },
  { label: 'Narasaraopet' },
  { label: 'Raigarh' },
  { label: 'Roorkee' },
  { label: 'Valsad' },
  { label: 'Ambikapur' },
  { label: 'Giridih' },
  { label: 'Chandausi' },
  { label: 'Purulia' },
  { label: 'Patan' },
  { label: 'Bagaha' },
  { label: 'Hardoi' },
  { label: 'Achalpur' },
  { label: 'Osmanabad' },
  { label: 'Deesa' },
  { label: 'Nandurbar' },
  { label: 'Azamgarh' },
  { label: 'Ramgarh' },
  { label: 'Firozpur' },
  { label: 'Baripada' },
  { label: 'Karwar' },
  { label: 'Siwan' },
  { label: 'Anantnag' },
  { label: 'Tadpatri' },
  { label: 'Satara' },
  { label: 'Bhadrak' },
  { label: 'Kishanganj' },
  { label: 'Wardha' },
  { label: 'Amreli' },
  { label: 'Neyveli' },
  { label: 'Jamalpur' },
  { label: 'Marmagao' },
  { label: 'Udgir' },
  { label: 'Tadepalligudem' },
  { label: 'Nagapattinam' },
  { label: 'Buxar' },
  { label: 'Aurangabad' },
  { label: 'Jehanabad' },
  { label: 'Khair' },
  { label: 'Sawai Madhopur' },
  { label: 'Kapurthala' },
  { label: 'Chilakaluripet' },
  { label: 'Malappuram' },
  { label: 'Rewari' },
  { label: 'Nagaur' },
  { label: 'Sultanpur' },
  { label: 'Nagda' },
  { label: 'Port Blair' },
  { label: 'Lakhisarai' },
  { label: 'Panaji' },
  { label: 'Tinsukia' },
  { label: 'Itarsi' },
  { label: 'Kohima' },
  { label: 'Balangir' },
  { label: 'Nawada' },
  { label: 'Jharsuguda' },
  { label: 'Jagtial' },
  { label: 'Viluppuram' },
  { label: 'Amalner' },
  { label: 'Zirakpur' },
  { label: 'Tanda' },
  { label: 'Tiruchengode' },
  { label: 'Nagina' },
  { label: 'Yemmiganur' },
  { label: 'Vaniyambadi' },
  { label: 'Theni Allinagaram' },
  { label: 'Margao' },
  { label: 'Akot' },
  { label: 'Mhow' },
  { label: 'Kot Kapura' },
  { label: 'Kadiri' },
  { label: 'Najibabad' },
  { label: 'Nirmal' },
  { label: 'Udhagamandalam' },
  { label: 'Jhumri Tilaiya' },
  { label: 'Aruppukkottai' },
  { label: 'Jamui' },
  { label: 'Sitamarhi' },
  { label: 'Chirala' },
  { label: 'Anjar' },
  { label: 'Karaikal' },
  { label: 'Hansi' },
  { label: 'Anakapalle' },
  { label: 'Mahasamund' },
  { label: 'Faridkot' },
  { label: 'Dhoraji' },
  { label: 'Balaghat' },
  { label: 'Khambhat' },
  { label: 'Kavali' },
  { label: 'Dhamtari' },
  { label: 'Ashok Nagar' },
  { label: 'Bargarh' },
  { label: 'Kamareddy' },
  { label: 'Kothagudem' },
  { label: 'Ramanagaram' },
  { label: 'Gokak' },
  { label: 'Tikamgarh' },
  { label: 'Araria' },
  { label: 'Rishikesh' },
  { label: 'Daltonganj' },
  { label: 'Arakkonam' },
  { label: 'Washim' },
  { label: 'Sangrur' },
  { label: 'Bodhan' },
  { label: 'Fazilka' },
  { label: 'Palacole' },
  { label: 'Keshod' },
  { label: 'Wadhwan' },
  { label: 'Gurdaspur' },
  { label: 'Vatakara' },
  { label: 'Tura' },
  { label: 'Narnaul' },
  { label: 'Kharar' },
  { label: 'Yadgir' },
  { label: 'Ambejogai' },
  { label: 'Ankleshwar' },
  { label: 'Paradip' },
  { label: 'Virudhachalam' },
  { label: 'Kanhangad' },
  { label: 'Kadi' },
  { label: 'Gobindgarh' },
  { label: 'Tindivanam' },
  { label: 'Taliparamba' },
  { label: 'Tanuku' },
  { label: 'Virudhunagar' },
  { label: 'Koyilandy' },
  { label: 'Jorhat' },
  { label: 'Karur' },
  { label: 'Valparai' },
  { label: 'Neyyattinkara' },
  { label: 'Bapatla' },
  { label: 'Fatehabad' },
  { label: 'Sankarankovil' },
  { label: 'Tenkasi' },
  { label: 'Ratnagiri' },
  { label: 'Sikandrabad' },
  { label: 'Chaibasa' },
  { label: 'Chirmiri' },
  { label: 'Bhawanipatna' },
  { label: 'Kayamkulam' },
  { label: 'Nabha' },
  { label: 'Dhenkanal' },
  { label: 'Uran Islampur' },
  { label: 'Gopalganj' },
  { label: 'Bongaigaon' },
  { label: 'Tarn Taran' },
  { label: 'Barbil' },
  { label: 'Koratla' },
  { label: 'Arambagh' },
  { label: 'Gohana' },
  { label: 'Ladnu' },
  { label: 'Tamluk' },
  { label: 'Jagraon' },
  { label: 'Alirajpur' },
  { label: 'Tandur' },
  { label: 'Naidupet' },
  { label: 'Tirupathur' },
  { label: 'Tohana' },
  { label: 'Dhubri' },
  { label: 'Visnagar' },
  { label: 'Vrindavan' },
  { label: 'Nokha' },
  { label: 'Nagari' },
  { label: 'Narwana' },
  { label: 'Ujhani' },
  { label: 'Samastipur' },
  { label: 'Laharpur' },
  { label: 'Nimbahera' },
  { label: 'Diphu' },
  { label: 'Jhargram' },
  { label: 'Tilhar' },
  { label: 'Udumalaipettai' },
  { label: 'Wanaparthy' },
  { label: 'Gudur' },
  { label: 'Kendujhar' },
  { label: 'Mandi' },
  { label: 'Nedumangad' },
  { label: 'North Lakhimpur' },
  { label: 'Vinukonda' },
  { label: 'Tiptur' },
  { label: 'Gobichettipalayam' },
  { label: 'Wani' },
  { label: 'Upleta' },
  { label: 'Narasapuram' },
  { label: 'Nuzvid' },
  { label: 'Tezpur' },
  { label: 'Una' },
  { label: 'Sheopur' },
  { label: 'Thiruvarur' },
  { label: 'Lonavla' },
  { label: 'Ponnur' },
  { label: 'Kagaznagar' },
  { label: 'Gadwal' },
  { label: 'Bhatapara' },
  { label: 'Kandukur' },
  { label: 'Unjha' },
  { label: 'Lunglei' },
  { label: 'Karimganj' },
  { label: 'Kannur' },
  { label: 'Bobbili' },
  { label: 'Talegaon Dabhade' },
  { label: 'Anjangaon' },
  { label: 'Mangrol' },
  { label: 'Gangarampur' },
  { label: 'Thiruvallur' },
  { label: 'Tirur' },
  { label: 'Jatani' },
  { label: 'Viramgam' },
  { label: 'Rajsamand' },
  { label: 'Yanam' },
  { label: 'Kottayam' },
  { label: 'Dhuri' },
  { label: 'Namakkal' },
  { label: 'Kasaragod' },
  { label: 'Kunnamkulam' },
  { label: 'Umred' },
  { label: 'Bellampalle' },
  { label: 'Ottappalam' },
  { label: 'Dumraon' },
  { label: 'Jaggaiahpet' },
  { label: 'Goalpara' },
  { label: 'Tuni' },
  { label: 'Lachhmangarh' },
  { label: 'Bhongir' },
  { label: 'Amalapuram' },
  { label: 'Firozpur Cantt.' },
  { label: 'Vikarabad' },
  { label: 'Thiruvalla' },
  { label: 'Palghar' },
  { label: 'Jangaon' },
  { label: 'Bheemunipatnam' },
  { label: 'Panna' },
  { label: 'Thodupuzha' },
  { label: 'Arwal' },
  { label: 'Venkatagiri' },
  { label: 'Kalpi' },
  { label: 'Churu' },
  { label: 'Arsikere' },
  { label: 'Ozar' },
  { label: 'Thirumangalam' },
  { label: 'Nasirabad' },
  { label: 'Nanjangud' },
  { label: 'Forbesganj' },
  { label: 'Tundla' },
  { label: 'Bhadrachalam' },
  { label: 'Madhepura' },
  { label: 'Pithoragarh' },
  { label: 'Mathura' },
  { label: 'Sultanganj' },
  { label: 'Raxaul' },
  { label: 'Pilani' },
  { label: 'Mapusa' },
  { label: 'Sivaganga' },
  { label: 'Shahpur' },
  { label: 'Ramnagar' },
  { label: 'Rameshwaram' },
  { label: 'Perambalur' },
  { label: 'Manvi' },
  { label: 'Murshidabad' },
  { label: 'Mahe' },
  { label: 'Solan' },
  { label: 'Sanawad' },
  { label: 'Bulandshahr' },
  { label: 'Satana' },
  { label: 'Sanand' },
  { label: 'Sohna' },
  { label: 'Rudrapur' },
  { label: 'Perumbavoor' },
  { label: 'Raikot' },
  { label: 'Pauri' },
  { label: 'Shahganj' },
  { label: 'Mount Abu' },
  { label: 'Silvassa' },
  { label: 'Sikanderpur' },
  { label: 'Navi Mumbai' }
];
