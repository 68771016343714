import { globalAdminUrl } from '../services/globalApi';

export const CommonApiforJourney = async (method, endpoint, credentials, authToken, formType) => {
  try {
    const headerData = {};
    if (formType === 'formdata') {
      headerData.Authorization = `Bearer ${authToken}`;
    } else {
      headerData['Content-Type'] = `application/json`;
      headerData.Authorization = `Bearer ${authToken}`;
    }

    return await fetch(`${globalAdminUrl}/${endpoint}`, {
      method: method,
      headers: headerData,
      body: credentials
    })
      .then((response) => response.json())
      .then((res) => res);
  } catch (error) {
    return {
      msg: `Server not responding error ${error}`
    };
  }
};
