import { filter } from 'lodash';
import CryptoJS from 'crypto-js';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import UserMoreMenu from '../../components/_dashboard/user/UserMoreMenu';
import UserListHead from '../../components/_dashboard/user/UserListHead';
import UserListToolbar from '../../components/_dashboard/user/UserListToolbar';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
import Loader from '../Global/Loader';
//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';
import ProspectToolbar from './ProspectToolbar';
import prospectService from '../services/prospectService';
import partnerService from '../services/partnerService';
import leadsService from '../services/leadsService';

// ----------------------------------------------------------------------

const { ProspectListApi, ProspectListStageStatuses, ProspectDelete, AssignProspects } =
  prospectService;
const { LoanTypes, CityList, SourceList } = partnerService;
const { AssignLeads, InternalSalesManager } = leadsService; // need to replace api assign prospect

const TABLE_HEAD = [
  { id: 'id', label: 'Prospect ID', alignRight: false },
  { id: 'created', label: 'Prospect Date', alignRight: false },
  { id: 'randomPhoneNumber', label: 'Mobile', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'loanType', label: 'Loan Type', alignRight: false },
  { id: 'source', label: 'Source', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'modifiedOn', label: 'Last Update On', alignRight: false }
  // { id: 'createdBy', label: 'Assign to', alignRight: false },
  // { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function ProspectListView() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('dsc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('created');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [pageMenu, setPageMenu] = useState([]);
  const [prospectListApiData, setProspectListApiData] = useState([]);
  const [refresh, setRefresh] = useState('');
  const [prospectList, setProspectList] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [token, setToken] = useState('');
  const [userId, setUserId] = useState('');
  // for filter
  const [selectStatus, setSelectStatus] = useState(' '); // select status
  const [statusList, setStatusList] = useState([]); // select status data from api
  const [selectSource, setSelectSource] = useState(' '); // select source
  const [sourceListData, setSourceListData] = useState([]); // source list data from api
  const [cityListMenu, setCityListMenu] = useState([]);
  const [cityListData, setCityListData] = useState(null);
  const [refreshComp, setRefreshComp] = useState('');
  const [leadId1, setLeadId1] = useState();
  const [openLoader, setOpenLoader] = useState(false); // loader
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  // lead assign
  const [openAssign, setOpenAssign] = useState(false);
  const [assignValue, setAssignValue] = useState(' ');
  const [validationMsg, setValidationMsg] = useState('');
  const [alertMsg, setAlertMsg] = useState(false);
  const [salesPersonList, setSalesPersonList] = useState([]); // sales person list
  const [openDelete, setOpenDelete] = useState(false);

  const handleRequestSort = (event, property) => {
    if (property === 'created') {
      const isAsc = orderBy === property && order === 'dsc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = prospectList.map((n) => {
        console.log('newname', n);
        return n;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prospectList.length) : 0;

  // const filteredUsers = applySortFilter(prospectList, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'bold',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px'
    }
  }));
  const handleClick = (event, item) => {
    const selectedIndex = selected.indexOf(item);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // select status
  const handleSelectStatus = (e) => {
    setSelectStatus(e.target.value);
  };

  // select source
  const handleSelectSource = (e) => {
    setSelectSource(e.target.value);
  };

  // assign person
  const handleAssignSubmit = async (e) => {
    if (assignValue === ' ') {
      setAlertMsg(true);
      return true;
    }
    const newArray = [];
    selected.forEach((item) => {
      console.log('item22', item);
      const obj = {
        companyId: companyId,
        userId: userId,

        leadId: item.leadId,
        leadStageId: item.leadStageId,

        stageId: item.stageId,
        leadStageStatusId: item.leadStageStatusId,

        assignedTo: assignValue
      };
      newArray.push(obj);
    });
    const credential = {
      assignableProspects: newArray
    };

    const AssignProspectsApiResponse = await AssignProspects(credential, token);
    if (AssignProspectsApiResponse.msg) {
      if (AssignProspectsApiResponse.msg === 'Forbidden') {
        navigate('/');
      }
    }
    if (AssignProspectsApiResponse.status === 'success') {
      console.log('AssignProspectsApiResponse', AssignProspectsApiResponse);
      navigate('/prospect');
      setAssignValue(' ');
      setOpenAssign(false);
      setRefreshComp(new Date());
      setSelected([]);
    }
  };

  // dialog box for delete button

  const handleOpenDelete = (e, rowId) => {
    setOpenDelete(true);
    setLeadId1(rowId);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // delete prospect list
  const handleDelete = async (e) => {
    const credentials1 = {
      companyId: companyId,
      userId: userId,
      leadId: leadId1
    };
    const ProspectDeleteApiResponse = await ProspectDelete(credentials1, token);
    if (ProspectDeleteApiResponse.msg) {
      if (ProspectDeleteApiResponse.msg === 'Forbidden') {
        navigate('/');
      }
    }
    if (ProspectDeleteApiResponse.status === 'success') {
      handleCloseDelete();
      setRefreshComp(new Date());
    }
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }
    console.log('localJWTtoken', localJWTtoken);
    const authToken1 = localJWTtoken.token.jwt;
    const authCompanyId = localJWTtoken.token.companyId;
    const authUserId = localJWTtoken.token.userId;
    (async () => {
      // select status api
      const credentials1 = {
        stageId: 56
      };
      const ListStageStatusApiResponse = await ProspectListStageStatuses(credentials1, authToken1);
      if (ListStageStatusApiResponse.msg) {
        if (ListStageStatusApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ListStageStatusApiResponse.status === 'success') {
        setStatusList(ListStageStatusApiResponse.data.prospectStatuses);
      }

      // select source
      const SourceListApiResponse = await SourceList(authToken1);
      if (SourceListApiResponse.msg) {
        if (SourceListApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (SourceListApiResponse.status === 'success') {
        setSourceListData(SourceListApiResponse.data.prospectSourceTypes);
      }

      // select city  api for filter
      const CityListApiResponse = await CityList(
        {
          searchLiteral: '',
          stateId: null
        },
        authToken1
      );
      console.log('CityListApiResponse', CityListApiResponse);
      if (CityListApiResponse.action_status === 'success') {
        setCityListMenu(CityListApiResponse.data);
      } else {
        setCityListMenu([]);
      }

      // select internal sales manager
      const credentials2 = {
        companyId: authCompanyId
      };
      const InternalSalesManagerApiResponse = await InternalSalesManager(credentials2, authToken1);
      if (InternalSalesManagerApiResponse.msg) {
        if (InternalSalesManagerApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (InternalSalesManagerApiResponse.status === 'success') {
        const finalData = InternalSalesManagerApiResponse.data.salesPersons.filter(
          (item, index) => authUserId !== item.userId
        );
        setSalesPersonList(finalData);
      }
    })();
  }, []);

  useEffect(() => {
    const sidebarMenu = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d74');
    if (sidebarMenu !== null) {
      const bytes = CryptoJS.AES.decrypt(sidebarMenu, 'sidebarMenu');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      console.log('ciphertext', JSON.parse(originalText));

      const pageCode = localStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d75');
      const pageCodeBytes = CryptoJS.AES.decrypt(pageCode, 'pageCode');
      const pageCodeText = pageCodeBytes.toString(CryptoJS.enc.Utf8);
      console.log('ciphertext', pageCodeText);
      JSON.parse(originalText).accessPagesMapping.forEach((accessPageMap) => {
        if (accessPageMap.pageCode === pageCodeText) {
          setPageMenu(accessPageMap.accessSubPagesMappings);
        }
      });
    }

    setOpenLoader(true);
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    if (localJWTtoken === null) {
      return true;
    }
    console.log('localJWTtoken', localJWTtoken);
    const authToken = localJWTtoken.token.jwt;
    setToken(authToken);
    const authCompanyId = localJWTtoken.token.companyId;
    setCompanyId(authCompanyId);
    const authUserId = localJWTtoken.token.userId;
    setUserId(authUserId);

    const credentials = {
      companyId: authCompanyId,
      userId: authUserId,

      leadsFilterModel: {
        fromLeadDate: filterDatesRange[0].startDate,
        toLeadDate: filterDatesRange[0].endDate,
        leadStageStatusId: selectStatus !== ' ' ? selectStatus : null,
        sourceTypeId: selectSource !== ' ' ? selectSource : null,
        // cityId: stateListData.length < 0 ? stateListData : null,
        cityId: cityListData === null ? null : cityListData.cityId
      },

      entityListRequestModel: {
        searchLiteral: filterName !== '' ? filterName : null,
        pageSize: 10,
        pageNumber: 1,
        sortOrder: order
      }
    };

    setTimeout(async () => {
      // prospect list api
      const ProspectListApiResponse = await ProspectListApi(credentials, authToken);
      console.log('ProspectListApiResponse', ProspectListApiResponse);
      if (ProspectListApiResponse.msg) {
        if (ProspectListApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (ProspectListApiResponse.status === 'success') {
        setOpenLoader(false);
        setProspectList(ProspectListApiResponse.data.prospects);
      }

      // // select status api
      // const credentials1 = {
      //   stageId: 56
      // };
      // const ListStageStatusApiResponse = await ProspectListStageStatuses(credentials1, authToken);
      // if (ListStageStatusApiResponse.msg) {
      //   if (ListStageStatusApiResponse.msg === 'Forbidden') {
      //     navigate('/');
      //   }
      // }
      // if (ListStageStatusApiResponse.status === 'success') {
      //   setStatusList(ListStageStatusApiResponse.data.prospectStatuses);
      // }

      // // select source
      // const SourceListApiResponse = await SourceList(authToken);
      // if (SourceListApiResponse.msg) {
      //   if (SourceListApiResponse.msg === 'Forbidden') {
      //     navigate('/');
      //   }
      // }
      // if (SourceListApiResponse.status === 'success') {
      //   setSourceListData(SourceListApiResponse.data.prospectSourceTypes);
      // }

      // // select city  api for filter
      // const CityListApiResponse = await CityList(
      //   {
      //     searchLiteral: '',
      //     stateId: null
      //   },
      //   authToken
      // );
      // console.log('CityListApiResponse', CityListApiResponse);
      // if (CityListApiResponse.action_status === 'success') {
      //   setCityListMenu(CityListApiResponse.data);
      // } else {
      //   setCityListMenu([]);
      // }

      // // select internal sales manager
      // const credentials2 = {
      //   companyId: authCompanyId
      // };
      // const InternalSalesManagerApiResponse = await InternalSalesManager(credentials2, authToken);
      // if (InternalSalesManagerApiResponse.msg) {
      //   if (InternalSalesManagerApiResponse.msg === 'Forbidden') {
      //     navigate('/');
      //   }
      // }
      // if (InternalSalesManagerApiResponse.status === 'success') {
      //   setSalesPersonList(InternalSalesManagerApiResponse.data.salesPersons);
      // }
    }, 1000);
  }, [
    refresh,
    filterName,
    selectStatus,
    selectSource,
    cityListData,
    filterDatesRange,
    refreshComp,
    order
  ]);
  return (
    <Page title="PULSE by Real Value Finloans || Prospect">
      <Container maxWidth="">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="List of Prospect" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card>
            <ProspectToolbar
              numSelected={selected.length}
              selected={selected}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="prospect"
              pageMenu={pageMenu}
              selectStatus={selectStatus}
              onFilterStatus={handleSelectStatus}
              statusList={statusList}
              selectSource={selectSource}
              onFilterSource={handleSelectSource}
              sourceListData={sourceListData}
              cityListMenu={cityListMenu}
              cityListData={cityListData}
              setCityListData={setCityListData}
              filterDatesRange={filterDatesRange}
              setFilterDatesRange={setFilterDatesRange}
              setRefresh={setRefresh}
              onAssignSubmit={handleAssignSubmit}
              token={token}
              openAssign={openAssign}
              setOpenAssign={setOpenAssign}
              assignValue={assignValue}
              setAssignValue={setAssignValue}
              validationMsg={validationMsg}
              setValidationMsg={setValidationMsg}
              alertMsg={alertMsg}
              setAlertMsg={setAlertMsg}
              salesPersonList={salesPersonList}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={prospectList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {prospectList !== undefined && prospectList.length > 0
                      ? prospectList.map((item, index) => {
                          console.log(item);
                          const isItemSelected = selected.indexOf(item) !== -1;
                          return (
                            <StyledTableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, item)}
                                />
                              </TableCell>
                              <StyledTableCell align="left">
                                {item.leadId ? item.leadId : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {fDate(item.createdOn)}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.mobile ? item.mobile : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.customerName ? item.customerName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.cityName ? item.cityName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.loanName ? item.loanName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item.sourceTypeName ? item.sourceTypeName : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Label
                                  variant="ghost"
                                  color={
                                    (item.stageStatusName === 'Not Assigned' && 'error') ||
                                    'success'
                                  }
                                >
                                  {sentenceCase(
                                    item.stageStatusName ? item.stageStatusName : 'null'
                                  )}
                                </Label>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {fDate(item.leadStagesCreatedOn)}
                              </StyledTableCell>
                              {/* <StyledTableCell align="left">
                                {item.assignedTo ? item.assignedTo : 'N/A'}
                              </StyledTableCell> */}

                              {/* <StyledTableCell align="" width={80}>
                                <UserMoreMenu
                                  rowId={item.leadId}
                                  parent="prospect"
                                  companyId={item.companyId}
                                  pageMenu={pageMenu}
                                  handleDelete={handleDelete}
                                  handleOpenDelete={handleOpenDelete}
                                />
                              </StyledTableCell> */}
                            </StyledTableRow>
                          );
                        })
                      : ''}
                    {/* {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          created,
                          randomPhoneNumber,
                          name,
                          city,
                          loanType,
                          source,
                          status,
                          users,
                          createdBy,
                          modifiedOn,
                          isVerified
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>
                            <StyledTableCell align="left">{id}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{randomPhoneNumber}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="left">{source}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={(status === 'Not Assigned' && 'error') || 'success'}
                              >
                                {sentenceCase(status)}
                              </Label>
                            </StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{createdBy}</StyledTableCell>

                            <StyledTableCell align="" width={80}>
                              <UserMoreMenu rowId={id} parent="prospect" pageMenu={pageMenu} />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })} */}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {prospectList.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          align="center"
                          colSpan={TABLE_HEAD.length + 1}
                          sx={{ py: 3 }}
                        >
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Card>

          <Dialog
            open={openDelete}
            onClose={(e) => {
              handleCloseDelete();
            }}
            fullWidth
          >
            <Box sx={{ textAlign: 'center' }}>
              <DialogContent>Delete Prospect List</DialogContent>
            </Box>

            <Box m={2}>
              <Typography>Are you sure you want to delete?</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 1,
                  m: 1,
                  py: 1
                }}
              >
                <DialogActions>
                  <Box>
                    <Button onClick={handleCloseDelete} variant="outlined" color="primary">
                      Close
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={(e) => {
                        handleDelete(e);
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            </Box>
          </Dialog>
          <Loader openLoader={openLoader} />
        </Box>
      </Container>
    </Page>
  );
}
