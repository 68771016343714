import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputBase,
  Stack,
  TextField,
  Typography,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  DialogContentText,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { HiDocumentText } from 'react-icons/hi';
import { ImCross } from 'react-icons/im';
import { BsFileEarmarkCheckFill } from 'react-icons/bs';
import { LibraryAddCheckIcon } from '@mui/icons-material/LibraryAddCheck';
// import { Player } from 'video-react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css
// /video-react/dist/video-react.css
import partnerService from './services/partnerService';

const { PartnerSignup, RestartpartnerSignup } = partnerService;
function SignUp() {
  const navigate = useNavigate();
  const [secondary, setSecondary] = useState(false);
  const [partnerSignupResponse, setPartnerSignupResponse] = useState('');
  const [popupBox, setPopupBox] = useState(false);
  const [signupagainaction, setSignupagainaction] = useState('');
  useEffect(() => {
    let cancel = false;
    const PartnerSignupAPi = async () => {
      const PartnerSignupResponse = await PartnerSignup({
        password: '',
        username: ''
      });
      if (PartnerSignupResponse.status === 'OK') {
        if (cancel) return;
        setPartnerSignupResponse(PartnerSignupResponse.jwt);
        localStorage.setItem(
          'guestUser',
          JSON.stringify({
            isLogedIn: true,
            token: PartnerSignupResponse.jwt
          })
        );
      }
    };
    PartnerSignupAPi();
    return () => {
      cancel = true;
    };
  }, []);

  const lasttrackpoint = {
    routepage: '',
    isclicked: false
  };

  useEffect(async () => {
    const getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    const reviewIdd = await JSON.parse(localStorage.getItem('reviewobj'));
    if (getactivepage != null || reviewIdd != null) {
      setPopupBox(true);
      setSignupagainaction({ ...signupagainaction, reviewIdd });
    }

    if (reviewIdd != null) {
      getactivepage.isPartnerSignupContinued = false;
      localStorage.setItem('trackuser', JSON.stringify(getactivepage));
    }

    // navigate('/');
  }, []);

  const myaction = async (type) => {
    const getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    const reviewIdd = await JSON.parse(localStorage.getItem('reviewobj'));
    if (type === 'restart') {
      if (reviewIdd != null) {
        setSignupagainaction({ ...signupagainaction, reviewIdd });
        signupagain();
      } else {
        setPopupBox(false);
      }
    } else {
      if (reviewIdd != null) {
        getactivepage.isPartnerSignupContinued = true;
        localStorage.setItem('trackuser', JSON.stringify(getactivepage));
      }
      trackuserapi(getactivepage.routepage);
    }
  };

  const signupagain = async () => {
    const authtoken = await JSON.parse(localStorage.getItem('guestUser'));
    console.log('authhhhhh', authtoken, signupagainaction);
    if (signupagainaction !== '') {
      const restartsignup = await RestartpartnerSignup(
        signupagainaction.reviewIdd,
        authtoken.token
      );

      if (restartsignup.status === 'success') {
        setPopupBox(false);
        localStorage.removeItem('trackuser');
        localStorage.removeItem('reviewobj');
      } else {
        setPopupBox(false);
      }
    }
  };

  const gettokenApi = async (type) => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      getactivepage.routepage = type;
      localStorage.setItem('trackuser', JSON.stringify(getactivepage));
    } else {
      lasttrackpoint.routepage = type;
      lasttrackpoint.isclicked = true;
      localStorage.setItem('trackuser', JSON.stringify(lasttrackpoint));
    }
  };

  const trackuserapi = async (active) => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      if (getactivepage.routepage === `${active}`) {
        navigate(`/${active}`, { replace: true });
      }
    }
  };
  console.log('partnerSignupResponse', partnerSignupResponse);
  return (
    <Box className="signUpOuter" sx={{ width: '100%', height: '100vh' }}>
      <Container maxWidth="fullWidth" disableGutters="true">
        <Stack>
          <Box className="signUpInner">
            <Box className="signUpLeft">
              <Box className="signUpleftInner">
                <Box>
                  <Box mb={3}>
                    <img
                      className="signUpLogo"
                      alt="Logo"
                      src="../../static/logos/RVPulselogoW.svg"
                    />
                  </Box>
                  <Typography variant="subtitle2" className="signUpHeading">
                    Please keep the soft copies of the below mentioned documents ready to complete
                    your onboarding in just a few minutes
                  </Typography>
                  <List sx={{ width: '290px' }}>
                    <ListItem disablePadding="true" className="docListItem">
                      <ListItemIcon>
                        <BsFileEarmarkCheckFill fontSize={24} classname="docIcon" />
                      </ListItemIcon>
                      <Typography className="docListText">Aadhar Card (Back and Front)</Typography>
                    </ListItem>
                    <ListItem disablePadding="true" className="docListItem">
                      <ListItemIcon>
                        <BsFileEarmarkCheckFill fontSize={24} classname="docIcon" />
                      </ListItemIcon>
                      <Typography className="docListText">PAN Card</Typography>
                    </ListItem>
                    <ListItem disablePadding="true" className="docListItem">
                      <ListItemIcon>
                        <BsFileEarmarkCheckFill fontSize={24} classname="docIcon" />
                      </ListItemIcon>
                      <Typography className="docListText">Cancelled Cheque</Typography>
                    </ListItem>
                    {/* <ListItem disablePadding="true" className="docListItem">
                      <ListItemIcon>
                        <HiDocumentText fontSize={24} classname="docIcon" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Bank Statement (1 Year)"
                        secondary={secondary ? 'Secondary text' : null}
                      />
                    </ListItem> 
                    <ListItem disablePadding="true" className="docListItem">
                      <ListItemIcon>
                        <HiDocumentText fontSize={24} classname="docIcon" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Valid Address Proof (Voter Id, Passport, DL, Business Id, Photo Card)"
                        secondary={secondary ? 'Secondary text' : null}
                      />
                    </ListItem> */}
                  </List>
                  <Box paddingTop="1.5rem">
                    <Link
                      to="/signup-form"
                      className="readyBtn"
                      onClick={() => gettokenApi('signup-form')}
                    >
                      Yes I Am Ready
                    </Link>
                  </Box>

                  {popupBox ? (
                    <Dialog open={popupBox} fullWidth className="morezind">
                      <DialogTitle className="popupTitle">
                        <Typography variant="h6">Confirm</Typography>
                      </DialogTitle>
                      <DialogContent dividers>
                        <Typography variant="p" className="popBoxelm color-blue mb-3">
                          Do you want to continue your SignUp process?
                        </Typography>
                        <Typography variant="body1" textAlign="center">
                          Press CONTINUE to resume your signup journey with the previously filled
                          information or press RESTART to SignUp with fresh details.
                        </Typography>

                        <DialogActions className="btncustome">
                          <Button
                            onClick={() => myaction('restart')}
                            variant="contained"
                            className="mainBtn restart"
                          >
                            Restart
                          </Button>
                          <Button
                            onClick={() => myaction('resume')}
                            variant="contained"
                            className="mainBtn"
                          >
                            Continue
                          </Button>
                        </DialogActions>
                      </DialogContent>
                    </Dialog>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="signUpRight">
              <Box className="signUpRightInner">
                <Box className="positionRel">
                  <Player
                    playsInline
                    poster="../../static/login/videoPoster.jpg"
                    src="../../static/login/signupVideo.mp4"
                  />
                  <Link className="closeVideoOut" to="/signup-form">
                    <ImCross className="closeVideoIcon" />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

export default SignUp;
