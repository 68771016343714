import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
//
/**
 * @module AdminServiceService
 * @returns  it return All DataSource Apis
 */
// // http://localhost:8080/lms-dev-api/sms-api/request-otp
// requestbody = {
//     "mobileNumber":"7976805035",
//     "mailto:emailaddress":"hemant.veerwal@kalolytic.com"
// }

// response body = {
//     "msg": "OTP sent successfully",
//     "data": {
//         "msg": "OTP request placed successfully",
//         "iat": 1655352730472,
//         "status": "success",
//         "token": 1532727162
//     },
//     "status": "success"
// }

const partnerService = {
  RequestOtp: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/sms-api/request-otp`, credential, token),
  VerifyOtp: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/sms-api/verify-otp`, credential, token),
  LoginForm: (credential) =>
    useApiServices().postWithoutTokenApi(`${globalAdminUrl}/login`, credential),
  PartnerSignup: (credential) =>
    useApiServices().postWithoutTokenApi(`${globalAdminUrl}/onboard/partner-signup`, credential),
  RestartpartnerSignup: (credential, token) =>
    useApiServices().restartPartnerSignupWithTokenApi(
      `${globalAdminUrl}/onboard/restart-partner-signup`,
      credential,
      token
    ),
  // common apis
  OnBoardList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/onboard/list`, credential, token),
  UpdatePartnerStatus: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/onboard/update-partner-status`, credential, token),
  OnBoardReview: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/onboard/review`, credential, token),

  // On Boarding API
  BankList: (token) => useApiServices().getApi(`${globalAdminUrl}/bank/list`, token),
  LoanTypes: (token) => useApiServices().getApi(`${globalAdminUrl}/master/loan-types`, token),
  // Set commission API
  PartnerCommission: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/onboard/set-commission`, credential, token),
  // Show Set commission API
  ShowCommission: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/onboard/list-commission`, credential, token),
  // Completed

  // document-types API
  DocumentTypes: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/master/document-types`, token),
  PartnerTypes: (token) => useApiServices().getApi(`${globalAdminUrl}/master/partner-types`, token),
  // state list url
  StateList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/sa/cscs/state/list`, credential, token),
  // city list url
  CityList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/sa/cscs/city/list`, credential, token),

  // upload document API
  DocumentUpload: () => `${globalAdminUrl}/onboard/document-upload`,

  // Company Type
  CompanyType: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/master/partner-company-type`, token),

  // create partner api
  CreatePartner: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/onboard/create-partner`, credential, token),
  // partner status types
  PartnerStatusTypes: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/master/partner-status-type`, token),

  // select source
  SourceList: (token) => useApiServices().getApi(`${globalAdminUrl}/prospect-source/list`, token),

  // login submit global api

  LeadsSubmissionMode: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/master/lead-submission-modes`, token),
  BankBranchList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/bank-branch/list`, credential, token),
  AddBankBranch: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/branch/add-branch`, credential, token),
  ContactPersonList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/bank/contact-persons`, credential, token),
  AddContactPerson: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/bank/add-contact-person`, credential, token)
};

export default partnerService;
