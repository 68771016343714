import { useState } from 'react';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
  styled
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// components
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import { FaRegUser } from 'react-icons/fa';
import { TbBrandMastercard } from 'react-icons/tb';
import { AiFillYoutube, AiFillInstagram, AiOutlineFileProtect } from 'react-icons/ai';
import {
  MdOutlineLocalLaundryService,
  MdOutlineHomeWork,
  MdOutlineBrandingWatermark,
  MdOutlineSecurity
} from 'react-icons/md';
import BrudCrumbs from '../../components/BreadCrumbs';
import PageTitle from '../../components/PageHeading';
import Page from '../../components/Page';

function JourneyPublish() {
  const navigate = useNavigate();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
      padding: '6px',
      height: '42.5px',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#0d4689'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px',
      height: '42.5px'
    }
  }));
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));
  return (
    <Page title="Partner Management System || Journey">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Publish" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Journey Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <Box className="displayFlex" mb={3}>
                  <FaRegUser className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Journey Name
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      SRP Self Registration
                    </Typography>
                  </Box>
                </Box>
                <Box className="displayFlex" mb={3}>
                  <FaRegUser className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Customer Name
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      NT Telecom
                    </Typography>
                  </Box>
                </Box>
                <Box className="displayFlex">
                  <FaRegUser className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Version
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      Web &#38; Mobile
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box className="circleLine">
                  <Typography variant="h5">5 Mn</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box className="displayFlex" mb={3}>
                  <FaRegUser className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Created On
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      23rd March 2022
                    </Typography>
                  </Box>
                </Box>
                <Box className="displayFlex" mb={3}>
                  <FaRegUser className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Created By
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      Sandeep Ranjan
                    </Typography>
                  </Box>
                </Box>
                <Box className="displayFlex">
                  <FaRegUser className="viewLabelIcon" size={24} />
                  <Box>
                    <Typography variant="body2" className="labelText">
                      Modified On
                    </Typography>
                    <Typography variant="subtitle2" className="labelValue">
                      9th April 2022
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Generate Web Link
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} mb={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Generate Journey Key
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Journey Key"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Prefix
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type prefix"
                />
              </Grid>
            </Grid>
            <Box mb={2}>
              <FormControlLabel control={<Checkbox size="small" />} label="Customize Link" />
            </Box>
            <Box mb={2}>
              <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} mb={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="formLabel">
                    Domain Link
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Domain Link"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={2}>
              <FormControlLabel control={<Checkbox size="small" />} label="Individual Link" />
            </Box>
            <Box mb={2}>
              <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} mb={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="formLabel">
                    Domain Link
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Domain Link"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Remove Redundant Fields"
              />
            </Box>
          </Box>
          <Box sx={{ textAlign: 'right' }} mb={3}>
            <Button className="mainBtn">Generate Builds</Button>
          </Box>
          <Box className="greenBorder tableBox">
            <TableContainer sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Version</StyledTableCell>
                    <StyledTableCell align="">Journey Key</StyledTableCell>
                    <StyledTableCell align="">Journey Link</StyledTableCell>
                    <StyledTableCell align="">Customer Link</StyledTableCell>
                    <StyledTableCell align="">Published On</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="">1</StyledTableCell>
                    <StyledTableCell align="">152</StyledTableCell>
                    <StyledTableCell align="">--</StyledTableCell>
                    <StyledTableCell align="">--</StyledTableCell>
                    <StyledTableCell align="">27 July, 2022</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default JourneyPublish;
