import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
//
/**
 * @module AdminServiceService
 * @returns  it return All DataSource Apis
 */

const loginListService = {
  // Leads Submit API (fill details) man 03/06/2022
  LoginList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/log-in/list`, credential, token),

  // select status in login page
  ListStageStatusLogin: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/log-in/list-stage-statuses`, credential, token),

  // update login stage
  UpdateStageStatusesLogin: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/log-in/update-stage-statuses`, credential, token),

  // login decision info
  LoginDecisionInfo: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/log-in/decision-info`, credential, token),

  // Processing fee status
  ListProcessingFeeStatuses: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/log-in/list-processing-fee-statuses`, token),

  // Add decision info
  AddDecisionInfo: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/log-in/add-decision-info`, credential, token),

  // Rejection list
  ListRejectionStatuses: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/log-in/list-rejection-statuses`, token),

  // Login view report API (fill details) man 08/06/2022
  LoginViewReport: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/log-in/view-reports`, credential, token),

  AddRejectedReason: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/log-in/add-rejected-reason`, credential, token),

  // saving application summary details
  SaveApplicationDetails: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/log-in/saveApplicationDetails`, credential, token),

  // submit login api
  // Listing of application summary details
  LoginListApplicationSummary: (credential, token) =>
    useApiServices().postApi(
      `${globalAdminUrl}/log-in/list-application-summary`,
      credential,
      token
    ),
  // lead submission mode
  LeadsSubmissionMode: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/master/lead-submission-modes`, token),

  // bank branch
  BankBranchList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/bank-branch/list`, credential, token),

  // applicaiton login type dropdown
  PartnerApplicationLoginType: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/master/partner-application-login-type`, token),

  // login code
  LoginCodesList: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/log-in/dsa-login-codes-list`, credential, token)

  // LoginList: (token) =>
  // useApiServices().getApi(`${globalAdminUrl}/log-in/list`, token),
  //   BankBranchList: (credential, token) =>
  //     useApiServices().postApi(`${globalAdminUrl}/bank-branch/list`, credential, token),
  //   AddBankBranch: (credential, token) =>
  //     useApiServices().postApi(`${globalAdminUrl}/branch/add-branch`, credential, token),
  //   ContactPersonList: (credential, token) =>
  //     useApiServices().postApi(`${globalAdminUrl}/bank/contact-persons`, credential, token),
  //   AddContactPerson: (credential, token) =>
  //     useApiServices().postApi(`${globalAdminUrl}/bank/add-contact-person`, credential, token),
  //   SubmitApplication: (credential, token) =>
  //     useApiServices().postApi(
  //       `${globalAdminUrl}/leads/submit-application-record`,
  //       credential,
  //       token
  //     ),
  //   ListSubmittedApplication: (credential, token) =>
  //     useApiServices().postApi(
  //       `${globalAdminUrl}/leads/list-submitted-application`,
  //       credential,
  //       token
  //     )
};

export default loginListService;
