import { useState, useEffect } from 'react';

const statusMessages = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status (WebDAV)',
  208: 'Already Reported (WebDAV)',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: '(Unused)',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect (experimental)',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I am a teapot (RFC 2324)',
  420: 'Enhance Your Calm (Twitter)',
  422: 'Unprocessable Entity (WebDAV)',
  423: 'Locked (WebDAV)',
  424: 'Failed Dependency (WebDAV)',
  425: 'Reserved for WebDAV',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  444: 'No Response (Nginx)',
  449: 'Retry With (Microsoft)',
  450: 'Blocked by Windows Parental Controls (Microsoft)',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request (Nginx)',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates (Experimental)',
  507: 'Insufficient Storage (WebDAV)',
  508: 'Loop Detected (WebDAV)',
  509: 'Bandwidth Limit Exceeded (Apache)',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  598: 'Network read timeout error',
  599: 'Network connect timeout error'
};
async function fireLoginApi(endpoint, credentials) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function firePostTokenApi(endpoint, token) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then((response) => {
      console.log('response', response);
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function firePostTokenApiLogo(endpoint, token) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then((response) => {
      console.log('response', response);
      if (response.ok) {
        return response;
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function fireApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        // status: 'error',
        // msg: statusMessages[response.status],
        // data: []
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        localStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function fireviewagreementApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        // status: 'error',
        // msg: statusMessages[response.status],
        // data: []
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        localStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function firepolicyApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        // status: 'error',
        // msg: statusMessages[response.status],
        // data: []
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function firepolicylistApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        // status: 'error',
        // msg: statusMessages[response.status],
        // data: []
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function fireagreementlistApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        // status: 'error',
        // msg: statusMessages[response.status],
        // data: []
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function restartsignupfireApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        // status: 'error',
        // msg: statusMessages[response.status],
        // data: []
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        localStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function postFileApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: credentials
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function putToApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function deleteToApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    }
  })
    .then((response) => {
      console.log(response);

      if (response.ok && response.status === '200') {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function getToApi(endpoint, authToken) {
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    }
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

export default function useApiServices() {
  const postWithoutTokenApi = async (endPoint, credential) => {
    const isDataPosted = await fireLoginApi(endPoint, credential);
    return isDataPosted;
  };
  const postTokenApi = async (endPoint, token) => {
    const isDataPosted = await firePostTokenApi(endPoint, token);
    return isDataPosted;
  };
  const postTokenApiLogo = async (endPoint, token) => {
    const isDataPosted = await firePostTokenApiLogo(endPoint, token);
    return isDataPosted;
  };
  const postApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await fireApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const viewagreementpostApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await fireviewagreementApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const postpolicyApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await firepolicyApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const policylistApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await firepolicylistApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const agreementlistApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await fireagreementlistApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const restartPartnerSignupWithTokenApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await restartsignupfireApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const postFileData = async (endPoint, credential, authToken) => {
    const isDataPosted = await postFileApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const putApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await putToApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const getApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await getToApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const deleteApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await deleteToApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  return {
    postApi,
    postpolicyApi,
    policylistApi,
    agreementlistApi,
    viewagreementpostApi,
    putApi,
    getApi,
    deleteApi,
    postFileData,
    postWithoutTokenApi,
    postTokenApi,
    postTokenApiLogo,
    restartPartnerSignupWithTokenApi
  };
}
