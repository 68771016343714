import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  IconButton,
  FormControlLabel,
  FormGroup
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';
// styling
import { StyledTableRow, StyledTableCell } from '../Global/Styling';
import RevenueReceiptToolbar from './RevenueReceiptToolbar';
import RevenueApprovedToolbar from './RevenueApprovedToolbar';
import RevenueDisbursedToolbar from './RevenueDisbursedToolbar';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'bankName', label: 'Bank Name', alignRight: false },
  { id: 'id', label: 'File No', alignRight: false },
  { id: 'bankName', label: 'Branch Name', alignRight: false },
  { id: 'created', label: 'Request Date', alignRight: false },
  { id: 'name', label: 'Borrower Name', alignRight: false },
  { id: 'role', label: 'Emp Class', alignRight: false },
  { id: 'loanType', label: 'Loan Product Type', alignRight: false },
  { id: 'amount', label: 'Loan Amount', alignRight: false },
  { id: 'monthName', label: 'MIS Month', alignRight: false },
  { id: 'misStatus', label: 'MIS Status', alignRight: false },
  { id: '', label: 'ACTION' }
];
const TABLE_HEAD_APPROVED = [
  { id: 'bankName', label: 'Bank Name', alignRight: false },
  { id: 'id', label: 'File No', alignRight: false },
  { id: 'bankName', label: 'Branch Name', alignRight: false },
  { id: 'created', label: 'Approval Date', alignRight: false },
  { id: 'name', label: 'Borrower Name', alignRight: false },
  { id: 'role', label: 'Emp Class', alignRight: false },
  { id: 'loanType', label: 'Loan Product Type', alignRight: false },
  { id: 'amount', label: 'Loan Amount', alignRight: false },
  { id: 'monthName', label: 'MIS Month', alignRight: false },
  { id: 'misStatus', label: 'MIS Status', alignRight: false },
  { id: '', label: 'ACTION' }
];
const TABLE_HEAD_DISBURSMENT = [
  { id: 'bankName', label: 'Bank Name', alignRight: false },
  { id: 'id', label: 'File No', alignRight: false },
  { id: 'bankName', label: 'Branch Name', alignRight: false },
  { id: 'created', label: 'Disb Date', alignRight: false },
  { id: 'name', label: 'Borrower Name', alignRight: false },
  { id: 'role', label: 'Emp Class', alignRight: false },
  { id: 'loanType', label: 'Loan Product Type', alignRight: false },
  { id: 'amount', label: 'Disb Amount', alignRight: false },
  { id: 'monthName', label: 'MIS Month', alignRight: false },
  { id: 'misStatus', label: 'MIS Status', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function RevenueList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'bold',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px'
    }
  }));
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [sendInvoice, setSendInvoice] = useState(false);
  const [downloadPopup, setDownloadPopup] = useState(false);

  return (
    <Page title="Partner Management System || Revenue">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Bank MIS Detail" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Tabs
            value={value}
            onChange={handleChange}
            className="tabOuter"
            aria-label="basic tabs example"
            classes={{ indicator: 'tabIndicator' }}
          >
            <Tab
              label="Receipt"
              {...a11yProps(0)}
              classes={{ selected: 'tabSelected', root: 'tabDefault' }}
            />
            <Tab
              label="Approved"
              {...a11yProps(1)}
              classes={{ selected: 'tabSelected', root: 'tabDefault' }}
            />
            <Tab
              label="Disbursed"
              {...a11yProps(2)}
              classes={{ selected: 'tabSelected', root: 'tabDefault' }}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <RevenueReceiptToolbar parent="revenue" />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  {/* <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          routingNumber,
                          invoiceNo,
                          id,
                          name,
                          leadId,
                          amount,
                          company,
                          role,
                          noofcalls,
                          name2,
                          city,
                          bankName,
                          partnerName,
                          transactionType,
                          status,
                          segment,
                          active,
                          created,
                          partnerType,
                          loanType,
                          misStatus,
                          verifiedStatus,
                          monthName,
                          users
                        } = row;
                        console.log('users', users);
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{bankName}</StyledTableCell>
                            <StyledTableCell align="left">{id}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{role}</StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{monthName}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (misStatus === 'Not Matched' && 'warning') ||
                                  (misStatus === 'Matched' && 'success') ||
                                  'success'
                                }
                              >
                                {sentenceCase(misStatus)}
                              </Label>
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <UserMoreMenu rowId={id} parent="revenue-receipt" />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={13} />
                      </TableRow>
                    )}
                  </TableBody> */}
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RevenueApprovedToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="revenue"
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD_APPROVED}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  {/* <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          routingNumber,
                          invoiceNo,
                          id,
                          name,
                          leadId,
                          amount,
                          company,
                          role,
                          noofcalls,
                          name2,
                          city,
                          bankName,
                          partnerName,
                          transactionType,
                          status,
                          segment,
                          active,
                          created,
                          partnerType,
                          monthName,
                          loanType,
                          misStatus,
                          users
                        } = row;
                        console.log('users', users);
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{bankName}</StyledTableCell>
                            <StyledTableCell align="left">{id}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{role}</StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{monthName}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (misStatus === 'Not Matched' && 'warning') ||
                                  (misStatus === 'Matched' && 'success') ||
                                  'success'
                                }
                              >
                                {sentenceCase(misStatus)}
                              </Label>
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <UserMoreMenu rowId={id} parent="revenue-approved" />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody> */}
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <RevenueDisbursedToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="revenue"
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD_DISBURSMENT}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  {/* <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          routingNumber,
                          invoiceNo,
                          id,
                          name,
                          leadId,
                          amount,
                          company,
                          role,
                          noofcalls,
                          name2,
                          city,
                          bankName,
                          partnerName,
                          transactionType,
                          status,
                          segment,
                          active,
                          created,
                          partnerType,
                          loanType,
                          statusInvoice,
                          misStatus,
                          monthName,
                          users
                        } = row;
                        console.log('users', users);
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{bankName}</StyledTableCell>
                            <StyledTableCell align="left">{id}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{role}</StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{monthName}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (misStatus === 'Not Matched' && 'warning') ||
                                  (misStatus === 'Matched' && 'success') ||
                                  'success'
                                }
                              >
                                {sentenceCase(misStatus)}
                              </Label>
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <UserMoreMenu rowId={id} parent="revenue-disbursed" />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody> */}
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '1rem' }}>
          <Card>{children}</Card>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
