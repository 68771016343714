import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import { CgAssign } from 'react-icons/cg';
import { BiSync, BiCloudUpload } from 'react-icons/bi';
import { MdAssignmentInd } from 'react-icons/md';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  TextField,
  Button,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  FormHelperText
} from '@mui/material';
import { DateRange } from 'react-date-range';
import { navigate } from '@storybook/addon-links';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { RootStyle, SearchStyle, DateRangeStyled } from '../Global/Styling';
// ---------------------------------------------------------
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------

export default function ProspectToolbar({
  numSelected,
  filterName,
  onFilterName,
  parent,
  pageMenu,
  selectStatus,
  onFilterStatus,
  statusList,
  selectSource,
  onFilterSource,
  sourceListData,
  setCityListData,
  cityListMenu,
  filterDatesRange,
  setFilterDatesRange,
  setOpenAssign,
  onAssignSubmit,
  openAssign,
  selected,
  alertMsg,
  setAlertMsg,
  salesPersonList,
  setAssignValue,
  assignValue
}) {
  const navigate = useNavigate();
  const [isOpenFilter, openFilter] = useState(false);
  const [openSyncLeads, setOpenSyncLeads] = useState(false);
  const [sourceValue, setSourceValue] = useState('facebook');
  return (
    <>
      {isOpenFilter && (
        <Box px={3} pt={2}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectSource}`
                }}
                value={selectSource}
                onChange={onFilterSource}
              >
                <MenuItem value=" ">Select Source</MenuItem>
                {sourceListData.length > 0
                  ? sourceListData.map((item, index) => {
                      console.log(item);
                      return (
                        <MenuItem value={item.typeId} key={index}>
                          {item.typeName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Grid>
            <Grid item xs={3} sx={{ position: 'relative' }}>
              <Box className="displayDateRange">
                {filterDatesRange[0].startDate !== null
                  ? `${fDate(filterDatesRange[0].startDate)} to `
                  : ''}
                {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
                {filterDatesRange[0].startDate === null && filterDatesRange[0].endDate === null ? (
                  <Typography className="dissabledMenu">Select Date Range</Typography>
                ) : (
                  ''
                )}
              </Box>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                MenuProps={{
                  className: 'menuOpened'
                }}
                value=" "
                className="datesSelect"
              >
                <DateRangeStyled
                  editableDateInputs={false}
                  onChange={(item) => setFilterDatesRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={filterDatesRange}
                  className="dateRangePicker"
                />
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={cityListMenu}
                size="small"
                onChange={(e, newValue) => {
                  setCityListData(newValue);
                  console.log('newValue20', newValue);
                }}
                getOptionLabel={(option) => option.cityName}
                renderOption={(props, option) => <li {...props}>{option.cityName}</li>}
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select City" />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectStatus}`
                }}
                value={selectStatus}
                onChange={onFilterStatus}
              >
                <MenuItem value=" ">Select Status</MenuItem>
                {statusList.length > 0
                  ? statusList.map((item, index) => {
                      console.log(item);
                      return (
                        <MenuItem value={item.stageStatusId} key={index}>
                          {item.stageStatusName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Grid>
          </Grid>
        </Box>
      )}

      <RootStyle
        sx={{
          minHeight: 45,
          borderBottom: '1px solid #eaedf1'
        }}
      >
        <Box>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => {
              console.log(pageElement);
              return (
                <React.Fragment key={pageElement.subPageId + index}>
                  {pageElement.subPageCode === 'NEW_PROSPECT' && pageElement.isEnable === true ? (
                    <>
                      <Tooltip title="New Prospect" placement="top" arrow>
                        <IconButton
                          className="squareIconButton"
                          onClick={(e) => navigate(`/${parent}/add`)}
                        >
                          <Icon icon={add} width={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : pageElement.subPageCode === 'UPLOAD' && pageElement.isEnable === true ? (
                    <>
                      <Tooltip
                        title="Upload"
                        placement="top"
                        arrow
                        onClick={(e) => navigate(`/${parent}/upload`)}
                      >
                        <IconButton className="squareIconButton">
                          <BiCloudUpload size={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : pageElement.subPageCode === 'SYNC_LEADS' && pageElement.isEnable === true ? (
                    <>
                      <Tooltip
                        title="Sync Leads"
                        placement="top"
                        arrow
                        onClick={(e) => setOpenSyncLeads(true)}
                      >
                        <IconButton className="squareIconButton">
                          <BiSync size={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : pageElement.subPageCode === 'ASSIGN' && pageElement.isEnable === true ? (
                    <>
                      <Tooltip
                        title="Assign"
                        placement="top"
                        arrow
                        onClick={(e) => setOpenAssign(true)}
                      >
                        <IconButton
                          className="squareIconButton"
                          classes={{ disabled: 'disabledBtn' }}
                          disabled={selected && selected.length < 1}
                        >
                          <MdAssignmentInd size={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              );
            })}
        </Box>
        <Box>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => (
              <>
                {pageElement.subPageCode === 'SEARCH' && pageElement.isEnable === true ? (
                  <>
                    <SearchStyle
                      value={filterName}
                      onChange={onFilterName}
                      placeholder="Search Prospect"
                      classes={{
                        root: 'searchHolder',
                        input: 'searchInput'
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                        </InputAdornment>
                      }
                    />
                    &nbsp;&nbsp;&nbsp;
                  </>
                ) : pageElement.subPageCode === 'FILTER' && pageElement.isEnable === true ? (
                  <Tooltip title="Filter list">
                    <IconButton
                      onClick={(e) => {
                        if (isOpenFilter) {
                          openFilter(false);
                        } else {
                          openFilter(true);
                        }
                      }}
                    >
                      <Icon icon={roundFilterList} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ''
                )}
                &nbsp;
              </>
            ))}
        </Box>
      </RootStyle>
      <Dialog open={openSyncLeads} onClose={(e) => setOpenSyncLeads(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">Select the Source you want to Sync</Typography>
          <IconButton onClick={(e) => setOpenSyncLeads(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={sourceValue}
            onChange={(e) => setSourceValue(e.target.value)}
          >
            <FormControlLabel value="facebook" control={<Radio />} label="Facebook" />
            <FormControlLabel
              value="callCentre"
              control={<Radio />}
              label="Call Centre Application"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions disableSpacing={false}>
          <Button onClick={(e) => setOpenSyncLeads(false)} variant="contained" className="mainBtn">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAssign} onClose={(e) => setOpenAssign(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">{selected && selected.length} Prospect Selected</Typography>
          <IconButton onClick={(e) => setOpenAssign(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Select
            fullWidth
            inputProps={{
              className: `textInput customSelectBox dissabledMenu${assignValue}`
            }}
            value={assignValue}
            onChange={(e) => {
              setAlertMsg(false);
              setAssignValue(e.target.value);
            }}
          >
            <MenuItem value=" " disabled>
              Select Agent Name
            </MenuItem>
            {salesPersonList.length > 0
              ? salesPersonList.map((item, index) => {
                  console.log(item);
                  return <MenuItem value={item.userId}>{item.fullName}</MenuItem>;
                })
              : ''}
          </Select>
          {alertMsg === true && <FormHelperText error>Please select Agent Name</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onAssignSubmit} variant="contained" className="mainBtn">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
