import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputBase,
  Stack,
  TextField,
  Typography,
  List,
  ListItem,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  DialogContentText,
  Autocomplete
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Document, pdfjs, Page as Pages } from 'react-pdf';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { HiDocumentText, HiOutlineIdentification } from 'react-icons/hi';
import { ImCross } from 'react-icons/im';
import { TiUserOutline } from 'react-icons/ti';
import { MdOutlineBusiness } from 'react-icons/md';
import { RiGalleryUploadLine } from 'react-icons/ri';
import { BsShieldFillCheck, BsPatchCheck } from 'react-icons/bs';
import { LibraryAddCheckIcon } from '@mui/icons-material/LibraryAddCheck';
import { AiOutlineCloudUpload, AiOutlineClose } from 'react-icons/ai';
import CloseIcon from '@mui/icons-material/Close';
// import { Player } from 'video-react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css
import AgreementText from './AgreementText';

import partnerService from './services/partnerService';
import Loader from './Loader';
import ErrorDialogBox from './ErrorDialogBox';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const {
  StateList,
  CityList,
  DocumentUpload,
  DocumentTypes,
  CreatePartner,
  RequestOtp,
  VerifyOtp,
  CompanyType,
  OnBoardReview
} = partnerService;
// /video-react/dist/video-react.css
function SignUpCompany() {
  const [signUpAuthToken, setSignUpAuthToken] = useState('');
  const navigate = useNavigate();
  const [secondary, setSecondary] = useState(false);
  const [signupSteps, setSignupSteps] = useState('verifyIdentity');
  const [verifySteps, setVerifySteps] = useState('verifyStepOne');

  const [logoPreview, setLogoPreview] = useState(); // Logo
  const [panPreview, setPanPreview] = useState(); // pan card
  const [gstCertificatePreview, setGstCertificatePreview] = useState(); // GST Certificate
  const [incorporationCertificatePreview, setIncorporationCertificatePreview] = useState(); // Incorporation
  const [cancelChequePreview, setCancelChequePreview] = useState(); // cancel cheque

  const [logoPreviewtype1, setLogoPreviewtype1] = useState(''); // Logo local
  const [panPreviewtype1, setPanPreviewtype1] = useState(''); // pan card local
  const [gstCertificatePreviewtype1, setGstCertificatePreviewtype1] = useState(''); // GST Certificate local
  const [incorporationCertificatePreviewtype1, setIncorporationCertificatePreviewtype1] =
    useState(''); // Incorporation local
  const [cancelChequePreviewtype1, setCancelChequePreviewtype1] = useState(''); // cancel cheque local

  const [gstCertificatePreviewtype1live, setGstCertificatePreviewtype1live] = useState(''); // GST Certificate local
  const [incorporationCertificatePreviewtype1live, setIncorporationCertificatePreviewtype1live] =
    useState(''); // Incorporation local
  const [logoPreviewtype1live, setLogoPreviewtype1live] = useState(''); // cancel cheque local

  const [uploadImageFile, setUploadImageFile] = useState(); // uploaded image file
  const [selectState, setSelectState] = useState(' ');
  const [selectCity, setSelectCity] = useState(' ');
  const [isActive, setActive] = useState(false);
  const [selectDate, setSelectDate] = useState(' ');
  const [openAssign, setOpenAssign] = useState(false);
  const [selectCompanyType, setSelectCompanyType] = useState(' ');

  // api state
  const [stateListResponse, setStateListResponse] = useState([]);
  const [cityListResponse, setCityListResponse] = useState([]);
  const [stateID, setStateID] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [cityID, setCityID] = useState('');
  const [cityValue, setCityValue] = useState('');

  const [documentTypesList, setDocumentTypesList] = useState({});
  const [createPartnerData, setCreatePartnerData] = useState({});
  const [helperText, setHelperText] = useState('');
  const [mobileHelper, setMobileHelper] = useState('');
  const [mobileError, setMobileError] = useState(false);
  const [requestOtpData, setReuestOtp] = useState('verifyStepOne');
  const [resendDisable, setResendDisable] = useState(true);
  const [stateListMenu, setStateListMenu] = useState([]); // stateListMenu cityListMenu
  const [cityListMenu, setCityListMenu] = useState([]);

  //
  const [stateListData, setStateListData] = useState([]);
  const [cityListData, setCityListData] = useState([]);
  const [curFileName, setCurFileName] = useState('');
  const [checked, setChecked] = useState('');
  const [currentDate, setCurrentDate] = useState({});
  const [indDobYear, setIndDobYear] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [compAddress, setCompAddress] = useState('');
  const [stateListValueComp, setStateListValueComp] = useState(null);
  const [cityListValueComp, setCityListValueComp] = useState(null);
  const [companyTypeList, setCompanyTypeList] = useState([]);

  const [gstImage, setGstImage] = useState();
  const [incorporationUploadComp, setIncorporationUploadComp] = useState();
  const [uploadPanImageCompany, setUploadPanImageCompany] = useState();
  const [uploadChequeCompany, setUploadChequeCompany] = useState();
  const [logoUpload, setLogoUpload] = useState();
  const [authorisedName, setAuthorisedName] = useState('');
  const [counter, setCounter] = useState(1);
  const [showErrorPopUp, setshowErrorPopUp] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [cancelChequePreviewtype1live, setCancelChequePreviewtype1live] = useState('');
  const [panPreviewtype1live, setPanPreviewtype1live] = useState('');
  const [photoPreviewtype1live, setPhotoPreviewtype1live] = useState('');
  const [photoPreview, setPhotoPreview] = useState('');

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // setting 1 to show fisrt page

  const toggleClass = (box) => {
    setActive(box);
  };

  const handleFileUpload = async (
    e,
    setPreview,
    files,
    setUploadImage,
    setLocaltype,
    setLivetype
  ) => {
    if (e.target.files[0] !== undefined) {
      setLivetype('');
      setLocaltype(e.target.files[0].name.split('.')[1]);
      setUploadImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const fileDrop = (e, setPreview, setUploadImage, setLocaltype, setLivetype) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log('e.dataTransfer.files', e.dataTransfer.files);
    handleFileUpload(e, setPreview, files[0], setUploadImage, setLocaltype, setLivetype);
  };

  // Signup verify identity const by man 27 May 2022
  const handleClose = () => {
    setOpen(false);
  };
  // Valid identity
  const [name, setName] = useState('');
  const [phone, setPhone] = useState();
  const [emailAddr, setEmailAddr] = useState();
  // Otp section
  const [otp1, setOtp1] = useState('');
  // Personal details
  const [fname, setFname] = useState('');
  // Validation and open dialog
  const [validationMsg, setValidationMsg] = useState('');
  const [open, setOpen] = useState(false);
  // Signup const
  const handleSignUp = async (e) => {
    const typeId = JSON.parse(localStorage.getItem('typeID'));
    const trackuserData = await JSON.parse(localStorage.getItem('trackuser'));
    setHelperText('');
    setError(false);
    const pattern = /^[6-9][0-9]{9}$/; // mobile regex pattern
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // email regex pattern
    if (!authorisedName || authorisedName.trim() === '') {
      setOpen(true);
      setValidationMsg('Please enter your authorised name');
      return;
    }

    if (!pattern.test(phone)) {
      setOpen(true);
      setValidationMsg('Please enter valid number');
      return;
    }

    if (!mailformat.test(emailAddr)) {
      setOpen(true);
      setValidationMsg('Please enter your E-Mail address');
      return;
    }

    let localData = '';
    if (trackuserData != null) {
      localData = trackuserData.isPartnerSignupContinued;
    }

    console.log('hiiiiiiiiiiiiiiii', trackuserData.isPartnerSignupContinued);

    const createCredentials = {
      companyId: 36,
      typeId: typeId.typeId, // from localStorage
      // partnerName: String(name),
      // partnerName: String(companyName),
      authorisedPersonName: String(authorisedName),
      mobile: String(phone),
      emailAddress: String(emailAddr),
      isSignUpFlow: true,
      isPartnerSignupContinued: localData !== '' && localData !== undefined ? localData : false
    };
    const CreatePartnerApiResponse = await CreatePartner(createCredentials, signUpAuthToken);
    if (CreatePartnerApiResponse.status === 'success') {
      if (CreatePartnerApiResponse.data.mobileNumberCheckMsg) {
        setLoader(false);
        setMobileHelper('Mobile Number Already Available');
        setMobileError(true);
        return;
      }
      setLoader(false);
      setCreatePartnerData(CreatePartnerApiResponse.data);
      localStorage.setItem('reviewobj', JSON.stringify(CreatePartnerApiResponse.data));
    }

    /*  ---------------- open when otp required ------------------- */
    // const requestotp = await RequestOtp(
    //   {
    //     mobileNumber: phone,
    //     emailAddress: emailAddr
    //   },
    //   signUpAuthToken
    // );

    // console.log('requestotprequestotp', requestotp);
    // setLoader(false);
    // if (requestotp.status === 'success') {
    //   setReuestOtp(requestotp.data);
    //   setVerifySteps('verifyStepTwo');
    // } else {
    //   setshowErrorPopUp(true);
    //   setErrorMsg('Failed to send OTP - Please try again');
    // }
    /*  ---------------- open when otp required ------------------- */

    setSignupSteps('personalDetails');
  };
  // OTP
  const handleSignUpTwo = async () => {
    if (!otp1 || otp1.trim() === '') {
      setOpen(true);
      setValidationMsg('Please enter OTP');
      return;
    }
    const verifytotp = await VerifyOtp(
      {
        mobileNumber: phone,
        otp: otp1,
        emailAddress: emailAddr,
        token: requestOtpData.token,
        iat: requestOtpData.iat
      },
      signUpAuthToken
    );
    console.log('requestotprequestotp', verifytotp);
    setLoader(false);
    if (verifytotp.status === 'success') {
      setSignupSteps('personalDetails');
    } else {
      setResendDisable(false);
      setError(true);
      setHelperText('Unable to verify OTP - invalid OTP! Or OTP Expired');
    }
    // setSignupSteps('personalDetails');
  };
  // Personal Details
  // const handleSignUpThree = () => {
  //   if (!fname || fname.trim() === '') {
  //     setOpen(true);
  //     setValidationMsg('Please enter your father name');
  //     return;
  //   }
  //   if (!selectDate || selectDate.trim() === '') {
  //     setOpen(true);
  //     setValidationMsg('Please enter your DOB');
  //     return;
  //   }
  //   if (!address || address.trim() === '') {
  //     setOpen(true);
  //     setValidationMsg('Please enter your address');
  //     return;
  //   }
  //   if (!selectCity || selectCity.trim() === '') {
  //     setOpen(true);
  //     setValidationMsg('Please enter your city');
  //     return;
  //   }
  //   if (!selectState || selectState.trim() === '') {
  //     setOpen(true);
  //     setValidationMsg('Please enter your state');
  //     return;
  //   }
  //   setSignupSteps('personalDetails');
  // };

  // Personal Details
  const handleSignUpThree = async () => {
    const typeId = JSON.parse(localStorage.getItem('typeID'));

    // if (!fname || fname.trim() === '') {
    //   setOpen(true);
    //   setValidationMsg('Please enter your father name');
    //   return;
    // }
    // if (!selectDate || selectDate.trim() === '') {
    //   setOpen(true);
    //   setValidationMsg('Date of birth cannot be empty');
    //   return;
    // }
    // const diff = currentDate - indDobYear;
    // if (selectDate === '') {
    //   setOpen(true);
    //   setValidationMsg('Please enter your Date of Birth');
    //   return;
    // } else if (diff < 18) {
    //   console.log('over18', currentDate - indDobYear);
    //   setOpen(true);
    //   setValidationMsg('Age should be greater then 18 years');
    //   return;
    // }

    // if (!address || address.trim() === '') {
    //   setOpen(true);
    //   setValidationMsg('Please enter your address');
    //   return;
    // }
    // if (!stateID) {
    //   setOpen(true);
    //   setValidationMsg('Please enter your city');
    //   return;
    // }
    // if (!cityID) {
    //   setOpen(true);
    //   setValidationMsg('Please enter your state');
    // }

    const createCredentials = {
      companyId: 36,
      typeId: typeId.typeId, // from localStorage
      // partnerName: String(name),
      partnerName: String(companyName),
      // fathersName: String(fname),
      dob: String(selectDate),
      mobile: String(phone),
      address: String(compAddress),
      emailAddress: String(emailAddr),
      authorisedPersonName: String(authorisedName),
      // districtId: Number(cityID),
      // provinceId: Number(stateID),
      districtId: cityListMenu && cityListValueComp ? cityListValueComp.cityId : null,
      provinceId: stateListMenu && stateListValueComp ? stateListValueComp.stateId : null,
      countryId: 101,
      isSignUpFlow: true,
      partnerId: createPartnerData.partnerId
    };
    const CreatePartnerApiResponse = await CreatePartner(createCredentials, signUpAuthToken);
    if (CreatePartnerApiResponse.status === 'success') {
      // isPartnerAlreadyExist
      setCreatePartnerData(CreatePartnerApiResponse.data);
      localStorage.setItem('reviewobj', JSON.stringify(CreatePartnerApiResponse.data));
    }
    setSignupSteps('uploadDocuments');
  };

  // Signup verify identity const end

  // document validation
  const documentValidation = (e) => {
    // if (!uploadImageFile) {
    //   setOpen(true);
    //   setValidationMsg('Please Upload Photo');
    //   return;
    // }
    // if (!uploadImageFileFront) {
    //   setOpen(true);
    //   setValidationMsg('Please upload aadhar card front photo');
    //   return;
    // } else if (!uploadImageFileBack) {
    //   setOpen(true);
    //   setValidationMsg('Please upload aadhar card Back photo');
    //   return;
    // }
    // if (!uploadPanImage) {
    //   setOpen(true);
    //   setValidationMsg('Please Upload Pan Card Photo');
    //   return;
    // }
    // if (!uploadChequeImage) {
    //   setOpen(true);
    //   setValidationMsg('Please Upload Cancel Cheque Photo');
    //   return;
    // }

    // if (uploadImageFileFront !== undefined && uploadImageFileBack === undefined) {
    //   setOpen(true);
    //   setValidationMsg('Please upload aadhaar card backside');
    //   return;
    // }
    // if (uploadImageFileBack !== undefined && uploadImageFileFront === undefined) {
    //   setOpen(true);
    //   setValidationMsg('Please upload aadhaar card frontside');
    //   return;
    // }

    fireAllDocumentApi(e);
    setSignupSteps('results');
  };

  // fire document apis
  const fireAllDocumentApi = (e) => {
    if (logoUpload && logoUpload.size > 0) {
      (async () => {
        // user photo
        const formData = new FormData();
        formData.append('partnerId', createPartnerData.partnerId);
        formData.append('companyId', 36);
        documentTypesList.forEach((item) => {
          console.log(item);
          if (item.typeCode.toLowerCase() === 'COMPANY_LOGO'.toLowerCase()) {
            formData.append('documentTypeId', item.typeId);
          }
        });
        formData.append('companyLogo', logoUpload);
        return fetch(DocumentUpload(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${signUpAuthToken}`
          },
          body: formData
        }).then((response) => {
          response.json().then((data) => {
            if (data.status === 'success') {
              console.log('aadharData', data);
            }
          });
        });
      })();
    }

    // if (gstImage && gstImage.size > 0) {
    //   // adhar api
    //   (async () => {
    //     // aadhar card api
    //     const formData = new FormData();
    //     formData.append('partnerId', createPartnerData.partnerId);
    //     formData.append('companyId', 36);
    //     documentTypesList.forEach((item) => {
    //       console.log(item);
    //       if (item.typeName === 'AADHAR CARD') {
    //         formData.append('documentTypeId', item.typeId);
    //       }
    //     });

    //     formData.append('aadharFront', uploadImageFileFront);
    //     formData.append('aadharBack', uploadImageFileBack);
    //     return fetch(DocumentUpload(), {
    //       method: 'POST',
    //       headers: {
    //         Authorization: `Bearer ${signUpAuthToken}`
    //       },
    //       body: formData
    //     }).then((response) => {
    //       response.json().then((data) => {
    //         if (data.status === 'success') {
    //           console.log('aadharData', data);
    //         }
    //       });
    //     });
    //   })();
    // }

    // gst certificate company
    if (gstImage && gstImage.size > 0) {
      (async () => {
        const formData = new FormData();
        formData.append('partnerId  ', createPartnerData.partnerId);
        formData.append('companyId', 36);
        documentTypesList.forEach((item) => {
          console.log(item);
          if (item.typeCode === 'GSTIN') {
            formData.append('documentTypeId', item.typeId);
          }
        });
        formData.append('gstInDocument', gstImage);
        return fetch(DocumentUpload(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${signUpAuthToken}`
          },
          body: formData
        }).then((response) => {
          response.json().then((data) => {
            if (data.status === 'success') {
              console.log('gst', data);
            }
          });
        });
      })();
    }

    // incorporation Upload Company
    if (incorporationUploadComp && incorporationUploadComp.size > 0) {
      (async () => {
        const formData = new FormData();
        formData.append('partnerId  ', createPartnerData.partnerId);
        formData.append('companyId', 36);
        documentTypesList.forEach((item) => {
          console.log(item);
          if (item.typeCode === 'INCORPORATION_CERTIFICATE') {
            formData.append('documentTypeId', item.typeId);
          }
        });
        formData.append('incorporationCertificate', incorporationUploadComp);
        return fetch(DocumentUpload(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${signUpAuthToken}`
          },
          body: formData
        }).then((response) => {
          response.json().then((data) => {
            if (data.status === 'success') {
              console.log('incorp', data);
            }
          });
        });
      })();
    }

    // pancard upload api company
    if (uploadPanImageCompany && uploadPanImageCompany.size > 0) {
      (async () => {
        // user photo
        const formData = new FormData();
        formData.append('partnerId', createPartnerData.partnerId);
        formData.append('companyId', 36);
        documentTypesList.forEach((item) => {
          console.log(item);
          if (item.typeCode === 'PAN CARD') {
            formData.append('documentTypeId', item.typeId);
          }
        });
        formData.append('panCard', uploadPanImageCompany);
        return fetch(DocumentUpload(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${signUpAuthToken}`
          },
          body: formData
        }).then((response) => {
          response.json().then((data) => {
            if (data.status === 'success') {
              console.log('pan', data);
            }
          });
        });
      })();
    }

    // cheque api company
    if (uploadChequeCompany && uploadChequeCompany.size > 0) {
      (async () => {
        // user photo
        const formData = new FormData();
        formData.append('partnerId', createPartnerData.partnerId);
        formData.append('companyId', 36);
        documentTypesList.forEach((item) => {
          console.log(item);
          if (item.typeCode === 'CHEQUE') {
            formData.append('documentTypeId', item.typeId);
          }
        });
        formData.append('cheque', uploadChequeCompany);
        return fetch(DocumentUpload(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${signUpAuthToken}`
          },
          body: formData
        }).then((response) => {
          response.json().then((data) => {
            if (data.status === 'success') {
              console.log('cheque', data);
            }
          });
        });
      })();
    }
  };

  // city api
  const getCityList = async (e, newValue) => {
    const CityListApiResponse = await CityList(
      {
        searchLiteral: '',
        stateId: newValue.stateId ? newValue.stateId : null
      },
      signUpAuthToken
    );
    console.log('CityListApiResponse', CityListApiResponse);
    if (CityListApiResponse.action_status === 'success') {
      setCityListMenu(CityListApiResponse.data);
    } else {
      setCityListMenu([]);
    }
  };

  // Signup verify identity const end
  useEffect(() => {
    let cancel = false;
    const SignUpToken = JSON.parse(localStorage.getItem('guestUser'));
    console.log('signUpAuthToken', SignUpToken);
    const SignUpAuthToken = SignUpToken.token;
    setSignUpAuthToken(SignUpAuthToken);
    console.log('signUpAuthToken', SignUpAuthToken);
    // state apis
    const credential = {
      searchLiteral: null,
      countryId: 101
    };
    // const StateListApi = async () => {
    //   const StateListResponse = await StateList(credential, SignUpAuthToken);
    //   if (StateListResponse.action_status === 'success') {
    //     // if (cancel) return;
    //     setStateListResponse(StateListResponse.data);
    //   } else {
    //     setStateListResponse([]);
    //   }
    // };
    // StateListApi();

    // document types api
    (async () => {
      const DocumentTypesApiResponse = await DocumentTypes(SignUpAuthToken);
      console.log('DocumentTypesApiResponse', DocumentTypesApiResponse);
      if (DocumentTypesApiResponse.status === 'success') {
        setDocumentTypesList(DocumentTypesApiResponse.data.documentTypes);
      } else {
        setDocumentTypesList({});
      }

      // state list api
      const StateCredentials = {
        countryId: 101,
        searchLiteral: ''
      };

      const StateListApiResponse = await StateList(StateCredentials, SignUpAuthToken);
      console.log('StateListApiResponse', StateListApiResponse);
      if (StateListApiResponse.action_status === 'success') {
        setStateListMenu(StateListApiResponse.data);
      } else {
        setStateListMenu([]);
      }

      // Company Type
      const CompanyTypeApiResponse = await CompanyType(SignUpAuthToken);
      console.log('CompanyTypeApiResponse', CompanyTypeApiResponse);
      if (CompanyTypeApiResponse.status === 'success') {
        setCompanyTypeList(CompanyTypeApiResponse.data.partnerCompanyTypes);
      } else {
        setCompanyTypeList([]);
      }
    })();
    const today = new Date();
    setCurrentDate(today.getFullYear());

    return () => {
      cancel = true;
    };
  }, []);

  useEffect(async () => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      trackuserapi(getactivepage);
    }
    const token = JSON.parse(localStorage.getItem('guestUser'));
    const reviewIdd = JSON.parse(localStorage.getItem('reviewobj'));

    if (reviewIdd !== null) {
      setCreatePartnerData(reviewIdd);
    }
    getReviewbyid(reviewIdd, token.token);
  }, []);

  const [reviewData, setReviewData] = useState([]);
  const getReviewbyid = async (credential, token) => {
    const reviewResponse = await OnBoardReview(credential, token);
    if (reviewResponse.status === 'success') {
      setReviewData(reviewResponse.data.partnerDocuments);
    } else {
      setReviewData([]);
    }
  };

  const trackuserapi = async (active) => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      if (getactivepage.routepage === `${active.routepage}`) {
        console.log('hello ', getactivepage);

        if (getactivepage.stageone !== undefined) {
          setSignupSteps('verifyIdentity');
          if (Object.keys(getactivepage.stageone).length > 0) {
            if (getactivepage.stageone.authorisedName !== undefined) {
              setAuthorisedName(getactivepage.stageone.authorisedName);
            }

            if (getactivepage.stageone.phone !== undefined) {
              setPhone(getactivepage.stageone.phone);
            }

            if (getactivepage.stageone.emailAddr !== undefined) {
              setEmailAddr(getactivepage.stageone.emailAddr);
            }
          }
        }

        if (getactivepage.stagethree !== undefined) {
          setSignupSteps('personalDetails');
          if (Object.keys(getactivepage.stagethree).length > 0) {
            if (getactivepage.stagethree.companyName !== undefined) {
              setCompanyName(getactivepage.stagethree.companyName);
            }
            if (getactivepage.stagethree.selectDate !== undefined) {
              setSelectDate(getactivepage.stagethree.selectDate);
            }
            if (getactivepage.stagethree.selectCompanyType !== undefined) {
              setSelectCompanyType(getactivepage.stagethree.selectCompanyType);
            }
            if (getactivepage.stagethree.compAddress !== undefined) {
              setCompAddress(getactivepage.stagethree.compAddress);
            }
            if (getactivepage.stagethree.state !== undefined) {
              setStateValue(getactivepage.stagethree.state.stateName);
              setStateID(getactivepage.stagethree.state.stateId);
            }
            if (getactivepage.stagethree.city !== undefined) {
              setCityValue(getactivepage.stagethree.city.cityName);
              setCityID(getactivepage.stagethree.city.cityId);
            }
          }
        }

        if (getactivepage.stagefour !== undefined) {
          setSignupSteps('uploadDocuments');
          if (reviewData.length > 0) {
            reviewData.forEach((docItem) => {
              console.log('dockssssssss', docItem);
              if (docItem.documentType.typeCode === 'CHEQUE') {
                setCancelChequePreviewtype1live(docItem.contentType);
                setCancelChequePreview(docItem.documentUrl);
              }

              if (docItem.documentType.typeCode === 'PAN CARD') {
                setPanPreviewtype1live(docItem.contentType);
                setPanPreview(docItem.documentUrl);
              }

              if (docItem.documentType.typeCode === 'INCORPORATION_CERTIFICATE') {
                setIncorporationCertificatePreviewtype1live(docItem.contentType);
                setIncorporationCertificatePreview(docItem.documentUrl);
              }

              if (docItem.documentType.typeCode === 'GSTIN') {
                setGstCertificatePreviewtype1(docItem.contentType);
                setGstCertificatePreview(docItem.documentUrl);
              }

              if (docItem.documentType.typeCode === 'COMPANY_LOGO') {
                setLogoPreviewtype1live(docItem.contentType);
                setLogoPreview(docItem.documentUrl);
              }
            });
          }
        }

        if (getactivepage.stagefifth !== undefined) {
          setSignupSteps('results');
        }

        if (getactivepage.stagelast !== undefined) {
          setOpenAssign(true);
        }
      }
    }
  };

  const activetab = async (type, idd) => {
    let getactivepage = '';
    getactivepage = await JSON.parse(localStorage.getItem('trackuser'));
    if (getactivepage != null) {
      if (getactivepage.routepage === 'signup-form/Company') {
        if (idd === 0) {
          if (getactivepage.stageone !== undefined) {
            setSignupSteps(type);
            if (Object.keys(getactivepage.stageone).length > 0) {
              if (getactivepage.stageone.authorisedName !== undefined) {
                setAuthorisedName(getactivepage.stageone.authorisedName);
              }

              if (getactivepage.stageone.phone !== undefined) {
                setPhone(getactivepage.stageone.phone);
              }

              if (getactivepage.stageone.emailAddr !== undefined) {
                setEmailAddr(getactivepage.stageone.emailAddr);
              }
            }
          }
        }

        if (idd === 1) {
          if (getactivepage.stagethree !== undefined) {
            setSignupSteps(type);
            if (Object.keys(getactivepage.stagethree).length > 0) {
              if (getactivepage.stagethree.companyName !== undefined) {
                setCompanyName(getactivepage.stagethree.companyName);
              }
              if (getactivepage.stagethree.selectDate !== undefined) {
                setSelectDate(getactivepage.stagethree.selectDate);
              }
              if (getactivepage.stagethree.selectCompanyType !== undefined) {
                setSelectCompanyType(getactivepage.stagethree.selectCompanyType);
              }
              if (getactivepage.stagethree.compAddress !== undefined) {
                setCompAddress(getactivepage.stagethree.compAddress);
              }
              if (getactivepage.stagethree.state !== undefined) {
                setStateValue(getactivepage.stagethree.state.stateName);
                setStateID(getactivepage.stagethree.state.stateId);
              }
              if (getactivepage.stagethree.city !== undefined) {
                setCityValue(getactivepage.stagethree.city.cityName);
                setCityID(getactivepage.stagethree.city.cityId);
              }
            }
          }
        }

        if (idd === 2) {
          if (getactivepage.stagefour !== undefined) {
            setSignupSteps(type);
            if (reviewData.length > 0) {
              reviewData.forEach((docItem) => {
                console.log('dockssssssss', docItem);
                if (docItem.documentType.typeCode === 'CHEQUE') {
                  setCancelChequePreviewtype1live(docItem.contentType);
                  setCancelChequePreview(docItem.documentUrl);
                }

                if (docItem.documentType.typeCode === 'PAN CARD') {
                  setPanPreviewtype1live(docItem.contentType);
                  setPanPreview(docItem.documentUrl);
                }

                if (docItem.documentType.typeCode === 'INCORPORATION_CERTIFICATE') {
                  setIncorporationCertificatePreviewtype1live(docItem.contentType);
                  setIncorporationCertificatePreview(docItem.documentUrl);
                }

                if (docItem.documentType.typeCode === 'GSTIN') {
                  setGstCertificatePreviewtype1(docItem.contentType);
                  setGstCertificatePreview(docItem.documentUrl);
                }

                if (docItem.documentType.typeCode === 'COMPANY_LOGO') {
                  setLogoPreviewtype1live(docItem.contentType);
                  setLogoPreview(docItem.documentUrl);
                }
              });
            }
          }
        }

        if (idd === 3) {
          if (getactivepage.stagefifth !== undefined) {
            setSignupSteps(type);
          }
        }

        if (idd === 4) {
          if (getactivepage.stagelast !== undefined) {
            setSignupSteps(type);
          }
        }
      }
    }
  };

  return (
    <Box className="signUpOuter" sx={{ width: '100%', height: '100vh' }}>
      <Container maxWidth="fullWidth" disableGutters="true">
        <Stack>
          <Box className="signUpInner">
            <Box className="signUpLeft">
              <Box className="signUpleftInner">
                <Box>
                  <Typography variant="h6" className="signUpHeading">
                    <img
                      className="signUpLogo"
                      alt="Logo"
                      src="../../static/logos/RVPulselogoW.svg"
                    />
                    {/* Welcome to Partner Management System */}
                  </Typography>
                  <Box className="signUpFormLeft mt-2">
                    <Box
                      className={
                        signupSteps === 'verifyIdentity'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                      // onClick={(e) => navigate(`/signup-form`)}
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Select User Type</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Select Your User Type for next step
                        </Typography>
                      </Box>
                      <Typography
                        onClick={() => activetab('verifyIdentity', 0)}
                        className="signUpFormIconOut"
                      >
                        <HiOutlineIdentification classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                    <Box
                      className={
                        signupSteps === 'personalDetails'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                      // onClick={(e) => setSignupSteps('personalDetails')}
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Company Details</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Fill in Your Name, Mobile No and verify your contact number
                        </Typography>
                      </Box>
                      <Typography
                        onClick={() => activetab('personalDetails', 1)}
                        className="signUpFormIconOut"
                      >
                        <TiUserOutline classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                    <Box
                      className={
                        signupSteps === 'uploadDocuments'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                      // onClick={(e) => setSignupSteps('uploadDocuments')}
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Upload Documents</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Upload your KYC documents
                        </Typography>
                      </Box>
                      <Typography
                        onClick={() => activetab('uploadDocuments', 2)}
                        className="signUpFormIconOut"
                      >
                        <RiGalleryUploadLine classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                    <Box
                      className={
                        signupSteps === 'results'
                          ? 'signUpFormStep mb-4 active'
                          : 'signUpFormStep mb-4'
                      }
                      // onClick={(e) => setSignupSteps('results')}
                    >
                      <Box className="signUpFormTextBox">
                        <Typography className="signUpFormText mb-1">Business Agreement</Typography>
                        <Typography className="signUpFormTextSec" variant="body2">
                          Agree to the terms &#38; conditions of the business agreement and submit
                          for activation of your id
                        </Typography>
                      </Box>
                      <Typography
                        onClick={() => activetab('results', 3)}
                        className="signUpFormIconOut"
                      >
                        <BsShieldFillCheck classname="signUpFormIcon" fontSize={24} />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="signUpRight">
              <Box className="signUpRightInner">
                <Box className="signUpFormRight">
                  {signupSteps === 'verifyIdentity' ? (
                    <>
                      {' '}
                      {verifySteps === 'verifyStepOne' ? (
                        ({
                          /* First Step Start Here */
                        },
                        (
                          <Grid container alignItems="center" spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="h6" className="mb-1">
                                Verify Identity
                              </Typography>
                              <Typography variant="body2" className="mb-3">
                                (Please fill in your contact details to start the Process)
                              </Typography>
                              <Box className="mb-3">
                                <Typography variant="h6" className="formLabel">
                                  Authorised Person Name
                                </Typography>
                                <TextField
                                  value={authorisedName}
                                  onChange={(e) => {
                                    setAuthorisedName(e.target.value);
                                    let getactivepage = '';
                                    getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                    if (getactivepage != null) {
                                      if (getactivepage.routepage === 'signup-form/Company') {
                                        getactivepage.stageone = {};
                                        getactivepage.stageone.authorisedName = e.target.value;
                                        localStorage.setItem(
                                          'trackuser',
                                          JSON.stringify(getactivepage)
                                        );
                                      }
                                    }
                                  }}
                                  type="text"
                                  variant="outlined"
                                  inputProps={{
                                    className: 'textInput'
                                  }}
                                  placeholder="Type Your Name"
                                  fullWidth
                                />
                              </Box>
                              <Box className="mb-3">
                                <Typography variant="h6" className="formLabel">
                                  Mobile Number
                                </Typography>
                                <TextField
                                  value={phone}
                                  onChange={(e) => {
                                    setPhone(e.target.value);
                                    setMobileHelper('');
                                    setMobileError(false);
                                    let getactivepage = '';
                                    getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                    if (getactivepage != null) {
                                      if (getactivepage.routepage === 'signup-form/Company') {
                                        getactivepage.stageone.phone = e.target.value;
                                        localStorage.setItem(
                                          'trackuser',
                                          JSON.stringify(getactivepage)
                                        );
                                      }
                                    }
                                  }}
                                  type="number"
                                  variant="outlined"
                                  inputProps={{
                                    className: 'textInput'
                                  }}
                                  placeholder="Type Mobile Number"
                                  fullWidth
                                  error={mobileError}
                                  helperText={mobileHelper}
                                />
                              </Box>
                              <Box className="mb-3">
                                <Typography variant="h6" className="formLabel">
                                  Email
                                </Typography>
                                <TextField
                                  value={emailAddr}
                                  onChange={(e) => {
                                    setEmailAddr(e.target.value);
                                    let getactivepage = '';
                                    getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                    if (getactivepage != null) {
                                      if (getactivepage.routepage === 'signup-form/Company') {
                                        getactivepage.stageone.emailAddr = e.target.value;
                                        localStorage.setItem(
                                          'trackuser',
                                          JSON.stringify(getactivepage)
                                        );
                                      }
                                    }
                                  }}
                                  type="text"
                                  variant="outlined"
                                  inputProps={{
                                    className: 'textInput'
                                  }}
                                  placeholder="Type Email"
                                  fullWidth
                                />
                              </Box>
                              <Button
                                className="mainBtn"
                                // onClick={(e) => setVerifySteps('verifyStepTwo')}
                                onClick={(e) => {
                                  handleSignUp(e);
                                  let getactivepage = '';
                                  getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                  if (getactivepage != null) {
                                    if (getactivepage.routepage === 'signup-form/Company') {
                                      getactivepage.stagethree = {};
                                      localStorage.setItem(
                                        'trackuser',
                                        JSON.stringify(getactivepage)
                                      );
                                    }
                                  }
                                }}
                              >
                                Next
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <img src="../static/applicatin.jpg" alt="application" />
                            </Grid>
                          </Grid>
                        ))
                      ) : verifySteps === 'verifyStepTwo' ? (
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={6}>
                            <Typography variant="h6" className="mb-1">
                              Verify Identity
                            </Typography>
                            <Typography variant="body2" className="mb-3">
                              (We have send a One Time Password to your Mobile. Please fill in your
                              Mobile OTP to proceed further.)
                            </Typography>
                            <Box className="mb-3">
                              <Typography variant="h6" className="formLabel">
                                Mobile OTP
                              </Typography>
                              <TextField
                                value={otp1}
                                error={error}
                                helperText={helperText}
                                onChange={(e) => setOtp1(e.target.value)}
                                type="number"
                                variant="outlined"
                                inputProps={{
                                  className: 'textInput'
                                }}
                                placeholder="Type Mobile OTP"
                                fullWidth
                              />
                            </Box>
                            <Button
                              className="mainBtn"
                              // onClick={(e) => setSignupSteps('personalDetails')}
                              onClick={(e) => {
                                handleSignUpTwo(e);
                                // let getactivepage = '';
                                // getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                // if (getactivepage != null) {
                                //   if (getactivepage.routepage === 'signup-form/Company') {
                                //     getactivepage.stagethree = {};
                                //     localStorage.setItem(
                                //       'trackuser',
                                //       JSON.stringify(getactivepage)
                                //     );
                                //   }
                                // }
                              }}
                            >
                              Next
                            </Button>
                            <Button
                              disabled={resendDisable}
                              className="mainBtn"
                              sx={{ marginLeft: '15px' }}
                              // onClick={(e) => setSignupSteps('personalDetails')
                              onClick={(e) => {
                                if (counter < 3) {
                                  setCounter(counter + 1);
                                  const requestotpApi = async () => {
                                    const requestotp = await RequestOtp(
                                      {
                                        mobileNumber: phone,
                                        emailAddress: emailAddr
                                      },
                                      signUpAuthToken
                                    );
                                    if (requestotp.status === 'success') {
                                      setReuestOtp(requestotp.data);
                                      setVerifySteps('verifyStepTwo');
                                    }
                                  };

                                  requestotpApi();
                                } else {
                                  setVerifySteps('verifyStepOne');
                                }
                              }}
                            >
                              Resend OTP
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <img src="../static/applicatin.jpg" alt="application" />
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </>
                  ) : signupSteps === 'personalDetails' ? (
                    <>
                      <Typography variant="h6" className="mb-1">
                        Company Details
                      </Typography>
                      <Typography variant="body2" className="mb-3">
                        (Please fill in your company details for the next step)
                      </Typography>
                      <Grid container spacing={3} mb={3}>
                        <Grid item xs={12} sm={6}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              Company Name
                            </Typography>
                            <TextField
                              value={companyName}
                              onChange={(e) => {
                                setCompanyName(e.target.value);
                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Company') {
                                    getactivepage.stagethree.companyName = e.target.value;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }
                              }}
                              type="text"
                              variant="outlined"
                              inputProps={{
                                className: 'textInput'
                              }}
                              placeholder="Type Company Name"
                              fullWidth
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              Date of Incorporation
                            </Typography>
                            <TextField
                              variant="outlined"
                              inputProps={{
                                className: `textInput dissabledMenu${selectDate}`
                              }}
                              placeholder="Select Date of Birth"
                              fullWidth
                              type="date"
                              value={selectDate}
                              onChange={(e) => {
                                setSelectDate(e.target.value);
                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Company') {
                                    getactivepage.stagethree.selectDate = e.target.value;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                        {companyTypeList.length > 0 ? (
                          <Grid item xs={12} sm={6}>
                            <Box className="">
                              <Typography variant="h6" className="formLabel">
                                Company Type
                              </Typography>
                              <Select
                                fullWidth
                                inputProps={{
                                  className: `textInput customSelectBox dissabledMenu${selectCompanyType}`
                                }}
                                value={selectCompanyType}
                                onChange={(e) => {
                                  setSelectCompanyType(e.target.value);
                                  let getactivepage = '';
                                  getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                  if (getactivepage != null) {
                                    if (getactivepage.routepage === 'signup-form/Company') {
                                      getactivepage.stagethree.selectCompanyType = e.target.value;
                                      localStorage.setItem(
                                        'trackuser',
                                        JSON.stringify(getactivepage)
                                      );
                                    }
                                  }
                                }}
                              >
                                <MenuItem value=" " disabled>
                                  Select Company Type
                                </MenuItem>
                                {companyTypeList.length > 0
                                  ? companyTypeList.map((item, index) => {
                                      console.log('item', item);
                                      return (
                                        <MenuItem value={item.typeId} key={index}>
                                          {item.typeName}
                                        </MenuItem>
                                      );
                                    })
                                  : ''}
                              </Select>
                            </Box>
                          </Grid>
                        ) : (
                          ''
                        )}
                        <Grid item xs={12} sm={6}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              Address
                            </Typography>

                            <TextField
                              fullWidth
                              inputProps={{
                                className: 'textInput'
                              }}
                              placeholder="Type Address"
                              value={compAddress}
                              onChange={(e) => {
                                setCompAddress(e.target.value);
                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Company') {
                                    getactivepage.stagethree.compAddress = e.target.value;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              State
                            </Typography>
                            {/* <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={topStates}
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} fullWidth placeholder="Select State" />
                              )}
                            /> */}
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo3"
                              options={stateListMenu}
                              size="small"
                              value={stateValue}
                              onChange={(e, newValue) => {
                                setCityListValueComp(null);
                                setStateListValueComp(newValue);
                                getCityList(e, newValue);
                                setStateValue(newValue.stateName);
                                setStateID(newValue.stateId);

                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Company') {
                                    getactivepage.stagethree.state = newValue;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }
                              }}
                              getOptionLabel={(option) =>
                                option.stateName ? option.stateName : stateValue
                              }
                              renderOption={(props, option) => (
                                <li {...props}>{option.stateName}</li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth placeholder="Select State" />
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box className="">
                            <Typography variant="h6" className="formLabel">
                              City
                            </Typography>
                            {/* <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={topCities}
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} fullWidth placeholder="Select City" />
                              )}
                            /> */}
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo4"
                              options={cityListMenu}
                              size="small"
                              value={cityValue}
                              onChange={(e, newValue) => {
                                setCityListValueComp(newValue);
                                setCityValue(newValue.cityName);
                                setCityID(newValue.cityId);
                                console.log('newValue', newValue);
                                let getactivepage = '';
                                getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                                if (getactivepage != null) {
                                  if (getactivepage.routepage === 'signup-form/Company') {
                                    getactivepage.stagethree.city = newValue;
                                    localStorage.setItem(
                                      'trackuser',
                                      JSON.stringify(getactivepage)
                                    );
                                  }
                                }
                              }}
                              getOptionLabel={(option) =>
                                option.cityName ? option.cityName : cityValue
                              }
                              renderOption={(props, option) => (
                                <li {...props}>{option.cityName}</li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth placeholder="Select City" />
                              )}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Button
                        className="mainBtn"
                        // onClick={(e) => setSignupSteps('uploadDocuments')}
                        onClick={(e) => {
                          handleSignUpThree(e);
                          let getactivepage = '';
                          getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                          if (getactivepage != null) {
                            if (getactivepage.routepage === 'signup-form/Company') {
                              getactivepage.stagefour = {};
                              localStorage.setItem('trackuser', JSON.stringify(getactivepage));
                            }
                          }
                        }}
                      >
                        Next
                      </Button>
                    </>
                  ) : signupSteps === 'uploadDocuments' ? (
                    <>
                      <Typography variant="h6" className="mb-1">
                        Upload Documents
                      </Typography>
                      <Typography variant="body2" className="mb-3">
                        (Please Upload your KYC documents for the next step)
                      </Typography>
                      <Grid container alignItems="center" spacing={3} mb={3}>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file1"
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                setLogoPreview,
                                null,
                                setLogoUpload,
                                setLogoPreviewtype1,
                                setLogoPreviewtype1live
                              )
                            }
                          />
                          <label
                            htmlFor="contained-button-file1"
                            className={
                              logoPreviewtype1 === 'pdf' ||
                              logoPreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) =>
                                fileDrop(
                                  e,
                                  setLogoPreview,
                                  setLogoUpload,
                                  setLogoPreviewtype1,
                                  setLogoPreviewtype1live
                                )
                              }
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {logoPreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    Logo
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {logoPreview !== undefined ? (
                                <img src={logoPreview} alt="logo" width="100%" height="" />
                              ) : (
                                ''
                              )} */}

                              {logoPreviewtype1 === 'pdf' ||
                              logoPreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={logoPreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img src={logoPreview} alt="" width="100%" height="" />
                              )}
                            </Box>
                          </label>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file2"
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                setGstCertificatePreview,
                                null,
                                setGstImage,
                                setGstCertificatePreviewtype1,
                                setGstCertificatePreviewtype1live
                              )
                            }
                          />
                          <label
                            htmlFor="contained-button-file2"
                            className={
                              gstCertificatePreviewtype1 === 'pdf' ||
                              gstCertificatePreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) =>
                                fileDrop(
                                  e,
                                  setGstCertificatePreview,
                                  setGstImage,
                                  setGstCertificatePreviewtype1,
                                  setGstCertificatePreviewtype1live
                                )
                              }
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {gstCertificatePreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    GST Certificate
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {gstCertificatePreview !== undefined ? (
                                <img
                                  src={gstCertificatePreview}
                                  alt="gst_certificate"
                                  width="100%"
                                  height=""
                                />
                              ) : (
                                ''
                              )} */}

                              {gstCertificatePreviewtype1 === 'pdf' ||
                              gstCertificatePreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={gstCertificatePreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img src={gstCertificatePreview} alt="" width="100%" height="" />
                              )}
                            </Box>
                          </label>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file3"
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                setIncorporationCertificatePreview,
                                null,
                                setIncorporationUploadComp,
                                setIncorporationCertificatePreviewtype1,
                                setIncorporationCertificatePreviewtype1live
                              )
                            }
                          />
                          <label
                            htmlFor="contained-button-file3"
                            className={
                              incorporationCertificatePreviewtype1 === 'pdf' ||
                              incorporationCertificatePreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) =>
                                fileDrop(
                                  e,
                                  setIncorporationCertificatePreview,
                                  setIncorporationUploadComp,
                                  setIncorporationCertificatePreviewtype1,
                                  setIncorporationCertificatePreviewtype1live
                                )
                              }
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {incorporationCertificatePreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    Incoporation Certificate
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {incorporationCertificatePreview !== undefined ? (
                                <img
                                  src={incorporationCertificatePreview}
                                  alt="incorporation_certificate"
                                  width="100%"
                                  height=""
                                />
                              ) : (
                                ''
                              )} */}

                              {incorporationCertificatePreviewtype1 === 'pdf' ||
                              incorporationCertificatePreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={incorporationCertificatePreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img
                                  src={incorporationCertificatePreview}
                                  alt=""
                                  width="100%"
                                  height=""
                                />
                              )}
                            </Box>
                          </label>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file4"
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                setPanPreview,
                                null,
                                setUploadPanImageCompany,
                                setPanPreviewtype1,
                                setPanPreviewtype1live
                              )
                            }
                          />
                          <label
                            htmlFor="contained-button-file4"
                            className={
                              panPreviewtype1 === 'pdf' || panPreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) =>
                                fileDrop(
                                  e,
                                  setPanPreview,
                                  setUploadPanImageCompany,
                                  setPanPreviewtype1,
                                  setPanPreviewtype1live
                                )
                              }
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {panPreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    Pan Card
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {panPreview !== undefined ? (
                                <img src={panPreview} alt="pan" width="100%" height="" />
                              ) : (
                                ''
                              )} */}

                              {panPreviewtype1 === 'pdf' ||
                              panPreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={panPreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img src={panPreview} alt="" width="100%" height="" />
                              )}
                            </Box>
                          </label>
                        </Grid>
                        <Grid item>
                          <input
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            className="fileInput"
                            id="contained-button-file5"
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                setCancelChequePreview,
                                null,
                                setUploadChequeCompany,
                                setCancelChequePreviewtype1,
                                setCancelChequePreviewtype1live
                              )
                            }
                          />
                          <label
                            htmlFor="contained-button-file5"
                            className={
                              cancelChequePreviewtype1 === 'pdf' ||
                              cancelChequePreviewtype1live === 'application/pdf'
                                ? `pdfBox uploadDocBoxLabel`
                                : `uploadDocBoxLabel`
                            }
                          >
                            <Box
                              variant="contained"
                              color="primary"
                              component="span"
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) =>
                                fileDrop(
                                  e,
                                  setCancelChequePreview,
                                  setUploadChequeCompany,
                                  setCancelChequePreviewtype1,
                                  setCancelChequePreviewtype1live
                                )
                              }
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {cancelChequePreview === undefined ? (
                                <>
                                  <Typography variant="h6" className="partnerTypeIconO mb-2">
                                    <AiOutlineCloudUpload fontSize={40} />
                                  </Typography>
                                  <Typography variant="subtitle1" className="textAlignCenter">
                                    Cancelled Cheque
                                  </Typography>
                                </>
                              ) : (
                                ''
                              )}
                              {/* {cancelChequePreview !== undefined ? (
                                <img
                                  src={cancelChequePreview}
                                  alt="cheque"
                                  width="100%"
                                  height=""
                                />
                              ) : (
                                ''
                              )} */}

                              {cancelChequePreviewtype1 === 'pdf' ||
                              cancelChequePreviewtype1live === 'application/pdf' ? (
                                <Document
                                  file={cancelChequePreview}
                                  onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages)}
                                >
                                  <Pages pageNumber={pageNumber} />
                                </Document>
                              ) : (
                                <img src={cancelChequePreview} alt="" width="100%" height="" />
                              )}
                            </Box>
                          </label>
                        </Grid>
                      </Grid>
                      <Button
                        className="mainBtn"
                        // onClick={(e) => setSignupSteps('results')}
                        onClick={(e) => {
                          documentValidation(e);
                          let getactivepage = '';
                          getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                          if (getactivepage != null) {
                            if (getactivepage.routepage === 'signup-form/Company') {
                              getactivepage.stagefifth = {};
                              localStorage.setItem('trackuser', JSON.stringify(getactivepage));
                            }
                          }
                        }}
                      >
                        Next
                      </Button>
                    </>
                  ) : signupSteps === 'results' ? (
                    <>
                      <Typography className="mb-3" variant="h5" textAlign="">
                        Associate Partner/Channel Partner/Authorised Person/Franchisee Service
                        Agreement
                      </Typography>
                      <AgreementText />
                      <Box className="displayFlex alignItemTop" mb={3}>
                        <Checkbox
                          size="small"
                          sx={{ padding: '0 10px 0 0' }}
                          onClick={(e) => setChecked(e.target.checked)}
                          disableRipple
                        />
                        <Typography variant="caption" color="#0d4689">
                          Yes, I understand and agree to the Real Value Finloan Terms and
                          Conditions, including User Agreement and Privacy Policy. I declare that
                          the information I have provided is accurate and complete to the best of my
                          knowledge. I hereby authorize Real Value Finloan and its affiliates to
                          call, email, send a text through the Short messaging Service (SMS) and/or
                          WhatsApp. The consent herein shall override any registration for DNC/NDNC.
                        </Typography>
                      </Box>
                      <Box textAlign="">
                        {/* <Button className="mainBtn" onClick={(e) => setOpenAssign(true)}>
                          Agree &#38; Submit
                        </Button> */}
                        <Button
                          className="mainBtn"
                          disabled={checked ? false : true}
                          onClick={async (e) => {
                            const typeId = JSON.parse(localStorage.getItem('typeID'));
                            const createCredentials = {
                              companyId: 36,
                              typeId: typeId.typeId, // from localStorage

                              partnerName: String(companyName),
                              // fathersName: String(fname),
                              dob: String(selectDate),
                              mobile: String(phone),
                              address: String(compAddress),
                              emailAddress: String(emailAddr),
                              authorisedPersonName: String(authorisedName),
                              // districtId: Number(cityID),
                              // provinceId: Number(stateID),
                              districtId:
                                cityListMenu && cityListValueComp ? cityListValueComp.cityId : null,
                              provinceId:
                                stateListMenu && stateListValueComp
                                  ? stateListValueComp.stateId
                                  : null,
                              countryId: 101,
                              isSignUpFlow: true,
                              partnerId: createPartnerData.partnerId,
                              isAgreementChecked: checked
                            };
                            setLoader(true);
                            const CreatePartnerApiResponse = await CreatePartner(
                              createCredentials,
                              signUpAuthToken
                            );
                            if (CreatePartnerApiResponse.status === 'success') {
                              setLoader(false);
                              setCreatePartnerData(CreatePartnerApiResponse.data);
                            }
                            setOpenAssign(true);

                            let getactivepage = '';
                            getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                            if (getactivepage != null) {
                              if (getactivepage.routepage === 'signup-form/Company') {
                                getactivepage.stagelast = {};
                                localStorage.setItem('trackuser', JSON.stringify(getactivepage));
                              }
                            }
                          }}
                        >
                          Agree &#38; Submit
                        </Button>
                      </Box>
                      <Dialog open={openAssign} onClose={(e) => setOpenAssign(false)} fullWidth>
                        <DialogTitle className="popupTitle">
                          <Typography variant="h6">Congratulations</Typography>
                          <IconButton
                            onClick={(e) => {
                              setOpenAssign(false);

                              let getactivepage = '';
                              getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                              if (getactivepage != null) {
                                if (getactivepage.routepage === 'signup-form/Company') {
                                  localStorage.removeItem('trackuser');
                                  localStorage.removeItem('reviewobj');
                                }
                              }
                            }}
                            size="small"
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                          <Typography textAlign="center" className="mb-2">
                            <BsPatchCheck fontSize={50} color="#a6ce39" />
                          </Typography>
                          <Typography variant="body1" textAlign="center" className="mb-2">
                            Your Digital application have been succeefully processed and your
                            Partner account has been opened successfully.
                          </Typography>
                          <Typography variant="body1" textAlign="center">
                            We have mailed your Partner account User Id and Password on your
                            registered email id
                          </Typography>
                          <Typography variant="body1" textAlign="center">
                            <Link to="/">{emailAddr}</Link>
                          </Typography>
                          {/* <Typography variant="body1" className="mb-3" textAlign="center">
                            You can Download our App by clicking below and log in with your details
                            to proceed further.
                          </Typography> */}
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={(e) => {
                              setOpenAssign(false);
                              let getactivepage = '';
                              getactivepage = JSON.parse(localStorage.getItem('trackuser'));
                              if (getactivepage != null) {
                                if (getactivepage.routepage === 'signup-form/Company') {
                                  localStorage.removeItem('trackuser');
                                  localStorage.removeItem('reviewobj');
                                }
                              }
                              navigate('/');
                            }}
                            variant="contained"
                            className="mainBtn"
                          >
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
        {/* Dialog for validation verify Identity */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">&nbsp;</Typography>
            <IconButton onClick={(e) => setOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box py={1} px={4} textAlign="center">
                <Typography variant="subtitle2">{validationMsg}</Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" className="mainBtn" onClick={handleClose}>
              Retry
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog for validation verify Identity end */}
      </Container>
    </Box>
  );
}

export default SignUpCompany;

// Top Cities
const topCities = [
  { label: 'Mumbai' },
  { label: 'Delhi' },
  { label: 'Bengaluru' },
  { label: 'Ahmedabad' },
  { label: 'Hyderabad' },
  { label: 'Chennai' },
  { label: 'Kolkata' },
  { label: 'Pune' },
  { label: 'Jaipur' },
  { label: 'Surat' },
  { label: 'Lucknow' },
  { label: 'Kanpur' },
  { label: 'Nagpur' },
  { label: 'Patna' },
  { label: 'Indore' },
  { label: 'Thane' },
  { label: 'Bhopal' },
  { label: 'Visakhapatnam' },
  { label: 'Vadodara' },
  { label: 'Firozabad' },
  { label: 'Ludhiana' },
  { label: 'Rajkot' },
  { label: 'Agra' },
  { label: 'Siliguri' },
  { label: 'Nashik' },
  { label: 'Faridabad' },
  { label: 'Patiala' },
  { label: 'Meerut' },
  { label: 'Varanasi' },
  { label: 'Dhanbad' },
  { label: 'Jodhpur' },
  { label: 'Amritsar' },
  { label: 'Raipur' },
  { label: 'Allahabad' },
  { label: 'Coimbatore' },
  { label: 'Jabalpur' },
  { label: 'Gwalior' },
  { label: 'Vijayawada' },
  { label: 'Madurai' },
  { label: 'Guwahati' },
  { label: 'Chandigarh' },
  { label: 'Hubli-Dharwad' },
  { label: 'Amroha' },
  { label: 'Moradabad' },
  { label: 'Gurgaon' },
  { label: 'Aligarh' },
  { label: 'Solapur' },
  { label: 'Ranchi' },
  { label: 'Jalandhar' },
  { label: 'Tiruchirappalli' },
  { label: 'Bhubaneswar' },
  { label: 'Salem' },
  { label: 'Warangal' },
  { label: 'Mira-Bhayandar' },
  { label: 'Thiruvananthapuram' },
  { label: 'Bhiwandi' },
  { label: 'Saharanpur' },
  { label: 'Guntur' },
  { label: 'Amravati' },
  { label: 'Bikaner' },
  { label: 'Noida' },
  { label: 'Jamshedpur' },
  { label: 'Bhilai Nagar' },
  { label: 'Cuttack' },
  { label: 'Kochi' },
  { label: 'Udaipur' },
  { label: 'Bhavnagar' },
  { label: 'Dehradun' },
  { label: 'Asansol' },
  { label: 'Nanded-Waghala' },
  { label: 'Ajmer' },
  { label: 'Jamnagar' },
  { label: 'Ujjain' },
  { label: 'Sangli' },
  { label: 'Loni' },
  { label: 'Jhansi' },
  { label: 'Pondicherry' },
  { label: 'Nellore' },
  { label: 'Jammu' },
  { label: 'Belagavi' },
  { label: 'Raurkela' },
  { label: 'Mangaluru' },
  { label: 'Tirunelveli' },
  { label: 'Malegaon' },
  { label: 'Gaya' },
  { label: 'Tiruppur' },
  { label: 'Davanagere' },
  { label: 'Kozhikode' },
  { label: 'Akola' },
  { label: 'Kurnool' },
  { label: 'Bokaro' },
  { label: 'Rajahmundry' },
  { label: 'Ballari' },
  { label: 'Agartala' },
  { label: 'Bhagalpur' },
  { label: 'Latur' },
  { label: 'Dhule' },
  { label: 'Korba' },
  { label: 'Bhilwara' },
  { label: 'Brahmapur' },
  { label: 'Mysore' },
  { label: 'Muzaffarpur' },
  { label: 'Ahmednagar' },
  { label: 'Kollam' },
  { label: 'Bilaspur' },
  { label: 'Shahjahanpur' },
  { label: 'Thrissur' },
  { label: 'Alwar' },
  { label: 'Kakinada' },
  { label: 'Nizamabad' },
  { label: 'Sagar' },
  { label: 'Tumkur' },
  { label: 'Hisar' },
  { label: 'Rohtak' },
  { label: 'Panipat' },
  { label: 'Darbhanga' },
  { label: 'Kharagpur' },
  { label: 'Aizawl' },
  { label: 'Ichalkaranji' },
  { label: 'Tirupati' },
  { label: 'Karnal' },
  { label: 'Bathinda' },
  { label: 'Rampur' },
  { label: 'Shivamogga' },
  { label: 'Ratlam' },
  { label: 'Modinagar' },
  { label: 'Durg' },
  { label: 'Shillong' },
  { label: 'Imphal' },
  { label: 'Hapur' },
  { label: 'Ranipet' },
  { label: 'Anantapur' },
  { label: 'Arrah' },
  { label: 'Karimnagar' },
  { label: 'Etawah' },
  { label: 'Bharatpur' },
  { label: 'Begusarai' },
  { label: 'Chhapra' },
  { label: 'Kadapa' },
  { label: 'Pali' },
  { label: 'Satna' },
  { label: 'Vizianagaram' },
  { label: 'Katihar' },
  { label: 'Hardwar' },
  { label: 'Sonipat' },
  { label: 'Nagercoil' },
  { label: 'Thanjavur' },
  { label: 'Murwara' },
  { label: 'Naihati' },
  { label: 'Sambhal' },
  { label: 'Nadiad' },
  { label: 'Yamunanagar' },
  { label: 'Eluru' },
  { label: 'Munger' },
  { label: 'Panchkula' },
  { label: 'Deoghar' },
  { label: 'Ongole' },
  { label: 'Nandyal' },
  { label: 'Morena' },
  { label: 'Bhiwani' },
  { label: 'Porbandar' },
  { label: 'Palakkad' },
  { label: 'Anand' },
  { label: 'Purnia' },
  { label: 'Baharampur' },
  { label: 'Barmer' },
  { label: 'Morvi' },
  { label: 'Orai' },
  { label: 'Bahraich' },
  { label: 'Vellore' },
  { label: 'Khammam' },
  { label: 'Mahesana' },
  { label: 'Silchar' },
  { label: 'Sambalpur' },
  { label: 'Rewa' },
  { label: 'Unnao' },
  { label: 'Hugli-Chinsurah' },
  { label: 'Raiganj' },
  { label: 'Adityapur' },
  { label: 'Alappuzha' },
  { label: 'Bahadurgarh' },
  { label: 'Machilipatnam' },
  { label: 'Rae Bareli' },
  { label: 'Jalpaiguri' },
  { label: 'Bharuch' },
  { label: 'Hoshiarpur' },
  { label: 'Baramula' },
  { label: 'Adoni' },
  { label: 'Jind' },
  { label: 'Tonk' },
  { label: 'Tenali' },
  { label: 'Kancheepuram' },
  { label: 'Vapi' },
  { label: 'Navsari' },
  { label: 'Mahbubnagar' },
  { label: 'Puri' },
  { label: 'Erode' },
  { label: 'Batala' },
  { label: 'Haldwani' },
  { label: 'Vidisha' },
  { label: 'Saharsa' },
  { label: 'Thanesar' },
  { label: 'Chittoor' },
  { label: 'Veraval' },
  { label: 'Lakhimpur' },
  { label: 'Sitapur' },
  { label: 'Hindupur' },
  { label: 'Santipur' },
  { label: 'Balurghat' },
  { label: 'Ganjbasoda' },
  { label: 'Moga' },
  { label: 'Srinagar' },
  { label: 'Medinipur' },
  { label: 'Habra' },
  { label: 'Sasaram' },
  { label: 'Hajipur' },
  { label: 'Bhuj' },
  { label: 'Shimla' },
  { label: 'Tiruvannamalai' },
  { label: 'Kaithal' },
  { label: 'Rajnandgaon' },
  { label: 'Godhra' },
  { label: 'Hazaribag' },
  { label: 'Bhimavaram' },
  { label: 'Mandsaur' },
  { label: 'Dibrugarh' },
  { label: 'Kolar' },
  { label: 'Bankura' },
  { label: 'Mandya' },
  { label: 'Dehri-on-Sone' },
  { label: 'Madanapalle' },
  { label: 'Malerkotla' },
  { label: 'Lalitpur' },
  { label: 'Bettiah' },
  { label: 'Pollachi' },
  { label: 'Khanna' },
  { label: 'Neemuch' },
  { label: 'Palwal' },
  { label: 'Palanpur' },
  { label: 'Guntakal' },
  { label: 'Nabadwip' },
  { label: 'Udupi' },
  { label: 'Jagdalpur' },
  { label: 'Motihari' },
  { label: 'Pilibhit' },
  { label: 'Dimapur' },
  { label: 'Mohali' },
  { label: 'Dharmavaram' },
  { label: 'Kashipur' },
  { label: 'Sivakasi' },
  { label: 'Darjiling' },
  { label: 'Chikkamagaluru' },
  { label: 'Gudivada' },
  { label: 'Adilabad' },
  { label: 'Yavatmal' },
  { label: 'Barnala' },
  { label: 'Nagaon' },
  { label: 'Narasaraopet' },
  { label: 'Raigarh' },
  { label: 'Roorkee' },
  { label: 'Valsad' },
  { label: 'Ambikapur' },
  { label: 'Giridih' },
  { label: 'Chandausi' },
  { label: 'Purulia' },
  { label: 'Patan' },
  { label: 'Bagaha' },
  { label: 'Hardoi' },
  { label: 'Achalpur' },
  { label: 'Osmanabad' },
  { label: 'Deesa' },
  { label: 'Nandurbar' },
  { label: 'Azamgarh' },
  { label: 'Ramgarh' },
  { label: 'Firozpur' },
  { label: 'Baripada' },
  { label: 'Karwar' },
  { label: 'Siwan' },
  { label: 'Anantnag' },
  { label: 'Tadpatri' },
  { label: 'Satara' },
  { label: 'Bhadrak' },
  { label: 'Kishanganj' },
  { label: 'Wardha' },
  { label: 'Amreli' },
  { label: 'Neyveli' },
  { label: 'Jamalpur' },
  { label: 'Marmagao' },
  { label: 'Udgir' },
  { label: 'Tadepalligudem' },
  { label: 'Nagapattinam' },
  { label: 'Buxar' },
  { label: 'Aurangabad' },
  { label: 'Jehanabad' },
  { label: 'Khair' },
  { label: 'Sawai Madhopur' },
  { label: 'Kapurthala' },
  { label: 'Chilakaluripet' },
  { label: 'Malappuram' },
  { label: 'Rewari' },
  { label: 'Nagaur' },
  { label: 'Sultanpur' },
  { label: 'Nagda' },
  { label: 'Port Blair' },
  { label: 'Lakhisarai' },
  { label: 'Panaji' },
  { label: 'Tinsukia' },
  { label: 'Itarsi' },
  { label: 'Kohima' },
  { label: 'Balangir' },
  { label: 'Nawada' },
  { label: 'Jharsuguda' },
  { label: 'Jagtial' },
  { label: 'Viluppuram' },
  { label: 'Amalner' },
  { label: 'Zirakpur' },
  { label: 'Tanda' },
  { label: 'Tiruchengode' },
  { label: 'Nagina' },
  { label: 'Yemmiganur' },
  { label: 'Vaniyambadi' },
  { label: 'Theni Allinagaram' },
  { label: 'Margao' },
  { label: 'Akot' },
  { label: 'Mhow' },
  { label: 'Kot Kapura' },
  { label: 'Kadiri' },
  { label: 'Najibabad' },
  { label: 'Nirmal' },
  { label: 'Udhagamandalam' },
  { label: 'Jhumri Tilaiya' },
  { label: 'Aruppukkottai' },
  { label: 'Jamui' },
  { label: 'Sitamarhi' },
  { label: 'Chirala' },
  { label: 'Anjar' },
  { label: 'Karaikal' },
  { label: 'Hansi' },
  { label: 'Anakapalle' },
  { label: 'Mahasamund' },
  { label: 'Faridkot' },
  { label: 'Dhoraji' },
  { label: 'Balaghat' },
  { label: 'Khambhat' },
  { label: 'Kavali' },
  { label: 'Dhamtari' },
  { label: 'Ashok Nagar' },
  { label: 'Bargarh' },
  { label: 'Kamareddy' },
  { label: 'Kothagudem' },
  { label: 'Ramanagaram' },
  { label: 'Gokak' },
  { label: 'Tikamgarh' },
  { label: 'Araria' },
  { label: 'Rishikesh' },
  { label: 'Daltonganj' },
  { label: 'Arakkonam' },
  { label: 'Washim' },
  { label: 'Sangrur' },
  { label: 'Bodhan' },
  { label: 'Fazilka' },
  { label: 'Palacole' },
  { label: 'Keshod' },
  { label: 'Wadhwan' },
  { label: 'Gurdaspur' },
  { label: 'Vatakara' },
  { label: 'Tura' },
  { label: 'Narnaul' },
  { label: 'Kharar' },
  { label: 'Yadgir' },
  { label: 'Ambejogai' },
  { label: 'Ankleshwar' },
  { label: 'Paradip' },
  { label: 'Virudhachalam' },
  { label: 'Kanhangad' },
  { label: 'Kadi' },
  { label: 'Gobindgarh' },
  { label: 'Tindivanam' },
  { label: 'Taliparamba' },
  { label: 'Tanuku' },
  { label: 'Virudhunagar' },
  { label: 'Koyilandy' },
  { label: 'Jorhat' },
  { label: 'Karur' },
  { label: 'Valparai' },
  { label: 'Neyyattinkara' },
  { label: 'Bapatla' },
  { label: 'Fatehabad' },
  { label: 'Sankarankovil' },
  { label: 'Tenkasi' },
  { label: 'Ratnagiri' },
  { label: 'Sikandrabad' },
  { label: 'Chaibasa' },
  { label: 'Chirmiri' },
  { label: 'Bhawanipatna' },
  { label: 'Kayamkulam' },
  { label: 'Nabha' },
  { label: 'Dhenkanal' },
  { label: 'Uran Islampur' },
  { label: 'Gopalganj' },
  { label: 'Bongaigaon' },
  { label: 'Tarn Taran' },
  { label: 'Barbil' },
  { label: 'Koratla' },
  { label: 'Arambagh' },
  { label: 'Gohana' },
  { label: 'Ladnu' },
  { label: 'Tamluk' },
  { label: 'Jagraon' },
  { label: 'Alirajpur' },
  { label: 'Tandur' },
  { label: 'Naidupet' },
  { label: 'Tirupathur' },
  { label: 'Tohana' },
  { label: 'Dhubri' },
  { label: 'Visnagar' },
  { label: 'Vrindavan' },
  { label: 'Nokha' },
  { label: 'Nagari' },
  { label: 'Narwana' },
  { label: 'Ujhani' },
  { label: 'Samastipur' },
  { label: 'Laharpur' },
  { label: 'Nimbahera' },
  { label: 'Diphu' },
  { label: 'Jhargram' },
  { label: 'Tilhar' },
  { label: 'Udumalaipettai' },
  { label: 'Wanaparthy' },
  { label: 'Gudur' },
  { label: 'Kendujhar' },
  { label: 'Mandi' },
  { label: 'Nedumangad' },
  { label: 'North Lakhimpur' },
  { label: 'Vinukonda' },
  { label: 'Tiptur' },
  { label: 'Gobichettipalayam' },
  { label: 'Wani' },
  { label: 'Upleta' },
  { label: 'Narasapuram' },
  { label: 'Nuzvid' },
  { label: 'Tezpur' },
  { label: 'Una' },
  { label: 'Sheopur' },
  { label: 'Thiruvarur' },
  { label: 'Lonavla' },
  { label: 'Ponnur' },
  { label: 'Kagaznagar' },
  { label: 'Gadwal' },
  { label: 'Bhatapara' },
  { label: 'Kandukur' },
  { label: 'Unjha' },
  { label: 'Lunglei' },
  { label: 'Karimganj' },
  { label: 'Kannur' },
  { label: 'Bobbili' },
  { label: 'Talegaon Dabhade' },
  { label: 'Anjangaon' },
  { label: 'Mangrol' },
  { label: 'Gangarampur' },
  { label: 'Thiruvallur' },
  { label: 'Tirur' },
  { label: 'Jatani' },
  { label: 'Viramgam' },
  { label: 'Rajsamand' },
  { label: 'Yanam' },
  { label: 'Kottayam' },
  { label: 'Dhuri' },
  { label: 'Namakkal' },
  { label: 'Kasaragod' },
  { label: 'Kunnamkulam' },
  { label: 'Umred' },
  { label: 'Bellampalle' },
  { label: 'Ottappalam' },
  { label: 'Dumraon' },
  { label: 'Jaggaiahpet' },
  { label: 'Goalpara' },
  { label: 'Tuni' },
  { label: 'Lachhmangarh' },
  { label: 'Bhongir' },
  { label: 'Amalapuram' },
  { label: 'Firozpur Cantt.' },
  { label: 'Vikarabad' },
  { label: 'Thiruvalla' },
  { label: 'Palghar' },
  { label: 'Jangaon' },
  { label: 'Bheemunipatnam' },
  { label: 'Panna' },
  { label: 'Thodupuzha' },
  { label: 'Arwal' },
  { label: 'Venkatagiri' },
  { label: 'Kalpi' },
  { label: 'Churu' },
  { label: 'Arsikere' },
  { label: 'Ozar' },
  { label: 'Thirumangalam' },
  { label: 'Nasirabad' },
  { label: 'Nanjangud' },
  { label: 'Forbesganj' },
  { label: 'Tundla' },
  { label: 'Bhadrachalam' },
  { label: 'Madhepura' },
  { label: 'Pithoragarh' },
  { label: 'Mathura' },
  { label: 'Sultanganj' },
  { label: 'Raxaul' },
  { label: 'Pilani' },
  { label: 'Mapusa' },
  { label: 'Sivaganga' },
  { label: 'Shahpur' },
  { label: 'Ramnagar' },
  { label: 'Rameshwaram' },
  { label: 'Perambalur' },
  { label: 'Manvi' },
  { label: 'Murshidabad' },
  { label: 'Mahe' },
  { label: 'Solan' },
  { label: 'Sanawad' },
  { label: 'Bulandshahr' },
  { label: 'Satana' },
  { label: 'Sanand' },
  { label: 'Sohna' },
  { label: 'Rudrapur' },
  { label: 'Perumbavoor' },
  { label: 'Raikot' },
  { label: 'Pauri' },
  { label: 'Shahganj' },
  { label: 'Mount Abu' },
  { label: 'Silvassa' },
  { label: 'Sikanderpur' },
  { label: 'Navi Mumbai' }
];
// Top States
const topStates = [
  { label: 'Andhra Pradesh' },
  { label: 'Arunachal Pradesh' },
  { label: 'Assam' },
  { label: 'Bihar' },
  { label: 'Chhattisgarh' },
  { label: 'Goa' },
  { label: 'Gujarat' },
  { label: 'Haryana' },
  { label: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir' },
  { label: 'Jharkhand' },
  { label: 'Karnataka' },
  { label: 'Kerala' },
  { label: 'Madhya Pradesh' },
  { label: 'Maharashtra' },
  { label: 'Manipur' },
  { label: 'Meghalaya' },
  { label: 'Mizoram' },
  { label: 'Nagaland' },
  { label: 'Odisha' },
  { label: 'Punjab' },
  { label: 'Rajasthan' },
  { label: 'Sikkim' },
  { label: 'Tamil Nadu' },
  { label: 'Telangana' },
  { label: 'Tripura' },
  { label: 'Uttarakhand' },
  { label: 'Uttar Pradesh' },
  { label: 'West Bengal' },
  { label: 'Andaman and Nicobar Islands' },
  { label: 'Chandigarh' },
  { label: 'Dadra and Nagar Haveli' },
  { label: 'Daman and Diu' },
  { label: 'Delhi' },
  { label: 'Lakshadweep' },
  { label: 'Puducherry' }
];
