import { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonGroup
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import partnerService from '../services/partnerService';
import prospectService from '../services/prospectService';

const { LoanTypes, CityList, SourceList, BankList, StateList } = partnerService;
const { ProspectCreate, EmploymentTypes, PropertyConfigurationType } = prospectService;

function AddEditProspect() {
  const navigate = useNavigate();
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [loanAmountValue, setLoanAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [developerName, setDeveloperName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [cityName, setCityName] = useState('');
  const [selectCity, setSelectCity] = useState(' ');

  const [token, setToken] = useState('');
  const [companyId, setCompanyId] = useState();
  const [userId, setUserId] = useState('');
  const [cityListData, setCityListData] = useState(null);
  const [bankPref, setBankPref] = useState(null);
  const [loanTypesList, setLoanTypesList] = useState([]); // select product data from api
  const [typeId, setTypeId] = useState(); // id from select product data api
  const [selectSource, setSelectSource] = useState(126); // select source
  // const [selectSource, setSelectSource] = useState(' '); // select source
  const [sourceListData, setSourceListData] = useState([]); // source list data from api
  const [bankListOpt, setBankListOpt] = useState([]);
  const [leadId, setLeadId] = useState(null);
  const [validationMsg, setValidationMsg] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inputBankName, setInputBankName] = useState('');
  const [stateListMenu, setStateListMenu] = useState([]);
  const [cityListMenu, setCityListMenu] = useState([]);
  const [stateListData, setStateListData] = useState(null);
  const [employmentType, setEmploymentType] = useState(' ');
  const [employmentTypeSec, setEmploymentTypeSec] = useState(' ');
  const [projectIdentified, setProjectIdentified] = useState(' ');
  const [propertyConfiguration, setPropertyConfiguration] = useState(' ');
  const [propertyIdentify, setPropertyIdentify] = useState('propertyNotIdentified');
  const [coApplicant, setCoApplicant] = useState('coApplicantNo');
  const [empTypesOptions, setEmpTypesOptions] = useState([]);
  const [employmentTypeData, setEmploymentTypeData] = useState([]);
  const [propertyConfigurationData, setPropertyConfigurationData] = useState([]);
  const [selectLoanType, setSelectLoanType] = useState(' ');
  const [remark, setRemark] = useState('');

  console.log('sourceListData', sourceListData);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };

  // select source
  const handleSelectSource = (e) => {
    setSelectSource(e.target.value);
  };

  const handleSubmit = async (e) => {
    const pattern = /^[6-9][0-9]{9}$/; // mobile regex pattern
    if (!pattern.test(mobileNumber)) {
      setDialogOpen(true);
      setValidationMsg('Please enter valid number');
      return;
    }
    // need to change
    const credentials = {
      // bankPref: bankPref !== null ? bankPref.bankId : null,
      // loanType: loanInterested !== ' ' ? loanInterested : null,

      // new cred
      companyId: companyId,
      userId: userId,
      mobile: mobileNumber !== '' ? mobileNumber : null,
      // customerName: 'Saurabh K',
      // bankPref: 50,
      // loanType: 4,
      // sourceTypeId: selectSource !== ' ' ? selectSource : null,
      sourceTypeId: selectSource !== ' ' ? selectSource : null,
      provinceId: stateListData === null ? null : stateListData.stateId,
      districtId: cityListData === null ? null : cityListData.cityId,
      loanAmountRequested: loanAmountValue !== '' ? loanAmountValue : null,
      applicantEmploymentTypeId: employmentType !== ' ' ? employmentType : null,
      coapplicantEnable: coApplicant === 'coApplicantYes' ? true : false,
      coapplicantEmploymentTypeId: employmentTypeSec !== ' ' ? employmentTypeSec : null,
      projectIdentifiedEnable: propertyIdentify === 'propertyIdentified' ? true : false,
      developerName: developerName !== '' ? developerName : null,
      projectName: projectName !== '' ? projectName : null,
      propertyConfigurationTypeId: propertyConfiguration !== ' ' ? propertyConfiguration : null,
      customerFirstName: firstName,
      customerMiddleName: middleName,
      customerLastName: lastName,
      loanType: selectLoanType !== ' ' ? selectLoanType : null,
      remark: remark !== '' ? remark : null
    };

    const ProspectCreateApiResponse = await ProspectCreate(credentials, token);
    if (ProspectCreateApiResponse.msg) {
      if (ProspectCreateApiResponse.msg === 'Forbidden') {
        navigate('/');
      }
    }
    if (ProspectCreateApiResponse.status === 'success') {
      setLeadId(ProspectCreateApiResponse.data.leadId);
      navigate('/prospect');
      setMobileNumber('');
    }
    console.log('ProspectCreateApiResponse', ProspectCreateApiResponse);
  };

  const getCityList = async (e, newValue) => {
    const CityListApiResponse = await CityList(
      {
        searchLiteral: '',
        stateId: newValue.stateId ? newValue.stateId : null
      },
      token
    );
    console.log('CityListApiResponse', CityListApiResponse);
    if (CityListApiResponse.action_status === 'success') {
      setCityListMenu(CityListApiResponse.data);
    } else {
      setCityListMenu([]);
    }
  };

  useEffect(() => {
    (async () => {
      const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
      if (localJWTtoken === null) {
        return true;
      }
      console.log('localJWTtoken', localJWTtoken);
      const authToken = localJWTtoken.token.jwt;
      setToken(authToken);
      const authCompanyId = localJWTtoken.token.companyId;
      setCompanyId(authCompanyId);
      const authUserId = localJWTtoken.token.userId;
      setUserId(authUserId);
      // // select city  api for filter
      // const CityListApiResponse = await CityList(
      //   {
      //     searchLiteral: '',
      //     stateId: null
      //   },
      //   authToken
      // );
      // console.log('CityListApiResponse', CityListApiResponse);
      // if (CityListApiResponse.action_status === 'success') {
      //   setCityListMenu(CityListApiResponse.data);
      // } else {
      //   setCityListMenu([]);
      // }

      // select product api
      const LoanTypesApiResponse = await LoanTypes(authToken);
      if (LoanTypesApiResponse.msg) {
        if (LoanTypesApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (LoanTypesApiResponse.status === 'success') {
        setLoanTypesList(LoanTypesApiResponse.data.loanTypes);
        setTypeId(LoanTypesApiResponse.data.loanTypes.typeId);
      }

      // select source
      const SourceListApiResponse = await SourceList(authToken);
      if (SourceListApiResponse.msg) {
        if (SourceListApiResponse.msg === 'Forbidden') {
          navigate('/');
        }
      }
      if (SourceListApiResponse.status === 'success') {
        setSourceListData(SourceListApiResponse.data.prospectSourceTypes);
      }

      // Fired Api of Bank name
      const ifPreviewDataBank = await BankList(authToken);
      if (ifPreviewDataBank.status === 'success') {
        setBankListOpt(ifPreviewDataBank.data.banks);
      } else if (ifPreviewDataBank.status === 'error') {
        localStorage.clear();
        navigate('/');
      }

      // state list api
      const StateCredentials = {
        countryId: 101,
        searchLiteral: ''
      };

      const StateListApiResponse = await StateList(StateCredentials, authToken);
      console.log('StateListApiResponse', StateListApiResponse);
      if (StateListApiResponse.action_status === 'success') {
        setStateListMenu(StateListApiResponse.data);
      } else {
        setStateListMenu([]);
      }

      // Employment Types api
      const EmploymentTypesApiResponse = await EmploymentTypes(authToken);
      console.log('EmploymentTypesApiResponse', EmploymentTypesApiResponse);
      if (EmploymentTypesApiResponse.status === 'success') {
        setEmploymentTypeData(EmploymentTypesApiResponse.data.combinedEmploymentTypes);
      } else {
        setEmploymentTypeData([]);
      }

      // Property Configuration Type
      const PropertyConfigurationTypeApiResponse = await PropertyConfigurationType(authToken);
      if (PropertyConfigurationTypeApiResponse.status === 'success') {
        setPropertyConfigurationData(
          PropertyConfigurationTypeApiResponse.data.propertyConfigurationTypes
        );
      } else {
        setPropertyConfigurationData([]);
      }
    })();
  }, []);

  return (
    <Page title="PULSE by Real Value Finloans || Prospect">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info={rowId ? 'Edit Prospect' : 'Add New Prospect'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Fill Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type First Name"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Middle Name
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Middle Name"
                    value={middleName}
                    onChange={(event) => setMiddleName(event.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Last Name"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Mobile Number
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Mobile Number"
                    value={mobileNumber}
                    onChange={(event) => setMobileNumber(event.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Loan Amount Required
                  </Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${loanAmountValue}`
                    }}
                    placeholder="Type Loan Amount Required"
                    fullWidth
                    value={loanAmountValue}
                    onChange={(event) => setLoanAmountValue(event.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Employment Type
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${employmentType}`
                    }}
                    value={employmentType}
                    onChange={(e) => setEmploymentType(e.target.value)}
                  >
                    <MenuItem value=" " disabled>
                      Select Employment Type
                    </MenuItem>
                    {/* <MenuItem value="Salaried (Cash)">Salaried (Cash)</MenuItem> */}
                    {employmentTypeData && employmentTypeData.length > 0
                      ? employmentTypeData.map((item, index) => {
                          console.log(item);
                          return <MenuItem value={item.typeId}>{item.typeName}</MenuItem>;
                        })
                      : ''}
                  </Select>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Loan Type
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${selectLoanType}`
                    }}
                    value={selectLoanType}
                    onChange={(e) => setSelectLoanType(e.target.value)}
                  >
                    <MenuItem value=" " disabled>
                      Select Loan Type
                    </MenuItem>
                    {loanTypesList.length > 0
                      ? loanTypesList.map((item, index) => {
                          console.log(item);
                          return (
                            <MenuItem value={item.loanId} key={index}>
                              {item.loanName}
                            </MenuItem>
                          );
                        })
                      : ''}
                  </Select>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Co Applicant
                  </Typography>
                  <ButtonGroup disableElevation variant="contained">
                    <Button
                      className={coApplicant === 'coApplicantYes' ? 'yesNoBtn active' : 'yesNoBtn'}
                      onClick={(e) => {
                        setCoApplicant('coApplicantYes');
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className={coApplicant === 'coApplicantNo' ? 'yesNoBtn active' : 'yesNoBtn'}
                      onClick={(e) => {
                        setCoApplicant('coApplicantNo');
                      }}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </Box>
              </Grid>
              {coApplicant === 'coApplicantYes' ? (
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="">
                    <Typography variant="h6" className="formLabel">
                      Employment Type
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${employmentTypeSec}`
                      }}
                      value={employmentTypeSec}
                      onChange={(e) => setEmploymentTypeSec(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Employment Type
                      </MenuItem>
                      {employmentTypeData && employmentTypeData.length > 0
                        ? employmentTypeData.map((item, index) => {
                            console.log(item);
                            return <MenuItem value={item.typeId}>{item.typeName}</MenuItem>;
                          })
                        : ''}
                    </Select>
                  </Box>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Project Identified
                  </Typography>
                  <ButtonGroup disableElevation variant="contained">
                    <Button
                      className={
                        propertyIdentify === 'propertyIdentified' ? 'yesNoBtn active' : 'yesNoBtn'
                      }
                      onClick={(e) => {
                        setPropertyIdentify('propertyIdentified');
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className={
                        propertyIdentify === 'propertyNotIdentified'
                          ? 'yesNoBtn active'
                          : 'yesNoBtn'
                      }
                      onClick={(e) => {
                        setPropertyIdentify('propertyNotIdentified');
                      }}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </Box>
              </Grid>
              {propertyIdentify === 'propertyIdentified' ? (
                <>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Developer Name
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type Developer Name"
                        value={developerName}
                        onChange={(event) => setDeveloperName(event.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Project Name
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type Project Name"
                        value={projectName}
                        onChange={(event) => setProjectName(event.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="">
                      <Typography variant="h6" className="formLabel">
                        Property Configuration
                      </Typography>
                      <Select
                        fullWidth
                        inputProps={{
                          className: `textInput customSelectBox dissabledMenu${propertyConfiguration}`
                        }}
                        value={propertyConfiguration}
                        onChange={(e) => setPropertyConfiguration(e.target.value)}
                      >
                        <MenuItem value=" " disabled>
                          Select Property Configuration
                        </MenuItem>
                        {propertyConfigurationData && propertyConfigurationData.length > 0
                          ? propertyConfigurationData.map((item, index) => {
                              console.log(item);
                              return <MenuItem value={item.typeId}>{item.typeName}</MenuItem>;
                            })
                          : ''}
                      </Select>
                    </Box>
                  </Grid>
                </>
              ) : (
                ''
              )}
            </Grid>

            <Grid container spacing={3} mb={3}>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    State
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo3"
                    options={stateListMenu}
                    size="small"
                    onChange={(e, newValue) => {
                      setCityListData(null);
                      // setCityListMenu([]);
                      // setStateListData(newValue.stateId);
                      setStateListData(newValue);
                      console.log('newValue', newValue);
                      // getCityList(newValue.stateId);
                      getCityList(e, newValue);
                    }}
                    getOptionLabel={(option) => option.stateName}
                    renderOption={(props, option) => <li {...props}>{option.stateName}</li>}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth placeholder="Select State" />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    City
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo2"
                    options={cityListMenu}
                    size="small"
                    onChange={(e, newValue) => {
                      // setCityListData(newValue.cityId);
                      setCityListData(newValue);
                      console.log('newValue', newValue);
                    }}
                    getOptionLabel={(option) => option.cityName}
                    renderOption={(props, option) => <li {...props}>{option.cityName}</li>}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth placeholder="Select City" />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Source
                  </Typography>
                  {sourceListData && sourceListData.length > 0 && (
                    <Select
                      fullWidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenu${selectSource}`
                      }}
                      value={selectSource}
                      // onChange={(e) => setSourceValue(e.target.value)}
                      onChange={(e) => handleSelectSource(e)}
                    >
                      <MenuItem value=" ">Select Source</MenuItem>
                      {sourceListData && sourceListData.length > 0
                        ? sourceListData.map((item, index) => {
                            console.log(item);
                            return (
                              <MenuItem value={item.typeId} key={index}>
                                {item.typeName}
                              </MenuItem>
                            );
                          })
                        : ''}
                    </Select>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Remark
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Type Remark"
                    value={remark}
                    onChange={(event) => setRemark(event.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="" textAlign="right">
            <Button variant="contained" className="mainBtn" onClick={(e) => handleSubmit(e)}>
              Save
            </Button>
          </Box>
          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Retry</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}
export default AddEditProspect;

// Top Cities
const topCities = [
  { label: 'Mumbai' },
  { label: 'Delhi' },
  { label: 'Bengaluru' },
  { label: 'Ahmedabad' },
  { label: 'Hyderabad' },
  { label: 'Chennai' },
  { label: 'Kolkata' },
  { label: 'Pune' },
  { label: 'Jaipur' },
  { label: 'Surat' },
  { label: 'Lucknow' },
  { label: 'Kanpur' },
  { label: 'Nagpur' },
  { label: 'Patna' },
  { label: 'Indore' },
  { label: 'Thane' },
  { label: 'Bhopal' },
  { label: 'Visakhapatnam' },
  { label: 'Vadodara' },
  { label: 'Firozabad' },
  { label: 'Ludhiana' },
  { label: 'Rajkot' },
  { label: 'Agra' },
  { label: 'Siliguri' },
  { label: 'Nashik' },
  { label: 'Faridabad' },
  { label: 'Patiala' },
  { label: 'Meerut' },
  { label: 'Varanasi' },
  { label: 'Dhanbad' },
  { label: 'Jodhpur' },
  { label: 'Amritsar' },
  { label: 'Raipur' },
  { label: 'Allahabad' },
  { label: 'Coimbatore' },
  { label: 'Jabalpur' },
  { label: 'Gwalior' },
  { label: 'Vijayawada' },
  { label: 'Madurai' },
  { label: 'Guwahati' },
  { label: 'Chandigarh' },
  { label: 'Hubli-Dharwad' },
  { label: 'Amroha' },
  { label: 'Moradabad' },
  { label: 'Gurgaon' },
  { label: 'Aligarh' },
  { label: 'Solapur' },
  { label: 'Ranchi' },
  { label: 'Jalandhar' },
  { label: 'Tiruchirappalli' },
  { label: 'Bhubaneswar' },
  { label: 'Salem' },
  { label: 'Warangal' },
  { label: 'Mira-Bhayandar' },
  { label: 'Thiruvananthapuram' },
  { label: 'Bhiwandi' },
  { label: 'Saharanpur' },
  { label: 'Guntur' },
  { label: 'Amravati' },
  { label: 'Bikaner' },
  { label: 'Noida' },
  { label: 'Jamshedpur' },
  { label: 'Bhilai Nagar' },
  { label: 'Cuttack' },
  { label: 'Kochi' },
  { label: 'Udaipur' },
  { label: 'Bhavnagar' },
  { label: 'Dehradun' },
  { label: 'Asansol' },
  { label: 'Nanded-Waghala' },
  { label: 'Ajmer' },
  { label: 'Jamnagar' },
  { label: 'Ujjain' },
  { label: 'Sangli' },
  { label: 'Loni' },
  { label: 'Jhansi' },
  { label: 'Pondicherry' },
  { label: 'Nellore' },
  { label: 'Jammu' },
  { label: 'Belagavi' },
  { label: 'Raurkela' },
  { label: 'Mangaluru' },
  { label: 'Tirunelveli' },
  { label: 'Malegaon' },
  { label: 'Gaya' },
  { label: 'Tiruppur' },
  { label: 'Davanagere' },
  { label: 'Kozhikode' },
  { label: 'Akola' },
  { label: 'Kurnool' },
  { label: 'Bokaro' },
  { label: 'Rajahmundry' },
  { label: 'Ballari' },
  { label: 'Agartala' },
  { label: 'Bhagalpur' },
  { label: 'Latur' },
  { label: 'Dhule' },
  { label: 'Korba' },
  { label: 'Bhilwara' },
  { label: 'Brahmapur' },
  { label: 'Mysore' },
  { label: 'Muzaffarpur' },
  { label: 'Ahmednagar' },
  { label: 'Kollam' },
  { label: 'Bilaspur' },
  { label: 'Shahjahanpur' },
  { label: 'Thrissur' },
  { label: 'Alwar' },
  { label: 'Kakinada' },
  { label: 'Nizamabad' },
  { label: 'Sagar' },
  { label: 'Tumkur' },
  { label: 'Hisar' },
  { label: 'Rohtak' },
  { label: 'Panipat' },
  { label: 'Darbhanga' },
  { label: 'Kharagpur' },
  { label: 'Aizawl' },
  { label: 'Ichalkaranji' },
  { label: 'Tirupati' },
  { label: 'Karnal' },
  { label: 'Bathinda' },
  { label: 'Rampur' },
  { label: 'Shivamogga' },
  { label: 'Ratlam' },
  { label: 'Modinagar' },
  { label: 'Durg' },
  { label: 'Shillong' },
  { label: 'Imphal' },
  { label: 'Hapur' },
  { label: 'Ranipet' },
  { label: 'Anantapur' },
  { label: 'Arrah' },
  { label: 'Karimnagar' },
  { label: 'Etawah' },
  { label: 'Bharatpur' },
  { label: 'Begusarai' },
  { label: 'Chhapra' },
  { label: 'Kadapa' },
  { label: 'Pali' },
  { label: 'Satna' },
  { label: 'Vizianagaram' },
  { label: 'Katihar' },
  { label: 'Hardwar' },
  { label: 'Sonipat' },
  { label: 'Nagercoil' },
  { label: 'Thanjavur' },
  { label: 'Murwara' },
  { label: 'Naihati' },
  { label: 'Sambhal' },
  { label: 'Nadiad' },
  { label: 'Yamunanagar' },
  { label: 'Eluru' },
  { label: 'Munger' },
  { label: 'Panchkula' },
  { label: 'Deoghar' },
  { label: 'Ongole' },
  { label: 'Nandyal' },
  { label: 'Morena' },
  { label: 'Bhiwani' },
  { label: 'Porbandar' },
  { label: 'Palakkad' },
  { label: 'Anand' },
  { label: 'Purnia' },
  { label: 'Baharampur' },
  { label: 'Barmer' },
  { label: 'Morvi' },
  { label: 'Orai' },
  { label: 'Bahraich' },
  { label: 'Vellore' },
  { label: 'Khammam' },
  { label: 'Mahesana' },
  { label: 'Silchar' },
  { label: 'Sambalpur' },
  { label: 'Rewa' },
  { label: 'Unnao' },
  { label: 'Hugli-Chinsurah' },
  { label: 'Raiganj' },
  { label: 'Adityapur' },
  { label: 'Alappuzha' },
  { label: 'Bahadurgarh' },
  { label: 'Machilipatnam' },
  { label: 'Rae Bareli' },
  { label: 'Jalpaiguri' },
  { label: 'Bharuch' },
  { label: 'Hoshiarpur' },
  { label: 'Baramula' },
  { label: 'Adoni' },
  { label: 'Jind' },
  { label: 'Tonk' },
  { label: 'Tenali' },
  { label: 'Kancheepuram' },
  { label: 'Vapi' },
  { label: 'Navsari' },
  { label: 'Mahbubnagar' },
  { label: 'Puri' },
  { label: 'Erode' },
  { label: 'Batala' },
  { label: 'Haldwani' },
  { label: 'Vidisha' },
  { label: 'Saharsa' },
  { label: 'Thanesar' },
  { label: 'Chittoor' },
  { label: 'Veraval' },
  { label: 'Lakhimpur' },
  { label: 'Sitapur' },
  { label: 'Hindupur' },
  { label: 'Santipur' },
  { label: 'Balurghat' },
  { label: 'Ganjbasoda' },
  { label: 'Moga' },
  { label: 'Srinagar' },
  { label: 'Medinipur' },
  { label: 'Habra' },
  { label: 'Sasaram' },
  { label: 'Hajipur' },
  { label: 'Bhuj' },
  { label: 'Shimla' },
  { label: 'Tiruvannamalai' },
  { label: 'Kaithal' },
  { label: 'Rajnandgaon' },
  { label: 'Godhra' },
  { label: 'Hazaribag' },
  { label: 'Bhimavaram' },
  { label: 'Mandsaur' },
  { label: 'Dibrugarh' },
  { label: 'Kolar' },
  { label: 'Bankura' },
  { label: 'Mandya' },
  { label: 'Dehri-on-Sone' },
  { label: 'Madanapalle' },
  { label: 'Malerkotla' },
  { label: 'Lalitpur' },
  { label: 'Bettiah' },
  { label: 'Pollachi' },
  { label: 'Khanna' },
  { label: 'Neemuch' },
  { label: 'Palwal' },
  { label: 'Palanpur' },
  { label: 'Guntakal' },
  { label: 'Nabadwip' },
  { label: 'Udupi' },
  { label: 'Jagdalpur' },
  { label: 'Motihari' },
  { label: 'Pilibhit' },
  { label: 'Dimapur' },
  { label: 'Mohali' },
  { label: 'Dharmavaram' },
  { label: 'Kashipur' },
  { label: 'Sivakasi' },
  { label: 'Darjiling' },
  { label: 'Chikkamagaluru' },
  { label: 'Gudivada' },
  { label: 'Adilabad' },
  { label: 'Yavatmal' },
  { label: 'Barnala' },
  { label: 'Nagaon' },
  { label: 'Narasaraopet' },
  { label: 'Raigarh' },
  { label: 'Roorkee' },
  { label: 'Valsad' },
  { label: 'Ambikapur' },
  { label: 'Giridih' },
  { label: 'Chandausi' },
  { label: 'Purulia' },
  { label: 'Patan' },
  { label: 'Bagaha' },
  { label: 'Hardoi' },
  { label: 'Achalpur' },
  { label: 'Osmanabad' },
  { label: 'Deesa' },
  { label: 'Nandurbar' },
  { label: 'Azamgarh' },
  { label: 'Ramgarh' },
  { label: 'Firozpur' },
  { label: 'Baripada' },
  { label: 'Karwar' },
  { label: 'Siwan' },
  { label: 'Anantnag' },
  { label: 'Tadpatri' },
  { label: 'Satara' },
  { label: 'Bhadrak' },
  { label: 'Kishanganj' },
  { label: 'Wardha' },
  { label: 'Amreli' },
  { label: 'Neyveli' },
  { label: 'Jamalpur' },
  { label: 'Marmagao' },
  { label: 'Udgir' },
  { label: 'Tadepalligudem' },
  { label: 'Nagapattinam' },
  { label: 'Buxar' },
  { label: 'Aurangabad' },
  { label: 'Jehanabad' },
  { label: 'Khair' },
  { label: 'Sawai Madhopur' },
  { label: 'Kapurthala' },
  { label: 'Chilakaluripet' },
  { label: 'Malappuram' },
  { label: 'Rewari' },
  { label: 'Nagaur' },
  { label: 'Sultanpur' },
  { label: 'Nagda' },
  { label: 'Port Blair' },
  { label: 'Lakhisarai' },
  { label: 'Panaji' },
  { label: 'Tinsukia' },
  { label: 'Itarsi' },
  { label: 'Kohima' },
  { label: 'Balangir' },
  { label: 'Nawada' },
  { label: 'Jharsuguda' },
  { label: 'Jagtial' },
  { label: 'Viluppuram' },
  { label: 'Amalner' },
  { label: 'Zirakpur' },
  { label: 'Tanda' },
  { label: 'Tiruchengode' },
  { label: 'Nagina' },
  { label: 'Yemmiganur' },
  { label: 'Vaniyambadi' },
  { label: 'Theni Allinagaram' },
  { label: 'Margao' },
  { label: 'Akot' },
  { label: 'Mhow' },
  { label: 'Kot Kapura' },
  { label: 'Kadiri' },
  { label: 'Najibabad' },
  { label: 'Nirmal' },
  { label: 'Udhagamandalam' },
  { label: 'Jhumri Tilaiya' },
  { label: 'Aruppukkottai' },
  { label: 'Jamui' },
  { label: 'Sitamarhi' },
  { label: 'Chirala' },
  { label: 'Anjar' },
  { label: 'Karaikal' },
  { label: 'Hansi' },
  { label: 'Anakapalle' },
  { label: 'Mahasamund' },
  { label: 'Faridkot' },
  { label: 'Dhoraji' },
  { label: 'Balaghat' },
  { label: 'Khambhat' },
  { label: 'Kavali' },
  { label: 'Dhamtari' },
  { label: 'Ashok Nagar' },
  { label: 'Bargarh' },
  { label: 'Kamareddy' },
  { label: 'Kothagudem' },
  { label: 'Ramanagaram' },
  { label: 'Gokak' },
  { label: 'Tikamgarh' },
  { label: 'Araria' },
  { label: 'Rishikesh' },
  { label: 'Daltonganj' },
  { label: 'Arakkonam' },
  { label: 'Washim' },
  { label: 'Sangrur' },
  { label: 'Bodhan' },
  { label: 'Fazilka' },
  { label: 'Palacole' },
  { label: 'Keshod' },
  { label: 'Wadhwan' },
  { label: 'Gurdaspur' },
  { label: 'Vatakara' },
  { label: 'Tura' },
  { label: 'Narnaul' },
  { label: 'Kharar' },
  { label: 'Yadgir' },
  { label: 'Ambejogai' },
  { label: 'Ankleshwar' },
  { label: 'Paradip' },
  { label: 'Virudhachalam' },
  { label: 'Kanhangad' },
  { label: 'Kadi' },
  { label: 'Gobindgarh' },
  { label: 'Tindivanam' },
  { label: 'Taliparamba' },
  { label: 'Tanuku' },
  { label: 'Virudhunagar' },
  { label: 'Koyilandy' },
  { label: 'Jorhat' },
  { label: 'Karur' },
  { label: 'Valparai' },
  { label: 'Neyyattinkara' },
  { label: 'Bapatla' },
  { label: 'Fatehabad' },
  { label: 'Sankarankovil' },
  { label: 'Tenkasi' },
  { label: 'Ratnagiri' },
  { label: 'Sikandrabad' },
  { label: 'Chaibasa' },
  { label: 'Chirmiri' },
  { label: 'Bhawanipatna' },
  { label: 'Kayamkulam' },
  { label: 'Nabha' },
  { label: 'Dhenkanal' },
  { label: 'Uran Islampur' },
  { label: 'Gopalganj' },
  { label: 'Bongaigaon' },
  { label: 'Tarn Taran' },
  { label: 'Barbil' },
  { label: 'Koratla' },
  { label: 'Arambagh' },
  { label: 'Gohana' },
  { label: 'Ladnu' },
  { label: 'Tamluk' },
  { label: 'Jagraon' },
  { label: 'Alirajpur' },
  { label: 'Tandur' },
  { label: 'Naidupet' },
  { label: 'Tirupathur' },
  { label: 'Tohana' },
  { label: 'Dhubri' },
  { label: 'Visnagar' },
  { label: 'Vrindavan' },
  { label: 'Nokha' },
  { label: 'Nagari' },
  { label: 'Narwana' },
  { label: 'Ujhani' },
  { label: 'Samastipur' },
  { label: 'Laharpur' },
  { label: 'Nimbahera' },
  { label: 'Diphu' },
  { label: 'Jhargram' },
  { label: 'Tilhar' },
  { label: 'Udumalaipettai' },
  { label: 'Wanaparthy' },
  { label: 'Gudur' },
  { label: 'Kendujhar' },
  { label: 'Mandi' },
  { label: 'Nedumangad' },
  { label: 'North Lakhimpur' },
  { label: 'Vinukonda' },
  { label: 'Tiptur' },
  { label: 'Gobichettipalayam' },
  { label: 'Wani' },
  { label: 'Upleta' },
  { label: 'Narasapuram' },
  { label: 'Nuzvid' },
  { label: 'Tezpur' },
  { label: 'Una' },
  { label: 'Sheopur' },
  { label: 'Thiruvarur' },
  { label: 'Lonavla' },
  { label: 'Ponnur' },
  { label: 'Kagaznagar' },
  { label: 'Gadwal' },
  { label: 'Bhatapara' },
  { label: 'Kandukur' },
  { label: 'Unjha' },
  { label: 'Lunglei' },
  { label: 'Karimganj' },
  { label: 'Kannur' },
  { label: 'Bobbili' },
  { label: 'Talegaon Dabhade' },
  { label: 'Anjangaon' },
  { label: 'Mangrol' },
  { label: 'Gangarampur' },
  { label: 'Thiruvallur' },
  { label: 'Tirur' },
  { label: 'Jatani' },
  { label: 'Viramgam' },
  { label: 'Rajsamand' },
  { label: 'Yanam' },
  { label: 'Kottayam' },
  { label: 'Dhuri' },
  { label: 'Namakkal' },
  { label: 'Kasaragod' },
  { label: 'Kunnamkulam' },
  { label: 'Umred' },
  { label: 'Bellampalle' },
  { label: 'Ottappalam' },
  { label: 'Dumraon' },
  { label: 'Jaggaiahpet' },
  { label: 'Goalpara' },
  { label: 'Tuni' },
  { label: 'Lachhmangarh' },
  { label: 'Bhongir' },
  { label: 'Amalapuram' },
  { label: 'Firozpur Cantt.' },
  { label: 'Vikarabad' },
  { label: 'Thiruvalla' },
  { label: 'Palghar' },
  { label: 'Jangaon' },
  { label: 'Bheemunipatnam' },
  { label: 'Panna' },
  { label: 'Thodupuzha' },
  { label: 'Arwal' },
  { label: 'Venkatagiri' },
  { label: 'Kalpi' },
  { label: 'Churu' },
  { label: 'Arsikere' },
  { label: 'Ozar' },
  { label: 'Thirumangalam' },
  { label: 'Nasirabad' },
  { label: 'Nanjangud' },
  { label: 'Forbesganj' },
  { label: 'Tundla' },
  { label: 'Bhadrachalam' },
  { label: 'Madhepura' },
  { label: 'Pithoragarh' },
  { label: 'Mathura' },
  { label: 'Sultanganj' },
  { label: 'Raxaul' },
  { label: 'Pilani' },
  { label: 'Mapusa' },
  { label: 'Sivaganga' },
  { label: 'Shahpur' },
  { label: 'Ramnagar' },
  { label: 'Rameshwaram' },
  { label: 'Perambalur' },
  { label: 'Manvi' },
  { label: 'Murshidabad' },
  { label: 'Mahe' },
  { label: 'Solan' },
  { label: 'Sanawad' },
  { label: 'Bulandshahr' },
  { label: 'Satana' },
  { label: 'Sanand' },
  { label: 'Sohna' },
  { label: 'Rudrapur' },
  { label: 'Perumbavoor' },
  { label: 'Raikot' },
  { label: 'Pauri' },
  { label: 'Shahganj' },
  { label: 'Mount Abu' },
  { label: 'Silvassa' },
  { label: 'Sikanderpur' },
  { label: 'Navi Mumbai' }
];
// Top States
const topStates = [
  { label: 'Andhra Pradesh' },
  { label: 'Arunachal Pradesh' },
  { label: 'Assam' },
  { label: 'Bihar' },
  { label: 'Chhattisgarh' },
  { label: 'Goa' },
  { label: 'Gujarat' },
  { label: 'Haryana' },
  { label: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir' },
  { label: 'Jharkhand' },
  { label: 'Karnataka' },
  { label: 'Kerala' },
  { label: 'Madhya Pradesh' },
  { label: 'Maharashtra' },
  { label: 'Manipur' },
  { label: 'Meghalaya' },
  { label: 'Mizoram' },
  { label: 'Nagaland' },
  { label: 'Odisha' },
  { label: 'Punjab' },
  { label: 'Rajasthan' },
  { label: 'Sikkim' },
  { label: 'Tamil Nadu' },
  { label: 'Telangana' },
  { label: 'Tripura' },
  { label: 'Uttarakhand' },
  { label: 'Uttar Pradesh' },
  { label: 'West Bengal' },
  { label: 'Andaman and Nicobar Islands' },
  { label: 'Chandigarh' },
  { label: 'Dadra and Nagar Haveli' },
  { label: 'Daman and Diu' },
  { label: 'Delhi' },
  { label: 'Lakshadweep' },
  { label: 'Puducherry' }
];
// Top Banks
const topBanks = [
  { label: 'Axis Bank' },
  { label: 'HDFC Bank' },
  { label: 'HDFC Ltd.' },
  { label: 'ICICI Bank' },
  { label: 'PNB Housing' },
  { label: 'Piramal Housing' },
  { label: 'Standard Chartered Bank' },
  { label: 'Yes Bank' },
  { label: 'Aditya Birla Housing Finance' },
  { label: 'IDFC Bank' },
  { label: 'RBL Bank' },
  { label: 'Federal Bank' },
  { label: 'Fedfina' },
  { label: 'HDB Financial' },
  { label: 'IIFL Finance' },
  { label: 'Tata Capital' },
  { label: 'ICICI Home Finance' },
  { label: 'Cholamandalam Finance' },
  { label: 'L&T Finance' },
  { label: 'Karur Vysya Bank' },
  { label: 'Hero Housing Finance' },
  { label: 'Kotak Mahindra Bank' },
  { label: 'Fullerton Grihashakti' },
  { label: 'Anand Rathi Financial' },
  { label: 'Aadhar Housing Finance' },
  { label: 'Godrej Housing Finance' },
  { label: 'Deutsche Bank' },
  { label: 'Jana Small Finance Bank' },
  { label: 'HSBC Bank' },
  { label: 'DCB Bank' },
  { label: 'Bank of Baroda' },
  { label: 'Bajaj Housing Finance' },
  { label: 'LIC Housing Finance' },
  { label: 'AU Small Finance Bank' },
  { label: 'Union Bank of India' },
  { label: 'State Bank of India' }
];
