import { useState } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  styled,
  TableContainer,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  tableCellClasses,
  Autocomplete
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { BiCloudUpload, BiCloudDownload } from 'react-icons/bi';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import Label from '../../components/Label';
import SearchNotFound from '../../components/SearchNotFound';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import { DateRangeStyled } from '../Global/Styling';
import { fDate } from '../../utils/formatTime';
import USERLIST from '../../_mocks_/user';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';

const TABLE_HEAD = [
  { id: 'bankName', label: 'Bank Name', alignRight: false },
  { id: 'id', label: 'File No', alignRight: false },
  { id: 'bankName', label: 'Branch Name', alignRight: false },
  { id: 'created', label: 'Request Date', alignRight: false },
  { id: 'name', label: 'Borrower Name', alignRight: false },
  { id: 'role', label: 'Emp Class', alignRight: false },
  { id: 'loanType', label: 'Loan Product Type', alignRight: false },
  { id: 'amount', label: 'Loan Amount', alignRight: false }
  // { id: '', label: 'ACTION' }
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */

function UploadBulkFileReceipt() {
  const navigate = useNavigate();
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  const [selectStatus, setSelectStatus] = useState(' ');
  const [selectDate, setSelectDate] = useState(' ');
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const [value, setValue] = useState(0);

  return (
    <Page title="Partner Management System || Revenue">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Upload Files" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            File Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Bank
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo2"
                    options={topBanks}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth placeholder="Select Bank" />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Source
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Source"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Source Date
                  </Typography>
                  <TextField
                    fullWidth
                    type="date"
                    placeholder="Select Source Date"
                    inputProps={{
                      className: `textInput dissabledMenu${selectDate}`
                    }}
                    value={selectDate}
                    onChange={(e) => setSelectDate(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    File Type
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${selectStatus}`
                    }}
                    value={selectStatus}
                    onChange={(e) => setSelectStatus(e.target.value)}
                  >
                    <MenuItem value=" " disabled>
                      Select File Type
                    </MenuItem>
                    <MenuItem value="Receipt">Receipt</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Disburshed">Disbursed</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ position: 'relative' }}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Period
                  </Typography>
                  <Box className="displayDateRange">
                    {filterDatesRange[0].startDate !== null
                      ? `${fDate(filterDatesRange[0].startDate)} to `
                      : ''}
                    {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
                    {filterDatesRange[0].startDate === null &&
                    filterDatesRange[0].endDate === null ? (
                      <Typography className="dissabledMenu">Select Date Range</Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    MenuProps={{
                      className: 'menuOpened'
                    }}
                    value=" "
                    className="datesSelect"
                  >
                    <DateRangeStyled
                      editableDateInputs={false}
                      onChange={(item) => setFilterDatesRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={filterDatesRange}
                      className="dateRangePicker"
                    />
                  </Select>
                </Box>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs="10" mb={3}>
                <Box variant="Container" className="uploadBox">
                  <label htmlFor="contained-button-file" onChange={handleChange}>
                    <Input accept="image/*" id="contained-button-file" multiple type="file" />
                    <Box>
                      <BiCloudUpload className="upladIcon" />
                      <Typography variant="h5">Upload</Typography>
                    </Box>
                  </label>
                </Box>
              </Grid>
              <Grid xs="2" item mb={3} sx={{ flexGrow: '1' }}>
                <Button className="mainBtn downloadBtn" variant="contained">
                  <BiCloudDownload className="downloadIcon" />
                  <Typography variant="subtitle1">
                    Download <br />
                    Template
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {/* <img src={file} alt="upload Preview" className="uploadPreview" /> */}
            {/* <div className="App">
              <img src={file} alt="upload" />
            </div> */}
            <Card className="greenBorder">
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={USERLIST.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      // isCheckBox="false"
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const {
                            routingNumber,
                            invoiceNo,
                            id,
                            name,
                            leadId,
                            amount,
                            company,
                            role,
                            noofcalls,
                            name2,
                            city,
                            bankName,
                            partnerName,
                            transactionType,
                            status,
                            segment,
                            active,
                            created,
                            partnerType,
                            loanType,
                            statusInvoice,
                            verifiedStatus,
                            monthName,
                            misStatus,
                            users
                          } = row;
                          console.log('users', users);
                          const isItemSelected = selected.indexOf(name) !== -1;

                          return (
                            <StyledTableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <StyledTableCell align="left">{bankName}</StyledTableCell>
                              <StyledTableCell align="left">{id}</StyledTableCell>
                              <StyledTableCell align="left">{city}</StyledTableCell>
                              <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                              <StyledTableCell align="left">{name}</StyledTableCell>
                              <StyledTableCell align="left">{role}</StyledTableCell>
                              <StyledTableCell align="left">{loanType}</StyledTableCell>
                              <StyledTableCell align="left">{amount}</StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <StyledTableCell colSpan={13} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Box>
          <Box className="" textAlign="right">
            <Button variant="contained" className="mainBtn" onClick={(e) => navigate('/revenue')}>
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
export default UploadBulkFileReceipt;

// Top Banks
const topBanks = [
  { label: 'Axis Bank' },
  { label: 'HDFC Bank' },
  { label: 'HDFC Ltd.' },
  { label: 'ICICI Bank' },
  { label: 'PNB Housing' },
  { label: 'Piramal Housing' },
  { label: 'Standard Chartered Bank' },
  { label: 'Yes Bank' },
  { label: 'Aditya Birla Housing Finance' },
  { label: 'IDFC Bank' },
  { label: 'RBL Bank' },
  { label: 'Federal Bank' },
  { label: 'Fedfina' },
  { label: 'HDB Financial' },
  { label: 'IIFL Finance' },
  { label: 'Tata Capital' },
  { label: 'ICICI Home Finance' },
  { label: 'Cholamandalam Finance' },
  { label: 'L&T Finance' },
  { label: 'Karur Vysya Bank' },
  { label: 'Hero Housing Finance' },
  { label: 'Kotak Mahindra Bank' },
  { label: 'Fullerton Grihashakti' },
  { label: 'Anand Rathi Financial' },
  { label: 'Aadhar Housing Finance' },
  { label: 'Godrej Housing Finance' },
  { label: 'Deutsche Bank' },
  { label: 'Jana Small Finance Bank' },
  { label: 'HSBC Bank' },
  { label: 'DCB Bank' },
  { label: 'Bank of Baroda' },
  { label: 'Bajaj Housing Finance' },
  { label: 'LIC Housing Finance' },
  { label: 'AU Small Finance Bank' },
  { label: 'Union Bank of India' },
  { label: 'State Bank of India' }
];
