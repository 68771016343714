import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  styled
} from '@mui/material';
import { Box } from '@mui/system';
// other icons
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// react icons
import { BsWindowDock, BsCardHeading, BsTelephone } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineIdcard } from 'react-icons/ai';
import { RiBankCardLine } from 'react-icons/ri';
import { FaRegAddressCard, FaRegUser } from 'react-icons/fa';
import { CgCalendarDates } from 'react-icons/cg';
import { HiOutlineMail } from 'react-icons/hi';
import { SiOpensourceinitiative } from 'react-icons/si';
import { GiSwapBag, GiModernCity } from 'react-icons/gi';
import { MdIncompleteCircle, MdOutlineDateRange } from 'react-icons/md';
import { BiCloudUpload, BiCloudDownload, BiBoltCircle } from 'react-icons/bi';
// material icons
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import UpdateIcon from '@mui/icons-material/Update';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InterestsIcon from '@mui/icons-material/Interests';
import SourceIcon from '@mui/icons-material/Source';
import DateRangeIcon from '@mui/icons-material/DateRange';
import GroupIcon from '@mui/icons-material/Group';
import { fDate } from '../../utils/formatTime';
// components
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
// Api call
import loginListAPI from '../services/loginListApplication';

const { LoginViewReport } = loginListAPI;

function ViewLogin() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');
  // activityLog Const
  const [showUDoc, setShowUDoc] = useState(false);
  const [showULogIn, setUShowLogIn] = useState(false);
  const [showUSanction, setUSanction] = useState(false);
  const [showSanctioned, setShowSanctioned] = useState(false);
  const [showUDisbursement, setShowUDisbursement] = useState(false);
  const [showDisbursed, setShowDisbursed] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [showQuery, setShowQuery] = useState(false);
  const [showRejected, setShowRejected] = useState(false);
  const [showLogin, setShowLogIn] = useState(false);
  const [chequeHandover, setChequeHandover] = useState(false);
  const [encashment, setEncashment] = useState(false);
  const [loginViewReport, setLoginViewReport] = useState('');
  const [credGbleObj, setCredGbleObj] = useState({
    token: '',
    companyId: ''
  });
  // console.log('loginViewReport', loginViewReport);

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  // Styled icon button in Action
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
      padding: '6px',
      height: '42.5px',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#0d4689'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: '4px 6px',
      height: '42.5px'
    }
  }));
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const token = localJWTtoken.token.jwt;
    const companyId = localJWTtoken.token.companyId;
    const userId = localJWTtoken.token.userId;
    const localJWTtoken1 = JSON.parse(localStorage.getItem('loginLeadId'));
    // setCredGbleObj({
    //   token: token,
    //   companyId: companyId
    // });
    (async () => {
      const credential = {
        companyId: companyId,
        userId: userId,
        leadId: localJWTtoken1
      };
      const ifFiredLoginViewReportAPI = await LoginViewReport(credential, token);
      console.log('credential', credential);
      console.log('ifFiredLoginViewReportAPI', ifFiredLoginViewReportAPI);
      if (ifFiredLoginViewReportAPI.status === 'success') {
        setLoginViewReport(ifFiredLoginViewReportAPI.data);
      }
    })();
  }, []);

  return (
    <Page title="PULSE by Real Value Finloans || Log In">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="View Login" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Application Details
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <StayCurrentPortraitIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Current Stage
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {loginViewReport.applicationDetails !== undefined
                          ? loginViewReport.applicationDetails.currentStage
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <FaRegAddressCard className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Customer ID
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {loginViewReport.applicationDetails !== undefined
                          ? loginViewReport.applicationDetails.customerId
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <MdOutlineDateRange className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Current Status
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {loginViewReport.applicationDetails !== undefined
                          ? loginViewReport.applicationDetails.currentStatus
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <AppRegistrationIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Lead/Application ID
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {loginViewReport.applicationId !== undefined
                          ? loginViewReport.applicationId
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <UpdateIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Last Update Date
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {loginViewReport.applicationDetails !== undefined
                          ? loginViewReport.applicationDetails.lastUpdatedOn
                            ? fDate(loginViewReport.applicationDetails.lastUpdatedOn)
                            : 'N/A'
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <AccountBalanceIcon className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Bank
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        {loginViewReport.applicationDetails !== undefined
                          ? loginViewReport.applicationDetails.bankName !== undefined
                            ? loginViewReport.applicationDetails.bankName
                            : 'N/A'
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs="12" sm="6" md="6" mb={3}>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Loan Requirement
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin">
                  <Grid container spacing={3}>
                    {/* <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.loanRequirement !== undefined
                              ? loginViewReport.loanRequirement.customerName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            First Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.loanRequirement !== undefined
                              ? loginViewReport.loanRequirement.customerFirstName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Middle Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.loanRequirement !== undefined
                              ? loginViewReport.loanRequirement.customerMiddleName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Last Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.loanRequirement !== undefined
                              ? loginViewReport.loanRequirement.customerLastName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <BsTelephone className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Phone
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.loanRequirement !== undefined
                              ? loginViewReport.loanRequirement.mobile
                                ? loginViewReport.loanRequirement.mobile
                                : 'N/A'
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <HiOutlineMail className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Email
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.loanRequirement !== undefined
                              ? loginViewReport.loanRequirement.emailAddress
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <InterestsIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Interested in
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.loanRequirement !== undefined
                              ? loginViewReport.loanRequirement.loanTypeName
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiSwapBag className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Amount
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.loanRequirement !== undefined
                              ? loginViewReport.loanRequirement.loanAmountRequested
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiModernCity className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            City
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.loanRequirement !== undefined
                              ? loginViewReport.loanRequirement.cityName
                                ? loginViewReport.loanRequirement.cityName
                                : 'N/A'
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs="12" sm="6" md="6" mb={3}>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Lead Source
                </Typography>
                <Box className="viewLeadCard cardTopMinusMargin">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <DateRangeIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Lead Date
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.leadSource !== undefined
                              ? new Date(loginViewReport.leadSource.leadDate).toLocaleDateString()
                              : ''}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <SourceIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Lead Source
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.leadSource !== undefined
                              ? loginViewReport.leadSource.leadSource
                              : ''}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GroupIcon className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Partner Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            N/A
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Sales Person
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            {loginViewReport.leadSource !== undefined
                              ? loginViewReport.leadSource.salesPerson
                              : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {/* <Box className="formRowHolder">
              <Button variant="contained" className="mainBtn">
                Submit
              </Button>
            </Box> */}
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Activity Log
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin">
              <Box container>
                <Box className="statusBox">
                  <Typography variant="body2">Current Status:</Typography>
                  <Typography variant="subtitle2" className="disbursedText">
                    {/* Leads (U-Doc) */}
                    {loginViewReport.activityLog &&
                      loginViewReport.activityLog.currentStageStatusName}
                  </Typography>
                </Box>
              </Box>
              <Box className="timelineB">
                {/* {console.log('loginViewReportactivityLog', loginViewReport)}
                    {console.log('loginViewReportactivityLog', loginViewReport.activityLog)} */}
                {loginViewReport.activityLog !== undefined &&
                loginViewReport.activityLog.stages.length > 0
                  ? loginViewReport.activityLog.stages.map((item, index) => {
                      console.log();
                      return (
                        <>
                          {item.stageName === 'U-Doc' && (
                            <Box className="timelineContentB timelineContentBGreen">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setShowUDoc(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'U-Log in' && (
                            <Box className="timelineContentB timelineContentBGreen">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setUShowLogIn(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'U-Sanction' && (
                            <Box className="timelineContentB timelineContentBOrange">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setUSanction(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'Sanctioned' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setShowSanctioned(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'U-Disbursement' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setShowUDisbursement(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'Disbursed' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setShowDisbursed(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'Closed' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setShowClosed(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'Query' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {new Date(item.startDate).toLocaleDateString() !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setShowQuery(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'Rejected' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setShowRejected(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'Log-in ' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setShowLogIn(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'Cheque Handover' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setChequeHandover(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                          {item.stageName === 'Encashment' && (
                            <Box className="timelineContentB">
                              <Typography variant="subtitle1" className="date">
                                {item.stageName}
                              </Typography>
                              <Typography variant="body2" className="">
                                Start Date:{' '}
                                <strong>
                                  {item.startDate !== undefined
                                    ? new Date(item.startDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Typography variant="body2" className="" mb={1}>
                                End Date:{' '}
                                <strong>
                                  {item.endtDate !== undefined
                                    ? new Date(item.endDate).toLocaleDateString()
                                    : 'N/A'}
                                </strong>
                              </Typography>
                              <Button
                                className="mainBtnSmall"
                                disabled={Object.values(item.more).length === 0}
                                onClick={(e) => {
                                  setEncashment(true);
                                }}
                              >
                                More
                              </Button>
                            </Box>
                          )}
                        </>
                      );
                    })
                  : ''}
              </Box>
              {/* <Box className="timelineB">
                <Box className="timelineContentB timelineContentBGreen">
                  <Typography variant="subtitle1" className="date">
                    U-Log In
                  </Typography>
                  <Typography variant="body2" className="">
                    Start Date: <strong>27 April, 2022</strong>
                  </Typography>
                  <Typography variant="body2" className="" mb={1}>
                    End Date: <strong>30 April, 2022</strong>
                  </Typography>
                  <Button
                    className="mainBtnSmall"
                    onClick={(e) => {
                      setShowProspectDetail(true);
                      setShowCallCenterDetail(false);
                      setShowLeadsDetail(false);
                    }}
                  >
                    More
                  </Button>
                </Box>
                <Box className="timelineContentB timelineContentBGreen">
                  <Typography variant="subtitle1" className="date">
                    Log In
                  </Typography>
                  <Typography variant="body2" className="">
                    Start Date: <strong>27 April, 2022</strong>
                  </Typography>
                  <Typography variant="body2" className="" mb={1}>
                    End Date: <strong>30 April, 2022</strong>
                  </Typography>
                  <Button
                    className="mainBtnSmall"
                    onClick={(e) => {
                      setShowProspectDetail(false);
                      setShowCallCenterDetail(true);
                      setShowLeadsDetail(false);
                    }}
                  >
                    More
                  </Button>
                </Box>
                <Box className="timelineContentB timelineContentBOrange">
                  <Typography variant="subtitle1" className="date">
                    U-Sanction
                  </Typography>
                  <Typography variant="body2" className="">
                    Start Date: <strong>27 April, 2022</strong>
                  </Typography>
                  <Typography variant="body2" className="" mb={1}>
                    End Date: <strong>30 April, 2022</strong>
                  </Typography>
                  <Button
                    className="mainBtnSmall"
                    onClick={(e) => {
                      setShowProspectDetail(false);
                      setShowCallCenterDetail(false);
                      setShowLeadsDetail(true);
                    }}
                  >
                    More
                  </Button>
                </Box>
                <Box className="timelineContentB">
                  <Typography variant="subtitle1" className="date">
                    Sanction
                  </Typography>
                </Box>
                <Box className="timelineContentB">
                  <Typography variant="subtitle1" className="date">
                    U-Disbursement
                  </Typography>
                </Box>
                <Box className="timelineContentB">
                  <Typography variant="subtitle1" className="date">
                    Disbursed
                  </Typography>
                </Box>
                <Box className="timelineContentB">
                  <Typography variant="subtitle1" className="date">
                    Query from Bank
                  </Typography>
                </Box>
                <Box className="timelineContentB">
                  <Typography variant="subtitle1" className="date">
                    Rejected
                  </Typography>
                </Box>
              </Box> */}

              {loginViewReport.activityLog !== undefined &&
              loginViewReport.activityLog.stages.length > 0
                ? loginViewReport.activityLog.stages.map((item, index) => {
                    console.log();
                    return (
                      <>
                        {showUDoc && item.stageName === 'U-Doc' ? (
                          <Box className="viewLeadCard" mt={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Prospect ID
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Campaign Name
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Source
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Leads Assigned on
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Assigned by
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Lead Generated on
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Lead Qualified on
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Lead Qualified by
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Lead Status
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Agents Comments
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Sales Person
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    &nbsp;
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        ) : null}

                        {showULogIn && item.stageName === 'U-Log in' ? (
                          <Box className="viewLeadCard" mt={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Prospect ID
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    {item.more.prospectId !== undefined
                                      ? item.more.prospectId
                                      : 'N/A'}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Campaign Name
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    {item.more.campaignName !== null
                                      ? item.more.campaignName
                                      : 'N/A'}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Source
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    {item.more.leadSource !== undefined
                                      ? item.more.leadSource
                                      : 'N/A'}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Leads Assigned on
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    N/A
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Assigned by
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    N/A
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Lead Generated on
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    N/A
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Lead Qualified on
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    N/A
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Lead Qualified by
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    N/A
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Lead Status
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    N/A
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Agents Comments
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    N/A
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="">
                                  <Typography variant="body2" className="labelText">
                                    Sales Person
                                  </Typography>
                                  <Typography variant="subtitle2" className="labelValue">
                                    {item.more.salesPerson !== undefined
                                      ? item.more.salesPerson
                                      : 'N/A'}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        ) : null}
                        {showUSanction && item.stageName === 'U-Sanction' ? (
                          <Box className="greenBorder tableBox" mt={3}>
                            <TableContainer
                              sx={{ minWidth: 800, overflow: 'auto', background: 'white' }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Comments</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <StyledTableRow>
                                    <StyledTableCell>27 April, 2022</StyledTableCell>
                                    <StyledTableCell>Document Pending</StyledTableCell>
                                  </StyledTableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        ) : null}
                      </>
                    );
                  })
                : ''}
            </Box>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
export default ViewLogin;
