import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
//
/**
 * @module PartnerServiceService
 * @returns  it return All DataSource Apis
 */

const globalService = {
  // common apis
  AccessLevel: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/access-level/configs`, credential, token)
};

export default globalService;
