import React, { useEffect, useState } from 'react';
import {
  Step,
  Stepper,
  StepLabel,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  Tab,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  DialogTitle,
  FormLabel,
  FormControlLabel,
  Card,
  Autocomplete,
  ButtonGroup
} from '@mui/material';
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';
import { IoIosAlert } from 'react-icons/io';

// table component
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ImFolderUpload } from 'react-icons/im';
import { MdOutlinePreview, MdUploadFile, MdUpload, MdOutlineAdd } from 'react-icons/md';
import { BiCloudUpload } from 'react-icons/bi';
import { DiGoogleDrive } from 'react-icons/di';
import { AiOutlineDropbox } from 'react-icons/ai';
import { CgDigitalocean } from 'react-icons/cg';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { FaRegUser } from 'react-icons/fa';
import add from '@iconify/icons-ic/add';
import { Icon } from '@iconify/react';

// import { CloseIcon } from 'react-icons'
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import pages
import { DateRangeStyled } from '../Global/Styling';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import { fDate } from '../../utils/formatTime';

import partnerService from '../services/partnerService';
import AgreementService from '../services/AgreementService';
import prospectService from '../services/prospectService';

const { Addpolicyaction } = AgreementService;
const { EmploymentTypes } = prospectService;
const { StateList, CityList, BankList, LoanTypes } = partnerService;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '6px',
    height: '42.5px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#0d4689'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '4px 6px',
    height: '42.5px'
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));
function AddPolicy(parent) {
  const navigate = useNavigate();
  const [token1, setToken1] = useState('');
  const [bankListData, setBankListData] = useState([]);
  const [userDetails1, setUserDetails1] = useState({});
  const [open, setOpen] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [loanTypeItem, setLoanTypeItem] = useState([]);
  const [employmentTypeData, setEmploymentTypeData] = useState([]);
  const [agreementId, setAgreementId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [userId, setUserId] = useState('');
  const [bankNamePolicy, setBankNamePolicy] = useState('');
  const [newpolicyData, setNewpolicyData] = useState({
    aggrementPolicyId: null,
    bankId: null,
    bankName: null,
    aggrementPolicyCode: null,
    aggrementPolicyName: null,
    loanTypeId: null,
    loanTypeName: ' ',
    loanSubTypeId: null,
    loanSubTypeName: ' ',
    ageMin: null,
    ageMax: null,
    employmentTypeId: null,
    employmentTypeName: ' ',
    minCibilScore: null,
    collateralRequest: ' ',
    interestRateMin: null,
    interestRateMax: null,
    preProcessingCharge: null,
    downPayment: null,
    loanToValueRatio: null,
    policyStartDate: null,
    policyEndDate: null
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
    const authToken = localJWTtoken.token.jwt;
    setToken1(authToken);
    setCompanyId(localJWTtoken.token.companyId);
    setUserId(localJWTtoken.token.userId);

    const localJWTtoken2 = JSON.parse(localStorage.getItem('viewAgreementId'));
    setAgreementId(localJWTtoken2);

    const localJWTtoken3 = JSON.parse(localStorage.getItem('bankNameDetails'));
    setBankNamePolicy(localJWTtoken3);
    setNewpolicyData({
      ...newpolicyData,
      bankName: localJWTtoken3.bankName,
      bankId: localJWTtoken3.bankId
    });

    const authUserDetails = localJWTtoken.token;
    setUserDetails1(authUserDetails);
    firebanklistapi(authToken);
    fireloantypeapi(authToken);
    fireemploymenttypeapi(authToken);
  }, []);

  const firebanklistapi = async (authToken) => {
    const ifPreviewDataBank = await BankList(authToken);
    if (ifPreviewDataBank.status === 'success') {
      setBankListData(ifPreviewDataBank.data.banks);
    } else if (ifPreviewDataBank.status === 'error') {
      navigate('/');
    }
  };

  const fireloantypeapi = async (authToken) => {
    const ifPreviewDataBank = await LoanTypes(authToken);
    if (ifPreviewDataBank.status === 'success') {
      setLoanTypeItem(ifPreviewDataBank.data.loanTypes);
    } else if (ifPreviewDataBank.status === 'error') {
      navigate('/');
    }
  };

  const fireemploymenttypeapi = async (authToken) => {
    const EmploymentTypesApiResponse = await EmploymentTypes(authToken);
    if (EmploymentTypesApiResponse.status === 'success') {
      setEmploymentTypeData(EmploymentTypesApiResponse.data.combinedEmploymentTypes);
    } else if (EmploymentTypesApiResponse.status === 'error') {
      setEmploymentTypeData([]);
      navigate('/');
    }
  };

  const handleAddPolicy = async () => {
    let allow = true;
    if (newpolicyData.bankName === null) {
      setOpen(true);
      setValidationMsg('Please select bank name');
      allow = false;
      return false;
    }
    if (newpolicyData.aggrementPolicyName === null) {
      setOpen(true);
      setValidationMsg('Please select policy Name');
      allow = false;
      return false;
    }
    if (newpolicyData.loanTypeName === ' ') {
      setOpen(true);
      setValidationMsg('Please select loan type');
      allow = false;
      return false;
    }
    if (newpolicyData.loanSubTypeName === ' ') {
      setOpen(true);
      setValidationMsg('Please select loan sub type');
      allow = false;
      return false;
    }
    if (newpolicyData.ageMax === null) {
      setOpen(true);
      setValidationMsg('Please enter minimum age');
      return false;
    }
    if (newpolicyData.ageMax === null) {
      setOpen(true);
      setValidationMsg('Please enter maximum age');
      allow = false;
      return false;
    }
    if (newpolicyData.employmentTypeName === ' ') {
      setOpen(true);
      setValidationMsg('Please select employment type');
      return false;
    }
    if (newpolicyData.minCibilScore === null) {
      setOpen(true);
      setValidationMsg('Please enter cibil score');
      allow = false;
      return false;
    }
    if (newpolicyData.collateralRequest === ' ') {
      setOpen(true);
      setValidationMsg('Please select collatreal request');
      allow = false;
      return false;
    }
    if (newpolicyData.interestRateMin === null) {
      setOpen(true);
      setValidationMsg('Please enter minimum interest rate');
      return false;
    }
    if (newpolicyData.interestRateMax === null) {
      setOpen(true);
      setValidationMsg('Please enter maximum interest rate');
      allow = false;
      return false;
    }
    if (newpolicyData.preProcessingCharge === null) {
      setOpen(true);
      setValidationMsg('Please enter pre processing charge');
      allow = false;
      return false;
    }
    if (newpolicyData.downPayment === null) {
      setOpen(true);
      setValidationMsg('Please enter down payment');
      allow = false;
      return false;
    }
    if (newpolicyData.loanToValueRatio === null) {
      setOpen(true);
      setValidationMsg('Please enter loan to view ratio');
      allow = false;
      return false;
    }
    if (newpolicyData.policyStartDate === null) {
      setOpen(true);
      setValidationMsg('Please enter start date');
      allow = false;
      return false;
    }
    if (newpolicyData.policyEndDate === null) {
      setOpen(true);
      setValidationMsg('Please enter end date');
      allow = false;
      return false;
    }

    if (allow === true) {
      if (newpolicyData.collateralRequest === 'Yes') {
        newpolicyData.collateralRequest = true;
      } else {
        newpolicyData.collateralRequest = false;
      }

      console.log('final data ', newpolicyData, token1);
      const ifPreviewDataBank = await Addpolicyaction(
        {
          ...newpolicyData,
          userId: userId,
          companyId: companyId,
          aggrementId: agreementId
        },
        token1
      );
      if (ifPreviewDataBank.status === 'success') {
        clearData();
        navigate('/agreement/policy');
      } else if (ifPreviewDataBank.status === 'error') {
        navigate('/');
      }
    }
  };

  const clearData = () => {
    setNewpolicyData({
      userId: null,
      companyId: null,
      agreementId: null,
      aggrementPolicyId: null,
      bankId: null,
      bankName: null,
      aggrementPolicyCode: null,
      aggrementPolicyName: null,
      loanTypeId: null,
      loanTypeName: ' ',
      loanSubTypeId: null,
      loanSubTypeName: ' ',
      ageMin: null,
      ageMax: null,
      employmentTypeId: null,
      employmentTypeName: ' ',
      minCibilScore: null,
      collateralRequest: ' ',
      interestRateMin: null,
      interestRateMax: null,
      preProcessingCharge: null,
      downPayment: null,
      loanToValueRatio: null,
      policyStartDate: null,
      policyEndDate: null
    });
  };

  // const fireApi = async (data) => {
  //   setNewpolicyData({
  //     ...newpolicyData,
  //     userId: userId,
  //     companyId: companyId,
  //     agreementId: agreementId,
  //     aggrementPolicyId: null,
  //     bankId: 410
  //   });

  //   if (data.collateralRequest === 'Yes') {
  //     data.collateralRequest = true;
  //   } else {
  //     data.collateralRequest = false;
  //   }

  //   console.log('final data ', data, token1);
  //   const ifPreviewDataBank = await Addpolicyaction(data, token1);
  //   if (ifPreviewDataBank.status === 'success') {
  //     clearData();
  //     navigate('/agreement/policy');
  //   } else if (ifPreviewDataBank.status === 'error') {
  //     navigate('/');
  //   }
  // };

  return (
    <Page title="Partner Management System || Agreement">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Add Policy" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Policy Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid item container spacing={{ xs: 1, sm: 2, md: 3 }}>
              {/* <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Bank Name
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo2"
                  options={bankListData}
                  size="small"
                  getOptionLabel={(option) =>
                    option.bankName ? option.bankName : newpolicyData.bankName
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth placeholder="Select Bank Name" />
                  )}
                  value={newpolicyData.bankName}
                  onChange={(e, newValue) => {
                    console.log('newValue22', newValue);
                    setNewpolicyData({
                      ...newpolicyData,
                      bankName: newValue.bankName,
                      bankId: newValue.bankId
                    });
                  }}
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Bank Name
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Bank Name"
                  value={newpolicyData.bankName}
                  disabled
                  onChange={(e) => setNewpolicyData({ ...newpolicyData, bankName: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Policy Name
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Scheme Name"
                  value={newpolicyData.aggrementPolicyName}
                  onChange={(e) =>
                    setNewpolicyData({ ...newpolicyData, aggrementPolicyName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Loan Type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${newpolicyData.loanTypeName}`
                  }}
                  value={newpolicyData.loanTypeName}
                  onChange={(e) => {
                    setNewpolicyData({
                      ...newpolicyData,
                      loanTypeName: e.target.value,
                      loanTypeId: e.target.value
                    });
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Loan Type
                  </MenuItem>
                  {loanTypeItem.map((item, index) => (
                    <MenuItem value={item.loanId} key={index}>
                      {item.loanName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Loan Sub Type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${newpolicyData.loanSubTypeName}`
                  }}
                  value={newpolicyData.loanSubTypeName}
                  onChange={(e) => {
                    setNewpolicyData({ ...newpolicyData, loanSubTypeName: e.target.value });
                  }}
                >
                  <MenuItem value=" " disabled>
                    Select Loan Sub Type
                  </MenuItem>
                  <MenuItem value="Fresh Home Loan">Fresh Home Loan</MenuItem>
                  <MenuItem value="Loan Against Property">Loan Against Property</MenuItem>
                  <MenuItem value="Balance Transfer">Balance Transfer</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Age
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <TextField
                      type="number"
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Min"
                      value={newpolicyData.ageMin}
                      onChange={(e) =>
                        setNewpolicyData({ ...newpolicyData, ageMin: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      type="number"
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Max"
                      value={newpolicyData.ageMax}
                      onChange={(e) =>
                        setNewpolicyData({ ...newpolicyData, ageMax: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Employment Type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${newpolicyData.employmentTypeName}`
                  }}
                  value={newpolicyData.employmentTypeName}
                  onChange={(e) =>
                    setNewpolicyData({
                      ...newpolicyData,
                      employmentTypeName: e.target.value,
                      employmentTypeId: e.target.value
                    })
                  }
                >
                  <MenuItem value=" " disabled>
                    Select Employment Type
                  </MenuItem>

                  {employmentTypeData.map((item, index) => {
                    console.log(item);
                    return <MenuItem value={item.typeId}>{item.typeName}</MenuItem>;
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Cibil Score(Min)
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Cibil Score"
                  value={newpolicyData.minCibilScore}
                  onChange={(e) =>
                    setNewpolicyData({ ...newpolicyData, minCibilScore: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Collatreal Request
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${newpolicyData.collateralRequest}`
                  }}
                  value={newpolicyData.collateralRequest}
                  onChange={(e) =>
                    setNewpolicyData({ ...newpolicyData, collateralRequest: e.target.value })
                  }
                >
                  <MenuItem value=" " disabled>
                    Select Collatreal Request
                  </MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Interest Rate
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <TextField
                      type="number"
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Min"
                      value={newpolicyData.interestRateMin}
                      onChange={(e) =>
                        setNewpolicyData({
                          ...newpolicyData,
                          interestRateMin: e.target.value
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      type="number"
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Max"
                      value={newpolicyData.interestRateMax}
                      onChange={(e) =>
                        setNewpolicyData({
                          ...newpolicyData,
                          interestRateMax: e.target.value
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Pre Processing Charge
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Pre Processing Charge"
                  value={newpolicyData.preProcessingCharge}
                  onChange={(e) =>
                    setNewpolicyData({
                      ...newpolicyData,
                      preProcessingCharge: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Down Payment
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Down Payment"
                  value={newpolicyData.downPayment}
                  onChange={(e) =>
                    setNewpolicyData({ ...newpolicyData, downPayment: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Loan to Value Ratio
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Loan to Value Ratio"
                  value={newpolicyData.loanToValueRatio}
                  onChange={(e) =>
                    setNewpolicyData({ ...newpolicyData, loanToValueRatio: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  Start Date
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Start Date"
                  value={newpolicyData.policyStartDate}
                  onChange={(e) =>
                    setNewpolicyData({ ...newpolicyData, policyStartDate: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" className="formLabel">
                  End Date
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type End Date"
                  value={newpolicyData.policyEndDate}
                  onChange={(e) =>
                    setNewpolicyData({ ...newpolicyData, policyEndDate: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box className="" textAlign="right">
            <Button variant="contained" className="mainBtn" onClick={handleAddPolicy}>
              Add
            </Button>
          </Box>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Retry</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}

export default AddPolicy;
