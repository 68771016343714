import { Icon } from '@iconify/react';
import CryptoJS from 'crypto-js';
import React, { useState } from 'react';
// material
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// react icons
import { MdAssignmentInd, MdNotificationsNone, MdUpdate } from 'react-icons/md';
// material icons
import CloseIcon from '@mui/icons-material/Close';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
// other icons
import searchFill from '@iconify/icons-eva/search-fill';
import add from '@iconify/icons-ic/add';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// ---------------------------------------------------------
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fDate } from '../../utils/formatTime';
import { DateRangeStyled, RootStyle, SearchStyle } from '../Global/Styling';
import leadsService from '../services/leadsService';
// ----------------------------------------------------------------------
import { PARTNER_TO_OFFLINE_URL } from '../services/globalApi';

const { AssignLeads } = leadsService;

export default function LeadsToolbar({
  numSelected,
  filterName,
  onFilterName,
  parent,
  pageMenu,
  selectProduct,
  setSelectProduct,
  onFilterProduct,
  loanTypesList,
  selectStatus,
  onFilterStatus,
  statusList,
  selectSource,
  onFilterSource,
  sourceListData,
  selected,
  salesPersonList,
  stateListMenu,
  setStateListData,
  filterDatesRange,
  setFilterDatesRange,
  cityListData,
  setCityListData,
  cityListMenu,
  token,
  setCityId,
  setRefresh,
  onAssignSubmit,
  openAssign,
  setOpenAssign,
  assignValue,
  setAssignValue,
  validationMsg,
  setValidationMsg,
  alertMsg,
  setAlertMsg
}) {
  const navigate = useNavigate();
  const [isOpenFilter, openFilter] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [showTextfield, setShowTextfield] = useState(false);

  return (
    <>
      {isOpenFilter && (
        <Box px={3} pt={2}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectProduct}`
                }}
                value={selectProduct}
                onChange={onFilterProduct}
              >
                <MenuItem value=" ">Select Product</MenuItem>
                {loanTypesList.length > 0
                  ? loanTypesList.map((item, index) => {
                      console.log(item);
                      return (
                        <MenuItem value={item.loanId} key={index}>
                          {item.loanName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Grid>
            <Grid item xs sx={{ position: 'relative' }}>
              <Box className="displayDateRange">
                {filterDatesRange[0].startDate !== null
                  ? `${fDate(filterDatesRange[0].startDate)} to `
                  : ''}
                {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
                {filterDatesRange[0].startDate === null && filterDatesRange[0].endDate === null ? (
                  <Typography className="dissabledMenu">Select Date Range</Typography>
                ) : (
                  ''
                )}
              </Box>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                MenuProps={{
                  className: 'menuOpened'
                }}
                value=" "
                className="datesSelect"
              >
                <DateRangeStyled
                  editableDateInputs={false}
                  onChange={(item) => setFilterDatesRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={filterDatesRange}
                  className="dateRangePicker"
                />
              </Select>
            </Grid>
            <Grid item xs>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={cityListMenu}
                size="small"
                onChange={(e, newValue) => {
                  setCityListData(newValue);
                  console.log('newValue20', newValue);
                }}
                getOptionLabel={(option) => option.cityName}
                renderOption={(props, option) => <li {...props}>{option.cityName}</li>}
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select City" />
                )}
              />
            </Grid>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectStatus}`
                }}
                value={selectStatus}
                onChange={onFilterStatus}
              >
                <MenuItem value=" ">Select Status</MenuItem>
                {statusList.length > 0
                  ? statusList.map((item, index) => {
                      console.log(item);
                      return (
                        <MenuItem value={item.stageStatusId} key={index}>
                          {item.stageStatusName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Grid>
            <Grid item xs>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectSource}`
                }}
                value={selectSource}
                onChange={onFilterSource}
              >
                <MenuItem value=" ">Select Source</MenuItem>
                {sourceListData.length > 0
                  ? sourceListData.map((item, index) => {
                      console.log(item);
                      return (
                        <MenuItem value={item.typeId} key={index}>
                          {item.typeName}
                        </MenuItem>
                      );
                    })
                  : ''}
              </Select>
            </Grid>
          </Grid>
        </Box>
      )}

      <RootStyle
        sx={{
          minHeight: 45,
          borderBottom: '1px solid #eaedf1'
        }}
      >
        <Box>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => {
              console.log('pageElement', pageElement);
              return (
                <React.Fragment key={pageElement.subPageId + index}>
                  {pageElement.subPageCode === 'ADD_NEW_APPLICATION' &&
                  pageElement.isEnable === true ? (
                    <>
                      <Tooltip title="Add new application" placement="top" arrow>
                        <IconButton
                          className="squareIconButton"
                          // onClick={(e) => setOpenAdd(true)}
                          onClick={(e) => {
                            const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
                            const authToken = localJWTtoken.token.jwt;
                            const companyId = localJWTtoken.token.companyId;
                            const userId = localJWTtoken.token.userId;
                            console.log('companyId', companyId);
                            const appToken = CryptoJS.AES.encrypt('null', 'appToken').toString();
                            const companyToken = CryptoJS.AES.encrypt(
                              String(companyId),
                              'companyToken'
                            ).toString();
                            const userToken = CryptoJS.AES.encrypt(
                              authToken,
                              'userToken'
                            ).toString();
                            const leadToken = CryptoJS.AES.encrypt('null', 'leadToken').toString();
                            const userIdToken = CryptoJS.AES.encrypt(
                              String(userId),
                              'userId'
                            ).toString();
                            window.open(
                              `${PARTNER_TO_OFFLINE_URL}/?u=${encodeURIComponent(
                                userToken
                              )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                                appToken
                              )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                                userIdToken
                              )}`,
                              `_blank`
                            );
                            setOpenAdd(false);
                          }}
                        >
                          <Icon icon={add} width={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : pageElement.subPageCode === 'UPDATE' && pageElement.isEnable === true ? (
                    <>
                      <Tooltip
                        title="Update"
                        placement="top"
                        arrow
                        onClick={(e) => {
                          const storagearr = [];
                          selected.forEach((element) => {
                            const obj = {
                              leadId: element.leadId,
                              leadName: element.customerName
                            };
                            storagearr.push(obj);
                            localStorage.setItem('leadData', JSON.stringify(storagearr));
                          });
                          navigate(`/${parent}/update`);
                        }}
                      >
                        <IconButton
                          className="squareIconButton"
                          classes={{ disabled: 'disabledBtn' }}
                          disabled={selected && selected.length < 1}
                        >
                          <MdUpdate size={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : pageElement.subPageCode === 'ASSIGN' && pageElement.isEnable === true ? (
                    <>
                      <Tooltip
                        title="Assign"
                        placement="top"
                        arrow
                        onClick={(e) => setOpenAssign(true)}
                      >
                        <IconButton
                          className="squareIconButton"
                          classes={{ disabled: 'disabledBtn' }}
                          disabled={selected && selected.length < 1}
                        >
                          <MdAssignmentInd size={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : pageElement.subPageCode === 'NOTIFICATION' &&
                    pageElement.isEnable === true ? (
                    <>
                      <Tooltip title="Notification" placement="top" arrow>
                        <IconButton className="squareIconButton">
                          <MdNotificationsNone size={18} />
                        </IconButton>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              );
            })}
        </Box>
        <Box>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => (
              <>
                {' '}
                {pageElement.subPageCode === 'SEARCH' && pageElement.isEnable === true ? (
                  <>
                    <SearchStyle
                      value={filterName}
                      onChange={onFilterName}
                      placeholder="Search Leads"
                      classes={{
                        root: 'searchHolder',
                        input: 'searchInput'
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                        </InputAdornment>
                      }
                    />
                    &nbsp;&nbsp;&nbsp;
                  </>
                ) : pageElement.subPageCode === 'FILTER_LIST' && pageElement.isEnable === true ? (
                  <Tooltip title="Filter list">
                    <IconButton
                      onClick={(e) => {
                        if (isOpenFilter) {
                          openFilter(false);
                        } else {
                          openFilter(true);
                        }
                      }}
                    >
                      <Icon icon={roundFilterList} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </>
            ))}
        </Box>
      </RootStyle>
      <Dialog open={openAdd} onClose={(e) => setOpenAdd(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">Select Mode of Application Submission</Typography>
          <IconButton onClick={(e) => setOpenAdd(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            spacing={3}
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            mb={2}
          >
            <Grid item xs={4}>
              <Box
                className="appSubmissionLinkB"
                onClick={(e) => {
                  window.open(`http://losdemo.kalolytic.com/`, `_blank`);
                  setOpenAdd(false);
                }}
              >
                <EmojiPeopleIcon className="appSubLinkIcon" />
                <Typography variant="subtitle1">Customer</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                className="appSubmissionLinkB"
                onClick={(e) => {
                  const localJWTtoken = JSON.parse(localStorage.getItem('userDetails'));
                  const authToken = localJWTtoken.token.jwt;
                  const companyId = localJWTtoken.token.companyId;
                  const userId = localJWTtoken.token.userId;
                  console.log('companyId', companyId);
                  const appToken = CryptoJS.AES.encrypt('null', 'appToken').toString();
                  const companyToken = CryptoJS.AES.encrypt(
                    String(companyId),
                    'companyToken'
                  ).toString();
                  const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
                  const leadToken = CryptoJS.AES.encrypt('null', 'leadToken').toString();
                  const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
                  window.open(
                    `${PARTNER_TO_OFFLINE_URL}/?u=${encodeURIComponent(
                      userToken
                    )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                      appToken
                    )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(userIdToken)}`,
                    `_blank`
                  );
                  setOpenAdd(false);
                }}
              >
                <PeopleAltIcon className="appSubLinkIcon" />
                <Typography variant="subtitle1">Sales</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                className="appSubmissionLinkB"
                onClick={(e) => {
                  window.open(`http://manual.kalolytic.com/`, `_blank`);
                  setOpenAdd(false);
                }}
              >
                <SettingsAccessibilityIcon className="appSubLinkIcon" />
                <Typography variant="subtitle2">Referral</Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={4}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#808080' }}
                onClick={(e) => setShowTextfield(true)}
              >
                Manual
              </Button>
            </Grid> */}
          </Grid>
          {showTextfield === 'true' ? (
            <Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type master name..."
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type master name..."
                />
              </Box>
            </Box>
          ) : (
            ''
          )}
        </DialogContent>

        {/* <DialogActions>
          <Button onClick={(e) => setOpenAdd(false)} variant="contained">
            SUBMIT
          </Button>
        </DialogActions> */}
      </Dialog>
      <Dialog open={openAssign} onClose={(e) => setOpenAssign(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">{selected && selected.length} Leads Selected</Typography>
          <IconButton onClick={(e) => setOpenAssign(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Select
            fullWidth
            inputProps={{
              className: `textInput customSelectBox dissabledMenu${assignValue}`
            }}
            value={assignValue}
            onChange={(e) => {
              setAlertMsg(false);
              setAssignValue(e.target.value);
            }}
          >
            <MenuItem value=" " disabled>
              Select Sales Person
            </MenuItem>
            {salesPersonList.length > 0
              ? salesPersonList.map((item, index) => {
                  console.log(item);
                  return <MenuItem value={item.userId}>{item.fullName}</MenuItem>;
                })
              : ''}
          </Select>
          {alertMsg === true && <FormHelperText error>Please select sales person</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onAssignSubmit} variant="contained" className="mainBtn">
            Submit
          </Button>
        </DialogActions>
        {/* <Dialog
          open={dialogOpen}
          onClose={(e) => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => setDialogOpen(false)}>Retry</Button>
          </DialogActions>
        </Dialog> */}
      </Dialog>
    </>
  );
}

// Top Cities
const topCities = [
  { label: 'Mumbai' },
  { label: 'Delhi' },
  { label: 'Bengaluru' },
  { label: 'Ahmedabad' },
  { label: 'Hyderabad' },
  { label: 'Chennai' },
  { label: 'Kolkata' },
  { label: 'Pune' },
  { label: 'Jaipur' },
  { label: 'Surat' },
  { label: 'Lucknow' },
  { label: 'Kanpur' },
  { label: 'Nagpur' },
  { label: 'Patna' },
  { label: 'Indore' },
  { label: 'Thane' },
  { label: 'Bhopal' },
  { label: 'Visakhapatnam' },
  { label: 'Vadodara' },
  { label: 'Firozabad' },
  { label: 'Ludhiana' },
  { label: 'Rajkot' },
  { label: 'Agra' },
  { label: 'Siliguri' },
  { label: 'Nashik' },
  { label: 'Faridabad' },
  { label: 'Patiala' },
  { label: 'Meerut' },
  { label: 'Varanasi' },
  { label: 'Dhanbad' },
  { label: 'Jodhpur' },
  { label: 'Amritsar' },
  { label: 'Raipur' },
  { label: 'Allahabad' },
  { label: 'Coimbatore' },
  { label: 'Jabalpur' },
  { label: 'Gwalior' },
  { label: 'Vijayawada' },
  { label: 'Madurai' },
  { label: 'Guwahati' },
  { label: 'Chandigarh' },
  { label: 'Hubli-Dharwad' },
  { label: 'Amroha' },
  { label: 'Moradabad' },
  { label: 'Gurgaon' },
  { label: 'Aligarh' },
  { label: 'Solapur' },
  { label: 'Ranchi' },
  { label: 'Jalandhar' },
  { label: 'Tiruchirappalli' },
  { label: 'Bhubaneswar' },
  { label: 'Salem' },
  { label: 'Warangal' },
  { label: 'Mira-Bhayandar' },
  { label: 'Thiruvananthapuram' },
  { label: 'Bhiwandi' },
  { label: 'Saharanpur' },
  { label: 'Guntur' },
  { label: 'Amravati' },
  { label: 'Bikaner' },
  { label: 'Noida' },
  { label: 'Jamshedpur' },
  { label: 'Bhilai Nagar' },
  { label: 'Cuttack' },
  { label: 'Kochi' },
  { label: 'Udaipur' },
  { label: 'Bhavnagar' },
  { label: 'Dehradun' },
  { label: 'Asansol' },
  { label: 'Nanded-Waghala' },
  { label: 'Ajmer' },
  { label: 'Jamnagar' },
  { label: 'Ujjain' },
  { label: 'Sangli' },
  { label: 'Loni' },
  { label: 'Jhansi' },
  { label: 'Pondicherry' },
  { label: 'Nellore' },
  { label: 'Jammu' },
  { label: 'Belagavi' },
  { label: 'Raurkela' },
  { label: 'Mangaluru' },
  { label: 'Tirunelveli' },
  { label: 'Malegaon' },
  { label: 'Gaya' },
  { label: 'Tiruppur' },
  { label: 'Davanagere' },
  { label: 'Kozhikode' },
  { label: 'Akola' },
  { label: 'Kurnool' },
  { label: 'Bokaro' },
  { label: 'Rajahmundry' },
  { label: 'Ballari' },
  { label: 'Agartala' },
  { label: 'Bhagalpur' },
  { label: 'Latur' },
  { label: 'Dhule' },
  { label: 'Korba' },
  { label: 'Bhilwara' },
  { label: 'Brahmapur' },
  { label: 'Mysore' },
  { label: 'Muzaffarpur' },
  { label: 'Ahmednagar' },
  { label: 'Kollam' },
  { label: 'Bilaspur' },
  { label: 'Shahjahanpur' },
  { label: 'Thrissur' },
  { label: 'Alwar' },
  { label: 'Kakinada' },
  { label: 'Nizamabad' },
  { label: 'Sagar' },
  { label: 'Tumkur' },
  { label: 'Hisar' },
  { label: 'Rohtak' },
  { label: 'Panipat' },
  { label: 'Darbhanga' },
  { label: 'Kharagpur' },
  { label: 'Aizawl' },
  { label: 'Ichalkaranji' },
  { label: 'Tirupati' },
  { label: 'Karnal' },
  { label: 'Bathinda' },
  { label: 'Rampur' },
  { label: 'Shivamogga' },
  { label: 'Ratlam' },
  { label: 'Modinagar' },
  { label: 'Durg' },
  { label: 'Shillong' },
  { label: 'Imphal' },
  { label: 'Hapur' },
  { label: 'Ranipet' },
  { label: 'Anantapur' },
  { label: 'Arrah' },
  { label: 'Karimnagar' },
  { label: 'Etawah' },
  { label: 'Bharatpur' },
  { label: 'Begusarai' },
  { label: 'Chhapra' },
  { label: 'Kadapa' },
  { label: 'Pali' },
  { label: 'Satna' },
  { label: 'Vizianagaram' },
  { label: 'Katihar' },
  { label: 'Hardwar' },
  { label: 'Sonipat' },
  { label: 'Nagercoil' },
  { label: 'Thanjavur' },
  { label: 'Murwara' },
  { label: 'Naihati' },
  { label: 'Sambhal' },
  { label: 'Nadiad' },
  { label: 'Yamunanagar' },
  { label: 'Eluru' },
  { label: 'Munger' },
  { label: 'Panchkula' },
  { label: 'Deoghar' },
  { label: 'Ongole' },
  { label: 'Nandyal' },
  { label: 'Morena' },
  { label: 'Bhiwani' },
  { label: 'Porbandar' },
  { label: 'Palakkad' },
  { label: 'Anand' },
  { label: 'Purnia' },
  { label: 'Baharampur' },
  { label: 'Barmer' },
  { label: 'Morvi' },
  { label: 'Orai' },
  { label: 'Bahraich' },
  { label: 'Vellore' },
  { label: 'Khammam' },
  { label: 'Mahesana' },
  { label: 'Silchar' },
  { label: 'Sambalpur' },
  { label: 'Rewa' },
  { label: 'Unnao' },
  { label: 'Hugli-Chinsurah' },
  { label: 'Raiganj' },
  { label: 'Adityapur' },
  { label: 'Alappuzha' },
  { label: 'Bahadurgarh' },
  { label: 'Machilipatnam' },
  { label: 'Rae Bareli' },
  { label: 'Jalpaiguri' },
  { label: 'Bharuch' },
  { label: 'Hoshiarpur' },
  { label: 'Baramula' },
  { label: 'Adoni' },
  { label: 'Jind' },
  { label: 'Tonk' },
  { label: 'Tenali' },
  { label: 'Kancheepuram' },
  { label: 'Vapi' },
  { label: 'Navsari' },
  { label: 'Mahbubnagar' },
  { label: 'Puri' },
  { label: 'Erode' },
  { label: 'Batala' },
  { label: 'Haldwani' },
  { label: 'Vidisha' },
  { label: 'Saharsa' },
  { label: 'Thanesar' },
  { label: 'Chittoor' },
  { label: 'Veraval' },
  { label: 'Lakhimpur' },
  { label: 'Sitapur' },
  { label: 'Hindupur' },
  { label: 'Santipur' },
  { label: 'Balurghat' },
  { label: 'Ganjbasoda' },
  { label: 'Moga' },
  { label: 'Srinagar' },
  { label: 'Medinipur' },
  { label: 'Habra' },
  { label: 'Sasaram' },
  { label: 'Hajipur' },
  { label: 'Bhuj' },
  { label: 'Shimla' },
  { label: 'Tiruvannamalai' },
  { label: 'Kaithal' },
  { label: 'Rajnandgaon' },
  { label: 'Godhra' },
  { label: 'Hazaribag' },
  { label: 'Bhimavaram' },
  { label: 'Mandsaur' },
  { label: 'Dibrugarh' },
  { label: 'Kolar' },
  { label: 'Bankura' },
  { label: 'Mandya' },
  { label: 'Dehri-on-Sone' },
  { label: 'Madanapalle' },
  { label: 'Malerkotla' },
  { label: 'Lalitpur' },
  { label: 'Bettiah' },
  { label: 'Pollachi' },
  { label: 'Khanna' },
  { label: 'Neemuch' },
  { label: 'Palwal' },
  { label: 'Palanpur' },
  { label: 'Guntakal' },
  { label: 'Nabadwip' },
  { label: 'Udupi' },
  { label: 'Jagdalpur' },
  { label: 'Motihari' },
  { label: 'Pilibhit' },
  { label: 'Dimapur' },
  { label: 'Mohali' },
  { label: 'Dharmavaram' },
  { label: 'Kashipur' },
  { label: 'Sivakasi' },
  { label: 'Darjiling' },
  { label: 'Chikkamagaluru' },
  { label: 'Gudivada' },
  { label: 'Adilabad' },
  { label: 'Yavatmal' },
  { label: 'Barnala' },
  { label: 'Nagaon' },
  { label: 'Narasaraopet' },
  { label: 'Raigarh' },
  { label: 'Roorkee' },
  { label: 'Valsad' },
  { label: 'Ambikapur' },
  { label: 'Giridih' },
  { label: 'Chandausi' },
  { label: 'Purulia' },
  { label: 'Patan' },
  { label: 'Bagaha' },
  { label: 'Hardoi' },
  { label: 'Achalpur' },
  { label: 'Osmanabad' },
  { label: 'Deesa' },
  { label: 'Nandurbar' },
  { label: 'Azamgarh' },
  { label: 'Ramgarh' },
  { label: 'Firozpur' },
  { label: 'Baripada' },
  { label: 'Karwar' },
  { label: 'Siwan' },
  { label: 'Anantnag' },
  { label: 'Tadpatri' },
  { label: 'Satara' },
  { label: 'Bhadrak' },
  { label: 'Kishanganj' },
  { label: 'Wardha' },
  { label: 'Amreli' },
  { label: 'Neyveli' },
  { label: 'Jamalpur' },
  { label: 'Marmagao' },
  { label: 'Udgir' },
  { label: 'Tadepalligudem' },
  { label: 'Nagapattinam' },
  { label: 'Buxar' },
  { label: 'Aurangabad' },
  { label: 'Jehanabad' },
  { label: 'Khair' },
  { label: 'Sawai Madhopur' },
  { label: 'Kapurthala' },
  { label: 'Chilakaluripet' },
  { label: 'Malappuram' },
  { label: 'Rewari' },
  { label: 'Nagaur' },
  { label: 'Sultanpur' },
  { label: 'Nagda' },
  { label: 'Port Blair' },
  { label: 'Lakhisarai' },
  { label: 'Panaji' },
  { label: 'Tinsukia' },
  { label: 'Itarsi' },
  { label: 'Kohima' },
  { label: 'Balangir' },
  { label: 'Nawada' },
  { label: 'Jharsuguda' },
  { label: 'Jagtial' },
  { label: 'Viluppuram' },
  { label: 'Amalner' },
  { label: 'Zirakpur' },
  { label: 'Tanda' },
  { label: 'Tiruchengode' },
  { label: 'Nagina' },
  { label: 'Yemmiganur' },
  { label: 'Vaniyambadi' },
  { label: 'Theni Allinagaram' },
  { label: 'Margao' },
  { label: 'Akot' },
  { label: 'Mhow' },
  { label: 'Kot Kapura' },
  { label: 'Kadiri' },
  { label: 'Najibabad' },
  { label: 'Nirmal' },
  { label: 'Udhagamandalam' },
  { label: 'Jhumri Tilaiya' },
  { label: 'Aruppukkottai' },
  { label: 'Jamui' },
  { label: 'Sitamarhi' },
  { label: 'Chirala' },
  { label: 'Anjar' },
  { label: 'Karaikal' },
  { label: 'Hansi' },
  { label: 'Anakapalle' },
  { label: 'Mahasamund' },
  { label: 'Faridkot' },
  { label: 'Dhoraji' },
  { label: 'Balaghat' },
  { label: 'Khambhat' },
  { label: 'Kavali' },
  { label: 'Dhamtari' },
  { label: 'Ashok Nagar' },
  { label: 'Bargarh' },
  { label: 'Kamareddy' },
  { label: 'Kothagudem' },
  { label: 'Ramanagaram' },
  { label: 'Gokak' },
  { label: 'Tikamgarh' },
  { label: 'Araria' },
  { label: 'Rishikesh' },
  { label: 'Daltonganj' },
  { label: 'Arakkonam' },
  { label: 'Washim' },
  { label: 'Sangrur' },
  { label: 'Bodhan' },
  { label: 'Fazilka' },
  { label: 'Palacole' },
  { label: 'Keshod' },
  { label: 'Wadhwan' },
  { label: 'Gurdaspur' },
  { label: 'Vatakara' },
  { label: 'Tura' },
  { label: 'Narnaul' },
  { label: 'Kharar' },
  { label: 'Yadgir' },
  { label: 'Ambejogai' },
  { label: 'Ankleshwar' },
  { label: 'Paradip' },
  { label: 'Virudhachalam' },
  { label: 'Kanhangad' },
  { label: 'Kadi' },
  { label: 'Gobindgarh' },
  { label: 'Tindivanam' },
  { label: 'Taliparamba' },
  { label: 'Tanuku' },
  { label: 'Virudhunagar' },
  { label: 'Koyilandy' },
  { label: 'Jorhat' },
  { label: 'Karur' },
  { label: 'Valparai' },
  { label: 'Neyyattinkara' },
  { label: 'Bapatla' },
  { label: 'Fatehabad' },
  { label: 'Sankarankovil' },
  { label: 'Tenkasi' },
  { label: 'Ratnagiri' },
  { label: 'Sikandrabad' },
  { label: 'Chaibasa' },
  { label: 'Chirmiri' },
  { label: 'Bhawanipatna' },
  { label: 'Kayamkulam' },
  { label: 'Nabha' },
  { label: 'Dhenkanal' },
  { label: 'Uran Islampur' },
  { label: 'Gopalganj' },
  { label: 'Bongaigaon' },
  { label: 'Tarn Taran' },
  { label: 'Barbil' },
  { label: 'Koratla' },
  { label: 'Arambagh' },
  { label: 'Gohana' },
  { label: 'Ladnu' },
  { label: 'Tamluk' },
  { label: 'Jagraon' },
  { label: 'Alirajpur' },
  { label: 'Tandur' },
  { label: 'Naidupet' },
  { label: 'Tirupathur' },
  { label: 'Tohana' },
  { label: 'Dhubri' },
  { label: 'Visnagar' },
  { label: 'Vrindavan' },
  { label: 'Nokha' },
  { label: 'Nagari' },
  { label: 'Narwana' },
  { label: 'Ujhani' },
  { label: 'Samastipur' },
  { label: 'Laharpur' },
  { label: 'Nimbahera' },
  { label: 'Diphu' },
  { label: 'Jhargram' },
  { label: 'Tilhar' },
  { label: 'Udumalaipettai' },
  { label: 'Wanaparthy' },
  { label: 'Gudur' },
  { label: 'Kendujhar' },
  { label: 'Mandi' },
  { label: 'Nedumangad' },
  { label: 'North Lakhimpur' },
  { label: 'Vinukonda' },
  { label: 'Tiptur' },
  { label: 'Gobichettipalayam' },
  { label: 'Wani' },
  { label: 'Upleta' },
  { label: 'Narasapuram' },
  { label: 'Nuzvid' },
  { label: 'Tezpur' },
  { label: 'Una' },
  { label: 'Sheopur' },
  { label: 'Thiruvarur' },
  { label: 'Lonavla' },
  { label: 'Ponnur' },
  { label: 'Kagaznagar' },
  { label: 'Gadwal' },
  { label: 'Bhatapara' },
  { label: 'Kandukur' },
  { label: 'Unjha' },
  { label: 'Lunglei' },
  { label: 'Karimganj' },
  { label: 'Kannur' },
  { label: 'Bobbili' },
  { label: 'Talegaon Dabhade' },
  { label: 'Anjangaon' },
  { label: 'Mangrol' },
  { label: 'Gangarampur' },
  { label: 'Thiruvallur' },
  { label: 'Tirur' },
  { label: 'Jatani' },
  { label: 'Viramgam' },
  { label: 'Rajsamand' },
  { label: 'Yanam' },
  { label: 'Kottayam' },
  { label: 'Dhuri' },
  { label: 'Namakkal' },
  { label: 'Kasaragod' },
  { label: 'Kunnamkulam' },
  { label: 'Umred' },
  { label: 'Bellampalle' },
  { label: 'Ottappalam' },
  { label: 'Dumraon' },
  { label: 'Jaggaiahpet' },
  { label: 'Goalpara' },
  { label: 'Tuni' },
  { label: 'Lachhmangarh' },
  { label: 'Bhongir' },
  { label: 'Amalapuram' },
  { label: 'Firozpur Cantt.' },
  { label: 'Vikarabad' },
  { label: 'Thiruvalla' },
  { label: 'Palghar' },
  { label: 'Jangaon' },
  { label: 'Bheemunipatnam' },
  { label: 'Panna' },
  { label: 'Thodupuzha' },
  { label: 'Arwal' },
  { label: 'Venkatagiri' },
  { label: 'Kalpi' },
  { label: 'Churu' },
  { label: 'Arsikere' },
  { label: 'Ozar' },
  { label: 'Thirumangalam' },
  { label: 'Nasirabad' },
  { label: 'Nanjangud' },
  { label: 'Forbesganj' },
  { label: 'Tundla' },
  { label: 'Bhadrachalam' },
  { label: 'Madhepura' },
  { label: 'Pithoragarh' },
  { label: 'Mathura' },
  { label: 'Sultanganj' },
  { label: 'Raxaul' },
  { label: 'Pilani' },
  { label: 'Mapusa' },
  { label: 'Sivaganga' },
  { label: 'Shahpur' },
  { label: 'Ramnagar' },
  { label: 'Rameshwaram' },
  { label: 'Perambalur' },
  { label: 'Manvi' },
  { label: 'Murshidabad' },
  { label: 'Mahe' },
  { label: 'Solan' },
  { label: 'Sanawad' },
  { label: 'Bulandshahr' },
  { label: 'Satana' },
  { label: 'Sanand' },
  { label: 'Sohna' },
  { label: 'Rudrapur' },
  { label: 'Perumbavoor' },
  { label: 'Raikot' },
  { label: 'Pauri' },
  { label: 'Shahganj' },
  { label: 'Mount Abu' },
  { label: 'Silvassa' },
  { label: 'Sikanderpur' },
  { label: 'Navi Mumbai' }
];
